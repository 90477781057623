import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  bindSubjectList,
  deleteQuestions,
  getCountryList,
  getCourseTypelist,
  getExamSetTypeId,
  getGeneralExamList,
  getQuestionsExamSetList,
  getQuestionsList,
} from "../../Redux/Actions/AdminAction";
import PaginationTable from "../../Components/paginationTable";
import { useNavigate } from "react-router-dom";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import QuestionEdit from "./questionEdit";
import { GradeList } from "../../Utils/dataConstant";

export default function UpdateQuestion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any[]>([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [examType, setExamType] = useState<any>("");
  const [examTypeList, setExamTypeList] = useState<any[]>([]);
  const [resetExamType, setResetExamType] = useState(false);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any[]>([]);
  const [resetBatch, setResetBatch] = useState(false);
  const [examSetList, setExamSetList] = useState<any[]>([]);
  const [resetExamSet, setResetExamSet] = useState(false);
  const [examSet, setExamSet] = useState<any>("");
  const [questionlist, setQuestionlist] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [examSection, setExamSection] = useState<any[]>([]);
  const [selectedSectionId, setSelectedSectionId] = useState(0);
  const [editQuesValue, setEditQuesValue] = useState({
    questionData: "",
    examSet: "",
  });
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  useEffect(() => {
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamTypeList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value);
    setResetExamSet(false);
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setresetExam(true);
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );

    const Data: any = {};

    if (course?._id != "") {
      Data["courseId"] = course?._id;
    }

    if (grade != "") {
      Data["gradeId"] = value?._id;
    }

    if (examType !== 1 && subject !== "") {
      Data["subjectId"] = subject;
    }

    dispatch(
      getGeneralPackageList(
        Data,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value);

    if (examType?.name === "DPP") {
      dispatch(
        bindSubjectList(
          {
            examtypeId: value._id,
          },
          (res: any) => {
            setSubjectList(res);
          },

          () => {}
        )
      );
    } else if (examType?.name === "Scholarship") {
      const postData = {
        coursetypeId: course?._id,
        examtypeId: value?._id,
        examSetTypeId: examType._id,
      };
      dispatch(
        getQuestionsExamSetList(
          postData,
          (res: any) => {
            setExamSetList(res);
          },
          () => {}
        )
      );
    } else {
      const postData = {
        examtypeId: value._id,
      };
      dispatch(
        getGeneralPackageList(
          postData,
          (res: any) => {
            setBatchList(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setResetSubject(false);
    setExamSetList([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      examSetTypeId: examType?._id,
      subjectId: value._id,
    };
    dispatch(
      getQuestionsExamSetList(
        postData,
        (res: any) => {
          setExamSetList(res);
        },
        () => {}
      )
    );
  };

  const onChangeBatch = (value: { _id: any; name?: string }) => {
    setBatch(value);
    setResetBatch(false);
    setExamSetList([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      examSetTypeId: examType?._id,
      packageId: value._id,
    };
    dispatch(
      getQuestionsExamSetList(
        postData,
        (res: any) => {
          setExamSetList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExamSet = (value: { _id: any; name?: string }) => {
    setExamSet(value);
    setQuestionlist([]);
    const postData: any = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
      packageId: batch?._id,
      examSetTypeId: examType?._id,
      examSetId: value?._id,
    };

    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getQuestionsList(
        postData,
        (res: any) => {
          setQuestionlist(res[0]?.questionData);
          setExamSection(res[0]?.sections);
          setSelectedSectionId(res[0]?.sections[0]?._id);
        },
        () => {}
      )
    );
  };

  const handleEdit = async (value: any) => {
    await setEditQuesValue({ questionData: value, examSet: examSet });
    setisEditMode(true);
  };

  const getData = () => {
    const postData: any = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
      packageId: batch?._id,
      examSetTypeId: examType?._id,
      examSetId: examSet?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getQuestionsList(
        postData,
        (res: any) => {
          setQuestionlist(res[0]?.questionData);
          setExamSection(res[0]?.sections);
          setSelectedSectionId(res[0]?.sections[0]?._id);
        },
        () => {}
      )
    );
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteQuestions(
        value?.examQuestionId,
        (res: any) => {
          getData();
        },
        () => {}
      )
    );
  };

  const getQuestionsData = (item: any[]) => {
    return item?.filter((item: any) => {
      if (selectedSectionId === item?.section?.sectionId) {
        return item;
      }
    });
  };
  const renderListMode = () => {
    return (
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="col-12   pt-2">
          <div className="col-6 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamType(value)
                }
                value={examType}
                options={examTypeList}
                Isrequired={true}
                reset={resetExamType}
              />
            </div>
          </div>
          <div className="col-6  d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-8 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="col-6 d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name "} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
          )}

          {course?.type === 2 ? (
            <div className=" col-6 d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {examType?.name === "DPP" ? (
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
          ) : examType?.name === "Scholarship" ? null : (
            <>
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeBatch(value)
                    }
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                  />
                </div>
              </div>
            </>
          )}
          <div className="col-6 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Set Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Set Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamSet(value)
                }
                value={examSet}
                options={examSetList}
                Isrequired={true}
                reset={resetExamSet}
              />
            </div>
          </div>
        </div>

        <PaginationTable
          tableData={getQuestionsData(questionlist) || []}
          pagename={"Updatequestion"}
          rowsPerPage={0}
          page={0}
          tableHead={["S.N", "Question Description", "Correct", "Actions"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </div>
    );
  };
  const renderEditView = () => {
    return (
      <QuestionEdit
        questionData={editQuesValue?.questionData}
        examSet={editQuesValue?.examSet}
        isEditMode={isEditMode}
        examType={examType}
        setisEditMode={setisEditMode}
        getData={getData}
      />
    );
  };

  return (
    <div className="contentBox">
      {isEditMode ? (
        renderEditView()
      ) : (
        <>
          <PageTitle
            name={"UPDATE QUESTION"}
            isEditMode={isEditMode}
            setisEditMode={setisEditMode}
          />
          {renderListMode()}
        </>
      )}
    </div>
  );
}
