import React from "react";

const Checkbox = (props:any) => {
  return (
    <label className="CheckBoxcontainer">
      <input
        type="checkbox"
        value={props.value}
        name={props.namne}
        checked={props.checked}
        onChange={props.onChange}
      />
      <span className="checkmark"></span>
      <span className="font-14">{props.labelname}</span>
    </label>
  );
};
export default Checkbox;
