import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  value?: string;
  setInputText: Function;
  defaultValue?: string;
  maxdate?: Date;
  mindate?: Date;
  Isrequired?: boolean;
  disabled?: any;
}

export default function TimeHoursMinutes({
  value,
  setInputText,
  defaultValue,
  maxdate,
  mindate,
  Isrequired,
  disabled,
}: props) {
  return (
    <div className=" mb-2 align-items-center custommargin">
      <div className=" align-items-center">
        <input
          className="time rounded mt-1 "
          type="time"
          onChange={(e) => setInputText(e.target.value)}
          value={value}
          defaultValue={defaultValue}
          required={Isrequired}
          disabled={disabled}
          // step="1"
        />
        {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
      </div>
    </div>
  );
}
