import "../Assets/css/studentCard.css";
import user from "../Assets/images/user.jpg";
import moment from "moment";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { ReScheduleDemo } from "../Redux/Actions/AdminAction";
import {
  getLiveStatusChange,
  getLiveStatusUpdate,
} from "../Redux/Actions/SuperAdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../Modals";
export default function StudentCard(props: any) {
  const [demoButton, setDemoButton] = useState<any>(true);
  const changeIntoIst = (date: any) => {
    var date: any = new Date();
    date.getHours();
    date.getMinutes();
    date.getSeconds();
    return date;
  };
  const [startTime, setStartTime] = useState<any>(
    moment(changeIntoIst(new Date())).format("hh:mm:ss")
  );
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));
  const dispatch: Function = useDispatch();
  function tConvert(time: any) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  const onClickStartDemo = () => {
    setDemoButton(false);
    let postData: any = {
      demoId: props?.item?.demoId,
      statusId: 6,
    };

    dispatch(
      ReScheduleDemo(
        postData,
        (res: any) => {
          window.open(
            props?.item?.URL,
            "_blank",
            "width=" +
              window.screen?.availWidth +
              ",height=" +
              window.screen?.availHeight
          );
        },
        () => {}
      )
    );
  };
  const onClickRejoinDemo = () => {
    setDemoButton(false);
    window.open(
      props?.item?.URL,
      "_blank",
      "width=" +
        window.screen?.availWidth +
        ",height=" +
        window.screen?.availHeight
    );
  };
  const onClickEndDemo = () => {
    setDemoButton(true);
    let postData: any = {
      demoId: props?.item?.demoId,
      statusId: 5,
    };
    dispatch(
      ReScheduleDemo(
        postData,
        (res: any) => {
          props?.getData();
        },
        () => {}
      )
    );
  };

  const onClickStartClass = () => {
    const sendData = {
      lectureId: props?.item?.lectureId,
      topicId: props?.item?.topicId,
      studentId: props?.item?.studentId,
      teacherId: userId,
      teacher: true,
    };
    const encodedData = encodeURIComponent(JSON.stringify(sendData));
    // window.open(
    //   `${process.env.REACT_APP_WEBLINK}/LectureIndex?data=${encodedData}`,
    //   "_blank"
    // );
    // window.open(
    //   `https://devfrontend.lurnigo.com/LectureIndex?data=${encodedData}`,
    //   "_blank"
    // );
    // window.open(`http://localhost:3700/LectureIndex?data=${encodedData}`, '_blank');

    setDemoButton(false);
    dispatch(
      getLiveStatusUpdate(
        {
          titleId: props?.item?.titleId,
          statusCode: 2,
        },
        (res: any) => {
          window.open(
            props?.item?.URL,
            "_blank",
            "width=" +
              window.screen?.availWidth +
              ",height=" +
              window.screen?.availHeight
          );
        },
        () => {}
      )
    );
    dispatch(
      getLiveStatusChange(
        {
          titleId: props?.item?.titleId,
          statusCode: 2,
          startTime: startTime,
          plVideoId: props?.item?.plVideoId,
        },
        (res: any) => {},
        () => {}
      )
    );
  };
  const onClickRejoinClass = () => {
    const sendData = {
      lectureId: props?.item?.lectureId,
      topicId: props?.item?.topicId,
      studentId: props?.item?.studentId,
      teacherId: userId,
      teacher: true,
    };
    const encodedData = encodeURIComponent(JSON.stringify(sendData));
    // window.open(
    //   `${process.env.REACT_APP_WEBLINK}/LectureIndex?data=${encodedData}`,
    //   "_blank"
    // );
    // window.open(`https://devfrontend.lurnigo.com/LectureIndex?data=${encodedData}`, '_blank');
    // window.open(`http://localhost:3700/LectureIndex?data=${encodedData}`, '_blank');
    setDemoButton(false);
    window.open(
      props?.item?.URL,
      "_blank",
      "width=" +
        window.screen?.availWidth +
        ",height=" +
        window.screen?.availHeight
    );
  };
  const onClickEndClass = () => {
    setDemoButton(true);

    dispatch(
      getLiveStatusUpdate(
        {
          titleId: props?.item?.titleId,
          statusCode: 9,
        },
        (res: any) => {
          props?.getData();
        },
        () => {}
      )
    );
  };
  return (
    <div className="studentCard">
      <div className="d-flex">
        <div className="userImgDiv d-flex align-items-center me-2">
          <img src={user} alt="User" className="userImg" />
        </div>
        <div className="w-100">
          <h6 className="fw-bold">{`${props?.item?.firstName} ${props?.item?.lastName}`}</h6>

          <h6>{props?.item?.title}</h6>
          <div className="gradeDiv">
            {props?.item?.gradedesc ? (
              <div>{props?.item?.gradedesc}</div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="mt-2 scheduleBox rounded">
        <h6 className="text-primary">{props?.item?.coursetypeName}</h6>
        <h6>{props?.item?.UTCOffset}</h6>
        {props?.pageName === "MyClassTeacherPanel" ? (
          <h6 className="fw-bold">
            at{" "}
            {`${moment(props?.item?.scheduleTo).format("DD-MM-YYYY")} ,
              ${tConvert(props?.item?.time)}
              ${props?.item?.timezone}`}
          </h6>
        ) : (
          <h6 className="fw-bold">
            at{" "}
            {`${moment(props?.item?.date).format("DD-MM-YYYY")} ,
              ${tConvert(props?.item?.time)}
              ${props?.item?.timezone}`}
          </h6>
        )}
        {props?.pageName === "MyDemoTeacherPanel" ||
        props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Indian date & Time : </div>
            <div className="ms-1">
              <h6>
                {moment
                  .utc(props?.item?.Indian_DateTime)
                  .format("DD-MM-YYYY, HH:mm:ss")}
              </h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Subject : </div>
            <div className="ms-1">
              <h6>{props?.item?.subjectName}</h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Topic : </div>
            <div className="ms-1">
              <h6>{props?.item?.topicName}</h6>
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex">
            <div className="fw-bold">Sub-Topic : </div>
            <div className="ms-1">
              <h6>{props?.item?.subTopicName}</h6>{" "}
            </div>
          </div>
        ) : null}

        {props?.pageName === "MyDemoTeacherPanel" ? (
          <div className="d-flex justify-content-between">
            <div className="mt-4 d-flex">
              {demoButton === true ? (
                <button
                  type="button"
                  className="btn btn-dark mb-2"
                  onClick={onClickStartDemo}
                >
                  START DEMO
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-dark  mb-2"
                  onClick={onClickEndDemo}
                >
                  END DEMO
                </button>
              )}
            </div>
            <div className="mt-4 d-flex">
              {demoButton === false ? (
                <button
                  type="button"
                  className="btn btn-dark mb-2"
                  onClick={onClickRejoinDemo}
                >
                  REJOIN DEMO
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
        {props?.pageName === "MyClassTeacherPanel" ? (
          <div className="d-flex justify-content-between">
            <div className="mt-4 d-flex">
              {demoButton === true && props?.item?.status === 1 ? (
                <button
                  type="button"
                  className="btn btn-dark mb-2"
                  onClick={onClickStartClass}
                >
                  START CLASS
                </button>
              ) : (
                <button
                  type="button"
                  className="btn btn-dark  mb-2"
                  onClick={onClickEndClass}
                >
                  END CLASS
                </button>
              )}
            </div>
            <div className="mt-4 d-flex">
              {demoButton === false || props?.item?.status === 2 ? (
                <button
                  type="button"
                  className="btn btn-dark mb-2"
                  onClick={onClickRejoinClass}
                >
                  REJOIN CLASS
                </button>
              ) : null}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
