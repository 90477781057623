import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import {
  alignDemo,
  bindSubjectList,
  getCourseExamList,
  getGeneralTimezoneList,
  getOtherTeacherList,
  getPreferTeacherList,
} from "../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Addmorebtn from "./addmorebtn";
import moment from "moment";
import DatePicker from "./datePicker";
import Time from "./time";

interface props {
  open: boolean;
  handleClose: Function;
  getDemoReq: Function;
  data?: any;
  showEditSchedule?: any;
  showDemoSchedule?: any;
  pageName?: any;
}

export default function LeadDemoAlignPopup(props: props) {
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [rating, setRating] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);
  const [otherTeacher, setOtherTeacher] = useState<any>("");
  const [otherTeacherList, setOtherTeacherList] = useState<any>([]);
  const [resetOtherTeacher, setResetOtherTeacher] = useState(false);
  const [clickOther, setClickOther] = useState(false);
  const [openFeedBackPopup, setOpenFeedBackPopup] = useState(false);
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [startDate, setStartDate] = useState<any>("");
  const [mindate, setmindate] = useState<any>("");
  const [demoTime, setDemoTime] = useState<any>("");
  const [courseExamList, setCourseExamList] = useState<any>([]);
  const [examList, setExamList] = useState<any>([]);
  const [examType, setExamType] = useState<any>("");
  const [resetExamType, setResetExamType] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);

  const { open, handleClose, data, pageName, getDemoReq } = props;

  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  useEffect(() => {
    setmindate(moment(new Date()).subtract(1, "days").format("YYYY-MM-DD"));
    setDate(moment(startDate).format("YYYY-MM-DD"));

    setRating({ id: props?.data?.rating, value: labels[props?.data?.rating] });

    dispatch(
      getCourseExamList(
        "",
        (res: any) => {
          setCourseExamList(res);
          res?.map((value: any) => {
            if (value?._id === data?.coursetypeId) {
              setExamList(value?.examList);
            }
          });
        },
        () => { }
      )
    );
    dispatch(
      getGeneralTimezoneList(
        data?.countryId,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => { }
      )
    );

    if (
      pageName === "LandingPageLeads" ||
      pageName === "MarketingLandingpage"
    ) {
    } else {
      const postData: any = {
        coursetypeId: data?.coursetypeId,
      };
      if (data?.type === 1 || data?.type === 3) {
        postData["examtypeId"] = data?.examtypeId;
      }
      if (data?.type === 2) {
        postData["gradeId"] = data?.gradeId;
      }
      dispatch(
        bindSubjectList(
          postData,
          (res: any) => {
            setSubjectList(res);
          },
          () => { }
        )
      );
    }
  }, [data, startDate]);

  const convertTimeTo24HourFormat = (time: any) => {
    const [hour, minute] = time.split(" ");
    let hourNumber = parseInt(hour);
    if (hourNumber === 12) {
      hourNumber = 0;
    }
    if (time.toLowerCase().includes("pm")) {
      hourNumber += 12;
    }
    const formattedHour = hourNumber.toString().padStart(2, "0");
    return `${formattedHour}:00:00`;
  };

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
    getDemoReq();
    setClickOther(false);
  }
  const onChangeSubject = (value: any) => {
    setSubject(value);
    setResetSubject(false);
    const postData = {
      timezoneId: timeZone?._id,
      countryId: data?.countryId,
      subjectId: value?._id,
    };
    dispatch(
      getOtherTeacherList(
        postData,
        (res: any) => {
          setOtherTeacherList(res);
        },
        () => { }
      )
    );
  };
  const onChangeOtherTeacher = (value: any) => {
    setOtherTeacher(value?.empId);
  };
  const onChangeTeacher = (value: any) => {
    setTeacher(value?._id);
  };
  const onChangeDemoTime = (value: any) => {
    setDemoTime(value);
    const postData = {
      date: date,
      // time: convertTimeTo24HourFormat(value?.name),
      time: value,
      timezoneId: timeZone?.timezoneId,
      countryId: data?.countryId,
      subjectId: subject?._id,
    };
    dispatch(
      getPreferTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res);
        },
        () => { }
      )
    );
  };

  const onPressFeedBack = (value: any) => {
    setOpenFeedBackPopup(true);
  };
  const onClickOther = (value: any) => {
    setClickOther(true);
    setTeacher("");
    setResetTeacher(true);

    const postData = {
      timezoneId: timeZone?._id,
      countryId: data?.countryId,
      subjectId: subject?._id,
    };
    dispatch(
      getOtherTeacherList(
        postData,
        (res: any) => {
          setOtherTeacherList(res);
        },
        () => { }
      )
    );
  };

  const onClickAlignTeacher = (value: any) => {
    const postData = {
      demoReqId: data?.demoReqId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      parentEmail: data?.parentEmail,
      mobile: data?.mobile,
      currentSchool: data?.schoolName,
      teacherId: teacher ? teacher : otherTeacher,
      gradeId: data?.gradeId,
      countryCode: data?.countryCode,
      countryName: data?.countryName,
      countryId: data?.countryId,
      date: date,
      time: demoTime,
      timezoneId: timeZone?.timezoneId,
      parentName: data?.parentName,
      examtypeId: examType?._id,
      coursetypeId: data?.coursetypeId,
      subjectId: subject?._id,
      statusId: 2,
      sourceId: data?.sourceId,
      schoolId: data?.schoolId,
    };
    if (
      postData["firstName"] === null ||
      postData["firstName"] === undefined ||
      postData["firstName"] === ""
    ) {
      delete postData["firstName"];
    }
    if (
      postData["lastName"] === null ||
      postData["lastName"] === undefined ||
      postData["lastName"] === ""
    ) {
      delete postData["lastName"];
    }
    if (
      postData["currentSchool"] === null ||
      postData["currentSchool"] === undefined ||
      postData["currentSchool"] === ""
    ) {
      delete postData["currentSchool"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["parentEmail"] === null ||
      postData["parentEmail"] === undefined ||
      postData["parentEmail"] === ""
    ) {
      delete postData["parentEmail"];
    }
    if (
      postData["parentName"] === null ||
      postData["parentName"] === undefined ||
      postData["parentName"] === ""
    ) {
      delete postData["parentName"];
    }
    if (
      postData["subjectId"] === null ||
      postData["subjectId"] === undefined ||
      postData["subjectId"] === ""
    ) {
      delete postData["subjectId"];
    }
    if (
      postData["schoolId"] === null ||
      postData["schoolId"] === undefined ||
      postData["schoolId"] === ""
    ) {
      delete postData["schoolId"];
    }
    dispatch(
      alignDemo(
        postData,
        (res: any) => {
          handleClose();
          getDemoReq();
          setResetTeacher(true);
          setResetOtherTeacher(true);
          setDate("");
          setTime("");
        },
        () => { }
      )
    );
  };

  const onChangeTimeZone = (value: any) => {
    setTimeZone(value);
    setResetTimezone(false);
  };

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value);
    setResetExamType(false);
    const postData: any = {
      coursetypeId: data?.coursetypeId,
      examtypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="demoLeadsPopup ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Demo Booking</h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCardLeads mt-0">
              <div className="row mt-2 ">
                <div className="d-flex">
                  <div className="col-md-7">
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Student Name:</div>
                      <div className="font16 mb-2 ms-2">
                        {" "}
                        {`${data?.firstName} ${data?.lastName}`}
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Course Type:</div>
                      <div className="font16 mb-2 ms-2">
                        {data?.coursetypeName}
                      </div>
                    </div>

                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Country:</div>
                      <div className="font16 mb-2 ms-2">
                        {data?.countryName}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-5 ms-4">
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Phone:</div>
                      <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                    </div>
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Email:</div>
                      <div className="font16 mb-2 ms-2"> {data?.email}</div>
                    </div>
                  </div>
                </div>

                {pageName === "salesPanelDemoConducted" ? (
                  <div className="col-md-5">
                    <h4 className="px-5 ms-5">Feedback</h4>
                    <div className="d-flex justify-content-center mt-2 ms-2">
                      <Addmorebtn
                        name="View Feedback"
                        handleClick={onPressFeedBack}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-12  border-top align-items-center ">
                <>
                  {pageName === "MarketingLandingpage" ||
                    pageName === "counsellingleads" ? (
                    <div className="col-md-10 d-flex Justify-content-between mt-4 ">
                      <div className="col-3 align-item-center d-flex">
                        <LabelField lableName={"Exam Type"} />
                      </div>
                      <div className="col-5 ps-2 ">
                        <CustomDropdown
                          lableName="Select Exam Type"
                          setInputText={(value: {
                            _id: string;
                            name: string;
                          }) => onChangeExamType(value)}
                          value={examType}
                          options={examList}
                          reset={resetExamType}
                        />
                      </div>
                    </div>
                  ) : null}
                  <div className="col-md-10 d-flex Justify-content-between mt-4 ">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Subject"} />
                    </div>
                    <div className="col-5 ps-2 ">
                      <CustomDropdown
                        lableName="Select Subject"
                        setInputText={(value: any) => onChangeSubject(value)}
                        value={subject}
                        options={subjectList}
                        reset={resetSubject}
                      />
                    </div>
                  </div>
                  <div className="col-md-10 d-flex Justify-content-between mt-4 ">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Time Zone"} />
                    </div>
                    <div className="col-5 ps-2 ">
                      <CustomDropdown
                        lableName="Select Time Zone"
                        value={timeZone}
                        options={timeZoneList}
                        reset={resetTimezone}
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTimeZone(value)
                        }
                      />
                    </div>
                  </div>
                  <div className="col-md-10 d-flex Justify-content-between mt-4 ">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Demo Date"} />
                    </div>
                    <div className="col-5 ">
                      <DatePicker
                        value={startDate}
                        setInputText={(value: string) => setStartDate(value)}
                        mindate={mindate}
                      />
                    </div>
                  </div>
                  <div className="col-md-10 d-flex Justify-content-between mt-4 ">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Demo Time"} />
                    </div>
                    <div className="col-5 ps-2 ">
                      <Time
                        value={demoTime}
                        setInputText={(value: any) => {
                          onChangeDemoTime(value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-md-10 d-flex mb-0 align-items-center">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Prefered Teacher"} />
                    </div>
                    <div className="col-5 ps-2 justify-content-between">
                      <CustomDropdown
                        lableName="Select Teacher Name"
                        setInputText={(value: any) => onChangeTeacher(value)}
                        value={teacher}
                        options={teacherList}
                        reset={resetTeacher}
                        disabled={clickOther === true}
                        type={"teacher"}
                      />
                    </div>

                    <div className="p-4">
                      <button
                        type="button"
                        className="btn btn-dark mb-0 align-items-center searchButton"
                        onClick={onClickOther}
                      >
                        Other
                      </button>
                    </div>
                  </div>

                  {clickOther ? (
                    <div className="col-md-10 d-flex mb-0 align-items-center">
                      <div className="col-3 align-item-center d-flex">
                        <LabelField lableName={"Other Teacher"} />
                      </div>
                      <div className="col-5 ps-2">
                        <CustomDropdown
                          lableName="Select Teacher Name"
                          setInputText={(value: any) =>
                            onChangeOtherTeacher(value)
                          }
                          value={otherTeacher}
                          options={otherTeacherList}
                          reset={resetOtherTeacher}
                          type={"teacher"}
                        />
                      </div>
                    </div>
                  ) : null}
                </>

                <div className=" d-flex justify-content-center mt-2">
                  <button
                    type="button"
                    className="btn btn-dark mb-3 align-items-center searchButton mt-2"
                    onClick={onClickAlignTeacher}
                  >
                    Align Teacher
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
