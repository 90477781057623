import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import { addBlog, uploadBlogImg } from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import {
  length_Hundred,
  validateIsFileImageType,
  startsWithSpace,
  pdf_size_1MB,
  length_Two,
  validateFullName,
  validateDigitOnly,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import TextEditor from "../../Components/TextEditor";

export default function CreateBlog() {
  const [title, setTitle] = useState<string>("");
  const [blogImage, setBlogImage] = useState<any>("");
  const [uploadBlogImage, setUploadBlogImage] = useState<any>("");
  const [readTime, setReadTime] = useState<any>("");
  const [desc, setDesc] = useState<any>("");

  const dispatch: Function = useDispatch();

  useEffect(() => {}, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      title: title,
      blogImage: uploadBlogImage?.file,
      readingTime: readTime,
      content: desc,
    };

    dispatch(
      addBlog(
        postData,
        (res: any) => {
          setTitle("");
          setBlogImage("");
          setReadTime("");
          setDesc("");
        },
        () => {}
      )
    );
  };

  const onChangeTitle = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setTitle(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBlogImage(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadBlogImg(
          file,
          (res: any) => {
            delete res?.filePath;
            setUploadBlogImage(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeReadTime = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setReadTime(value);
    } else {
      dispatch(showErrorSnackbar("Please digits only"));
      return;
    }
  };
  const onChangeText = (val: string) => {
    setDesc(val);
  };
  function validation() {
    return (
      title === "" ||
      title.length < length_Two - 1 ||
      blogImage === "" ||
      readTime === "" ||
      desc === ""
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE BLOG"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Title"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Title"
                  value={title}
                  onChangeInput={(value: any) => onChangeTitle(value)}
                  maxlength={length_Hundred - 1}
                  minlength={length_Two}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Upload Image"} />
              </div>
              <div className="col-8 ps-2">
                <ImagePickerAndViewer
                  lableName={"Option Image"}
                  value={blogImage}
                  handleChange={(e: any) => handleOptionImageChange(e)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Reading Time"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Reading Time"
                  value={readTime}
                  onChangeInput={(value: any) => onChangeReadTime(value)}
                  maxlength={length_Hundred - 1}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Content"} />
              </div>
              <div className="col-8 ps-2 d-flex">
                <div className="editor_content mb-2">
                  <TextEditor
                    value={desc}
                    onChange={(value: string) => onChangeText(value)}
                    height="vh100"
                    width="vw100"
                  />
                </div>
                <span className="required ms-2 fs-6">*</span>
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"ADD"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
