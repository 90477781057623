import services from '../../Constant/service';
import endpoint from '../../Constant/endpoint';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { updateCommonValues } from './CommonAction';

export const addAdminUser = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.addAdminUser,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    )
  };

export const getDepartment = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getDept,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getRole = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getRole,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getUserRoles = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getUserRoles,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getExamList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getExamList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getAdminPanelUsers = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getAdminPanelUsers,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const filterRoles = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.filterRoles,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addTeacher = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.addTeacher,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    )
  };

export const getRegisterUser = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.getRegisterUser,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };

export const updateAdminUser = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.superAdmin.updateAdminUser + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getTimezone = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getTimezone,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getStudentList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getStudentList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          //dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getPremierVideoId = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getPremierVideoId,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const addLivePremier = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.addLivePremier,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLivePremierList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getLivePremierList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getTimezoneList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.getTimezoneList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };

export const getLivePremierEditData = (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.getLivePremierEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateLivePremier = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.superAdmin.updateLivePremier + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const deleteLivePremier = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.superAdmin.deleteLivePremier + params,
      "",
      (res: { data: { statusCode: number; msg: any; }; }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getLiveStatusUpdate = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getLiveStatusUpdate,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deleteTeacher = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.superAdmin.deleteTeacher + params,
      "",
      (res: { data: { statusCode: number; msg: any; }; }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
export const assignTeacherExam = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.assignTeacherExam,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateTeacherList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.updateTeacherList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          //dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };

export const updateTeacher = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.superAdmin.updateTeacher + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateTeacherStatus = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.superAdmin.updateTeacherStatus + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const updateUserStatus = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.superAdmin.updateUserStatus + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };
/****************************Reco************************** */
export const addRecordedVideo = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.recordedVideo.addRecordedVideo,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };
export const getLiveRecordedList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.recordedVideo.getLiveRecordedList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          //dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getRecordedEditData = (id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.recordedVideo.getRecordedEditData + id,
      "",
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateRecVideo = (params: any, id: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.patchApiCall(
      endpoint.recordedVideo.updateRecVideo + id,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res?.data?.data);
        } else {
          errorCallback(res);
          if (res?.code) {
            // dispatch(showErrorSnackbar(res?.response?.data?.msg));
          } else {
            // dispatch(showErrorSnackbar(res?.data?.msg));
          }
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getRecordedDppList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.recordedVideo.getRecordedDppList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          //dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const deleteRecVideo = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.deleteApiCall(
      endpoint.recordedVideo.deleteRecVideo + params,
      "",
      (res: { data: { statusCode: number; msg: any; }; }) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar(res?.data?.msg));
          callback(res);
        } else {
          errorCallback(res);
          // dispatch(showErrorSnackbar(res?.data?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    );
  };

export const getGeneralPackageList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.recordedVideo.getGeneralPackageList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          // dispatch(showSuccessSnackbar("Success!"));
          callback(res?.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      }, (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error)
      }
    )
  };



export const getGeneralTopicList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.recordedVideo.getGeneralTopicList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          callback(res?.data?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getPackageSessionList = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.recordedVideo.getPackageSessionList,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          //dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getLiveStatusChange = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getLiveStatusChange,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const getAffiliateUsers = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.postApiCall(
      endpoint.superAdmin.getAffiliateUsers,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          callback(res?.data);
          dispatch(showSuccessSnackbar("Success!"));
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };

export const updateAffiliateUser =
  (params: any, id: any, callback: Function, errorCallback: Function): any =>
    async (dispatch: Function, getState: Function) => {
      dispatch(updateCommonValues("loader", true));
      services.patchApiCall(
        endpoint.superAdmin.updateAffiliateUser + id,
        params,
        (res: any) => {
          dispatch(updateCommonValues("loader", false));
          if (res?.data?.statusCode === services?.status_code?.success) {
            callback(res?.data?.data);
          } else {
            errorCallback(res);
          }
        },
        (error: any) => {
          dispatch(updateCommonValues("loader", false));
          errorCallback(error);
        }
      );
    };

