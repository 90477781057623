import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import affiliateSidebarData from "./affiliateSidebarData";
import UserProfile from "./userProfile";
import AffiliateLeads from "./affiliateLeads";
import DemoPayments from "./demoPayments";
import DemoRequest from "./demoRequest";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoCancel from "./demoCancel";
import DemoJoin from "./demoJoin";
export default function AffiliatePanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={affiliateSidebarData?.AffiliateSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<UserProfile />} />
          <Route path="userProfile" element={<UserProfile />} />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          {/* <Route path="affiliateLeads" element={<AffiliateLeads />} /> */}
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoPayments" element={<DemoPayments />} />
          <Route path="demoCancel" element={<DemoCancel />} />
          <Route path="demoJoin" element={<DemoJoin />} />
          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
