import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import dsaUserSidebarData from "./dsaUserSidebarData";
import CreateUser from "./createUser";
import CreateSalesPerson from "./createSalesPerson";
import UpdateSalesPerson from "./updateSalesPerson";
import DemoPayments from "./demoPayments";
import DemoRequest from "./demoRequest";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoCancel from "./demoCancel";
import UpdateUser from "./updateUser";

export default function DsaUserPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={dsaUserSidebarData?.DsaUserSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateUser />} />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="updateUser" element={<UpdateUser />} />
          <Route path="createSalesPerson" element={<CreateSalesPerson />} />
          <Route path="updateSalesPerson" element={<UpdateSalesPerson />} />
          <Route path="demoPayments" element={<DemoPayments />} />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoCancel" element={<DemoCancel />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
