import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  dateFormat,
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SearchButton from "../../Components/searchButton";
import { useDispatch } from "react-redux";
import { getRegisterUser } from "../../Redux/Actions/SuperAdminAction";
import {
  getCountryList,
  getCourseTypelist,
  getExamList,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportButton from "../../Components/exportButton";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import moment from "moment";
import { GradeList } from "../../Utils/dataConstant";

export default function RegisteredUsers() {
  const tableRef = useRef(null);
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);

  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);

  const [users, setUsers] = useState([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);

  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  // const _DATA = usePagination(count, rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    getUserData();
  }, []);

  const getUserData = () => {
    // const postdata: any = {
    //   startDate: startDate,
    //   endDate: endDate,
    //   examtypeId: exam,
    //   countryCode: country?.phonecode,
    //   skip: 0,
    //   limit: 10,
    // };

    // if (exam == "") {
    //   delete postdata["examtypeId"];
    // }
    // if (country?.phonecode === "") {
    //   delete postdata["countryCode"];
    // }

    const Postdata = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      coursetypeId: course?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      skip: 0,
      limit: 10,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }

    dispatch(
      getRegisterUser(
        Postdata,
        (res: any) => {
          setCount(res?.count);
          setUsers(res?.data);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value?._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
  };

  const onChangeExam = (value: { _id: any }) => {
    setExam(value._id);
  };

  const handleEdit = (value: any) => { };

  const handleDelete = (value: any) => { };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }

    if (exam) {
      data["examtypeId"] = exam;
    }
    if (country) {
      data["countryCode"] = country?.phonecode;
    }

    dispatch(
      getRegisterUser(
        data,
        (res: any) => {
          setCount(res?.count);
          setUsers(res?.data);
        },
        () => { }
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const onChangeStartDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) >
      moment(endDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    } else {
      setStartDate(value);
    }
  };

  const onChangeEndDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) <
      moment(startDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("End date should be greater than Start date"));
    } else {
      setEndDate(value);
    }
  };

  const renderListMode = () => {
    return (
      <>
        {users?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={users ? users : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div ref={tableRef}>
          <PaginationTable
            tableData={users ? users : []}
            pagename={"RegisteredUsers"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "FULL NAME",
              "MOBILE NUMBER",
              "EMAIL ADDRESS",
              "EXAM NAME",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getUserData();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"REGISTERED USER"} />
      <form className="text-center" onSubmit={handleSubmit}>
        <div className=" px-2 mx-0 mt-4 row Justify-content-between ">
          <div className="col-md-3 d-flex mb-2 align-items-center ">
            <LabelField lableName={"Start Date:"} />
            <DatePicker
              value={startDate}
              setInputText={(value: string) => onChangeStartDate(value)}
              Isrequired
              maxdate={today}
            />
          </div>

          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"End Date:"} />
            <DatePicker
              value={endDate}
              setInputText={(value: string) => onChangeStartDate(value)}
              Isrequired
              maxdate={today}
            />
          </div>
          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"Country"} />
            <CustomDropdown
              lableName="Select Country"
              setInputText={(value: any) => onChangeCountry(value)}
              value={country}
              options={countryList}
              Isrequired={true}
              reset={resetCountryList}
            />
          </div>
          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"Course Type"} />
            <CustomDropdown
              lableName="Select Course Type Name"
              setInputText={(value: any) => onChangeCourseType(value)}
              value={course}
              options={courseList}
              Isrequired={true}
              reset={resetParentCourse}
            />
          </div>

          {course?.type === 2 ? (
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Student Grade"} />
              <CustomDropdown
                lableName="Select Student Grade"
                setInputText={(value: any) => onChangeStudentGrade(value)}
                value={grade}
                Isrequired={true}
                options={GradeList}
                reset={resetGradeList}
              />
            </div>
          ) : null}
          {course?.type === 1 ? (
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Exam Name "} />
              <CustomDropdown
                lableName="Select Exam Name"
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExam(value)
                }
              />
            </div>
          ) : null}

          <div className="col-md-1">
            <SearchButton name={"Search"} />
          </div>
        </div>
      </form>
      {users?.length !== 0 && users?.length !== 0 ? (
        <div className="pe-5 d-flex justify-content-end">
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <ExportButton name={"Export Details"} />
          </DownloadTableExcel>
        </div>
      ) : (
        ""
      )}
      {renderListMode()}
    </div>
  );
}
