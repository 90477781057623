import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  length_Eight,
  length_One,
  length_Two,
  startsWithSpace,
  validateDigitOnly,
} from "../../Utils/validators";
import { length_Ten } from "../../Utils/validators";
import Addmorebtn from "../../Components/addmorebtn";
import AdminTable from "../../Components/adminTable";
import CustomDropdown from "../../Components/customDropdown";
import {
  getGeneralExamList,
  getGeneralSubjectList,
  getMainCategoryList,
  getRegistrationDetails,
  getSessionList,
  updateCourse,
  getGeneralTeacherList,
  getCountryList,
  bindSubjectList,
  getPackageList,
  getGeneralTimezoneList,
  getCourseTypelist,
  getPackageWebList,
  getTopicList,
  oldManualActivation,
  updateUserProfile,
} from "../../Redux/Actions/AdminAction";
import moment from "moment";
import {
  GradeList,
  courseActivationStatusList,
} from "../../Utils/dataConstant";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";

export default function OldManualActivation() {
  const dispatch: Function = useDispatch();
  const initialVal = [
    {
      subject: "",
      _id: "",
    },
  ];
  const NoOfClases = [
    {
      _id: 10,
      name: "10",
    },
    {
      _id: 15,
      name: "15",
    },
    {
      _id: 25,
      name: "25",
    },
  ];
  const classScheduleArr = [
    {
      _id: "9",
      name: "NineAM",
      time: "9.00AM",
      isSelected: false,
    },
    {
      _id: "10",
      name: "TenAM",
      time: "10.00AM",
      isSelected: false,
    },
    {
      _id: "11",
      name: "ElevenAM",
      time: "11.00AM",
      isSelected: false,
    },
    {
      _id: "12",
      name: "TwelveAM",
      time: "12.00AM",
      isSelected: false,
    },
    {
      _id: "13",
      name: "OnePM",
      time: "1.00PM",
      isSelected: false,
    },
    {
      _id: "14",
      name: "TwoPM",
      time: "2.00PM",
      isSelected: false,
    },
    {
      _id: "15",
      name: "ThreePM",
      time: "3.00PM",
      isSelected: false,
    },
    {
      _id: "16",
      name: "FourPM",
      time: "4.00PM",
      isSelected: false,
    },
    {
      _id: "17",
      name: "FivePM",
      time: "5.00PM",
      isSelected: false,
    },
    {
      _id: "18",
      name: "SixPM",
      time: "6.00PM",
      isSelected: false,
    },
    {
      _id: "19",
      name: "SevenPM",
      time: "7.00PM",
      isSelected: false,
    },
    {
      _id: "20",
      name: "EightPM",
      time: "8.00PM",
      isSelected: false,
    },
    {
      _id: "21",
      name: "NinePM",
      time: "9.00PM",
      isSelected: false,
    },
  ];
  const initdayArr = [
    {
      _id: "Sunday",
      name: "S",
      isSelected: false,
    },
    {
      _id: "Monday",
      name: "M",
      isSelected: false,
    },
    {
      _id: "Tuesday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Wednesday",
      name: "W",
      isSelected: false,
    },
    {
      _id: "Thursday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Friday",
      name: "F",
      isSelected: false,
    },
    {
      _id: "Saturday",
      name: "S",
      isSelected: false,
    },
  ];
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDetailsList, setUserDetailsList] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  const [category, setCategory] = useState<any>("");
  const [categoryList, setCategoryList] = useState([]);
  const [resetCategory, setResetCategory] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [addcourse, setAddcourse] = useState(false);
  const [status, setStatus] = useState<any>("");
  const [resetStatus, setresetStatus] = useState(false);
  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [resetSession, setResetSession] = useState(false);
  const [duration, setDuration] = useState("");
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any>();
  const [resetBatch, setResetBatch] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>();
  const [detailList, setdetailList] = useState(false);
  const [grade, setGrade] = useState<any>("");

  const [IsEditProfile, setIsEditProfile] = useState(false);
  const [studentId, setStudentId] = useState<any>("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [mobNo, setMobNo] = useState("");
  const [email, setEmail] = useState("");
  const [parentName, setParentName] = useState("");
  const [parentEmail, setParentEmail] = useState("");

  const [editStatus, setEditStatus] = useState<any>("");

  const [countryCode, setCountryCode] = useState<any>("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [resetCountryCode, setResetCountryCode] = useState(false);
  const [classScheduleArray, setclassScheduleArray] =
    useState(classScheduleArr);
  const [dayArray, setDayArray] = useState(initdayArr);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryCodeList(res);
        },
        () => {}
      )
    );
  }, []);
  const getData = () => {
    const postData = {
      mobile: phoneNumber,
      countryCode: countryCode?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          if (res !== null) {
            setUserDetailsList(res);
            setdetailList(true);
            setAddcourse(false);
            setIsEditProfile(false);
          } else {
            setdetailList(false);
          }
        },
        () => {}
      )
    );
  };

  const OnchangePatternName = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setPhoneNumber(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };
  const phoneNumberLengthValidation = (number: string) => {
    if (countryCode?._id === 13) {
      return number?.length !== length_Eight;
    } else if (countryCode?._id === 13) {
      return number?.length !== length_Ten;
    }
  };
  const phoneValidations = () => {
    return (
      phoneNumber === "" ||
      phoneNumberLengthValidation(phoneNumber) ||
      countryCode === ""
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    setResetExam(true);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );

    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );

    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetExam(true);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    setGrade(value);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExam = (value: any) => {
    setExam(value);
    setResetExam(false);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);

    const PostData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };

    dispatch(
      bindSubjectList(
        PostData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );

    const postData: any = {
      coursetypeId: course?._id,
      examId: value._id,
    };

    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: any) => {
    setSubject(value);
    setResetSubject(false);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setCategoryList(res);
          if (course?.type === 2) {
            let tempStatus = res?.filter((item: any) => item?._id === 1);
            setCategoryList(tempStatus);
          }
        },
        () => {}
      )
    );

    const postdata: any = {
      coursetypeId: course?._id,
      subjectId: value?._id,
    };
    if (course?.type === 2) {
      postdata["gradeId"] = grade?._id;
    }
    if (course?.type == 1) {
      postdata["examId"] = exam?._id;
    }

    dispatch(
      getPackageList(
        postdata,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSession = (value: any) => {
    setSession(value);
    setResetSession(false);
    setresetStatus(true);
  };
  const onChangeMainCategory = (value: any) => {
    setCategory(value);
    setResetCategory(false);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
  };
  const onChangeBatch = (value: any) => {
    setBatch(value);
    setResetBatch(false);
    setResetSession(true);
    setresetStatus(true);
  };

  const onChangeStatus = (value: any) => {
    setresetStatus(false);
    setStatus(value?.id);

    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res);
        },
        () => {}
      )
    );
  };
  const OnchangeDuration = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setDuration(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setdetailList(true);
    const postData = {
      mobile: phoneNumber,
      countryCode: countryCode?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          if (res !== null) {
            setUserDetailsList(res);
            setdetailList(true);
            setAddcourse(false);
            setIsEditProfile(false);
          } else {
            setdetailList(false);
          }
        },
        () => {}
      )
    );
  };
  const handleAddCourse = (e: any) => {
    e.preventDefault();
    const postData = {
      mobile: phoneNumber,
      packageId: "" + batch?._id,
      subscriptionDetails: duration,
    };

    dispatch(
      oldManualActivation(
        postData,
        (res: any) => {
          setAddcourse(false);
          getData();
        },
        () => {}
      )
    );
  };
  const onPressAdd = () => {
    setAddcourse(true);
  };

  const handleEdit = (value: any) => {
    dispatch(
      getGeneralExamList(
        {
          courseId: value?.Course_type?._id,
        },
        (res: any) => {
          setExamList(res);
          setExam(value?.Exam_Name?._id);
        },
        () => {}
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setCategoryList(res);
          setCategory(value?.Main_Category?._id);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralPackageList(
        {
          examtypeId: value?.Exam_Name?._id,
        },
        (res: any) => {
          setBatchList(res);
          setBatch(value?.Batch_Name?._id);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value?.Exam_Name?._id,
        },
        (res: any) => {
          setSubjectList(res);
          setSubject(value?.Subject?._id);
        },
        () => {}
      )
    );
    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res);
          setSession(value?.Sessions?._id);
        },
        () => {}
      )
    );
    setIsEdit(true);
    setdetailList(false);

    setEditStatus(value?.course_Activation === true ? 1 : 2);
  };
  const onChangeEditStatus = (value: any) => {
    setEditStatus(value?._id === 1 ? true : false);
  };

  const handleupdateCourse = (e: any) => {
    e.preventDefault();
    const postData = {
      mobile: phoneNumber,
      packageId: batch?._id,
      subscriptionDetails: duration,
      isActive: editStatus,
    };
    dispatch(
      updateCourse(
        postData,
        {},
        (res: any) => {
          setIsEdit(false);
          getData();
        },
        () => {}
      )
    );
  };
  const validation = () => {
    return category === "" || subject === "" || course === "";
  };

  const renderEditMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#343a40" }}
        >
          Course Details
        </p>
        <div className="row mx-0">
          <div className="col-md-10 col-lg-10 col-xl-8">
            <form className="col-md-10" onSubmit={handleupdateCourse}>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course type"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Type Name"
                    setInputText={(value: any) => onChangeCourse(value)}
                    value={course}
                    options={courseList}
                    Isrequired={true}
                    reset={resetCourse}
                    defaultValue={userDetailsList?.Course_Details[0]?._id}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className=" col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    defaultValue={exam}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Subject"
                    setInputText={(value: any) => onChangeSubject(value)}
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                    defaultValue={subject}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Main Category"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Main Category"
                    setInputText={(value: any) => onChangeMainCategory(value)}
                    value={category}
                    options={categoryList}
                    Isrequired={true}
                    reset={resetCategory}
                    defaultValue={category}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: any) => onChangeBatch(value)}
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                    defaultValue={batch}
                    disabled={true}
                  />
                </div>
              </div>

              {category?._id === 2 ? (
                <div className=" d-flex mb-2 align-items-center">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Session"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Session"
                      setInputText={(value: any) => onChangeSession(value)}
                      value={session}
                      options={sessionList}
                      Isrequired={true}
                      reset={resetSession}
                      defaultValue={session}
                      disabled={true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Activation"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Activation"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeEditStatus(value)
                    }
                    value={status}
                    options={courseActivationStatusList}
                    Isrequired={true}
                    reset={resetStatus}
                    defaultValue={editStatus}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subscription Duration"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Subscription Duration"
                    value={duration}
                    onChangeInput={(value: { _id: String; name: String }) =>
                      OnchangeDuration(value)
                    }
                    maxlength={length_Two}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Submit"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  const renderAddMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#343a40" }}
        >
          Course Details
        </p>
        <div className="row mx-0">
          <div className="col-md-10 col-lg-10 col-xl-8">
            <form className="col-md-10" onSubmit={handleAddCourse}>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Course type"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Type Name"
                    setInputText={(value: any) => onChangeCourse(value)}
                    value={course}
                    options={courseList}
                    Isrequired={true}
                    reset={resetCourse}
                    defaultValue={userDetailsList?.Course_Details[0]?._id}
                  />
                </div>
              </div>
              {course?.type === 2 ? (
                <div className="d-flex mb-2 align-items-center">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Grade"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      Isrequired={true}
                      options={GradeList}
                    />
                  </div>
                </div>
              ) : null}
              {course?.type === 2 || course?.type === 3 ? null : (
                <div className=" d-flex mb-2 align-items-center">
                  <div className=" col-3 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      reset={resetExam}
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                    />
                  </div>
                </div>
              )}

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Subject"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Subject"
                    setInputText={(value: any) => onChangeSubject(value)}
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Main Category"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Main Category"
                    setInputText={(value: any) => onChangeMainCategory(value)}
                    value={category}
                    options={categoryList}
                    Isrequired={true}
                    reset={resetCategory}
                  />
                </div>
              </div>

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: any) => onChangeBatch(value)}
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                  />
                </div>
              </div>
              {category?._id === 2 && course?.type !== 2 ? (
                <div className=" d-flex mb-2 align-items-center">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Session"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Session"
                      setInputText={(value: any) => onChangeSession(value)}
                      value={session}
                      options={sessionList}
                      Isrequired={true}
                      reset={resetSession}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Subscription Duration"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Subscription Duration"
                    value={duration}
                    onChangeInput={(value: { _id: String; name: String }) =>
                      OnchangeDuration(value)
                    }
                    maxlength={length_Two}
                    minlength={length_One}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className=" d-flex mb-2 align-items-center mt-3">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Course Activation"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Activation"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeStatus(value)
                    }
                    value={status}
                    options={courseActivationStatusList}
                    Isrequired={true}
                    reset={resetStatus}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Submit"} validation={validation()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  const editProfile = () => {
    setIsEditProfile(true);
    setStudentId(userDetailsList?.studentId);
    setFirstName(userDetailsList?.firstName);
    setLastName(userDetailsList?.lastName);
    setMobNo(userDetailsList?.mobile);
    setEmail(userDetailsList?.email);
    setParentName(userDetailsList?.parentName);
    setParentEmail(userDetailsList?.parentEmail);
  };

  const renderEditProfile = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#343a40" }}
        >
          Edit Profile
        </p>
        <div className="row mx-0">
          <div className="col-7">
            <form onSubmit={handleUpdateProfile}>
              <div className="col-auto">
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"First Name"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter First Name"}
                      value={firstName}
                      key={"firstName"}
                      onChangeInput={(value: any) => setFirstName(value)}
                      maxlength={50}
                      minlength={1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Last Name"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter Last Name"}
                      value={lastName}
                      key={"lastName"}
                      onChangeInput={(value: any) => setLastName(value)}
                      maxlength={50}
                      minlength={1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Mobile Number"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter Mobile Number"}
                      value={mobNo}
                      key={"mobNo"}
                      onChangeInput={(value: any) => setMobNo(value)}
                      maxlength={10}
                      minlength={10}
                      disabled={true}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Email"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter Email"}
                      value={email}
                      key={"email"}
                      onChangeInput={(value: any) => setEmail(value)}
                      maxlength={100}
                      minlength={1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Parent Name"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter Parent Name"}
                      value={parentName}
                      key={"parentName"}
                      onChangeInput={(value: any) => setParentName(value)}
                      maxlength={50}
                      minlength={1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Parent Email"} />
                  </div>
                  <div className="col-6 mt-0">
                    <InputField
                      placeholder={"Enter Parent Email"}
                      value={parentEmail}
                      key={"parentEmail"}
                      onChangeInput={(value: any) => setParentEmail(value)}
                      maxlength={100}
                      minlength={1}
                    />
                  </div>
                </div>

                <div className="d-flex justify-content-evenly mt-2">
                  <Submitbtn
                    name={"Save and Update"}
                    validation={profileValidation()}
                  />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };

  const profileValidation = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      mobNo === "" ||
      phoneNumberLengthValidation(mobNo) ||
      email === "" ||
      parentName === "" ||
      parentEmail === ""
    );
  };

  const handleUpdateProfile = (e: any) => {
    e.preventDefault();
    const PostData = {
      firstName: firstName.toString(),
      lastName: lastName.toString(),
      email: email.toString(),
      parentName: parentName.toString(),
      parentEmail: parentEmail.toString(),
    };

    dispatch(
      updateUserProfile(
        studentId,
        PostData,
        () => {
          const postData = {
            mobile: phoneNumber,
            countryCode: countryCode?.phonecode?.toString(),
          };
          if (
            postData["countryCode"] === null ||
            postData["countryCode"] === undefined ||
            postData["countryCode"] === ""
          ) {
            delete postData["countryCode"];
          }
          dispatch(
            getRegistrationDetails(
              postData,
              (res: any) => {
                if (res !== null) {
                  setUserDetailsList(res);
                  setdetailList(true);
                  setAddcourse(false);
                  setIsEditProfile(false);
                } else {
                  setdetailList(false);
                }
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const renderUserDetails = () => {
    return (
      <div>
        <>
          <p
            className="p-1 mb-2 text-white mb-4"
            style={{ backgroundColor: "#343a40" }}
          >
            Personal & Contact
          </p>
          <div className="px-3 text-end">
            <button
              className="btn btn-outline-primary"
              style={{ fontSize: "xx-small" }}
              onClick={editProfile}
            >
              Edit Profile
            </button>
          </div>
          <div className="row d-flex mx-0">
            <div className="col-4 d-flex ps-4">
              <p className="col-4"> First Name</p>
              <p className="col-8"> {userDetailsList?.firstName} </p>
            </div>
            <div className=" col-4 d-flex ps-4">
              <p className="col-4"> Last Name</p>
              <p className="col-8"> {userDetailsList?.lastName} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Contact</p>
              <p className="col-8"> {userDetailsList?.mobile} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Email Address</p>
              <p className="col-8"> {userDetailsList?.email} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Status</p>
              <p className="col-8">
                {userDetailsList?.isActive === true ? "Yes" : "No"}
              </p>
            </div>

            <div className="col-4 d-flex ps-4">
              <p className="col-4">Active Plan</p>
              <p className="col-8">
                {" "}
                {userDetailsList?.ActivePlan === true ? "Yes" : "No"}
              </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Is Mobile Verify</p>
              <p className="col-8">
                {userDetailsList?.isRegistered === true ? "Yes" : "No"}
              </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4"> Parent Name</p>
              <p className="col-8"> {userDetailsList?.parentName} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4"> Parent Email</p>
              <p className="col-8"> {userDetailsList?.parentEmail} </p>
            </div>
          </div>

          <p
            className="p-1 mb-2 text-white mb-4"
            style={{ backgroundColor: "#343a40" }}
          >
            <div className="d-flex justify-content-between me-2 align-items-center">
              <span>Course Details</span>
            </div>
          </p>
          {userDetailsList?.Course_Details?.map((item: any, index: any) => {
            return (
              <div className="row d-flex mx-0">
                <div className="col-6 d-flex ps-4">
                  <p className="col-4">Course</p>
                  <p className="col-8">{item?.name}</p>
                </div>
                <div className=" col-6 d-flex ps-4">
                  <p className="col-4">Registration Date</p>
                  <p className="col-8">
                    {}
                    {moment(item?.Registration_Date).format("DD - MM - YYYY")}
                  </p>
                </div>
              </div>
            );
          })}
          <div>
            <p
              className="p-1 mb-2 text-white mb-4"
              style={{ backgroundColor: "#343a40" }}
            >
              Subscription Details
            </p>
            <AdminTable
              tableData={userDetailsList?.Subscription_Details}
              pagename={"registrationDetails"}
              tableHead={[
                "S.N",
                "Exam Name",
                "Batch Name",
                "Course Activation",
                "Activation End Date",
                "Actions",
              ]}
              edit={true}
              handleEdit={(value: any) => handleEdit(value)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Addmorebtn name={"Add More"} handleClick={onPressAdd} />
          </div>
        </>
      </div>
    );
  };

  const onChangeCountry = (value: any) => {
    setCountryCode(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"OLD MANUAL ACTIVATION"}
        isEditMode={isEdit}
        setisEditMode={setIsEdit}
      />
      <div className="px-4 mx-0 pt-4 formDiv ">
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between">
            <div className="col-6 d-flex mb-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Phone Number"} />
              </div>
              <div className="col-12 d-flex">
                <div className="col-4 ps-0">
                  <CustomDropdown
                    lableName="Select Country Code"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={countryCode}
                    options={countryCodeList}
                    Isrequired={true}
                    reset={resetCountryCode}
                    defaultValue={countryCode?._id}
                  />
                </div>
                <div className="col-6 pe-2">
                  <InputField
                    placeholder="Enter Phone Number"
                    value={phoneNumber}
                    onChangeInput={(value: { _id: String; name: String }) =>
                      OnchangePatternName(value)
                    }
                    maxlength={
                      countryCode?._id === 13 ? length_Eight : length_Ten
                    }
                    minlength={
                      countryCode?._id === 13 ? length_Eight : length_Ten
                    }
                  />
                </div>
                <div className="mb-2">
                  <Submitbtn name={"Search"} validation={phoneValidations()} />
                </div>
              </div>
            </div>
          </div>
        </form>
        {!addcourse && userDetailsList && detailList && !IsEditProfile ? (
          <div>{renderUserDetails()}</div>
        ) : IsEditProfile ? (
          renderEditProfile()
        ) : (
          ""
        )}
        {addcourse ? <div>{renderAddMode()}</div> : ""}
        {isEdit ? <>{renderEditMode()}</> : ""}
      </div>
    </div>
  );
}
