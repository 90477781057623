import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import AdminTable from '../../Components/adminTable';
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import { priorityOrderData } from '../../Utils/dataConstant';
import CustomDropdown from "../../Components/customDropdown";
import ToggleSwitchbtn from '../../Components/toggleSwitchBtn';
import { deleteSection, getSectionList, getSectionTypesList, updateSection } from '../../Redux/Actions/AdminAction';
import { useDispatch } from 'react-redux';
import { startsWithSpace, length_Two, validateFullName } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function UpdateSection() {
  const dispatch: Function = useDispatch();
  const [p_order, setP_order] = useState<any>();
  const [sectionTypeList, setSectionTypeList] = useState([]);
  const [sectionVal, setSectionVal] = useState<any>("");
  const [section, setSection] = useState<string>("");
  const [toggle, setToggle] = useState(false);
  const [resetPriorityOrder, setResetPriorityOrder] = useState(false);
  const [resetType, setResetType] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>();
  const [sectionList, setSectionList] = useState<string>("");
  const [sectionId, setSectionId] = useState<any>("");
  const [sectionArr, setSectionArr] = useState<any>("");

  useEffect(() => {
    updatedata();
  }, []);

  useEffect(() => {

    dispatch(
      getSectionTypesList(
        {},
        (res: any) => {
          setSectionTypeList(res)
        },
        () => { }
      )
    );
  }, []);

  const updatedata = () => {

    dispatch(
      getSectionList(
        "",
        (res: any) => {
          setSectionList(res)
        },
        () => { }
      )
    );
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const onChangeSection = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setSection(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }
  const handleEdit = (value: any) => {
    setIsEdit(true);
    setSection(value?.name);
    setP_order(value?.order);
    setSectionVal(value?.type);
    setToggle(value?.isHomePage);
    setSectionId(value._id);
    setSectionArr(value)

  }

  const handleDelete = (value: any) => {
    dispatch(
      deleteSection(
        value._id,
        () => {
          updatedata();
        },
        () => { }
      )
    );

  }
  const handleOnEdit = (e: any) => {
    e.preventDefault();

    const postData: any = {
    }
    if (sectionArr?.name != section) {
      postData["name"] = section
    }
    if (sectionArr?.order != p_order._id) {
      postData["order"] = p_order._id
    }
    if (sectionArr?.type != sectionVal?._id) {
      postData["type"] = sectionVal?._id
    }
    if (sectionArr?.isHomePage != toggle) {
      postData["isHomePage"] = toggle
    }

    dispatch(
      updateSection(
        postData,
        sectionId,
        (res: any) => {
          setIsEdit(false);
          updatedata();

        }, () => { }
      )
    );

  };
  const onChangePriorityOrder = (value: any) => {
    setP_order(value);
    setResetPriorityOrder(false)
  };
  const onChangeType = (value: any) => {
    setSectionVal(value);
    setResetType(false);
  };

  function validation() {
    return (
      section === "" || section.length < length_Two || p_order === "" || sectionVal === ""

    );
  }
  return (
    <div className="contentBox">
      <PageTitle name={"EDIT SECTION"} isEditMode={isEdit} setisEditMode={setIsEdit} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        {isEdit !== true ? <AdminTable
          tableData={sectionList ? sectionList : []}
          pagename={"UpdateSection"}
          tableHead={["S.N", "Section", "Priority Order", "Type", "Home Page Visibility", "Action"]}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          edit={true}
        /> : ""}

        {isEdit === true ? <div>
          <form className='col-10' onSubmit={handleOnEdit}>

            <div className='d-flex mb-2 align-items-center'>
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Section Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Section Name"
                  value={section}
                  onChangeInput={(value: any) => onChangeSection(value)}
                  maxlength={100}
                  minlength={2}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Priority Order"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Priority Order"
                  setInputText={(value: any) =>
                    onChangePriorityOrder(value)
                  }
                  value={p_order}
                  defaultValue={p_order}
                  options={priorityOrderData}
                  Isrequired={true}
                  reset={resetPriorityOrder}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Type"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Panel Role"
                  setInputText={(value: any) =>
                    onChangeType(value)
                  }
                  value={sectionVal}
                  disabled={true}
                  defaultValue={sectionVal}
                  options={sectionTypeList}
                  Isrequired={true}
                  reset={resetType}
                />
              </div>
            </div>
            <div className="">
              <ToggleSwitchbtn
                lableName='Home Page'
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
                isActive={toggle}
              />
            </div>




            <div className='d-flex justify-content-center mt-3'>
              <Submitbtn name={"Save & Update"} validation={validation()} />
            </div>
          </form>
        </div> : ""}
      </div>
    </div>
  )
}

