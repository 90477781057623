import { useEffect, useRef, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import { today } from "../../Utils/appConstants";
import SubmitButton from "../../Components/searchButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import {
  PayoutSave,
  getCountryList,
  getPayoutList,
} from "../../Redux/Actions/AdminAction";
import { updateTeacherList } from "../../Redux/Actions/SuperAdminAction";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportButton from "../../Components/exportButton";

export default function PaymentPayout() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(today);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [payoutList, setPayoutList] = useState<any>([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetteacher, setResetTeacher] = useState<any>(false);
  const [toggle, setToggle] = useState("Pending");
  const [allCheck, setAllCheck] = useState(false);
  const [checkall, setCheckall] = useState(false);
  const tableRef = useRef(null);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    const postData = {};
    dispatch(
      updateTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => { }
      )
    );
  }, []);

  const tab = [
    {
      _id: "1",
      name: "Pending",
    },
    {
      _id: "2",
      name: "Paid",
    },
  ];

  const handleChangetab = (val: any) => {
    setToggle(val);
    if (val === "Pending") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        empId: teacher?.empId,
      };
      dispatch(
        getPayoutList(
          data,
          (res: any) => {
            setPayoutList(res);
            let unpaidList = res?.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            if (teacher?.empId === undefined) {
              setCheckall(false);
            } else {
              setCheckall(true);
            }
            setPayoutList(unpaidList);
          },
          () => { }
        )
      );
    } else if (val === "Paid") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        type: "LIVE",
        countryId: country?._id,
        empId: teacher?.empId,
      };
      dispatch(
        getPayoutList(
          data,
          (res: any) => {
            setPayoutList(res);
            let paidList = res?.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setCheckall(false);
            setPayoutList(paidList);
          },
          () => { }
        )
      );
    }
  };

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
  };
  const onChangeteacher = (value: any) => {
    if (value === null) {
      setAllCheck(false);
    } else {
      setAllCheck(true);
    }
    setTeacher(value);
    setPayoutList([]);
  };
  const handleOpenPayPopUp = (value: any) => {
    let selectedCheckboxes = payoutList.filter(
      (elm: any, idx: any) => elm.isSelected === true
    );
    const postdata = {
      payout: selectedCheckboxes,
    };
    dispatch(
      PayoutSave(
        postdata,
        (res: any) => {
          reloadList();
        },
        () => { }
      )
    );
  };

  const handleCheck = (value: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(payoutList));
    if (value.target.checked) {
      newArray[index]["isSelected"] = true;
      setPayoutList(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      setPayoutList(newArray);
    }
  };

  const handleAllCheck = (value: any) => {
    let newArray = JSON.parse(JSON.stringify(payoutList));

    if (value.target.checked) {
      newArray.map((item: any) => {
        Object.assign(item, { isSelected: value.target.checked });
        setAllCheck(value.target.checked);
        return item;
      });
    } else {
      newArray.map((item: any) => {
        Object.assign(item, { isSelected: value.target.checked });
        setAllCheck(value.target.checked);
        return item;
      });
    }
    setPayoutList(newArray);
  };
  let sum: any = 0;
  for (let i = 0; i < payoutList.length; i++) {
    sum += payoutList[i].Amount;
  }
  const renderListMode = () => {
    return (
      <div className="p-2 table-responsive">
        <div className="col-md-12 p-2 border-end ">
          <div className=" d-flex  justify-content-start ">
            <span className="fw-bold">Total Amount:</span>
            <h5 className="fw-bold ms-2">{parseFloat(sum).toFixed(2)}</h5>
          </div>
          <div className="d-flex mt-5  border-bottom  ">
            {tab?.map((c, index) => (
              <div
                className={`col-md-4 fs-6 text-center cursor  ${toggle === c.name
                    ? " text-primary border-bottom border-primary border-3 fw-bold"
                    : "text-secondary fw-bold"
                  }`}
                onClick={() => handleChangetab(c.name)}
              >
                {c.name}
              </div>
            ))}
          </div>
        </div>
        <table className="table table-bordered text-center " ref={tableRef}>
          <thead className="table-primary ">
            <tr className="p-3 text-start col-2">
              {checkall === true ? (
                <th>
                  <span>
                    Check All
                    <input
                      className="check ms-1"
                      type="checkbox"
                      onChange={(value) => {
                        handleAllCheck(value);
                      }}
                    />
                  </span>
                </th>
              ) : null}
              {checkall === false ? <th className="p-3 "></th> : null}
              <th className="p-2 text-start">S.N</th>
              <th className="p-2 text-start col-2">Course Type</th>
              <th className="p-2 text-start col-2">Teacher Name</th>
              <th className="p-2 text-start col-2">Class Title</th>
              <th className="p-2 text-start col-2">Class Date</th>
              <th className="p-2 text-start col-2">Actual Start Time</th>
              <th className="p-2 text-start col-2">Delayed/Early </th>
              <th className="p-2 text-start col-2">
                Total Class Time (in min)
              </th>
              <th className="p-2 text-start col-2">DPP Status </th>
              <th className="p-2 text-start col-2">Recorded Status</th>
              <th className="p-2 text-start col-2"> Per Class Charges</th>
              <th className="p-2 text-start col-2"> Amount</th>
              <th className="p-2 text-start col-2">Pay</th>
            </tr>
          </thead>
          {payoutList.length > 0 ? (
            <tbody>
              {payoutList?.map((value: any, index: any) => {
                return (
                  <>
                    <tr>
                      <div className="d-flex col-2">
                        {value?.status === "paid" ||
                          toggle === "Paid" ? null : (
                          <>
                            <td>
                              <span>
                                <input
                                  className="check"
                                  type="checkbox"
                                  checked={value.isSelected}
                                  onChange={(value) => {
                                    handleCheck(value, index);
                                  }}
                                />
                              </span>
                            </td>
                          </>
                        )}
                      </div>
                      <td className="text-start ">{index + 1}</td>
                      <td className="text-start col-2">
                        {value?.coursetypeName ? value?.coursetypeName : "-"}
                      </td>
                      <td className="text-start col-1">{value?.name}</td>
                      <td className="text-start col-2"> {value?.title}</td>
                      <td className="text-start col-2"> {value?.classDate}</td>
                      <td className="text-start col-2">
                        {" "}
                        {value?.actualStartTime}
                      </td>
                      {value?.delayMinutes ? (
                        <td
                          className={
                            value?.delayMinutes?.includes("-")
                              ? "text-danger fw-bold"
                              : "text-success fw-bold"
                          }
                        >
                          {" "}
                          {value?.delayMinutes} Min
                        </td>
                      ) : (
                        <td className=" col-2">-</td>
                      )}

                      <td className="text-start col-2">
                        {value?.totalClassTimeMinutes}
                      </td>
                      <td className="text-start col-2">
                        {value?.DPP_Uploaded === true
                          ? "Uploaded"
                          : "Not Uploaded"}
                      </td>
                      <td className="text-start col-2">
                        {value?.recordedStatus === true
                          ? "Uploaded"
                          : "Not Uploaded"}
                      </td>
                      <td className="text-start col-2">
                        {value?.charges ? value?.charges : "-"}
                      </td>
                      <td className="text-start col-2">
                        {value?.Amount
                          ? parseFloat(value?.Amount).toFixed(2)
                          : "-"}
                      </td>
                      <td className="text-start col-2">
                        {value?.status === "paid" ? value?.status : "Unpaid"}
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <td className="fw-bold p-1">No Record Found</td>
            </tbody>
          )}
        </table>
        {payoutList.length > 0 && toggle === "Pending" ? (
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-secondary"
              onClick={(value) => handleOpenPayPopUp(value)}
            >
              Submit
            </button>
          </div>
        ) : null}
      </div>
    );
  };

  const reloadList = () => {
    const data = {
      startDate: startDate,
      endDate: endDate,
      type: "LIVE",
    };
    dispatch(
      getPayoutList(
        data,
        (res: any) => {
          setPayoutList(res);
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
      type: "LIVE",
      countryId: country?._id,
      empId: teacher?.empId,
    };
    if (
      data["empId"] === null ||
      data["empId"] === undefined ||
      data["empId"] === ""
    ) {
      setCheckall(false);
    } else {
      setCheckall(true);
    }

    dispatch(
      getPayoutList(
        data,
        (res: any) => {
          setPayoutList(res);
          if (toggle === "Pending") {
            let unpaidList = res.filter(
              (elm: any, idx: any) => elm.status !== "paid"
            );
            if (teacher?.empId === undefined) {
              setCheckall(false);
            } else {
              setCheckall(true);
            }
            setPayoutList(unpaidList);
          } else if (toggle === "Paid") {
            let paidList = res.filter(
              (elm: any, idx: any) => elm.status === "paid"
            );
            setCheckall(false);
            setPayoutList(paidList);
          }
        },
        (error: any) => {
          setPayoutList(error?.data);
        }
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PAYMENT PAYOUT"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-2 d-flex mb-2 align-items-center ms-5">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                reset={resetCountryList}
              />
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Teachers Name"} />
              <CustomDropdown
                lableName="Select Teacher Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeteacher(value)
                }
                value={teacher}
                options={teacherList}
                reset={resetteacher}
              />
            </div>

            <div className="d-flex col-md-2  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        {payoutList?.length > 0 ? (
          <div className="d-flex justify-content-end">
            <DownloadTableExcel
              filename="Payment Status"
              sheet="users"
              currentTableRef={tableRef.current}
            >
              <ExportButton name={"Export Details"} />
            </DownloadTableExcel>
          </div>
        ) : null}
        <div className="shadow-lg  m-2 mt-5  ">{renderListMode()}</div>
      </div>
    </div>
  );
}
