import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { getDepartment } from "../../Redux/Actions/SuperAdminAction";
import SubmitButton from "../../Components/submitButton";
import {
  validateFullName,
  validateIsNumberOnly,
  startsWithSpace,
  hasWhiteSpace,
  emailCheck,
  length_Ten,
  length_Six,
  length_Hundred,
  length_Eight,
} from "../../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../../Redux/Actions/snackbarAction";
import {
  bindSubjectList,
  checkUser,
  createRegisterUser,
  getCountryList,
  getCourseTypelist,
  getDsaManagerList,
  getGeneralExamList,
  getGeneralTimezoneList,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import { GradeList, demoTimeList } from "../../Utils/dataConstant";
import TimeHoursMinutes from "../../Components/timeHoursMinutes";

export default function CreateUser() {
  const dispatch: Function = useDispatch();
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentList, setDepartmentList] = useState([]);
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);

  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);

  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);

  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);

  const [parentName, setParentName] = useState<string>("");
  const [parentMobileNo, setParentMobileNo] = useState<any>("");
  const [pEmail, setPEmail] = useState<any>("");
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [userVerify, setUserVerify] = useState<any>("");
  const [manager, setManager] = useState<any>("");
  const [joiningTime, setJoiningTime] = useState<any>("");
  const [companyId, setCompanyId] = useState<any>("");
  const [countryId, setCountryId] = useState<any>("");

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 16);
          setDepartmentList(tempStatus);
        },
        () => {}
      )
    );
    setDepartmentList(Object(departmentList));
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postdata = {
      empId: userId,
    };
    dispatch(
      getDsaManagerList(
        postdata,
        (res: any) => {
          setManager(res?.data[0]?._id);
          setCountryId(res?.data[0]?.countryId);
          setCompanyId(res?.data[0]?.companyId);
        },
        () => {}
      )
    );
  }, []);

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeParentMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setParentMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  // const onClickverify = (val: any) => {
  //   dispatch(
  //     checkUser(
  //       { mobile: mobileNo },
  //       (res: any) => {
  //         setUserVerify(res?.msgCode);
  //         if (res?.msgCode === "507") {
  //           dispatch(showErrorSnackbar(res?.msg));
  //         } else if (res?.msgCode === "550") {
  //           dispatch(showSuccessSnackbar(res?.msg));
  //         }
  //       },
  //       () => {}
  //     )
  //   );
  // };
  const onChangeFirstName = (value: any) => {
    if (validateFullName(value)) {
      setFirstName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  const onChangeLastName = (value: any) => {
    if (validateFullName(value)) {
      setLastName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
  };
  const onChangeTimeZone = (value: any) => {
    setTimeZone(value);
    setResetTimezone(false);
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };

  const onChangeJoiningTime = (value: any) => {
    let newValue = value + ":00";
    setJoiningTime(newValue);
  };

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const onChangeParentEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setPEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }

  const onChangeParentName = (value: any) => {
    if (validateFullName(value)) {
      setParentName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData = {
        firstName: firstName,
        lastName: lastName,
        mobile: mobileNo,
        email: email,
        countryId: country?._id,
        countryCode: country?.phonecode.toString(),
        timezoneId: timeZone?.timezoneId,
        coursetypeId: course?._id,
        gradeId: grade?._id,
        examtypeId: exam,
        subjectId: subject,
        date: dateOfJoining,
        time: joiningTime,
        parentName: parentName,
        parentNumber: parentMobileNo,
        parentEmail: pEmail,
        sourceId: 2,
        refId: userId,
        dsaId: manager,
        companyId: companyId,
      };
      if (
        postData["examtypeId"] === null ||
        postData["examtypeId"] === undefined ||
        postData["examtypeId"] === ""
      ) {
        delete postData["examtypeId"];
      }
      if (
        postData["gradeId"] === null ||
        postData["gradeId"] === undefined ||
        postData["gradeId"] === ""
      ) {
        delete postData["gradeId"];
      }
      if (
        postData["parentEmail"] === null ||
        postData["parentEmail"] === undefined ||
        postData["parentEmail"] === ""
      ) {
        delete postData["parentEmail"];
      }
      if (
        postData["parentNumber"] === null ||
        postData["parentNumber"] === undefined ||
        postData["parentNumber"] === ""
      ) {
        delete postData["parentNumber"];
      }
      dispatch(
        createRegisterUser(
          postData,
          (res: any) => {
            setFirstName("");
            setLastName("");
            setMobileNo("");
            setEmail("");
            setResetCountryList(true);
            setResetTimezone(true);
            setResetParentCourse(true);
            setResetGradeList(true);
            setresetExam(true);
            setResetSubject(true);
            setDateOfJoining("");
            setJoiningTime("");
            setParentName("");
            setParentMobileNo("");
            setPEmail("");
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  const validation = () => {
    return (
      firstName === "" ||
      lastName === "" ||
      country === "" ||
      timeZone === "" ||
      course === "" ||
      subject === "" ||
      mobileNo === "" ||
      mobileNo?.length < length_Eight ||
      email === "" ||
      dateOfJoining === "" ||
      joiningTime === "" ||
      parentName === ""
      //  ||
      // userVerify === "507"
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE DEMO SCHEDULE"} />
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-10 px-4 mx-0 mt-4 ">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"First Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => onChangeFirstName(value)}
                maxlength={length_Hundred}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Last Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => onChangeLastName(value)}
                maxlength={length_Hundred}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Time Zone"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Time Zone"
                value={timeZone}
                options={timeZoneList}
                Isrequired={true}
                reset={resetTimezone}
                setInputText={(value: any) => onChangeTimeZone(value)}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name "} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
          )}

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Mobile Number"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChangeInput={(value: any) => onChangeMobileNO(value)}
                maxlength={length_Ten}
                minlength={length_Six}
                isRequired
              />
            </div>
            {/* <div className="col-6">
              <button
                type="button"
                className={
                  mobileNo?.length === 10
                    ? "btn btn-success searchButton font12 mb-2 ms-2 "
                    : "btn btn-outline-secondary searchButton font12 mb-2 ms-2"
                }
                onClick={onClickverify}
              >
                Verify Number
              </button>
            </div> */}
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Email Address"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => onChangeEmail(value)}
                maxlength={length_Hundred}
                isRequired
              />
            </div>
          </div>

          <div className=" d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Demo Date & Time"} />
            </div>
            <div className="col-8 d-flex ">
              <div className="col-4 p-2">
                <DatePicker
                  value={dateOfJoining}
                  setInputText={(value: string) => setDateOfJoining(value)}
                  Isrequired={true}
                />
              </div>
              <div className="col-5 ms-2 p-2">
                <TimeHoursMinutes
                  value={joiningTime}
                  setInputText={(value: string) => onChangeJoiningTime(value)}
                  Isrequired={true}
                />
              </div>
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Parent Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Parent Name"
                value={parentName}
                onChangeInput={(value: any) => onChangeParentName(value)}
                maxlength={length_Hundred}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Parent Mobile Number"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Mobile Number"
                value={parentMobileNo}
                onChangeInput={(value: any) => onChangeParentMobileNO(value)}
                maxlength={length_Ten}
                minlength={length_Six}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Parent Email Address"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Parent Email Address"
                value={pEmail}
                onChangeInput={(value: any) => onChangeParentEmail(value)}
                maxlength={length_Hundred}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <SubmitButton name="Create User" validation={validation()} />
        </div>
      </form>
    </div>
  );
}
