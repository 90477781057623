import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import Submitbtn from "../../Components/submitButton";
import {
  validateIsFileImageType,
  image_size_2MB,
  validateIsFilePDFType,
  pdf_size_1MB,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFilePDFTypeErrMsg,
} from "../../Utils/errorMsg";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  deletePackage,
  getCountlist,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getMainCategoryList,
  getPackageEditData,
  getPackageList,
  getSectionList,
  updatePackage,
  uploadPackageMedia,
} from "../../Redux/Actions/AdminAction";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import { GradeList, homepageList, statusList } from "../../Utils/dataConstant";
import Time from "../../Components/time";

export default function UpdatePackage() {
  const LabelArr = [
    "Level",
    "Offer Lecture Count",
    "Display Name",
    "Selling Price",
    "Offer Price",
    "Priority Order",
    "Recommendation",
  ];

  const initialData = [
    {
      levelId: "1",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
    {
      levelId: "2",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
    {
      levelId: "3",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
  ];

  const dispatch: Function = useDispatch();
  const [isEditMode, setisEditMode] = useState<any>(false);
  const [packageId, setpackageId] = useState<any>("");
  const [packageEditData, setPackageEditData] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [courseType, setCourseType] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [lectureCount, setLectureCount] = useState<any>("");
  const [section, setSection] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [packageName, setPackageName] = useState<any>("");
  const [packageBanner, setPackageBanner] = useState<any>();
  const [bannerImage, setBannerImage] = useState<any>("");
  const [packagePDF, setPackagePDF] = useState<any>("");
  const [bannerPdf, setBannerPdf] = useState<any>("");
  const [video, setVideo] = useState<any>("");
  const [homePage, setHomePage] = useState<any>(false);
  const [status, setStatus] = useState<any>(false);

  const [countryList, setCountryList] = useState<any>([]);
  const [courseTypeList, setCourseTypeList] = useState<any>([]);
  const [examList, setExamList] = useState<any>([]);
  const [subjectList, setSubjectList] = useState<any>([]);
  const [sectionList, setSectionList] = useState<any>([]);
  const [mainCategoryList, setMainCategoryList] = useState<any>([]);
  const [sessionList, setSessionList] = useState<any>([]);
  const [packageDetails, setPackageDetails] = useState<any>(initialData);

  const [resetCountryList, setResetCountryList] = useState<any>(false);
  const [resetCourseType, setresetCourseType] = useState<any>(false);
  const [resetGradeList, setResetGradeList] = useState<any>(false);
  const [resetexam, setresetexam] = useState<any>(false);
  const [resetSubject, setResetSubject] = useState<any>(false);
  const [resetSection, setresetSection] = useState<any>(false);
  const [resetMainCategory, setresetMainCategory] = useState<any>(false);
  const [resetHomePriority, setresetHomePriority] = useState<any>(false);
  const [resetStatus, setresetStatus] = useState<any>(false);

  const [originalPackagePDF, setOriginalPackagePDF] = useState<any>("");
  const [uploadedPDF, setUploadedPDF] = useState<any>("");
  const [packageList, setPackageList] = useState<any>([]);
  const [originalBannerImage, setOriginalBannerImage] = useState<any>("");

  // pagination
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  const handleChangePage = (event: any, newPage: any) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: any) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const onPageChange = (page: any, rowsPerPage: any, flag: any) => {
    /************ page change****************** */
    let data: any = "";
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountryList(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setresetCourseType(false);
    const Data = {
      coursetypeId: value._id,
    };
    dispatch(
      getCountlist(
        Data,
        (res: any) => {
          setLectureCount(res?.count);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    dispatch(
      getSectionList(
        "",
        (res: any) => {
          setSectionList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    setresetexam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const getList = () => {
    const postData: any = {
      coursetypeId: courseType?._id,
      examId: exam?._id,
      gradeId: grade?._id,
      subjectId: subject?._id,
    };
    if (
      postData["examId"] === null ||
      postData["examId"] === undefined ||
      postData["examId"] === ""
    ) {
      delete postData["examId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["subjectId"] === null ||
      postData["subjectId"] === undefined ||
      postData["subjectId"] === ""
    ) {
      delete postData["subjectId"];
    }

    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setPackageList(res);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getList();
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setResetSubject(false);
    const Data = {
      coursetypeId: courseType._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value?._id,
    };
    if (
      Data["gradeId"] === null ||
      Data["gradeId"] === undefined ||
      Data["gradeId"] === ""
    ) {
      delete Data["gradeId"];
    }
    if (
      Data["examtypeId"] === null ||
      Data["examtypeId"] === undefined ||
      Data["examtypeId"] === ""
    ) {
      delete Data["examtypeId"];
    }
    const newArrr: any = [...packageDetails];
    dispatch(
      getCountlist(
        Data,
        (res: any) => {
          setLectureCount(res?.level3);
          newArrr[0].offerLectureCount = res?.level1;
          newArrr[1].offerLectureCount = res?.level2;
          newArrr[2].offerLectureCount = res?.level3;
        },
        () => {}
      )
    );
    setPackageDetails(newArrr);
  };

  const onChangeLectureCount = (value: any) => {
    setLectureCount(value?._id);
  };

  const onChangeSection = (value: { _id: string; name?: string }) => {
    setSection(value._id);
    setresetSection(false);
  };

  const onChangeMainCategory = (value: {
    _id?: number;
    name?: string;
    sessionIds?: any;
  }) => {
    setCategory(value);
    setresetMainCategory(false);
    setPackageName(
      `${grade !== "" ? grade?.name : exam?.name ? exam?.name : ""} ${
        subject?.name ? subject?.name : ""
      } - ${value?.name}`
    );
    if (value?._id === 2) {
      setSessionList(value?.sessionIds);
    } else {
      setSessionList([]);
    }
    setresetMainCategory(false);
    setresetStatus(true);
  };

  const onChangePackageName = (val: any) => {
    setPackageName(val);
  };

  const changeSessionFlag = (event: any, index: any) => {
    const newData = JSON.parse(JSON.stringify(sessionList));
    if (event.target.checked) {
      newData[index].isCheck = true;
      setSessionList(newData);
    } else {
      newData[index].isCheck = false;
      setSessionList(newData);
    }
  };

  const changeSessionTime = (value: any, index: any) => {
    const newData = JSON.parse(JSON.stringify(sessionList));
    newData[index].time = value;
    setSessionList(newData);
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      setBannerImage("");
      return;
    }
    if (e?.target?.files[0]?.size > image_size_2MB) {
      dispatch(showErrorSnackbar("File size should be less than 2 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBannerImage(reader.result);
        setPackageBanner(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionPdfChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      setBannerPdf("");
      return;
    }
    if (e?.target?.files[0]?.size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 10 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBannerPdf(reader.result);
        setPackagePDF(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeHomePriority = (value: any) => {
    setHomePage(value);
    setresetHomePriority(false);
  };

  const onChangeStatus = (value: any) => {
    setStatus(value);
    setresetStatus(false);
  };

  const onChangePackageDetails = (val: any, index: number, key: string) => {
    const newArrr: any = [...packageDetails];
    if (key === "isRecommended") {
      newArrr.map((item: any, indexval: number) => {
        if (index === indexval) {
          item.isRecommended = true;
        } else {
          item.isRecommended = false;
        }
        return item;
      });
      setPackageDetails(newArrr);
    } else if (key === "priorityOrder") {
      if (val > "3") {
        dispatch(showErrorSnackbar("Priority Order should be less than 3!!"));
      } else {
        const checkPO = newArrr?.filter?.(
          (item: any) => item?.priorityOrder === val
        );
        if (checkPO?.length > 0) {
          dispatch(
            showErrorSnackbar("Priority Order " + val + " Already Set.")
          );
          newArrr[index][key] = "";
          setPackageDetails(newArrr);
        } else {
          newArrr[index][key] = val;
          setPackageDetails(newArrr);
        }
      }
    } else {
      newArrr[index][key] = val;
      setPackageDetails(newArrr);
    }
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setpackageId(value?._id);
    dispatch(
      getPackageEditData(
        value?._id,
        (res: any) => {
          setisEditMode(true);
          setPackageEditData(res);
          setCountry(res?.countryId);
          setCourseType(res?.coursetypeId);
          setGrade(res?.gradeId);
          setExam(res?.examtypeId);
          setSubject(res?.subjectId);
          setLectureCount(res?.totalLectureCount);
          setSection(res?.sectionId);
          setCategory(res?.maincategoryId);
          setSessionList(res?.sessionList);
          setPackageName(res?.packageName);
          setBannerImage(res?.bannerImage?.bannerImage);
          setOriginalBannerImage(res?.originalBannerImage);
          setBannerPdf(res?.packagePDF?.packagePDF);
          setOriginalPackagePDF(res?.originalPackagePDF);
          setVideo(res?.videoUrl?.videoUrl);
          setHomePage(
            res?.isHomePage === true
              ? { name: "Yes", _id: true }
              : { name: "No", _id: false }
          );
          setStatus(
            res?.isCourseActive === true
              ? { name: "Yes", _id: true }
              : { name: "No", _id: false }
          );
          setPackageDetails(res?.packageOptions);
          dispatch(
            getMainCategoryList(
              "",
              (res: any) => {
                setMainCategoryList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const handleDelete = (value: any) => {
    dispatch(
      deletePackage(
        value._id,
        () => {
          getList();
        },
        () => {}
      )
    );
  };

  const renderSearchMode = () => {
    return (
      <form onSubmit={handleSubmit}>
        <div className="col-7">
          <div className="d-flex mb-2 align-items-center mt-2">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-8 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
                disabled={isEditMode}
                defaultValue={country?._id}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center mt-2">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                Isrequired={true}
                reset={resetCourseType}
                disabled={isEditMode}
                defaultValue={courseType?._id}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex mb-2 align-items-center mt-2">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                  disabled={isEditMode}
                  defaultValue={grade?._id}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 1 || courseType?.type === 3 ? (
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetexam}
                  disabled={isEditMode}
                  defaultValue={exam?._id}
                />
              </div>
            </div>
          ) : null}
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-8 d-flex ps-2">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                reset={resetSubject}
                disabled={isEditMode}
                defaultValue={subject?._id}
              />
            </div>
          </div>
          {!isEditMode ? (
            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Search"} validation={searchValidation()} />
            </div>
          ) : null}
        </div>
      </form>
    );
  };

  const renderListMode = () => {
    return (
      <PaginationTable
        tableData={packageList ? packageList : []}
        pagename={"updatePackage"}
        rowsPerPage={rowsPerPage}
        page={page}
        tableHead={[
          "S.N",
          "Package Name",
          "Grade / Exam",
          "Subject Name",
          "Section Name",
          "Main Category",
          "Status",
          "ACTION",
        ]}
        edit={true}
        handleEdit={(value: any) => handleEdit(value)}
        handleDelete={(value: any) => handleDelete(value)}
      />
    );
  };

  const handleUpdate = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const session = sessionList?.map((item: any) => {
      return { sessionId: item?._id, time: item?.time };
    });
    const postData: any = {
      packageOptions: packageDetails,
    };
    if (packageEditData?.packageName !== packageName) {
      postData["packageName"] = packageName;
    }
    if (packageEditData?.videoUrl?.videoUrl !== video) {
      postData["videoUrl"] = video;
    }
    if (packageEditData?.isHomePage !== homePage?._id) {
      postData["isHomePage"] = homePage?._id;
    }
    if (packageEditData?.isCourseActive !== status?._id) {
      postData["isCourseActive"] = status?._id;
    }
    if (sessionList?.length > 0) {
      postData["sessions"] = session;
    }
    if (
      packageBanner &&
      packageEditData?.bannerImage?.bannerImage !== bannerImage
    ) {
      dispatch(
        uploadPackageMedia(
          packageBanner,
          (resImage: any) => {
            if (resImage?.file) {
              postData["bannerImage"] = resImage?.file;
            }
            if (resImage?.originalName) {
              postData["originalBannerImage"] = resImage?.originalName;
            }
            if (
              packagePDF &&
              packageEditData?.packagePDF?.packagePDF !== bannerPdf
            ) {
              dispatch(
                uploadPackageMedia(
                  packagePDF,
                  (resPdf: any) => {
                    if (resPdf?.file) {
                      postData["packagePDF"] = resPdf?.file;
                    }
                    if (resPdf?.originalName) {
                      postData["originalPackagePDF"] = resPdf?.originalName;
                    }
                    dispatch(
                      updatePackage(
                        postData,
                        packageId,
                        () => {
                          setisEditMode(false);
                          resetForm();
                          getList();
                        },
                        () => {}
                      )
                    );
                  },
                  () => {}
                )
              );
            } else {
              dispatch(
                updatePackage(
                  postData,
                  packageId,
                  () => {
                    setisEditMode(false);
                    resetForm();
                    getList();
                  },
                  () => {}
                )
              );
            }
          },
          () => {}
        )
      );
    } else {
      if (packagePDF && packageEditData?.packagePDF?.packagePDF !== bannerPdf) {
        dispatch(
          uploadPackageMedia(
            packagePDF,
            (resPdf: any) => {
              if (resPdf?.file) {
                postData["packagePDF"] = resPdf?.file;
              }
              if (resPdf?.originalName) {
                postData["originalPackagePDF"] = resPdf?.originalName;
              }
              dispatch(
                updatePackage(
                  postData,
                  packageId,
                  () => {
                    setisEditMode(false);
                    resetForm();
                    getList();
                  },
                  () => {}
                )
              );
            },
            () => {}
          )
        );
      } else {
        dispatch(
          updatePackage(
            postData,
            packageId,
            () => {
              setisEditMode(false);
              resetForm();
              getList();
            },
            () => {}
          )
        );
      }
    }
  };

  const renderSession = () => {
    return (
      <div className="d-flex mb-2">
        <div className="col-4 align-item-center d-flex">
          <LabelField lableName={"Session Flag"} />
        </div>
        <div className="col-8 ps-2">
          <div className="mt-2 border rounded p-1 ">
            <table className="border-0">
              <tr>
                <th style={{ background: "#fff", color: "#000" }}>session</th>
                <th style={{ background: "#fff", color: "#000" }}>
                  Timing(Optional)
                </th>
              </tr>
              {sessionList !== undefined
                ? sessionList?.map(
                    (
                      c: {
                        _id: string;
                        time: string;
                        name: string;
                        isSelected: boolean;
                      },
                      index: number
                    ) => (
                      <tr>
                        <td
                          style={{
                            background: "#fff",
                            color: "#000",
                            padding: "0 10px",
                          }}
                        >
                          <div>
                            <input
                              type="checkbox"
                              id={c._id}
                              name={c.time}
                              value={c.name}
                              onChange={(event) =>
                                changeSessionFlag(event, index)
                              }
                              checked={c?.isSelected}
                            />
                            <span className="ms-2">
                              <b>{c.name}</b>
                            </span>
                          </div>
                        </td>
                        <td
                          style={{
                            background: "#fff",
                            color: "#000",
                            padding: "0 10px",
                          }}
                        >
                          <div
                            className="datepicker"
                            style={{ width: "220px" }}
                          >
                            <Time
                              value={c.time}
                              setInputText={(value: string) =>
                                changeSessionTime(value, index)
                              }
                            />
                          </div>
                        </td>
                      </tr>
                    )
                  )
                : null}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const renderEditMode = () => {
    return (
      <form onSubmit={handleUpdate}>
        <div className="col-7">
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Total Lecture Count"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Lecture Count"
                value={lectureCount}
                onChangeInput={(value: any) => onChangeLectureCount(value)}
                isRequired={true}
                disabled={true}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Section Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Section Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSection(value)
                }
                value={section}
                options={sectionList}
                Isrequired={true}
                defaultValue={section?._id}
                reset={resetSection}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Main Category"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Main Category"
                setInputText={(value: { _id: number; name: string }) =>
                  onChangeMainCategory(value)
                }
                value={category}
                options={mainCategoryList}
                Isrequired={true}
                reset={resetMainCategory}
                defaultValue={category?._id}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Package Name"} />
            </div>
            <div className="col-8  ">
              <InputField
                placeholder="Enter Package Name"
                value={packageName}
                onChangeInput={(value: any) => onChangePackageName(value)}
                minlength={5}
                maxlength={150}
              />
            </div>
          </div>

          {sessionList?.length > 0 && category?._id === 2
            ? renderSession()
            : null}

          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Package Banner"} />
            </div>
            <div className="col-8 ">
              <ImagePickerAndViewer
                value={bannerImage}
                handleChange={(e: any) => handleOptionImageChange(e)}
                isRequired
              />
            </div>
          </div>

          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Package PDF"} />
            </div>
            <div className="col-8 ">
              <ImagePickerAndViewer
                value={bannerPdf}
                handleChange={(e: any) => handleOptionPdfChange(e)}
                isRequired
                doctype={"pdf"}
              />
            </div>
          </div>

          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Package Video"} />
            </div>
            <div className="col-8 ">
              <InputField
                placeholder="Enter Package Video"
                value={video}
                onChangeInput={(value: any) => setVideo(value)}
              />
            </div>
          </div>

          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Home Page"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Show On Home Page "
                setInputText={(value: any) => onChangeHomePriority(value)}
                value={homePage}
                options={homepageList}
                Isrequired={true}
                defaultValue={homePage?._id}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Active"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select status "
                setInputText={(value: any) => onChangeStatus(value)}
                value={status}
                options={statusList}
                Isrequired={true}
                defaultValue={status?._id}
              />
            </div>
          </div>
        </div>
        <div className="mb-2">
          <div className="fw-bold mb-4">Package Details</div>
          <div className="row w-100">
            <div className="col-md-3">
              <div className="border p-2 pt-1 rounded mb-3 w-100">
                {LabelArr?.map((item: any, index: number) => (
                  <div key={index} className="packageLabelName d-flex">
                    <LabelField lableName={item} />
                  </div>
                ))}
              </div>
            </div>
            {packageDetails?.map((item: any, index: number) => {
              return (
                <div className="d-flex col-md-3" key={index}>
                  <div className="border p-2 pt-3 rounded mb-3 w-100">
                    <div className="d-flex mb-3 align-items-center">
                      <div className="col-12 ps-2">Level {item?.levelId}</div>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      <div className="col-12  ">
                        <InputField
                          placeholder="Offer Lecture Count"
                          value={item?.offerLectureCount}
                          onChangeInput={(value: any) =>
                            onChangePackageDetails(
                              value,
                              index,
                              "offerLectureCount"
                            )
                          }
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      <div className="col-12  ">
                        <InputField
                          placeholder="Enter Display Name"
                          value={item?.levelName}
                          onChangeInput={(value: any) =>
                            onChangePackageDetails(value, index, "levelName")
                          }
                          minlength={5}
                          maxlength={100}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      <div className="col-12">
                        <InputField
                          placeholder="Enter Selling Price"
                          value={item?.sellingPrice}
                          onChangeInput={(value: any) =>
                            onChangePackageDetails(value, index, "sellingPrice")
                          }
                          maxlength={6}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      <div className="col-12 ">
                        <InputField
                          placeholder="Enter Offer Price "
                          value={item?.offerPrice}
                          onChangeInput={(value: any) =>
                            onChangePackageDetails(value, index, "offerPrice")
                          }
                          maxlength={6}
                        />
                      </div>
                    </div>
                    <div className="d-flex mb-2 align-items-center">
                      <div className="col-12  ">
                        <InputField
                          placeholder="Enter Priority Order"
                          value={item?.priorityOrder}
                          onChangeInput={(value: any) =>
                            onChangePackageDetails(
                              value,
                              index,
                              "priorityOrder"
                            )
                          }
                          maxlength={1}
                        />
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="col-12 ps-2">
                        <div className="form-check">
                          <input
                            className="form-check-input border-primary"
                            type="radio"
                            name="recommendation"
                            value={item?.offerPrice}
                            checked={item.isRecommended}
                            onChange={(value: any) =>
                              onChangePackageDetails(
                                value?.target?.checked,
                                index,
                                "isRecommended"
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="d-flex justify-content-center mt-3">
          <Submitbtn name={"Save & Update"} />
        </div>
      </form>
    );
  };

  const resetForm = () => {
    setLectureCount("");
    setSection("");
    setresetSection(true);
    setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    setPackageDetails(initialData);
  };

  function searchValidation() {
    return country === "" || courseType === "";
  }

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT PACKAGE"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        {renderSearchMode()}
        {isEditMode ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
