import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsNumberOnly,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import "../../App.css";
import CustomDropdown from "../../Components/customDropdown";
import {
  addInstallment,
  getCountryList,
  getInstallment,
} from "../../Redux/Actions/AdminAction";

export default function InstallmentSetting() {
  const dispatch: Function = useDispatch();
  const [minAmount, setMinAmount] = useState<any>("");
  const [additionalAmount, setAdditionalAmount] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [type, setType] = useState<any>(2);
  const [secondInst, setSecondInst] = useState<any>("");
  const [ratio1, setRatio1] = useState<any>("");
  const [ratio2, setRatio2] = useState<any>("");
  const [ratio3, setRatio3] = useState<any>("");
  const [thirdInst, setThirdInst] = useState<any>("");
  const [deactiveSecondInst, setDeactiveSecondInst] = useState<any>("");
  const [deactiveThirdInst, setDeactiveThirdInst] = useState<any>("");
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postdata = {
      ratioType: type,
      countryId: value?._id,
    };
    dispatch(
      getInstallment(
        postdata,
        (res: any) => {
          if (res?.length !== 0) {
            setMinAmount(res[0]?.applicableAmount);
            setAdditionalAmount(res[0]?.additionalAmount);
            setRatio1(res[0]?.ratio1);
            setRatio2(res[0]?.ratio2);
            setRatio3(res[0]?.ratio3);
            setSecondInst(res[0]?.secondLectureRatio);
            setDeactiveSecondInst(res[0]?.secondDeactivatePercentage);
            setThirdInst(res[0]?.thirdLectureRatio);
            setDeactiveThirdInst(res[0]?.thirdDeactivatePercentage);
          } else {
            setMinAmount("");
            setAdditionalAmount("");
            setRatio1("");
            setRatio2("");
            setRatio3("");
            setSecondInst("");
            setDeactiveSecondInst("");
            setThirdInst("");
            setDeactiveThirdInst("");
          }
        },
        () => {}
      )
    );
  };

  const onChangeType = (value: any) => {
    setType(value);
    clearData();
  };
  const onChangeAdditionalAmount = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setAdditionalAmount(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeMinAmount = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMinAmount(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeRatio1 = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setRatio1(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeRatio2 = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setRatio2(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeRatio3 = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setRatio3(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeSecondInst = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setSecondInst(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeDeactiveSecondInst = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setDeactiveSecondInst(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeThirdInst = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setThirdInst(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeDeactiveThirdInst = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setDeactiveThirdInst(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const clearData = () => {
    setResetCountryList(true);
    setMinAmount("");
    setAdditionalAmount("");
    setRatio1("");
    setRatio2("");
    setRatio3("");
    setSecondInst("");
    setDeactiveSecondInst("");
    setThirdInst("");
    setDeactiveThirdInst("");
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (type === 2) {
      const postdata = {
        ratioType: 2,
        countryId: country?._id,
        applicableAmount: minAmount,
        additionalAmount: additionalAmount,
        secondLectureRatio: secondInst,
        secondDeactivatePercentage: deactiveSecondInst,
        ratio1: ratio1,
        ratio2: ratio2,
      };
      dispatch(
        addInstallment(
          postdata,
          (res: any) => {
            clearData();
          },
          () => {}
        )
      );
    } else if (type === 3) {
      const postdata = {
        ratioType: 3,
        countryId: country?._id,
        applicableAmount: minAmount,
        additionalAmount: additionalAmount,
        secondLectureRatio: secondInst,
        secondDeactivatePercentage: deactiveSecondInst,
        thirdLectureRatio: thirdInst,
        thirdDeactivatePercentage: deactiveThirdInst,
        ratio1: ratio1,
        ratio2: ratio2,
        ratio3: ratio3,
      };
      dispatch(
        addInstallment(
          postdata,
          (res: any) => {
            clearData();
          },
          () => {}
        )
      );
    }
  };

  function validation() {
    return (
      country === "" ||
      minAmount === "" ||
      additionalAmount === "" ||
      (type === 2 && ratio1 === "" && ratio2 === "") ||
      (type === 3 && ratio1 === "" && ratio2 === "" && ratio3 === "") ||
      (type === 2 && secondInst === "" && deactiveSecondInst === "") ||
      (type === 2 && deactiveSecondInst === "") ||
      (type === 3 && secondInst === "") ||
      (type === 3 && deactiveSecondInst === "") ||
      (type === 3 && thirdInst === "") ||
      (type === 3 && deactiveThirdInst === "")
    );
  }
  return (
    <div className="contentBox">
      <PageTitle name={"INSTALLMENT SETTINGS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" row col-7 mt-2">
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Installment Counts"} />
              </div>
              <div className="p-3  d-flex col-6 justify-content-between">
                <span className="">
                  <input
                    type="radio"
                    value={2}
                    name={"type"}
                    checked={type === 2}
                    onChange={() => onChangeType(2)}
                  />{" "}
                  2 Installments
                </span>
                <span className="ms-3">
                  <input
                    type="radio"
                    value={3}
                    name={"type"}
                    checked={type === 3}
                    onChange={() => onChangeType(3)}
                  />{" "}
                  3 Installments
                </span>
              </div>
            </div>
            <div className="d-flex Justify-content-between p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between  p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Min Applicable Amount"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Min Applicable Amount"
                  value={minAmount}
                  isRequired={true}
                  onChangeInput={(value: string) => onChangeMinAmount(value)}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between p-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Extra Additional Amount"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Extra Additional Amount"
                  value={additionalAmount}
                  onChangeInput={(value: string) =>
                    onChangeAdditionalAmount(value)
                  }
                  isRequired={true}
                  maxlength={length_TwoHundredFifty - 1}
                />
              </div>
            </div>
            {type === 2 ? (
              <div className="d-flex Justify-content-between p-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Installment Ratio"} />
                </div>
                <div className="col-3 ps-2">
                  <InputField
                    placeholder="Enter Ratio"
                    value={ratio1}
                    onChangeInput={(value: string) => onChangeRatio1(value)}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
                <div className="col-3 ps-2">
                  <InputField
                    placeholder="Enter Ratio"
                    value={ratio2}
                    onChangeInput={(value: string) => onChangeRatio2(value)}
                    isRequired={true}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
              </div>
            ) : (
              <div className="d-flex Justify-content-between p-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Installment Ratio"} />
                </div>
                <div className="col-2 ps-2">
                  <InputField
                    placeholder="Enter Ratio"
                    value={ratio1}
                    onChangeInput={(value: string) => onChangeRatio1(value)}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
                <div className="col-2 ps-2">
                  <InputField
                    placeholder="Enter Ratio"
                    value={ratio2}
                    onChangeInput={(value: string) => onChangeRatio2(value)}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
                <div className="col-2 ps-2">
                  <InputField
                    placeholder="Enter Ratio"
                    value={ratio3}
                    onChangeInput={(value: string) => onChangeRatio3(value)}
                    isRequired={true}
                    maxlength={length_TwoHundredFifty - 1}
                  />
                </div>
              </div>
            )}

            {type === 2 ? (
              <>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"2nd Installment Show Lecture Ratio"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={secondInst}
                      onChangeInput={(value: string) =>
                        onChangeSecondInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"Account Deactive After 2nd  Installment"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={deactiveSecondInst}
                      onChangeInput={(value: string) =>
                        onChangeDeactiveSecondInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
              </>
            ) : null}
            {type === 3 ? (
              <>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"2nd Installment Show Lecture Ratio"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={secondInst}
                      onChangeInput={(value: string) =>
                        onChangeSecondInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"Account Deactive After 2nd  Installment"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={deactiveSecondInst}
                      onChangeInput={(value: string) =>
                        onChangeDeactiveSecondInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"3rd Installment Show Lecture Ratio"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={thirdInst}
                      onChangeInput={(value: string) =>
                        onChangeThirdInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between p-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField
                      lableName={"Account Deactive After 3rd Installment"}
                    />
                  </div>
                  <div className="col-6 ps-2">
                    <InputField
                      placeholder="Enter Percent"
                      value={deactiveThirdInst}
                      onChangeInput={(value: string) =>
                        onChangeDeactiveThirdInst(value)
                      }
                      isRequired={true}
                      maxlength={length_TwoHundredFifty - 1}
                    />
                  </div>
                </div>
              </>
            ) : null}

            <div className="d-flex justify-content-center mt-4">
              <Submitbtn name={"Update"} validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}
