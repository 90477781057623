export default {
  SuperAdminSidebarData: [
    {
      title: "USER CREATION",
      subNav: [
        {
          title: "CREATE",
          path: "createUser",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateUser",
          cName: "sub-nav",
        },
      ],
    },

    // {
    //   title: "CREATE TEACHER",
    //   subNav: [
    //     {
    //       title: "CREATE",
    //       path: "createTeacher",
    //       cName: "sub-nav",
    //     },
    //     {
    //       title: "EDIT / DELETE",
    //       path: "updateteacher",
    //       cName: "sub-nav",
    //     },
    //   ],
    // },

    {
      title: "REGISTERED USER",
      path: "registeredUsers",
    },
    {
      title: "ENROLLED USER",
      path: "enrolledStudent",
    },
    {
      title: "DSA USER CREATION",
      subNav: [
        {
          title: "DSA COMPANY CREATE",
          path: "createDsaUser",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateDsaUser",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "AFFILIATE USER CREATION",
      subNav: [
        {
          title: "CREATE",
          path: "createAffiliateUser",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateAffiliateUser",
          cName: "sub-nav",
        },
      ],
    },
  ],
};
