import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
   name: String,
   handleClick ?: React.MouseEventHandler<HTMLButtonElement>,
   disabled?: boolean
}

export default function Addmorebtn({ name, handleClick, disabled }: props) {

   return (

      <button type="button" className="btn btn-outline-secondary rounded-pill px-3 " disabled={disabled} onClick={handleClick}>{name}</button>

   );
}
