import React from 'react'

interface props{
    name:string,
}
export default function submitbtn({name}:props) {

  return (
    <button type="submit"  className="btn btn-dark mb-3 " >{name}</button>
  )
}
