import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import Submitbtn from "../../Components/submitButton";
import Addmorebtn from "../../Components/addmorebtn";
import CustomDropdown from "../../Components/customDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import TextEditor from "../../Components/TextEditor";
import { useDispatch } from "react-redux";
import {
  getGeneralSubjectList,
  getSubTopicList,
  getTopicList,
  updateQuestions,
  uploadQuestionMedia,
} from "../../Redux/Actions/AdminAction";
import Options from "../../Components/options";
import { useLocation } from "react-router-dom";
import {
  complexLevelData,
  inputTypeImage,
  inputTypeText,
  inputTypeVideo,
} from "../../Utils/dataConstant";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LabelField from "../../Components/labelField";
import {
  image_size_150,
  validateIsFileImageType,
  validateIsFileVideoType,
  video_size_25MB,
} from "../../Utils/validators";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";

export default function QuestionEdit(props: any) {
  const dispatch: Function = useDispatch();
  const location = useLocation();
  const [complexityLevel, setComplexityLevel] = useState(0);
  const [resetSubTopic, setResetSubTopic] = useState(false);
  const [topic, setTopic] = useState("");
  const [subTopic, setSubTopic] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);
  const [titleArray, setTitleArray] = useState<any>([]);
  const [optionArr, setOptionArr] = useState<any[]>([]);
  const [descriptionText, setDescriptionText] = useState(inputTypeText);
  const [descriptionImage, setDescriptionImage] = useState<any>(inputTypeImage);
  const [descriptionVideo, setDescriptionVideo] = useState<any>(inputTypeVideo);
  const [showDescriptionText, setShowDescriptionText] = useState(false);
  const [showDescriptionImage, setShowDescriptionImage] = useState(false);
  const [showDescriptionVideo, setShowDescriptionVideo] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [isCorrectCheck, setIsCorrectCheck] = useState(false);


  useEffect(() => {
    const id = props?.questionData?.examQuestionId;
    setOptionArr(props?.questionData?.options);
    setTitleArray(props?.questionData?.title);
    setDescriptionText({
      descType: "text",
      description: props?.questionData?.examAnsDesc?.text,
    });
    setDescriptionImage({
      descType: "img",
      description: props?.questionData?.examAnsDesc?.image,
    });
    if (props?.questionData?.examAnsDesc?.image !== "") {
      setShowDescriptionImage(true);
    }
    if (props?.questionData?.examAnsDesc?.video !== "") {
      setShowDescriptionVideo(true);
    }
    setDescriptionVideo({
      descType: "vid",
      description: props?.questionData?.examAnsDesc?.video,
    });
    setComplexityLevel(props?.questionData?.complexitylevelId);
    setSubject(props?.questionData?.subject?._id);
    setTopic(props?.questionData?.topic?._id);
    setSubTopic(props?.questionData?.subTopic?._id);
    const postData = {
      examtypeId: props?.questionData?.examType?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getGeneralSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  }, []);

  const setTopicData = (res: any) => {
    return res?.map((item: any) => {
      return {
        _id: item?.topicId,
        name: item?.topicName,
      };
    });
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const postData = {
      coursetypeId: props?.questionData?.courseType?._id,
      examtypeId: props?.questionData?.examType?._id,
      subjectId: value?._id,
    };

    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(setTopicData(res));
        },
        () => { }
      )
    );
  };

  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setSubTopic(value._id);
  };
  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);
    const postData = {
      coursetypeId: props?.questionData?.courseType?._id,
      examtypeId: props?.questionData?.examType?._id,
      subjectId: subject,
      topicId: value._id,
    };

    dispatch(
      getSubTopicList(
        postData,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
  };

  const onPressAdd = () => {
    if (optionArr.length < 4) setOptionArr([...optionArr, {}]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...optionArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setOptionArr(newArrr);
  };

  const handleOption = (value: {
    descType: string;
    description: string;
    isCorrect: boolean;
    index: number;
  }) => {
    const item = {
      descType: value.descType,
      description: value.description,
      isCorrect: value.isCorrect,
    };
    optionArr[value.index] = item;
  };

  const handleCorrectOption = (value: {
    index: number;
    isCorrect: boolean;
  }) => {
    optionArr.map((item, index) => {
      if (value.index === index) {
        item.isCorrect = true;
        setIsCorrectCheck(true);
      } else {
        item.isCorrect = false;
      }
      return item;
    });
  };
  const renderOption = () => {
    return optionArr?.map((item, index) => {
      return (
        <div className="position-relative d-flex justify-content-center col-12 mt-2">
          <div className="col-11 mx-5">
            <Options
              index={index}
              option={item}
              setOptionData={(value: {
                descType: string;
                description: string;
                isCorrect: boolean;
                index: number;
              }) => handleOption(value)}
              setCorrectOption={(value: {
                index: number;
                isCorrect: boolean;
              }) => handleCorrectOption(value)}
            />
          </div>
          <div className="position-absolute justify-content-end p-2 top-0 end-0">
            {optionArr?.length === 1 ? null : (
              <DeleteIcon
                className="cursor"
                color="error"
                fontSize="small"
                onClick={() => handleClose(index)}
              />
            )}
          </div>
        </div>
      );
    });
  };

  const handleCloseDescription = (type: string) => {
    if (type == "text") {
      setDescriptionText(descriptionText);
      setShowDescriptionText(false);
    } else if (type == "image") {
      setDescriptionImage(descriptionImage);
      setShowDescriptionImage(false);
    } else if (type == "video") {
      setDescriptionVideo(descriptionVideo);
      setShowDescriptionVideo(false);
    }
  };

  const handleDescriptionVideoChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > video_size_25MB) {
      dispatch(showErrorSnackbar("File size should be less than 25MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setDescriptionVideo(reader.result);
      };
      reader.readAsDataURL(file);

      dispatch(
        uploadQuestionMedia(
          file,
          (res: { filePath: any }) => {
            const data = {
              descType: "vid",
              description: res?.filePath,
            };
            setDescriptionVideo(data);
          },
          () => { }
        )
      );
    }
  };

  const handleDescriptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_150) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setDescriptionImage(reader.result);
      };
      reader.readAsDataURL(file);

      dispatch(
        uploadQuestionMedia(
          file,
          (res: { filePath: SetStateAction<string> }) => {
            const data = {
              descType: "img",
              description: res?.filePath,
            };
            setDescriptionImage(data);
          },
          () => { }
        )
      );
    }
  };

  const renderDescription = () => {
    return (
      <div className="mx-5">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          <h5 className="fw-bold text-light">Answer Description</h5>
          <div className=" d-flex">
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={() => showDesc("text")}
            >
              + Text
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={() => showDesc("image")}
            >
              + Image
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={() => showDesc("video")}
            >
              + Video
            </div>
          </div>
        </div>

        {showDescriptionText || descriptionText?.description?.length > 0 ? (
          <div className="editor_content mb-2 position-relative d-flex align-items-center">
            <span className="position-absolute justify-content-end p-2 top-0 end-0  ">
              <HighlightOffIcon
                className="cursor"
                color="error"
                fontSize="small"
              />
            </span>
            <TextEditor
              value={descriptionText?.description}
              onChange={(value: any) =>
                setDescriptionText({
                  descType: "text",
                  description: value,
                })
              }
              height="vh100"
              width="vw100"
            />
          </div>
        ) : null}

        {showDescriptionImage ? (
          <div className="mb-2 position-relative d-flex align-items-center">
            <span className="position-absolute justify-content-end p-2 top-0 end-0  ">
              <HighlightOffIcon
                className="cursor"
                color="error"
                fontSize="small"
              // onClick={() => handleCloseDescription("image")}
              />
            </span>
            <ImagePickerAndViewer
              lableName={"Description Image"}
              value={descriptionImage?.description}
              handleChange={(e: any) => handleDescriptionImageChange(e)}
            />
          </div>
        ) : null}

        {showDescriptionVideo ? (
          <div className="mb-2 position-relative d-flex align-items-center">
            <span className="justify-content-end p-2 removeButton">
              <HighlightOffIcon
                className="cursor"
                color="error"
                fontSize="small"
                onClick={() => handleCloseDescription("video")}
              />
            </span>
            <ImagePickerAndViewer
              lableName={"Description Video"}
              value={descriptionVideo?.description}
              handleChange={(e: any) => handleDescriptionVideoChange(e)}
              doctype={"video"}
            />
          </div>
        ) : null}
      </div>
    );
  };

  const onChangeTitleImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_150) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const file = e.target.files[0];
      dispatch(
        uploadQuestionMedia(
          file,
          (res: { filePath: any }) => {
            onChangeTitleText(index, res.filePath, "description");
          },
          () => { }
        )
      );
    }
  };

  const onChangeTitleText = (
    index: string | number,
    value: any,
    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(titleArray));
    if (key) {
      newArrr[index][key] = value;
      setTitleArray(newArrr);
    }
  };

  const showInputMenu = (inputType: any) => {
    if (inputType === "text") {
      setTitleArray([...titleArray, inputTypeText]);
    } else if (inputType === "image") {
      setTitleArray([...titleArray, inputTypeImage]);
    }
  };

  const showDesc = (obj: any) => {
    if (obj === "text") {
      setShowDescriptionText(true);
    } else if (obj === "image") {
      setShowDescriptionImage(true);
    } else if (obj === "video") {
      setShowDescriptionVideo(true);
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      examPatternId: props?.questionData?.section?.examPatternId,
      examSetId: props?.questionData?.examSet?._id,
      examSetTypeId: props?.questionData?.examType._id,
      sectionId: props?.questionData?.section?.sectionId,
      partId: props?.questionData?.section?.partId,
      complexitylevelId: complexityLevel,
      subjectId: subject,
      topicId: topic,
      subTopicId: subTopic,
      question: titleArray,
      answer: [descriptionText, descriptionImage, descriptionVideo],
      option: optionArr,
    };
    dispatch(
      updateQuestions(
        props?.questionData?.examQuestionId,
        postData,
        () => {
          clearData();
          props?.getData();
          props?.setisEditMode(false);
        },
        () => { }
      )
    );
  };

  const renderTitle = () => {
    return titleArray?.map((item: any, index: any) => {
      const { descType, description } = item;
      return (
        <div className="position-relative col-12" key={index}>
          <span className="position-absolute justify-content-end p-2 top-0 end-0  ">
            <HighlightOffIcon
              className="cursor"
              color="error"
              fontSize="small"
              onClick={() => handleClose(index)}
            />
          </span>
          {descType === "text" ? (
            <div className="editor_content mt-2 mb-2">
              <TextEditor
                value={description}
                onChange={(value: any) =>
                  onChangeTitleText(index, value, "description")
                }
                height="vh100"
                width="vw100"
              />
            </div>
          ) : (
            <div className="mt-2 mb-2">
              <ImagePickerAndViewer
                lableName={"Title Image"}
                value={description}
                handleChange={(e: any) => onChangeTitleImg(e, index)}
                removelabel={true}
              />
            </div>
          )}
        </div>
      );
    });
  };

  const clearData = () => {
    return (
      setComplexityLevel(0),
      setTopic(""),
      setSubTopic(""),
      setTitleArray([]),
      setDescriptionText(inputTypeText),
      setDescriptionImage(inputTypeImage),
      setDescriptionVideo(inputTypeVideo),
      setOptionArr([{}]),
      setShowDescriptionText(false),
      setShowDescriptionImage(false),
      setShowDescriptionVideo(false),
      setResetSubTopic(true),
      setResetTopic(true),
      setResetSubject(true)
    );
  };

  const validation = () => {
    let isSelectedval: any = false;
    optionArr?.map((item) => {
      if (item?.isCorrect === true) {
        isSelectedval = true;
      }
    });
    return (
      complexityLevel === 0 ||
      topic === "" ||
      subTopic === "" ||
      titleArray?.length === 0 ||
      optionArr?.length < 2 ||
      isSelectedval === false
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT QUESTION"}
        isEditMode={props?.isEditMode}
        setisEditMode={props?.setisEditMode}
      />
      <form className="px-4 mx-0 mt-4 formDiv" onSubmit={handleSubmit}>
        <h6 className="p-4 ps-0 fw-bold">
          {props?.questionData?.examSet?.name}
        </h6>
        <div className="mb-2">
          <button className="btn btn-dark rounded-pill">
            {props?.questionData?.section_obj?.name}
          </button>
        </div>
        <div className="border border-dark p-4">
          <div className="d-flex">
            <div className="col-2 justify-content-between">
              <div className="fw-bold">Question : 1</div>
            </div>
            <div className="col-4 d-flex me-3">
              <label className="fw-bold">Answer Type</label>
              <input
                className="mx-1 my-2 form-control"
                type="text"
                placeholder="MCQ"
                disabled
              />
            </div>
            <div className="col-5 me-4">
              <span className="fw-bold me-4">Complexity Level</span>
              {complexLevelData.map((c: any, index: any) => {
                return (
                  <span className=" me-5">
                    <input
                      type="radio"
                      name="level"
                      value={complexityLevel}
                      checked={complexityLevel === c?._id ? true : false}
                      onChange={() => setComplexityLevel(c?._id)}
                    />
                    <span className="ms-2">{c?.name}</span>
                  </span>
                );
              })}
            </div>
          </div>
          <div className="d-flex jystify-content-between mb-2">
            <div className="col-5 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-7 ps-2">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                  disabled={props?.examType?.name === "DPP"}
                  defaultValue={subjectList?.length > 0 ? subject : null}
                />
              </div>
            </div>

            <div className="col-4  d-flex align-items-center flex-fill mt-2 me-5">
              <div className=" me-3">
                <label className="fw-bold">Topic</label>
              </div>
              <div className="col-10 mt-3">
                <CustomDropdown
                  lableName="Select Topic Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeTopic(value)
                  }
                  value={topic}
                  options={topicList}
                  Isrequired={true}
                  reset={resetTopic}
                  disabled={props?.examType?.name === "DPP"}
                  defaultValue={topicList?.length > 0 ? topic : ""}
                />
              </div>
            </div>
            <div className="col-4  d-flex align-items-center flex-fill mt-2 me-5">
              <div className=" me-3">
                <label className="fw-bold">Sub-Topic</label>
              </div>
              <div className="col-10 mt-3">
                <CustomDropdown
                  lableName="Select Sub-Topic Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubTopic(value)
                  }
                  value={subTopic}
                  options={subTopicList}
                  Isrequired={true}
                  reset={resetSubTopic}
                  defaultValue={subTopicList?.length > 0 ? subTopic : ""}
                  disabled={props?.examType?.name === "DPP"}
                />
              </div>
            </div>
          </div>
          <div>
            <div className="mx-5">
              <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
                <h5 className="fw-bold text-light">Part A</h5>
                <div className=" d-flex">
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={() => showInputMenu("text")}
                  >
                    + Text
                  </div>
                  <div
                    className="cursor addInputbtn ms-2 p-1"
                    onClick={() => showInputMenu("image")}
                  >
                    + Image
                  </div>
                </div>
              </div>
              {renderTitle()}
            </div>
            <div className="mt-2">{renderOption()}</div>
            {optionArr?.length < 4 ? (
              <div className="d-flex justify-content-end mt-2">
                <Addmorebtn name="Add More" handleClick={onPressAdd} />
              </div>
            ) : null}
            <div className="mt-3">{renderDescription()}</div>
          </div>
        </div>
        <div className="my-5 d-flex justify-content-center">
          <Submitbtn name={"Submit"} validation={validation()} />
        </div>
      </form>
    </div>
  );
}
