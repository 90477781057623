import { MenuItem, Select } from "@mui/material";
import React from "react";
import { useState } from "react";
import moment from "moment";
interface props {
  lableName: any;
  value: any;
  options: any;
  setInputText: any;
  type?: any;
  reset?: any;
  defaultValue?: any;
  x?: any;
  disabled?: any;
  Isrequired?: any;
}

function CustomDropdown({
  lableName,
  value,
  options,
  setInputText,
  type,
  reset,
  defaultValue,
  x,
  disabled,
  Isrequired,
}: props) {
  const [names, setName] = useState(lableName);

  React.useEffect(() => {
    let newArr = [];
    if (type === "timezone") {
      newArr = options?.filter(function (el: { timezoneId: any }) {
        return el.timezoneId == defaultValue;
      });
    } else {
      newArr = options?.filter(function (el: { _id: any }) {
        return el._id == defaultValue;
      });
    }

    newArr?.map((item: { name: any }) => {
      setName(item.name);
      setInputText(item);
    });
  }, [defaultValue]);
  React.useEffect(() => {
    if (reset === true) {
      setName(lableName);
    }
  }, [reset]);
  const handleChange = (e: any) => {
    if (type === "type") {
      setName(e.target.value.type);
      setInputText(e.target.value);
    } else if (type === "teacher") {
      setName(e.target.value.name);
      setInputText(e.target.value);
    } else {
      if (e.target.value.months) {
        setName(e.target.value.months);
      } else if (e.target.value.date) {
        setName(moment(e.target.value.date).format("DD - MM - YYYY"));
      } else if (e.target.value.en) {
        setName(e.target.value.en.name);
        setInputText(e.target.value);
      } else {
        setName(e.target.value.name);
      }

      setInputText(e.target.value);
    }
  };

  return (
    <div className="d-flex mb-2 flex-fill CustomDropDown">
      <Select
        className="w-100 bgwhite"
        defaultValue={names}
        value={names}
        label="name"
        onChange={handleChange}
        disabled={disabled}
        required={Isrequired}
      >
        <MenuItem disabled value={names}>
          {names}
        </MenuItem>

        {options
          ? options.map((item: any) => (
              <MenuItem key={item._id} value={item}>
                {item?.name
                  ? item?.name
                  : item?.months
                  ? item?.months
                  : item?.type
                  ? item?.type
                  : item?.en?.name
                  ? item?.en?.name
                  : item?.date
                  ? moment(item.date).format("DD - MM - YYYY")
                  : item?.maincategoryName
                  ? item?.maincategoryName
                  : ""}
              </MenuItem>
            ))
          : null}
      </Select>
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}

export default CustomDropdown;
