import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  validateFullName,
  validateIsNumberOnly,
  startsWithSpace,
  length_Ten,
  length_Five,
  validatePassword,
  length_Hundred,
  length_One,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  assignTeacherExam,
  updateTeacher,
  updateTeacherList,
  updateTeacherStatus,
} from "../../Redux/Actions/SuperAdminAction";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import Addmorebtn from "../../Components/addmorebtn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getCountryList,
  getGeneralTimezoneList,
} from "../../Redux/Actions/AdminAction";

export default function Updateteacher() {
  const dispatch: Function = useDispatch();

  const initialVal: {
    timeZone: any;
    _id: any;
  }[] = [
    {
      timeZone: "",
      _id: "",
    },
  ];
  const [count, setCount] = useState(0);
  const [employeeId, setEmployeeId] = useState<string>("");
  const [catergory, setCatergory] = useState<any>(1);
  const [isEditMode, setisEditMode] = useState(false);
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [updateTeacherId, setUpdateTeacherId] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [assignedSubjectList, setAssignedSubjectList] = useState<any>([]);
  const [changePassword, setChangePassword] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [timeZoneArr, setTimeZoneArr] = useState<any>(initialVal);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [numberOfClasses, setNumberOfClasses] = useState<any>("");
  const [yearOfExperience, setYearOfExperience] = useState<any>("");
  const [numberOfDemoClasses, setNumberOfDemoClasses] = useState<any>("");
  const [regularAcademics, setRegularAcademics] = useState<any>("");
  const [collegeConsulting, setCollegeConsulting] = useState<any>("");
  const [testPreperation, setTestPreperation] = useState<any>("");
  const [advancedAcademics, setAdvancedAcademics] = useState<any>("");

  // pagination
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  let temp = 0;
  temp = page * rowsPerPage + 1;

  useEffect(() => {
    getData();
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const getData = () => {
    const postData: any = {
      skip: 0,
      limit: rowsPerPage,
    };
    if (employeeId.length > 0) {
      postData["name"] = employeeId;
    }

    if (catergory) {
      postData["isNative"] = catergory === 1 ? true : false;
    }

    dispatch(
      updateTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number) => {
    /************ page change****************** */
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    if (employeeId.length > 0) {
      postData["name"] = employeeId;
    }

    if (catergory) {
      postData["isNative"] = catergory === 1 ? true : false;
    }

    dispatch(
      updateTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const onChangeCategory = (value: any) => {
    setCatergory(value);
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setEmployeeName(value?.name);
    setMobileNo(value?.mobile);
    setEmail(value?.email);
    setDateOfJoining(value?.doj);
    setCountry(value?.countryId);
    setTimeZoneArr(value?.timezoneId);
    setUserName(value?.username);
    setCatergory(value?.isNative);
    setUpdateTeacherId(value?.empId);
    setNumberOfClasses(value?.numberOfClasses);
    setYearOfExperience(value?.yearOfExperience);
    setNumberOfDemoClasses(value?.numberOfDemoClasses);
    setRegularAcademics(value?.regularAcademics);
    setCollegeConsulting(value?.collegeConsulting);
    setTestPreperation(value?.testPrep);
    setAdvancedAcademics(value?.advancedAcademics);
    dispatch(
      getGeneralTimezoneList(
        value?.countryId,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
    const postData: any = {
      empId: value?.empId,
    };
    setAssignedSubjectList([]);
    dispatch(
      assignTeacherExam(
        postData,
        (res: any) => {
          setAssignedSubjectList(res?.subject);
        },
        () => {}
      )
    );
  };

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const changeCheckBox = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(assignedSubjectList));
    if (event.target.checked) {
      newArray[index]["assigned"] = event.target.checked;
      setAssignedSubjectList(newArray);
    } else {
      newArray[index]["assigned"] = event.target.checked;
    }
    setAssignedSubjectList(newArray);
  };

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const onChangeClasses = (value: any) => {
    setNumberOfClasses(value);
  };
  const onChangeExperiences = (value: any) => {
    setYearOfExperience(value);
  };
  const onChangeDemoClasses = (value: any) => {
    setNumberOfDemoClasses(value);
  };

  const onChangeTimeZone = (value: any, index: any) => {
    let newArr = JSON.parse(JSON.stringify(timeZoneArr));
    newArr[index] = value?.timezoneId;
    setTimeZoneArr(newArr);
  };
  const onPressAdd = () => {
    setTimeZoneArr([...timeZoneArr, initialVal[0]]);
  };

  const handleCrossLive = (index: any) => {
    const newArray = [...timeZoneArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setTimeZoneArr(newArray);
  };
  const onChangeRegularAcademics = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setRegularAcademics(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeCollegeConsulting = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setCollegeConsulting(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeTestPreperation = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setTestPreperation(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeAdvancedAcademics = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setAdvancedAcademics(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };

  const onChangePassword = (value: SetStateAction<string>) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const changeToggle = (event: any) => {
    const PostData: any = {
      isActive: event.target.checked,
    };
    dispatch(
      updateTeacherStatus(
        PostData,
        event.target.id,
        (res: any) => {
          getData();
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {teacherList.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={teacherList ? teacherList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={teacherList ? teacherList : []}
          pagename={"teacherData"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Teacher Name",
            "Teacher ID",
            "Mobile Number",
            "Username",
            "Date Of Joining",
            "Status",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleToggle={(value: any) => changeToggle(value)}
        />
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getData();
  };

  const handleOnEdit = (e: any) => {
    e.preventDefault();
    const subjectArray: number[] = [];
    assignedSubjectList.forEach(
      (element: { assigned: boolean; subjectId: number }) => {
        if (element.assigned) {
          subjectArray.push(element.subjectId);
        }
      }
    );

    const PostData: any = {
      name: employeeName,
      subjectId: subjectArray,
      username: userName,
      mobile: mobileNo,
      email: email,
      doj: dateOfJoining,
      isActive: true,
      timezoneId: timeZoneArr,
      countryId: country?._id,
      numberOfClasses: numberOfClasses,
      yearOfExperience: yearOfExperience,
      numberOfDemoClasses: numberOfDemoClasses,
      regularAcademics: regularAcademics,
      collegeConsulting: collegeConsulting,
      testPrep: testPreperation,
      advancedAcademics: advancedAcademics,
    };

    if (password.length > 0) {
      PostData["password"] = password;
    }
    if (
      PostData["numberOfClasses"] === null ||
      PostData["numberOfClasses"] === undefined ||
      PostData["numberOfClasses"] === ""
    ) {
      delete PostData["numberOfClasses"];
    }
    if (
      PostData["yearOfExperience"] === null ||
      PostData["yearOfExperience"] === undefined ||
      PostData["yearOfExperience"] === ""
    ) {
      delete PostData["yearOfExperience"];
    }
    if (
      PostData["numberOfDemoClasses"] === null ||
      PostData["numberOfDemoClasses"] === undefined ||
      PostData["numberOfDemoClasses"] === ""
    ) {
      delete PostData["numberOfDemoClasses"];
    }

    dispatch(
      updateTeacher(
        PostData,
        updateTeacherId,
        (res: any) => {
          setisEditMode(false);
          getData();
        },
        () => {}
      )
    );
  };

  const handleChangePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setChangePassword(!changePassword);
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="" onSubmit={handleOnEdit}>
              <div className="col-10 px-4 mx-0 mt-4 ">
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Teacher Name"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Teacher Name"
                      value={employeeName}
                      onChangeInput={(value: any) => onChangeEmpName(value)}
                      maxlength={100}
                      minlength={3}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between my-2">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Teacher Category"} />
                  </div>
                  <div className="col-6 d-flex ps-3 ">
                    <div className="col-4">
                      <span>
                        <input
                          type="radio"
                          value={1}
                          name={"catergory"}
                          disabled
                          checked={catergory === true ? true : false}
                          onChange={() => onChangeCategory(1)}
                        />{" "}
                        Native
                      </span>
                    </div>
                    <div className="col-4">
                      <span>
                        <input
                          type="radio"
                          value={2}
                          name={"catergory"}
                          checked={catergory === false ? true : false}
                          onChange={() => onChangeCategory(2)}
                          disabled
                        />{" "}
                        Indian
                      </span>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 d-flex Justify-content-between my-2">
                  <div className="col-5 align-items-start d-flex mt-2">
                    <LabelField lableName={"Subject Name "} />
                  </div>
                  <div className="d-flex col-8 ps-2">
                    <div className="border rounded p-3">
                      <div className=" input-border">
                        {assignedSubjectList.map((item: any, index: any) => {
                          const { subjectId, subjectName } = item;
                          return (
                            <div key={item?._id} className="d-flex center p-1">
                              <div className="w-100">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex e-input align-items-center ms-3">
                                    <input
                                      id={subjectId}
                                      className="check"
                                      type="checkbox"
                                      defaultChecked={item?.assigned}
                                      onChange={(event) => {
                                        changeCheckBox(event, index);
                                      }}
                                    />
                                    <span className="checkVal ms-3">
                                      {subjectName}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between mt-4 mb-2">
                  <div className="col-md-5 align-item-center d-flex">
                    <LabelField lableName={"Country"} />
                  </div>
                  <div className="col-md-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Country"
                      setInputText={(value: any) => onChangeCountry(value)}
                      value={country}
                      options={countryList}
                      // Isrequired={true}
                      defaultValue={countryList?.length > 0 ? country : null}
                      reset={resetCountryList}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Mobile Number"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Mobile Number"
                      value={mobileNo}
                      onChangeInput={(value: any) => onChangeMobileNO(value)}
                      maxlength={length_Ten}
                      minlength={length_Five}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Email Address"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Email Address"
                      value={email}
                      onChangeInput={(value: any) => onChangeEmail(value)}
                      maxlength={100}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Number of classes"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Number of classes"
                      value={numberOfClasses}
                      onChangeInput={(value: any) => onChangeClasses(value)}
                      maxlength={length_Hundred - 1}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Year Of Experience"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Year Of Experience"
                      value={yearOfExperience}
                      onChangeInput={(value: any) => onChangeExperiences(value)}
                      maxlength={length_Hundred - 1}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Number Of Demo Classes"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Number Of Demo Classes"
                      value={numberOfDemoClasses}
                      onChangeInput={(value: any) => onChangeDemoClasses(value)}
                      maxlength={length_Hundred - 1}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Date Of Joining"} />
                  </div>
                  <div className="col-6 ">
                    <DatePicker
                      value={dateOfJoining}
                      setInputText={(value: string) => setDateOfJoining(value)}
                      maxdate={today}
                    />
                  </div>
                </div>

                <div>
                  {timeZoneArr?.map((item: any, index: any) => {
                    return (
                      <>
                        <span className=" col-6 d-flex justify-content-end removeButton mb-2 mt-2">
                          {timeZoneArr.length === 1 ? null : (
                            <HighlightOffIcon
                              className="cursor"
                              color="primary"
                              fontSize="small"
                              onClick={() => handleCrossLive(index)}
                            />
                          )}
                        </span>
                        <div className="col-md-6 d-flex Justify-content-between mt-2 mb-2">
                          <div className="col-5 align-item-center d-flex">
                            <LabelField lableName={"Time Zone"} />
                          </div>
                          <div className="col-6 ps-2 ">
                            <CustomDropdown
                              lableName="Select Time Zone"
                              value={item}
                              type="timezone"
                              options={timeZoneList}
                              defaultValue={
                                timeZoneList?.length > 0 ? item : null
                              }
                              setInputText={(value: {
                                _id: string;
                                name: string;
                              }) => onChangeTimeZone(value, index)}
                            />
                          </div>
                        </div>
                      </>
                    );
                  })}
                  <div className="d-flex col-6 d-flex justify-content-end mb-2">
                    <Addmorebtn name="Add More" handleClick={onPressAdd} />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between  ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Regular Academics"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Price"
                      value={regularAcademics}
                      onChangeInput={(value: any) =>
                        onChangeRegularAcademics(value)
                      }
                      minlength={length_One}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between  ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"College Consulting"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Price"
                      value={collegeConsulting}
                      onChangeInput={(value: any) =>
                        onChangeCollegeConsulting(value)
                      }
                      minlength={length_One}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between  ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Test Preperation"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Price"
                      value={testPreperation}
                      onChangeInput={(value: any) =>
                        onChangeTestPreperation(value)
                      }
                      minlength={length_One}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between  ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Advanced Academics"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Price"
                      value={advancedAcademics}
                      onChangeInput={(value: any) =>
                        onChangeAdvancedAcademics(value)
                      }
                      minlength={length_One}
                      isRequired={true}
                    />
                  </div>
                </div>
                <div className="col-md-6 d-flex Justify-content-between  ">
                  <div className="col-5 align-item-center d-flex">
                    <LabelField lableName={"Username"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Username"
                      value={userName}
                      onChangeInput={(value: any) => onChangeUserName(value)}
                      maxlength={30}
                      minlength={5}
                    />
                  </div>
                </div>
                {changePassword ? (
                  <div className="col-md-6 d-flex Justify-content-between  ">
                    <div className="col-5 align-item-center d-flex">
                      <LabelField lableName={"Password"} />
                    </div>
                    <div className="col-6">
                      <InputField
                        placeholder="Enter Password"
                        value={password}
                        onChangeInput={(value: any) => onChangePassword(value)}
                        maxlength={30}
                        minlength={5}
                      />
                    </div>
                  </div>
                ) : (
                  <div className="  ">
                    <button
                      type="submit"
                      className={"btn btn-primary rounded-pill me-4"}
                      onClick={(e) => handleChangePassword(e)}
                    >
                      Change Password{" "}
                    </button>
                  </div>
                )}
                <div className="d-flex justify-content-center">
                  <SubmitButton name="Save & Update" />
                </div>
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Edit Teacher"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />

      {isEditMode !== true ? (
        <div className="col-md-12 px-4 mx-0 mt-4 d-flex Justify-content-between">
          <div className="col-md-5 d-flex  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Teacher Name"} />{" "}
            </div>
            <InputField
              placeholder="Enter Teacher Name"
              value={employeeId}
              onChangeInput={(value: any) => setEmployeeId(value)}
            />
          </div>
          <div className="col-md-5 d-flex Justify-content-between my-2">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Teacher Category"} />
            </div>
            <div className="col-7 d-flex align-item-center ps-3 ">
              <div className="col-6 d-flex align-item-center pt-3">
                <span>
                  <input
                    type="radio"
                    value={1}
                    name={"catergory"}
                    checked={catergory === 1}
                    onChange={() => onChangeCategory(1)}
                  />{" "}
                  Native
                </span>
              </div>
              <div className="col-6 d-flex align-item-center pt-3">
                <span>
                  <input
                    type="radio"
                    value={2}
                    name={"catergory"}
                    checked={catergory === 2}
                    onChange={() => onChangeCategory(2)}
                  />{" "}
                  Indian
                </span>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-dark mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => handleSubmit(e)}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      ) : (
        ""
      )}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
