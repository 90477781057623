import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import {
  bindSubjectList,
  deleteExamDescription,
  getCountryList,
  getCourseTypelist,
  getExamDescriptionList,
  getExamList,
  getGeneralCourseList,
  getGeneralExamList,
  updateExamDescription,
  uploadExamBanner,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import {
  image_size_1MB,
  length_FiveHundred,
  length_OneHundredFifty,
  length_Ten,
  validateIsFileImageType,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import SubmitButton from "../../Components/submitButton";
import { GradeList } from "../../Utils/dataConstant";

export default function UpdateExamDescription() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [video, setVideo] = useState<string>("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [examDescList, setExamDescList] = useState([]);
  const [examlogo, setExamlogo] = useState<any>("");
  const [originalExamBanner, setoriginalExamBanner] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [examDescriptionId, setExamDescriptionId] = useState<any>("");
  const [examDescriptionArr, setExamDescriptionArr] = useState<any>("");

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetExam, setresetExam] = useState(false);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };


  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const data = {
      coursetypeId: value?._id,
    };
    dispatch(
      getExamDescriptionList(
        data,
        (res: any) => {
          setExamDescList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    const data = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      getExamDescriptionList(
        data,
        (res: any) => {
          setExamDescList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    const data = {
      coursetypeId: course?._id,
      examTypeId: value?._id,
    };
    dispatch(
      getExamDescriptionList(
        data,
        (res: any) => {
          setExamDescList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const data = {
      coursetypeId: course?._id,
      examTypeId: exam,
      gradeId: grade?._id,
      subjectId: value._id
    };
    if (
      data["examTypeId"] === null ||
      data["examTypeId"] === undefined ||
      data["examTypeId"] === ""
    ) {
      delete data["examTypeId"];
    }
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    dispatch(
      getExamDescriptionList(
        data,
        (res: any) => {
          setExamDescList(res);
        },
        () => { }
      )
    );
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    }
  };

  const changeExamBanner = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setExamlogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadExamBanner(
          file,
          (res: any) => {
            delete res?.filePath;
            setoriginalExamBanner(res);
          },
          () => { }
        )
      );
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setExam(value?.examTypeId);
    setExamlogo(value?.examBanner);
    setVideo(value?.examVideo);
    setDesc(value?.examDesc);
    setExamDescriptionId(value._id);
    setExamDescriptionArr(value);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteExamDescription(
        value._id,
        () => {
          dispatch(
            getExamDescriptionList(
              {
                examTypeId: value?._id,
              },
              (res: any) => {
                setExamDescList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData: any = {};
    if (examDescriptionArr?.coursetypeId._id != course._id) {
      PostData["coursetypeId"] = course._id;
    }
    if (examDescriptionArr?.gradeId._id != grade._id) {
      PostData["gradeId"] = grade._id;
    }
    if (examDescriptionArr?.examTypeId._id != exam._id) {
      PostData["examTypeId"] = exam._id;
    }
    if (examDescriptionArr?.subjectId._id != subject._id) {
      PostData["subjectId"] = subject._id;
    }
    if (examDescriptionArr?.examBanner != originalExamBanner.file) {
      PostData["examBanner"] = originalExamBanner.file;
    }
    if (
      examDescriptionArr?.originalExamBanner != originalExamBanner.originalName
    ) {
      PostData["originalExamBanner"] = originalExamBanner.originalName;
    }
    if (examDescriptionArr?.examVideo != video) {
      PostData["examVideo"] = video;
    }
    if (examDescriptionArr?.examDesc != desc) {
      PostData["examDesc"] = desc;
    }
    dispatch(
      updateExamDescription(
        PostData,
        examDescriptionId,
        (res: any) => {
          setisEditMode(false);
          const data = {};
          dispatch(
            getExamDescriptionList(
              data,
              (res: any) => {
                setExamDescList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const renderListMode = () => {
    return (
      <>
        {examDescList.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center mt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={examDescList ? examDescList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={examDescList ? examDescList : []}
          pagename={"ExamDescriprtion"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", "Exam Name", "Exam Description", "Action"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const validation = () => {
    return (
      exam === "" ||
      desc === "" ||
      desc?.length < length_Ten ||
      video === "" ||
      video?.length < length_Ten ||
      examlogo === ""
    );
  };

  const renderEditMode = () => {
    return (
      <div className="col-7 px-3">
        <form onSubmit={handleSubmit}>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Exam Banner"} />
            </div>
            <div className="col-8">
              <ImagePickerAndViewer
                lableName={"Option Image"}
                value={examlogo}
                handleChange={(e: any) => changeExamBanner(e)}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Exam Video"} />
            </div>
            <div className="col-8">
              <InputField
                placeholder="Enter Video Url"
                value={video}
                onChangeInput={(value: string) => setVideo(value)}
                minlength={length_Ten}
                maxlength={length_OneHundredFifty}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Exam Description "} />
            </div>
            <div className="col-8 ps-2">
              <textarea
                className="w-100"
                name="postContent"
                rows={4}
                cols={40}
                value={desc}
                onChange={(e: any) => setDesc(e.target.value)}
                maxLength={length_FiveHundred}
                minLength={length_Ten}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-5">
            <SubmitButton name={"Save & Update"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Edit Exam Description"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="col-7 p-3">
        <div className="d-flex mb-2 align-items-center">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Country"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Country"
              setInputText={(value: any) => onChangeCountry(value)}
              value={country}
              options={countryList}
              Isrequired={true}
              // Isrequired={true}
              reset={resetCountryList}
              disabled={isEditMode}
            />
          </div>
        </div>
        <div className="d-flex mb-2 align-items-center">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Course Type Name"
              setInputText={(value: any) => onChangeCourseType(value)}
              value={course}
              options={courseList}
              Isrequired={true}
              reset={resetParentCourse}
              disabled={isEditMode}
            />
          </div>
        </div>
        {course?.type === 2 ? (
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Student Grade"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Student Grade"
                setInputText={(value: any) => onChangeStudentGrade(value)}
                value={grade}
                Isrequired={true}
                options={GradeList}
                reset={resetGradeList}
                disabled={isEditMode}
              />
            </div>
          </div>
        ) : null}
        {course?.type === 3 || course?.type === 2 ? null : (
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Exam Name "} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Exam Name"
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
                disabled={isEditMode}
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExam(value)
                }
              />
            </div>
          </div>
        )}
        <div className="d-flex mb-2 align-items-center">
          <div className="col-4 align-item-center d-flex">
            <LabelField lableName={"Subject Name"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Subject Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubject(value)
              }
              value={subject}
              options={subjectList}
              Isrequired={true}
              reset={resetSubject}
              disabled={isEditMode}

            />
          </div>
        </div>
      </div>
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
