import services from '../../Constant/service';
import endpoint from '../../Constant/endpoint';
import { showErrorSnackbar, showSuccessSnackbar } from './snackbarAction';
import { updateCommonValues } from './CommonAction';

export const superAdminLogin = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true))
    services.postApiCall(
      endpoint.superAdmin.login,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.statusCode === services?.status_code?.success) {
          localStorage.setItem("token", res.data.token.refresh.token);

          dispatch(updateCommonValues("authToken", res.data.token.access.token));
          // const token = localStorage.getItem('token');
          services.setAuthorizationToken(res.data.token.access.token);

          dispatch(showSuccessSnackbar("Success!"));
          callback(res.data);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
        errorCallback(error);
      }
    )
  };

export const adminLogout = (params: any, callback: Function, errorCallback: Function): any =>
  async (dispatch: Function, getState: Function) => {
    dispatch(updateCommonValues("loader", true));
    services.getApiCall(
      endpoint.superAdmin.logout,
      params,
      (res: any) => {
        dispatch(updateCommonValues("loader", false));
        if (res?.data?.statusCode === services?.status_code?.success) {
          localStorage.setItem("token", "");
          dispatch(updateCommonValues("authToken", ""));
          services.setAuthorizationToken("");
          services.handleApiError()
          dispatch(showSuccessSnackbar(res?.msg));
          callback(res);
        } else {
          errorCallback(res);
          dispatch(showErrorSnackbar(res?.msg));
        }
      },
      (error: any) => {
        dispatch(updateCommonValues("loader", false));
      }
    );
  };