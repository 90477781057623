import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import CustomDropdown from "../../Components/customDropdown";
import {
  addExamDescription,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  uploadExamBanner,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import {
  length_FiveHundred,
  length_Ten,
  length_TwoHundredFifty,
  validateIsFileImageType,
} from "../../Utils/validators";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import SubmitButton from "../../Components/submitButton";
import { GradeList } from "../../Utils/dataConstant";

export default function CreateExamDescription() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<string>("");
  const [examList, setExamList] = useState([]);
  const [video, setVideo] = useState<string>("");
  const [examlogo, setExamlogo] = useState<any>("");
  const [ExamBanner, setExamBanner] = useState<any>("");
  const [originalExamBanner, setoriginalExamBanner] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [resetExam, setresetExam] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };

  const changeExamBanner = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setExamlogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadExamBanner(
          file,
          (res: any) => {
            setExamBanner(res.file);
            setoriginalExamBanner(res.originalName);
            delete res?.filePath;
          },
          () => { }
        )
      );
    }
  };

  const validation = () => {
    return course === "" || grade
      ? grade === ""
      : false || exam
        ? exam === ""
        : false ||
        subject === "" ||
        desc === "" ||
        desc.length < length_Ten ||
        // video === "" ||
        (video.length > 0 && video.length < length_Ten) ||
        ExamBanner === "" ||
        originalExamBanner === "";
  };

  function handleSubmit(e: any) {
    e.preventDefault();
    const Postdata: any = {
      examVideo: video,
      examBanner: ExamBanner,
      originalExamBanner: originalExamBanner,
      examDesc: desc,
      coursetypeId: course?._id,
      examTypeId: exam,
      subjectId: subject,
      gradeId: grade?._id,
    };

    if (video.length == 0) {
      delete Postdata["examVideo"];
    }
    if (
      Postdata["examTypeId"] === null ||
      Postdata["examTypeId"] === undefined ||
      Postdata["examTypeId"] === ""
    ) {
      delete Postdata["examTypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }

    dispatch(
      addExamDescription(
        Postdata,
        (res: any) => {
          setresetExam(true);
          setExam("");
          setVideo("");
          setExamlogo("");
          setExamBanner("");
          setoriginalExamBanner("");
          setDesc("");
          setResetParentCourse(true);
          setResetGradeList(true);
          setResetSubject(true);
          setResetCountryList(true);
        },
        () => { }
      )
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE EXAM DESCRIPTION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 mt-3">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.type === 3 || course?.type === 2 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Exam Name "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            )}
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Exam Banner"} />
              </div>
              <div className="col-8 ps-2">
                <ImagePickerAndViewer
                  value={examlogo}
                  handleChange={(e: any) => changeExamBanner(e)}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Exam Video"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Video Url"
                  value={video}
                  onChangeInput={(value: string) => setVideo(value)}
                  minlength={length_Ten}
                  maxlength={length_TwoHundredFifty - 1}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Exam Description "} />
              </div>
              <div className="col-6 ps-2">
                <textarea
                  className="w-100"
                  name="postContent"
                  rows={4}
                  cols={40}
                  value={desc}
                  onChange={(e: any) => setDesc(e.target.value)}
                  maxLength={length_FiveHundred}
                  minLength={length_Ten}
                />
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <SubmitButton name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
