import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  length_Eight,
  length_One,
  length_Two,
  startsWithSpace,
  validateDigitOnly,
} from "../../Utils/validators";
import { length_Ten } from "../../Utils/validators";
import Addmorebtn from "../../Components/addmorebtn";
import AdminTable from "../../Components/adminTable";
import CustomDropdown from "../../Components/customDropdown";
import {
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getMainCategoryList,
  getRegistrationDetails,
  getSessionList,
  addManuallyCourse,
  updateCourse,
  getGeneralTeacherList,
  getCountryList,
  bindSubjectList,
  getPackageList,
  getGeneralTimezoneList,
  getOtherTeacherList,
  getCourseTypelist,
  getPackageWebList,
  getTopicList,
  getLectureCount,
} from "../../Redux/Actions/AdminAction";
import moment from "moment";
import {
  GradeList,
  courseActivationStatusList,
} from "../../Utils/dataConstant";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import WeeklyDatePicker from "../../Components/weeklyDatePicker";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatePicker from "../../Components/datePicker";
import SchedulePopup from "../../Components/schedulePopup";
import { selectClasses } from "@mui/material";

export default function RegistrationDetail() {
  const dispatch: Function = useDispatch();
  const initialVal = [
    {
      subject: "",
      _id: "",
    },
  ];
  const NoOfClases = [
    {
      _id: 10,
      name: "10",
    },
    {
      _id: 15,
      name: "15",
    },
    {
      _id: 25,
      name: "25",
    },
  ];
  const classScheduleArr = [
    {
      _id: "9",
      name: "NineAM",
      time: "9.00AM",
      isSelected: false,
    },
    {
      _id: "10",
      name: "TenAM",
      time: "10.00AM",
      isSelected: false,
    },
    {
      _id: "11",
      name: "ElevenAM",
      time: "11.00AM",
      isSelected: false,
    },
    {
      _id: "12",
      name: "TwelveAM",
      time: "12.00AM",
      isSelected: false,
    },
    {
      _id: "13",
      name: "OnePM",
      time: "1.00PM",
      isSelected: false,
    },
    {
      _id: "14",
      name: "TwoPM",
      time: "2.00PM",
      isSelected: false,
    },
    {
      _id: "15",
      name: "ThreePM",
      time: "3.00PM",
      isSelected: false,
    },
    {
      _id: "16",
      name: "FourPM",
      time: "4.00PM",
      isSelected: false,
    },
    {
      _id: "17",
      name: "FivePM",
      time: "5.00PM",
      isSelected: false,
    },
    {
      _id: "18",
      name: "SixPM",
      time: "6.00PM",
      isSelected: false,
    },
    {
      _id: "19",
      name: "SevenPM",
      time: "7.00PM",
      isSelected: false,
    },
    {
      _id: "20",
      name: "EightPM",
      time: "8.00PM",
      isSelected: false,
    },
    {
      _id: "21",
      name: "NinePM",
      time: "9.00PM",
      isSelected: false,
    },
  ];
  const initdayArr = [
    {
      _id: "Sunday",
      name: "S",
      isSelected: false,
    },
    {
      _id: "Monday",
      name: "M",
      isSelected: false,
    },
    {
      _id: "Tuesday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Wednesday",
      name: "W",
      isSelected: false,
    },
    {
      _id: "Thursday",
      name: "T",
      isSelected: false,
    },
    {
      _id: "Friday",
      name: "F",
      isSelected: false,
    },
    {
      _id: "Saturday",
      name: "S",
      isSelected: false,
    },
  ];
  const [classesArr, setClassesArr] = useState<any>(NoOfClases);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userDetailsList, setUserDetailsList] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [subjectArr, setSubjectArr] = useState<any>(initialVal);

  const [resetCourse, setResetCourse] = useState(false);
  const [category, setCategory] = useState<any>("");
  const [categoryList, setCategoryList] = useState([]);
  const [resetCategory, setResetCategory] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [addcourse, setAddcourse] = useState(false);
  const [status, setStatus] = useState<any>("");
  const [resetStatus, setresetStatus] = useState(false);
  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [resetSession, setResetSession] = useState(false);
  const [duration, setDuration] = useState("");
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any>();
  const [resetBatch, setResetBatch] = useState(false);
  const [isEdit, setIsEdit] = useState<boolean>();
  const [detailList, setdetailList] = useState(false);
  const [timeSlot, setTimeSlot] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);
  const [show, setShhow] = useState<any>(false);

  const [editStatus, setEditStatus] = useState<any>("");

  const [countryCode, setCountryCode] = useState<any>("");
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [resetCountryCode, setResetCountryCode] = useState(false);
  const [selectClass, setSelectClass] = useState<any>("0");

  const [startDate, setStartDate] = useState("");
  const [mindate, setmindate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [classScheduleArray, setclassScheduleArray] =
    useState(classScheduleArr);

  const [open, setopen] = useState(false);
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [timeZone, setTimeZone] = useState<any>("");
  const [resetTimezone, setResetTimezone] = useState<any>(false);

  const [dayArray, setDayArray] = useState(initdayArr);
  const [selectedDays, setSelectedDays] = useState<any>([]);
  const [time, settime] = useState<string>("");
  const [classEndDate, setClassEndDate] = useState("");
  const [studentId, setStudentId] = useState("");
  const [packageOptionId, setPackageOptionId] = useState("");

  const [topic, setTopic] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryCodeList(res);
        },
        () => {}
      )
    );
  }, []);
  const getData = () => {
    const postData = {
      mobile: phoneNumber,
      countryCode: countryCode?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          setUserDetailsList(res);
        },
        () => {}
      )
    );
  };

  const onClickTime = (val: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(classScheduleArray));
    if (newArray[index]["isSelected"] === false) {
      newArray[index]["isSelected"] = true;
      setopen(true);
      setclassScheduleArray(newArray);
    } else {
      newArray[index]["isSelected"] = false;
    }
    setclassScheduleArray(newArray);
  };

  const onSelectTime = (val: any) => {
    settime(val?._id);
  };
  const calculateEndDate = () => {
    if (!startDate || selectClass <= 0 || selectedDays.length === 0) {
      setEndDate("");
      setClassEndDate("");
      return "";
    }

    let date = new Date(startDate);
    let count = 0;

    while (count < selectClass) {
      date.setDate(date.getDate() + 1); // Increment the date by one day

      if (selectedDays.includes(date.getDay())) {
        count++;
      }
    }
    setClassEndDate(date.toISOString().split("T")[0]);
    date.setDate(date.getDate() + 30);
    setEndDate(date.toISOString().split("T")[0]);
    return date.toISOString().split("T")[0];
  };
  useEffect(() => {
    calculateEndDate();
  }, [selectedDays]);
  const onClickDay = (val: any, index: any) => {
    const day = parseInt(index);
    const newSelectedDay: any = [...selectedDays];
    if (val?.isSelected === false) {
      newSelectedDay?.push(day);
    } else {
      let timeindex = newSelectedDay?.findIndex((obj: any) => obj === day);
      newSelectedDay?.splice(timeindex, 1);
    }
    setSelectedDays(newSelectedDay);
    const newArr = [...dayArray];
    newArr[index]["isSelected"] = !val?.isSelected;
    setDayArray(newArr);

    const postdata: any = {
      coursetypeId: course?._id,
    };
    // calculateEndDate()
    // dispatch(
    //   getPackageWebList(
    //     postdata,
    //     (res: any) => {
    //       res?.map((item: any) => {
    //         setpackageData(item?.packageOptions);
    //         item?.packageOptions?.map((value: any) => {
    //           setPackageOptionId(value?.packageOptionId);

    //           if (
    //             !startDate ||
    //             value?.offerLectureCount <= 0 ||
    //             selectedDays.length === 0
    //           ) {
    //             setEndDate("");
    //             setClassEndDate("");
    //             return "";
    //           }

    //           let date = new Date(startDate);
    //           let count = 0;

    //           while (count < value?.offerLectureCount) {
    //             date.setDate(date.getDate() + 1); // Increment the date by one day

    //             if (selectedDays.includes(date.getDay())) {
    //               count++;
    //             }
    //           }
    //           setClassEndDate(date.toISOString().split("T")[0]);
    //           date.setDate(date.getDate() + 30);
    //           setEndDate(date.toISOString().split("T")[0]);
    //           return date.toISOString().split("T")[0];
    //         });
    //       });
    //     },
    //     () => { }
    //   )
    // );
  };

  const OnchangePatternName = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setPhoneNumber(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };
  const phoneNumberLengthValidation = (number: string) => {
    if (countryCode?._id === 13) {
      return number?.length !== length_Eight;
    } else if (countryCode?._id === 13) {
      return number?.length !== length_Ten;
    }
  };
  const phoneValidations = () => {
    return (
      phoneNumber === "" ||
      phoneNumberLengthValidation(phoneNumber) ||
      countryCode === ""
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    setResetExam(true);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    setResetTeacher(true);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );

    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );

    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
    // const postdata: any = {
    //   coursetypeId: value?._id,
    // };
    // dispatch(
    //   getPackageWebList(
    //     postdata,
    //     (res: any) => {
    //       res?.map((item: any) => {
    //         setpackageData(item?.packageOptions);
    //         item?.packageOptions?.map((value: any) => {
    //           setlectureCount(value?.offerLectureCount);
    //           setPackageOptionId(value?.packageOptionId);
    //         });
    //       });
    //     },
    //     () => { }
    //   )
    // );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetExam(true);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    setResetTeacher(true);
    setGrade(value);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
    // const postdata: any = {
    //   coursetypeId: course?._id,
    //   gradeId: value._id,
    // };
    // dispatch(
    //   getPackageWebList(
    //     postdata,
    //     (res: any) => {
    //       res?.map((item: any) => {
    //         setpackageData(item?.packageOptions);
    //         item?.packageOptions?.map((value: any) => {
    //           setlectureCount(value?.offerLectureCount);
    //           setPackageOptionId(value?.packageOptionId);
    //         });
    //       });
    //     },
    //     () => { }
    //   )
    // );
  };
  const onChangeExam = (value: any) => {
    setExam(value);
    setResetExam(false);
    setResetSubject(true);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);

    const PostData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };

    dispatch(
      bindSubjectList(
        PostData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
    // const postData = {
    //   examtypeId: value._id,
    // };
    // dispatch(
    //   getGeneralPackageList(
    //     postData,
    //     (res: any) => {
    //       setBatchList(res);
    //     },
    //     () => {}
    //   )
    // );

    const postData: any = {
      coursetypeId: course?._id,
      examId: value._id,
    };

    dispatch(
      getPackageList(
        postData,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
    // const postdata: any = {
    //   coursetypeId: course?._id,
    //   examtypeId: value?._id,
    // };
    // dispatch(
    //   getPackageWebList(
    //     postdata,
    //     (res: any) => {
    //       res?.map((item: any) => {
    //         setpackageData(item?.packageOptions);
    //         item?.packageOptions?.map((value: any) => {
    //           setlectureCount(value?.offerLectureCount);
    //           setPackageOptionId(value?.packageOptionId);
    //         });
    //       });
    //     },
    //     () => { }
    //   )
    // );
  };
  const onChangeSubject = (value: any) => {
    setSubject(value);
    setResetSubject(false);
    setResetCategory(true);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    setResetTeacher(true);
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setCategoryList(res);
          if (course?.type === 2) {
            let tempStatus = res?.filter((item: any) => item?._id === 1);
            setCategoryList(tempStatus);
          }
        },
        () => {}
      )
    );
    dispatch(
      getGeneralTeacherList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setTeacherList(res);
        },
        () => {}
      )
    );
    const postdata: any = {
      coursetypeId: course?._id,
      subjectId: value?._id,
    };
    if (course?.type === 2) {
      postdata["gradeId"] = grade?._id;
    }
    if (course?.type == 1) {
      postdata["examId"] = exam?._id;
    }
    // dispatch(
    //   getPackageWebList(
    //     postdata,
    //     (res: any) => {
    //       res?.map((item: any) => {
    //         setpackageData(item?.packageOptions);
    //         item?.packageOptions?.map((value: any) => {
    //           setlectureCount(value?.offerLectureCount);
    //           setPackageOptionId(value?.packageOptionId);
    //         });
    //       });
    //     },
    //     () => { }
    //   )
    // );

    dispatch(
      getPackageList(
        postdata,
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: course?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          const updatedArray = res?.map((item: any) => {
            return { ...item, isSelected: false };
          });
          setTopicList(updatedArray);
        },
        () => {}
      )
    );
  };
  const onChangeTeacher = (value: any) => {
    setTeacher(value);
    setResetTeacher(false);
    setShhow(true);
  };
  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);
    setResetTopic(false);
  };
  const changeTimeZone = (val: any) => {
    setTimeZone(val);
  };
  const onChangeSession = (value: any) => {
    setSession(value);
    setResetSession(false);
    setresetStatus(true);
  };
  const onChangeMainCategory = (value: any) => {
    setCategory(value);
    setResetCategory(false);
    setResetBatch(true);
    setResetSession(true);
    setresetStatus(true);
    setResetTeacher(true);
  };
  const onChangeBatch = (value: any) => {
    setBatch(value);
    setResetBatch(false);
    setResetSession(true);
    setresetStatus(true);
  };
  const onChangeStatus = (value: any) => {
    setresetStatus(false);
    setStatus(value?.id);

    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res);
        },
        () => {}
      )
    );
  };
  const OnchangeDuration = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setDuration(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };

  function validate() {
    return (
      time === "" ||
      teacher === "" ||
      startDate === "" ||
      endDate === "" ||
      selectedDays?.length === 0
    );
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    setdetailList(true);
    const postData = {
      mobile: phoneNumber,
      countryCode: countryCode?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          setUserDetailsList(res);
          setStudentId(res?.studentId);
        },
        () => {}
      )
    );
  };
  const handleAddCourse = (e: any) => {
    e.preventDefault();
    const filterTopicArr = topicList?.filter(
      (item: any) => item?.isSelected === true
    );
    const TopicIdArr = filterTopicArr?.map((item: any) => item?._id);
    const postData = {
      studentId: "" + studentId,
      mobile: phoneNumber,
      packageId: "" + batch?._id,
      packageOptionId: "" + batch?.packageOptionId,
      timezoneId: timeZone?.timezoneId,
      teacherId: teacher?._id,
      startDate: startDate,
      endDate: classEndDate,
      subscriptionEndDate: endDate,
      time: time,
      days: selectedDays,
      topicIds: TopicIdArr,
      offerLectureCount: selectClass,
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      gradeId: grade?._id,
      maincategoryId: category._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      addManuallyCourse(
        postData,
        (res: any) => {
          setAddcourse(false);
          getData();
        },
        () => {}
      )
    );
  };
  const onPressAdd = () => {
    setAddcourse(true);
  };

  const handleEdit = (value: any) => {
    dispatch(
      getGeneralExamList(
        {
          courseId: value?.Course_type?._id,
        },
        (res: any) => {
          setExamList(res);
          setExam(value?.Exam_Name?._id);
        },
        () => {}
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setCategoryList(res);
          setCategory(value?.Main_Category?._id);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralPackageList(
        {
          examtypeId: value?.Exam_Name?._id,
        },
        (res: any) => {
          setBatchList(res);
          setBatch(value?.Batch_Name?._id);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value?.Exam_Name?._id,
        },
        (res: any) => {
          setSubjectList(res);
          setSubject(value?.Subject?._id);
        },
        () => {}
      )
    );
    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res);
          setSession(value?.Sessions?._id);
        },
        () => {}
      )
    );
    setIsEdit(true);
    setdetailList(false);

    setEditStatus(value?.course_Activation === true ? 1 : 2);
  };
  const onChangeEditStatus = (value: any) => {
    setEditStatus(value?._id === 1 ? true : false);
  };

  const handleupdateCourse = (e: any) => {
    e.preventDefault();
    const postData = {
      mobile: phoneNumber,
      packageId: batch?._id,
      subscriptionDetails: duration,
      isActive: editStatus,
    };
    dispatch(
      updateCourse(
        postData,
        {},
        (res: any) => {
          setIsEdit(false);
          getData();
        },
        () => {}
      )
    );
  };
  const validation = () => {
    return category === "" || subject === "" || course === "";
  };
  const changeTopic = (val: any, index: number) => {
    const newTopicArray = JSON.parse(JSON.stringify(topicList));
    newTopicArray[index]["isSelected"] = val.target.checked;
    setTopicList(newTopicArray);
    const filterTopicArr = newTopicArray?.filter(
      (item: any) => item?.isSelected === true
    );
    const TopicIdArr = filterTopicArr?.map((item: any) => item?._id);
    if (TopicIdArr?.length > 0) {
      dispatch(
        getLectureCount(
          {
            topicIds: TopicIdArr,
          },
          (res: any) => {
            setSelectClass(res?.count);
          },
          () => {}
        )
      );
    } else {
      setSelectClass("0");
    }
  };
  const renderEditMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#343a40" }}
        >
          Course Details
        </p>
        <div className="row mx-0">
          <div className="col-md-10 col-lg-10 col-xl-8">
            <form className="col-md-10" onSubmit={handleupdateCourse}>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course type"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Type Name"
                    setInputText={(value: any) => onChangeCourse(value)}
                    value={course}
                    options={courseList}
                    Isrequired={true}
                    reset={resetCourse}
                    defaultValue={userDetailsList?.Course_Details[0]?._id}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className=" col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    defaultValue={exam}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Subject"
                    setInputText={(value: any) => onChangeSubject(value)}
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                    defaultValue={subject}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Main Category"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Main Category"
                    setInputText={(value: any) => onChangeMainCategory(value)}
                    value={category}
                    options={categoryList}
                    Isrequired={true}
                    reset={resetCategory}
                    defaultValue={category}
                    disabled={true}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: any) => onChangeBatch(value)}
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                    defaultValue={batch}
                    disabled={true}
                  />
                </div>
              </div>

              {category?._id === 2 ? (
                <div className=" d-flex mb-2 align-items-center">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Session"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Session"
                      setInputText={(value: any) => onChangeSession(value)}
                      value={session}
                      options={sessionList}
                      Isrequired={true}
                      reset={resetSession}
                      defaultValue={session}
                      disabled={true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Activation"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Activation"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeEditStatus(value)
                    }
                    value={status}
                    options={courseActivationStatusList}
                    Isrequired={true}
                    reset={resetStatus}
                    defaultValue={editStatus}
                  />
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subscription Duration"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Subscription Duration"
                    value={duration}
                    onChangeInput={(value: { _id: String; name: String }) =>
                      OnchangeDuration(value)
                    }
                    maxlength={length_Two}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Submit"} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  const renderAddMode = () => {
    return (
      <div>
        <p
          className="p-1 mb-2 text-white mb-4"
          style={{ backgroundColor: "#343a40" }}
        >
          Course Details
        </p>
        <div className="row mx-0">
          <div className="col-md-10 col-lg-10 col-xl-8">
            <form className="col-md-10" onSubmit={handleAddCourse}>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Course type"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Type Name"
                    setInputText={(value: any) => onChangeCourse(value)}
                    value={course}
                    options={courseList}
                    Isrequired={true}
                    reset={resetCourse}
                    defaultValue={userDetailsList?.Course_Details[0]?._id}
                  />
                </div>
              </div>
              {course?.type === 2 ? (
                <div className="d-flex mb-2 align-items-center">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Grade"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      Isrequired={true}
                      options={GradeList}
                    />
                  </div>
                </div>
              ) : null}
              {course?.type === 2 || course?.type === 3 ? null : (
                <div className=" d-flex mb-2 align-items-center">
                  <div className=" col-3 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      reset={resetExam}
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                    />
                  </div>
                </div>
              )}

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Subject"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Subject"
                    setInputText={(value: any) => onChangeSubject(value)}
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Main Category"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Main Category"
                    setInputText={(value: any) => onChangeMainCategory(value)}
                    value={category}
                    options={categoryList}
                    Isrequired={true}
                    reset={resetCategory}
                  />
                </div>
              </div>

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: any) => onChangeBatch(value)}
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                  />
                </div>
              </div>
              {category?._id === 2 && course?.type !== 2 ? (
                <div className=" d-flex mb-2 align-items-center">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Session"} />
                  </div>
                  <div className="col-8 ps-2">
                    <CustomDropdown
                      lableName="Select Session"
                      setInputText={(value: any) => onChangeSession(value)}
                      value={session}
                      options={sessionList}
                      Isrequired={true}
                      reset={resetSession}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}

              <div className=" d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Topic Name"} />
                </div>
                <div className="col-8 ps-2 ">
                  <div className="border rounded p-3">
                    <div className=" input-border">
                      {topicList?.map((item: any, index: any) => {
                        const { _id, name } = item;
                        return (
                          <div key={item?._id} className="d-flex center p-1">
                            <div className="w-100">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex e-input align-items-center ms-3">
                                  <input
                                    id={_id}
                                    className="check"
                                    type="checkbox"
                                    checked={item?.assigned}
                                    onChange={(event) => {
                                      changeTopic(event, index);
                                    }}
                                  />
                                  <span className="checkVal ms-3">{name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>

              <div className="">
                <div className="">
                  <div className="d-flex  mb-2">
                    <div className="col-3  d-flex">
                      <LabelField lableName={"Lecture Count"} />
                    </div>
                    <div className="ps-2 col-8 fw-bold">{selectClass}</div>
                  </div>

                  <div className=" d-flex mb-2 align-items-center">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Teacher Name"} />
                    </div>
                    <div className="col-8 ps-2">
                      <CustomDropdown
                        lableName="Select Teacher Name"
                        setInputText={(value: any) => onChangeTeacher(value)}
                        value={teacher}
                        options={teacherList}
                        reset={resetTeacher}
                        Isrequired={true}
                      />
                    </div>
                  </div>

                  <div className=" d-flex mb-2 align-items-center">
                    <div className="col-3 align-item-center d-flex">
                      <LabelField lableName={"Time Zone"} />
                    </div>
                    <div className="col-8 ps-2 ">
                      <CustomDropdown
                        lableName="Select Time Zone"
                        value={timeZone}
                        options={timeZoneList}
                        Isrequired={true}
                        reset={resetTimezone}
                        setInputText={(value: any) => changeTimeZone(value)}
                      />
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-md-3">
                      <label className="mt-1 fw-bold">Start Date</label>
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        value={startDate}
                        setInputText={(value: string) => setStartDate(value)}
                        defaultValue={startDate}
                      />
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-md-3">
                      <label className="mt-1 fw-bold">Select Day</label>
                    </div>
                    <div className="col-md-9 d-flex flex-wrap">
                      {dayArray?.map((value: any, index: any) => {
                        return (
                          <div
                            className={`border rounded-circle slotdaydiv text-center cursor ${
                              value?.isSelected === true
                                ? "selectedGray text-white"
                                : ""
                            } me-3 `}
                            onClick={() => onClickDay(value, index)}
                          >
                            <p className="p-2  mb-0 text-center">
                              {value?.name}
                            </p>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-md-3">
                      <label className="mt-1 fw-bold">Time Slot</label>
                    </div>
                    <div className="col-md-8">
                      <div className="d-flex flex-wrap">
                        {classScheduleArr?.map((value: any, index: any) => {
                          return (
                            <div
                              className={`border rounded p-3 cursor  m-2 timeSlot ${
                                time === value?._id
                                  ? "selectedGray text-white"
                                  : ""
                              } `}
                              onClick={() => onSelectTime(value)}
                            >
                              {value?.time}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-md-3">
                      <label className="mt-1 fw-bold">
                        Last Live Class Date
                      </label>
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        value={classEndDate}
                        setInputText={(value: string) => setClassEndDate(value)}
                        defaultValue={endDate}
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="row d-flex mt-3">
                    <div className="col-md-3">
                      <label className="mt-1 fw-bold">
                        Subscription End Date
                      </label>
                    </div>
                    <div className="col-md-6">
                      <DatePicker
                        value={endDate}
                        setInputText={(value: string) => setEndDate(value)}
                        defaultValue={endDate}
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className=" d-flex mb-2 align-items-center mt-3">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Course Activation"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Course Activation"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeStatus(value)
                    }
                    value={status}
                    options={courseActivationStatusList}
                    Isrequired={true}
                    reset={resetStatus}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Submit"} validation={validation()} />
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  };
  const renderUserDetails = () => {
    return (
      <div>
        <>
          <p
            className="p-1 mb-2 text-white mb-4"
            style={{ backgroundColor: "#343a40" }}
          >
            Personal & Contact
          </p>
          <div className="row d-flex mx-0">
            <div className="col-4 d-flex ps-4">
              <p className="col-4"> First Name</p>
              <p className="col-8"> {userDetailsList?.firstName} </p>
            </div>
            <div className=" col-4 d-flex ps-4">
              <p className="col-4"> Last Name</p>
              <p className="col-8"> {userDetailsList?.lastName} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Contact</p>
              <p className="col-8"> {userDetailsList?.mobile} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Email Address</p>
              <p className="col-8"> {userDetailsList?.email} </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Status</p>
              <p className="col-8">
                {userDetailsList?.isActive === true ? "Yes" : "No"}
              </p>
            </div>

            <div className="col-4 d-flex ps-4">
              <p className="col-4">Active Plan</p>
              <p className="col-8">
                {" "}
                {userDetailsList?.ActivePlan === true ? "Yes" : "No"}
              </p>
            </div>
            <div className="col-4 d-flex ps-4">
              <p className="col-4">Is Mobile Verify</p>
              <p className="col-8">
                {userDetailsList?.isRegistered === true ? "Yes" : "No"}
              </p>
            </div>
          </div>

          <p
            className="p-1 mb-2 text-white mb-4"
            style={{ backgroundColor: "#343a40" }}
          >
            <div className="d-flex justify-content-between me-2 align-items-center">
              <span>Course Details</span>
            </div>
          </p>
          {userDetailsList?.Course_Details?.map((item: any, index: any) => {
            return (
              <div className="row d-flex mx-0">
                <div className="col-6 d-flex ps-4">
                  <p className="col-4">Course</p>
                  <p className="col-8">{item?.name}</p>
                </div>
                <div className=" col-6 d-flex ps-4">
                  <p className="col-4">Registration Date</p>
                  <p className="col-8">
                    {}
                    {moment(item?.Registration_Date).format("DD - MM - YYYY")}
                  </p>
                </div>
              </div>
            );
          })}
          <div>
            <p
              className="p-1 mb-2 text-white mb-4"
              style={{ backgroundColor: "#343a40" }}
            >
              Subscription Details
            </p>
            <AdminTable
              tableData={userDetailsList?.Subscription_Details}
              pagename={"registrationDetails"}
              tableHead={[
                "S.N",
                "Exam/Grade",
                "Batch Name",
                "Course Activation",
                "Activation End Date",
                "Actions",
              ]}
              edit={true}
              handleEdit={(value: any) => handleEdit(value)}
            />
          </div>
          <div className="d-flex justify-content-end">
            <Addmorebtn name={"Add More"} handleClick={onPressAdd} />
          </div>
        </>
      </div>
    );
  };

  const onChangeCountry = (value: any) => {
    setCountryCode(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"Registration Detail"}
        isEditMode={isEdit}
        setisEditMode={setIsEdit}
      />
      <div className="px-4 mx-0 pt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between">
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Phone Number"} />
              </div>
              <div className="col-8 d-flex">
                <div className="col-6 ps-0">
                  <CustomDropdown
                    lableName="Select Country Code"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={countryCode}
                    options={countryCodeList}
                    Isrequired={true}
                    reset={resetCountryCode}
                    defaultValue={countryCode?._id}
                  />
                </div>
                <InputField
                  placeholder="Enter Phone Number"
                  value={phoneNumber}
                  onChangeInput={(value: { _id: String; name: String }) =>
                    OnchangePatternName(value)
                  }
                  maxlength={
                    countryCode?._id === 13 ? length_Eight : length_Ten
                  }
                  minlength={
                    countryCode?._id === 13 ? length_Eight : length_Ten
                  }
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <Submitbtn name={"Search"} validation={phoneValidations()} />
            </div>
          </div>
        </form>
        {!addcourse && userDetailsList && detailList ? (
          <div>{renderUserDetails()}</div>
        ) : (
          ""
        )}
        {addcourse ? <div>{renderAddMode()}</div> : ""}
        {isEdit ? <>{renderEditMode()}</> : ""}
      </div>
    </div>
  );
}
