import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import Addmorebtn from "../../Components/addmorebtn";
import CustomDropdown from "../../Components/customDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  addCourseDescFeature,
  assignFeature,
  bindSubjectList,
  courseDescFeatureList,
  getCountryList,
  getCourseTypelist,
  getGeneralCourseList,
  getGeneralExamList,
  getMainCategoryList,
} from "../../Redux/Actions/AdminAction";
import {
  validateFullName,
  startsWithSpace,
  length_Ten,
  length_Hundred,
  length_Two,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { GradeList } from "../../Utils/dataConstant";

export default function AssignFeature() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [featureList, setFeatureList] = useState<any>([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [featureData, setFeatureData] = useState([]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
          setResetParentCourse(true);
          setresetExam(true);
          setResetGradeList(true);
          setResetSubject(true);
          setFeatureList([]);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);

    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value?._id);

    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const Data = {
      coursetypeId: course?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: value._id,
    };
    if (
      Data["examtypeId"] === null ||
      Data["examtypeId"] === undefined ||
      Data["examtypeId"] === ""
    ) {
      delete Data["examtypeId"];
    }
    if (
      Data["gradeId"] === null ||
      Data["gradeId"] === undefined ||
      Data["gradeId"] === ""
    ) {
      delete Data["gradeId"];
    }
    dispatch(
      courseDescFeatureList(
        Data,
        (res: any) => {
          setFeatureList(res);
          setFeatureData(res?.data);
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData = {
      coursetypeId: course?._id,
      examtypeId: exam,
      subjectId: subject,
      gradeId: grade?._id,
      level: featureData,
    };
    if (
      PostData["examtypeId"] === null ||
      PostData["examtypeId"] === undefined ||
      PostData["examtypeId"] === ""
    ) {
      delete PostData["examtypeId"];
    }
    if (
      PostData["gradeId"] === null ||
      PostData["gradeId"] === undefined ||
      PostData["gradeId"] === ""
    ) {
      delete PostData["gradeId"];
    }
    dispatch(
      assignFeature(
        PostData,
        (res: any) => {
          setresetExam(true);
          setExam("");
          setresetExam(true);
          setResetParentCourse(true);
          setResetGradeList(true);
          setResetSubject(true);
        },
        () => { }
      )
    );
  };

  const validation = () => {
    return course === "";
  };

  const changeCheckBox = (event: any, levelindex: any, featureindex: any) => {
    let newArrr = JSON.parse(JSON.stringify(featureData));
    newArrr[levelindex].features[featureindex]["isSelected"] =
      event.target.checked;
    setFeatureData(newArrr);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"ASSIGN FEATURE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-9 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.type === 2 || course?.type === 3 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Exam Name "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
          </div>
          {featureData?.length > 0 ? (
            <div className="d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Features"} />
              </div>
              <div className="d-flex ms-3">
                <div className="p-3 w-100">
                  <div className="d-flex ms-2">
                    <div className="d-flex">
                      <div className="col">
                        <table>
                          <tr>
                            <td className="mt-2 p-2 text-break featureHeight fw-bold text-center">
                              {"FEATURES"}
                            </td>
                          </tr>
                          {featureList?.featureNames?.map((value: any) => {
                            return (
                              <tr>
                                <td className="mt-2 p-2 text-break featureHeight">
                                  {value}
                                </td>
                              </tr>
                            );
                          })}
                        </table>
                      </div>
                      {featureData?.map((item: any, levelindex: any) => {
                        return (
                          <>
                            <table>
                              <tr>
                                <td className="mt-2 p-2 text-break featureHeight fw-bold text-center">
                                  {item.levelName ? item.levelName : "LEVEL"}
                                </td>
                              </tr>
                              {item?.features?.map(
                                (value: any, featureindex: any) => {
                                  return (
                                    <>
                                      <tr>
                                        <td className="mt-2 p-2 text-break featureHeight fw-bold text-center">
                                          <input
                                            className="check"
                                            type="checkbox"
                                            checked={value?.isSelected}
                                            onChange={(event) => {
                                              changeCheckBox(
                                                event,
                                                levelindex,
                                                featureindex
                                              );
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    </>
                                  );
                                }
                              )}
                            </table>
                          </>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
