import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  teacherOverallRecord,
} from "../../Redux/Actions/AdminAction";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import CustomDropdown from "../../Components/customDropdown";
import SubmitButton from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import ConversionPopup from "../../Components/conversionPopup";
import { useNavigate } from "react-router-dom";

export default function Conversion() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(5, "days").format("YYYY-MM-DD")
  );
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [conversionData, setConversionData] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
    const postData = {
      countryId: country?._id,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      teacherOverallRecord(
        postData,
        (res: any) => {
          setConversionData(res);
        },
        () => { }
      )
    );
  }, []);

  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetCourse(true);
    setResetSubject(true);
    const postData = {
      countryId: data?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
    const postdata = {
      countryId: data?._id,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      teacherOverallRecord(
        postdata,
        (res: any) => {
          setConversionData(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    const postdata = {
      countryId: country?._id,
      startDate: startDate,
      endDate: endDate,
      coursetypeId: value._id,
    };
    dispatch(
      teacherOverallRecord(
        postdata,
        (res: any) => {
          setConversionData(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const postdata = {
      countryId: country?._id,
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course._id,
      subjectId: value._id,
    };
    dispatch(
      teacherOverallRecord(
        postdata,
        (res: any) => {
          setConversionData(res);
        },
        () => { }
      )
    );
  };
  const handleNavigate = (value: any) => {
    navigate("../conversionDetails", { state: { details: value, sDate: startDate, eDate: endDate, pageName: "teacherConversion" } });
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata = {
      countryId: country?._id,
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      subjectId: subject,
    };
    if (
      postdata["coursetypeId"] === null ||
      postdata["coursetypeId"] === undefined ||
      postdata["coursetypeId"] === ""
    ) {
      delete postdata["coursetypeId"];
    }
    if (
      postdata["subjectId"] === null ||
      postdata["subjectId"] === undefined ||
      postdata["subjectId"] === ""
    ) {
      delete postdata["subjectId"];
    }
    dispatch(
      teacherOverallRecord(
        postdata,
        (res: any) => {
          setConversionData(res);
        },
        () => { }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="mt-5">
          <PaginationTable
            tableData={conversionData ? conversionData : []}
            pagename={"teacherConversion"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "TEACHER NAME",
              "DEMO COUNT",
              "PAYMENT",
              "CONVERSION",
              "RATING",
              "ACTION",
            ]}
            edit={true}
            handleNavigate={(value: any) => handleNavigate(value)}
          />
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CONVERSION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(startDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className=" mb-2  mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>

        <div className="demoReqCountry mt-4 col-md-12">
          <div className="d-flex col-md-7">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4 ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-3 d-flex align-items-center mt-2">
            <LabelField lableName={"Course type"} />
            <div className="col-md-6">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
                reset={resetCourse}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex align-items-center mt-2">
            <LabelField lableName={"Subject Name"} />
            <div className="col-md-5">
              <CustomDropdown
                lableName="Select Subject"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                reset={resetSubject}
              />
            </div>
          </div>
        </div>
        {renderListMode()}
        {openPopup ? (
          <ConversionPopup
            open={openPopup}
            data={"popupData"}
            handleClose={handleClose}
          />
        ) : (
          ""
        )}
      </div>
    </div>
  );
}
