import { useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import InputField from '../../Atoms/InputField';
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import { validateIsFileImageType, startsWithSpace, validateFullName, length_Three, length_Two, length_Hundred, image_size_1MB } from '../../Utils/validators';
import { addLanguage, uploadLanguageLogo } from '../../Redux/Actions/AdminAction';
import { useDispatch } from 'react-redux';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import SubmitButton from '../../Components/submitButton';

export default function CreateLanguage() {
  const dispatch: Function = useDispatch();
  const [language, setLanguage] = useState<string>("");
  const [abbreviation, setAbbreviation] = useState<string>("");
  const [languageLogo, setLanguageLogo] = useState<any>("");
  const [uploadLanguagelogo, setUploadLanguagelogo] = useState<any>("");

  const onChangeLanguage = (value: any) => {
    if (validateFullName(value)) {
      setLanguage(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  }

  const onChangeAbbreviation = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setAbbreviation(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  }

  const handleOptionImageChange = (e: { preventDefault: () => void; target: { files: any[]; }; }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 1 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setLanguageLogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadLanguageLogo(file, (res: any) => {
        delete res?.filePath;
        setUploadLanguagelogo(res);
      }, () => { }));
    }
  }

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const postData = {
      language: language,
      abbreviation: abbreviation,
      languageIcon: uploadLanguagelogo?.file,
      originalLanguageIcon: uploadLanguagelogo?.originalName
    }

    dispatch(
      addLanguage(
        postData,
        (res: any) => {
          setLanguage("");
          setAbbreviation("");
          setLanguageLogo("");
        },
        () => { }
      )
    );
  }

  const validation = () => {
    return (
      language === "" ||
      language.length < length_Two ||
      abbreviation === "" ||
      abbreviation.length < length_Two ||
      languageLogo === ""

    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"ADD LANGUAGE"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='' onSubmit={handleSubmit}>

          <div className="col-7">
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Language"} />
              </div>
              <div className="col-8">
                <InputField
                  placeholder="Enter Language"
                  value={language}
                  onChangeInput={(value: any) => onChangeLanguage(value)}
                  maxlength={length_Hundred}
                  minlength={length_Two}
                  isRequired={true}
                />

              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Language Abbreviation"} />
              </div>
              <div className="col-8">
                <InputField
                  placeholder="Enter Language Abbreviation"
                  value={abbreviation}
                  onChangeInput={(value: any) => onChangeAbbreviation(value)}
                  maxlength={length_Three - 1}
                  minlength={length_Two}
                  isRequired={true}
                />

              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Language Icon"} />
              </div>
              <div className="col-8 ps-2">
                <ImagePickerAndViewer
                  lableName={"Option Image"}
                  value={languageLogo}
                  handleChange={(e: any) => handleOptionImageChange(e)}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <SubmitButton
              name={"Submit"}
              validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  )
}

