import axios, { AxiosResponse } from "axios";
import { setInitialState, updateMoreValues } from "../Redux/Actions/CommonAction";
import { store } from "../store";

const $http = axios.create({
  baseURL: process.env.REACT_APP_BASEURL,
  timeout: 30000,
  headers: {
    "Content-Type": "application/json",
  },
});

$http.interceptors.response.use(
  (config: any) => config,
  (error: any) => {
    if (
      error?.message?.includes('403') // Block
    ) {
      handleApiError();
    } else {
      // CommonFunction.showSnackbar(message);
    }
    return error;
  }
);


const handleApiError = async () => {
  const { dispatch } = store;
  await dispatch(updateMoreValues({ ACCESS_TOKEN: "" }));
  await dispatch(setInitialState());
  await window.location.assign("/");
  return
};

$http.interceptors.request.use(
  async (req) => {
    if (req?.headers) {
      const getState = await store?.getState();
      if (getState) {
        const authToken = store.getState()?.Auth?.authToken;

        if (authToken && authToken.length > 0) {
          req.headers.Authorization = `Bearer ${authToken}`;
        }
      }
    }
    return req;
  },
  (err) => {
    return err;
  }
);

const setAuthorizationToken = (token: any) => {
  if (token) {
    $http.defaults.headers.common.Authorization = `Bearer ${token}`;
  }
};

let status_code = {
  success: 200,
  successAction: 201,
  notFound: 204,
  badRequest: 400,
  Unauthorized: 401,
  invalid: 400,
  timeout: 408,
  userDelete: 410,
  serverError: 500,
};

const postApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  try {
    $http
      .post(endPoint, JSON.stringify(params))
      .then((response: any) => {
        if (response && response?.status === 200) {
          successCallback(response?.data);
        } else {
          successCallback(response?.response?.data);
        }
      })
      .catch((error) => {
        errorCallback && errorCallback(error?.response);
      });
  } catch (error) {
    console.log('errorCallback', error)
  }

};

const postApiWebCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  try {
    $http
      .post(endPoint, JSON.stringify(params))
      .then((response: any) => {
        if (response && response?.status === 200) {
          successCallback(response?.data);
        } else {
          successCallback(response?.response?.data);
        }
      })
      .catch((error) => {
        errorCallback && errorCallback(error?.response);
      });
  } catch (error) {
    console.log('errorCallback', error)
  }

};

const postUploadApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, params, {
      headers: {
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const putApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .put(endPoint, params)
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const postUploadMedia = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .post(endPoint, params, {
      headers: {
        Accept: "*/*",
        "content-type": "multipart/form-data",
      },
    })
    .then((response: any) => {
      if (response && response?.status === 200) {
        successCallback(response?.data);
      } else {
        successCallback(response?.response?.data);
      }
    })
    .catch((error) => {
      errorCallback && errorCallback(error?.response);
    });
};

const getApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .get(endPoint + params, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const patchApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .patch(endPoint, JSON.stringify(params))
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

const deleteApiCall = (
  endPoint: string,
  params: any,
  successCallback: Function,
  errorCallback: Function
) => {
  $http
    .delete(endPoint + params, {})
    .then((response: any) => {
      successCallback(response);
    })
    .catch((error) => {
      errorCallback(error.response);
    });
};

export default {
  postUploadMedia,
  postApiCall,
  postApiWebCall,
  postUploadApiCall,
  getApiCall,
  patchApiCall,
  putApiCall,
  deleteApiCall,
  handleApiError,
  setAuthorizationToken,
  status_code,
  $http,
};
