import DemoRequestCard from "../../Components/demoRequestCard";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomDropdown from "../../Components/customDropdown";
import SubmitButton from "../../Components/submitButton";
import {
  getCountryList,
  getCourseTypelist,
  getDemoRequestList,
  getSourceList,
} from "../../Redux/Actions/AdminAction";
export default function DemoRequest() {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(7, "days").format("YYYY-MM-DD")
  );
  const [source, setSource] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });

  const [courseList, setCourseList] = useState([]);
  const [sourceList, setSourceList] = useState([]);
  const [countryList, setCountryList] = useState<any>([]);
  const [demoRequestList, setDemoRequestList] = useState([]);

  const [resetCourse, setResetCourse] = useState(false);
  const [resetSource, setResetSource] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getSourceList(
        "",
        (res: any) => {
          setSourceList(res);
        },
        () => { }
      )
    );
    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
    getDemoRequest();
  }, []);

  const getDemoRequest = () => {
    dispatch(
      getDemoRequestList(
        {
          startDate: startDate,
          endDate: endDate,
          countryId: country?._id,
          statusId: 1,
        },
        (res: any) => {
          setDemoRequestList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      coursetypeId: value?._id,
      statusId: 1,
    };
    if (source) {
      postData["sourceId"] = source?._id;
    }
    dispatch(
      getDemoRequestList(
        postData,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetCourse(true);
    const postData = {
      countryId: data?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
    dispatch(
      getDemoRequestList(
        {
          startDate: startDate,
          endDate: endDate,
          countryId: data?._id,
          statusId: 1,
        },
        (res: any) => {
          setDemoRequestList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSource = (value: any) => {
    setSource(value);
    setResetSource(false);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      statusId: 1,
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (source) {
      postData["sourceId"] = source?._id;
    }
    dispatch(
      getDemoRequestList(
        postData,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => { }
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"DEMO REQUEST"} />
      <div className="px-4 mx-0 mt-2 formDiv ">
        <form onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center mt-2">
              <LabelField lableName={"Source"} />
              <div className="col-md-9 ">
                <CustomDropdown
                  lableName="Select Source"
                  setInputText={(value: any) => onChangeSource(value)}
                  value={source}
                  options={sourceList}
                  reset={resetSource}
                />
              </div>
            </div>

            <div className=" d-flex mb-2 align-items-center  mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>
        <div className="demoReqCountry mt-4">
          <div className="d-flex">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4 ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-4 d-flex ms-3 align-items-center mt-2">
            <LabelField lableName={"Course type"} />
            <div className="col-md-6">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
                reset={resetCourse}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {demoRequestList?.map((item, index) => {
            return (
              <div className="col-md-4 mt-2 p-2">
                <DemoRequestCard
                  key={index}
                  data={item}
                  name={"ALIGN"}
                  pageName={"salesPanelDemoRequest"}
                  getDemoReq={getDemoRequest}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
