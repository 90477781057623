import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade"; 
import { useDispatch } from "react-redux";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import moment from "moment";

 

export default function NotificationPopUp(props: any) {
  const dispatch = useDispatch();
  useEffect(() => { }, []);

  let date = new Date(props?.dataCard?.scheduledAt);
  date.toString();

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    props.handleClose();
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={props.openPopup}
        onClose={props.handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={props.openPopup}>
          <div className="modeldivChat ">
          <div className="  mt-0 justify-content-center  ">
            <div className="d-flex">
              <p className="fw-bolder fs-5 text-primary">Notification</p>
              <div className="col mb-2 d-flex justify-content-end ">
                <ClearOutlinedIcon
                  className="colorblue cursor font28"
                  onClick={closePopup}
                />
              </div>
            </div>


            <div className=" row align-items-center boxshadow border border-2 p-4 cursor mb-2 rounded">
              <div className="col-2 col-sm-1 justify-centent-end">
                <img
                  src={props?.dataCard?.notificationIcon}
                  alt="icon"
                  className="notiicon"
                />
              </div>
              <div className="col-7 col-sm-8 ">
                <p className="fw-bold text-success fs-5 m-0 ellipsisNotificationTitle">
                  {props?.dataCard?.notificationTitle}
                </p>

                <p className="  m-0  text-break">
                  {props?.dataCard?.notificationText}
                </p>
              </div>
              <div className="col-3 d-flex justify-content-end">
                <p className="font10 text-secondary">{`${moment(
                  date,
                  "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                ).format("h:mm a")}`}</p>
              </div>
            </div>
          </div>
          </div>
        </Fade>
      </Modal>
      
    </>
  );
}
