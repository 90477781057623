import TablePagination from "@mui/material/TablePagination";
import { title } from "process";
import { ReactElement, JSXElementConstructor, ReactNode, ReactPortal } from "react";
import "../App.css";

interface props {
  count?: any,
  tabs?: any,
  onTabChange?: any,
  selectedIndex?: any,
  title?: any,
  tableData?: any,
  page?: any,
  rowsPerPage?: any,
  handleChangePage?: any,
  handleChangeRowsPerPage?: any,
  rowsPerPageOptions?: any,
}
function PaginationView(props: props) {
  const {
    count,
    tabs,
    onTabChange,
    selectedIndex,
    title,
    tableData,
    page,
    rowsPerPage,
    handleChangePage,
    handleChangeRowsPerPage,
    rowsPerPageOptions,
  } = props

  return (
    <div className="px-3">
      <h5 className="font-weight-bold">{title}</h5>
      <div className="d-flex justify-content-end align-items-center paginateno">
        {tabs?.map((data: string | number | boolean | ReactElement<any, string | JSXElementConstructor<any>> | Iterable<ReactNode> | ReactPortal | null | undefined, index: number) => {
          return (
            <button
              className={`add_Question-btn btn btn btn-sm me-4 ${selectedIndex === index + 1 ? "btn-success" : "btn-secondary"
                }`}
              onClick={(e) => onTabChange(e, index + 1)}
            >
              {data}
            </button>
          );
        })}
        <TablePagination
          component="div"
          count={count ? count : tableData?.length}
          //   count={tableData?.length}
          page={page}
          onPageChange={handleChangePage}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={handleChangeRowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
        />
      </div>
    </div>
  );
}

export default PaginationView;
