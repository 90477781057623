export default {
  DataEntrySidebarData: [
    {
      title: " TOPIC",
      subNav: [
        {
          title: "CREATE",
          path: "createTopic",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateTopic",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "SUB-TOPIC",
      subNav: [
        {
          title: "CREATE",
          path: "createSubTopic",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateSubTopic",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "LECTURE",
      subNav: [
        {
          title: "CREATE",
          path: "createLecture",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateLecture",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "LIVE/PREMEIR VIDEO",
      subNav: [
        {
          title: "CREATE",
          path: "createlivepremierVideo",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updatelivepremeirvideo",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "LIVE/RECORDED VIDEO",
      subNav: [
        {
          title: "CREATE",
          path: "createRecordedVideo",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateLiveRecordedVideo",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: " NOTIFICATION",
      subNav: [
        {
          title: "CREATE",
          path: "createNotification",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "UpdateNotification",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM SET",
      subNav: [
        {
          title: "CREATE",
          path: "createExamSet",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExamSet",
          cName: "sub-nav",
        },
        {
          title: "COPY EXAM SET  ",
          path: "copyExamSet",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "QUESTION",
      subNav: [
        {
          title: "CREATE",
          path: "createQuestion",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateQuestion",
          cName: "sub-nav",
        },
        {
          title: "SET QUESTION ORDER",
          path: "questionOrder",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "QUESTION BANK",
      subNav: [
        {
          title: "CREATE",
          path: "questionBank",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateQuestionBank",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "BLOG",
      subNav: [
        {
          title: "CREATE",
          path: "createBlog",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateBlog",
          cName: "sub-nav",
        },
      ],
    },
  ],
};
