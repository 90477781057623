import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import { useNavigate } from "react-router-dom";
import {
  getCountryList,
  getFeedbackForGraph,
  getFeedbackForGraphLectureWise,
  getLiveTeacherFeedbackList,
  getStudentListFeedback,
} from "../../Redux/Actions/AdminAction";
import { updateTeacherList } from "../../Redux/Actions/SuperAdminAction";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import FeedbackGraphs from "../../Components/feedbackGraphs";

export default function PtmFeedback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [feedbacklist, setFeedbacklist] = useState<any>([]);

  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(5, "days").format("YYYY-MM-DD")
  );
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [student, setStudent] = useState<any>("");
  const [studentList, setStudentList] = useState([]);
  const [resetStudent, setResetStudent] = useState(false);
  const tabs = ["LIST VIEW", "GRAPHICAL VIEW"];
  const [selectedTabIndex, setSelectedTabIndex] = useState<any>(1);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);

  const [qLabel1, setQLabel1] = useState<any>([]);
  const [GraphdataQ1, setGraphdataQ1] = useState<any>([]);
  const [question1, setQuestion1] = useState<any>("");

  const [qLabel2, setQLabel2] = useState<any>([]);
  const [GraphdataQ2, setGraphdataQ2] = useState<any>([]);
  const [question2, setQuestion2] = useState<any>("");

  const [qLabel3, setQLabel3] = useState<any>([]);
  const [GraphdataQ3, setGraphdataQ3] = useState<any>([]);
  const [question3, setQuestion3] = useState<any>("");

  const [qLabel4, setQLabel4] = useState<any>([]);
  const [GraphdataQ4, setGraphdataQ4] = useState<any>([]);
  const [question4, setQuestion4] = useState<any>("");

  const [xLabel1, setxLabel1] = useState<any>([]);
  const [Graphdata1, setGraphdata1] = useState<any>([]);

  let temp = 0;
  temp = page * rowsPerPage + 1;

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postData = {
      countryId: country?._id,
    };

    dispatch(
      getStudentListFeedback(
        postData,
        (res: any) => {
          setStudentList(res);
        },
        () => {}
      )
    );

    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      updateTeacherList(
        postdata,
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetStudent(true);
    setResetTeacher(true);
    setFeedbacklist([]);
    xLabel1.length = 0;
    Graphdata1.length = 0;
    qLabel1.length = 0;
    GraphdataQ1.length = 0;
    qLabel2.length = 0;
    GraphdataQ2.length = 0;
    qLabel3.length = 0;
    GraphdataQ3.length = 0;
    qLabel4.length = 0;
    GraphdataQ4.length = 0;

    const postData = {
      countryId: data?._id,
    };

    dispatch(
      getStudentListFeedback(
        postData,
        (res: any) => {
          setStudentList(res);
        },
        () => {}
      )
    );
    const postdata = {
      countryId: data?._id,
    };
    dispatch(
      updateTeacherList(
        postdata,
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeStudent = (value: any) => {
    setResetStudent(false);
    setStudent(value);
  };

  const onChangeTeacher = (value: any) => {
    setTeacher(value);
    setResetTeacher(false);
    const postData = {
      startDate: startDate,
      endDate: endDate,
      studentId: student?._id,
      teacherId: value?.empId,
    };

    dispatch(
      getLiveTeacherFeedbackList(
        postData,
        (res: any) => {
          setFeedbacklist(res?.data);
        },
        () => {}
      )
    );
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      studentId: student?._id,
      teacherId: value?.empId,
    };

    dispatch(
      getFeedbackForGraphLectureWise(
        postdata,
        (res: any) => {
          xLabel1.length = 0;
          Graphdata1.length = 0;
          res?.data?.map((value: any) => {
            xLabel1?.push(value?.lectureName);
            Graphdata1?.push(value?.rating);
          });
        },
        () => {}
      )
    );
    const Data = {
      startDate: startDate,
      endDate: endDate,
      studentId: student?._id,
      teacherId: value?.empId,
    };

    dispatch(
      getFeedbackForGraph(
        Data,
        (res: any) => {
          qLabel1.length = 0;
          GraphdataQ1.length = 0;
          setQuestion1(res?.data[0]?.question);
          res?.data[0]?.lectureData?.map((value1: any) => {
            qLabel1?.push(value1?.lectureName);
            GraphdataQ1?.push(value1?.totalRating);
          });

          qLabel2.length = 0;
          GraphdataQ2.length = 0;
          setQuestion2(res?.data[1]?.question);
          res?.data[1]?.lectureData?.map((value2: any) => {
            qLabel2?.push(value2?.lectureName);
            GraphdataQ2?.push(value2?.totalRating);
          });

          qLabel3.length = 0;
          GraphdataQ3.length = 0;
          setQuestion3(res?.data[2]?.question);
          res?.data[2]?.lectureData?.map((value3: any) => {
            qLabel3?.push(value3?.lectureName);
            GraphdataQ3?.push(value3?.totalRating);
          });

          qLabel4.length = 0;
          GraphdataQ4.length = 0;
          setQuestion4(res?.data[3]?.question);
          res?.data[3]?.lectureData?.map((value4: any) => {
            qLabel4?.push(value4?.lectureName);
            GraphdataQ4?.push(value4?.totalRating);
          });
        },
        () => {}
      )
    );
  };

  const handleNavigate = (value: any) => {
    navigate("/OperationPanel/TeacherLiveFeedback", {
      state: { pageName: "PtmFeedback", PtmData: value, isFeedback: true },
    });
  };

  const onSubmit = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      studentId: student?._id,
    };

    dispatch(
      getLiveTeacherFeedbackList(
        postData,
        (res: any) => {
          setFeedbacklist(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeTabs = (e: { preventDefault: () => void }, status: number) => {
    setSelectedTabIndex(status);
  };
  const renderListMode = () => {
    return (
      <div className="">
        <PaginationTable
          tableData={feedbacklist ? feedbacklist : []}
          pagename={"PtmFeedback"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "COURSE TYPE",
            "EXAM NAME",
            "SUBJECT NAME",
            "LECTURE NAME",
            "OVERALL RATING",
            "ACTION",
          ]}
          handleNavigate={(value: any) => handleNavigate(value)}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PTM FEEDBACK"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className=" d-flex flex-wrap  mt-3">
          <div className="col-md-3 d-flex mb-2">
            <LabelField lableName={"Start Date"} />
            <div className="col-md-6">
              <DatePicker
                value={moment(startDate).format("YYYY-MM-DD")}
                setInputText={(value: string) => setStartDate(value)}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"End  Date"} />
            <div className="col-md-8 ">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => setEndDate(value)}
              />
            </div>
          </div>
          <div className=" d-flex justify-content-center">
            <button
              type="button"
              className="btn btn-dark mb-3 align-items-center liveSearchBtn "
              onClick={() => onSubmit()}
            >
              Apply Filter
            </button>
          </div>
        </div>
        <div className="demoReqCountry mt-2 col-md-12">
          <div className="d-flex col-md-6">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4 ${
                      selectedIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                    }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-3 d-flex align-items-center mt-2">
            <LabelField lableName={"Student"} />
            <div className="col-md-7">
              <CustomDropdown
                lableName="Select Student Name"
                setInputText={(value: any) => onChangeStudent(value)}
                value={student}
                options={studentList}
                reset={resetStudent}
              />
            </div>
          </div>

          <div className="col-md-4 d-flex ms-3 align-items-center mt-2">
            <LabelField lableName={"Teacher"} />
            <div className="col-md-5">
              <CustomDropdown
                lableName="Select Teacher Name"
                setInputText={(value: any) => onChangeTeacher(value)}
                value={teacher}
                options={teacherList}
                reset={resetTeacher}
              />
            </div>
          </div>
        </div>

        <div className="pe-5 d-flex justify-content-between mx-2 ps-2 mt-5">
          <div>
            {tabs?.map((data: string, index: number) => {
              return (
                <button
                  className={`add_Question-btn btn     me-4 ${
                    selectedTabIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                  }`}
                  onClick={(e) => onChangeTabs(e, index + 1)}
                >
                  {data}
                </button>
              );
            })}
          </div>
        </div>
        {selectedTabIndex === 2 ? (
          <div className="w-100">
            <FeedbackGraphs
              qLabel1={qLabel1}
              GraphdataQ1={GraphdataQ1}
              question1={question1}
              qLabel2={qLabel2}
              GraphdataQ2={GraphdataQ2}
              question2={question2}
              qLabel3={qLabel3}
              GraphdataQ3={GraphdataQ3}
              question3={question3}
              qLabel4={qLabel4}
              GraphdataQ4={GraphdataQ4}
              question4={question4}
              xLabel1={xLabel1}
              Graphdata1={Graphdata1}
            />
          </div>
        ) : (
          renderListMode()
        )}
      </div>
    </div>
  );
}
