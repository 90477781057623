import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import ClassRequestCard from "../../Components/classRequestCard";
import { getClassData, getCountryList } from "../../Redux/Actions/AdminAction";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
export default function ClassJoin() {
  const dispatch = useDispatch();
  const [upcomingList, setUpcomingList] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    const data: any = {};
    dispatch(
      getClassData(
        data,
        (res: any) => {
          setUpcomingList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data: any = {
      countryId: country?._id,
    };
    dispatch(
      getClassData(
        data,
        (res: any) => {
          setUpcomingList(res);
        },
        () => { }
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"REGULAR CLASS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex  ">
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Country"} />
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                reset={resetCountryList}
              />
            </div>

            <div className="d-flex col-md-3">
              <button
                type="submit"
                className="btn btn-dark mb-3 align-items-center liveSearchBtn mt-2"
              >
                {" "}
                Search{" "}
              </button>
            </div>


          </div>
          {upcomingList?.length > 0 ? <div className="row">
            {upcomingList?.map((item: any, index: any) => {
              return (
                <div className="col-md-4  py-5 p-2">
                  <ClassRequestCard
                    key={index}
                    data={item}
                    name={"JOIN CLASS"}
                    pageName={"salesPanelClassJoin"}
                    getDemoReq={getDemoScheduledList}
                  />
                </div>
              );
            })}
          </div>
            :
            <h2 className="fw-bold mt-5 p-2">No Demo Today</h2>
          }
        </form>
      </div>
    </div>
  );
}
