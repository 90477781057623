import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import CXOSidebarData from "./cxoSidebarData";
import WIP from "../wip";
import TransactionHistory from "./TransactionHistory";
import PaymentStatus from "./paymentStatus";
import CounsellorPayments from "./counsellorPayments";
import UpcomingInstallments from "./upcomingInstallments";
import OverdueInstallments from "./overdueInstallments";

import CounsellingLeads from "../Operations/counsellingLeads";
import LandingPageLeads from "../Operations/landingPageLeads";
import RegisteredUsers from "../SuperAdmin/registeredUsers";
import MarketingLandingpage from "../Operations/marketingLandingpage";
import AffiliateMarketing from "../Operations/affiliateMarketing";

import DemoRequest from "../Operations/demoRequest";
import MyDemo from "../Operations/myDemo";
import DemoSchedule from "../Operations/demoSchedule";
import DemoConducted from "../Operations/demoConducted";
import DemoCancel from "../Operations/demoCancel";

import DsaConversion from "../Operations/dsaConversion";
import DsaPaymentPayout from "../Operations/dsaPaymentPayout";
import UpdateDsaUser from "./updateDsaUser";

import AffiliatePaymentPayout from "../Operations/affiliatePaymentPayout";
import PaymentPayout from "../Operations/paymentPayout";
import Conversion from "../Operations/conversion";
import PtmFeedback from "../Operations/ptmFeedback";
import LiveClassStudentFeedback from "../Operations/liveClassStudentFeedback";

export default function CXODashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={CXOSidebarData?.CXOSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<PaymentStatus />} />
          <Route path="transaction_history" element={<TransactionHistory />} />
          <Route path="payment_status" element={<PaymentStatus />} />
          <Route path="CounsellorPayments" element={<CounsellorPayments />} />
          <Route
            path="upcomingInstallments"
            element={<UpcomingInstallments />}
          />
          <Route path="overdueInstallments" element={<OverdueInstallments />} />

          <Route path="counsellingLeads" element={<CounsellingLeads />} />
          <Route path="landingpageleads" element={<LandingPageLeads />} />
          <Route path="registeredUsers" element={<RegisteredUsers />} />
          <Route
            path="marketingLandingpage"
            element={<MarketingLandingpage />}
          />
          <Route path="affiliateMarketing" element={<AffiliateMarketing />} />

          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="myDemo" element={<MyDemo />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoCancel" element={<DemoCancel />} />

          <Route path="dsaConversion" element={<DsaConversion />} />
          <Route path="dsaPaymentPayout" element={<DsaPaymentPayout />} />
          <Route path="updateDsaUser" element={<UpdateDsaUser />} />

          <Route
            path="affiliatePaymentPayout"
            element={<AffiliatePaymentPayout />}
          />
          <Route path="paymentPayout" element={<PaymentPayout />} />
          <Route path="conversion" element={<Conversion />} />
          <Route path="ptmFeedback" element={<PtmFeedback />} />
          <Route
            path="liveClassStudentFeedback"
            element={<LiveClassStudentFeedback />}
          />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
