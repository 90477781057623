import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  value?: string,
  setInputText: Function,
  defaultValue?: string,
  maxdate?: string,
  mindate?: string,
  Isrequired?: boolean,
  disabled?: boolean,
}

export default function DateTimePicker({ value, setInputText, defaultValue, maxdate, mindate, Isrequired,disabled }: props) {
  return (
    <div className="d-flex mb-2 align-items-center custommargin">
      <input
        className="form-control"
        type="datetime-local"
        value={value}
        onChange={(e) => setInputText(e.target.value)}
        defaultValue={defaultValue?.substring(0, 19)}
        max={maxdate}
        min={mindate}
        // step="1"
        disabled={disabled}
        // pattern=" d{4}-\d{2}-\d{2}"
      />
      {Isrequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}



