export default {
  DsaSalesSidebarData: [
    {
      title: "DEMO CREATE",
      path: "createUser",
    },

    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "DEMO REQUEST",
          path: "demoRequest",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULE",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "DEMO CONDUCTED",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "DEMO CANCEL",
          path: "demoCancel",
          cName: "sub-nav",
        },
      ],
    },
  ],
};
