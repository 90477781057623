import React from "react";
 
 

interface props {
   name:String
}

export default function ExportButton({  name }: props) {
  
  return (
    <div className="d-flex mb-2 align-items-center"> 
     <button type="button" className="btn btn-outline-secondary rounded-pill exportbutton">{name}</button>
  </div>
  );
}
