import { useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import Submitbtn from "../../Components/submitButton";
import { validateIsFileImageType, pdf_size_1MB } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import { uploadExamDateIcon, addExamDateIcon } from '../../Redux/Actions/AdminAction';

export default function CreateExamDateIcon() {
  const dispatch: Function = useDispatch();
  const [label, setLabel] = useState<any>("");
  const [examIcon, setExamIcon] = useState<any>("");
  const [uploadExamIcon, setUploadExamIcon] = useState<any>("");

  const setInputLabel = (value: any) => {
    setLabel(value)
  };

  const handleOptionImageChange = (e: { preventDefault: () => void; target: { files: any[]; }; }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setExamIcon(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadExamDateIcon(file, (res: any) => {
        delete res?.filePath;
        setUploadExamIcon(res);
      }, () => { }));

    }
  }
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const PostData = {
      lableName: label,
      lableLogo: uploadExamIcon,

    }

    dispatch(
      addExamDateIcon(
        PostData,
        (res: any) => {
        },
        () => { }
      )
    );
  }





  return (
    <div className="contentBox">
      <PageTitle name={"ADD EXAM DATES ICON"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='col-8' onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Label Name"} />
            </div>
            <div className="col-8 ps-2">
              <InputField
                placeholder="Enter Label Name"
                value={label}
                onChangeInput={(value: any) => setInputLabel(value)}
                maxlength={100}
                minlength={2}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Label Logo "} />
            </div>
            <div className="col-8 ps-2">
              <ImagePickerAndViewer
                lableName={"Option Image"}
                value={examIcon}
                handleChange={(e: any) => handleOptionImageChange(e)}
                isRequired={true}
              />
            </div>
          </div>

          <div className='d-flex justify-content-center mt-3'>
            <Submitbtn
              name={"Submit"}

            />
          </div>
        </form>
      </div>
    </div>
  )
}

