import DemoRequestCard from "../../Components/demoRequestCard";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import {
  getCountryList,
  getCourseTypelist,
  teacherDemoSchedule,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import CustomDropdown from "../../Components/customDropdown";
import { demoTimeList } from "../../Utils/dataConstant";
import SubmitButton from "../../Components/submitButton";
export default function DemoSchedule() {
  const dispatch = useDispatch();
  const [demoRequestList, setDemoRequestList] = useState([]);
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(7, "days").format("YYYY-MM-DD")
  );
  const [demoTime, setDemoTime] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    getDemoScheduledList();
  }, []);

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetCourse(true);
    const postData = {
      countryId: data?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );

    const postdata = {
      teacherId: userId,
      statusId: [2, 3],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      countryId: data?._id,
    };
    if (course) {
      postdata["coursetypeId"] = course?._id;
    }
    dispatch(
      teacherDemoSchedule(
        postdata,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => {}
      )
    );
  };

  const getDemoScheduledList = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      teacherId: userId,
      statusId: [2, 3],
      countryId: country?._id,
    };
    dispatch(
      teacherDemoSchedule(
        postData,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postdata = {
      teacherId: userId,
      statusId: [2, 3],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: value?._id,
      countryId: country?._id,
    };
    dispatch(
      teacherDemoSchedule(
        postdata,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      teacherId: userId,
      statusId: [2, 3],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      countryId: country?._id,
    };
    dispatch(
      teacherDemoSchedule(
        postData,
        (res: any) => {
          setDemoRequestList(res);
        },
        () => {}
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"DEMO SCHEDULED"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(startDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className=" mb-2  mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>

        <div className="demoReqCountry mt-4 col-md-12">
          <div className="d-flex col-md-8">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4 ${
                      selectedIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                    }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-4 d-flex ms-3 align-items-center mt-2">
            <LabelField lableName={"Course type"} />
            <div className="col-md-8">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
                reset={resetCourse}
              />
            </div>
          </div>
        </div>

        {demoRequestList?.length > 0 ? (
          <div className="row">
            {demoRequestList?.map((item, index) => {
              return (
                <div className="col-md-4  py-3 p-2">
                  <DemoRequestCard
                    key={index}
                    data={item}
                    name={"DEMO SCHEDULED"}
                    pageName={"teacherPanelDemoSchedule"}
                    getDemoReq={getDemoScheduledList}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex  mt-5">
            <h3 className="fw-bold p-1 ">No Record Found</h3>
          </div>
        )}
      </div>
    </div>
  );
}
