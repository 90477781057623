import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { usePagination } from "../Utils/appConstants";
import moment from "moment";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function DsaDemoDetail(props: props) {
  const { open, handleClose, data } = props;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoTeacherPopup p-md-4 py-md-4 ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Demo Details</h5>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-5">
            <div className="row mt-2">
              <div className="col-md-7">
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Student Name:</div>
                  <div className="font16 mb-2 ms-2">
                    {" "}
                    {`${data?.firstName} ${data?.lastName}`}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Phone:</div>
                  <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Email:</div>
                  <div className="font16 mb-2 ms-2"> {data?.email}</div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Course Type:</div>
                  <div className="font16 mb-2 ms-2">{data?.coursetypeName}</div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Country:</div>
                  <div className="font16 mb-2 ms-2">
                    {data?.countryName} ({data?.timezone})
                  </div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Demo Date :</div>
                  <div className="font16 mb-2 ms-2">{data?.date}</div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">
                    Indian Date & Time :
                  </div>
                  <div className="font16 mb-2 ms-2">
                    {moment
                      .utc(data?.Indian_DateTime)
                      .format("DD-MM-YYYY, HH:mm:ss ")}
                  </div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Subject Name:</div>
                  <div className="font16 mb-2 ms-2">{data?.subjectName}</div>
                </div>
                <div className="d-flex">
                  <div className="font16 mb-2 fw-bold">Parents Name:</div>
                  <div className="font16 mb-2 ms-2"> {data?.parentName}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
