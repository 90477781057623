import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  addTeacherTimeAvailability,
  getCountryList,
  getGeneralTimezoneList,
} from "../../Redux/Actions/AdminAction";
import SubmitButton from "../../Components/submitButton";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import BookSlotDatePicker from "../../Components/bookSlotDatePicker";
import moment from "moment";

export default function TimeAvailability() {
  const dispatch = useDispatch();
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [selectedDate, setSelectedDate] = useState(
    moment().add(1, "day").format().slice(0, 10)
  );
  const [selectedTime, setSelectedTime] = useState<string>("");
  const initialTimeArr = [
    {
      _id: "EightAM",
      name: "8",
      time: "8.00 AM",
      isSelected: false,
    },
    {
      _id: "NineAM",
      name: "9",
      time: "9.00 AM",
      isSelected: false,
    },
    {
      _id: "TenAM",
      name: "10",
      time: "10.00 AM",
      isSelected: false,
    },
    {
      _id: "ElevenAM",
      name: "11",
      time: "11.00 AM",
      isSelected: false,
    },
    {
      _id: "TwelvePM",
      name: "12",
      time: "12.00 PM",
      isSelected: false,
    },
    {
      _id: "OnePM",
      name: "13",
      time: "1.00 PM",
      isSelected: false,
    },
    {
      _id: "TwoPM",
      name: "14",
      time: "2.00 PM",
      isSelected: false,
    },
    {
      _id: "ThreePM",
      name: "15",
      time: "3.00 PM",
      isSelected: false,
    },
    {
      _id: "FourPM",
      name: "16",
      time: "4.00 PM",
      isSelected: false,
    },
    {
      _id: "FivePM",
      name: "17",
      time: "5.00 PM",
      isSelected: false,
    },
    {
      _id: "SixPM",
      name: "18",
      time: "6.00 PM",
      isSelected: false,
    },
    {
      _id: "SevenPM",
      name: "19",
      time: "7.00 PM",
      isSelected: false,
    },
    {
      _id: "EightPM",
      name: "20",
      time: "8.00 PM",
      isSelected: false,
    },
    {
      _id: "NinePM",
      name: "21",
      time: "9.00 PM",
      isSelected: false,
    },
  ];
  const [timeArr, settimeArr] = useState(initialTimeArr);
  const [dateArr, setDateArr] = useState<any>([]);
  const [groupedData, setgroupedData] = useState<any>({});

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const dateData = dateArr?.map((item: any) => {
      if (groupedData[item]) {
        return groupedData[item];
      }
    });
    const postData = {
      teacherId: userId,
      countryId: country?._id,
      timezoneId: timeZone?.timezoneId,
      availability: {
        date: dateData,
      },
    };
    dispatch(
      addTeacherTimeAvailability(
        postData,
        (res: any) => {
          setResetCountryList(true);
          setResetTimezone(true);
          settimeArr(initialTimeArr);
          setgroupedData({});
          setDateArr([]);
          setSelectedDate(moment().add(1, "day").format().slice(0, 10));
        },
        () => {}
      )
    );
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setResetTimezone(true);
    setCountry(value);
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => {}
      )
    );
  };

  const onChangeTimeZone = (value: any) => {
    setResetTimezone(false);
    setTimeZone(value);
  };

  const selectedDay = (val: any) => {
    setSelectedTime("");
    settimeArr(initialTimeArr);
    if (groupedData[val?.slice(0, 10)]) {
      const updatedTimeArr = initialTimeArr.map((timeSlot) => {
        if (groupedData[val?.slice(0, 10)]?.time?.includes(timeSlot.name)) {
          return { ...timeSlot, isSelected: true };
        }
        return timeSlot;
      });
      settimeArr(updatedTimeArr);
    }
    setSelectedDate(val?.slice(0, 10));
  };
  const onSelectTime = (val: any, index: number) => {
    // setSelectedTime(val);
    let newArray = JSON.parse(JSON.stringify(timeArr));
    if (!groupedData[selectedDate]) {
      dateArr?.push(selectedDate);
      groupedData[selectedDate] = {
        date: selectedDate,
        time: [],
      };
    }
    if (newArray[index]["isSelected"] === false) {
      newArray[index]["isSelected"] = true;
      groupedData[selectedDate]?.time?.push(val);
      // settimeArr(newArray);
    } else {
      newArray[index]["isSelected"] = false;
      let timeindex = groupedData[selectedDate]?.time?.findIndex(
        (obj: any) => obj.name === val
      );
      groupedData[selectedDate]?.time?.splice(timeindex, 1);
    }
    setgroupedData(groupedData);
    settimeArr(newArray);
  };
  function validations() {
    let checkData = false;
    timeArr?.forEach((item: any) => {
      if (item.isSelected === true) {
        checkData = true;
      }
    });
    return country === "" || timeZone === "" || checkData === false;
  }
  return (
    <div className="contentBox">
      <PageTitle name={"TIME AVAILABILITY"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-md-6 d-flex Justify-content-between mt-4 mb-2">
            <div className="col-md-5 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-md-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                // Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between mt-2 mb-2">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Time Zone"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Time Zone"
                value={timeZone}
                options={timeZoneList}
                Isrequired={true}
                reset={resetTimezone}
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeTimeZone(value)
                }
              />
            </div>
          </div>
          <div className="col-md-8 d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Schedule"} />
            </div>
            <div className="col-8 Justify-content-between border rounded">
              <div className="col-12">
                <div
                  className="p-2 sticky-top bgwhite "
                  style={{ zIndex: "1" }}
                >
                  <BookSlotDatePicker
                    selectedDay={selectedDay}
                    selectedDate={selectedDate}
                  />
                  <div className="d-flex flex-wrap mt-3">
                    {timeArr?.map((item, index) => (
                      <div
                        className={`timeCard border rounded ${
                          item?.isSelected === true ? "active" : null
                        }`}
                        onClick={() => onSelectTime(item?.name, index)}
                      >
                        {item?.time}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <SubmitButton name={"Submit"} validation={validations()} />
          </div>
        </form>
      </div>
    </div>
  );
}
