export default {
  DsaManagerSidebarData: [
    {
      title: "CREATE SALES PERSON",
      subNav: [
        {
          title: "CREATE",
          path: "createUser",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateUser",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "DEMO REQUEST",
          path: "demoRequest",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULE",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "DEMO CONDUCTED",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "DEMO CANCEL",
          path: "demoCancel",
          cName: "sub-nav",
        },
      ],
    },
    // {
    //   title: "DEMO PAYMANTS",
    //   path: "demoPayments",
    // },
  ],
};
