import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import CustomDropdown from "../../Components/customDropdown";
import { getAssignExam, getGeneralCourseList, getAssignExamList } from '../../Redux/Actions/AdminAction';
import { useDispatch } from 'react-redux';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function CreateAssignExam() {
  const dispatch : Function = useDispatch();

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState();
  const [examList, setExamList] = useState<any>([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);


  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res)
        },
        () => { }
      )
    );
  }, []);

  const onChangeExam = (value: any) => {
    setCourse(value)
    const postData = {
      courseId: value._id
    }
    dispatch(
      getAssignExamList(
        postData,
        (res: any) => {
          setExamList(res)
        },
        () => { }
      )
    );
  };

  const changeCheckBox = (event: any, index: any, type: any) => {
    let newArrr = JSON.parse(JSON.stringify(examList));

    if (type === "exam") {
      if (event.target.checked) {
        newArrr[index]["assigned"] = true;
        setExamList(newArrr);
      } else {
        newArrr[index]["assigned"] = false;
        setExamList(newArrr);
      }
    }
  };
  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    let selectedCheckboxes = examList
      .filter((elm: any, idx: any) => (elm.assigned === true));

    let subArr: any = [];
    const tempArray = selectedCheckboxes?.map((item: any) => {

      subArr.push(item?.examtypeId);

    });
    if (selectedCheckboxes?.length === 0) {
      dispatch(showErrorSnackbar("Please add Exam"));
      return;
    }

    const postData = {
      courseId: course._id,
      examtypes: subArr,
    }
    dispatch(
      getAssignExam(
        postData,
        (res: any) => {
          setResetParentCourse(true);
          setExamList([]);


        },
        () => { }
      )
    );

  }

  function validation() {
    return (
      course === ""

    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"ASSIGN EXAM NAME"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='' onSubmit={handleSubmit}>

          <div className="col-8">
            <div className="d-flex Justify-content-between  mt-3">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) =>
                    onChangeExam(value)
                  }
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>

              {course !== "" && examList?.length > 0 ? (
                <>
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="d-flex col-8 ps-2">
                    <div className="border rounded p-3">
                      <div className=" input-border">
                        {examList.map((eList: any, index: any) => {
                          const { _id, name } = eList;
                          return (
                            <div key={eList?._id} className="d-flex center p-1">
                              <div className="w-100">
                                <div className="d-flex justify-content-between">
                                  <div className="d-flex e-input align-items-center ms-3">
                                    <input
                                      id={_id}
                                      className="check"
                                      type="checkbox"
                                      checked={eList?.assigned}
                                      onChange={(event) => {
                                        changeCheckBox(event, index, "exam");
                                      }}
                                    />
                                    <span className="checkVal ms-3">
                                      {eList?.examtypeName}
                                    </span>
                                  </div>

                                </div>

                              </div>
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </>

              ) : null}
            </div>

          </div>
          <div className='d-flex justify-content-center mt-3'>
            <Submitbtn name={"Assign Exam Name"}
              validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  )
}

