// import React from "react";
// import { Link, useLocation } from "react-router-dom";
// import { Interface } from "readline";

// interface props {
//    name:String
// }

// export default function SearchButton({  name }: props) {

//   return (
//     <div className="d-flex mb-2 align-items-center">
//      <button type="button" className="btn btn-secondary searchButton">{name}</button>
//   </div>
//   );
// }

interface props {
  name: string,
}
export default function SearchButton({ name }: props) {

  return (
    <button type="submit" className="btn btn-secondary mb-3 align-items-center" >{name}</button>
  )
}