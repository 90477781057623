// import "../Assets/Css/home.css";
import * as React from "react";
import Rating from "@mui/material/Rating";
import Box from "@mui/material/Box";
import StarIcon from "@mui/icons-material/Star";

function RatingStar(props: any) {
  function getLabelText(value: number) {
    return `${value} Star${value !== 1 ? "s" : ""}, ${props?.label[value]}`;
  }
  const [hover, setHover] = React.useState(-1);
  return (
    <Box
      sx={{
        width: 300,
        display: "flex",
        alignItems: "center",
      }}
    >
      <Rating
        name="hover-feedback"
        value={props?.value}
        precision={1}
        getLabelText={getLabelText}
        onChange={(event, newValue) => {
          props?.setValue(newValue);
        }}
        onChangeActive={(event, newHover) => {
          setHover(newHover);
        }}
        emptyIcon={<StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />}
        disabled={props?.disabled}

      />
      {props?.value !== null && (
        <Box sx={{ ml: 2 }}>
          {/* {props?.label[hover !== -1 ? hover : props?.value]} */}
        </Box>
      )}
    </Box>
  );
}

export default RatingStar;
