// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.studentCard{
    background: #f5f5f5;
    border-radius: 10px;
    -webkit-border-radius: 10px;
    -moz-border-radius: 10px;
    -ms-border-radius: 10px;
    -o-border-radius: 10px;
    padding: 10px;
}
.studentCard h6{
    font-size: clamp(0.625rem, 0.525rem + 0.5vw, 1.125rem);
}
.studentDiv{
    max-height: calc(100vh - 155px);
    overflow: auto;
}
.gradeDiv{
    background: #E5E5E5;
    padding: 3px 5px;
    border-radius: 3px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    -o-border-radius: 3px;
}
.userImg{
    width: 70px;
    border-radius: 5px;
}
.scheduleBox{
    background: #FBFBFB;
    padding: 10px;
}
.studentCard a{
    border: 0;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/studentCard.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,mBAAmB;IACnB,2BAA2B;IAC3B,wBAAwB;IACxB,uBAAuB;IACvB,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,sDAAsD;AAC1D;AACA;IACI,+BAA+B;IAC/B,cAAc;AAClB;AACA;IACI,mBAAmB;IACnB,gBAAgB;IAChB,kBAAkB;IAClB,0BAA0B;IAC1B,uBAAuB;IACvB,sBAAsB;IACtB,qBAAqB;AACzB;AACA;IACI,WAAW;IACX,kBAAkB;AACtB;AACA;IACI,mBAAmB;IACnB,aAAa;AACjB;AACA;IACI,SAAS;AACb","sourcesContent":[".studentCard{\n    background: #f5f5f5;\n    border-radius: 10px;\n    -webkit-border-radius: 10px;\n    -moz-border-radius: 10px;\n    -ms-border-radius: 10px;\n    -o-border-radius: 10px;\n    padding: 10px;\n}\n.studentCard h6{\n    font-size: clamp(0.625rem, 0.525rem + 0.5vw, 1.125rem);\n}\n.studentDiv{\n    max-height: calc(100vh - 155px);\n    overflow: auto;\n}\n.gradeDiv{\n    background: #E5E5E5;\n    padding: 3px 5px;\n    border-radius: 3px;\n    -webkit-border-radius: 3px;\n    -moz-border-radius: 3px;\n    -ms-border-radius: 3px;\n    -o-border-radius: 3px;\n}\n.userImg{\n    width: 70px;\n    border-radius: 5px;\n}\n.scheduleBox{\n    background: #FBFBFB;\n    padding: 10px;\n}\n.studentCard a{\n    border: 0;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
