import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import hrOperationSidebarData from "./hrOperationSidebarData";
import CreateTeacher from "./createTeacher";
import UpdateTeacher from "./updateTeacher";
import TutorDetails from "./tutorDetails";
import TutorDataDetails from "./tutorDataDetails";
export default function HrOperationPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={hrOperationSidebarData?.HrOperationSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateTeacher />} />
          <Route path="createTeacher" element={<CreateTeacher />} />
          <Route path="updateTeacher" element={<UpdateTeacher />} />
          <Route path="tutorDetails" element={<TutorDetails />} />
          <Route path="tutorDataDetails" element={<TutorDataDetails />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
