import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import { addMeeting, getBBBTeacherList } from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validatePassword,
  startsWithSpace,
  hasWhiteSpace,
  length_Five,
  length_Hundred,
  length_Thirty,
} from "../../Utils/validators";

export default function CreateBBMeeting() {
  const dispatch: Function = useDispatch();
  const [mailID, setMailID] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [meeting, setMeeting] = useState<string>("");
  const [alternatePassword, setAlternatePassword] = useState<string>("");
  const [inviteLink, setInviteLink] = useState("");
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);

  useEffect(() => {
    dispatch(
      getBBBTeacherList(
        "",
        (res: any) => {
          setTeacherList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeTeacher = (value: any) => {
    setTeacher(value?.empId);
    setResetTeacher(false);
  };
  /***********************email**************************/
  const onChangeMail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setMailID(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const onChangeInviteLink = (value: any) => {
    setInviteLink(value);
  };

  /***********************password**************************/
  const onChangepwd = (value: any) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };

  /*********************************Alternate Password*******************************/
  const onChangeAltpwd = (value: any) => {
    if (validatePassword(value)) {
      setAlternatePassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };

  const validation = () => {
    return (
      mailID === "" ||
      mailID.length < length_Five - 1 ||
      password === "" ||
      password.length < length_Five - 1 ||
      meeting === "" ||
      meeting.length < length_Five - 1 ||
      alternatePassword === "" ||
      alternatePassword.length < length_Five - 1 ||
      teacher === "" ||
      inviteLink === "" ||
      inviteLink.length < length_Five - 1
    );
  };

  function handleSubmit(e: any) {
    e.preventDefault();
    const Postdata = {
      meetingId: meeting,
      mailId: mailID,
      password: password,
      attentivePass: alternatePassword,
      empId: teacher,
      meetingInviteLink: inviteLink,
    };

    dispatch(
      addMeeting(
        Postdata,
        (res: any) => {
          setResetTeacher(true);
          setTeacher("");
          setMailID("");
          setPassword("");
          setAlternatePassword("");
          setMeeting("");
          setInviteLink("");
        },
        () => {}
      )
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"LIVE MEETING ID CREATION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center mt-3">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Teacher Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Teacher Name"
                  setInputText={(value: any) => onChangeTeacher(value)}
                  value={teacher}
                  options={teacherList}
                  reset={resetTeacher}
                  Isrequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Mail ID"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Email Address"
                  value={mailID}
                  onChangeInput={(value: any) => onChangeMail(value)}
                  maxlength={length_Hundred}
                  minlength={length_Five}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Password"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Password"
                  value={password}
                  onChangeInput={(value: any) => onChangepwd(value)}
                  maxlength={length_Thirty}
                  minlength={length_Five}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Meeting ID"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Meeting ID"
                  value={meeting}
                  onChangeInput={(value: any) => setMeeting(value)}
                  minlength={length_Five}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Attentive Password"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Attentive Password"
                  value={alternatePassword}
                  onChangeInput={(value: any) => onChangeAltpwd(value)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Invite Link"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Link"
                  value={inviteLink}
                  onChangeInput={(value: any) => onChangeInviteLink(value)}
                  maxlength={length_Hundred}
                  minlength={length_Five}
                  isRequired={true}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
