import { AuthReducerModal } from '../../Modals';
import * as Actions from '../Types/authTypes';

const AuthReducer = (state = new AuthReducerModal(), action:any) => {
  switch (action.type) {
    case Actions.SET:
      return { ...state, ...action.payload };
    case Actions.LOADER:
      return { ...state, settingLoader: action.payload };
    default:
      return state;
  }
};

export default AuthReducer;
