import { useState } from 'react'
import PageTitle from '../../Components/pageTitle'
 
export default function RaiseIssue() {
  
  const [meetingID, setMeetingID] = useState<string>("");
  const [password, setPassword ]  = useState<string>("");
 
 

   

   

  return (
    <div className="contentBox">
      <PageTitle name={"Raise Issue"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <p className='fw-bold font24'>Working....</p>
        <form className=''>
        
        

         
  
      </form>
      </div>
    </div>
  )
}

