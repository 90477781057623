import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import { addCourseType, getCountryList } from '../../Redux/Actions/AdminAction';
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsNumberOnly, startsWithSpace, validateIsLettersAndDotOnly, validateFullName, length_Ten, length_Hundred, length_Two, length_OneHundredFifty, validateExpertDetails, length_TwoHundredFifty } from '../../Utils/validators';
import '../../App.css';
import CustomDropdown from '../../Components/customDropdown';

export default function CreateParentCourse() {
  const dispatch: Function = useDispatch();
  const [coursename, setCoursename] = useState<string>("");
  const [expno, setExpno] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [type, setType] = useState<any>("");


  useEffect(() => {

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);
  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const postData = {
      countryId: country?._id,
      coursetypeName: coursename,
      type: type,
      expertNo: expno,
      expertMsg: message,
    }

    dispatch(
      addCourseType(
        postData,
        (res: any) => {
          setCoursename('');
          setType("");
          setExpno('');
          setMessage('');
          setResetCountryList(true);
        },
        () => { }
      )
    );
  }
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
  };
  const onChangeCourseName = (value: any) => {
    setCoursename(value)
  }

  const onChangeMessage = (value: any) => {
    setMessage(value)
  }
  const onChangeExperNo = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setExpno(value)
    }
    else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }

  }

  const onChangeType = (value: any) => {
    setType(value);
  };
  function validation() {
    return (
      country === "" ||
      coursename === "" ||
      type === "" ||
      coursename.length < length_Two ||
      expno === "" ||
      expno.length < length_Ten ||
      message === "" ||
      message.length < length_Ten

    );
  }


  return (
    <div className="contentBox">
      <PageTitle name={"CREATE COURSE TYPE"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='' onSubmit={handleSubmit}>

          <div className=' col-7 mt-2'>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course type"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Parent Course"
                  value={coursename}
                  onChangeInput={(value: string) => onChangeCourseName(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_Two}
                  isRequired={true}
                //reset={resetParentCourse}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between my-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Type"} />
              </div>
              <div className="col-7 d-flex ps-3">
                <span className=''>
                  <input
                    type="radio"
                    value={1}
                    name={"type"}
                    checked={type === 1}
                    onChange={() => onChangeType(1)}
                  />{" "}
                  Exam
                </span>
                <span className='ms-3'>
                  <input
                    type="radio"
                    value={2}
                    name={"type"}
                    checked={type === 2}
                    onChange={() => onChangeType(2)}
                  />{" "}
                  Grade
                </span>
                <span className='ms-3'>
                  <input
                    type="radio"
                    value={3}
                    name={"type"}
                    checked={type === 3}
                    onChange={() => onChangeType(3)}
                  />{" "}
                  None
                </span>
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Expert Number"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter Expert Number"
                  value={expno}
                  maxlength={length_Ten}
                  minlength={length_Ten}
                  isRequired={true}
                  onChangeInput={(value: string) => onChangeExperNo(value)}

                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Talk To Expert Message"} />
              </div>
              <div className="col-6 ps-2">
                <InputField
                  placeholder="Enter message to talk to expert"
                  value={message}
                  onChangeInput={(value: string) => onChangeMessage(value)}
                  isRequired={true}
                  minlength={length_Ten}
                  maxlength={length_TwoHundredFifty - 1}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Submitbtn name={"Create Parent Course"} validation={validation()} />
            </div>
          </div>



        </form>
      </div>
    </div>
  )
}

