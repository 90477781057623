import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import PaginationTable from "../../Components/paginationTable";
import { today, usePagination } from "../../Utils/appConstants";
import { useLocation } from "react-router-dom";
import { teacherRecordDetails } from "../../Redux/Actions/AdminAction";

export default function DsaConversionDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { details, sDate, eDate, pageName } = location.state;
  const lastWeek = new Date();
  lastWeek.setDate(lastWeek.getDate() - 7);
  const [startDate, setStartDate] = useState<any>(
    moment(lastWeek).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(today);
  const [teacherRecords, setTeacherRecords] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    const postData = {
      startDate: sDate,
      endDate: eDate,
      sourceId: 2,
    };
    dispatch(
      teacherRecordDetails(
        postData,
        details?.empId,
        (res: any) => {
          setTeacherRecords(res);
        },
        () => {}
      )
    );
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      startDate: sDate,
      endDate: eDate,
    };
    dispatch(
      teacherRecordDetails(
        postData,
        details?.empId,
        (res: any) => {
          setTeacherRecords(res);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="">
        <div className="justify-content-between mt-5">
          {pageName === "teacherConversion" ? (
            <h5 className="fw-bold">{details?.name}</h5>
          ) : (
            <h5 className="fw-bold">{details?.DSAName}</h5>
          )}
          <PaginationTable
            tableData={teacherRecords ? teacherRecords : []}
            pagename={"conversionPopup"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "COURSE TYPE",
              "EXAM TYPE",
              "GRADE",
              "SUBJECT",
              "DATE",
              "ADMISSION STATUS",
              "SOURCE",
            ]}
            edit={true}
          />
        </div>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CONVERSION DETAIL"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(sDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => setStartDate(value)}
                  disabled
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={eDate}
                  setInputText={(value: string) => setEndDate(value)}
                  disabled
                />
              </div>
            </div>
          </div>
        </form>

        {renderListMode()}
      </div>
    </div>
  );
}
