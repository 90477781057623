import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import Addmorebtn from "../../Components/addmorebtn";
import CustomDropdown from "../../Components/customDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  addCourseDescFeature,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { length_Ten, length_Hundred, length_Two, length_TwoHundredFifty } from "../../Utils/validators";
import { GradeList } from "../../Utils/dataConstant";

export default function CreateFeature() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  const initialVal = "";
  const [featureArr, setFeatureArr] = useState<any>([initialVal]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value?._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };
  const onPressAdd = () => {
    setFeatureArr([...featureArr, initialVal[0]]);
  };
  const handleClose = (index: any) => {
    const newArrr = [...featureArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setFeatureArr(newArrr);
  };
  function onchangeFeature(val: any, index: any) {
    let newArr = JSON.parse(JSON.stringify(featureArr));
    newArr[index] = val;
    setFeatureArr(newArr);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData = {
      coursetypeId: course?._id,
      examtypeId: exam,
      subjectId: subject,
      gradeId: grade?._id,
      features: featureArr,
    };
    if (
      PostData["examtypeId"] === null ||
      PostData["examtypeId"] === undefined ||
      PostData["examtypeId"] === ""
    ) {
      delete PostData["examtypeId"];
    }
    if (
      PostData["gradeId"] === null ||
      PostData["gradeId"] === undefined ||
      PostData["gradeId"] === ""
    ) {
      delete PostData["gradeId"];
    }
    dispatch(
      addCourseDescFeature(
        PostData,
        (res: any) => {
          setResetCountryList(true)
          setresetExam(true);
          setExam("");
          setresetExam(true);
          setFeatureArr([initialVal]);
          setResetParentCourse(true);
          setResetGradeList(true);
          setResetSubject(true);
        },
        () => { }
      )
    );
  };

  const validateFeature = () => {
    let isFeatureBlank: boolean = false;
    featureArr?.map((item: any) => {
      if (
        item === "" ||
        item === null ||
        item === undefined ||
        item.length < length_Ten - 1
      ) {
        isFeatureBlank = true;
      }
    });
    return isFeatureBlank;
  };

  const validation = () => {
    return course === "" || grade
      ? grade === ""
      : false || exam
        ? exam === ""
        : false || subject
          ? subject === ""
          : false || validateFeature();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE FEATURE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.type === 2 || course?.type === 3 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Exam Name "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Feature Name"} />
              </div>
              <div className="col-6">
                {featureArr.map((item: any, index: any) => {
                  return (
                    <div className=" d-flex">
                      <div className="col-12 ps-2">
                        <textarea
                          className="w-100"
                          name="postContent"
                          rows={4}
                          cols={40}
                          value={item}
                          onChange={(e: any) => {
                            onchangeFeature(e.target.value, index);
                          }}
                          maxLength={length_TwoHundredFifty}
                          minLength={length_Two + 1}
                        />
                      </div>

                      {featureArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-10 d-flex justify-content-end">
              <Addmorebtn name="Add More" handleClick={onPressAdd} />
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
