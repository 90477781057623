import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import { TypeList } from "../../Utils/appConstants";

import {
  getCounsellorList,
  getCountryList,
  getCourseTypelist,
  getPaymentDetails,
} from "../../Redux/Actions/AdminAction";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import PaginationTable from "../../Components/paginationTable";

export default function CounsellorPayments() {
  const [type, setType] = useState<any>("");
  const [resetTypeList, setResetTypeList] = useState(false);
  const [counsellorName, setCounsellorName] = useState<any>("");
  const [counsellorNameList, setCounsellorNameList] = useState<any>([]);
  const [resetcounsellorName, setResetcounsellorName] = useState<any>([]);
  const [counsellorPayList, setCounsellorPayList] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setCourseTypeList] = useState<any[]>([]);

  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );

  const dispatch: Function = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = ["Success", "Cancelled"];
  useEffect(() => {
    const postData = {};
    dispatch(
      getCounsellorList(
        postData,
        (data: any) => {
          setCounsellorNameList(data);
        },
        () => {}
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
          if (res?.length > 0) {
            setCountry(res[2]);
          }
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    getPaymentData(1);
  };

  const getPaymentData = (status: number) => {
    setSelectedIndex(status);
    setCounsellorPayList([]);
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      country_code: country?._id,
      coursetypeId: courseType?._id,
      type: type?._id,
      counsellorId: counsellorName?._id,
      paymentStatusCode: status === 1 ? 200 : 400,
    };
    dispatch(
      getPaymentDetails(
        postdata,
        (res: any) => {
          setCounsellorPayList(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
  };

  const onChangeType = (value: any) => {
    setResetTypeList(false);
    setType(value);
  };
  const onChangeCounsellor = (value: any) => {
    setCounsellorName(value);
    setResetcounsellorName(false);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"COUNSELLOR PAYMENTS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Country"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Country Name"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Course Type"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={courseType}
                  options={courseTypeList}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Type"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Type"
                  setInputText={(value: any) => onChangeType(value)}
                  value={type}
                  options={TypeList}
                  Isrequired={true}
                  reset={resetTypeList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Counsellor Name"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Counsellor"
                  setInputText={(value: any) => onChangeCounsellor(value)}
                  value={counsellorName}
                  options={counsellorNameList}
                  Isrequired={true}
                  reset={resetcounsellorName}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <Submitbtn name={"Search"} />
            </div>
          </div>
        </form>

        <div className="pt-5">
          {tabs?.map((data: string, index: number) => {
            return (
              <button
                className={`add_Question-btn btn     me-4 ${
                  selectedIndex === index + 1 ? "btn-success" : "btn-secondary"
                }`}
                onClick={() => getPaymentData(index + 1)}
              >
                {data}
              </button>
            );
          })}
        </div>

        <div className="row">
          <>
            <PaginationTable
              tableData={counsellorPayList ? counsellorPayList : []}
              pagename={"CxoCounsellorPayments"}
              rowsPerPage={0}
              page={0}
              tableHead={[
                "S.N",
                "COUNSELLOR NAME",
                "ORDER DATE",
                "BUYER NAME",
                "COURSE NAME",
                "PACKAGE NAME",
                "LEVEL",
                "PAID AMOUNT",
                "COUPON CODE",
                "TOTAL COUPON DISCOUNT",
                "PAYMENT STATUS",
              ]}
            />
          </>
        </div>
      </div>
    </div>
  );
}
