import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import TechncalSidebarData from "./technicalSidebarData";
import RegistrationDetail from "./registrationDetail";
import WIP from "../wip";
import OldManualActivation from "./oldManualActivation";
export default function TechnicalDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={TechncalSidebarData?.TechncalSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<RegistrationDetail />} />
          <Route path="RegistrationDetail" element={<RegistrationDetail />} />
          <Route path="OldManualActivation" element={<OldManualActivation />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
