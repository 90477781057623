import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import SubmitButton from "../../Components/submitButton";
import {
  validateFullName,
  validateIsNumberOnly,
  startsWithSpace,
  validatePassword,
  length_Hundred,
  length_Three,
  length_Ten,
  length_Thirty,
  length_Four,
  length_One,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { addTeacher } from "../../Redux/Actions/SuperAdminAction";
import { teacherId, today } from "../../Utils/appConstants";
import {
  getCountryList,
  getGeneralTimezoneList,
  getNewSubjectList,
  getSubjectList,
} from "../../Redux/Actions/AdminAction";
import CustomDropdown from "../../Components/customDropdown";
import Addmorebtn from "../../Components/addmorebtn";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

export default function CreateTeacher() {
  const dispatch: Function = useDispatch();
  const initialVal = [
    {
      timeZone: "",
      _id: "",
    },
  ];
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [catergory, setCatergory] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [timeZoneArr, setTimeZoneArr] = useState<any>(initialVal);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [numberOfClasses, setNumberOfClasses] = useState<any>("");
  const [yearOfExperience, setYearOfExperience] = useState<any>("");
  const [numberOfDemoClasses, setNumberOfDemoClasses] = useState<any>("");
  const [regularAcademics, setRegularAcademics] = useState<any>("");
  const [collegeConsulting, setCollegeConsulting] = useState<any>("");
  const [testPreperation, setTestPreperation] = useState<any>("");
  const [advancedAcademics, setAdvancedAcademics] = useState<any>("");

  useEffect(() => {
    dispatch(
      getNewSubjectList(
        "",
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneList(res);
        },
        () => { }
      )
    );
  };
  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  const onChangeClasses = (value: any) => {
    setNumberOfClasses(value);
  };
  const onChangeExperiences = (value: any) => {
    setYearOfExperience(value);
  };
  const onChangeDemoClasses = (value: any) => {
    setNumberOfDemoClasses(value);
  };

  function allemail(inputtxt: any) {
    const emailtest =
      /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})*\w(.com|.in|.uk|.co)/;
    if (email.match(emailtest)) {
      return true;
    } else {
      // dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }
  const onChangeTimeZone = (value: any, index: any) => {
    setTimeZone(value);
    setResetTimezone(false);
    let newArr = JSON.parse(JSON.stringify(timeZoneArr));
    newArr[index] = value?.timezoneId;

    setTimeZoneArr(newArr);
  };
  const onPressAdd = () => {
    setTimeZoneArr([...timeZoneArr, initialVal[0]]);
  };

  const handleCrossLive = (index: any) => {
    const newArray = [...timeZoneArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setTimeZoneArr(newArray);
  };
  const onChangeRegularAcademics = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setRegularAcademics(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeCollegeConsulting = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setCollegeConsulting(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeTestPreperation = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setTestPreperation(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeAdvancedAcademics = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setAdvancedAcademics(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangepwd = (value: any) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };
  const onChangeCategory = (value: any) => {
    setCatergory(value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const subjectArray: string[] = [];
    subjectList.forEach((element: { isSelected: boolean; _id: string }) => {
      if (element.isSelected) {
        subjectArray.push(element._id);
      }
    });

    const postData = {
      name: employeeName,
      mobile: mobileNo,
      doj: dateOfJoining,
      email: email,
      username: userName,
      password: password,
      isNative: catergory === 1 ? true : false,
      departmentId: teacherId,
      userRoleId: teacherId,
      subjectId: subjectArray,
      timezoneId: timeZoneArr,
      countryId: country?._id,
      numberOfClasses: numberOfClasses,
      yearOfExperience: yearOfExperience,
      numberOfDemoClasses: numberOfDemoClasses,
      regularAcademics: regularAcademics,
      collegeConsulting: collegeConsulting,
      testPrep: testPreperation,
      advancedAcademics: advancedAcademics
    };
    dispatch(
      addTeacher(
        postData,
        (res: any) => {
          setEmployeeName("");
          setMobileNo("");
          setDateOfJoining("");
          setEmail("");
          setUserName("");
          setPassword("");
          setCatergory("");
          setResetTimezone(true);
          setNumberOfClasses("");
          setYearOfExperience("");
          setNumberOfDemoClasses("");
          setResetCountryList(true);
          subjectList.map((item: any) => (item.isSelected = false));
        },
        () => { }
      )
    );
  };
  const validateArray = () => {
    let result = false;
    for (var i = 0; i < timeZoneArr?.length; i++) {
      if (timeZoneArr === "") {
        result = true;
      }
    }
    return result;
  };

  function validation() {
    const subjectArray: string[] = [];
    subjectList.forEach((element: { isSelected: boolean; _id: string }) => {
      if (element.isSelected) {
        subjectArray.push(element._id);
      }
    });
    return (
      employeeName === "" ||
      employeeName.length < length_Three - 1 ||
      catergory === "" ||
      mobileNo === "" ||
      mobileNo.length !== length_Ten ||
      !allemail(email) ||
      dateOfJoining === "" ||
      userName === "" ||
      userName.length < length_Four ||
      password === "" ||
      password.length < length_Four ||
      subjectArray.length === 0 ||
      regularAcademics === "" ||
      collegeConsulting === "" ||
      testPreperation === "" ||
      advancedAcademics === "" ||
      validateArray()
    );
  }

  const changeCheckBox = (event: any, index: any) => {
    let newArrr = JSON.parse(JSON.stringify(subjectList));
    if (event.target.checked) {
      newArrr[index]["isSelected"] = event.target.checked;
      setSubjectList(newArrr);
    } else {
      newArrr[index]["isSelected"] = event.target.checked;
      setSubjectList(newArrr);
    }
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE TEACHER"} />
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-10 px-4 mx-0 mt-4 ">
          <div className="d-flex Justify-content-between col-md-6">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Teacher Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Teacher Name"
                value={employeeName}
                onChangeInput={(value: any) => onChangeEmpName(value)}
                maxlength={length_Hundred - 1}
                minlength={length_Three - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between my-2 col-md-6">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Teacher Category"} />
            </div>
            <div className="col-6 d-flex ps-3 ">
              <div className="col-4">
                <span>
                  <input
                    type="radio"
                    value={1}
                    name={"catergory"}
                    checked={catergory === 1}
                    onChange={() => onChangeCategory(1)}
                  />{" "}
                  Native
                </span>
              </div>
              <div className="col-4">
                <span>
                  <input
                    type="radio"
                    value={2}
                    name={"catergory"}
                    checked={catergory === 2}
                    onChange={() => onChangeCategory(2)}
                  />{" "}
                  Indian
                </span>
              </div>
            </div>
          </div>

          <div className="d-flex Justify-content-between my-2 col-md-6">
            <div className="col-5 align-items-start d-flex mt-2">
              <LabelField lableName={"Subject Name "} />
            </div>
            <div className="d-flex col-8 ps-2">
              <div className="border rounded p-3">
                <div className=" input-border">
                  {subjectList.map((item: any, index: any) => {
                    const { _id, name } = item;
                    return (
                      <div key={item?._id} className="d-flex center p-1">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                id={_id}
                                className="check"
                                type="checkbox"
                                checked={item?.isSelected}
                                onChange={(event) => {
                                  changeCheckBox(event, index);
                                }}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-6 d-flex Justify-content-between mt-4 mb-2">
            <div className="col-md-5 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-md-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                // Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>

          <div className="col-md-6 d-flex Justify-content-between">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Mobile Number"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChangeInput={(value: any) => onChangeMobileNO(value)}
                maxlength={length_Ten}
                minlength={length_Ten}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Email Address"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => onChangeEmail(value)}
                maxlength={length_Hundred - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Number of classes"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Number of classes"
                value={numberOfClasses}
                onChangeInput={(value: any) => onChangeClasses(value)}
                maxlength={length_Hundred - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Year Of Experience"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Year Of Experience"
                value={yearOfExperience}
                onChangeInput={(value: any) => onChangeExperiences(value)}
                maxlength={length_Hundred - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Number Of Demo Classes"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Number Of Demo Classes"
                value={numberOfDemoClasses}
                onChangeInput={(value: any) => onChangeDemoClasses(value)}
                maxlength={length_Hundred - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Date Of Joining"} />
            </div>
            <div className="col-6">
              <DatePicker
                value={dateOfJoining}
                setInputText={(value: string) => setDateOfJoining(value)}
                Isrequired={true}
                maxdate={today}
              />
            </div>
          </div>

          <div>
            {timeZoneArr?.map((value: any, index: any) => {
              return (
                <>
                  <span className="col-6 d-flex justify-content-end removeButton mb-1 mt-2">
                    {timeZoneArr.length === 1 ? null : (
                      <HighlightOffIcon
                        className="cursor"
                        color="primary"
                        fontSize="small"
                        onClick={() => handleCrossLive(index)}
                      />
                    )}
                  </span>
                  <div className="col-md-6 d-flex Justify-content-between mt-2 mb-2">
                    <div className="col-5 align-item-center d-flex">
                      <LabelField lableName={"Time Zone"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Time Zone"
                        value={timeZone}
                        options={timeZoneList}
                        Isrequired={true}
                        reset={resetTimezone}
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTimeZone(value, index)
                        }
                      />
                    </div>
                  </div>

                </>
              );
            })}
            <div className="d-flex col-6 d-flex justify-content-end mb-2">
              <Addmorebtn name="Add More" handleClick={onPressAdd} />
            </div>
          </div>

          <div className="col-md-6 d-flex Justify-content-between  ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Regular Academics"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Price"
                value={regularAcademics}
                onChangeInput={(value: any) => onChangeRegularAcademics(value)}
                minlength={length_One}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between  ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"College Consulting"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Price"
                value={collegeConsulting}
                onChangeInput={(value: any) => onChangeCollegeConsulting(value)}
                minlength={length_One}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between  ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Test Preperation"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Price"
                value={testPreperation}
                onChangeInput={(value: any) => onChangeTestPreperation(value)}
                minlength={length_One}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between  ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Advanced Academics"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Price"
                value={advancedAcademics}
                onChangeInput={(value: any) => onChangeAdvancedAcademics(value)}
                minlength={length_One}
                isRequired={true}
              />
            </div>
          </div>

          <div className="col-md-6 d-flex Justify-content-between  ">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Username"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Username"
                value={userName}
                onChangeInput={(value: any) => onChangeUserName(value)}
                maxlength={length_Thirty - 1}
                minlength={length_Four + 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-md-6 d-flex Justify-content-between mt-2">
            <div className="col-5 align-item-center d-flex">
              <LabelField lableName={"Password"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangepwd(value)}
                maxlength={length_Thirty - 1}
                minlength={length_Four + 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className="col-10 d-flex justify-content-center">
            <SubmitButton name="Create Teacher" validation={validation()} />
          </div>
        </div>
      </form>
    </div>
  );
}
