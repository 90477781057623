import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import {
  getDemoParentFeedBack,
} from "../Redux/Actions/AdminAction";
import RatingStar from "./ratingStar";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function DemoFeedBackPopup(props: props) {
  const dispatch: Function = useDispatch();
  const [q1ans, setq1ans] = useState({ id: 0, value: "" });
  const [q2ans, setq2ans] = useState({ id: 0, value: "" });
  const [q3ans, setq3ans] = useState<boolean>();
  const [q3anstxt, setq3anstxt] = useState("");
  const [q4ans, setq4ans] = useState({ id: 0, value: "" });
  const [q5ans, setq5ans] = useState("");
  const [q6ans, setq6ans] = useState("");

  const { open, handleClose, data } = props;

  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  useEffect(() => {
    const postData = {
      demoId: data?.demoId,
      teacherId: data?.teacherId,
    };
    dispatch(
      getDemoParentFeedBack(
        postData,
        (res: any) => {
          setq1ans({
            id: res?.feedback[0]?.answer,
            value: labels[res?.feedback[0]?.answer],
          });
          setq2ans({
            id: res?.feedback[1]?.answer,
            value: labels[res?.feedback[1]?.answer],
          });
          if (res?.feedback[2]?.answer === "Yes") {
            setq3ans(true);
          } else {
            setq3ans(false);
          }
          setq3anstxt(res?.feedback[2]?.answer);
          setq4ans({
            id: res?.feedback[3]?.answer,
            value: labels[res?.feedback[3]?.answer],
          });

          setq5ans(res?.feedback[4]?.answer);
          setq6ans(res?.feedback[5]?.answer);
        },
        () => {}
      )
    );
  }, []);

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoFeedbackmodeldivChat ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold"> Parent Feedback</h5>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-0">
            <div className="row mt-0 justify-content-center">
              <div className="px-2 mx-0 mt-0 Justify-content-space-evenly">
                <div className="col-md-12 px-0">
                  <div className="p-4">
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        1. How satisfied were you with the trial session
                        provided by LurniGo Tutors?
                      </div>
                      <div>
                        <RatingStar
                          value={q1ans?.id}
                          label={labels}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        2. How would you rate the tutor's expertise in the
                        subject taught?
                      </div>
                      <div>
                        <RatingStar
                          value={q2ans?.id}
                          label={labels}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        3. Was the session interactive and engaging for your
                        child?
                      </div>
                      <div className="d-flex">
                        <div className="form-check me-3">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios1"
                            value="option1"
                            defaultChecked={q3ans}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios1"
                          >
                            Yes
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="exampleRadios"
                            id="exampleRadios2"
                            value="option2"
                            defaultChecked={!q3ans}
                          />
                          <label
                            className="form-check-label"
                            htmlFor="exampleRadios2"
                          >
                            No
                          </label>
                        </div>
                      </div>
                      <span>{q3anstxt}</span>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        4. How likely are you to enroll your child for regular
                        sessions with LurniGo Tutors based on this trial?
                      </div>
                      <div>
                        <RatingStar
                          value={q4ans?.id}
                          label={labels}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        5. What did you like the most about the trial session?
                      </div>
                      <div>
                        <span>{q5ans}</span>
                      </div>
                    </div>
                    <div className="mt-2">
                      <div className="fw-bold mb-2">
                        6. Any other comments or suggestions?
                      </div>
                      <div>
                        <span>{q6ans}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
