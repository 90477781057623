import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SubmitButton from "../../Components/searchButton";
import {
  getCountryList,
  getTutorNetwork,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import ExportButton from "../../Components/exportButton";
import { DownloadTableExcel } from "react-export-table-to-excel";
import CustomDropdown from "../../Components/customDropdown";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function TutorNetwork() {
  const dispatch: Function = useDispatch();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [leads, setLeads] = useState([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    getLeads();
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const getLeads = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      countryCode: country?.phonecode,
      // skip: 0,
      // limit: 10,
    };
    if (country?.phonecode === "") {
      delete postdata["countryCode"];
    }
    dispatch(
      getTutorNetwork(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        (error: any) => {
          dispatch(showErrorSnackbar(error?.msg) as any);
        }
      )
    );
  };

  const handleEdit = (value: any) => {};
  const handleDelete = (value: any) => {};
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    dispatch(
      getTutorNetwork(
        postdata,
        (res: any) => {
          setLeads(res);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }

    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex justify-content-between mt-3">
          {leads?.length !== 0 && leads?.length !== 0 ? (
            <div className="pe-5 d-flex justify-content-end ms-3">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <ExportButton name={"Export Details"} />
              </DownloadTableExcel>
            </div>
          ) : (
            ""
          )}
          {leads?.length !== 0 ? (
            <div className="d-flex paginationdiv justify-content-end align-items-center">
              <Pagination
                count={totalPages}
                size="large"
                page={currentPage}
                variant="outlined"
                shape="rounded"
                color={"primary"}
                onChange={handleChange}
              />
            </div>
          ) : null}
        </div>

        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={leads ? leads : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div ref={tableRef}>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"tutorNetwork"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "SUBJECT NAME",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getLeads();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"TUTOR NETWORK"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-2 d-flex mb-2 align-items-center ms-5">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>
            <div className="d-flex col-md-3">
              <div className="col-md-2 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-md-5 ps-2">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex col-md-2 ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        {renderListMode()}
      </div>
    </div>
  );
}
