import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import {
  CounsellorPayTypeList,
  PaymentStatus,
  PaymentStatusList,
  PaymentTypes,
} from "../../Utils/dataConstant";
import {
  getCountryList,
  getCourseTypelist,
  getTransactionPaymentLinks,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import AdminTable from "../../Components/adminTable";

export default function CounsellorStudentPayLinks() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [type, setType] = useState<any>({
    _id: 6,
    name: "For Counsellor Link Payments",
  });
  const [country, setCountryCode] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
    isoCode: "USA",
    phonecode: 1,
    currency: "USD",
  });
  const [courseType, setCourseType] = useState<any>("");
  const [paymentStatus, setPaymentStatus] = useState<any>("");
  const [resetPaymentStatus, setResetPaymentStatust] = useState(false);

  const [countryList, setCountryList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [counsellorPayList, setCounsellorPayList] = useState<any>([]);

  const [resetType, setResetType] = useState<any>(false);
  const [resetCountry, setResetCountry] = useState<any>(false);
  const [resetCourseType, setResetCourseType] = useState<any>(false);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      counsellorId: userId,
      type: type?._id,
      country_code: country?._id,
    };
    dispatch(
      getTransactionPaymentLinks(
        postdata,
        (res: any) => {
          setCounsellorPayList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeType = (value: any) => {
    setType(value);
    setResetType(false);
  };

  const onChangeCountry = (value: any) => {
    setCountryCode(value);
    setResetCountry(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
  };

  const onChangeStatus = (value: any) => {
    setPaymentStatus(value);
    setResetPaymentStatust(false);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postdata = {
      startDate: startDate,
      endDate: endDate,
      counsellorId: userId,
      type: type?._id,
      country_code: country?._id,
      coursetypeId: courseType?._id,
      paymentStatusCode: paymentStatus?._id,
    };
    dispatch(
      getTransactionPaymentLinks(
        postdata,
        (res: any) => {
          setCounsellorPayList(res);
        },
        () => {}
      )
    );
  };

  console?.log("counsellorPayList", counsellorPayList);

  return (
    <div className="contentBox">
      <PageTitle name={"PREVIOUS PAYMENT LINKS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="d-flex flex-wrap  mt-3">
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Type"} />
              <div className="col-8">
                <CustomDropdown
                  lableName="Select Type"
                  setInputText={(value: any) => onChangeType(value)}
                  value={type}
                  options={CounsellorPayTypeList}
                  reset={resetType}
                  defaultValue={
                    CounsellorPayTypeList?.length > 0 ? type?._id : ""
                  }
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Country"} />
              <div className="col-8">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  reset={resetCountry}
                  defaultValue={countryList?.length > 0 ? country?._id : ""}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Course"} />
              <div className="col-8">
                <CustomDropdown
                  lableName="Select Course"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={courseType}
                  options={courseTypeList}
                  reset={resetCourseType}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Status"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Status"
                  setInputText={(value: any) => onChangeStatus(value)}
                  value={paymentStatus}
                  options={PaymentStatusList}
                  reset={resetPaymentStatus}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <Submitbtn name={"Search"} />
            </div>
          </div>
          <div className="row">
            <>
              <AdminTable
                tableData={counsellorPayList ? counsellorPayList : []}
                pagename={"counsellorPaymentList"}
                tableHead={[
                  "S.N",
                  "STUDENT NAME",
                  "PACKAGE NAME",
                  "INSTALLMENT",
                  "AMOUNT",
                  "COUPON NAME",
                  "PAYMENT STATUS",
                  "PAYMENT DATE",
                ]}
              />
            </>
          </div>
        </form>
      </div>
    </div>
  );
}
