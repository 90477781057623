import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import dsaManagerSidebarData from "./dsaManagerSidebarData";
import CreateUser from "./createUser";
import UpdateUser from "./updateUser";
import DemoPayments from "./demoPayments";
import DemoRequest from "./demoRequest";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoCancel from "./demoCancel";

export default function DsaManagerPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={dsaManagerSidebarData?.DsaManagerSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateUser />} />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="updateUser" element={<UpdateUser />} />
          <Route path="demoPayments" element={<DemoPayments />} />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoCancel" element={<DemoCancel />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
