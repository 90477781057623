import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SubmitButton from "./submitButton";
import {
  ReScheduleDemo,
  deleteRescheduleReq,
} from "../Redux/Actions/AdminAction";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "./customDropdown";
import LabelField from "./labelField";
import { cancelReasonList } from "../Utils/dataConstant";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList?: any;
}

export default function RescheduleCancelPopup(props: props) {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [reason, setReason] = useState<any>("");
  const [remark, setRemark] = useState<any>("");
  const [reasonList, setReasonList] = useState<any>(cancelReasonList);
  const [resetReason, setResetReason] = useState<any>(false);

  const { open, handleClose, data, getList } = props;

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    dispatch(
      deleteRescheduleReq(
        data?._id,
        () => {
          handleClose();
          getList();
        },
        () => { }
      )
    );
  };

  const onChangeReason = (value: any) => {
    setReason(value);
    setResetReason(false);
  };

  const changeRemark = (val: any) => {
    setRemark(val);
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoReSchedulemodel ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Cancel Request</h5>

            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-2">
            <form className="" onSubmit={handleSubmit}>
              <div className="row mt-2 justify-content-center">
                <div className="px-2 mx-0 mt-2 Justify-content-between">
                  <h4 className="fw-bold mb-4">Do You Want To Cancel?</h4>
                  <div className="col-md-12 d-flex mb-2 pe-3 mt-2">
                    <LabelField lableName={"Reason"} />
                    <div className="col-md-6">
                      <CustomDropdown
                        lableName="Select Reason"
                        setInputText={(value: any) => onChangeReason(value)}
                        value={reason}
                        options={reasonList}
                        reset={resetReason}
                      />
                    </div>
                  </div>
                  <div className="d-flex justify-content-between">
                    <LabelField lableName={"Remark"} />
                    <textarea
                      className="form-control"
                      placeholder={"Remark"}
                      value={remark}
                      onChange={(e) => changeRemark(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <SubmitButton name={"Cancel"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
