import { useState,useEffect } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import DatePicker from "../../Components/datePicker";
import DeleteIcon from '@mui/icons-material/Delete';
import Addmorebtn from '../../Components/addmorebtn';
import Submitbtn from "../../Components/submitButton";
import { getGeneralCourseList, getSubjectList, getGeneralExamList, addExamDates, getExamDateIconList, getGeneralSubjectList } from '../../Redux/Actions/AdminAction';
export default function CreateExamDate() {
  const dispatch = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState();
  const [resetExam, setResetExam] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [label, setLabel] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [icon, setIcon] = useState<any>("");
  const [iconList, setIconList] = useState([]);
  const [resetIcon, setResetIcon] = useState(false);
  const initialVal = {
    lableName : "",
    lableDate  : "",
    lableLogo : null
  };
  const [labelArr, setLabelArr] = useState([initialVal]);

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res)
        },
        () => { }
      )
    );

    

    dispatch(
      getExamDateIconList(
        "",
        (res: any) => {
          setIconList(res)
        },
        () => { }
      )
    );
  }, []);
  const onChangeCourse = (value: any) => {
    setCourse(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id
        },
        (res: any) => {
          setExamList(res);

        },
        () => { }
      )
    );

  };
  const onChangeExam = (value: any) => {
    setExam(value)
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: exam._id,
        },
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  
  const onChangeSubject = (value: any) => {
    setSubject(value)
  };
 

  const onPressAdd = () => {
    setLabelArr([...labelArr, initialVal]);
  };

  const onchangeLabel =(val:any ,index:any, key:any )=>{
    let newArrr = JSON.parse(JSON.stringify(labelArr));
    newArrr[index][key] = val;
    setLabelArr(newArrr);
  }
  const handleClose = (index: any) => {
    const newArrr = [...labelArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }

    setLabelArr(newArrr);
  };
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const PostData = {
      coursetypeId: course,
      examtypeId: exam._id,
      subjectId: subject._id,
      lableInfo: labelArr,
    }
 
    dispatch(
      addExamDates(
        PostData,
        (res: any) => {
          setResetParentCourse(true);
          setResetExam(true);
          setResetSubject(true);

        },
        () => { }
      )
    );
  }




  return (
    <div className="contentBox">
      <PageTitle name={"ADD TENTATIVE EXAM DATES"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='col-8 pt-2' onSubmit={handleSubmit} >
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) =>
                  onChangeCourse(value)
                }
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Name"
                setInputText={(value: any) =>
                  onChangeExam(value)
                }
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Subject"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Subject"
                setInputText={(value: any) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>

          {labelArr.map((item, index) => {
            return (<div className="d-flex Justify-content-between  ">
              <div className="col-3   ">
                <div><LabelField lableName={"Label Info " + (index + 1)} /></div>
              </div>
              <div className='col-10'>
                <div className=' mb-3 d-flex'>
                  <div className='col-11 border rounded'>
                    <div className='col-11'>
                      <InputField
                        placeholder="Enter Label Name"
                        value={item.lableName}
                        onChangeInput={(value: string) => { onchangeLabel(value, index, "lableName") }}
                        maxlength={100}
                        minlength={2}
                      />
                    </div>
                    <div className='col-11'>
                      <DatePicker
                        value={item.lableDate}
                        setInputText={(value: any) => { onchangeLabel(value, index, "lableDate") }}
                      />
                    </div>
                    <div className='col-12 ps-2'>
                      <CustomDropdown
                        lableName="Select Icon"
                        setInputText={(value: any) => { onchangeLabel(value.lableLogo, index, "lableLogo") }}
                        value={item.lableLogo}
                        options={iconList}
                        Isrequired={true}
                        reset={resetIcon}
                      />
                    </div>
                  </div>
                  {labelArr.length === 1 ? null : (<DeleteIcon className="cursor" color="error" fontSize='small' onClick={() => handleClose(index)} />)}
                </div> </div>
            </div>)
          })}

          <div className='d-flex justify-content-end'>
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <Submitbtn
              name={"Submit"}

            />
          </div>
        </form>
      </div>
    </div>
  )
}

