import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getGlobalSettingList,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import {
  length_One,
  length_TwoHundredFifty,
  validateIsNumberOnly,
} from "../../Utils/validators";
import { GradeList, currencyList } from "../../Utils/dataConstant";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function UpdateGlobalSetting() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [subtopic, setSubTopic] = useState<any>("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [type, setType] = useState<any>("");
  const [globalSettingList, setGlobalSettingList] = useState([]);
  const [classCharges, setClassCharges] = useState<any>("");
  const [currency, setCurrency] = useState<any>("");
  const [resetCurrencyList, setResetCurrencyList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCountryList(false);
    const data = {
      coursetypeId: value?._id,
    };
    dispatch(
      getGlobalSettingList(
        data,
        (res: any) => {
          setGlobalSettingList(res);
          setResetCountryList(false);
          setResetExam(true);
          setResetGradeList(true);
          setResetSubject(true);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const data = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      getGlobalSettingList(
        data,
        (res: any) => {
          setGlobalSettingList(res);
          setResetGradeList(false);
          setResetSubject(true);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: value?._id,
    };
    dispatch(
      getGlobalSettingList(
        data,
        (res: any) => {
          setGlobalSettingList(res);
          setResetExam(false);
          setResetSubject(true);
        },
        () => {}
      )
    );

    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          // setResetGradeList(true);
          // setGrade("");
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getGlobalSettingList(
        data,
        (res: any) => {
          setGlobalSettingList(res);
          setResetSubject(false);
        },
        () => {}
      )
    );
  };

  const onChangeType = (value: any) => {
    setType(value);
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: subject,
      isNative: type === 1 ? true : false,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getGlobalSettingList(
        data,
        (res: any) => {
          setGlobalSettingList(res);
        },
        () => {}
      )
    );
  };
  const onchangeClassCharges = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setClassCharges(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeCurrency = (value: any) => {
    setCurrency(value?.name);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
  };
  const handleDelete = (value: any) => {};
  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={globalSettingList ? globalSettingList : []}
          pagename={"globalSetting"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "COURSETYPE",
            "EXAM",
            "SUBJECT",
            "TYPE",
            "CHARGES",
            "CURRENCY",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };
  function validation() {
    return courseType === "" || exam === "" || subject === "";
  }

  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="col-10" onSubmit={handleSubmit}>
              <div className="d-flex Justify-content-between mt-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    defaultValue={courseType?.length > 0 ? courseType?._id : ""}
                    Isrequired={true}
                    disabled
                  />
                </div>
              </div>
              {courseType?.type === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      options={GradeList}
                      Isrequired={true}
                      defaultValue={GradeList.length > 0 ? grade : ""}
                      reset={resetGradeList}
                      disabled
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      defaultValue={examList.length > 0 ? exam : ""}
                      disabled
                    />
                  </div>
                </div>
              )}
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    defaultValue={subjectList?.length > 0 ? subject : ""}
                    disabled
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between mt-2 mb-2">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Teacher Type"} />
                </div>
                <div className="col-5 d-flex ps-3 ">
                  <div className="col-4">
                    <span>
                      <input
                        type="radio"
                        value={1}
                        name={"type"}
                        checked={type === 1 ? true : false}
                        onChange={() => onChangeType(1)}
                      />{" "}
                      Native
                    </span>
                  </div>
                  <div className="col-6">
                    <span>
                      <input
                        type="radio"
                        value={2}
                        name={"type"}
                        checked={type === 2 ? true : false}
                        onChange={() => onChangeType(2)}
                      />{" "}
                      Indian
                    </span>
                  </div>
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Per Class Charges "} />
                </div>
                <div className="col-6 ps-2 ">
                  <InputField
                    placeholder="Per Class Charges"
                    value={classCharges}
                    isRequired={true}
                    onChangeInput={(value: any) => onchangeClassCharges(value)}
                    maxlength={length_TwoHundredFifty - 1}
                    minlength={length_One}
                  />
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"currency"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Currency"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCurrency(value)
                    }
                    value={currency}
                    options={currencyList}
                    Isrequired={true}
                    reset={resetCurrencyList}
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Save & Update"} validation={validation()} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT GLOBAL SETTING"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="px-4 mx-0 pt-4">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                Isrequired={true}
              />
            </div>
          </div>

          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  options={GradeList}
                  Isrequired={true}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  Isrequired={true}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between my-2 col-md-6">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Teacher Type"} />
            </div>
            <div className="col-6 d-flex ps-3 ">
              <div className="col-6">
                <span>
                  <input
                    type="radio"
                    value={1}
                    name={"type"}
                    checked={type === 1 ? true : false}
                    onChange={() => onChangeType(1)}
                  />{" "}
                  Native
                </span>
              </div>
              <div className="col-6">
                <span>
                  <input
                    type="radio"
                    value={2}
                    name={"type"}
                    checked={type === 2 ? true : false}
                    onChange={() => onChangeType(2)}
                  />{" "}
                  Indian
                </span>
              </div>
            </div>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
