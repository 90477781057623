import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import { useDispatch } from "react-redux";
import CustomDropdown from "../../Components/customDropdown";
import {
  getGeneralExamList,
  bindSubject,
  assignedSubjectList,
  getCourseTypelist,
  getCountryList,
} from "../../Redux/Actions/AdminAction";
import { GradeList } from "../../Utils/dataConstant";

export default function AssignSubject() {
  const dispatch: Function = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [exam, setExam] = useState<string>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [subjectList, setSubjectList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const subjectArray: string[] = [];
    subjectList.forEach((element: { assigned: boolean; _id: string }) => {
      if (element.assigned) {
        subjectArray.push(element._id);
      }
    });
    const Postdata: any = {
      coursetypeId: course?._id,
      examtypeId: exam,
      subjectId: subjectArray,
      gradeId: grade?._id,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    dispatch(
      bindSubject(
        Postdata,
        (res: any) => {
          setresetExam(true);
          setResetParentCourse(true);
          setExam("");
          setCourse("");
          setResetGradeList(true);
          setResetCountryList(true);
          subjectList.map((item: any) => (item.assigned = false));
        },
        () => { }
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
          setResetParentCourse(false);
          setresetExam(true);
          setResetGradeList(true);
          setSubjectList([])
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: any) => {
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setresetExam(false);
          setGrade("")
          setExam("")
          setResetGradeList(true);
          setSubjectList([])
        },
        () => { }
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postdata = {
      coursetypeId: course?._id,
      examtypeId: value._id,
    };
    dispatch(
      assignedSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: course?._id,
      examtypeId: value._id,
    };
    dispatch(
      assignedSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const changeCheckBox = (event: any, index: any) => {
    let newArrr = JSON.parse(JSON.stringify(subjectList));
    if (event.target.checked) {
      newArrr[index]["assigned"] = event.target.checked;
      setSubjectList(newArrr);
    } else {
      newArrr[index]["assigned"] = event.target.checked;
      setSubjectList(newArrr);
    }
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postdata = {
      coursetypeId: course?._id,
      gradeId: value._id,
    };
    dispatch(
      assignedSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: course?._id,
      gradeId: value._id,
    };
    dispatch(
      assignedSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  function validation() {
    const subjectArray: string[] = [];
    subjectList.forEach((element: { assigned: boolean; _id: string }) => {
      if (element.assigned) {
        subjectArray.push(element._id);
      }
    });
    return course === "" || subjectArray.length === 0;
  }

  return (
    <div className="contentBox">
      <PageTitle name={"ASSIGN SUBJECT"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 mt-3">
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-3 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex Justify-content-between">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-3 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.course?.type === 3 || course?.type === 2 ? null : (
              <div className="d-flex Justify-content-between">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-6 ps-3 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                  />
                </div>
              </div>
            )}
            {subjectList?.length > 0 ? (
              <div className="d-flex Justify-content-between ">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Subject Name "} />
                </div>
                <div className="d-flex col-8 ps-3">
                  <div className="border rounded p-3">
                    <div className=" input-border">
                      {subjectList.map((item: any, index: any) => {
                        const { _id, name } = item;
                        return (
                          <div key={item?._id} className="d-flex center p-1">
                            <div className="w-100">
                              <div className="d-flex justify-content-between">
                                <div className="d-flex e-input align-items-center ms-3">
                                  <input
                                    id={_id}
                                    className="check"
                                    type="checkbox"
                                    checked={item?.assigned}
                                    onChange={(event) => {
                                      changeCheckBox(event, index);
                                    }}
                                  />
                                  <span className="checkVal ms-3">{name}</span>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Assign Subject"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
