import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import AdminTable from "../../Components/adminTable";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import {
  deleteCourseType,
  getCountryList,
  getCourseTypelist,
  updateCourseType,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  validateIsNumberOnly,
  length_Ten,
  length_Hundred,
  length_Two,
  length_OneHundredFifty,
} from "../../Utils/validators";
import CustomDropdown from "../../Components/customDropdown";

export default function UpdateParentCourse() {
  const initialVal = {
    coursetypeName: "",
    expertNo: "",
    expertMsg: "",
  };
  const dispatch: Function = useDispatch();
  const [coursename, setCoursename] = useState<string>("");
  const [expno, setExpno] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [courseList, setCourseList] = useState<any>("");
  const [isEdit, setIsEdit] = useState<boolean>();
  const [updateCourse, setUpdateCourse] = useState<string>("");
  const [courseTypeId, setCourseTypeId] = useState<any>("");
  const [courseArr, setcourseArr] = useState<any>(initialVal);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [type, setType] = useState<any>("");

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEdit(true);
    setCountry(value?.countryId?._id);
    setCoursename(value?.name);
    setType(value?.type);
    setExpno(value?.expertNo);
    setMessage(value?.expertMsg);
    setCourseTypeId(value._id);
    setcourseArr(value);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteCourseType(
        value._id,
        () => {
        },
        () => {}
      )
    );
  };

  const handleOnEdit = (e: any) => {
    e.preventDefault();

    const postData: any = {};
    if (courseArr?.name != coursename) {
      postData["coursetypeName"] = coursename;
    }
    if (courseArr?.expertNo != expno) {
      postData["expertNo"] = expno;
    }
    if (courseArr?.expertMsg != message) {
      postData["expertMsg"] = message;
    }

    dispatch(
      updateCourseType(
        postData,
        courseTypeId,
        (res: any) => {
          setUpdateCourse(res);
          setIsEdit(false);
        },
        () => {}
      )
    );
  };

  const onChangeExperNo = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setExpno(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeCourseName = (value: any) => {
    setCoursename(value);
  };
  const onChangeMessage = (value: any) => {
    setMessage(value);
  };
  const onChangeType = (value: any) => {
    setType(value);
  };
  function validation() {
    return (
      coursename === "" ||
      coursename.length < length_Two - 1 ||
      expno === "" ||
      expno.length === length_Ten - 1 ||
      message === "" ||
      message.length < length_Ten - 1
    );
  }
  const renderListMode = () => {
    return (
      <>
        <AdminTable
          tableData={courseList ? courseList : []}
          pagename={"ParentCourse"}
          tableHead={[
            "S.N",
            "Parent Course Name",
            "Expert Number",
            "Message",
            "Action",
          ]}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          edit={true}
        />
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <form className="" onSubmit={handleOnEdit}>
          <div className=" col-7">
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course type"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter Parent Course"
                  value={coursename}
                  onChangeInput={(value: string) => onChangeCourseName(value)}
                  maxlength={length_Hundred}
                  minlength={length_Two}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between my-2 col-md-6">
              <div className="col-6 align-item-center d-flex">
                <LabelField lableName={"Type"} />
              </div>
              <div className="col-7 d-flex ps-4">
                <div className="col-4">
                  <span>
                    <input
                      type="radio"
                      value={1}
                      name={"type"}
                      checked={type === 1 ? true : false}
                      onChange={() => onChangeType(1)}
                    />{" "}
                    Exam
                  </span>
                </div>
                <div className="col-4">
                  <span>
                    <input
                      type="radio"
                      value={2}
                      name={"type"}
                      checked={type === 2 ? true : false}
                      onChange={() => onChangeType(2)}
                    />{" "}
                    Grade
                  </span>
                </div>
                <div className="col-4">
                  <span>
                    <input
                      type="radio"
                      value={3}
                      name={"type"}
                      checked={type === 3 ? true : false}
                      onChange={() => onChangeType(3)}
                    />{" "}
                    None
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Expert Number"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter Expert Number"
                  value={expno}
                  maxlength={length_Ten}
                  minlength={length_Ten}
                  onChangeInput={(value: string) => onChangeExperNo(value)}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Talk To Expert Message"} />
              </div>
              <div className="col-6 ps-3 ">
                <InputField
                  placeholder="Enter Message"
                  value={message}
                  onChangeInput={(value: string) => onChangeMessage(value)}
                  minlength={length_Ten}
                  maxlength={length_OneHundredFifty - 1}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Save & Update"} validation={validation()} />
          </div>
        </form>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT COURSE "}
        isEditMode={isEdit}
        setisEditMode={setIsEdit}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        {isEdit !== true ? (
          <div>
            <div className=" col-7">
              <div className="d-flex Justify-content-between mt-2">
                <div className="col-3 align-item-center d-flex">
                  <LabelField lableName={"Country"} />
                </div>
                <div className="col-6 ps-3 ">
                  <CustomDropdown
                    lableName="Select Country"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={country}
                    options={countryList}
                    reset={resetCountryList}
                  />
                </div>
              </div>
            </div>
          </div>
        ) : null}
        {isEdit ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
