import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import { getUpcomingList } from "../../Redux/Actions/TeacherActions";
import ClassRequestCard from "../../Components/classRequestCard";
export default function ClassSchedule() {
  const dispatch = useDispatch();
  const [upcomingList, setUpcomingList] = useState<any>([]);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    dispatch(
      getUpcomingList(
        userId,
        { statusId: 1 },
        (res: any) => {
          setUpcomingList(res?.data);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CLASS SCHEDULED"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}></form>

        <div className="row">
          {upcomingList?.map((item: any, index: any) => {
            return (
              <div className="col-md-4  py-5 p-2">
                <ClassRequestCard
                  key={index}
                  data={item}
                  name={"CLASS SCHEDULED"}
                  pageName={"teacherPanelClassSchedule"}
                  getDemoReq={getDemoScheduledList}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
