import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationTable from "../../Components/paginationTable";
import { useDispatch } from "react-redux";

import moment from "moment";
import {
  getCountryList,
  getOverdueInstallments,
} from "../../Redux/Actions/AdminAction";

export default function OverdueInstallments() {
  const dispatch: Function = useDispatch();
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(endDate).subtract(6, "days").format("YYYY-MM-DD")
  );
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [installmentList, setInstallmentList] = useState([]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    getPaymentData();
  }, []);

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: data?._id,
    };

    dispatch(
      getOverdueInstallments(
        postdata,
        (res: any) => {
          setInstallmentList(res?.data);
        },
        () => { }
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="mt-3">
        <PaginationTable
          tableData={installmentList ? installmentList : []}
          pagename={"OverdueInstallments"}
          rowsPerPage={0}
          page={0}
          tableHead={[
            "S.N",
            "ORDER NO",
            "BUYER NAME",
            "REMAINING AMOUNT",
            "INSTALLMENT",
            "OVERDUE INSTALLMENT DATE",
            "INSTALLMENT LAST DATE",
            "PAYMENT STATUS",
          ]}
          edit={true}
        />
      </div>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getPaymentData();
  };

  const getPaymentData = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
    };

    dispatch(
      getOverdueInstallments(
        postdata,
        (res: any) => {
          setInstallmentList(res?.data);
        },
        () => { }
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"OVERDUE INSTALLMENTS"} />
      <form className="text-center" onSubmit={handleSubmit}>
        <div className="px-2 mx-0 mt-4 row d-flex Justify-content-between">
          <div className="col-md-4 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Start Date:"} />
            </div>
            <div className="col-10 ps-5">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => setStartDate(value)}
                Isrequired
              />
            </div>
          </div>
          <div className=" col-md-4 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"End Date:"} />
            </div>
            <div className="col-10 ps-5">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => setEndDate(value)}
                Isrequired
              />
            </div>
          </div>

          <div className="col-md-3">
            <button className={"btn btn-dark"}>Search</button>
          </div>
        </div>
      </form>
      <div className="demoReqCountry mt-4">
        <div className="d-flex">
          <h5 className="font-weight-bold me-5">Country</h5>
          {countryList?.map((item: any, index: any) => {
            return (
              <>
                <button
                  className={`btn btn-sm me-4 ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                    }`}
                  onClick={(e) => onChangeCountry(e, index + 1, item)}
                >
                  {item?.name}
                </button>
              </>
            );
          })}
        </div>
      </div>
      {renderListMode()}
    </div>
  );
}
