import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import SocialMediasidebarData from "./socialMediasidebarData";
import CreateBlog from "./createBlog";
import UpdateBlog from "./updateBlog";

export default function SocialMediaDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={SocialMediasidebarData?.SocialMediasidebarData} />

      <div className="dashboard">
        <Routes>
            <Route path="/" element={<CreateBlog />} />  
            <Route path="createBlog" element={<CreateBlog />} /> 
            <Route path="updateBlog" element={<UpdateBlog />} /> 
          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
