import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  deleteNotification,
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getNotificationEditData,
  getNotificationIconList,
  getNotificationList,
  updateNotification,
} from "../../Redux/Actions/AdminAction";
import {
  notificationTypeData,
  priorityOrderData,
  notificationScheduleType,
} from "../../Utils/dataConstant";
import { length_FiveHundred, length_Ten } from "../../Utils/validators";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import InputTextArea from "../../Atoms/InputTextArea";
import {
  getGeneralPackageList,
  getPackageSessionList,
  getStudentList,
  getTimezoneList,
} from "../../Redux/Actions/SuperAdminAction";
import DateTimePicker from "../../Components/dateTimePicker";

export default function UpdateNotification() {
  const dispatch = useDispatch();
  const [noti, setNoti] = useState<any>(1);
  const [courseType, setCourseType] = useState("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [notification, setNotification] = useState<any>("");
  const [notificationList, setNotificationList] = useState<any>([]);
  const [resetNotification, setResetNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [priorityOrder, setPriorityOrder] = useState<any>("");
  const [priorityOrderList, setPriorityOrderList] = useState<any>([]);
  const [descriptionArr, setDescription] = useState("");
  const [date, setdate] = useState("");
  const [timezone, setTimezone] = useState<any>("");
  const [timezoneList, setTimezoneList] = useState<any>([]);
  const [batchName, setBatchName] = useState<any>("");
  const [batchNameList, setBatchNameList] = useState<any>([]);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  const [batchList, setBatchList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);

  const [notificationId, setNotificationId] = useState<any>("");
  const [notificationEditDataList, setNotificationEditDataList] = useState<any>(
    []
  );

  const [notificationIconList, setNotificationIconList] = useState([]);
  const [icon, setIcon] = useState<any>("");

  // const [mindate, setmindate] = useState(today_dateTime);

  const [batch, setBatch] = useState<any>("");

  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);

  useEffect(() => {
    setPriorityOrderList(priorityOrderData);

    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );

    dispatch(
      getNotificationIconList(
        "",
        (res: any) => {
          setNotificationIconList(res);
        },
        () => {}
      )
    );

    dispatch(
      getTimezoneList(
        {},
        (res: any) => {
          setTimezoneList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
        },
        () => {}
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setSubjectList(res);
          setResetExam(false);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };
  const onChangeNotificationType = (value: { _id: any; name?: string }) => {
    setNotification(value._id);
  };
  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value._id);
  };
  const onChangePriority = (value: { _id: any; name?: string }) => {
    setPriorityOrder(value);
  };
  function onChangeDesc(val: any) {
    setDescription(val);
  }
  const onChangeTimeZone = (value: any) => {
    setTimezone(value);
  };
  const onChangeBatchName = (value: any) => {
    setBatchName(value);
  };
  const onChangeStudentSearch = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };

  const changeNotificationDate = (value: any) => {
    setdate(value);
  };

  const onSelectBatch = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(notificationEditDataList));
    if (event.target.checked) {
      newArray.packages[index]["isSelected"] = event.target.checked;
      selectedBatch.push(parseInt(event.target.id));
      setSelectedBatch(selectedBatch);
      setNotificationEditDataList(newArray);
    }
  };

  const onSelectGeneralBatch = (event: any, index: any) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
  };

  const onChangeBatch = (value: any) => {
    setBatch(value._id);
  };

  const getData = () => {
    setNotificationList([]);
    const postData: any = {
      scheduleForId: noti,
      coursetypeId: courseType,
      examtypeId: exam,
      subjectId: subject,
      notificationTypeId: notification,
      packageIds: noti === 1 ? selectedBatch : [batch],
    };

    if (selectedBatch.length === 0 && batch === "")
      delete postData["packageIds"];
    if (exam === "") delete postData["examtypeId"];
    if (subject === "") delete postData["subjectId"];

    dispatch(
      getNotificationList(
        postData,
        (res: any) => {
          setNotificationList(res);
        },
        () => {}
      )
    );
  };

  const onChangeRegisteredStud = (event: any, index: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);

    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList, ""]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  const onChangeStudent = () => {
    dispatch(
      getStudentList(
        {
          examtypeId: exam,
          search: student,
        },
        (res: any) => {
          selectedStudent?.map((item: any, i: any) =>
            res?.map((data: any, index: any) => {
              if (data?._id == item) {
                data["selected"] = true;
              }
            })
          );
          setStudentList(res);
        },
        () => {}
      )
    );
  };

  const onChange = () => {
    getData();
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    var result = selectedStudent.map(function (x: string) {
      return parseInt(x);
    });
    const postData: any = {
      scheduleForId: noti,
      coursetypeId: courseType,
      notificationTypeId: notification?._id,
      notificationTitle: notificationTitle,
      priorityorderId: priorityOrder._id,
      notificationText: descriptionArr,
      scheduledAt: date,
      notificationIconId: icon._id,
    };
    if (result.length > 0) {
      postData["studentArrayID"] = result;
    }
    if (noti === 1 || noti === 2) {
      postData["packageArrayId"] = noti === 1 ? selectedBatch : [batch];
    }
    if (noti !== 4) {
      postData["examtypeId"] = exam;
      postData["subjectId"] = subject;
    }
    dispatch(
      updateNotification(
        postData,
        notificationId,
        (res: any) => {
          setisEditMode(false);
          setSelectedStudentList([]);
          setSelectedStudent([]);
          getData();
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setNotificationId(value?.notificationId);
    setSelectedStudent([]);
    setSelectedStudentList([]);
    dispatch(
      getNotificationEditData(
        value?.notificationId,
        (res: any) => {
          setisEditMode(true);
          setNotificationEditDataList(res);
          setCourseType(res?.data?.coursetypeId);
          setNotification(res?.data?.notificationType?._id);
          setNotificationTitle(res?.data?.notificationTitle);
          setPriorityOrder(res?.data?.priorityorderId);
          setDescription(res?.data?.notificationText);
          setdate(res?.data?.scheduledAt);
          setTimezone(res?.data?.timezone?._id);
          setIcon(res?.data?.notificationIcon?.icon);
          setSession(res?.data?.session?._id);
          

          // *********student**********
          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudent;
            if (item?.isSelected) {
              newArrr.push(item.studentId);
            }
            setSelectedStudent(newArrr);
          });
          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudentList;
            if (item?.isSelected) {
              newArrr.push(
                `${item.firstName} ${item.lastName} - ${item.mobile}`
              );
            }
            setSelectedStudentList(newArrr);
          });

          // ******packages***********

          res?.packages?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedBatch;
            if (item?.isSelected) {
              newArrr.push(item._id);
            }
            setSelectedBatch(newArrr);
          });

          dispatch(
            getPackageSessionList(
              {
                packageIds: selectedBatch,
              },
              (res: any) => {
                setSessionList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const handleDelete = (value: any) => {
    dispatch(
      deleteNotification(
        value.notificationId,
        () => {
          getData();
        },
        () => {}
      )
    );
  };
  const onChangeValue = (e: any, index: any) => {
    setNoti(index);
  };

  useEffect(() => {
    if (isEditMode === false) {
      setSelectedStudentList([]);
      setSelectedStudent([]);
    }
  }, [isEditMode]);

  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={notificationList ? notificationList : []}
          pagename={"Notification"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", "Notification Title", "Date & Time", "ACTION"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="col-10" onSubmit={handleSubmit}>
              <div className="col-10">
                <div className="d-flex Justify-content-between mb-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Schedule For"} />
                  </div>
                  <div className="col-10 d-flex">
                    {notificationScheduleType.map((c: any, index: any) => {
                      return (
                        <>
                          {noti === c?._id ? (
                            <div className="col-3">
                              <span className="me-3 radiobtnspan">
                                <span>
                                  <input
                                    type="radio"
                                    name="type"
                                    value={c._id}
                                    checked={noti === c?._id ? true : false}
                                    onChange={(e) => onChangeValue(e, c._id)}
                                  />{" "}
                                  <span className="ms-2">{c?.name}</span>
                                </span>
                              </span>
                            </div>
                          ) : null}
                        </>
                      );
                    })}
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Course Type"} />
                  </div>
                  <div className="col-6 ps-2  ">
                    <CustomDropdown
                      lableName="Select Course Type"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeCourseType(value)
                      }
                      value={courseType}
                      options={courseTypeList}
                      defaultValue={courseType}
                      Isrequired={true}
                      disabled
                    />
                  </div>
                </div>

                {noti !== 4 ? (
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Exam Name"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Exam Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeExam(value)
                        }
                        value={exam}
                        options={examList}
                        defaultValue={examList.length > 0 ? exam : ""}
                        Isrequired={true}
                        disabled
                      />
                    </div>
                  </div>
                ) : null}
                {noti !== 4 ? (
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Subject Name"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Subject Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSubject(value)
                        }
                        value={subject}
                        options={subjectList}
                        defaultValue={subjectList.length > 0 ? subject : ""}
                        Isrequired={true}
                        reset={resetSubject}
                        disabled
                      />
                    </div>
                  </div>
                ) : null}
                {noti === 1 && batchList.length > 0 ? (
                  <>
                    <div className="d-flex Justify-content-between">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Batch Name"} />
                      </div>
                      <div className="col-6  ">
                        <div className=" border rounded p-3 mx-2 my-2">
                          {notificationEditDataList?.packages !== undefined
                            ? notificationEditDataList?.packages.map(
                                (c: any, index: any) => {
                                  return (
                                    <div className="ms-3">
                                      <input
                                        type="checkbox"
                                        id={c._id}
                                        value={c.name}
                                        onChange={(e) =>
                                          onSelectBatch(e, index)
                                        }
                                        defaultChecked={c.isSelected}
                                      />
                                      <span className="ms-2">{c.name}</span>
                                    </div>
                                  );
                                }
                              )
                            : null}
                        </div>
                      </div>
                    </div>
                    <div className="d-flex Justify-content-between mt-3">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Session"} />
                      </div>

                      <div className="col-6 ps-2 ">
                        <CustomDropdown
                          lableName="Select Session"
                          setInputText={(value: {
                            _id: string;
                            name: string;
                          }) => onChangeSession(value)}
                          value={session}
                          options={sessionList}
                          Isrequired={true}
                          defaultValue={sessionList.length > 0 ? session : ""}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                {noti === 2 ? (
                  <>
                    <div className="d-flex Justify-content-between">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Batch Name"} />
                      </div>
                      <div className="col-6 ps-2">
                        <CustomDropdown
                          lableName="Select Batch Name"
                          setInputText={(value: {
                            _id: string;
                            name: string;
                          }) => onChangeBatchName(value)}
                          value={batchName}
                          options={batchNameList}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}

                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Notification Type"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Notification Type"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeNotificationType(value)
                      }
                      value={notification}
                      options={notificationTypeData}
                      Isrequired={true}
                      defaultValue={
                        notificationTypeData.length > 0 ? notification : ""
                      }
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Notification Title"} />
                  </div>

                  <div className="col-6 ">
                    <InputField
                      placeholder="Enter Notification Title"
                      value={notificationTitle}
                      onChangeInput={(value: any) =>
                        setNotificationTitle(value)
                      }
                      maxlength={50}
                      minlength={3}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Priority Order"} />
                  </div>

                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Priority Order"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangePriority(value)
                      }
                      value={priorityOrder}
                      options={priorityOrderList}
                      Isrequired={true}
                      defaultValue={
                        priorityOrderList.length > 0 ? priorityOrder : ""
                      }
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Notification Text"} />
                  </div>

                  <div className="col-6 ps-2 ">
                    <InputTextArea
                      placeholder="Enter Notification Description"
                      rows={4}
                      cols={40}
                      value={descriptionArr}
                      onChangeInput={(value: any) => {
                        onChangeDesc(value);
                      }}
                      maxlength={length_FiveHundred}
                      minlength={length_Ten}
                      defaultValue={descriptionArr}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Date & Time"} />
                  </div>
                  <div className="col-6 d-flex ">
                    <div className="col-6">
                      <div className="fw-bold ps-2">From Date</div>
                      <DateTimePicker
                        setInputText={(value: any) => {
                          changeNotificationDate(value);
                        }}
                        value={date}
                        defaultValue={date}
                      />
                    </div>
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Time Zone"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Time Zone"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeTimeZone(value)
                      }
                      value={timezone}
                      options={timezoneList}
                      Isrequired={true}
                      defaultValue={timezoneList.length > 0 ? timezone : ""}
                    />
                  </div>
                </div>

                <div className="d-flex Justify-content-between ps-2">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Icon"} />
                  </div>
                  <div className="col-6  d-flex pe-3">
                    <div className="col-12 border rounded p-3">
                      {notificationIconList.map((item: any) => {
                        return (
                          <div className=" ">
                            <input
                              type="radio"
                              className="me-2"
                              name="icon"
                              value={item}
                              onChange={() => setIcon(item.notificationIcon)}
                              checked={
                                icon == item?.notificationIcon ? true : false
                              }
                            />
                            {
                              <img
                                src={item?.notificationIcon}
                                style={{ width: "40px", height: "40px" }}
                                alt={""}
                              />
                            }
                            {"   "}
                            {item?.originaliconName}
                          </div>
                        );
                      })}
                    </div>
                    <span className="required ms-2 fs-6">*</span>
                  </div>
                </div>

                {noti === 2 || noti === 3 ? (
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Scheduled For"} />
                    </div>
                    <div className="col-6 p-2 border rounded">
                      <p className="fw-bold ">Registered Student</p>
                      <div className="d-flex">
                        <InputField
                          placeholder="Enter student name"
                          value={student}
                          onChangeInput={(value: any) =>
                            onChangeStudentSearch(value)
                          }
                          maxlength={50}
                        />
                        <button
                          type="button"
                          className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
                          onClick={(e) => onChangeStudent()}
                        >
                          Search
                        </button>
                      </div>

                      {studentList !== undefined && student
                        ? studentList.map((c: any, index: any) => {
                            return (
                              <div className="ms-3">
                                <input
                                  type="checkbox"
                                  id={c._id}
                                  value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                                  onChange={(e) =>
                                    onChangeRegisteredStud(e, index)
                                  }
                                  defaultChecked={c.selected}
                                />
                                <span className="ms-2">
                                  {c.firstName} {c.lastName}
                                </span>
                              </div>
                            );
                          })
                        : null}
                    </div>
                    {selectedStudentList && selectedStudentList?.length > 0 ? (
                      <div className="col-auto p-2 border rounded">
                        {selectedStudentList.map((item: any, index: any) => {
                          return <div>{item}</div>;
                        })}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Save & Update"} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT NOTIFICATION"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="p-4">
          <div className="col-10 d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {notificationScheduleType.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          value={c._id}
                          checked={noti === c?._id ? true : false}
                          onChange={() => setNoti(c?._id)}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="col-10">
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2  ">
                <CustomDropdown
                  lableName="Select Course Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCourseType(value)
                  }
                  value={courseType}
                  options={courseTypeList}
                  Isrequired={true}
                />
              </div>
            </div>
            {noti === 1 || noti === 2 || noti === 3 ? (
              <>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                      Isrequired={true}
                    />
                  </div>
                </div>
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Subject Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Subject Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubject(value)
                      }
                      value={subject}
                      options={subjectList}
                      Isrequired={true}
                      reset={resetSubject}
                    />
                  </div>
                </div>
              </>
            ) : null}

            {noti === 1 ? (
              <>
                {batchList.length > 0 ? (
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Batch Name"} />
                    </div>

                    <div className="col-6 pe-2">
                      <div className="border rounded p-3 m-2 mb-3 mt-0 ">
                        {batchList !== undefined
                          ? batchList.map((c: any, index: any) => {
                              return (
                                <div className="ms-3">
                                  <input
                                    type="checkbox"
                                    id={c._id}
                                    value={c.name}
                                    onChange={(e) =>
                                      onSelectGeneralBatch(e, index)
                                    }
                                  />
                                  <span className="ms-2">{c.name}</span>
                                </div>
                              );
                            })
                          : null}
                      </div>
                    </div>
                  </div>
                ) : null}
              </>
            ) : (
              ""
            )}

            {noti === 2 && batchList.length > 0 ? (
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>

                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Batch Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeBatch(value)
                    }
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                  />
                </div>
              </div>
            ) : null}

            {!isEditMode ? (
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Notification Type"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Notification Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeNotificationType(value)
                    }
                    value={notification}
                    options={notificationTypeData}
                    Isrequired={true}
                    reset={resetNotification}
                  />
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => onChange()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
