// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.day{
    width: 30%;
    text-align: center;
    border: 1px solid #ccc;
    color: var(--gray);
    flex-grow: 1;
    padding: 3px;
    cursor: pointer;
    margin: 3px;
}
.day:hover {
    background: #f6f7f8;
}
.day.active{
    background: var(--gray);
    color: #fff;
}
.timeCard{
    width: 30%;
    text-align: center;
    color: var(--gray);
    /* flex-grow: 1; */
    cursor: pointer;
    margin: 5px 10px 5px 0;
    padding: 12px;
}
.timeCard:hover{
background: #f6f7f8;
}
.timeCard.active{
    background: var(--gray);
    color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/BookSlotDatePicker.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,kBAAkB;IAClB,sBAAsB;IACtB,kBAAkB;IAClB,YAAY;IACZ,YAAY;IACZ,eAAe;IACf,WAAW;AACf;AACA;IACI,mBAAmB;AACvB;AACA;IACI,uBAAuB;IACvB,WAAW;AACf;AACA;IACI,UAAU;IACV,kBAAkB;IAClB,kBAAkB;IAClB,kBAAkB;IAClB,eAAe;IACf,sBAAsB;IACtB,aAAa;AACjB;AACA;AACA,mBAAmB;AACnB;AACA;IACI,uBAAuB;IACvB,WAAW;AACf","sourcesContent":[".day{\n    width: 30%;\n    text-align: center;\n    border: 1px solid #ccc;\n    color: var(--gray);\n    flex-grow: 1;\n    padding: 3px;\n    cursor: pointer;\n    margin: 3px;\n}\n.day:hover {\n    background: #f6f7f8;\n}\n.day.active{\n    background: var(--gray);\n    color: #fff;\n}\n.timeCard{\n    width: 30%;\n    text-align: center;\n    color: var(--gray);\n    /* flex-grow: 1; */\n    cursor: pointer;\n    margin: 5px 10px 5px 0;\n    padding: 12px;\n}\n.timeCard:hover{\nbackground: #f6f7f8;\n}\n.timeCard.active{\n    background: var(--gray);\n    color: #fff;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
