import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { ImageRatioList, TextOptions } from "../Utils/dataConstant";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../Utils/errorMsg";
import { uploadAcademicsImg } from "../Redux/Actions/AdminAction";
import InputField from "../Atoms/InputField";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "./addmorebtn";
import TextCKEditor from "./textCKEditor";

export default function AcademicsFunFact(props: any) {
  const dispatch = useDispatch();
  const bottomRef: any = useRef(null);
  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [imageRatio, setImageRatio] = useState<any>("");
  const [resetImageRatioList, setResetImageRatioList] = useState(false);
  const [uploadVideo, setUploadVideo] = useState<any>("");
  const [loadVideo, setLoadVideo] = useState<any>("");

  useEffect(() => {
    props?.getData?.map((value: any) => {
      setClassContentArray(value?.funFact);
    });
    setmenuArray(TextOptions);
  }, []);

  const showInputMenu = (inputType: any, data: any) => {
    const index = classContentArray?.length;
    if (inputType === "text") {
      const inputTypeText = {
        descType: inputType,
        content: "",
        contentType: data,
        contentOrder: index + 1,
      };
      setClassContentArray([...classContentArray, inputTypeText]);
    } else if (inputType === "image") {
      const inputTypeImage = {
        descType: inputType,
        content: "",
        contentType: 5,
        contentOrder: index + 1,
        resolution: imageRatio,
      };
      setClassContentArray([...classContentArray, inputTypeImage]);
    } else if (inputType === "video") {
      const inputTypeVideo = {
        descType: inputType,
        contentType: 6,
        contentOrder: index + 1,
      };
      setClassContentArray([...classContentArray, inputTypeVideo]);
    }
  };

  function clickText() {
    setShow(!show);
  }

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };

  const onChangeClassContentText = (
    index: string | number,
    value: any,
    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(classContentArray));
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  function onchangePoints(
    index: number,
    innerIndex: number,
    val: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(classContentArray));

    if (key) {
      let arr = newArr[index]["content"].split(" || ");
      arr[innerIndex] = val;
      newArr[index]["content"] = arr.join(" || ");
      setClassContentArray(newArr);
    }
  }

  const scrollToBottom = () => {
    if (bottomRef.current) {
      const bottomElement = bottomRef.current.querySelector("#bottomRef");
      if (bottomElement) {
        bottomRef.current.scrollTop = bottomElement.offsetTop;
      }
    }
  };

  const onPressAdd = (index: any) => {
    const newArr = [...classContentArray];
    let arr = newArr[index]["content"].split(" || ");
    arr.push("");
    newArr[index]["content"] = arr.join(" || ");
    setClassContentArray(newArr);

    setTimeout(scrollToBottom, 100);
  };

  const handleClose = (index: any, pointsIndex: number) => {
    const newArr = [...classContentArray];
    if (index > -1) {
      let arr = newArr[index]["content"].split(" || ");
      arr?.splice(pointsIndex, 1);
      newArr[index]["content"] = arr.join(" || ");
    }
    setClassContentArray(newArr);
  };

  const onChangeImageRatio = (value: any, index: any) => {
    onChangeClassContentText(index, value?.name, "resolution");
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "content");
          },
          () => {}
        )
      );
    }
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLoadVideo(reader.result);
    };
    reader.readAsDataURL(file);

    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "content");
            setUploadVideo(res.filePath);
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    setFunFactValue();
  }, [classContentArray]);

  const setFunFactValue = () => {
    let tempClassContentArray = classContentArray?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          content: item.content.replace(/'/g, "&apos;"),
          contentType: item?.contentType,
          contentOrder: item?.contentOrder,
        };
      }
      return item;
    });
    const PostData = {
      funFact: tempClassContentArray,
    };
    props?.setFunFactData(PostData);
    return PostData;
  };

  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, content, resolution, contentType } = item;
      let image: any = {};
      if (descType === "image") {
        const filterimage = ImageRatioList?.filter(
          (imgitem: any) => imgitem?.name === resolution
        );
        if (filterimage) {
          image = filterimage[0];
        }
      }
      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {descType === "text" ? (
              <>
                {contentType === 4 ? (
                  <div className="w-100">
                    <LabelField lableName={contentType === 4 ? "Points" : ""} />
                    <div className="d-flex Justify-content-between border border-black p-2">
                      <div
                        className="col-6"
                        style={{ maxHeight: "250px", overflow: "auto" }}
                        ref={bottomRef}
                      >
                        {content
                          .split(" || ")
                          ?.map((contentData: any, i: any) => {
                            return (
                              <div
                                className="d-flex border rounded p-1 mt-2"
                                id={
                                  i === content?.split(" || ")?.length - 1
                                    ? "bottomRef"
                                    : i
                                }
                              >
                                <span className="px-2">{i + 1} </span>
                                <div className="w-100 d-flex justify-content-between mt-2">
                                  <InputField
                                    placeholder="Enter Points"
                                    value={contentData}
                                    onChangeInput={(value: any) =>
                                      onchangePoints(index, i, value, "content")
                                    }
                                  />
                                </div>
                                <span className="p-2 m-0 d-flex removeButton">
                                  {content.length === 0 ? null : (
                                    <DeleteIcon
                                      className="cursor"
                                      color="error"
                                      fontSize="small"
                                      onClick={() => handleClose(index, i)}
                                    />
                                  )}
                                </span>
                              </div>
                            );
                          })}
                      </div>
                      <div className="col-6">
                        <div className="d-flex justify-content-center">
                          <div className="d-flex justify-content-center mt-3">
                            <Addmorebtn
                              name="Add More"
                              handleClick={() => onPressAdd(index)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="m-2 w-100">
                    <label className="fw-bold mb-2">
                      {contentType === 1
                        ? "Heading"
                        : contentType === 2
                        ? "Sub-Heading"
                        : contentType === 3
                        ? "Description"
                        : ""}
                    </label>
                    <TextCKEditor
                      value={content}
                      onChange={(value: any) =>
                        onChangeClassContentText(index, value, "content")
                      }
                    />
                  </div>
                )}
              </>
            ) : null}

            {descType === "image" ? (
              <div>
                <label className="ms-2 fw-bold mb-2">
                  {contentType === 5 ? "Image" : ""}
                </label>
                <div className="d-flex mt-0 ms-2 border justify-content-center p-2">
                  <div className="d-flex py-2 me-2">
                    <LabelField lableName={"Image Ratio"} />
                    <CustomDropdown
                      lableName="Select Image Ratio"
                      setInputText={(value: any) =>
                        onChangeImageRatio(value, index)
                      }
                      value={resolution}
                      options={ImageRatioList}
                      reset={resetImageRatioList}
                      defaultValue={
                        ImageRatioList?.length > 0 ? image?._id : null
                      }
                    />
                  </div>
                  <div className="border border-3 rounded p-2">
                    <ImagePickerAndViewer
                      lableName={"Image"}
                      value={content}
                      handleChange={(e: any) =>
                        onChangeClassContentImg(e, index)
                      }
                      removelabel={true}
                    />
                  </div>
                </div>
              </div>
            ) : null}
            {descType === "video" ? (
              <div className="mt-0">
                <label className="ms-2 fw-bold mb-2">
                  {contentType === 6 ? "Video" : ""}
                </label>
                <ImagePickerAndViewer
                  lableName={"Video"}
                  value={content}
                  handleChange={(value: any) =>
                    onChangeClassContentVideo(value, index)
                  }
                  doctype={"video"}
                />
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className="w-100">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          <h5 className="fw-bold text-light">Fun Fact</h5>
          <div className=" d-flex">
            <div className="d-flex align-items-center">
              <span
                className="position-relative addInputbtn"
                onClick={clickText}
              >
                <div className="cursor ms-2 p-1">+ Text</div>

                <div className="position-absolute notiDiv">
                  <div
                    className="position-absolute transperentbg"
                    onClick={() => setShow(false)}
                  ></div>
                  <div
                    className="position-absolute border p-1 font12"
                    style={{
                      background: "#fff",
                      top: "2px",
                      right: "29px",
                      width: "160px",
                      boxShadow: "black -2px 2px 10px -2px",
                    }}
                  >
                    <div className="px-2">
                      {menuArray?.map((c: any) => (
                        <div
                          className="p-2 addQuestionType"
                          style={{ cursor: "pointer" }}
                          onClick={(id: any) => showInputMenu("text", c?._id)}
                        >
                          <span className="ms-3 colorgray">{c?.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </span>
            </div>

            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("image", id)}
            >
              + Image
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("video", id)}
            >
              + Video
            </div>
          </div>
        </div>
        {renderClassContent()}
      </div>
    </>
  );
}
