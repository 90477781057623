import React, { useEffect, useState } from "react";

interface props {
  value?: any;
  setInputText?: any;
  Isrequired?: boolean;
}

function timerClock({ value, setInputText, Isrequired }: props) {
  const newValue = value?.split(":");
  /* eslint-disable */
  const [HH, setHH] = useState(newValue[0]);
  const [MM, setMM] = useState(newValue[1]);
  const [SS, setSS] = useState(newValue[2]);

  useEffect(() => {
    const newValue = value?.split(":");
    setHH(newValue[0]);
    setMM(newValue[1]);
    setSS(newValue[2]);
  }, [value]);

  const changeText = (key: any, newValue: any) => {
    if (newValue >= 0) {
      let value = newValue;
      if (key === "HH") {
        if (value) setInputText(`${value}:${MM}:${SS}`);
        else setInputText(`00:${MM}:${SS}`);
      }
      if (key === "MM") {
        if (value) setInputText(`${HH}:${value}:${SS}`);
        else setInputText(`${HH}:00:${SS}`);
      }
      if (key === "SS") {
        if (value) setInputText(`${HH}:${MM}:${value}`);
        else setInputText(`${HH}:${MM}:00`);
      }
    }
  };
  return (
    <div className="d-flex flex-row mb-2 align-items-center">
      <div className="d-flex  align-items-center">
        <label className="me-1">hh</label>
        <input
          className="form-control me-1"
          style={{ width: "62px" }}
          type={"text"}
          step="1"
          value={HH === "00" ? "" : HH}
          onChange={(e) => changeText("HH", e.target.value)}
          defaultValue={HH}
          maxLength={2}
        />
      </div>
      <div className="d-flex  align-items-center">
        <label className="me-1">mm</label>
        <input
          className="form-control me-1"
          style={{ width: "62px" }}
          type={"text"}
          step="1"
          value={MM === "00" ? "" : MM}
          onChange={(e) => changeText("MM", e.target.value)}
          defaultValue={MM}
          maxLength={2}
          minLength={1}
        />
      </div>
      <div className="d-flex  align-items-center">
        <label className="me-1">ss</label>
        <input
          className="form-control"
          style={{ width: "62px" }}
          type={"text"}
          value={SS === "00" ? "" : SS}
          step="1"
          onChange={(e) => changeText("SS", e.target.value)}
          defaultValue={SS}
          maxLength={2}
          minLength={1}
        />
      </div>
      {Isrequired ? <span className="required ms-1 fs-6">*</span> : null}
    </div>
  );
}

export default timerClock;
