import React, { useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  errorMsg?: any;
}

export default function LiveVideoPopup(props: props) {
  const dispatch = useDispatch();
  const { open, handleClose, data, errorMsg } = props;
  useEffect(() => { }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldivChat p-md-4 py-md-4 ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold"></h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCard mt-0">
              <div className="row mt-2">
                <div className="col-md-12">
                  <div className="">
                    <div className="font16 mb-2 fw-bold">{errorMsg} :</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.map((value: any) => {
                        return (
                          <li>
                            {`${value?.scheduleFrom}, ${value?.sessionTime}`}
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
    </>
  );
}
