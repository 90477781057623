export default {
  FinanceSidebarData: [
    {
      title: "FINANCE",
      subNav: [
        {
          title: "CREATE",
          path: "finance",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "finance",
          cName: "sub-nav",
        },
      ],
    },
    // {
    //   title: "Transaction History",
    //   path: "transaction_history",

    // },
    {
      title: "Payment Status",
      path: "payment_status",

    },



  ]
};