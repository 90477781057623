import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import DatePicker from "../../Components/datePicker";
import Time from "../../Components/time";
import Submitbtn from "../../Components/submitButton";
import {
  deleteLivePremier,
  getGeneralPackageList,
  getLivePremierEditData,
  getLivePremierList,
  getPackageSessionList,
  getPremierVideoId,
  getStudentList,
  updateLivePremier,
} from "../../Redux/Actions/SuperAdminAction";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralBbbMeetingList,
  getGeneralExamList,
  getGeneralTeacherList,
  getGeneralTimezoneList,
  getMainCategoryList,
  getTopicList,
} from "../../Redux/Actions/AdminAction";
import moment from "moment";
import { startsWithSpace, validateFullName } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import Addmorebtn from "../../Components/addmorebtn";
import { GradeList } from "../../Utils/dataConstant";
import ScheduleLive from "../../Components/scheduleLive";

export default function Updatelivepremeirvideo() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [lecture, setLecture] = useState("");
  const [loginID, setLoginID] = useState<any>("");
  const [loginIDList, setLoginIDList] = useState<any>([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [fromdate, setFromdate] = useState<string>("");
  const [todate, setTodate] = useState<string>("");
  const [sessionTime, setSessionTime] = useState<string>("");
  const [isSelected, setIsSelected] = useState(false);
  const [batch, setBatch] = useState("");
  const [batchList, setBatchList] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);

  const _DATA = usePagination(String(count), rowsPerPage);

  const [premierId, setPremierId] = useState<any>(1);
  const [premierIdList, setPremierIdList] = useState<any>([]);

  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetloginID, setResetLoginID] = useState<any>(false);
  const [resetteacher, setResetTeacher] = useState<any>(false);

  const [livePremierList, setLivePremierList] = useState<any>([]);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState<any>([]);
  const [selectedStudent, setSelectedStudent] = useState<any>([]);

  const [demoLiveId, setDemoLiveId] = useState<any>("");
  const [livePremierEditDataList, setLivePremierEditDataList] = useState<any>(
    []
  );
  const [isEditMode, setisEditMode] = useState(false);

  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);

  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);

  const [category, setCategory] = useState<any>("");
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [resetMainCategory, setresetMainCategory] = useState(false);

  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState<any>(false);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [topicNameList, setTopicNameList] = useState<any>([]);

  const [timeZoneNew, setTimeZoneNew] = useState<any>("");
  const [timeZoneNewList, setTimeZoneNewList] = useState([]);
  const [resetTimezoneNew, setResetTimezoneNew] = useState<any>(false);

  const [mindate, setmindate] = useState("");
  const initialVal: {
    lectureId: any,
    title: any;
    scheduleFrom: any;
    scheduleTo: any;
    sessionTime: any;
  }[] = [
      {
        lectureId: "",
        title: "",
        scheduleFrom: "",
        scheduleTo: "",
        sessionTime: "",
      },
    ];
  const [titleClassScheduleArr, setTitleClassScheduleArr] = useState<any>([]);
  console.log('timeZoneNew', timeZoneNew);
  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getPremierVideoId(
        {},
        (res: any) => {
          setPremierIdList(res);
          setPremierId(res[0]?._id);
        },
        () => { }
      )
    );
  }, []);

  const onChange = () => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      subjectId: subject,
      premierVideoId: premierId,
      packageIds: premierId === 1 ? selectedBatch : [batch],
      maincategoryId: category._id,
      gradeId: grade?._id,
    };
    if (selectedBatch.length === 0 && batch === "")
      delete postData["packageIds"];
    if (category._id === "") delete postData["maincategoryId"];
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getLivePremierList(
        postData,
        (res: any) => {
          setLivePremierList(res);
        },
        () => { }
      )
    );
  };
  const clearData = () => {
    setResetCourseType(true);
    setResetExam(true);
    setResetSubject(true);
    setBatchList([]);
    setLivePremierEditDataList([]);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const onSelectBatch = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(livePremierEditDataList));
    if (event.target.checked) {
      newArray.packages[index]["isSelected"] = event.target.checked;
      selectedBatch.push(parseInt(event.target.id));
      setSelectedBatch(selectedBatch);
      setLivePremierEditDataList(newArray);
    }
  };

  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value._id);
  };

  const onChangeStudent = () => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      search: student,
      gradeId: grade?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getStudentList(
        postData,
        (res: any) => {
          selectedStudent?.map((item: any, i: any) =>
            res?.map((data: any, index: any) => {
              if (data?._id == item) {
                data["selected"] = true;
              }
            })
          );
          setStudentList(res);
        },
        () => { }
      )
    );
  };

  const onChangeRegisteredStud = (event: any, index: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);

    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList, ""]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setDemoLiveId(value?._id);
    setSelectedStudent([]);
    setSelectedStudentList([]);
    dispatch(
      getLivePremierEditData(
        value?._id,
        (res: any) => {
          setisEditMode(true);
          setLivePremierEditDataList(res);
          setCourseType(res?.data?.coursetypeId);
          setExam(res?.data?.examtypeId);
          setSubject(res?.data?.subjectId);
          setCategory(res?.data?.maincategoryId);
          setLecture(res?.data?.lectureTitle);
          setFromdate(res?.data?.scheduleFrom);
          setTodate(res?.data?.scheduleTo);
          setSession(res?.data?.sessionId?._id);
          setSessionTime(res?.data?.sessionTime);
          setLoginID(res?.data?.bbbMeetingId?._id);
          setTeacher(res?.data?.teacherId?._id);
          setIsSelected(res?.student?.isSelected);
          const filterTimezone = timeZoneNewList?.filter((item: any) => item?.timezoneId === res?.data?.timeZone?._id);
          console.log('filterTimezone', filterTimezone);
          if (filterTimezone) {
            setTimeZoneNew(filterTimezone[0]);
          }
          // setTimeZoneNew(res?.data?.timeZone);
          setStudentList(res?.student);
          setTitleClassScheduleArr(res?.data?.lectureData);
          setGrade(res?.data?.gradeId?._id);

          dispatch(
            getPackageSessionList(
              {
                packageIds: selectedBatch,
              },
              (res: any) => {
                setSessionList(res);
              },
              () => { }
            )
          );

          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudent;
            if (item?.isSelected) {
              newArrr.push(item.studentId);
            }
            setSelectedStudent(newArrr);
          });
          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudentList;
            if (item?.isSelected) {
              newArrr.push(
                `${item.firstName} ${item.lastName} - ${item.mobile}`
              );
            }
            setSelectedStudentList(newArrr);
          });

          const postData = {
            coursetypeId: res?.data?.coursetypeId,
            examtypeId: res?.data?.examtypeId,
            subjectId: res?.data?.subjectId,
            gradeId: res?.data?.gradeId?._id,
          };
          if (
            postData["examtypeId"] === null ||
            postData["examtypeId"] === undefined ||
            postData["examtypeId"] === ""
          ) {
            delete postData["examtypeId"];
          }
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          dispatch(
            getTopicList(
              postData,
              (res: any) => {
                setTopicNameList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const onPressAdd = () => {
    setTitleClassScheduleArr([...titleClassScheduleArr, initialVal[0]]);
  };

  const handleCrossLive = (index: any) => {
    const newArray = [...titleClassScheduleArr];
    if (index > -1) {
      newArray.splice(index, 1);
    }
    setTitleClassScheduleArr(newArray);
  };
  const onChangeDate = (val: any, index: any, key: any) => {
    let newArray = JSON.parse(JSON.stringify(titleClassScheduleArr));
    if (key) {
      if (key === "scheduleFrom") {
        newArray[index]["scheduleTo"] = val;
      }
      newArray[index][key] = val;
      setTitleClassScheduleArr(newArray);
    }
  };
  const handleDelete = (value: any) => {
    dispatch(
      deleteLivePremier(
        value._id,
        () => {
          const postData: any = {
            coursetypeId: courseType?._id,
            examtypeId: exam,
            subjectId: subject,
            premierVideoId: premierId,
            packageIds: premierId === 1 ? selectedBatch : [batch],
            maincategoryId: category._id,
            gradeId: grade?._id,
          };
          if (selectedBatch.length === 0 && batch === "")
            delete postData["packageIds"];
          if (category._id === "") delete postData["maincategoryId"];
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          if (
            postData["examtypeId"] === null ||
            postData["examtypeId"] === undefined ||
            postData["examtypeId"] === ""
          ) {
            delete postData["examtypeId"];
          }
          dispatch(
            getLivePremierList(
              postData,
              (res: any) => {
                setLivePremierList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneNewList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postData = {
      coursetypeId: courseType._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    dispatch(
      getGeneralTeacherList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setTeacherList(res);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralBbbMeetingList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setLoginIDList(res);
        },
        () => { }
      )
    );

    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
          if (premierId === 2) {
            let tempStatus = res?.filter((item: any) => item?._id === 1);
            setMinCategoryList(tempStatus);
          }
        },
        () => { }
      )
    );
  };

  const onChangeMainCategory = (value: {
    _id?: string;
    name?: string;
    sessionIds?: any;
  }) => {
    setCategory(value._id);
    setresetMainCategory(false);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: subject,
          maincategoryId: value._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => { }
      )
    );
  };
  const onChangeLecture = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setLecture(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  const onChangeloginID = (value: any) => {
    setLoginID(value);
  };
  const onChangeteacher = (value: any) => {
    setTeacher(value);
  };

  const onChangeTimeZoneNew = (value: any) => {
    setTimeZoneNew(value);
    setResetTimezoneNew(false);
  };

  const onChangeStudentSearch = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };

  const onChangeBatch = (value: any) => {
    setBatch(value._id);
  };

  const onChangeEnrollStud = (value: any) => { };
  const onSelectGeneralBatch = (event: any, index: any) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
  };
  console.log('livePremierEditDataList', livePremierEditDataList);
  const handleOnEdit = (e: any) => {
    e.preventDefault();
    const postData: any = {
      lectureData: titleClassScheduleArr,
    };

    if (livePremierEditDataList?.data?.coursetypeId != courseType?._id) {
      postData["coursetypeId"] = courseType?._id;
    }
    if (livePremierEditDataList?.data?.examtypeId != exam) {
      postData["examtypeId"] = exam;
    }
    if (livePremierEditDataList?.data?.subjectId != subject) {
      postData["subjectId"] = subject;
    }
    if (livePremierEditDataList?.data?.maincategoryId != category) {
      postData["maincategoryId"] = category;
    }
    if (livePremierEditDataList?.data?.lectureTitle != lecture) {
      postData["lectureTitle"] = lecture;
    }
    if (livePremierEditDataList?.data?.teacherId?._id != teacher._id) {
      postData["teacherId"] = teacher._id;
    }
    if (livePremierEditDataList?.data?.scheduleFrom != fromdate) {
      postData["scheduleFrom"] = fromdate;
    }
    if (livePremierEditDataList?.data?.scheduleTo != todate) {
      postData["scheduleTo"] = todate;
    }
    if (livePremierEditDataList?.data?.sessionTime != sessionTime) {
      postData["sessionTime"] = sessionTime;
    }
    if (livePremierEditDataList?.data?.premierVideoId != premierId) {
      postData["premierVideoId"] = premierId;
    }
    if (livePremierEditDataList?.student != selectedStudent) {
      if (selectedStudent.length > 0) {
        postData["studentArrayID"] = selectedStudent;
      }
    }
    if (livePremierEditDataList?.data?.timezone?._id != timeZoneNew?.timezoneId) {
      postData["timezoneId"] = timeZoneNew?.timezoneId;
    }
    if (livePremierEditDataList?.data?.bbbMeetingId?._id != loginID._id) {
      postData["bbbMeetingId"] = loginID._id;
    }
    if (livePremierEditDataList?.data?.gradeId?._id != grade._id) {
      postData["gradeId"] = grade._id;
    }
    // console.log('postData', postData);
    dispatch(
      updateLivePremier(
        postData,
        demoLiveId,
        (res: any) => {
          setisEditMode(false);
          setSelectedStudentList([]);
          setSelectedStudent([]);
          const postData: any = {
            coursetypeId: courseType?._id,
            examtypeId: exam,
            subjectId: subject,
            premierVideoId: premierId,
            packageIds: premierId === 1 ? selectedBatch : [batch],
            maincategoryId: category._id,
            gradeId: grade?._id,
          };
          if (selectedBatch.length === 0 && batch === "")
            delete postData["packageIds"];
          if (category._id === "") delete postData["maincategoryId"];
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          if (
            postData["examtypeId"] === null ||
            postData["examtypeId"] === undefined ||
            postData["examtypeId"] === ""
          ) {
            delete postData["examtypeId"];
          }
          dispatch(
            getLivePremierList(
              postData,
              (res: any) => {
                setResetCourseType(true);
                setResetExam(true);
                setResetSubject(true);
                setBatchList([]);
                setLivePremierList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={livePremierList ? livePremierList : []}
          pagename={"livevideo"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Lecture Title",
            "Student Name",
            "Subject",
            "Teacher Name",
            "Class Date & Time",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="" onSubmit={handleOnEdit}>
              <div className="d-flex Justify-content-between mb-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Schedule For"} />
                </div>
                <div className="col-10 d-flex">
                  {premierIdList.map((c: any, index: any) => {
                    return (
                      <>
                        {premierId === c?._id ? (
                          <div className="col-3">
                            <span className="me-3 radiobtnspan">
                              <span>
                                <input
                                  type="radio"
                                  name="type"
                                  value={c._id}
                                  checked={premierId === c?._id ? true : false}
                                  onChange={() => setPremierId(c?._id)}
                                />{" "}
                                <span className="ms-2">{c?.name}</span>
                              </span>
                            </span>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>

              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    Isrequired={true}
                    defaultValue={courseType}
                    disabled={isEditMode}
                    reset={resetCourseType}
                  />
                </div>
              </div>
              {courseType?.type === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} />
                  </div>
                  <div className="col-6 ps-2  ">
                    <CustomDropdown
                      lableName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      Isrequired={true}
                      options={GradeList}
                      defaultValue={grade}
                      disabled={isEditMode}
                      reset={resetGradeList}
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      disabled={isEditMode}
                      defaultValue={exam}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    disabled={isEditMode}
                    defaultValue={subject}
                  />
                </div>
              </div>
              {premierId === 1 || premierId === 2 ? (
                <div className="d-flex Justify-content-between mt-2">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Main Category"} />
                  </div>
                  <div className="col-6 ps-2">
                    <CustomDropdown
                      lableName="Select Main Category"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeMainCategory(value)
                      }
                      value={category}
                      options={mainCategoryList}
                      Isrequired={true}
                      defaultValue={mainCategoryList.length > 0 ? category : ""}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              ) : null}
              {premierId === 1 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Batch Name"} />
                    </div>

                    <div className="col-6 border rounded p-3">
                      {livePremierEditDataList?.packages !== undefined
                        ? livePremierEditDataList?.packages.map(
                          (c: any, index: any) => {
                            return (
                              <div className="ms-3">
                                <input
                                  type="checkbox"
                                  id={c._id}
                                  value={c.name}
                                  defaultChecked={c.isSelected}
                                  onChange={(e) => onSelectBatch(e, index)}
                                  disabled={isEditMode}
                                />
                                <span className="ms-2">{c.name}</span>
                              </div>
                            );
                          }
                        )
                        : null}
                    </div>
                  </div>

                  <div className="d-flex Justify-content-between mt-3">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Session"} />
                    </div>

                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Session"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSession(value)
                        }
                        value={session}
                        options={sessionList}
                        Isrequired={true}
                        defaultValue={sessionList.length > 0 ? session : ""}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {premierId === 2 ? (
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Batch Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeBatch(value)
                      }
                      value={batch}
                      options={batchList}
                      Isrequired={true}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              ) : null}

              {premierId === 3 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Lecture Title"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Lecture Title"
                      value={lecture}
                      onChangeInput={(value: any) => onChangeLecture(value)}
                      maxlength={50}
                      minlength={3}
                    />
                  </div>
                </div>
              ) : null}
              {premierId === 2 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Login ID"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Login ID"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeloginID(value)
                      }
                      value={loginID}
                      options={loginIDList}
                      Isrequired={true}
                      reset={resetloginID}
                      defaultValue={loginID}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Teachers Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Teacher Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeteacher(value)
                    }
                    value={teacher}
                    options={teacherList}
                    Isrequired={true}
                    reset={resetteacher}
                    defaultValue={teacher}
                  />
                </div>
              </div>
              {premierId === 3 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Class Schedule"} />
                  </div>
                  <div className="col-6 d-flex ps-2 ">
                    <div className="col-4">
                      <div className="fw-bold">From Date</div>
                      <DatePicker
                        value={moment(fromdate).format("YYYY-MM-DD")}
                        setInputText={(value: string) => setFromdate(value)}
                        mindate={mindate}
                      />
                    </div>
                    <div className="col-4">
                      <div className="fw-bold">To Date</div>
                      <DatePicker
                        value={moment(todate).format("YYYY-MM-DD")}
                        setInputText={(value: string) => setTodate(value)}
                        mindate={mindate}
                      />
                    </div>
                    <div className="col-4">
                      <div className="fw-bold">Session Time</div>
                      <Time
                        value={sessionTime}
                        setInputText={(value: string) => {
                          setSessionTime(value);
                        }}
                      />
                    </div>
                  </div>
                </div>
              ) : null}

              {premierId === 1 || premierId === 2 ? (
                <>
                  <span>
                    {titleClassScheduleArr?.map((item: any, index: number) => (
                      <ScheduleLive
                        courseType={courseType?._id}
                        exam={exam}
                        subject={subject}
                        grade={grade?._id}
                        topicNameList={topicNameList}
                        data={item}
                        index={index}
                        titleClassScheduleArr={titleClassScheduleArr}
                        setTitleClassScheduleArr={setTitleClassScheduleArr}
                      />
                    ))}
                  </span>
                  <div className=" col-8 d-flex justify-content-end mb-3">
                    <Addmorebtn name="Add More" handleClick={onPressAdd} />
                  </div>
                </>
              ) : null}

              {premierId === 2 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Time Zone"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Time Zone"
                        value={timeZoneNew}
                        options={timeZoneNewList}
                        Isrequired={true}
                        reset={resetTimezoneNew}
                        defaultValue={
                          timeZoneNewList?.length > 0 ? timeZoneNew?._id : null
                        }
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTimeZoneNew(value)
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {premierId != 1 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Scheduled For"} />
                    </div>
                    <div className="col-6 p-2 border rounded">
                      <p className="fw-bold ">Registered Student</p>
                      <div className="d-flex">
                        <InputField
                          placeholder="Enter student name"
                          value={student}
                          onChangeInput={(value: any) =>
                            onChangeStudentSearch(value)
                          }
                          maxlength={50}
                          minlength={3}
                        />
                        <button
                          type="button"
                          className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
                          onClick={(e) => onChangeStudent()}
                        >
                          Search
                        </button>
                      </div>

                      {studentList !== undefined && student
                        ? studentList.map((c: any, index: any) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                                onChange={(e) =>
                                  onChangeRegisteredStud(e, index)
                                }
                                defaultChecked={c.selected}
                              />
                              <span className="ms-2">
                                {c.firstName} {c.lastName}
                              </span>
                            </div>
                          );
                        })
                        : null}
                    </div>
                    {selectedStudentList && selectedStudentList?.length > 0 ? (
                      <div className="col-auto p-2 border rounded">
                        {selectedStudentList.map((item: any, index: any) => {
                          return <div>{item}</div>;
                        })}
                      </div>
                    ) : null}
                  </div>
                </>
              ) : null}

              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Save & Update"} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isEditMode === false) {
      setSelectedStudentList([]);
      setSelectedStudent([]);
    }
  }, [isEditMode]);

  return (
    <div className="contentBox">
      <PageTitle
        name={"Edit Live/Premeir Video"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="p-4">
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {premierIdList.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          value={c._id}
                          checked={premierId === c?._id ? true : false}
                          onChange={() => {
                            setPremierId(c?._id);
                            clearData();
                          }}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {premierId === 1 || premierId === 2 || premierId === 3 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Country"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Country"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={country}
                    options={countryList}
                    Isrequired={true}
                    reset={resetCountryList}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    Isrequired={true}
                    reset={resetCourseType}
                  />
                </div>
              </div>
              {courseType?.type === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} />
                  </div>
                  <div className="col-6 ps-2  ">
                    <CustomDropdown
                      lableName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      Isrequired={true}
                      options={GradeList}
                      reset={resetGradeList}
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      reset={resetExam}
                    />
                  </div>
                </div>
              )}
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {premierId === 1 || premierId === 2 ? (
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Main Category"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Main Category"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeMainCategory(value)
                  }
                  value={category}
                  options={mainCategoryList}
                  Isrequired={true}
                  reset={resetMainCategory}
                />
              </div>
            </div>
          ) : null}
          {premierId === 1 ? (
            <>
              {batchList.length > 0 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 border rounded p-3">
                    {batchList !== undefined
                      ? batchList.map((c: any, index: any) => {
                        return (
                          <div className="ms-3">
                            <input
                              type="checkbox"
                              id={c._id}
                              value={c.name}
                              onChange={(e) => onSelectGeneralBatch(e, index)}
                            />
                            <span className="ms-2">{c.name}</span>
                          </div>
                        );
                      })
                      : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            ""
          )}

          {premierId === 2 && category != "" ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>

              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                />
              </div>
            </div>
          ) : null}
          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => onChange()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
