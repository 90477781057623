import { useEffect, useState, useRef, MouseEvent } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import DatePicker from '../../Components/datePicker'
import CustomDropdown from '../../Components/customDropdown';
import PaginationTable from '../../Components/paginationTable';
import { dateFormat, today } from '../../Utils/appConstants';
import { useDispatch } from 'react-redux';
import { getGeneralPackageList } from '../../Redux/Actions/SuperAdminAction';
import { getGeneralCourseList, getGeneralExamList, getTransactions } from '../../Redux/Actions/AdminAction';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ExportButton from '../../Components/exportButton';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import moment from 'moment';
import SearchButton from '../../Components/searchButton';

export default function TransactionHistory() {
    const tableRef = useRef(null);
    const dispatch: Function = useDispatch();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [examName, setExamName] = useState<any>("");
    const [examList, setExamList] = useState([]);
    const [transactionList, setTransactionList] = useState<any>([]);
    const [courseType, setCourseType] = useState<any>("");
    const [courseTypeList, setCourseTypeList] = useState<any[]>([]);
    const [batch, setBatch] = useState<any>("");
    const [batchList, setBatchList] = useState<any[]>([]);
    const [selectedIndex, setSelectedIndex] = useState(0);
    const tabs = ["Success", "Fail"]
    useEffect(() => {
        dispatch(
            getGeneralCourseList(
                {},
                (res: any) => {
                    setCourseTypeList(res);
                },
                () => { }
            )
        );

        // dispatch(
        //     getExamList(
        //         "",
        //         (res: any) => {
        //             setExamList(res)
        //         },
        //         () => { }
        //     )
        // );
    }, [])

    const calculateSum = () => {
        let sum = 0
        for (let i = 0; i < transactionList.length; i++) {
            sum += parseInt(transactionList[i]?.amount)
        }
        return sum
    }

    const getTransactionData = (e: { preventDefault: () => void; }, status: number) => {
        setSelectedIndex(status)
        setTransactionList([])
        const postdata: any = {
            examTypeId: examName?._id,
            courseTypeId: courseType?._id,
            packageId: batch?._id,
            status: status == 1 ? 200 : 400,
            startDate: startDate,
            endDate: endDate,
        }

        dispatch(
            getTransactions(
                postdata,
                (res: any) => {
                    setTransactionList(res)
                },
                () => { }
            )
        );
    }

    const onChangeExam = (value: { _id: any; name?: string; }) => {
        setExamName(value);
        dispatch(
            getGeneralPackageList(
                {
                    examtypeId: value._id,
                },
                (res: any) => {
                    setBatchList(res);
                },
                () => { }
            )
        );
    };

    const onChangeBatchName = (value: { _id: any; name?: string; }) => {
        setBatch(value);
    };



    const handleEdit = (value: any) => {

    }

    const handleDelete = (value: any) => {

    }

    const onChangeStartDate = (value: any) => {
        if (moment(value).format(dateFormat.yyyymmddA) > moment(endDate).format(dateFormat.yyyymmddA)) {
            dispatch(showErrorSnackbar("Start date should be lass than End date"));
        } else {
            setStartDate(value);
        }
    };

    const onChangeEndDate = (value: any) => {
        if (moment(value).format(dateFormat.yyyymmddA) < moment(startDate).format(dateFormat.yyyymmddA)) {
            dispatch(showErrorSnackbar("End date should be greater than Start date"));
        } else {
            setEndDate(value);
        }
    };

    const renderListMode = () => {
        return (
            <div ref={tableRef}>
                <PaginationTable
                    tableData={transactionList ? transactionList : []}
                    pagename={"transaction"}
                    rowsPerPage={0}
                    page={0}
                    tableHead={[
                        "S.N",
                        "ORDER NO",
                        "ORDER DATE",
                        "BUYER NAME",
                        "MOBILE NO",
                        "AMOUNT",
                        "BATCH NAME",
                        "COURSE DURATION",
                        "PAYMENT STATUS",
                        "STATUS",
                    ]}
                    edit={true}
                    handleEdit={(value: any) => handleEdit(value)}
                    handleDelete={(value: any) => handleDelete(value)}
                />
            </div>
        );
    };

    const handleSubmit = (e: { preventDefault: () => void; }) => {
        e.preventDefault();
        setTransactionList([])
        getTransactionData(e, 1)
    }

    const onChangeCourseType = (value: { _id: any; name?: string }) => {
        setCourseType(value);
        dispatch(
            getGeneralExamList(
                {
                    courseId: value._id
                },
                (res: any) => {
                    setExamList(res);
                },
                () => { }
            )
        );
    };

    return (
        <div className="contentBox">
            <PageTitle name={"Transaction History"} />
            <form className='text-center' onSubmit={handleSubmit}>
                <div className='px-2 mx-0 mt-4 row d-flex Justify-content-between'>
                    <div className='col-md-3 d-flex mb-2 align-items-center'>
                        <LabelField lableName={"Start Date:"} />
                        <DatePicker
                            value={startDate}
                            setInputText={(value: string) => onChangeStartDate(value)}
                            Isrequired
                            maxdate={today}
                        />
                    </div>
                    <div className=' col-md-3 d-flex mb-2 align-items-center'>
                        <LabelField lableName={"End Date:"} />
                        <DatePicker
                            value={endDate}
                            setInputText={(value: string) => onChangeEndDate(value)}
                            Isrequired
                            maxdate={today} />
                    </div>
                    <div className="col-6 d-flex Justify-content-between ">
                        <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Course Type"} />
                        </div>
                        <div className="col-8 ps-2">
                            <CustomDropdown
                                lableName="Select Course Type Name"
                                setInputText={(value: any) =>
                                    onChangeCourseType(value)
                                }
                                value={courseType}
                                options={courseTypeList}
                                Isrequired={true}
                            />
                        </div>
                    </div>
                    <div className="col-6 d-flex Justify-content-between ">
                        <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Exam Name "} />
                        </div>
                        <div className="col-8 ps-2">
                            <CustomDropdown
                                lableName="Select Exam Name"
                                value={examName}
                                options={examList}
                                Isrequired={true}
                                setInputText={(value: { _id: string; name: string }) =>
                                    onChangeExam(value)
                                }
                            />
                        </div>
                    </div>

                    <div className="col-6 d-flex Justify-content-between ">
                        <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Batch Name "} />
                        </div>
                        <div className="col-8 ps-2">
                            <CustomDropdown
                                lableName="Select Batch Name"
                                value={batch}
                                options={batchList}
                                Isrequired={true}
                                setInputText={(value: { _id: string; name: string }) =>
                                    onChangeBatchName(value)
                                }
                            />
                        </div>
                    </div>
                    <div className='col-md-3 d-flex justify-content-end'>
                        <SearchButton name='Search' />
                    </div>
                </div>
            </form>
            <div className='pe-5 d-flex justify-content-between mx-2' >
                <div>
                    {tabs?.map((data: string, index: number) => {
                        return (
                            <button
                                className={`add_Question-btn btn btn btn-sm me-4 ${selectedIndex === index + 1 ? "btn-success" : "btn-secondary"
                                    }`}
                                onClick={(e) => getTransactionData(e, index + 1)}
                            >
                                {data}
                            </button>
                        );
                    })}
                </div>
                <div className='d-flex'>
                    <div className='me-2'>
                        Total : {calculateSum()}
                    </div>
                    <DownloadTableExcel
                        filename="users table"
                        sheet="users"
                        currentTableRef={tableRef.current}
                    >
                        <ExportButton name={"Export Details"} />
                    </DownloadTableExcel>
                </div>
            </div>
            {renderListMode()}
        </div>
    )
}

