import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import Addmorebtn from "../../Components/addmorebtn";
import CustomDropdown from "../../Components/customDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  addCourseDescription,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getMainCategoryList,
} from "../../Redux/Actions/AdminAction";
import {
  length_FiveHundred,
  length_Ten,
  length_Five,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import { GradeList } from "../../Utils/dataConstant";

export default function CreateCourseDescription() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [mainCategory, setMainCategory] = useState<any>("");
  const [courseTitle, setCourseTitle] = useState<any>("");
  const initialVal = [""];
  const [descriptionArr, setDescriptionArr] = useState(initialVal);
  const [resetExam, setresetExam] = useState(false);
  const [resetMainCategory, setresetMainCategory] = useState(false);
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onPressAdd = () => {
    setDescriptionArr([...descriptionArr, ""]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...descriptionArr];
    newArrr.splice(index, 1);
    setDescriptionArr(newArrr);
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value?._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };
  const onChangeCategory = (value: any) => {
    setMainCategory(value?._id);
    setresetMainCategory(false);
  };

  function onChangeDesc(val: any, index: any) {
    let newArrr = JSON.parse(JSON.stringify(descriptionArr));
    newArrr[index] = val;
    setDescriptionArr(newArrr);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData = {
      coursetypeId: course?._id,
      examTypeId: exam,
      subjectId: subject,
      gradeId: grade?._id,
      maincategoryId: mainCategory,
      header: courseTitle,
      courseDescriptions: descriptionArr,
    };
    if (
      PostData["examTypeId"] === null ||
      PostData["examTypeId"] === undefined ||
      PostData["examTypeId"] === ""
    ) {
      delete PostData["examTypeId"];
    }
    if (
      PostData["gradeId"] === null ||
      PostData["gradeId"] === undefined ||
      PostData["gradeId"] === ""
    ) {
      delete PostData["gradeId"];
    }
    dispatch(
      addCourseDescription(
        PostData,
        (res: any) => {
          setresetExam(true);
          setExam("");
          setMainCategory("");
          setCourseTitle("");
          setresetExam(true);
          setresetMainCategory(true);
          setDescriptionArr(initialVal);
          setResetParentCourse(true);
          setResetGradeList(true);
          setResetSubject(true);
          setResetCountryList(true);
        },
        () => { }
      )
    );
  };

  const onChangeCourseTitle = (value: any) => {
    setCourseTitle(value);
  };

  const validateDescription = () => {
    let isDescBlank: boolean = false;
    descriptionArr?.map((item: any) => {
      if (
        item === "" ||
        item === null ||
        item === undefined ||
        item.length < length_Ten - 1
      ) {
        isDescBlank = true;
      }
    });
    return isDescBlank;
  };

  const validation = () => {
    return course === "" ||
      subject === "" ||
      mainCategory === "" ||
      courseTitle === "" ||
      validateDescription()

  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE COURSE DESCRIPTION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  // Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.type === 2 || course?.type === 3 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Exam Name "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Main Category"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Main Category"
                  setInputText={(value: any) => onChangeCategory(value)}
                  value={mainCategory}
                  options={mainCategoryList}
                  Isrequired={true}
                  x="MainCatagory"
                  reset={resetMainCategory}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Title"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Course Title"
                  value={courseTitle}
                  onChangeInput={(value: string) => onChangeCourseTitle(value)}
                  minlength={length_Five}
                  maxlength={length_TwoHundredFifty - 1}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Description "} />
              </div>
              <div className="col-6 ps-2">
                {descriptionArr.map((item, index) => {
                  return (
                    <div className="d-flex jusftify-content-between mt-2">
                      <textarea
                        className="w-100"
                        name="postContent"
                        rows={4}
                        cols={40}
                        value={item}
                        onChange={(e: any) => {
                          onChangeDesc(e.target.value, index);
                        }}
                        maxLength={length_FiveHundred}
                        minLength={length_Ten}
                      />
                      {descriptionArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="col-10 d-flex justify-content-end">
              <Addmorebtn name="Add More" handleClick={onPressAdd} />
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
