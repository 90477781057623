import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  filterRoles,
  getDepartment,
} from "../../Redux/Actions/SuperAdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  hasWhiteSpace,
  length_Five,
  length_Four,
  length_Hundred,
  length_Six,
  length_Ten,
  length_Thirty,
  length_Three,
  startsWithSpace,
  validateFullName,
  validateIsLettersAndDigitsOnly,
  validateIsNumberOnly,
  validatePassword,
  validateUniqueId,
} from "../../Utils/validators";
import DatePicker from "../../Components/datePicker";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  deleteDsaUser,
  getCountryList,
  getDsaManagerList,
  getDsaPanelUsers,
  updateDsaUser,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import { CouponStatusList } from "../../Utils/dataConstant";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";

export default function UpdateSalesPerson() {
  const dispatch: Function = useDispatch();
  const [department, setDepartment] = useState<string>("");
  const [departmentList, setDepartmentList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  // const [employeeId, setEmployeeId] = useState<string>("");
  const _DATA = usePagination(String(count), rowsPerPage);
  const [users, setUsers] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentedit, setDepartmentedit] = useState<string>("");
  const [roleedit, setRoleedit] = useState("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userStatus, setuserStatus] = useState(false);
  const [userID, setuserID] = useState<number>();
  const [changePassword, setChangePassword] = useState(false);
  // const [uniqueID, setUniqueId] = useState<any>("");
  const [dsaUserStatus, setDsaUserStatus] = useState<any>("");
  const [resetDsaUserStatusList, setResetDsaUserStatusList] = useState(false);
  const [toggle, setToggle] = useState<any>(false);

  const [dsaManager, setDsaManager] = useState<any>("");
  const [dsaManagerList, setDsaManagerList] = useState<any>([]);
  const [resetDsaManager, setResetDsaManager] = useState<any>(false);

  const initialVal: {
    countryId: any;
    charges: any;
  }[] = [
    {
      countryId: "",
      charges: "",
    },
  ];
  const [priceArr, setPriceArr] = useState(initialVal);
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 16);
          setDepartmentList(tempStatus);
        },
        () => {}
      )
    );

    const postData = {
      companyId: userId,
    };
    dispatch(
      getDsaManagerList(
        postData,
        (res: any) => {
          setDsaManagerList(res?.data);
        },
        () => {}
      )
    );

    // const postData: any = {
    //   skip: 0,
    //   limit: 10,
    //   managerId: userId,
    //   departmentId: 16,
    //   userRoleId: 17,
    // };

    // if (employeeId) {
    //   postData["empCreatedId"] = employeeId;
    // }
    // if (dsaUserStatus) {
    //   postData["status"] = dsaUserStatus?._id;
    // }

    // dispatch(
    //   getDsaPanelUsers(
    //     postData,
    //     (res: any) => {
    //       setUsers(res?.data);
    //       setCount(res?.count);
    //     },
    //     () => {}
    //   )
    // );
  }, []);

  const onChangeDepartment = (value: { _id: any }) => {
    setDepartment(value._id);
    dispatch(
      filterRoles(
        value._id,
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 17);
          setRolesList(tempStatus);
        },
        () => {}
      )
    );
  };

  const onChangeRoles = (value: { _id: any; name?: string }) => {
    setRole(value._id);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        managerId: dsaManager?._id,
        departmentId: 16,
        userRoleId: 17,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        managerId: dsaManager?._id,
        departmentId: 16,
        userRoleId: 17,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        managerId: dsaManager?._id,
        departmentId: 16,
        userRoleId: 17,
      };
    }

    // if (employeeId) {
    //   postData["empCreatedId"] = employeeId;
    // }
    if (dsaUserStatus) {
      data["status"] = dsaUserStatus?._id;
    }

    dispatch(
      getDsaPanelUsers(
        data,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setEmployeeName(value?.name);
    // setEmployeeId(value?.empCreatedId);
    setDepartmentedit(value?.departmentId?._id);
    setRoleedit(value?.userRoleId?._id);
    setMobileNo(value?.mobile);
    setEmail(value?.email);
    setDateOfJoining(value?.doj);
    setUserName(value?.username);
    setuserStatus(value?.isActive);
    setuserID(value?.empId);
    // setUniqueId(value?.uniqueId);
    setToggle(value?.isActive);
    setisEditMode(true);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
      const postData: any = {
        isActive: true,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    } else {
      setToggle(false);
      const postData: any = {
        isActive: false,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    }
  };

  const handleDeactivate = (value: any) => {
    const postData: any = {
      isActive: false,
    };

    dispatch(
      deleteDsaUser(
        postData,
        value?.empId,
        (res: any) => {
          const postData: any = {
            skip: 0,
            limit: 10,
            managerId: dsaManager?._id,
            departmentId: 16,
            userRoleId: 17,
          };
          // if (employeeId) {
          //   postData["empCreatedId"] = employeeId;
          // }
          if (dsaUserStatus) {
            postData["status"] = dsaUserStatus?._id;
          }

          dispatch(
            getDsaPanelUsers(
              postData,
              (res: any) => {
                setUsers(res?.data);
                setCount(res?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangedsaManager = (value: any) => {
    setDsaManager(value);
    setResetDsaManager(false);
  };

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  const onChangeDsaUserStatus = (value: any) => {
    setResetDsaUserStatusList(false);
    setDsaUserStatus(value);
  };
  // const onChangeEmpId = (value: any) => {
  //   if (validateIsLettersAndDigitsOnly(value)) {
  //     setEmployeeId(value);
  //   } else {
  //     dispatch(showErrorSnackbar("Please only Character & Number"));
  //     return;
  //   }
  // };

  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  // const onchangeUniqueId = (val: string) => {
  //   if (validateUniqueId(val) && !startsWithSpace(val)) {
  //     setUniqueId(val?.toUpperCase());
  //   } else {
  //     dispatch(
  //       showErrorSnackbar("Please Character only & space is not allowed") as any
  //     );
  //     return;
  //   }
  // };

  const renderListMode = () => {
    return (
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-md-12 px-4 mx-0 mt-4 d-flex ">
          <div className="col-md-3 d-flex n">
            <LabelField lableName={"Manager"} />
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Manager"
                setInputText={(value: any) => onChangedsaManager(value)}
                value={dsaManager}
                options={dsaManagerList}
                reset={resetDsaManager}
              />
            </div>
          </div>
          <div className="col-md-3 d-flex n">
            <LabelField lableName={"Status"} />
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Status"
                setInputText={(value: any) => onChangeDsaUserStatus(value)}
                value={dsaUserStatus}
                options={CouponStatusList}
                reset={resetDsaUserStatusList}
              />
            </div>
          </div>
          {/* <div className="col-md-3 d-flex n">
            <LabelField lableName={"Employee ID"} />
            <div className="col-6 ps-2 ">
              <InputField
                placeholder="Enter Employee ID"
                value={employeeId}
                onChangeInput={(value: any) => onChangeEmpId(value)}
                maxlength={length_Six}
              />
            </div>
          </div> */}
          <div className="col-md-4 d-flex Justify-content-between">
            <SubmitButton name="Submit" />
          </div>
        </div>

        {users.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={users ? users : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={users ? users : []}
          pagename={"DsaUserdata"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Employee Name",
            // "Employee ID",
            "Department",
            "Status",
            "Panel Role",
            "Mobile Number",
            "Username",
            "Date Of Joining",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDeactivate={(value: any) => handleDeactivate(value)}
        />
      </form>
    );
  };

  const handleChangePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setChangePassword(!changePassword);
  };

  const onChangePassword = (value: SetStateAction<string>) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const handleClose = () => {
    setChangePassword(false);
  };

  const renderEditMode = () => {
    return (
      <div className="col-7 px-5">
        <form onSubmit={handleupdate}>
          <div className="d-flex mb-2 flex-column p-3">
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Manager"} />
              </div>
              <div className="col-6">
                <CustomDropdown
                  lableName="Select Manager"
                  setInputText={(value: any) => onChangedsaManager(value)}
                  value={dsaManager}
                  options={dsaManagerList}
                  Isrequired={true}
                  reset={resetDsaManager}
                  defaultValue={dsaManager?._id}
                  disabled={true}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee Name"
                  value={employeeName}
                  onChangeInput={(value: any) => onChangeEmpName(value)}
                  maxlength={length_Hundred}
                  minlength={length_Three}
                  isRequired
                />
              </div>
            </div>
            {/* <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee ID"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee ID"
                  value={employeeId}
                  onChangeInput={(value: any) => onChangeEmpId(value)}
                  maxlength={length_Six}
                  isRequired
                />
              </div>
            </div> */}

            <div className="d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChangeInput={(value: any) => onChangeMobileNO(value)}
                  maxlength={length_Ten}
                  minlength={length_Six}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Email Address"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Email Address"
                  value={email}
                  onChangeInput={(value: any) => onChangeEmail(value)}
                  maxlength={length_Hundred}
                  isRequired
                />
              </div>
            </div>
            {/* <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Unique Id"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Unique Id"
                  value={uniqueID}
                  onChangeInput={(value: any) => onchangeUniqueId(value)}
                  maxlength={length_Four - 1}
                  minlength={length_Three - 1}
                  isRequired={true}
                />
              </div>
            </div> */}
            <div className=" d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Date Of Joining"} />
              </div>
              <div className="col-6">
                <DatePicker
                  value={dateOfJoining}
                  setInputText={(value: string) => setDateOfJoining(value)}
                  Isrequired
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Username"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Username"
                  value={userName}
                  onChangeInput={(value: any) => onChangeUserName(value)}
                  maxlength={length_Thirty}
                  minlength={length_Five}
                  isRequired
                />
              </div>
            </div>
            {changePassword ? (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Password"} />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder="Enter Password"
                    value={password}
                    onChangeInput={(value: any) => onChangePassword(value)}
                    maxlength={30}
                    minlength={5}
                  />
                </div>
                <div className="pt-3">
                  <CancelOutlinedIcon onClick={() => handleClose()} />
                </div>
              </div>
            ) : (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex"></div>
                <div className=" ps-2 ">
                  <button
                    type="submit"
                    className={"btn btn-primary rounded-pill me-4"}
                    onClick={(e) => handleChangePassword(e)}
                  >
                    Change Password{" "}
                  </button>
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center mt-2">
              <LabelField lableName={"Change Status"} />
              <ToggleSwitchbtn
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
                isActive={toggle}
              />
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <SubmitButton name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      skip: 0,
      limit: 10,
      managerId: dsaManager?._id,
      departmentId: 16,
      userRoleId: 17,
    };

    // if (employeeId) {
    //   postData["empCreatedId"] = employeeId;
    // }
    if (dsaUserStatus) {
      postData["status"] = dsaUserStatus?._id;
    }

    dispatch(
      getDsaPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  var emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }

  const validation = () => {
    return (
      employeeName === "" ||
      employeeName.length < length_Three - 1 ||
      // employeeId === "" ||
      // employeeId.length < length_Three - 1 ||
      departmentedit === "" ||
      roleedit === "" ||
      userName === "" ||
      userName.length < length_Five - 1 ||
      mobileNo === "" ||
      mobileNo?.length < length_Ten - 1 ||
      dateOfJoining === "" ||
      !allemail(email)
    );
  };

  const handleupdate = (e: any) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData: any = {
        name: employeeName,
        // empCreatedId: employeeId,
        departmentId: 16,
        userRoleId: 17,
        username: userName,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        // uniqueId: uniqueID,
        isActive: toggle,
      };
      if (password.length > 0) {
        postData["password"] = password;
      }
      dispatch(
        updateDsaUser(
          postData,
          userID,
          (res: any) => {
            setisEditMode(false);
            const postData: any = {
              skip: 0,
              limit: 10,
              managerId: dsaManager?._id,
              departmentId: 16,
              userRoleId: 17,
            };

            // if (employeeId) {
            //   postData["empCreatedId"] = employeeId;
            // }
            if (dsaUserStatus) {
              postData["status"] = dsaUserStatus?._id;
            }
            dispatch(
              getDsaPanelUsers(
                postData,
                (res: any) => {
                  setUsers(res?.data);
                  setCount(res?.count);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT DEMO SCHEDULING AGENT"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
