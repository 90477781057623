import { useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import { validateFullName, validateIsFileImageType, startsWithSpace, length_Two, length_Hundred, image_size_2MB } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { useDispatch } from 'react-redux';
import { addSubcategory, uploadSubcategoryLogo } from '../../Redux/Actions/AdminAction';
import Checkbox from '../../Components/checkbox';

export default function CreateSubCategory() {
  const dispatch: Function = useDispatch();

  const [subCategory, setSubCategory] = useState<string>("");
  const [subCategoryLogo, setSubCategoryLogo] = useState<any>("");
  const [uploadSubCategoryIcon, setUploadSubCategorylogo] = useState<any>("");
  const [isTaxable, setIsTaxable] = useState(false);
  const [time, setTime] = useState<any>("");
  const [classType, setClassType] = useState<any>("");
  const [type, setType] = useState<any>(1);

  const handleOptionImageChange = (e: { preventDefault: () => void; target: { files: any[]; }; }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_2MB) {
      dispatch(showErrorSnackbar("File size should be less than 2 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setSubCategoryLogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadSubcategoryLogo(file, (res: any) => {
        delete res?.filePath;
        setUploadSubCategorylogo(res);
      }, () => { }));

    }
  }
  const onChangeSubCategory = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setSubCategory(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }

  const onChangeTax = (value: any) => {
    if (value.target.checked) {
      setIsTaxable(true);
    } else {
      setIsTaxable(false);
    }
  };

  const onChangeTime = (value: any) => {
    if (value === 1) {
      setTime(value);
    } else if (value === 2) {
      setTime(value);
      setClassType("");
    }
  };

  const onChangeClassType = (value: any) => {
    setClassType(value);
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const postData = {
      subcategoryName: subCategory,
      istax: isTaxable,
      subcategoryIcon: uploadSubCategoryIcon?.file,
      subcategoryOriginalIcon: uploadSubCategoryIcon?.originalName,
      timeType: time,
      classType: classType,
    }
    if (time === "") {
      delete postData["timeType"];
    }
    if (classType === "") {
      delete postData["classType"];
    }

    dispatch(
      addSubcategory(
        postData,
        (res: any) => {
          setSubCategory("");
          setSubCategoryLogo("");
          setIsTaxable(false);
          setTime("");
          setClassType("");
        },
        () => { }
      )
    );
  }

  function validation() {
    let isValidate = false;
    isValidate =
      subCategory === "" || subCategory.length < length_Two + 1 || uploadSubCategoryIcon === "" || time === "" || time == 1
      && classType === ""

    return isValidate;
  }

  return (
    <div className="contentBox">
      <PageTitle name={"SUB CATEGORY CREATION"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='' onSubmit={handleSubmit}>

          <div className="col-7">
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Sub Category For"} />
              </div>
              <div className="col-8 text-primary fw-bold ps-2 ">
                For Online Course
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Sub Category"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Sub Category Name"
                  value={subCategory}
                  onChangeInput={(value: any) => onChangeSubCategory(value)}
                  maxlength={length_Hundred - 1}
                  minlength={length_Two}
                  isRequired={true}
                />

              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Sub Category Icon1"} />
              </div>
              <div className="col-8 ps-2">
                <ImagePickerAndViewer
                  lableName={"Option Image"}
                  value={subCategoryLogo}
                  handleChange={(e: any) => handleOptionImageChange(e)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Tax Flag"} />
              </div>

              <div className="col-8 d-flex ps-2">
                <Checkbox
                  name="Tax"
                  onChange={(value: any) => onChangeTax(value)}
                  checked={isTaxable}
                />
                <span className="ms-2">Taxable Goods</span>

              </div>
            </div>

            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Time"} />
              </div>
              <div className='ps-2'>
                <span className="me-3 radiobtnspan">
                  <span>
                    <input
                      type="radio"
                      value={1}
                      name={"time"}
                      checked={time === 1}
                      onChange={() => onChangeTime(1)}
                    />{" "}
                    Start Date
                  </span>
                </span>
                <span className="me-3 radiobtnspan">
                  <input
                    type="radio"
                    value={2}
                    name={"time"}
                    checked={time === 2}
                    onChange={() => onChangeTime(2)}
                  />{" "}
                  Video Duration
                </span>
              </div>
            </div>

            {time === 1 ? (
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Class Type Flag"} />
                </div>
                <div className='ps-2'>
                  <span className="me-3 radiobtnspan">
                    <span>
                      <input
                        type="radio"
                        value={1}
                        name={"classType" + "1"}
                        checked={classType === 1}
                        onChange={() => onChangeClassType(1)}
                      />{" "}
                      Live
                    </span>
                  </span>
                  <span className="me-3 radiobtnspan">
                    <input
                      type="radio"
                      value={2}
                      name={"classType" + "2"}
                      checked={classType === 2}
                      onChange={() => onChangeClassType(2)}
                    />{" "}
                    Premier
                  </span>
                </div>
              </div>
            ) : null}

          </div>


          <div className='col-7 d-flex justify-content-center mt-3'>
            <Submitbtn name={"Submit"}
              validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  )
}

