import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import TextEditor from '../../Components/TextEditor';
import DeleteIcon from '@mui/icons-material/Delete';
import Addmorebtn from '../../Components/addmorebtn';
import Submitbtn from "../../Components/submitButton";
import { getGeneralCourseList, getSubjectList, getGeneralExamList, addAboutExam, getGeneralSubjectList } from '../../Redux/Actions/AdminAction';

export default function CreateAboutExam() {
  const dispatch = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState();
  const [resetExam, setResetExam] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [question, setQuestion] = useState<any>("");
  const initialVal = [{
    question: "",
    answer: ""
  }];
  const [faqArr, setFaqArr] = useState(initialVal);
  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res)
        },
        () => { }
      )
    );

  
  }, []);
  const onChangeCourse = (value: any) => {
    setCourse(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id
          
        },
        (res: any) => {
          
          setExamList(res);
         
        },
        () => { }
      )
    );
  };
  const onChangeExam = (value: any) => {
    setExam(value)
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: exam._id,
        },
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: any) => {
    setSubject(value)

  };

  const onPressAdd = () => {
    setFaqArr([...faqArr, initialVal[0]]);
  };
  const handleClose = (index: any) => {
    const newArrr = [...faqArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }

    setFaqArr(newArrr);
  };

  function onChangeFaq(value: any, index: any, key: any) {
    let newArrr = JSON.parse(JSON.stringify(faqArr));
    newArrr[index][key] = value;
    setFaqArr(newArrr);
  }
   
  const handleSubmit = (e: any) => {
    e.preventDefault()
    const PostData = {
      coursetypeId: course,
      examtypeId: exam._id,
      subjectId: subject._id,
      faqs: faqArr,
    }

    dispatch(
      addAboutExam(
        PostData,
        (res: any) => {
       
          setResetParentCourse(true);
          setResetExam(true);
          setResetSubject(true);

        },
        () => { }
      )
    );
  }
  const validation = () => {
    let isfaqArr: any = false;
    faqArr?.map((item) => {
      if (item.question === "" || item.question === null || item.question === undefined ) {
        isfaqArr = true;
      }
    });
    return (
      course === "" || exam === "" || subject === "" || isfaqArr === true
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"ALL ABOUT EXAM"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='col-8 pt-2' onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) =>
                  onChangeCourse(value)
                }
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Name"
                setInputText={(value: any) =>
                  onChangeExam(value)
                }
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Subject"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Subject"
                setInputText={(value: any) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          {faqArr.map((item, index) => {
            return (
              <div className="d-flex Justify-content-between  ">
                <div className="col-3  align-item-center d-flex">
                  <LabelField lableName={"Faq's " + (index + 1)} />
                </div>
                <div className='col-10 '>
                  <div className='d-flex mb-3'>
                  
                    <div className='border rounded p-1'> 
                    <p className='fw-bold ps-2 pt-2 mb-0'>{"Question " + (index + 1)}</p>
                    <InputField
                      placeholder="Enter Question"
                      value={item.question}
                      onChangeInput={(value: any) => { onChangeFaq(value, index, "question") }}
                      maxlength={100}
                      minlength={2}
                    />
                      <p className='fw-bold ps-2 mb-0'>Answer</p>
                      <TextEditor
                        value={item.answer}
                        onChange={(value: any) => { onChangeFaq(value, index, "answer") }}
                        height="vh100"
                        width='vw100'
                      /></div>
                    {faqArr.length === 1 ? null : (<DeleteIcon className="cursor" color="error" fontSize='small' onClick={() => handleClose(index)} />)}
                  </div> </div>
              </div>)
          })}
          <div className='d-flex justify-content-end'>
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <Submitbtn
              name={"Submit"}
              validation= {validation()} 
            />
          </div>
        </form>
      </div>
    </div>
  )
}

