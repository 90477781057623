import { Route, Routes } from "react-router-dom";
import Sidebar from '../../Components/sidebar';
import SuperAdminSidebarData from './superAdminSidebarData';
import RegisteredUsers from './registeredUsers';
import CreateUser from './createUser';
import UpdateUser from './updateUser';
import CreateTeacher from './createTeacher';
import Updateteacher from './updateteacher';
import EnrolledStudent from "./enrolledStudent";
import WIP from '../wip';
import CreateDsaUser from "./createDsaUser";
import UpdateDsaUser from "./updateDsaUser";
import CreateAffiliateUser from "./createAffiliateUser";
import UpdateAffiliateUser from "./updateAffiliateUser";
export default function superAdminPanelDashboard() {
  return (
    <div className='AppBox'>
      <Sidebar data={SuperAdminSidebarData?.SuperAdminSidebarData} />

      <div className='dashboard'>
        <Routes>
          <Route path="/" element={<CreateUser />} />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="updateUser" element={<UpdateUser />} />
          <Route path="registeredUsers" element={<RegisteredUsers />} />
          <Route path="createTeacher" element={<CreateTeacher />} />
          <Route path="updateteacher" element={<Updateteacher />} />
          <Route path="enrolledStudent" element={<EnrolledStudent />} />
          <Route path="createDsaUser" element={<CreateDsaUser />} />
          <Route path="updateDsaUser" element={<UpdateDsaUser />} />
          <Route path="createAffiliateUser" element={<CreateAffiliateUser />} />
          <Route path="updateAffiliateUser" element={<UpdateAffiliateUser />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  )
}
