import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  deleteLectures,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getLectureList,
  getSubTopicList,
  getTopicList,
  updateLecture,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { getGeneralTopicList } from "../../Redux/Actions/SuperAdminAction";
import { length_Hundred, length_Three } from "../../Utils/validators";
import { GradeList, levelList } from "../../Utils/dataConstant";

export default function UpdateLecture() {
  const dispatch: Function = useDispatch();
  const [isEditMode, setisEditMode] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [courseType, setCourseType] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [level, setLevel] = useState<any>("");
  const [topic, setTopic] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [priorityOrder, setPriorityOrder] = useState<any>("");
  const [subTopic, setsubTopic] = useState<any>("");
  const [lecture, setLecture] = useState<any>("");
  const [lectureId, setLectureId] = useState("");

  const [countryList, setCountryList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);
  const [subTopicList, setSubTopicList] = useState([]);
  const [lectureList, setLectureList] = useState([]);

  const [resetCountry, setResetCountry] = useState(false);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetGrade, setResetGrade] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetLevel, setResetLevel] = useState(false);
  const [resetTopic, setResetTopic] = useState(false);
  const [resetSubTopic, setResetSubTopic] = useState(false);
  const [extraLect, setCxtraLect] = useState(false);


  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralTopicList(
        "",
        (res: any) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountry(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    const data = {
      coursetypeId: value?._id,
    };
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value?._id);
    setResetGrade(false);
    const data = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: value?._id,
    };
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );

    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    getData(value._id, level, topic, subTopic);
  };

  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setLevel(value._id);
    setResetLevel(false);
    getData(subject, value._id, topic, subTopic);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade,
      examtypeId: exam,
      subjectId: subject,
      levelId: value._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => { }
      )
    );
  };

  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);
    setResetTopic(false);
    getData(subject, level, value._id, subTopic);
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade,
      subjectId: subject,
      levelId: level,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setsubTopic(value._id);
    setResetSubTopic(false);
    getData(subject, level, topic, value._id);
  };

  const getData = (
    subjectId: any,
    levelId: any,
    topicId: any,
    subTopicId: any
  ) => {
    const postdata = {
      coursetypeId: courseType?._id,
      gradeId: grade,
      examtypeId: exam,
      subjectId: subjectId,
      levelId: levelId,
      topicId: topicId,
      subTopicId: subTopicId,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => { }
      )
    );
  };

  const onChangLecture = (value: any) => {
    setLecture(value);
  };

  function onchangeExtraLect(val: any) {
    if (val.target.checked) {
      setCxtraLect(true);
    } else {
      setCxtraLect(false);
    }


  }

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setLectureId(value?._id);
    setLecture(value?.name);
    setPriorityOrder(value?.priorityOrder);
    setCxtraLect(value?.extra)
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteLectures(
        value._id,
        () => {
          getData(subject, level, topic, subTopic);
        },
        () => { }
      )
    );
  };

  function validation() {
    return (
      lecture === "" ||
      lecture.length < length_Three - 1 ||
      priorityOrder === ""
    );
  }
  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={lectureList ? lectureList : []}
          pagename={"lecture"}
          tableHead={[
            "S.N",
            "LECTURE NAME",
            "SUBTOPIC",
            "TOPIC NAME",
            "PRIORITY ORDER",
            "LEVEL",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      lectureName: lecture,
      priorityOrder: priorityOrder,
      extra: extraLect
    };
    dispatch(
      updateLecture(
        postData,
        lectureId,
        () => {
          setisEditMode(false);
          getData(subject, level, topic, subTopic);
        },
        () => { }
      )
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="px-4">
          <form className="" onSubmit={handleSubmit}>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Lecture Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Lecture Name"
                  value={lecture}
                  onChangeInput={(value: any) => onChangLecture(value)}
                  maxlength={length_Hundred - 1}
                  minlength={length_Three - 1}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={" Priority Order"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Priority Order"
                  value={priorityOrder}
                  onChangeInput={(value: any) => setPriorityOrder(value)}
                  maxlength={length_Hundred - 1}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={" Extra Lecture"} />
              </div>
              <div className="col-6 ms-2">
                <input
                  className="check"
                  type="checkbox"
                  checked={extraLect}
                  onChange={(value: any) => {
                    onchangeExtraLect(value);
                  }}
                />
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Save & Update"} validation={validation()} />
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderSearchMode = () => {
    return (
      <div className="px-4 mx-0 pt-4">
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Country"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Country"
              setInputText={(value: any) => onChangeCountry(value)}
              value={country}
              options={countryList}
              Isrequired={true}
              reset={resetCountry}
              disabled={isEditMode}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between mt-0">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
          </div>
          <div className="col-6 ps-2  ">
            <CustomDropdown
              lableName="Select Course Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeCourseType(value)
              }
              value={courseType}
              options={courseTypeList}
              defaultValue={courseType}
              reset={resetCourseType}
              Isrequired={true}
              disabled={isEditMode}
            />
          </div>
        </div>
        {courseType?.type === 2 ? (
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Student Grade"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Student Grade"
                setInputText={(value: any) => onChangeStudentGrade(value)}
                value={grade}
                options={GradeList}
                Isrequired={true}
                defaultValue={GradeList.length > 0 ? grade : ""}
                reset={resetGrade}
                disabled={isEditMode}
              />
            </div>
          </div>
        ) : null}
        {courseType?.type === 2 || courseType?.type === 3 ? null : (
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Exam Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExam(value)
                }
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
                defaultValue={examList.length > 0 ? exam : ""}
                disabled={isEditMode}
              />
            </div>
          </div>
        )}
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Subject Name"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Subject Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubject(value)
              }
              value={subject}
              options={subjectList}
              Isrequired={true}
              defaultValue={subjectList?.length > 0 ? subject : ""}
              disabled={isEditMode}
              reset={resetSubject}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Level"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Level"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeLevel(value)
              }
              value={level}
              options={levelList}
              Isrequired={true}
              defaultValue={levelList?.length > 0 ? level : ""}
              reset={resetLevel}
              disabled={isEditMode}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Topic Name"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Topic Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeTopic(value)
              }
              value={topic}
              options={topicList}
              Isrequired={true}
              reset={resetTopic}
              defaultValue={topicList?.length > 0 ? topic : ""}
              disabled={isEditMode}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Sub-Topic Name"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Sub-Topic Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubTopic(value)
              }
              value={subTopic}
              options={subTopicList}
              Isrequired={true}
              defaultValue={subTopic}
              reset={resetSubTopic}
              disabled={isEditMode}
            />
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT LECTURE"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {renderSearchMode()}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
