import { Route, Routes } from "react-router-dom";
import Sidebar from '../../Components/sidebar';
import DataEntrySidebarData from './dataEntrySidebarData';
import CreatelivepremierVideo from './createlivepremierVideo';
import Updatelivepremeirvideo from './updatelivepremeirvideo';
import CreateRecordedVideo from "./createRecordedVideo";
import CreateNotification from "./createNotification";
import WIP from '../wip';
import UpdateLiveRecordedVideo from "./updateLiveRecordedVideo";
import CreateExamSet from "./createExamSet";
import CreateTopic from "./createTopic";
import UpdateExamSet from "./updateExamSet";
import CreateSubTopic from "./createSubTopic";
import UpdateSubTopic from "./updateSubTopic";
import CopyExamSet from "./copyExamSet";
import CreateQuestion from "./createQuestion";
import AddQuestion from "./addQuestion";
import QuestionEditor from "./questionEditor";
import UpdateQuestion from "./updateQuestion";
import QuestionOrder from "./questionOrder";
import UpdateTopic from "./updateTopic";
import UpdateNotification from "./updateNotification";
import CreateBlog from "./createBlog";
import UpdateBlog from "./updateBlog";
import CreateLecture from "./createLecture";
import UpdateLecture from "./updateLecture";
import QuestionBank from "./questionBank";
import UpdateQuestionBank from "./updateQuestionBank";
export default function DataEntryPanelDashboard() {
  return (
    <div className='AppBox'>
      <Sidebar data={DataEntrySidebarData?.DataEntrySidebarData} />

      <div className='dashboard'>
        <Routes>
          <Route path="/" element={<CreateTopic />} />
          <Route path="createTopic" element={<CreateTopic />} />
          <Route path="createSubTopic" element={<CreateSubTopic />} />
          <Route path="updateSubTopic" element={<UpdateSubTopic />} />
          <Route path="createlivepremierVideo" element={<CreatelivepremierVideo />} />
          <Route path="updatelivepremeirvideo" element={<Updatelivepremeirvideo />} />
          <Route path="createRecordedVideo" element={<CreateRecordedVideo />} />
          <Route path="updateLiveRecordedVideo" element={<UpdateLiveRecordedVideo />} />
          <Route path="createNotification" element={<CreateNotification />} />
          <Route path="createExamSet" element={<CreateExamSet />} />
          <Route path="updateExamSet" element={<UpdateExamSet />} />
          <Route path="copyExamSet" element={<CopyExamSet />} />
          <Route path="createQuestion" element={<CreateQuestion />} />
          <Route path="addQuestion" element={<AddQuestion />} />
          <Route path="questionEditor" element={<QuestionEditor />} />
          <Route path="updateQuestion" element={<UpdateQuestion />} />
          <Route path="questionOrder" element={<QuestionOrder />} />
          <Route path="updateTopic" element={<UpdateTopic />} />
          <Route path="updateNotification" element={<UpdateNotification />} />
          <Route path="createBlog" element={<CreateBlog />} />
          <Route path="updateBlog" element={<UpdateBlog />} />
          <Route path="createLecture" element={<CreateLecture />} />
          <Route path="updateLecture" element={<UpdateLecture />} />
          <Route path="questionBank" element={<QuestionBank />} />
          <Route path="updateQuestionBank" element={<UpdateQuestionBank />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div></div>
  )
}
