import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  getExamSetAvailQuestionList,
  getExamSetList,
  getExamSetQuestionList,
  getExamSetTypeId,
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getPatternList,
} from "../../Redux/Actions/AdminAction";
import Submitbtn from "../../Components/submitButton";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
export default function CopyExamSet() {
  const dispatch = useDispatch();
  const [courseType, setCourseType] = useState("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState("");
  const [examList, setExamList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [batch, setBatch] = useState("");
  const [batchList, setBatchList] = useState([]);
  const [resetBatch, setResetBatch] = useState(false);
  const [pattern, setPattern] = useState("");
  const [patternList, setPatternList] = useState([]);
  const [resetPattern, setResetPattern] = useState(false);
  const [examSet, setExamSet] = useState("");
  const [examSetList, setExamSetList] = useState([]);
  const [resetExamSet, setResetExamSet] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [examType, setExamType] = useState<any>("");
  const [examTypeList, setExamTypeList] = useState<any>();
  const [resetExamType, setResetExamType] = useState(false);

  const [courseTypeCopy, setCourseTypeCopy] = useState("");
  const [courseTypeListCopy, setcourseTypeListCopy] = useState([]);
  const [resetCourseTypeCopy, setResetCourseTypeCopy] = useState(false);

  const [examCopy, setExamCopy] = useState("");
  const [examListCopy, setExamListCopy] = useState([]);
  const [resetExamCopy, setResetExamCopy] = useState(false);

  const [subjectCopy, setSubjectCopy] = useState("");
  const [subjectListCopy, setSubjectListCopy] = useState([]);
  const [resetSubjectCopy, setResetSubjectCopy] = useState(false);

  const [batchCopy, setBatchCopy] = useState("");
  const [batchListCopy, setBatchListCopy] = useState([]);
  const [resetBatchCopy, setResetBatchCopy] = useState(false);

  const [patternCopy, setPatternCopy] = useState("");
  const [patternListCopy, setPatternListCopy] = useState([]);
  const [resetPatternCopy, setResetPatternCopy] = useState(false);

  const [examSetCopy, setExamSetCopy] = useState("");
  const [examSetListCopy, setExamSetListCopy] = useState([]);
  const [resetExamSetCopy, setResetExamSetCopy] = useState(false);

  const [examTypeCopy, setExamTypeCopy] = useState<any>("");
  const [examTypeListCopy, setExamTypeListCopy] = useState<any>();
  const [resetExamTypeCopy, setResetExamTypeCopy] = useState(false);

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setcourseTypeList(res);
          setcourseTypeListCopy(res);
        },
        () => {}
      )
    );
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamTypeList(res);
          setExamTypeListCopy(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setSubjectList(res);
          setResetExam(false);
          setResetSubject(true);
        },
        () => {}
      )
    );
  };

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value?._id);
    setResetExamType(false);
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const postData: any = {};

    if (courseType != "") {
      postData["coursetypeId"] = courseType;
    }

    if (exam != "") {
      postData["examtypeId"] = exam;
    }

    if (subject != "") {
      postData["subjectId"] = subject;
    }

    dispatch(
      getPatternList(
        postData,
        (res: any) => {
          setPatternList(res);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralPackageList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };

  const onChangeBatch = (value: { _id: any; name?: string }) => {
    setBatch(value._id);
    setResetBatch(false);
    const data = {
      coursetypeId: courseType,
      examtypeId: exam,
      subjectId: subject,
      examSetTypeId: examType,
      packageId: value?._id,
    };

    dispatch(
      getExamSetList(
        data,
        (res: any) => {
          setExamSetList(res);
        },
        () => {}
      )
    );
  };

  const onChangePattern = (value: { _id: any; name?: string }) => {
    setPattern(value._id);
  };

  const onChangeExamSet = (value: { _id: any; name?: string }) => {
    setExamSet(value._id);
    const data = {
      coursetypeId: courseType,
      examtypeId: exam,
      examSetTypeId: value?._id,
      packageId: batch,
      examPatternId: pattern,
    };

    dispatch(
      getExamSetQuestionList(
        data,
        (res: any) => {},
        () => {}
      )
    );
  };

  // ****************Copy******************

  const onChangeCourseTypeCopy = (value: { _id: any; name?: string }) => {
    setCourseTypeCopy(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamListCopy(res);
          setResetCourseTypeCopy(false);
          setResetExamCopy(true);
        },
        () => {}
      )
    );
  };

  const onChangeExamCopy = (value: { _id: any; name?: string }) => {
    setExamCopy(value._id);
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setSubjectListCopy(res);
          setResetExamCopy(false);
          setResetSubjectCopy(true);
        },
        () => {}
      )
    );
  };

  const onChangeExamTypeCopy = (value: { _id: any; name?: string }) => {
    setExamTypeCopy(value?._id);
    setResetExamTypeCopy(false);
  };

  const onChangeSubjectCopy = (value: { _id: any; name?: string }) => {
    setSubjectCopy(value._id);
    setResetSubjectCopy(false);
    const postData: any = {};

    if (courseType != "") {
      postData["coursetypeId"] = courseType;
    }

    if (exam != "") {
      postData["examtypeId"] = exam;
    }

    if (subject != "") {
      postData["subjectId"] = subject;
    }

    dispatch(
      getPatternList(
        postData,
        (res: any) => {
          setPatternListCopy(res);
        },
        () => {}
      )
    );

    dispatch(
      getGeneralPackageList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setBatchListCopy(res);
        },
        () => {}
      )
    );
  };

  const onChangeBatchCopy = (value: { _id: any; name?: string }) => {
    setBatchCopy(value._id);
    setResetBatchCopy(false);
    const data = {
      coursetypeId: courseType,
      examtypeId: exam,
      subjectId: subject,
      examSetTypeId: examType,
      packageId: value?._id,
    };

    dispatch(
      getExamSetList(
        data,
        (res: any) => {
          setExamSetListCopy(res);
        },
        () => {}
      )
    );
  };

  const onChangePatternCopy = (value: { _id: any; name?: string }) => {
    setPatternCopy(value._id);
    setResetPatternCopy(false);
  };

  const onChangeExamSetCopy = (value: { _id: any; name?: string }) => {
    setExamSetCopy(value._id);
    setResetExamSetCopy(false);
    const data = {
      coursetypeId: courseTypeCopy,
      examtypeId: examCopy,
      examSetTypeId: value?._id,
      packageId: batchCopy,
      examPatternId: patternCopy,
    };

    dispatch(
      getExamSetAvailQuestionList(
        data,
        (res: any) => {
        },
        () => {}
      )
    );
  };

  const renderCopyFrom = () => {
    return (
      <div className="col-6">
        <div className="fw-bold text-primary pb-2">Copy From</div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
          </div>
          <div className="col-8 ps-2  ">
            <CustomDropdown
              lableName="Select Course Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeCourseType(value)
              }
              value={courseType}
              options={courseTypeList}
              Isrequired={true}
              reset={resetCourseType}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Exam Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExam(value)
              }
              value={exam}
              options={examList}
              Isrequired={true}
              reset={resetExam}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Type"} />
          </div>
          <div className="col-8 ps-2">
            <CustomDropdown
              lableName="Select Exam Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExamType(value)
              }
              value={examType}
              options={examTypeList}
              Isrequired={true}
              reset={resetExamType}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Subject Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Subject Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubject(value)
              }
              value={subject}
              options={subjectList}
              Isrequired={true}
              reset={resetSubject}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Batch Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Batch Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeBatch(value)
              }
              value={batch}
              options={batchList}
              reset={resetBatch}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Pattern Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Pattern Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangePattern(value)
              }
              value={pattern}
              options={patternList}
              reset={resetPattern}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Set Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Exam Set Name "
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExamSet(value)
              }
              value={examSet}
              options={examSetList}
              reset={resetExamSet}
              Isrequired={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderCopyTo = () => {
    return (
      <div className="col-6">
        <div className="fw-bold text-primary pb-2">Copy To</div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
          </div>
          <div className="col-8 ps-2  ">
            <CustomDropdown
              lableName="Select Course Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeCourseTypeCopy(value)
              }
              value={courseTypeCopy}
              options={courseTypeListCopy}
              Isrequired={true}
              reset={resetCourseTypeCopy}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Exam Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExamCopy(value)
              }
              value={examCopy}
              options={examListCopy}
              Isrequired={true}
              reset={resetExamCopy}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Type"} />
          </div>
          <div className="col-8 ps-2">
            <CustomDropdown
              lableName="Select Exam Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExamTypeCopy(value)
              }
              value={examTypeCopy}
              options={examTypeListCopy}
              Isrequired={true}
              reset={resetExamTypeCopy}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Subject Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Subject Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubjectCopy(value)
              }
              value={subjectCopy}
              options={subjectListCopy}
              Isrequired={true}
              reset={resetSubjectCopy}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Batch Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Batch Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeBatchCopy(value)
              }
              value={batchCopy}
              options={batchListCopy}
              reset={resetBatchCopy}
              Isrequired={true}
            />
          </div>
        </div>

        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Pattern Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Pattern Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangePatternCopy(value)
              }
              value={patternCopy}
              options={patternListCopy}
              reset={resetPatternCopy}
              Isrequired={true}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Exam Set Name"} />
          </div>
          <div className="col-8 ps-2 ">
            <CustomDropdown
              lableName="Select Exam Set Name "
              setInputText={(value: { _id: string; name: string }) =>
                onChangeExamSetCopy(value)
              }
              value={examSetCopy}
              options={examSetListCopy}
              reset={resetExamSetCopy}
              Isrequired={true}
            />
          </div>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    return (
      <table className="w-100 ">
        <thead className="text-center">
          <tr>
            <th style={{ width: "10%" }}>
              <input type="checkbox" />

              <label className="ms-2"> Copy all</label>
            </th>
            <th className="text-start">
              <label>Question</label>
            </th>
            <th>
              <label>Order</label>
            </th>
          </tr>
        </thead>
      </table>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE EXAM SET"} />
      <div className="px-4 mx-0 pt-4 formDiv ">
        <form className="col-12 formWidth">
          <div className="col-auto">
            <div className="row d-flex w-100">
              {renderCopyFrom()}
              {renderCopyTo()}
            </div>
            {renderTable()}
          </div>
        </form>
        <div>
          <div className=" d-flex justify-content-end mt-4">
            <Submitbtn name="Copy Exam Set" />
          </div>
        </div>
      </div>
    </div>
  );
}
