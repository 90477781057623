import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import { useDispatch } from "react-redux";
import {
  deleteBlog,
  getBlogList,
  updateBlog,
  uploadBlogImg,
} from "../../Redux/Actions/AdminAction";
import {
  image_size_150,
  validateIsFileImageType,
  startsWithSpace,
  length_Two,
  length_Hundred,
  validateFullName,
  validateDigitOnly,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import TextEditor from "../../Components/TextEditor";
import PaginationTable from "../../Components/paginationTable";
import DatePicker from "../../Components/datePicker";
import moment from "moment";

export default function UpdateBlog() {
  const dispatch: Function = useDispatch();
  const [isEdit, setIsEdit] = useState<boolean>();
  const [title, setTitle] = useState<string>("");
  const [blogImage, setBlogImage] = useState<any>("");
  const [readTime, setReadTime] = useState<any>("");
  const [desc, setDesc] = useState<any>("");
  const [uploadBlogImage, setUploadBlogImage] = useState<any>("");
  const [blogId, setBlogId] = useState<any>("");
  const [blogList, setBlogList] = useState<any>("");

  const [startDate, SetStartDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, SetEndDate] = useState<any>(
    moment.utc(new Date()).format("YYYY-MM-DD")
  );

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(
      getBlogList(
        postData,
        (res: any) => {
          setBlogList(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEdit(true);
    setTitle(value?.title);
    setBlogImage(value?.blogImage);
    setReadTime(value?.readingTime);
    setDesc(value.content);
    setBlogId(value?.blogId);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteBlog(
        value.blogId,
        () => {
          getData();
        },
        () => {}
      )
    );
  };

  const handleOnEdit = (e: any) => {
    e.preventDefault();
    const postData = {
      title: title,
      blogImage: uploadBlogImage,
      readingTime: readTime,
      content: desc,
    };
    dispatch(
      updateBlog(
        postData,
        blogId,
        (res: any) => {
          setIsEdit(false);
          getData();
        },
        () => {}
      )
    );
  };
  const onChange = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(
      getBlogList(
        postData,
        (res: any) => {},
        () => {}
      )
    );
  };
  const onChangeTitle = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setTitle(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_150) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBlogImage(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(
        uploadBlogImg(
          file,
          (res: any) => {
            delete res?.filePath;
            setUploadBlogImage(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeReadTime = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setReadTime(value);
    } else {
      dispatch(showErrorSnackbar("Please digits only"));
      return;
    }
  };
  const onChangeText = (val: string) => {
    setDesc(val);
  };
  function validation() {
    return (
      title === "" ||
      title.length < length_Two - 1 ||
      blogImage === "" ||
      readTime === "" ||
      desc === ""
    );
  }

  const renderListMode = () => {
    return (
      <div className="px-4 mx-0 mt-4 formDiv ">
        <PaginationTable
          tableData={blogList ? blogList : []}
          pagename={"updatedBlog"}
          tableHead={["S.N", "Title", "Created Date", "Action"]}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          edit={true}
        />
      </div>
    );
  };
  const renderEditMode = () => {
    return (
      <div>
        <div className="px-4 mx-0 mt-4 formDiv ">
          <form className="" onSubmit={handleOnEdit}>
            <div className="col-7">
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Title"} />
                </div>
                <div className="col-8  ">
                  <InputField
                    placeholder="Enter Title"
                    value={title}
                    onChangeInput={(value: any) => onChangeTitle(value)}
                    maxlength={length_Hundred - 1}
                    minlength={length_Two}
                    isRequired={true}
                  />
                </div>
              </div>

              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Upload Image"} />
                </div>
                <div className="col-8 ps-2">
                  <ImagePickerAndViewer
                    lableName={"Option Image"}
                    value={blogImage}
                    handleChange={(e: any) => handleOptionImageChange(e)}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Reading Time"} />
                </div>
                <div className="col-8  ">
                  <InputField
                    placeholder="Enter Reading Time"
                    value={readTime}
                    onChangeInput={(value: any) => onChangeReadTime(value)}
                    maxlength={length_Hundred - 1}
                    minlength={length_Two}
                    isRequired={true}
                  />
                </div>
              </div>
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Content"} />
                </div>
                <div className="col-8 ps-2 d-flex">
                  <div className="editor_content mb-2">
                    <TextEditor
                      value={desc}
                      onChange={(value: string) => onChangeText(value)}
                      height="vh100"
                      width="vw100"
                    />
                  </div>
                  <span className="required ms-2 fs-6">*</span>
                </div>
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Save & Update"} validation={validation()} />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT BLOG"}
        isEditMode={isEdit}
        setisEditMode={setIsEdit}
      />

      {isEdit !== true ? (
        <div className="px-4 mx-0 pt-4">
          <div className="d-flex">
            <div className="col-md-3 d-flex Justify-content-between ">
              <div className="col-md-3 mt-2">
                <LabelField lableName="Start Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => SetStartDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex Justify-content-between ms-3">
              <div className="col-md-3 mt-2">
                <LabelField lableName="End Date:" />
              </div>
              <div className="col-md-9">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => SetEndDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex Justify-content-between ms-3">
              <button
                className="btn btn-primary "
                type="submit"
                onClick={(e) => onChange()}
              >
                {" "}
                Search
              </button>
            </div>
          </div>
        </div>
      ) : null}

      {isEdit ? renderEditMode() : renderListMode()}
    </div>
  );
}
