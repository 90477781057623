import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import { userData } from "../../Utils/dataConstant";
import { useDispatch } from "react-redux";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import CustomDropdown from "../../Components/customDropdown";
import { useNavigate } from "react-router-dom";
import {
  getGeneralCourseList,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import { getUserFeedbackData } from "../../Redux/Actions/TeacherActions";

export default function Feedback() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetCourse, setResetcourse] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState<any>();
  const [resetExam, setExamcourse] = useState(false);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any>();
  const [resetBatch, setBatchcourse] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [userFeedbackDataList, setUserFeedbackDataList] = useState<any>([]);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  let temp = 0;
  temp = page * rowsPerPage + 1;

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const postData: any = {
      skip: 0,
      limit: rowsPerPage,
    };
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleNavigate = (value: any) => {
    navigate("../subjectFeedback", { state: { details: value?.Package } });
  };
  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    setUserFeedbackDataList([]);

    dispatch(
      getUserFeedbackData(
        {},
        (res: any) => {
          setUserFeedbackDataList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExam(value);
    dispatch(
      getGeneralPackageList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubjectType = (value: { _id: any; name?: string }) => {};

  const onChangeBatchType = (value: { _id: any; name?: string }) => {
    setBatch(value);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    setUserFeedbackDataList([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      packageId: batch?._id,
    };

    dispatch(
      getUserFeedbackData(
        postData,
        (res: any) => {
          setUserFeedbackDataList(res);
        },
        () => {}
      )
    );
  };
  const renderListMode = () => {
    return (
      <>
        {userData?.data?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={userData?.data ? userData?.data : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={userFeedbackDataList ? userFeedbackDataList : []}
          pagename={"Feedback"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "COURSE",
            "EXAM NAME",
            "SUBJECT NAME",
            "BATCH NAME",
            "REVIEW COUNT",
            "OVERALL RATING",
            "ACTION",
          ]}
          handleNavigate={(value: any) => handleNavigate(value)}
        />
      </>
    );
  };

  const overallRating = () => {
    let rating = 0;
    userFeedbackDataList.map((item: any) => {
      rating = rating + item?.overall_rating;
    });
    return rating / userFeedbackDataList.length;
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"FEEDBACK"}
        reviewCount={
          userFeedbackDataList.length ? userFeedbackDataList.length : "0"
        }
        overallRating={overallRating()}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-12 d-flex flex-wrap pt-2">
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCourseType(value)
                  }
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetCourse}
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExamType(value)
                  }
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Batch Type"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatchType(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                  reset={resetBatch}
                />
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Search Question"} />
          </div>
        </form>

        {renderListMode()}
      </div>
    </div>
  );
}
