interface props {
   name: String,
   validation?:boolean
}

export default function SubmitButton({ name ,validation}: props) {
   return (
      <button type="submit" className={validation ? "btn btn-secondary rounded-pill px-5 searchButton ":"btn btn-dark rounded-pill px-5 searchButton "} disabled={validation}>{name}</button>
   );
}
