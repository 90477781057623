import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import CouncellorSidebarData from "./councellorPanleSidebarData";
import ReferenceUser from "./referenceUser";
import ReferenceUserList from "./referenceUserList";
import WIP from "../wip";
import CounsellorPayments from "./counsellorPayments";
import StudentPaymentLink from "./studentPaymentLink";
import CounsellorStudentPayLinks from "./counsellorStudentPayLinks";
export default function CouncellorPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={CouncellorSidebarData?.CouncellorSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<ReferenceUser />} />
          <Route path="referenceUser" element={<ReferenceUser />} />
          <Route path="referenceUserList" element={<ReferenceUserList />} />
          <Route path="counsellorPayments" element={<CounsellorPayments />} />
          <Route path="studentPaymentLink" element={<StudentPaymentLink />} />
          <Route
            path="counsellorStudentPayLinks"
            element={<CounsellorStudentPayLinks />}
          />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
