import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import CustomDropdown from "../../Components/customDropdown"; 
import ToggleSwitchbtn from '../../Components/toggleSwitchBtn';
import { useDispatch } from 'react-redux';
import { addSection, getSectionTypesList } from '../../Redux/Actions/AdminAction';
import { priorityOrderData } from '../../Utils/dataConstant';
import {  startsWithSpace,length_Hundred ,length_Two,validateFullName } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function CreateSection() {
  const dispatch : Function = useDispatch();
  const [p_order, setP_order] = useState<any>();
  const [sectionTypeList, setSectionTypeList ]  = useState([]);
  const [sectionVal,setSectionVal] = useState<any>(""); 
  const [section,setSection] = useState<string>("");
  const [toggle, setToggle] = useState(false);
  const [resetPriorityOrder, setResetPriorityOrder] = useState(false);
  const [resetType, setResetType] = useState(false);


  const onChangePriorityOrder = (value: any) => {
    setP_order(value)
  };
  const onChangeType = (value:any) => {
    setSectionVal(value)
  };

  useEffect(() => {
  
    dispatch(
      getSectionTypesList(
        {},
        (res: any) => { 
          setSectionTypeList(res)
        },
        () => { }
      )
    );
  }, []);

  const changeToggle = (event:any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };
  const onChangeSection = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setSection(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }
  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const postData = {
      name: section,
      order: p_order._id,
      type: sectionVal._id,
      isHomePage: toggle,
    }

    dispatch(
      addSection(
        postData,
        (res: any) => { 
          setSection("");
          setResetPriorityOrder(true);
          setResetType(true);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el:any) => (el.checked = false));
        },
        () => { }
      )
    );
  }

  function validation() {
    return (
      section === ""||section.length < length_Two  || p_order === "" || sectionVal === ""

    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"SECTION"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='col-10' onSubmit={handleSubmit}>
        
        <div className='d-flex mb-2 align-items-center'>
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Section Name"} />
            </div>
            <div className="col-6 ">
            <InputField
              placeholder="Enter Section Name"
              value={section}
              onChangeInput={(value: any) => onChangeSection(value)}
              maxlength={length_Hundred -1}
              minlength={length_Two }
              isRequired={true}
            />
                

              </div>
          </div>
        <div className="d-flex Justify-content-between  ">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Priority Order"} />
            </div>
            <div className="col-6 ps-2">
            <CustomDropdown
              lableName="Select Priority Order"
              setInputText={(value: { _id: string; name: string }) =>
                onChangePriorityOrder(value)
              }
              value={p_order}
              options={priorityOrderData}
              Isrequired={true}
              reset={resetPriorityOrder}
            />
          </div>
          </div>
          <div className="d-flex Justify-content-between  ">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Type"} />
            </div>
            <div className="col-6 ps-2">
            <CustomDropdown
              lableName="Select Panel Role"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeType(value)
              }
              value={sectionVal}
              options={sectionTypeList}
              Isrequired={true}
              reset={resetType}
            />
          </div>
          </div>
          <div className="">
          <ToggleSwitchbtn 
            lableName='Home Page'
            getToggleval={(value:any) => {
              changeToggle(value);
            }}/>
          </div>
      
             
            <div className='d-flex justify-content-center mt-3'>
              <Submitbtn name={"Submit"}
              validation={validation()}/>
            </div>
          </form>
          </div>
        </div>
      )
    }

