import { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import {
  imagePositionList,
  ImageRatioList,
  TextOptions,
} from "../Utils/dataConstant";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../Utils/errorMsg";
import { uploadAcademicsImg } from "../Redux/Actions/AdminAction";
import InputField from "../Atoms/InputField";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "./addmorebtn";
import TextCKEditor from "./textCKEditor";

export default function AcademicsClassContent(props: any) {
  const dispatch = useDispatch();
  const initialVal: {
    Heading: any;
    Text: any;
    img: any;
    doctype: any;
  }[] = [
    {
      Heading: "",
      Text: "",
      img: "",
      doctype: "img",
    },
  ];

  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [show, setShow] = useState(false);
  const [menuArray, setmenuArray] = useState<any>();
  const [imageRatio, setImageRatio] = useState<any>("");
  const [centerImageRatio, setCenterImageRatio] = useState<any>("");
  const [resetImageRatioList, setResetImageRatioList] = useState(false);
  const [uploadVideo, setUploadVideo] = useState<any>("");
  const [loadVideo, setLoadVideo] = useState<any>("");
  const [flipcardDataArr, setFlipcardDataArr] = useState<any>(initialVal);
  const bottomRef: any = useRef(null);
  const [imagePosition, setImagePosition] = useState<any>();
  useEffect(() => {
    setmenuArray(TextOptions);

    props?.getData?.map((value: any) => {
      let sortArray = value?.classContent?.sort(
        (a: any, b: any) => a.contentOrder - b.contentOrder
      );
      setClassContentArray(value?.classContent);
    });
  }, []);
  console.log("classContentArray123", classContentArray);
  const showInputMenu = (inputType: any, data: any) => {
    const index =
      classContentArray?.length > 0
        ? classContentArray[classContentArray?.length - 1]?.contentOrder
        : 0;
    if (inputType === "text") {
      if (data === 11 || data === 12 || data === 13 || data === 14) {
        const initialArr = [
          {
            Heading: "",
            Text: "",
            img: "",
          },
        ];
        const inputTypeText = {
          descType: inputType,
          content: JSON.stringify(initialArr),
          contentType: data,
          contentOrder: index + 1,
        };

        setClassContentArray([...classContentArray, inputTypeText]);
      } else {
        const inputTypeText = {
          descType: inputType,
          content: "",
          contentType: data,
          contentOrder: index + 1,
        };

        setClassContentArray([...classContentArray, inputTypeText]);
      }
    } else if (inputType === "image") {
      const inputTypeImage = {
        descType: inputType,
        content: "",
        contentType: 5,
        contentOrder: index + 1,
        resolution: imageRatio,
      };

      setClassContentArray([...classContentArray, inputTypeImage]);
    } else if (inputType === "video") {
      const inputTypeVideo = {
        descType: inputType,
        contentType: 6,
        contentOrder: index + 1,
      };
      setClassContentArray([...classContentArray, inputTypeVideo]);
    }
  };

  function clickText() {
    setShow(!show);
  }

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };

  const onChangeClassContentText = (
    index: string | number,
    value: any,
    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(classContentArray));
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentTextImage = (
    index: string | number,

    value: any,

    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(classContentArray));

    if (key === "img") {
      value.preventDefault();

      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);

        return;
      } else {
        const reader = new FileReader();

        const file = value?.target.files[0];

        reader.readAsDataURL(file);

        dispatch(
          uploadAcademicsImg(
            file,

            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["img"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  text: "",
                  img: res.filePath,
                  ratio: "",
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },

            () => {}
          )
        );
      }
    } else if (key === "Leftimg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadAcademicsImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["Leftimg"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  text: "",
                  Leftimg: res.filePath,
                  LeftRatio: "",
                  Rightimg: "",
                  RightRatio: "",
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "Rightimg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadAcademicsImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["Rightimg"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  text: "",
                  Leftimg: "",
                  LeftRatio: "",
                  Rightimg: res.filePath,
                  RightRatio: "",
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "centerimg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadAcademicsImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["centerimg"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  LeftText: "",
                  RightText: value,
                  CenterImageRatio: "",
                  centerimg: res.filePath,
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "CardImg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadAcademicsImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["img"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  Heading: "",
                  Text: "",
                  img: res.filePath,
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "CardImg") {
      value.preventDefault();
      if (!validateIsFileImageType(value)) {
        dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
        return;
      } else {
        const reader = new FileReader();
        const file = value?.target.files[0];
        reader.readAsDataURL(file);
        dispatch(
          uploadAcademicsImg(
            file,
            (res: any) => {
              if (newArrr[index]["content"]) {
                let convertObj = JSON.parse(newArrr[index]["content"]);
                convertObj["img"] = res.filePath;
                convertObj["doctype"] = "img";
                const convertToStringObj = JSON.stringify(convertObj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              } else {
                let obj = {
                  Heading: "",
                  Text: "",
                  img: res.filePath,
                  doctype: "img",
                };
                const convertToStringObj = JSON.stringify(obj);
                newArrr[index]["content"] = convertToStringObj;
                setClassContentArray(newArrr);
              }
            },
            () => {}
          )
        );
      }
    } else if (key === "ratio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["ratio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          text: "",
          img: "",
          ratio: value?.name,
          doctype: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "LeftRatio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["LeftRatio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          text: "",
          LeftRatio: value?.name,
          Leftimg: "",
          Rightimg: "",
          RightRatio: "",
          doctype: "",
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "RightRatio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["RightRatio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          text: "",
          Rightimg: "",
          RightRatio: value?.name,
          LeftRatio: "",
          Leftimg: "",
          doctype: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "CenterImageRatio") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["CenterImageRatio"] = value?.name;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          LeftText: "",
          RightText: "",
          CenterImageRatio: value?.name,
          centerimg: "",
          doctype: "",
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "Innercontent") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["text"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          text: value,
          Rightimg: "",
          RightRatio: "",
          LeftRatio: "",
          Leftimg: "",
          doctype: "",
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "LeftText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["LeftText"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          LeftText: value,
          RightText: "",
          CenterImageRatio: "",
          centerimg: "",
          doctype: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "RightText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["RightText"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          LeftText: "",
          RightText: value,
          CenterImageRatio: "",
          centerimg: "",
          doctype: "",
        };
        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else if (key === "HeadingText") {
      if (newArrr[index]["content"]) {
        let convertObj = JSON.parse(newArrr[index]["content"]);
        convertObj["Heading"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          Heading: value,
          Text: "",
          img: "",
          doctype: "",
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index]["content"] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    } else {
      if (newArrr[index][key]) {
        let convertObj = JSON.parse(newArrr[index][key]);
        convertObj["text"] = value;
        const convertToStringObj = JSON.stringify(convertObj);
        newArrr[index][key] = convertToStringObj;
        setClassContentArray(newArrr);
      } else {
        let obj = {
          text: value,
          img: "",
          ratio: "",
          doctype: "",
        };

        const convertToStringObj = JSON.stringify(obj);
        newArrr[index][key] = convertToStringObj;
        setClassContentArray(newArrr);
      }
    }
  };

  function onchangePoints(
    index: number,
    innerIndex: number,
    val: any,
    key: any
  ) {
    let newArr = JSON.parse(JSON.stringify(classContentArray));
    if (key) {
      let arr = newArr[index]["content"].split(" || ");
      arr[innerIndex] = val;
      newArr[index]["content"] = arr.join(" || ");
      setClassContentArray(newArr);
    }
  }

  function onchangeCardData(val: any, index: any, contentindex: any, key: any) {
    console.log("objonchangeCardDataect", val, key);
    let newArr = JSON.parse(JSON.stringify(classContentArray));
    if (newArr[index]["content"] === "") {
      const initialArr = [
        {
          Heading: "",
          Text: "",
          img: "",
          imgPos: "",
        },
      ];
      newArr[index]["content"] = JSON.stringify(initialArr);
    }
    let arr = JSON.parse(newArr[index]["content"]);
    if (key === "Heading" || key === "Text") {
      arr[contentindex][key] = val;
      newArr[index]["content"] = JSON.stringify(arr);
      setClassContentArray(newArr);
    } else if (key === "img") {
      const reader = new FileReader();
      const file = val.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            arr[contentindex][key] = res?.filePath;
            newArr[index]["content"] = JSON.stringify(arr);
            setClassContentArray(newArr);
          },
          () => {}
        )
      );
      setFlipcardDataArr(newArr);
    } else if (key === "imgPos") {
      arr[contentindex][key] = val?._id;
      newArr[index]["content"] = JSON.stringify(arr);
      setClassContentArray(newArr);
    }
  }
  const scrollToBottom = () => {
    if (bottomRef.current) {
      const bottomElement = bottomRef.current.querySelector("#bottomRef");

      if (bottomElement) {
        bottomRef.current.scrollTop = bottomElement.offsetTop;
      }
    }
  };

  const onPressAdd = (index: any) => {
    const newArr = [...classContentArray];
    let arr = newArr[index]["content"].split(" || ");
    arr.push("");
    newArr[index]["content"] = arr.join(" || ");
    setClassContentArray(newArr);
    setTimeout(scrollToBottom, 100);
  };

  const handleClose = (index: any, pointsIndex: number) => {
    const newArr = [...classContentArray];
    if (index > -1) {
      let arr = newArr[index]["content"].split(" || ");
      arr?.splice(pointsIndex, 1);
      newArr[index]["content"] = arr.toString();
    }
    setClassContentArray(newArr);
  };

  const onChangeImageRatio = (value: any, index: any) => {
    onChangeClassContentText(index, value?.name, "resolution");
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "content");
          },
          () => {}
        )
      );
    }
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLoadVideo(reader.result);
    };
    reader.readAsDataURL(file);
    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "content");
            setUploadVideo(res.filePath);
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => {}
      )
    );
  };

  useEffect(() => {
    setClassContentValue();
  }, [classContentArray]);

  const setClassContentValue = () => {
    let tempClassContentArray = classContentArray?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          content: item.content.replace(/'/g, "&apos;"),
          contentType: item?.contentType,
          contentOrder: item?.contentOrder,
        };
      }
      return item;
    });

    const PostData = {
      classContent: tempClassContentArray,
    };
    props?.setClassContentData(PostData);
    return PostData;
  };

  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, content, resolution, contentType } = item;
      let image: any = {};
      if (descType === "image") {
        const filterimage = ImageRatioList?.filter(
          (imgitem: any) => imgitem?.name === resolution
        );
        if (filterimage) {
          image = filterimage[0];
        }
      }
      let textimageData: any = {},
        imageratio: any = {};
      if ((contentType === 7 || contentType === 8) && content) {
        textimageData = JSON.parse(content);
        let filterRatio = ImageRatioList?.filter(
          (item: any) => item?.name === textimageData?.ratio
        );
        if (filterRatio) {
          imageratio = filterRatio[0];
        }
      }

      // **************************Image outer*******************************

      let textimagOuterData: any = {},
        imageratioLeft: any = {},
        imageratioRight: any = {};
      if (contentType === 10 && content) {
        textimagOuterData = JSON.parse(content);
        let filterRatio1 = ImageRatioList?.filter(
          (item: any) => item?.name === textimagOuterData?.LeftRatio
        );
        let filterRatio2 = ImageRatioList?.filter(
          (item: any) => item?.name === textimagOuterData?.RightRatio
        );
        if (filterRatio1) {
          imageratioLeft = filterRatio1[0];
        }
        if (filterRatio2) {
          imageratioRight = filterRatio2[0];
        }
      }

      // ***********************Image center**************************************

      let textimagInnerData: any = {},
        CenterImageRatio: any = {};
      if (contentType === 9 && content) {
        textimagInnerData = JSON.parse(content);
        let filterRatio = ImageRatioList?.filter(
          (item: any) => item?.name === textimagInnerData?.CenterImageRatio
        );
        if (filterRatio) {
          CenterImageRatio = filterRatio[0];
        }
      }

      const onPressAddFlipcard = (index: number) => {
        const newArr = [...classContentArray];
        let arr = JSON.parse(newArr[index]["content"]);
        arr.push({
          Heading: "",
          Text: "",
          img: "",
          imgPos: "",
        });
        newArr[index]["content"] = JSON.stringify(arr);
        setClassContentArray(newArr);
      };

      const handleFlipCardClose = (
        item: any,
        index: any,
        contentIndex: any
      ) => {
        const newArr = [...classContentArray];
        if (index > -1) {
          let arr = JSON.parse(newArr[index]["content"]);
          arr?.splice(contentIndex, 1);
          newArr[index]["content"] = JSON.stringify(arr);
        }
        setClassContentArray(newArr);
      };
      const flipcardData = () => {
        return JSON.parse(content)?.map((item: any, contentindex: any) => {
          const { Heading, img, Text, imgPos } = item;
          return (
            <>
              <div className="w-100">
                {content.length === 0 ? null : (
                  <span className="text-dark d-flex justify-content-end">
                    <DeleteIcon
                      className="cursor"
                      color="error"
                      fontSize="small"
                      onClick={() =>
                        handleFlipCardClose(item, index, contentindex)
                      }
                    />
                  </span>
                )}

                <div className="d-flex Justify-content-between border border-black p-2 row mx-0 mt-3">
                  <div className="d-flex Justify-content-between ">
                    <div className="d-flex">
                      <div className="col-md-3 mt-2">
                        <LabelField lableName={"Heading"} />
                      </div>
                      <div className="col-md-9">
                        <InputField
                          placeholder="Enter Heading"
                          value={Heading}
                          onChangeInput={(value: any) =>
                            onchangeCardData(
                              value,
                              index,
                              contentindex,
                              "Heading"
                            )
                          }
                        />
                      </div>
                    </div>

                    {contentType === 11 ||
                    contentType === 13 ||
                    contentType === 12 ||
                    contentType === 14 ? (
                      <div className="d-flex ms-5">
                        <LabelField lableName={"Image"} />

                        <div className="mt-0">
                          <ImagePickerAndViewer
                            lableName={"Image"}
                            value={img}
                            handleChange={(value: any) =>
                              onchangeCardData(
                                value,
                                index,
                                contentindex,
                                "img"
                              )
                            }
                            removelabel={true}
                          />
                        </div>
                        <div className="d-flex">
                          <CustomDropdown
                            lableName="Select Image Type"
                            setInputText={(value: any) =>
                              onchangeCardData(
                                value,
                                index,
                                contentindex,
                                "imgPos"
                              )
                            }
                            value={imgPos}
                            options={imagePositionList}
                            defaultValue={imgPos}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>

                  <div className=" w-100">
                    <LabelField lableName={"Content"} />

                    <TextCKEditor
                      value={Text}
                      onChange={(value: any) =>
                        onchangeCardData(value, index, contentindex, "Text")
                      }
                    />
                  </div>
                </div>
              </div>
            </>
          );
        });
      };

      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {contentType === 4 ? (
              <div className="w-100">
                <LabelField lableName={contentType === 4 ? "Points" : ""} />
                <div className="d-flex Justify-content-between border border-black p-2">
                  <div
                    className="col-6"
                    style={{ maxHeight: "250px", overflow: "auto" }}
                    ref={bottomRef}
                  >
                    {content.split(" || ")?.map((contentData: any, i: any) => {
                      return (
                        <div
                          className="d-flex border rounded p-1 mt-2"
                          id={
                            i === content?.split(" || ")?.length - 1
                              ? "bottomRef"
                              : i
                          }
                        >
                          <span className="px-2">{i + 1} </span>
                          <div className="w-100 d-flex justify-content-between mt-2">
                            <InputField
                              placeholder="Enter Points"
                              value={contentData}
                              onChangeInput={(value: any) =>
                                onchangePoints(index, i, value, "content")
                              }
                            />
                          </div>

                          <span className="p-2 mt-2 d-flex removeButton">
                            {content.length === 0 ? null : (
                              <DeleteIcon
                                className="cursor"
                                color="error"
                                fontSize="small"
                                onClick={() => handleClose(index, i)}
                              />
                            )}
                          </span>
                        </div>
                      );
                    })}
                  </div>

                  <div className="col-6">
                    <div className="d-flex justify-content-center">
                      <div className="d-flex justify-content-center mt-3">
                        <Addmorebtn
                          name="Add More"
                          handleClick={() => onPressAdd(index)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : contentType === 7 || contentType === 8 ? (
              <div className="w-100">
                <LabelField
                  lableName={
                    contentType === 7
                      ? "TextImage-left"
                      : contentType === 8
                      ? "TextImage-right"
                      : ""
                  }
                />

                <div className="d-flex Justify-content-between border border-black p-2 row mx-0">
                  <div className="col-8">
                    <TextCKEditor
                      value={textimageData?.text}
                      onChange={(value: any) =>
                        onChangeClassContentTextImage(index, value, "content")
                      }
                    />
                  </div>

                  <div className="col-4 d-flex align-items-center">
                    <div className="d-flex mt-0 ms-2 border justify-content-center p-2">
                      <div className="d-flex py-2 me-2">
                        <LabelField lableName={"Image Ratio"} />
                        <CustomDropdown
                          lableName="Select Image Ratio"
                          setInputText={(value: any) =>
                            onChangeClassContentTextImage(index, value, "ratio")
                          }
                          value={imageratio}
                          options={ImageRatioList}
                          defaultValue={
                            ImageRatioList?.length > 0 ? imageratio?._id : null
                          }
                        />
                      </div>

                      <div className="border border-3 rounded p-2">
                        <ImagePickerAndViewer
                          lableName={"Image"}
                          value={textimageData?.img}
                          handleChange={(e: any) =>
                            onChangeClassContentTextImage(index, e, "img")
                          }
                          removelabel={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : contentType === 10 ? (
              <div className="w-100">
                <LabelField
                  lableName={contentType === 10 ? "TextImage-outer" : ""}
                />

                <div className="d-flex Justify-content-between border border-black p-2 row mx-0">
                  <div className="col-7">
                    <TextCKEditor
                      value={textimagOuterData?.text}
                      onChange={(value: any) =>
                        onChangeClassContentTextImage(
                          index,
                          value,
                          "Innercontent"
                        )
                      }
                    />
                  </div>

                  <div className="col-5 align-items-center">
                    <div className="d-flex mt-0 ms-2 border justify-content-center p-2">
                      <div className="d-flex py-2 me-2">
                        <LabelField lableName={"Left Image Ratio"} />
                        <CustomDropdown
                          lableName="Select Image Ratio"
                          setInputText={(value: any) =>
                            onChangeClassContentTextImage(
                              index,
                              value,
                              "LeftRatio"
                            )
                          }
                          value={imageratioLeft}
                          options={ImageRatioList}
                          defaultValue={
                            ImageRatioList?.length > 0
                              ? imageratioLeft?._id
                              : null
                          }
                        />
                      </div>

                      <div className="border border-3 rounded p-2">
                        <ImagePickerAndViewer
                          lableName={"Image"}
                          value={textimagOuterData?.Leftimg}
                          handleChange={(e: any) =>
                            onChangeClassContentTextImage(index, e, "Leftimg")
                          }
                          removelabel={true}
                        />
                      </div>
                    </div>

                    <div className="d-flex mt-0 ms-2 border justify-content-center p-2 mt-3">
                      <div className="d-flex py-2 me-2">
                        <LabelField lableName={"Right Image Ratio"} />

                        <CustomDropdown
                          lableName="Select Image Ratio"
                          setInputText={(value: any) =>
                            onChangeClassContentTextImage(
                              index,
                              value,
                              "RightRatio"
                            )
                          }
                          value={imageratioRight}
                          options={ImageRatioList}
                          defaultValue={
                            ImageRatioList?.length > 0
                              ? imageratioRight?._id
                              : null
                          }
                        />
                      </div>

                      <div className="border border-3 rounded p-2">
                        <ImagePickerAndViewer
                          lableName={"Image"}
                          value={textimagOuterData?.Rightimg}
                          handleChange={(e: any) =>
                            onChangeClassContentTextImage(index, e, "Rightimg")
                          }
                          removelabel={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : contentType === 9 ? (
              <div className="w-100">
                <LabelField
                  lableName={contentType === 9 ? "TextImage-center" : ""}
                />
                <div className="d-flex Justify-content-between border border-black p-2 row mx-0">
                  <div className="col-4">
                    <LabelField lableName={"Left Text"} />
                    <TextCKEditor
                      value={textimagInnerData?.LeftText}
                      onChange={(value: any) =>
                        onChangeClassContentTextImage(index, value, "LeftText")
                      }
                    />
                  </div>

                  <div className="col-4 align-items-center mt-5">
                    <div className="d-flex mt-0 ms-2 border justify-content-center p-2">
                      <div className="d-flex py-2 me-2">
                        <LabelField lableName={"Image Ratio"} />
                        <CustomDropdown
                          lableName="Select Image Ratio"
                          setInputText={(value: any) =>
                            onChangeClassContentTextImage(
                              index,
                              value,
                              "CenterImageRatio"
                            )
                          }
                          value={centerImageRatio}
                          options={ImageRatioList}
                          defaultValue={
                            ImageRatioList?.length > 0
                              ? centerImageRatio?._id
                              : null
                          }
                        />
                      </div>

                      <div className="border border-3 rounded p-2">
                        <ImagePickerAndViewer
                          lableName={"Image"}
                          value={textimagInnerData?.centerimg}
                          handleChange={(e: any) =>
                            onChangeClassContentTextImage(index, e, "centerimg")
                          }
                          removelabel={true}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-4">
                    <LabelField lableName={"Right Text"} />
                    <TextCKEditor
                      value={textimagInnerData?.RightText}
                      onChange={(value: any) =>
                        onChangeClassContentTextImage(index, value, "RightText")
                      }
                    />
                  </div>
                </div>
              </div>
            ) : contentType === 1 || contentType === 2 || contentType === 3 ? (
              <div className="m-2 w-100">
                <label className="fw-bold mb-2">
                  {contentType === 1
                    ? "Heading"
                    : contentType === 2
                    ? "Sub-Heading"
                    : contentType === 3
                    ? "Description"
                    : ""}
                </label>

                <TextCKEditor
                  value={content}
                  onChange={(value: any) =>
                    onChangeClassContentText(index, value, "content")
                  }
                />
              </div>
            ) : contentType === 5 ? (
              <div>
                <label className="ms-2 fw-bold mb-2">
                  {contentType === 5 ? "Image" : ""}
                </label>

                <div className="d-flex mt-0 ms-2 border justify-content-center p-2">
                  <div className="d-flex py-2 me-2">
                    <LabelField lableName={"Image Ratio"} />
                    <CustomDropdown
                      lableName="Select Image Ratio"
                      setInputText={(value: any) =>
                        onChangeImageRatio(value, index)
                      }
                      value={resolution}
                      options={ImageRatioList}
                      reset={resetImageRatioList}
                      defaultValue={
                        ImageRatioList?.length > 0 ? image?._id : null
                      }
                    />
                  </div>

                  <div className="border border-3 rounded p-2">
                    <ImagePickerAndViewer
                      lableName={"Image"}
                      value={content}
                      handleChange={(e: any) =>
                        onChangeClassContentImg(e, index)
                      }
                      removelabel={true}
                    />
                  </div>
                </div>
              </div>
            ) : contentType === 6 ? (
              <div className="">
                <label className="ms-2 fw-bold mb-2">
                  {contentType === 6 ? "Video" : ""}
                </label>
                <div className="mt-0">
                  <ImagePickerAndViewer
                    lableName={"Video"}
                    value={content}
                    handleChange={(value: any) =>
                      onChangeClassContentVideo(value, index)
                    }
                    doctype={"video"}
                  />
                </div>
              </div>
            ) : contentType === 11 ||
              contentType === 12 ||
              contentType === 13 ||
              contentType === 14 ? (
              <div className="mx-2 mb-2">
                <LabelField
                  lableName={
                    contentType === 11
                      ? "Flip Card"
                      : contentType === 12
                      ? "Accordian"
                      : contentType === 13
                      ? "Tabular Data"
                      : contentType === 14
                      ? "Horizontal Tab"
                      : ""
                  }
                />

                <div>{flipcardData()}</div>

                <div className="d-flex justify-content-end m-2">
                  <Addmorebtn
                    name="Add More"
                    handleClick={() => onPressAddFlipcard(index)}
                  />
                </div>
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <>
      <div className="w-100">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          <h5 className="fw-bold text-light">Class Content</h5>
          <div className="d-flex">
            <div className="d-flex align-items-center">
              <span
                className="position-relative addInputbtn"
                onClick={clickText}
              >
                <div className="cursor ms-2 p-1">+ Text</div>
                <div className="position-absolute notiDiv">
                  <div
                    className="position-absolute transperentbg"
                    onClick={() => setShow(false)}
                  ></div>

                  <div
                    className="position-absolute border p-1 font12"
                    style={{
                      background: "#fff",
                      top: "2px",
                      right: "29px",
                      width: "160px",
                      boxShadow: "black -2px 2px 10px -2px",
                    }}
                  >
                    <div className="px-2">
                      {menuArray?.map((c: any) => (
                        <div
                          className="p-2 addQuestionType"
                          style={{ cursor: "pointer" }}
                          onClick={(id: any) => showInputMenu("text", c?._id)}
                        >
                          <span className="ms-3 colorgray">{c?.name}</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </span>
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("image", id)}
            >
              + Image
            </div>

            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("video", id)}
            >
              + Video
            </div>
          </div>
        </div>

        {renderClassContent()}
      </div>
    </>
  );
}
