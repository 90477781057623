import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import ClassRequestCard from "../../Components/classRequestCard";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import SubmitButton from "../../Components/submitButton";
import moment from "moment";
import { getUpcomingListAdmin } from "../../Redux/Actions/AdminAction";
export default function ClassConducted() {
  const dispatch = useDispatch();
  const [upcomingList, setUpcomingList] = useState<any>([]);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(endDate).subtract(6, "days").format("YYYY-MM-DD")
  );

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    getDemoScheduledList();
  }, []);
  const getDemoScheduledList = () => {
    const postdata = {
      statusId: 9,
      empId: userId,
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getUpcomingListAdmin(
        postdata,
        (res: any) => {
          setUpcomingList(res?.data?.data);
        },
        () => { }
      )
    );
  };
  const overallRating = () => {
    let sum: any = 0;
    for (let i = 0; i < upcomingList.length; i++) {
      sum += upcomingList[i].rating / upcomingList.length;
    }
    return sum;
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoScheduledList();
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"CLASS CONDUCTED"}
        reviewCount={upcomingList.length ? upcomingList.length : "0"}
        overallRating={overallRating()}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="row d-flex flex-wrap mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>
        <div className="row">
          {upcomingList?.map((item: any, index: any) => {
            return (
              <div className="col-md-4  py-5 p-2">
                <ClassRequestCard
                  key={index}
                  data={item}
                  name={"GIVE FEEDBACK"}
                  pageName={"teacherPanelClassConducted"}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
