import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import configurationSidebarData from "./configurationSidebarData";
import WIP from "../wip";
import InstallmentSetting from "./installmentSetting";
import BbbServerTimings from "./bbbServerTimings";
import QuestionConfig from "./questionConfig"; 
import UpdateQuestionConfig from "./updateQuestionConfig"; 
import LectureCharges from "./lectureCharges"; 

export default function ConfigurationPanelDashboard() {
  return (
    <div className='AppBox'>
      <Sidebar data={configurationSidebarData?.ConfigurationSidebarData} />

      <div className='dashboard'>
        <Routes>
          <Route path="/" element={<InstallmentSetting />} />
          <Route path="installmentSetting" element={<InstallmentSetting />} />
          <Route path="bbbServerTimings" element={<BbbServerTimings />} />
          <Route path="questionConfig" element={<QuestionConfig />} /> 
          <Route path="updateQuestionConfig" element={<UpdateQuestionConfig />} />
          <Route path="lectureCharges" element={<LectureCharges />} />
 
          <Route path="*" element={<WIP />} />
        </Routes>
      </div></div>
  )
}
