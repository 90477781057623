import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import {
  getSectionsData,
  updatePartTotalQuestions,
} from "../../Redux/Actions/AdminAction";
import { startsWithSpace, validateDigitOnly } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function AddQuestion() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [examSetData, setExamSetData] = useState<any>();
  const {
    examSetId,
    coursetypeId,
    examtypeId,
    subjectId,
    examType,
    totalQuestion,
    gradeId
  } = location.state;

  useEffect(() => {
    dispatch(
      getSectionsData(
        examSetId,
        (res: any) => {
          setExamSetData(res[0]);
        },
        () => { }
      )
    );
  }, []);

  const onChangeTotal = (value: any, sectionIndex: number, PartIndex: any) => {
    value.preventDefault();
    if (
      validateDigitOnly(value.target.value) &&
      !startsWithSpace(value.target.value)
    ) {
      let localArray = JSON.parse(JSON.stringify(examSetData));
      localArray.sections[sectionIndex].parts[PartIndex].totalQuestion =
        parseInt(value.target.value ? value.target.value : 0);
      setExamSetData(localArray);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };

  const updateTotalQustion = (
    sectionId: number,
    parts: { _id: any; totalQuestion: any }[]
  ) => {
    const postdata = {
      examSetId: examSetId,
      sectionId: sectionId,
      partTotalQuestions: parts?.map(
        (item: { _id: any; totalQuestion: any }) => {
          return {
            PartId: item?._id,
            totalpartQuestion: item?.totalQuestion,
          };
        }
      ),
    };
    dispatch(
      updatePartTotalQuestions(
        postdata,
        (res: any) => {
          setExamSetData(res[0]);
        },
        () => { }
      )
    );
  };

  const routeUpdateSection = (
    examSetName: any,
    section: any,
    part: any,
    partIndex: number
  ) => {
    const data: any = {
      coursetypeId: coursetypeId,
      examtypeId: examtypeId,
      subjectId: subjectId,
      examSetId: examSetId,
      examType: examType,
      examSetName: examSetName,
      selectedSection: section,
      selectedPart: part,
      partIndex: partIndex,
      subTopicId: examSetData?.subTopic?._id,
      topicId: examSetData?.topic?._id,
      examPatternId: examSetData?.examPattern?._id,
      gradeId: gradeId,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    navigate("../questionEditor", { state: data });
  };

  const renderData = () => {
    return examSetData?.sections?.map((data: any, sectionIndex: number) => (
      <>
        <div className="d-flex flex-wrap">
          <div className="col-2">
            <p>
              <span className="fw-bold">Section Name:</span> {data?.name}
            </p>
          </div>
          <div className="col-2">
            <p>
              <span className="fw-bold"> Total No of Questions :</span>{" "}
              {data?.totalQuestion}
            </p>
          </div>
          <div className="col-12 border p-2 mb-2 ms-1">
            <p>Total No Of Questions For:</p>
            <div className="d-flex  flex-wrap">
              {data?.parts?.map((item: any, partIndex: number) => (
                <div className="d-flex align-items-center col-3 mb-2">
                  <p className="fw-bold mb-0">Part {partIndex + 1}</p>
                  <input
                    className="ms-4 form-control w-25"
                    type="text"
                    value={item?.totalQuestion}
                    onFocus={(event) => event.target.select()}
                    onChange={(value) =>
                      onChangeTotal(value, sectionIndex, partIndex)
                    }
                    disabled={examSetData?.modelSet}
                  />
                </div>
              ))}
              {examSetData?.modelSet ? null : (
                <div>
                  <button
                    type="submit"
                    className=" btn btn-outline-primary btn-sm rounded-pill p-2 px-4"
                    onClick={() => updateTotalQustion(data?._id, data?.parts)}
                  >
                    Submit
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        {data?.parts?.map((item: any, partIndex: number) => (
          <div className="d-flex border p-2 mb-2 row col-md-10 ms-1">
            <div className="d-flex align-items-center col-1">
              <p className="fw-bold mb-0">Part {partIndex + 1} </p>
            </div>
            <div className="d-flex align-items-center col-sm-3">
              <p className="fw-bold mb-0">Question:</p>
              <p className="mb-0 ms-3">
                {`${item?.addedQuestions} / ${item?.totalQuestion}`}{" "}
              </p>
            </div>
            <div className="col-sm-2">
              <button
                type="submit"
                className="btn btn-outline-primary btn-sm rounded-pill"
                onClick={() =>
                  routeUpdateSection(
                    examSetData?.examSetId?.name,
                    data,
                    item,
                    partIndex + 1
                  )
                }
                disabled={item?.addedQuestions >= item?.totalQuestion}
              >
                Add Questions
              </button>
            </div>
          </div>
        ))}
      </>
    ));
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE QUESTION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <h6 className="p-4 ps-0 fw-bold">{examSetData?.examSetId?.name}</h6>
        {examSetData?.modelSet ? (
          <h6 className="p-4 ps-0 fw-bold">
            Total Questions :{examSetData?.totalQuestion}
          </h6>
        ) : null}
        {renderData()}
      </div>
    </div>
  );
}
