import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  getExamSetTypeId,
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getQuestionsExamSetList,
  getQuestionsList,
  updateQuestionOrder,
} from "../../Redux/Actions/AdminAction";
import { useNavigate } from "react-router-dom";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import parser from "html-react-parser";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Backdrop from "@mui/material/Backdrop";

export default function QuestionOrder() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any[]>([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [examType, setExamType] = useState<any>("");
  const [examTypeList, setExamTypeList] = useState<any[]>([]);
  const [resetExamType, setResetExamType] = useState(false);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState<any[]>([]);
  const [resetBatch, setResetBatch] = useState(false);
  const [examSetList, setExamSetList] = useState<any[]>([]);
  const [resetExamSet, setResetExamSet] = useState(false);
  const [examSet, setExamSet] = useState<any>("");
  const [questionlist, setQuestionlist] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [examSection, setExamSection] = useState<any[]>([]);
  const [selectedSectionId, setSelectedSectionId] = useState(0);
  const [open, setOpen] = useState(false);
  const [popImg, setPopImg] = useState("");

  useEffect(() => {
    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamTypeList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeExamType = (value: { _id: any; name?: string }) => {
    setExamType(value);
    setResetExamSet(false);
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value);

    if (examType?.name === "DPP") {
      dispatch(
        getGeneralSubjectList(
          {
            examtypeId: value._id,
          },
          (res: any) => {
            setSubjectList(res);
          },

          () => {}
        )
      );
    } else {
      const postData = {
        examtypeId: value._id,
      };
      dispatch(
        getGeneralPackageList(
          postData,
          (res: any) => {
            setBatchList(res);
          },
          () => {}
        )
      );
    }
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setResetSubject(false);
    setExamSetList([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      examSetTypeId: examType?._id,
      subjectId: value._id,
    };
    dispatch(
      getQuestionsExamSetList(
        postData,
        (res: any) => {
          setExamSetList(res);
        },
        () => {}
      )
    );
  };

  const onChangeBatch = (value: { _id: any; name?: string }) => {
    setBatch(value);
    setResetBatch(false);
    setExamSetList([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      examSetTypeId: examType?._id,
      packageId: value._id,
    };
    dispatch(
      getQuestionsExamSetList(
        postData,
        (res: any) => {
          setExamSetList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExamSet = (value: { _id: any; name?: string }) => {
    setExamSet(value);
    setQuestionlist([]);
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      packageId: batch?._id,
      examSetTypeId: examType?._id,
      examSetId: value?._id,
    };
    dispatch(
      getQuestionsList(
        postData,
        (res: any) => {
          setQuestionlist(res[0]?.questionData);
          setExamSection(res[0]?.sections);
          setSelectedSectionId(res[0]?.sections[0]._id);
        },
        () => {}
      )
    );
  };

  const handleAddQuestions = (value: any) => {
    const data = {
      examType: examType,
      coursetypeId: course,
      examtypeId: exam,
      subjectId: subject,
      examSetId: value._id,
    };
    navigate("../addQuestion", { state: data });
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
  };

  const handleDelete = (value: any) => {};

  const changeOrder = (val: string, index: number) => {
    let newArrr = JSON.parse(JSON.stringify(questionlist));
    newArrr[index].updatedOrder = val;
    setQuestionlist(newArrr);
  };

  const getOrderData = (
    questionlist: { examQuestionId: number; updatedOrder: number }[]
  ) => {
    const postdata = questionlist.map(
      (item: { examQuestionId: number; updatedOrder: number }) => {
        return {
          examQuestionId: item?.examQuestionId,
          order: item.updatedOrder,
        };
      }
    );
    return { data: postdata };
  };

  const getData = () => {
    const postData = {
      coursetypeId: course?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      packageId: batch?._id,
      examSetTypeId: examType?._id,
      examSetId: examSet._id,
    };
    dispatch(
      getQuestionsList(
        postData,
        (res: any) => {
          setQuestionlist(res[0]?.questionData);
          setExamSection(res[0]?.sections);
          setSelectedSectionId(res[0]?.sections[0]._id);
        },
        () => {}
      )
    );
  };

  const updateOrder = (reqData: {
    data: { examQuestionId: number; order: number }[];
  }) => {
    dispatch(
      updateQuestionOrder(
        reqData,
        examSet._id,
        (res: any) => {
          getData();
        },
        () => {}
      )
    );
  };

  const changeSingleOrder = (val: {
    examQuestionId: number;
    updatedOrder: number;
  }) => {
    const postData = {
      data: [
        {
          examQuestionId: val?.examQuestionId,
          order: val?.updatedOrder,
        },
      ],
    };
    updateOrder(postData);
  };

  const handleImage = (val: any) => {
    debugger;
    setPopImg(val);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderTable = () => {
    return (
      <table className="w-100 ">
        <thead className="text-center">
          <tr>
            <th className="text-start">
              <label>Question</label>
            </th>
            <th>
              <label>Order</label>
            </th>
            <th>
              <label>updated Order</label>
            </th>
            <th>
              <label>Action</label>
            </th>
          </tr>
        </thead>
        <tbody className="text-center">
          {questionlist?.map((item: any, index: number) => {
            if (selectedSectionId === item?.section?.sectionId) {
              return (
                <tr key={`${item._id} ${index}`}>
                  <td className="text-start">
                    {item?.title?.map(
                      (
                        items: { descType: string; description: string },
                        i: number
                      ) => {
                        return (
                          <div key={i} className="tablecontent">
                            {items?.descType === "text" ? (
                              <div>{parser(items?.description)}</div>
                            ) : (
                              <img
                                className="img-fluid qusimgdesc"
                                src={items?.description}
                                alt={items?.description}
                                key={i}
                                onClick={() => handleImage(items?.description)}
                              />
                            )}
                          </div>
                        );
                      }
                    )}
                  </td>
                  <td>{item?.order}</td>
                  <td>
                    <input
                      type="text"
                      className="form-control"
                      value={item?.updatedOrder}
                      onChange={(e) => changeOrder(e.target.value, index)}
                    />
                  </td>
                  <td style={{ width: "130px" }}>
                    <button
                      type="button"
                      className={`btn ${
                        !item?.updatedOrder || item?.order == item?.updatedOrder
                          ? " btn-secondary"
                          : "btn-primary"
                      }`}
                      onClick={() => changeSingleOrder(item)}
                      disabled={
                        !item?.updatedOrder || item?.order == item?.updatedOrder
                          ? true
                          : false
                      }
                      style={{ fontSize: "10px", width: "90px" }}
                    >
                      Update Order
                    </button>
                  </td>
                </tr>
              );
            }
          })}
        </tbody>
      </table>
    );
  };

  const renderListMode = () => {
    return (
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="col-12 d-flex flex-wrap pt-2">
          <div className="col-6 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamType(value)
                }
                value={examType}
                options={examTypeList}
                Isrequired={true}
                reset={resetExamType}
              />
            </div>
          </div>
          <div className="col-6 d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          <div className="col-6 d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Name "} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Name"
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExam(value)
                }
              />
            </div>
          </div>
          {examType?.name !== "DPP" ? (
            <>
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Batch"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeBatch(value)
                    }
                    value={batch}
                    options={batchList}
                    Isrequired={true}
                    reset={resetBatch}
                  />
                </div>
              </div>
            </>
          ) : (
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
          )}
          <div className="col-6 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Set Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Set Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExamSet(value)
                }
                value={examSet}
                options={examSetList}
                Isrequired={true}
                reset={resetExamSet}
              />
            </div>
          </div>
        </div>

        <div className="d-flex my-2">
          {examSection?.map((item, index) => {
            return (
              <button
                key={item._id}
                className={
                  selectedSectionId === item._id
                    ? "btn btn-dark rounded-pill px-3 me-2"
                    : "btn border rounded-pill px-3 me-2"
                }
                onClick={() => setSelectedSectionId(item._id)}
              >
                <span>
                  {item.name}
                  <br />
                  {item &&
                    item?.parts.map(
                      (data: {
                        questionRangeFrom: any;
                        questionRangeTo: any;
                      }) => {
                        if (data?.questionRangeFrom && data?.questionRangeTo)
                          return `${data?.questionRangeFrom} - ${data?.questionRangeTo} `;
                      }
                    )}
                </span>
              </button>
            );
          })}
        </div>
        {renderTable()}

        <button
          type="button"
          className="btn btn-dark rounded-pill"
          onClick={() => updateOrder(getOrderData(questionlist))}
          style={{ fontSize: "10px", width: "90px" }}
        >
          Update Order
        </button>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"CHNAGE QUESTION ORDER"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {renderListMode()}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="model"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldiv editprofEdit">
            <div className="col d-flex mt-0 justify-content-end ">
              <ClearOutlinedIcon
                className="colorblue cursor"
                onClick={handleClose}
              />
            </div>
            <div className="">
              <img className="img-fluid " src={popImg} alt="images" />
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
