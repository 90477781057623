interface props {
  placeholder: string,
  value?: string | number,
  maxlength?: number,
  minlength?: number,
  onChangeInput: Function,
  isRequired?: boolean,
  disabled?: boolean
  rows?: number,
  cols?: number,
  defaultValue?:string | number
}

export default function InputTextArea({ placeholder, value, maxlength, minlength, onChangeInput, isRequired, disabled ,defaultValue}: props) {
  return (
    <div className="d-flex mb-2 align-items-center">
       <textarea
          className="w-100"
          name="postContent"
          rows={4}
          cols={40}
          onChange={(value) => onChangeInput(value.target.value)}
          maxLength={maxlength}
          minLength={minlength}
          defaultValue={defaultValue} 
          />
              
      {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  )
}
