import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  deleteFeature,
  getCountryList,
  getCourseTypelist,
  getFeatureList,
  getGeneralExamList,
  updateFeature,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { usePagination } from "../../Utils/appConstants";
import { GradeList } from "../../Utils/dataConstant";
import { length_TwoHundredFifty } from "../../Utils/validators";

export default function UpdateFeature() {
  const dispatch = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [featureList, setFeatureList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [feature, setFeature] = useState("");
  const [featureId, setFeatureId] = useState("");

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetExam(true);
    setResetGradeList(true);
    setGrade("");
    const data: any = {
      coursetypeId: value._id,
    };

    dispatch(
      getFeatureList(
        data,
        (res: any) => {
          setFeatureList(res);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };

    dispatch(
      getFeatureList(
        data,
        (res: any) => {
          setFeatureList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const data: any = {
      coursetypeId: courseType?._id,
      examtypeId: value?._id,
    };

    dispatch(
      getFeatureList(
        data,
        (res: any) => {
          setFeatureList(res);
        },
        () => { }
      )
    );
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          setResetGradeList(true);
          setGrade("");
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    const data: any = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade?._id,
      subjectId: value?._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getFeatureList(
        data,
        (res: any) => {
          setFeatureList(res);
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      features: feature,
    };
    dispatch(
      updateFeature(
        postData,
        featureId,
        (res: any) => {
          setisEditMode(false);

          const data: any = {
            coursetypeId: courseType?._id,
            examtypeId: exam,
            gradeId: grade?._id,
            subjectId: subject,
          };
          if (
            data["coursetypeId"] === null ||
            data["coursetypeId"] === undefined ||
            data["coursetypeId"] === ""
          ) {
            delete data["coursetypeId"];
          }
          if (
            data["gradeId"] === null ||
            data["gradeId"] === undefined ||
            data["gradeId"] === ""
          ) {
            delete data["gradeId"];
          }
          if (
            data["examtypeId"] === null ||
            data["examtypeId"] === undefined ||
            data["examtypeId"] === ""
          ) {
            delete data["examtypeId"];
          }
          if (
            data["subjectId"] === null ||
            data["subjectId"] === undefined ||
            data["subjectId"] === ""
          ) {
            delete data["subjectId"];
          }
          dispatch(
            getFeatureList(
              data,
              (res: any) => {
                setFeatureList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setFeatureId(value?.featureId);
    setCourseType(value?.coursetypeId);
    setGrade(value?.gradeId);
    setExam(value?.examTypeId)
    setSubject(value?.subjectId);
    setFeature(value?.features);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteFeature(
        value.featureId,
        () => {

          const data: any = {
            coursetypeId: courseType?._id,
            examtypeId: exam,
            gradeId: grade?._id,
            subjectId: subject,
          };
          if (
            data["coursetypeId"] === null ||
            data["coursetypeId"] === undefined ||
            data["coursetypeId"] === ""
          ) {
            delete data["coursetypeId"];
          }
          if (
            data["gradeId"] === null ||
            data["gradeId"] === undefined ||
            data["gradeId"] === ""
          ) {
            delete data["gradeId"];
          }
          if (
            data["examtypeId"] === null ||
            data["examtypeId"] === undefined ||
            data["examtypeId"] === ""
          ) {
            delete data["examtypeId"];
          }
          if (
            data["subjectId"] === null ||
            data["subjectId"] === undefined ||
            data["subjectId"] === ""
          ) {
            delete data["subjectId"];
          }
          dispatch(
            getFeatureList(
              data,
              (res: any) => {
                setFeatureList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };
  const validation = () => {
    return feature === "";
  };
  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={featureList ? featureList : []}
          pagename={"feature"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", " FEARURE", "ACTION"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="col-10" onSubmit={handleSubmit}>
              <div className="d-flex Justify-content-between mt-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    Isrequired={true}
                    defaultValue={courseTypeList.length > 0 ? courseType : ""}
                    disabled
                  />
                </div>
              </div>
              {courseType?.type === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      options={GradeList}
                      defaultValue={GradeList.length > 0 ? grade : ""}
                      reset={resetGradeList}
                      disabled
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={exam}
                      options={examList}
                      Isrequired={true}
                      defaultValue={examList.length > 0 ? exam : ""}
                      disabled
                    />
                  </div>
                </div>
              )}
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    defaultValue={subjectList.length > 0 ? subject : ""}
                    disabled
                  />
                </div>
              </div>

              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={" Feature"} />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder="Enter  Feature Name"
                    value={feature}
                    onChangeInput={(value: any) => setFeature(value)}
                    maxlength={length_TwoHundredFifty - 1}
                    minlength={3}
                    isRequired
                  />
                </div>
              </div>

              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Save & Update"} validation={validation()} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT FEATURE"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="px-4 mx-0 pt-4">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                reset={resetCourseType}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?._id === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  reset={resetExam}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                reset={resetSubject}
              />
            </div>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
