import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import PaginationView from '../../Components/paginationView';
import PaginationTable from '../../Components/paginationTable';
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from '../../Utils/appConstants';
import { userData } from '../../Utils/dataConstant';
import { useSelector } from 'react-redux';
import { AuthReducerModal } from '../../Modals';
import { useDispatch} from 'react-redux';
import { getUpcomingList } from '../../Redux/Actions/TeacherActions';
export default function UpcomingSchedule() {
  const dispatch = useDispatch();
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [upcomingList ,setUpcomingList]=useState<any>([]);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId
  }));

  let temp = 0;
  temp = page * rowsPerPage + 1;

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    const postData: any = {
      skip: 0,
      limit: rowsPerPage,
    }
    dispatch(
      getUpcomingList(
      
        userId,
         {},
        (res: any) => {
          setUpcomingList(res?.data)
          setCount(res?.count)
        },
        () => { }
      )
    );
  }
 
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    }

    dispatch(
      getUpcomingList(
        
        userId,
        postData,
        (res: any) => {
          setUpcomingList(res?.data)
          setCount(res?.count)
        },
        () => { }
      )
    );
   
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  
  const handleEdit = (value: any) => {

  }

  const handleDelete = (value: any) => {

  }
  const renderListMode = () => {
    return (
      <>
        {userData?.data?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
         {count > 0 ? (
          <PaginationView
            count={count}
            tableData={userData?.data ? userData?.data : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        

        <PaginationTable
          tableData={upcomingList ? upcomingList : []}
          pagename={"upcomingSchedule"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "CLASS DATE",
            "CLASS TIME",
            "BATCH NAME",
            "LECTURE TITLE",
            "CREATED DATE",
             
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />  
      </>
    );
  };
 
 

   

   

  return (
    <div className="contentBox">
      <PageTitle name={"UPCOMING LIVE SCHEDULE"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <p className='fw-bold font24'>My Upcoming Live</p>
        {renderListMode()}
      </div>
    </div>
  )
}

