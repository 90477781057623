import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from '../../Components/paginationView';
import PaginationTable from '../../Components/paginationTable';
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from '../../Utils/appConstants';
import { useDispatch } from "react-redux";
import { filterRoles, getAdminPanelUsers, getDepartment, getUserRoles, updateAdminUser, updateUserStatus } from "../../Redux/Actions/SuperAdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { hasWhiteSpace, length_Five, length_Four, length_Hundred, length_Six, length_Ten, length_Thirty, length_Three, startsWithSpace, validateFullName, validateIsLettersAndDigitsOnly, validateIsNumberOnly, validatePassword, validateUniqueId } from "../../Utils/validators";
import DatePicker from "../../Components/datePicker";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';

export default function UpdateUser() {
  const dispatch: Function = useDispatch();
  const [department, setDepartment] = useState<string>("");
  const [departmentList, setDepartmentList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState("");
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const [employeeId, setEmployeeId] = useState<string>("");
  const _DATA = usePagination(String(count), rowsPerPage);
  const [users, setUsers] = useState([]);
  const [isEditMode, setisEditMode] = useState(false)
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentedit, setDepartmentedit] = useState<string>("");
  const [roleedit, setRoleedit] = useState("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userStatus, setuserStatus] = useState(false)
  const [userID, setuserID] = useState<number>();
  const [changePassword, setChangePassword] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");


  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          setDepartmentList(res)
        },
        () => { }
      )
    );

    dispatch(
      getUserRoles(
        "",
        (res: any) => {
          setRolesList(res)
        },
        () => { }
      )
    );
  }, []);

  const onChangeDepartment = (value: { _id: any }) => {
    setDepartment(value._id);
  };

  const onChangeRoles = (value: { _id: any; name?: string; }) => {
    setRole(value._id)
  }

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number) => {
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    }

    if (employeeId) {
      postData["empCreatedId"] = employeeId
    }

    if (department) {
      postData["departmentId"] = department
    }

    if (role) {
      postData["userRoleId"] = role
    }

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data)
          setCount(res?.count)
        },
        () => { }
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleEdit = (value: any) => {
    setEmployeeName(value?.name);
    setEmployeeId(value?.empCreatedId);
    setDepartmentedit(value?.departmentId?._id)
    setRoleedit(value?.userRoleId?._id)
    setMobileNo(value?.mobile)
    setEmail(value?.email);
    setDateOfJoining(value?.doj)
    setUserName(value?.username)
    setuserStatus(value?.isActive)
    setuserID(value?.empId)
    setUniqueId(value?.uniqueId)
    setisEditMode(true)
  }

  const onChangeDepartmentedit = (value: { _id: any; name?: string; }) => {
    setDepartmentedit(value._id);
    dispatch(
      filterRoles(
        value._id,
        (res: any) => {
          setRolesList(res)
        },
        () => { }
      )
    );
  };

  const onChangeRolesedit = (value: { _id: any; name?: string; }) => {
    setRoleedit(value._id)
  }

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value)
    }
    else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  }

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  }
  const onChangeEmpId = (value: any) => {
    if (validateIsLettersAndDigitsOnly(value)) {
      setEmployeeId(value)
    }
    else {
      dispatch(showErrorSnackbar("Please only Character & Number"));
      return;
    }
  }

  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value)
    }
    else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  }

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value)
    }
    else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  }

  const onchangeUniqueId = (val: string) => {
    if (validateUniqueId(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(
        showErrorSnackbar("Please Character only & space is not allowed") as any
      );
      return;
    }
  };

  const changeToggle = (event: any) => {
    const PostData: any = {
      isActive: event.target.checked
    }
    dispatch(
      updateUserStatus(
        PostData,
        event.target.id,
        (res: any) => {
          const postData: any = {
            skip: 0,
            limit: 10,
          }

          if (employeeId) {
            postData["empCreatedId"] = employeeId
          }

          if (department) {
            postData["departmentId"] = department
          }

          if (role) {
            postData["userRoleId"] = role
          }

          dispatch(
            getAdminPanelUsers(
              postData,
              (res: any) => {
                setUsers(res?.data)
                setCount(res?.count)
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };


  const renderListMode = () => {
    return (
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-md-12 px-4 mx-0 mt-4 d-flex Justify-content-between">
          <div className="col-md-4 d-flex Justify-content-between p-2 ">
            <label className="fw-bold pt-2 me-2">Department</label>
            <CustomDropdown
              lableName="Select Department"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeDepartment(value)
              }
              value={department}
              options={departmentList}
            />
          </div>
          <div className="col-md-4 d-flex Justify-content-between p-2 ">
            <label className="fw-bold pt-2 me-2">Panel Role</label>

            <CustomDropdown
              lableName="Select Panel Role"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeRoles(value)
              }
              value={role}
              options={rolesList}
            />
          </div>
          <div className="col-md-4 d-flex Justify-content-between">
            <LabelField lableName={"Employee ID"} />
            <InputField
              placeholder="Enter Employee ID"
              value={employeeId}
              onChangeInput={(value: any) => onChangeEmpId(value)}
              maxlength={length_Six}
            />
          </div>
        </div>
        <div className="d-flex justify-content-end pe-4 mt-3">
          <SubmitButton name="Submit" />
        </div>
        {users.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={users ? users : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={users ? users : []}
          pagename={"userdata"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Employee Name",
            "Employee ID",
            "Department",
            "Panel Role",
            "Mobile Number",
            "Username",
            "Date Of Joining",
            "Status",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleToggle={(value: any) => changeToggle(value)}
        />
      </form>
    );
  };

  const handleChangePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault()
    setChangePassword(!changePassword)
  }

  const onChangePassword = (value: SetStateAction<string>) => {
    if (validatePassword(value)) {
      setPassword(value)
    }
    else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  }
  const handleClose = () => {
    setChangePassword(false)
  }

  const renderEditMode = () => {
    return (
      <div className="col-7 px-5">
        <form onSubmit={handleupdate}>
          <div className='d-flex mb-2 flex-column p-3'>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee Name"
                  value={employeeName}
                  onChangeInput={(value: any) => onChangeEmpName(value)}
                  maxlength={length_Hundred}
                  minlength={length_Three}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee ID"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee ID"
                  value={employeeId}
                  onChangeInput={(value: any) => onChangeEmpId(value)}
                  maxlength={length_Six}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Department"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Department"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeDepartmentedit(value)
                  }
                  value={departmentedit}
                  options={departmentList}
                  Isrequired={true}
                  defaultValue={departmentedit}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Panel Role"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Panel Role"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeRolesedit(value)
                  }
                  value={roleedit}
                  options={rolesList}
                  Isrequired={true}
                  defaultValue={roleedit}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChangeInput={(value: any) => onChangeMobileNO(value)}
                  maxlength={length_Ten}
                  minlength={length_Six}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Email Address"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Email Address"
                  value={email}
                  onChangeInput={(value: any) => onChangeEmail(value)}
                  maxlength={length_Hundred}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Unique Id"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Unique Id"
                  value={uniqueID}
                  onChangeInput={(value: any) => onchangeUniqueId(value)}
                  maxlength={length_Four - 1}
                  minlength={length_Three - 1}
                  isRequired={true}
                />
              </div>
            </div>
            <div className=" d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Date Of Joining"} />
              </div>
              <div className="col-6">
                <DatePicker
                  value={dateOfJoining}
                  setInputText={(value: string) => setDateOfJoining(value)}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Username"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Username"
                  value={userName}
                  onChangeInput={(value: any) => onChangeUserName(value)}
                  maxlength={length_Thirty}
                  minlength={length_Five}
                />
              </div>
            </div>
            {
              changePassword ?
                <div className="d-flex Justify-content-between  ">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Password"} />
                  </div>
                  <div className="col-6">
                    <InputField
                      placeholder="Enter Password"
                      value={password}
                      onChangeInput={(value: any) => onChangePassword(value)}
                      maxlength={30}
                      minlength={5}
                    />
                  </div>
                  <div className="pt-3">
                    <CancelOutlinedIcon onClick={() => handleClose()} />
                  </div>
                </div>
                :
                <div className="d-flex Justify-content-between  ">
                  <div className="col-2 align-item-center d-flex">
                  </div>
                  <div className=' ps-2 '>
                    <button
                      type="submit"
                      className={"btn btn-primary rounded-pill me-4"}
                      onClick={(e) => handleChangePassword(e)}
                    >Change Password </button>
                  </div>
                </div>
            }
            <div className=" pt-2">
              <ToggleSwitchbtn
                lableName='Active'
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
                isActive={userStatus}
              />
            </div>
          </div>
          <div className='d-flex justify-content-center mt-3'>
            <SubmitButton name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    const postData: any = {
      skip: 0,
      limit: 10,
    }

    if (employeeId) {
      postData["empCreatedId"] = employeeId
    }

    if (department) {
      postData["departmentId"] = department
    }

    if (role) {
      postData["userRoleId"] = role
    }

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data)
          setCount(res?.count)
        },
        () => { }
      )
    );
  }
  var emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
  function allemail(inputtxt: any) {

    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }

  const validation = () => {
    return (
      employeeName === "" ||
      employeeName.length < length_Three - 1 ||
      employeeId === "" ||
      employeeId.length < length_Three - 1 ||
      departmentedit === "" ||
      roleedit === "" ||
      userName === "" ||
      userName.length < length_Five - 1 ||
      mobileNo === "" ||
      mobileNo?.length < length_Ten - 1 ||
      dateOfJoining === "" ||
      !allemail(email)
    );
  }

  const handleupdate = (e: any) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData: any = {
        name: employeeName,
        empCreatedId: employeeId,
        departmentId: departmentedit,
        userRoleId: roleedit,
        username: userName,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        isActive: userStatus,
        uniqueId: uniqueID,

      }
      if (password.length > 0) {
        postData["password"] = password
      }
      dispatch(
        updateAdminUser(
          postData,
          userID,
          (res: any) => {
            setisEditMode(false)
            const postData: any = {
              skip: 0,
              limit: 10,
            }

            if (employeeId) {
              postData["empCreatedId"] = employeeId
            }

            if (department) {
              postData["departmentId"] = department
            }

            if (role) {
              postData["userRoleId"] = role
            }

            dispatch(
              getAdminPanelUsers(
                postData,
                (res: any) => {
                  setUsers(res?.data)
                  setCount(res?.count)
                },
                () => { }
              )
            );
          },
          () => { }
        )
      );
    }
    else {
      dispatch(showErrorSnackbar('Please input valid email'));
      return false;
    }
  }

  return (
    <div className="contentBox">
      <PageTitle name={"EDIT USER"} isEditMode={isEditMode} setisEditMode={setisEditMode} />
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
