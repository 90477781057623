export default {
  InfraSidebarData: [
    {
      title: "Raise Issue",
      subNav: [
        {
          title: "CREATE",
          path: "raiseIssue",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "raiseIssue",
          cName: "sub-nav",
        },
      ],
    },
  


 
   
  ]
};