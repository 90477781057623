import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import PaginationTable from "../../Components/paginationTable";
import { defaultDuration, GradeList } from "../../Utils/dataConstant";
import { useDispatch } from "react-redux";
import Submitbtn from "../../Components/submitButton";
import {
  getGeneralPackageList,
  getPackageSessionList,
} from "../../Redux/Actions/SuperAdminAction";
import {
  bindSubjectList,
  changeActiveStatusExamset,
  deleteExamSet,
  getCountryList,
  getCourseTypelist,
  getExamSetList,
  getExamSetTypeId,
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getPatternList,
  getSubTopicList,
  getTopicList,
  updateExamSet,
} from "../../Redux/Actions/AdminAction";
import { validateSubject } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import TimerClock from "../../Atoms/timerClock";
import { minConvertTime } from "../../Utils/dateFormat";
import DateTimePicker from "../../Components/dateTimePicker";
export default function UpdateExamSet() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [examName, setExamName] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [examSetIdList, setExamSetIdList] = useState<any>([]);
  const [examSetId, setExamSetId] = useState<number>(1);

  const [examSetList, setExamSetList] = useState<any>([]);
  const [batch, setBatch] = useState<any>("");
  const [batchList, setBatchList] = useState([]);
  const [topic, setTopic] = useState<any>("");
  const [topicList, setTopicList] = useState<any[]>([]);
  const [subTopic, setSubTopic] = useState<any>("");
  const [subTopicList, setSubTopicList] = useState([]);
  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [pattern, setPattern] = useState<any>("");
  const [patternList, setPatternList] = useState([]);
  const [dppName, setDppName] = useState<any>("");
  const [examSetName, setExamSetName] = useState<any>("");
  const [examNumber, setExamNumber] = useState<string>("");
  const [duration, setDuration] = useState<any>(defaultDuration);
  const [toggle, setToggle] = useState(false);
  const [examSetEditId, setExamsetEditId] = useState<any>("");
  const [examSetEditList, setExamSetEditList] = useState<any>([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetBatch, setResetBatch] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [resetTopic, setResetTopic] = useState(false);
  const [resetSubTopic, setResetSubTopic] = useState(false);
  const [startdate, setStartdate] = useState<any>("");
  const [enddate, setEnddate] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );

    dispatch(
      getExamSetTypeId(
        {},
        (res: any) => {
          setExamSetIdList(res);
          setExamSetId(res[0]?._id);
        },
        () => { }
      )
    );
  }, []);
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const setTopicData = (res: any) => {
    return res?.map((item: any) => {
      return {
        _id: item?.topicId,
        name: item?.topicName,
      };
    });
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setExamSetEditList(value);
    setExamsetEditId(value?.examSetId);
    setCourseType(value?.coursetype);
    setExamName(value?.examtype);
    setGrade(value?.grades);
    setSubject(value?.subject);
    setTopic(value?.topic);
    setSubTopic(value?.subTopic);
    setBatch(value?.packages);
    setSession(value?.session);
    setPattern(value?.examPattern);
    setDppName(value?.name);
    setExamSetName(value?.examSetName || value?.name);
    setExamNumber(value?.displayNumber);
    setToggle(value?.isActive ?? false);
    setDuration(minConvertTime(value?.examDuration));
    setStartdate(value?.startDate?.slice(0, 19));
    setEnddate(value?.endDate?.slice(0, 19));

    if (examSetId === 1) {
      const data: any = {
        coursetypeId: value?.coursetype?._id,
        examtypeId: value?.examtype?._id,
        subjectId: value?.subject?._id,
        gradeId: value?.grades?._id,
      };
      if (
        data["gradeId"] === null ||
        data["gradeId"] === undefined ||
        data["gradeId"] === ""
      ) {
        delete data["gradeId"];
      }
      if (
        data["examtypeId"] === null ||
        data["examtypeId"] === undefined ||
        data["examtypeId"] === ""
      ) {
        delete data["examtypeId"];
      }
      dispatch(
        getTopicList(
          data,
          (res: any) => {
            setTopicList(setTopicData(res));
          },
          () => { }
        )
      );
      const postdata = {
        coursetypeId: value?.coursetype?._id,
        examtypeId: value?.examtype?._id,
        subjectId: value?.subject?._id,
        topicId: value?.topic?._id,
        gradeId: value?.grades?._id,
      };
      if (
        data["gradeId"] === null ||
        data["gradeId"] === undefined ||
        data["gradeId"] === ""
      ) {
        delete data["gradeId"];
      }
      if (
        data["examtypeId"] === null ||
        data["examtypeId"] === undefined ||
        data["examtypeId"] === ""
      ) {
        delete data["examtypeId"];
      }
      dispatch(
        getSubTopicList(
          postdata,
          (res: any) => {
            setSubTopicList(res);
          },
          () => { }
        )
      );
    }
    if (examSetId == 2) {
      const data = {
        coursetypeId: parseInt(value?.coursetype?._id),
        examtypeId: value?.examtype?._id,
        gradeId: value?.grades?._id,
      };
      if (
        data["gradeId"] === null ||
        data["gradeId"] === undefined ||
        data["gradeId"] === ""
      ) {
        delete data["gradeId"];
      }
      if (
        data["examtypeId"] === null ||
        data["examtypeId"] === undefined ||
        data["examtypeId"] === ""
      ) {
        delete data["examtypeId"];
      }
      dispatch(
        getPatternList(
          data,
          (res: any) => {
            setPatternList(res);
          },
          () => { }
        )
      );
    }
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteExamSet(
        value.examSetId,
        () => {
          getData();
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);

    if (value?._id === 7 && examSetId === 2) {
      dispatch(
        getGeneralPackageList(
          {
            courseId: courseType?._id,
            examtypeId: value?._id,
          },
          (res: any) => {
            setBatchList(res);
          },
          () => { }
        )
      );
    } else {
      dispatch(
        getGeneralExamList(
          {
            courseId: value._id,
          },
          (res: any) => {
            setExamList(res);
          },
          () => { }
        )
      );
    }
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExamName(value);
    const postdata = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };
    dispatch(
      bindSubjectList(
        postdata,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
    if (examSetId === 2 || examSetId === 3) {
      dispatch(
        getGeneralPackageList(
          {
            courseId: courseType?._id,
            examtypeId: value?._id,
          },
          (res: any) => {
            setBatchList(res);
          },
          () => { }
        )
      );
    }
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: any) => {
    setSubject(value);
    const postData: any = {};

    if (courseType != "") {
      postData["coursetypeId"] = courseType?._id;
    }

    if (examName != "") {
      postData["examtypeId"] = examName?._id;
    }

    dispatch(
      getPatternList(
        postData,
        (res: any) => {
          setPatternList(res);
        },
        () => { }
      )
    );
  };

  const onChangeBatch = (value: any) => {
    setBatch(value);
    dispatch(
      getPackageSessionList(
        {
          packageIds: [value._id],
        },
        (res: any) => {
          setSessionList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSession = (value: any) => {
    setSession(value);
  };

  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value);
    const data: any = {
      coursetypeId: courseType?._id,
      examtypeId: examName?._id,
      subjectId: subject?._id,
      gradeId: grade?._id,
      topicId: value?._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setSubTopic(value);
    const postData: any = {};

    if (courseType != "") {
      postData["coursetypeId"] = courseType?._id;
    }

    if (examName != "") {
      postData["examtypeId"] = examName?._id;
    }

    if (grade != "") {
      postData["gradeId"] = grade?._id;
    }

    dispatch(
      getPatternList(
        postData,
        (res: any) => {
          setPatternList(res);
        },
        () => { }
      )
    );
  };

  const onChangePattern = (value: { _id: any; name?: string }) => {
    setPattern(value);
  };

  const onchangeDpp = (value: any) => {
    if (validateSubject(value)) {
      setDppName(value);
    } else {
      dispatch(showErrorSnackbar(" please enter alphabets & digits only"));
      return;
    }
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
    } else {
      setToggle(false);
    }
  };

  const handleOnEdit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let arr = [];
    arr.push(duration.split(":"));
    var minutes = arr[0][0] * 60 + +arr[0][1] + arr[0][2] / 60;

    const postData: any = {
      examSetTypeId: examSetId,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
      examPatternId: pattern?._id,
      DPP_Name: dppName,
      isActive: toggle,
      examDuration: minutes,
      packageId: batch?._id,
      sessionId: session?._id,
      examSetName: examSetName,
      examSetNumber: examNumber,
    };
    if (topic === "" || topic === null) delete postData["topicId"];
    if (subTopic === "" || subTopic === null) delete postData["subTopicId"];
    if (pattern === "" || pattern === null) delete postData["examPatternId"];
    if (dppName === "" || dppName === null) delete postData["DPP_Name"];
    if (batch === "" || batch === null) delete postData["packageId"];
    if (session?._id === "" || session?._id === null)
      delete postData["sessionId"];
    if (examSetName === "" || examSetName === null)
      delete postData["examSetName"];
    if (examNumber === "" || examNumber === null)
      delete postData["examSetNumber"];
    dispatch(
      updateExamSet(
        postData,
        examSetEditId,
        (res: any) => {
          setisEditMode(false);
          const data = {
            coursetypeId: courseType?._id,
            examtypeId: examName?._id,
            subjectId: subject?._id,
            examSetTypeId: examSetId,
            gradeId: grade?._id,
            packageId: batch?._id,
          };
          if (
            data["gradeId"] === null ||
            data["gradeId"] === undefined ||
            data["gradeId"] === ""
          ) {
            delete data["gradeId"];
          }
          if (
            data["examtypeId"] === null ||
            data["examtypeId"] === undefined ||
            data["examtypeId"] === ""
          ) {
            delete data["examtypeId"];
          }
          dispatch(
            getExamSetList(
              data,
              (res: any) => {
                setExamSetList(res);
              },
              () => { }
            )
          );
        },
        () => { }
      )
    );
  };

  const getData = () => {
    const data = {
      coursetypeId: courseType?._id,
      examtypeId: examName?._id,
      subjectId: subject?._id,
      examSetTypeId: examSetId,
      packageId: batch?._id,
      gradeId: grade?._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    if (
      data["packageId"] === null ||
      data["packageId"] === undefined ||
      data["packageId"] === ""
    ) {
      delete data["packageId"];
    }
    if (
      data["subjectId"] === null ||
      data["subjectId"] === undefined ||
      data["subjectId"] === ""
    ) {
      delete data["subjectId"];
    }

    dispatch(
      getExamSetList(
        data,
        (res: any) => {
          setExamSetList(res);
        },
        () => { }
      )
    );
  };

  const onChange = () => {
    getData();
  };


  const onstatusChanged = (event: any, examSetId: any) => {
    const PostData: any = {
      isActive: event?.target?.checked ? event?.target?.checked : false,
    };
    dispatch(
      changeActiveStatusExamset(
        PostData,
        event.target.id,
        (res: any) => {
          getData();
        },
        () => { }
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        <div className="p-4">
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Flag "} />
            </div>
            {examSetIdList.map((c: any, index: any) => {
              return (
                <div className="col-2">
                  <span className="me-3 radiobtnspan">
                    <span>
                      <input
                        type="radio"
                        name="type"
                        id={c._id}
                        checked={c._id === examSetId ? true : false}
                        onChange={(e) => {
                          setExamSetId(c?._id);
                        }}
                      />{" "}
                      <span className="ms-2">{c?.name}</span>
                    </span>
                  </span>
                </div>
              );
            })}
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                Isrequired={true}
                reset={resetCourseType}
              />
            </div>
          </div>
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={examName}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                />
              </div>
            </div>
          )}
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {examSetId === 1 && courseType?._id !== 8 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
          ) : null}

          {examSetId === 2 || examSetId === 3 ? (
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                  reset={resetBatch}
                />
              </div>
            </div>
          ) : null}

          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => onChange()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
        <PaginationTable
          tableData={examSetList ? examSetList : []}
          pagename={"ExamSet"}
          rowsPerPage={0}
          page={0}
          tableHead={[
            "S.N",
            "ID",
            "EXAM SET NAME",
            "TOTAL QUESTION",
            "ACTIVE",
            "DURATION",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          onstatusChanged={(value: any, id: any) => onstatusChanged(value, id)}
        />
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <div className="contentBox">
        <div className="px-4 mx-0 mt-4 formDiv ">
          <form className="col-10" onSubmit={handleOnEdit}>
            <div className="col-10">
              <div className="d-flex Justify-content-between mb-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Flag "} />
                </div>
                {examSetIdList.map((c: any, index: any) => (
                  <>
                    {c._id === examSetId ? (
                      <div className="col-2">
                        <span className="me-3 radiobtnspan">
                          <span>
                            <input
                              type="radio"
                              name="type"
                              id={c._id}
                              checked={c._id === examSetId ? true : false}
                              onChange={(e) => setExamSetId(c?._id)}
                            />{" "}
                            <span className="ms-2">{c?.name}</span>
                          </span>
                        </span>
                      </div>
                    ) : null}
                  </>
                ))}
              </div>

              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    defaultValue={courseType?._id}
                    Isrequired={true}
                    disabled={isEditMode}
                  />
                </div>
              </div>

              {courseType?.type === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Student Grade"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Student Grade"
                      setInputText={(value: any) => onChangeStudentGrade(value)}
                      value={grade}
                      Isrequired={true}
                      options={GradeList}
                      defaultValue={grade?._id}
                      disabled={isEditMode}
                      reset={resetGradeList}
                    />
                  </div>
                </div>
              ) : null}
              {courseType?.type === 2 || courseType?.type === 3 ? null : (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Exam Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Exam Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeExam(value)
                      }
                      value={examName}
                      options={examList}
                      defaultValue={examName?._id}
                      Isrequired={true}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              )}
              {examSetId === 1 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Subject Name"} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Subject Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubject(value)
                      }
                      value={subject}
                      options={subjectList}
                      defaultValue={subjectList?.length > 0 ? subject?._id : ""}
                      Isrequired={true}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              ) : null}

              {/* *******************************************FOR DPP********************************************* */}
              {examSetId === 1 ? (
                <>
                  {" "}
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Topic Name "} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Topic Name "
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTopic(value)
                        }
                        value={topic}
                        options={topicList}
                        defaultValue={topicList?.length > 0 ? topic?._id : ""}
                        Isrequired={true}
                        reset={resetTopic}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Sub-Topic Name "} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Sub-Topic Name "
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSubTopic(value)
                        }
                        value={subTopic}
                        options={subTopicList}
                        defaultValue={
                          subTopicList?.length > 0 ? subTopic?._id : ""
                        }
                        Isrequired={true}
                        reset={resetSubTopic}
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}

              {/* ***********************************************FOR UNIT TEST*********************************************** */}
              {examSetId === 2 || examSetId === 3 ? (
                <>
                  <div className="d-flex Justify-content-between mt-3">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Batch Name"} />
                    </div>

                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Batch Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeBatch(value)
                        }
                        value={batch}
                        options={batchList}
                        defaultValue={batchList?.length > 0 ? batch?._id : ""}
                        Isrequired={true}
                        disabled={isEditMode}
                      />
                    </div>
                  </div>

                  {batchList?.length > 0 && sessionList?.length > 0 ? (
                    <div className="d-flex Justify-content-between mt-3">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Batch Session"} />
                      </div>

                      <div className="col-6 ps-2 ">
                        <CustomDropdown
                          lableName="Select Session"
                          setInputText={(value: {
                            _id: string;
                            name: string;
                          }) => onChangeSession(value)}
                          value={session}
                          options={sessionList}
                          defaultValue={
                            sessionList?.length > 0 ? session?._id : ""
                          }
                        />
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                ""
              )}

              {examSetId === 1 || examSetId === 2 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Pattern Name  "} />
                  </div>
                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Pattern Name "
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangePattern(value)
                      }
                      value={pattern}
                      options={patternList}
                      defaultValue={patternList?.length > 0 ? pattern?._id : ""}
                      Isrequired={true}
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              {examSetId === 1 ? (
                <div className="d-flex Justify-content-between mt-3">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"DPP Name "} />
                  </div>

                  <div className="col-6 ">
                    <InputField
                      placeholder="Enter DPP Name "
                      value={dppName}
                      onChangeInput={(value: any) => onchangeDpp(value)}
                      maxlength={50}
                      minlength={3}
                      isRequired
                    />
                  </div>
                </div>
              ) : (
                ""
              )}
              <div className="d-flex Justify-content-between mt-2">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Model Set Paper"} />
                </div>
                <div className="col-6 ps-2 ">
                  <p>{examSetId === 3 || examSetId === 4 ? "Yes" : "No"}</p>
                </div>
              </div>
              {examSetId === 2 || examSetId === 3 || examSetId === 4 ? (
                <>
                  <div className="d-flex Justify-content-between ">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Exam Set Name "} />
                    </div>

                    <div className="col-6 ">
                      <InputField
                        placeholder="Enter Exam Set Name "
                        value={examSetName}
                        onChangeInput={(value: any) => setExamSetName(value)}
                        maxlength={100}
                        isRequired
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between mt-3">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Exam Display Number"} />
                    </div>

                    <div className="col-6 ">
                      <InputField
                        placeholder="Enter Exam Display Number "
                        value={examNumber}
                        onChangeInput={(value: any) => setExamNumber(value)}
                        maxlength={10}
                        isRequired
                      />
                    </div>
                  </div>
                </>
              ) : (
                ""
              )}
              {examSetId === 4 ? (
                <div className="d-flex">
                  <div className="d-flex Justify-content-between col-6">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Start Date"} />
                    </div>
                    <div className="col-6 d-flex ">
                      <div>
                        <DateTimePicker
                          value={startdate}
                          setInputText={(value: string) => setStartdate(value)}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between col-6">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"End Date"} />
                    </div>
                    <div className="col-6 d-flex ">
                      <div>
                        <DateTimePicker
                          value={enddate}
                          setInputText={(value: string) => setEnddate(value)}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div className="">
                <ToggleSwitchbtn
                  lableName="Active"
                  getToggleval={(value: any) => {
                    changeToggle(value);
                  }}
                  isActive={toggle}
                />
              </div>
              <div className="d-flex Justify-content-between mt-2">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Lecture Duration "} />
                </div>
                <div className="col-6  ">
                  <TimerClock
                    value={duration}
                    Isrequired={true}
                    setInputText={(value: any) => setDuration(value)}
                  />
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Save & Update"} />
            </div>
          </form>
        </div>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"DISPLAY EXAM SET"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
