export default {
  TechncalSidebarData: [
    {
      title: "COURSE EXTEND",
      path: "RegistrationDetail",
    },
    {
      title: "MANUAL ACTIVATION",
      path: "OldManualActivation",
    },

  ]
};