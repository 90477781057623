import { Route, Routes } from "react-router-dom";
import Sidebar from '../../Components/sidebar';
import infraSidebarData from './infraSidebarData';
import RaiseIssue from './raiseIssue';
import WIP from '../wip';
export default function InfraPanelDashboard() {
  return (
    <div className='AppBox'>
      <Sidebar data={infraSidebarData?.InfraSidebarData} />

      <div className='dashboard'>
        <Routes>
          <Route path="/" element={< RaiseIssue />} />
          <Route path="raiseIssue" element={<RaiseIssue />} />
          <Route path="*" element={<WIP />} />
        </Routes>
      </div></div>
  )
}
