import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import CustomDropdown from "../../Components/customDropdown";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import PaginationTable from "../../Components/paginationTable";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "../../Components/addmorebtn";
import Submitbtn from "../../Components/submitButton";
import {
  getGeneralCourseList,
  getGeneralExamList,
  updatePattern,
  deletePatternPart,
  deletePatternSection,
  deleteExamPattern,
  getCalculatorTypes,
  getScholorshipPattern,
  getScholorshipPatternByID,
} from "../../Redux/Actions/AdminAction";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import {
  length_Fifty,
  length_Four,
  length_Hundred,
  length_One,
  length_Six,
  length_Three,
  startsWithSpace,
  validateDigitOnly,
  validateIsLettersAndDigitsSpaceOnly,
  validateMinMaxMarks,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function UpdateScholershipPattern() {
  const initialPart = {
    PartId: "",
    questionRange: "",
    totalQuestion: "",
    correctMarks: "",
    negativeMarks: "",
    questionRangeFrom: "",
    questionRangeTo: "",
  };

  const initialVal = {
    sectionName: "",
    sectionId: "",
    compulsoryFlag: false,
    totalQuestion: "",
    maxAttempt: "",
    updateExamPatternParts: [initialPart],
    time: "",
    questionType: "",
  };

  const dispatch: Function = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState();
  const [isEditMode, setisEditMode] = useState(false);
  const [calculator, setCalculator] = useState<any>("");
  const [calculatorList, setCalculatorList] = useState([]);
  const [patternList, setPatternList] = useState([]);
  const [modelSet, setModelSet] = useState(false);
  const [totalQuestions, setTotalQuestions] = useState<any>("");
  const [sectionArray, setSectionArray] = useState<any>([initialVal]);
  const [patternName, setPatternName] = useState<any>();
  const [patternId, setPatternId] = useState<any>();

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    dispatch(
      getCalculatorTypes(
        "",
        (res: any) => {
          setCalculatorList(res);
        },
        () => {}
      )
    );
  }, []);

  const getData = () => {
    const postData: any = {};
    if (course != "") {
      postData["coursetypeId"] = course;
    }

    if (exam != "") {
      postData["examtypeId"] = exam;
    }

    dispatch(
      getScholorshipPattern(
        postData,
        (res: any) => {
          setPatternList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: any) => {
    setExam(value._id);
  };

  const getModalsetData = (
    sectionArray: {
      sectionName: string;
      sectionId: string;
      updateExamPatternParts: {
        correctMarks: number;
        negativeMarks: number;
        PartId: string;
      }[];
    }[]
  ) => {
    const localArray = sectionArray.map(
      (item: {
        sectionName: string;
        sectionId: string;
        updateExamPatternParts: {
          correctMarks: number;
          negativeMarks: number;
          PartId: string;
        }[];
      }) => {
        return {
          sectionName: item?.sectionName,
          sectionId: item?.sectionId,
          updateExamPatternParts: item?.updateExamPatternParts.map(
            (data: {
              correctMarks: number;
              negativeMarks: number;
              PartId: string;
            }) => {
              if (data?.PartId === "") {
                return {
                  correctMarks: data?.correctMarks,
                  negativeMarks: data?.negativeMarks,
                };
              } else {
                return {
                  PartId: data?.PartId,
                  correctMarks: data?.correctMarks,
                  negativeMarks: data?.negativeMarks,
                };
              }
            }
          ),
        };
      }
    );
    return localArray;
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      examPatternId: patternId,
      examPatternName: patternName,
      coursetypeId: course._id,
      examtypeId: exam._id,
      calculatorTypeId: calculator._id,
      updateExamPatternChild:
        modelSet === true ? sectionArray : getModalsetData(sectionArray),
    };
    if (modelSet === true) {
      postData["totalQuestion"] = totalQuestions;
    } else {
    }

    dispatch(
      updatePattern(
        postData,
        (res: any) => {
          setisEditMode(false);
        },
        () => {}
      )
    );
  };
  const changeModelToggle = (value: any) => {
    if (value.target.checked) {
      setModelSet(true);
    } else {
      setModelSet(false);
    }
  };

  const onPressAddPart = (index: number) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    localArray[index].updateExamPatternParts.push(initialPart);
    setSectionArray(localArray);
  };

  const onPressAddSection = () => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    localArray.push(initialVal);
    setSectionArray(localArray);
  };

  const handleCloseSection = (sectionId: any, index: any) => {
    const newArrr = [...sectionArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setSectionArray(newArrr);
    if (sectionId) {
      dispatch(
        deletePatternSection(
          sectionId,
          (res: any) => {
            getData();
          },
          () => {}
        )
      );
    }
  };

  const handleClosePart = (partId: number, index: any, partIndex: number) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    if (index > -1) {
      localArray[index].updateExamPatternParts.splice(partIndex, 1);
    }
    setSectionArray(localArray);
    if (partId) {
      dispatch(
        deletePatternPart(
          partId,
          (res: any) => {
            getData();
          },
          () => {}
        )
      );
    }
  };

  const onchangeQuestion = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setTotalQuestions(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };
  const OnchangePatternName = (value: any) => {
    if (validateIsLettersAndDigitsSpaceOnly(value) && !startsWithSpace(value)) {
      setPatternName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter only Character & Number"));
      return;
    }
  };

  const onChangeSectionName = (
    value: any,
    index: number,
    type: string,
    partIndex: number
  ) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    if (type == "name") {
      localArray[index].sectionName = value;
      if (
        validateIsLettersAndDigitsSpaceOnly(value) &&
        !startsWithSpace(value)
      ) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter only Character & Number"));
        return;
      }
    }

    if (type == "totalQuestion") {
      localArray[index].totalQuestion = value;

      if (validateDigitOnly(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    }

    if (type == "maxAttempt") {
      localArray[index].maxAttempt = value;
      if (validateDigitOnly(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    }

    if (type == "compulsoryFlag") {
      localArray[index].compulsoryFlag = value.target.checked;
      setSectionArray(localArray);
    }

    if (type == "totalQuestionPart") {
      localArray[index].updateExamPatternParts[partIndex].totalQuestion = value;
      setSectionArray(localArray);
    }

    if (type == "correctMarks") {
      localArray[index].updateExamPatternParts[partIndex].correctMarks = value;
      if (validateMinMaxMarks(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    }

    if (type == "negativeMarks") {
      localArray[index].updateExamPatternParts[partIndex].negativeMarks = value;
      if (validateMinMaxMarks(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    }
  };

  const renderListMode = () => {
    return (
      <>
        <div className="px-1 mx-0 mt-4 formDiv pt-2">
          <div className="col-8 px-3">
            <div className="d-flex Justify-content-between  ">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourse(value)}
                  value={course}
                  options={courseList}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between  ">
              <div className="col-3 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                />
              </div>
            </div>
          </div>
          <div className="pe-5 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={() => getData()}
            >
              Search
            </button>
          </div>
          <PaginationTable
            tableData={patternList ? patternList : []}
            pagename={"Pattern"}
            rowsPerPage={0}
            page={0}
            tableHead={["S.N", "Pattern Name", "Action"]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const getSectionTotalCount = (array: string | any[], value: any) => {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += parseInt(array[i].totalQuestion || 0);
    }
    if (sum > value) {
      return (
        <h6 className="text-danger">
          Section count is exceded by {sum - value}
        </h6>
      );
    } else {
      return null;
    }
  };

  const getTotalPartCount = (sectionArray: any, partArray: any[]) => {
    let partSum = 0;
    let sectionSum = sectionArray?.totalQuestion;
    for (let i = 0; i < partArray.length; i++) {
      partSum += parseInt(partArray[i].totalQuestion || 0);
    }
    if (partSum > sectionSum) {
      return (
        <h6 className="text-danger">
          Part count is exceded by {partSum - sectionSum}
        </h6>
      );
    } else {
      return null;
    }
  };
  const qtype = [
    {
      _id: 1,
      name: "MCQ",
    },
    {
      _id: 4,
      name: "CONTENT",
    },
  ];
  const renderEditMode = () => {
    return (
      <div className="col-12 px-3">
        <form className=" pt-2" onSubmit={handleSubmit}>
          <div className="col-12 d-flex flex-wrap">
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourse(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  disabled={isEditMode}
                  defaultValue={course?._id}
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className=" col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  disabled={isEditMode}
                  defaultValue={exam?._id}
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Model Set"} />
              </div>
              <div className="col-8 removelable">
                <ToggleSwitchbtn
                  getToggleval={(value: any) => {
                    changeModelToggle(value);
                  }}
                  isActive={modelSet}
                />
              </div>
            </div>
            {modelSet === true ? (
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Total Questions"} />
                </div>
                <div className="col-8 pe-3">
                  <InputField
                    placeholder="Enter Total Questions"
                    value={totalQuestions}
                    onChangeInput={(value: any) => onchangeQuestion(value)}
                    maxlength={length_Three - 1}
                    minlength={length_One}
                  />
                </div>
              </div>
            ) : (
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Pattern Name"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Exam Pattern Name"
                    value={patternName}
                    onChangeInput={(value: { _id: String; name: String }) =>
                      OnchangePatternName(value)
                    }
                    maxlength={length_Hundred - 1}
                    minlength={length_Four}
                  />
                </div>
              </div>
            )}
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Calculator Type"} />
              </div>
              <div className="col-8 ">
                <CustomDropdown
                  lableName="Calculator Type"
                  setInputText={(value: any) => setCalculator(value)}
                  value={calculator}
                  options={calculatorList}
                  Isrequired={true}
                  defaultValue={calculator._id}
                />
              </div>
            </div>
          </div>
          <p className="fw-bold">Section Creation</p>
          {sectionArray.map((item: any, index: any) => {
            const {
              sectionId,
              sectionName,
              compulsoryFlag,
              totalQuestion,
              maxAttempt,
              updateExamPatternParts,
              time,
              questionType,
            } = item;
            return (
              <div className="d-flex">
                <div className="col-11 border p-3 mt-2  ">
                  <div className="d-flex flex-wrap">
                    <div className="col-6 d-flex mb-2 align-items-center">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Section Name" + (index + 1)} />
                      </div>
                      <div className="col-8 ">
                        <InputField
                          placeholder={"Section" + (index + 1)}
                          value={sectionName}
                          onChangeInput={(value: string) =>
                            onChangeSectionName(value, index, "name", 0)
                          }
                          maxlength={length_Fifty - 1}
                          minlength={length_Three - 1}
                        />
                      </div>
                    </div>
                    {modelSet === true ? (
                      <>
                        <div className="col-6 d-flex mb-2 align-items-center">
                          <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Compulsory Flag"} />
                          </div>
                          <div className="col-8 removelable">
                            <ToggleSwitchbtn
                              id={index}
                              getToggleval={(value: any) =>
                                onChangeSectionName(
                                  value,
                                  index,
                                  "compulsoryFlag",
                                  0
                                )
                              }
                              isActive={compulsoryFlag}
                            />
                          </div>
                        </div>
                        <div className="col-6 d-flex mb-2 align-items-center">
                          <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Total Question"} />
                          </div>
                          <div className="col-8 ">
                            <InputField
                              placeholder="Enter Total Question For Section"
                              value={totalQuestion}
                              onChangeInput={(value: any) =>
                                onChangeSectionName(
                                  value,
                                  index,
                                  "totalQuestion",
                                  0
                                )
                              }
                              maxlength={length_Three - 1}
                              minlength={length_One}
                            />
                            {getSectionTotalCount(sectionArray, totalQuestions)}
                          </div>
                        </div>
                        <div className="col-6 d-flex mb-2 align-items-center">
                          <div className="col-2 align-item-center d-flex">
                            <LabelField lableName={"Max Attempt"} />
                          </div>
                          <div className="col-8 ">
                            <InputField
                              placeholder="Enter Max Attempt For Section"
                              value={maxAttempt}
                              onChangeInput={(value: any) =>
                                onChangeSectionName(
                                  value,
                                  index,
                                  "maxAttempt",
                                  0
                                )
                              }
                              maxlength={length_Three - 1}
                              minlength={length_One}
                            />
                            {parseInt(maxAttempt) > totalQuestion ? (
                              <h6 className="text-danger">
                                Max attempt exceded by{" "}
                                {parseInt(maxAttempt) - totalQuestion}{" "}
                              </h6>
                            ) : null}
                          </div>
                        </div>
                      </>
                    ) : (
                      ""
                    )}
                    <div className="col-6 d-flex mb-2 align-items-center">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Time"} />
                      </div>
                      <div className="col-8 ">
                        <InputField
                          placeholder={"Enter Time in minutes"}
                          value={time}
                          onChangeInput={(value: any) =>
                            onChangeSectionName(value, index, "time", 0)
                          }
                          maxlength={3}
                          minlength={length_One}
                          onFocus={(e: { target: { select: () => any } }) =>
                            e.target.select()
                          }
                        />
                      </div>
                    </div>
                    <div className="col-6 d-flex mb-2 align-items-center">
                      <div className="col-2 align-item-center d-flex">
                        <LabelField lableName={"Question Type"} />
                      </div>
                      <div className="col-8 ms-2">
                        <CustomDropdown
                          lableName="Select Question Type"
                          setInputText={(value: any) =>
                            onChangeSectionName(
                              value?._id,
                              index,
                              "questionType",
                              0
                            )
                          }
                          value={questionType}
                          defaultValue={questionType}
                          options={qtype}
                          Isrequired={true}
                        />
                      </div>
                    </div>
                  </div>

                  {updateExamPatternParts?.map(
                    (
                      data: {
                        correctMarks: any;
                        negativeMarks: any;
                        totalQuestion: any;
                        questionRange: any;
                        PartId: any;
                        questionRangeTo: any;
                        questionRangeFrom: any;
                      },
                      partIndex: number
                    ) => {
                      let {
                        correctMarks,
                        negativeMarks,
                        totalQuestion,
                        questionRangeFrom,
                        questionRangeTo,
                        PartId,
                      } = data;
                      return (
                        <>
                          <p className="fw-bold">
                            {"Part " + (partIndex + 1)}{" "}
                          </p>
                          <div className="d-flex p-2 p-3">
                            <div className="col-12 border p-2 mt-2">
                              <div>
                                {modelSet === true ? (
                                  <div className="col-6 d-flex mb-2 align-items-center">
                                    <div className="col-2 align-item-center d-flex">
                                      <LabelField
                                        lableName={"Total Question"}
                                      />
                                    </div>
                                    <div className="col-10 d-flex">
                                      <div className="col-5">
                                        <InputField
                                          placeholder={
                                            "Enter Total Question For Part " +
                                            (partIndex + 1)
                                          }
                                          value={totalQuestion}
                                          onChangeInput={(value: any) =>
                                            onChangeSectionName(
                                              value,
                                              index,
                                              "totalQuestionPart",
                                              partIndex
                                            )
                                          }
                                          maxlength={length_Three - 1}
                                          minlength={length_One}
                                        />
                                        {getTotalPartCount(
                                          item,
                                          updateExamPatternParts
                                        )}
                                      </div>

                                      <InputField
                                        placeholder="Question Range"
                                        value={`From: ${questionRangeFrom} - To: ${questionRangeTo}`}
                                        onChangeInput={(value: any) => {}} //onChangeSectionName(value, index, "questionRange", partIndex)}
                                        maxlength={length_Six - 1}
                                        minlength={length_One}
                                        disabled
                                      />
                                    </div>
                                  </div>
                                ) : (
                                  ""
                                )}
                              </div>
                              <div className="d-flex">
                                <div className="col-6 d-flex mb-2 align-items-center">
                                  <div className="col-2 align-item-center d-flex">
                                    <LabelField lableName={"Correct Marks"} />
                                  </div>
                                  <div className="col-8">
                                    <InputField
                                      placeholder={
                                        "Enter Correct Marks For Part" +
                                        (partIndex + 1)
                                      }
                                      value={correctMarks}
                                      onChangeInput={(value: any) =>
                                        onChangeSectionName(
                                          value,
                                          index,
                                          "correctMarks",
                                          partIndex
                                        )
                                      }
                                      maxlength={length_Three - 1}
                                      minlength={length_One}
                                    />
                                  </div>
                                </div>
                                <div className="col-6 d-flex mb-2 align-items-center">
                                  <div className="col-2 align-item-center d-flex">
                                    <LabelField lableName={"Negative Marks"} />
                                  </div>
                                  <div className="col-8 ">
                                    <InputField
                                      placeholder={
                                        "Enter Negative Marks For Part" +
                                        (partIndex + 1)
                                      }
                                      value={negativeMarks}
                                      onChangeInput={(value: any) =>
                                        onChangeSectionName(
                                          value,
                                          index,
                                          "negativeMarks",
                                          partIndex
                                        )
                                      }
                                      maxlength={length_Three - 1}
                                      minlength={length_One}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {updateExamPatternParts?.length === 1 ? null : (
                              <DeleteIcon
                                className="cursor"
                                color="error"
                                fontSize="small"
                                onClick={() =>
                                  handleClosePart(PartId, index, partIndex)
                                }
                              />
                            )}
                          </div>
                        </>
                      );
                    }
                  )}
                  <div className="d-flex justify-content-end pt-2">
                    <Addmorebtn
                      name="+ Add More"
                      handleClick={() => onPressAddPart(index)}
                    />
                  </div>
                </div>
                {sectionArray.length === 1 ? null : (
                  <DeleteIcon
                    className="cursor"
                    color="error"
                    fontSize="small"
                    onClick={() => handleCloseSection(sectionId, index)}
                  />
                )}
              </div>
            );
          })}
          {modelSet === true ? (
            <div className="d-flex justify-content-end pt-2">
              <Addmorebtn
                name="+ Add Section"
                handleClick={onPressAddSection}
              />
            </div>
          ) : (
            ""
          )}
          <div className="d-flex justify-content-center mt-4">
            <Submitbtn name={"Save & Update"} />
          </div>
        </form>
      </div>
    );
  };

  const getPatternData = (data: any) => {
    const {
      examPatternId,
      createExamPatternChild,
      CourseType,
      examName,
      CalculatorTypes,
      totalQuestion,
      examPatternName,
      modelSet,
    } = data;
    setCourse(CourseType);
    setExam(examName);
    setCalculator(CalculatorTypes);
    setTotalQuestions(totalQuestion);
    setPatternName(examPatternName);
    setModelSet(modelSet);
    setPatternId(examPatternId);
    const localArray = createExamPatternChild?.map(
      (item: {
        sectionName: any;
        compulsoryFlag: any;
        totalQuestion: any;
        maxAttempt: any;
        sectionId: any;
        createExamPatternParts: any[];
        time: string;
        questionType: any;
      }) => {
        return {
          sectionName: item?.sectionName,
          compulsoryFlag: item?.compulsoryFlag,
          totalQuestion: item?.totalQuestion,
          maxAttempt: item?.maxAttempt,
          sectionId: item?.sectionId,
          time: item?.time,
          questionType: item?.questionType,
          updateExamPatternParts: item?.createExamPatternParts?.map((data) => {
            return {
              PartId: data?.PartId,
              questionRangeFrom: data?.questionRangeFrom,
              questionRangeTo: data?.questionRangeTo,
              totalQuestion: data?.totalQuestion,
              correctMarks: data?.correctMarks,
              negativeMarks: data?.negativeMarks,
            };
          }),
        };
      }
    );
    setSectionArray(localArray);
    return localArray;
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    dispatch(
      getScholorshipPatternByID(
        value._id,
        (res: any) => {
          getPatternData(res);
        },
        () => {}
      )
    );
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteExamPattern(
        value._id,
        () => {
          getData();
        },
        () => {}
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT SCHOLERSHIP PATTERN "}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
