import React from "react";
import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import CategorySidebarData from "./categorySidebarData";
import CreateExamSyllabus from "./createExamSyllabus";
import UpdateExamSyllabus from "./updateExamSyllabus";
import CreateAboutExam from "./createAboutExam";
import UpdateAboutExam from "./updateAboutExam";
import CreateExamDate from "./createExamDate";
import UpdateExamDates from "./updateExamDates";
import CreateExamDateIcon from "./createExamDateIcon";
import UpdateExamDateIcon from "./updateExamDateIcon";
import CreatePaperAnalysis from "./createPaperAnalysis";
import UpdatePaperAnalysis from "./updatePaperAnalysis";
import CreatePackage from "../DataEntry/createPackage";
import UpdatePackage from "./updatePackage";

import WIP from "../wip";
export default function CategoryPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={CategorySidebarData?.CategorySidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreatePackage />} />
          <Route path="createExamSyllabus" element={<CreateExamSyllabus />} />
          <Route path="updateExamSyllabus" element={<UpdateExamSyllabus />} />
          <Route path="createAboutExam" element={<CreateAboutExam />} />
          <Route path="updateAboutExam" element={<UpdateAboutExam />} />
          <Route path="createExamDate" element={<CreateExamDate />} />
          <Route path="updateExamDates" element={<UpdateExamDates />} />
          <Route path="createExamDateIcon" element={<CreateExamDateIcon />} />
          <Route path="updateExamDateIcon" element={<UpdateExamDateIcon />} />
          <Route path="createPaperAnalysis" element={<CreatePaperAnalysis />} />
          <Route path="updatePaperAnalysis" element={<UpdatePaperAnalysis />} />
          <Route path="createPackage" element={<CreatePackage />} />
          <Route path="updatePackage" element={<UpdatePackage />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
