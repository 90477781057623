import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";

export default function UpgradeStudentLevel() {
  return (
    <div className="contentBox">
      <PageTitle name={"DOWNLOAD JITSI SOFTWARE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="d-flex justify-content-between mt-3">
          <div className="col-6 align-item-center d-flex justify-content-center">
            <LabelField lableName={"Windows"} />
            <a
              href="https://drive.google.com/file/d/1dLvHgAfT5fDadglTRin4aNaUy0OEJErA/view?usp=drive_link"
              target="_blank"
              className="btn btn-dark rounded-pill px-3"
            >
              DOWNLOAD JITSI
            </a>
          </div>
          <div className="col-6 align-item-center d-flex justify-content-center">
            <LabelField lableName={"IOS"} />
            <a
              href="https://drive.google.com/file/d/1oM1oJyDQifEa8le3PbK1JsHToxBc62Vu/view?usp=drive_link"
              target="_blank"
              className="btn btn-dark rounded-pill px-3"
            >
              DOWNLOAD JITSI
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
