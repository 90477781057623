import DemoRequestCard from "../../Components/demoRequestCard";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import {
  getCountryList,
  getCourseTypelist,
  teacherDemoSchedule,
} from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import CustomDropdown from "../../Components/customDropdown";
import SubmitButton from "../../Components/submitButton";
export default function DemoConducted() {
  const dispatch = useDispatch();
  const [demoConductedList, setDemoConductedList] = useState<any>([]);

  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(endDate).subtract(6, "days").format("YYYY-MM-DD")
  );
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    const postData = {
      teacherId: userId,
      startDate: startDate,
      endDate: endDate,
      statusId: [5, 8, 7],
      countryId: country?._id,
    };

    dispatch(
      teacherDemoSchedule(
        postData,
        (res: any) => {
          if (res) {
            setDemoConductedList(res);
          }
        },
        () => { }
      )
    );
  };
  const overallRating = () => {
    let sum: any = 0;
    for (let i = 0; i < demoConductedList.length; i++) {
      sum += demoConductedList[i].rating / demoConductedList.length;
    }
    return sum;
  };


  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetCourse(true);
    const postData = {
      countryId: data?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );

    const postdata = {
      teacherId: userId,
      statusId: [5, 8, 7],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      countryId: data?._id,
    };
    if (course) {
      postdata["coursetypeId"] = course?._id;
    }
    dispatch(
      teacherDemoSchedule(
        postdata,
        (res: any) => {
          if (res) {
            setDemoConductedList(res);
          }
        },
        () => { }
      )
    );
  };
  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postdata = {
      teacherId: userId,
      statusId: [5, 8, 7],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: value?._id,
      countryId: country?._id,
    };
    dispatch(
      teacherDemoSchedule(
        postdata,
        (res: any) => {
          if (res) {
            setDemoConductedList(res);
          }
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      teacherId: userId,
      statusId: [5, 8, 7],
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      countryId: country?._id,
    };
    dispatch(
      teacherDemoSchedule(
        postData,
        (res: any) => {
          if (res) {
            setDemoConductedList(res);
          }
        },
        () => { }
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"DEMO CONDUCTED"}
        reviewCount={demoConductedList.length ? demoConductedList.length : "0"}
        overallRating={overallRating()}
      />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2 pe-3 ">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(startDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => setStartDate(value)}
                // mindate={mindate}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className=" mb-2 pe-3 mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>
        <div className="demoReqCountry col-md-12">
          <div className="d-flex col-md-8">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4  ${selectedIndex === index + 1
                      ? "btn-success"
                      : "btn-secondary"
                      }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-4 d-flex ms-5 align-items-center mt-2">
            <LabelField lableName={"Course type"} />
            <div className="col-md-8">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
                reset={resetCourse}
              />
            </div>
          </div>
        </div>

        {demoConductedList?.length > 0 ? (
          <div className="row">
            {demoConductedList?.map((item: any, index: any) => {
              return (
                <div className="col-md-4 py-2 p-2 mt-4">
                  <DemoRequestCard
                    key={index}
                    data={item}
                    name={"CONDUCTED"}
                    pageName={"teacherPanelDemoConducted"}
                    getDemoReq={getDemoScheduledList}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div className="d-flex  mt-5">
            <h3 className="fw-bold p-1 ">No Record Found</h3>
          </div>
        )}
      </div>
    </div>
  );
}
