import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import PaginationView from '../../Components/paginationView';
import PaginationTable from '../../Components/paginationTable';
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from '../../Utils/appConstants';
import { userData } from '../../Utils/dataConstant';
import { useDispatch } from 'react-redux';
import { useLocation } from "react-router-dom";
import StarIcon from "@mui/icons-material/Star";
import { getUserFeedbackbypackage, getFeedbackforLecture } from '../../Redux/Actions/TeacherActions';
import moment from "moment";

export default function SubjectFeedback() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { details } = location.state
  const [feedbackdetails, setFeedbackdetails] = useState(false)
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [batchFeedbackDataList, setBatchFeedbackDataList] = useState<any>([]);
  const [feedbackforLectureList, setFeedbackforLectureList] = useState<any>([]);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  let temp = 0;
  temp = page * rowsPerPage + 1;

  useEffect(() => {
    dispatch(
      getUserFeedbackbypackage(

        {
          packageId: details?._id,
        },
        (res: any) => {
          setBatchFeedbackDataList(res)
        },
        () => { }
      )
    );

  }, []);

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    }



  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleNavigate = (value: any) => {
    setFeedbackdetails(true)
    dispatch(
      getFeedbackforLecture(
        value?._id,
        (res: any) => {
          setFeedbackforLectureList(res)
        },
        () => { }
      )
    );


  }

  const overallRating = () => {
    let rating = 0;
    batchFeedbackDataList.map((item: any) => {
      rating = rating + item?.overall_rating;
    });
    return rating / batchFeedbackDataList.length;
  };


  const renderListMode = () => {
    return (
      <>
        {userData?.data?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={userData?.data ? userData?.data : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}


        <PaginationTable
          tableData={batchFeedbackDataList ? batchFeedbackDataList : []}
          pagename={"BatchFeedbackDataList"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "LECTURE NAME",
            "SUBJECT NAME",
            "TOPIC NAME",
            "SUBTOPIC NAME",
            "REVIEW COUNT",
            "OVERALL RATING",
            "ACTION"

          ]}
          handleNavigate={(value: any) => handleNavigate(value?.RecordedVideo)}
        />
      </>
    );
  };

  const renderDetailsMode = () => {
    return (
      <>

        <>
          <p className='fw-bold fs-5 ps-3'>Lecture Name: <span className='text-primary'> </span></p>
          {feedbackforLectureList?.map((item: any, index: any) => {
            return (
              <div className="mt-4 shadow p-3  mb-5 bg-body rounded ">
                <div className="d-flex  justify-content-between">
                  <div className="d-flex">
                    <span
                      className="me-3 fw-bold"
                      style={{ color: "#3F51B5" }}
                    >
                      #{index + 1}
                    </span>

                    <div className="me-3 d-flex justify-content-between">
                      <span
                        className="me-1 fw-bold"
                        style={{ color: "#3F51B5" }}
                      >
                        {item?.rating}
                      </span>
                      <StarIcon
                        fontSize="small"
                        style={{ color: "#faaf00" }}
                      />
                    </div>
                  </div>
                  <div>
                    <span>

                      {moment(item?.createdAt).format('DD - MM - YYYY, h:mm:ss a')}
                    </span>
                  </div>
                </div>
                <div className="pt-2">
                  <p> {item?.userComment}</p>
                </div>
              </div>)
          })}

        </>
      </>
    )
  }

  return (
    <div className="contentBox">
      <PageTitle name={"FEEDBACK"}
        reviewCount={batchFeedbackDataList.length ? batchFeedbackDataList.length : "0"}
        overallRating={overallRating()} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        {feedbackdetails !== true ? <p className='fw-bold fs-5 ps-3'>Batch name: <span className='text-primary'>{details?.name}</span></p> : ""
        }
        <div>
          {feedbackdetails === true ? renderDetailsMode() : renderListMode()}</div>
      </div>
    </div>
  )
}

