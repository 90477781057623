import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import {
  ReScheduleDemo,
  alignDemo,
  getOtherTeacherList,
  getPreferTeacherList,
} from "../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Addmorebtn from "./addmorebtn";
import DemoSchedulePopup from "./demoSchedulePopup";
import DemoReSchedulePopup from "./demoReSchedulePopup";
import DemoCancelPopup from "./demoCancelPopup";
import moment from "moment";
import { demoTimeList } from "../Utils/dataConstant";
import RatingStar from "./ratingStar";
import DemoFeedBackPopup from "./demoFeedbackPopup";

interface props {
  open: boolean;
  handleClose: Function;
  getDemoReq: Function;
  data?: any;
  showEditSchedule?: any;
  showDemoSchedule?: any;
  pageName?: any;
}

export default function DemoPopup(props: props) {
  const dispatch = useDispatch();
  const [teacher, setTeacher] = useState<any>("");
  const [date, setDate] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [rating, setRating] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);
  const [otherTeacher, setOtherTeacher] = useState<any>("");
  const [otherTeacherList, setOtherTeacherList] = useState<any>([]);
  const [resetOtherTeacher, setResetOtherTeacher] = useState(false);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [clickOther, setClickOther] = useState(false);
  const [openReSchedulePopup, setOpenReSchedulePopup] = useState(false);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [openFeedBackPopup, setOpenFeedBackPopup] = useState(false);

  const { open, handleClose, data, pageName, getDemoReq } = props;

  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  useEffect(() => {
    if (data) {
      setDate(data?.date);

      setTime(data?.time);
      setRating({ id: props?.data?.rating, value: labels[props?.data?.rating] });
      const postData = {
        date: data?.date,
        time: data?.time,
        timezoneId: data?.timezoneId,
        countryId: data?.countryId,
        subjectId: data?.subjectId,
      };
      dispatch(
        getPreferTeacherList(
          postData,
          (res: any) => {
            setTeacherList(res);
          },
          () => { }
        )
      );
    }
  }, [data]);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
    getDemoReq();
    setClickOther(false);
  }

  const onChangeOtherTeacher = (value: any) => {
    setOtherTeacher(value?.empId);
  };
  const onChangeTeacher = (value: any) => {
    setTeacher(value?._id);
  };
  const handleScheduleClose = (value: any) => {
    setOpenSchedulePopup(false);
  };
  const handleReScheduleClose = (value: any) => {
    setOpenReSchedulePopup(false);
  };
  const handleCancelClose = (value: any) => {
    setOpenCancelPopup(false);
  };
  const handleFeedBackClose = (value: any) => {
    setOpenFeedBackPopup(false);
  };
  const onPressEditSchedule = (value: any) => {
    setOpenSchedulePopup(true);
  };
  const onPressFeedBack = (value: any) => {
    setOpenFeedBackPopup(true);
  };
  const onClickOther = (value: any) => {
    setClickOther(true);
    setTeacher("");
    setResetTeacher(true);
    const postData = {
      timezoneId: data?.timezoneId,
      countryId: data?.countryId,
      subjectId: data?.subjectId,
    };
    dispatch(
      getOtherTeacherList(
        postData,
        (res: any) => {
          setOtherTeacherList(res);
        },
        () => { }
      )
    );
  };
  const onClickRescheduleBtn = (value: any) => {
    setOpenReSchedulePopup(true);
  };
  const onClickCancelBtn = (value: any) => {
    setOpenCancelPopup(true);
  };
  const convertTimeTo24HourFormat = (time: any) => {
    const [hour, minute] = time.split(" ");
    let hourNumber = parseInt(hour);
    if (hourNumber === 12) {
      hourNumber = 0;
    }
    if (time.toLowerCase().includes("pm")) {
      hourNumber += 12;
    }
    const formattedHour = hourNumber.toString().padStart(2, "0");
    return `${formattedHour}:00:00`;
  };

  const handleSaverClose = (value: any) => {
    setDate(value.startDate);
    setTime(value.demoTime);
    const postData = {
      date: value.startDate,
      // time: convertTimeTo24HourFormat(value.demoTime?.name),
      time: value.demoTime,
      timezoneId: data?.timezoneId,
      countryId: data?.countryId,
      subjectId: data?.subjectId,
    };
    dispatch(
      getPreferTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res);
        },
        () => { }
      )
    );
    setOpenSchedulePopup(false);
  };

  const onClickAlignTeacher = (value: any) => {
    const postData = {
      demoReqId: data?.demoReqId,
      firstName: data?.firstName,
      lastName: data?.lastName,
      email: data?.email,
      parentEmail: data?.parentEmail,
      mobile: data?.mobile,
      currentSchool: data?.currentSchool,
      teacherId: teacher ? teacher : otherTeacher,
      gradeId: data?.gradeId,
      countryCode: data?.countryCode,
      countryName: data?.countryName,
      countryId: data?.countryId,
      date: date,
      time: time,
      timezoneId: data?.timezoneId,
      parentName: data?.parentName,
      examtypeId: data?.examtypeId,
      coursetypeId: data?.coursetypeId,
      subjectId: data?.subjectId,
      statusId: 2,
      sourceId: data?.sourceId,
      dsaId: data?.dsaId,
      refId: data?.refId,
      companyId: data?.companyId
    };
    if (
      postData["firstName"] === null ||
      postData["firstName"] === undefined ||
      postData["firstName"] === ""
    ) {
      delete postData["firstName"];
    }
    if (
      postData["lastName"] === null ||
      postData["lastName"] === undefined ||
      postData["lastName"] === ""
    ) {
      delete postData["lastName"];
    }
    if (
      postData["currentSchool"] === null ||
      postData["currentSchool"] === undefined ||
      postData["currentSchool"] === ""
    ) {
      delete postData["currentSchool"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["parentEmail"] === null ||
      postData["parentEmail"] === undefined ||
      postData["parentEmail"] === ""
    ) {
      delete postData["parentEmail"];
    }
    if (
      postData["subjectId"] === null ||
      postData["subjectId"] === undefined ||
      postData["subjectId"] === ""
    ) {
      delete postData["subjectId"];
    }
    if (
      postData["dsaId"] === null ||
      postData["dsaId"] === undefined ||
      postData["dsaId"] === ""
    ) {
      delete postData["dsaId"];
    }
    if (
      postData["refId"] === null ||
      postData["refId"] === undefined ||
      postData["refId"] === ""
    ) {
      delete postData["refId"];
    }
    dispatch(
      alignDemo(
        postData,
        (res: any) => {
          handleClose();
          getDemoReq();
          setResetTeacher(true);
          setResetOtherTeacher(true);
          setDate("");
          setTime("");
        },
        () => { }
      )
    );
  };

  function tConvert(time: any) {
    time = time
      .toString()
      .match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];

    if (time.length > 1) {
      time = time.slice(1);
      time[5] = +time[0] < 12 ? "AM" : "PM";
      time[0] = +time[0] % 12 || 12;
    }
    return time.join("");
  }

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        // onClose={() => {
        //   handleClose();
        // }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="demomodeldivChat ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Demo Booking</h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCard mt-0">
              <div className="row mt-2">
                <div className="col-md-7">
                  <h4 className="fw-bold">{data?.gradedesc}</h4>
                  {data?.parentName ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Parent Name:</div>
                      <div className="font16 mb-2 ms-2">{data?.parentName}</div>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Student Name:</div>
                    <div className="font16 mb-2 ms-2">
                      {" "}
                      {`${data?.firstName} ${data?.lastName}`}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Phone:</div>
                    <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Email:</div>
                    <div className="font16 mb-2 ms-2"> {data?.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Course Type:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.coursetypeName}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Country:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.countryName} ({data?.timezone})
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Subject Name:</div>
                    <div className="font16 mb-2 ms-2">{data?.subjectName}</div>
                  </div>

                  {props?.showDemoSchedule === true ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Teacher Name:</div>
                      <div className="font16 mb-2 ms-2">
                        {data?.teacherName}
                      </div>
                    </div>
                  ) : null}
                  {props?.showDemoSchedule === true &&
                    pageName === "salesPanelDemoConducted" || pageName === "teacherPanelDemoConducted" ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">
                        Demo Date & Time:{" "}
                        {`${moment(data?.date).format("DD-MM-YYYY")} 
              ${tConvert(data?.time)}
              ${data?.timezone}`}
                      </div>
                    </div>
                  ) : null}
                  {props?.showDemoSchedule === true &&
                    pageName === "salesPanelDemoConducted" || pageName === "teacherPanelDemoConducted" ? (
                    <div className="d-flex">
                      <div className="d-flex font16 mb-2 fw-bold">
                        <span>Rating:</span>
                        <div>
                          <RatingStar
                            value={rating?.id}
                            label={labels}
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  ) : null}
                </div>
                {pageName === "salesPanelDemoRequest" ||
                  pageName === "salesPanelDemoSchedule" ||
                  pageName === "salesPanelDemoCancel" ||
                  pageName === "teacherPanelDemoSchedule" ? (
                  <div className="col-md-5 ">
                    <h4 className="px-5 ms-5">
                      {moment(date).format("DD-MM-YYYY")}
                    </h4>

                    <div className="font16 px-5 ms-5 ">{time}</div>
                    {props?.showEditSchedule === true ? (
                      <div className="d-flex justify-content-center mt-2 ms-2">
                        <Addmorebtn
                          name="Time Update"
                          handleClick={onPressEditSchedule}
                        />
                      </div>
                    ) : null}
                  </div>
                ) : null}
                {pageName === "salesPanelDemoConducted" || pageName === "teacherPanelDemoConducted" ? (
                  <div className="col-md-5">
                    <h4 className="px-5 ms-5">Feedback</h4>
                    <div className="d-flex justify-content-center mt-2 ms-2">
                      <Addmorebtn
                        name="View Feedback"
                        handleClick={onPressFeedBack}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="col-md-8  border-top align-items-center">
                {pageName === "salesPanelDemoRequest" ? (
                  <>
                    <div className="col-md-7 d-flex mb-0 align-items-center">
                      <div className="col-7 align-item-center d-flex">
                        <LabelField lableName={"Prefered Teacher"} />
                      </div>
                      <div className="col-9 ps-2 justify-content-between">
                        <CustomDropdown
                          lableName="Select Teacher Name"
                          setInputText={(value: any) => onChangeTeacher(value)}
                          value={teacher}
                          options={teacherList}
                          reset={resetTeacher}
                          disabled={clickOther === true}
                          type={"teacher"}
                        />
                      </div>

                      <div className="p-4">
                        <button
                          type="button"
                          className="btn btn-dark mb-0 align-items-center searchButton"
                          onClick={onClickOther}
                        >
                          Other
                        </button>
                      </div>
                    </div>

                    {clickOther ? (
                      <div className=" d-flex mb-2 align-items-center">
                        <div className="col-4 align-item-center d-flex">
                          <LabelField lableName={"Other Teacher"} />
                        </div>
                        <div className="col-8 ps-2">
                          <CustomDropdown
                            lableName="Select Teacher Name"
                            setInputText={(value: any) =>
                              onChangeOtherTeacher(value)
                            }
                            value={otherTeacher}
                            options={otherTeacherList}
                            reset={resetOtherTeacher}
                            type={"teacher"}
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}

                {props?.showEditSchedule === true ? (
                  <div className=" d-flex justify-content-center mt-2">
                    <button
                      type="button"
                      className="btn btn-dark mb-3 align-items-center searchButton mt-2"
                      onClick={onClickAlignTeacher}
                    >
                      Align Teacher
                    </button>
                  </div>
                ) : null}
              </div>
            </div>
            {props?.showDemoSchedule === true ? (
              <div className="d-flex col-md-12 mt-2">
                <div className="col-md-6 d-flex justify-content-center mt-2">
                  <Addmorebtn
                    name="Re-Schedule"
                    handleClick={onClickRescheduleBtn}
                  />
                </div>
                {pageName === "salesPanelDemoSchedule" ? (
                  <div className="col-md-6 d-flex justify-content-center mt-2">
                    <Addmorebtn
                      name="Cancel Schedule"
                      handleClick={onClickCancelBtn}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        </Fade>
      </Modal>
      <DemoSchedulePopup
        open={openSchedulePopup}
        handleClose={handleScheduleClose}
        data={data}
        getDateTime={(value: any) => handleSaverClose(value)}
        pageName={pageName}
      />
      <DemoReSchedulePopup
        open={openReSchedulePopup}
        handleClose={handleReScheduleClose}
        data={data}
        getDateTime={(value: any) => handleSaverClose(value)}
        pageName={pageName}
      />
      <DemoCancelPopup
        open={openCancelPopup}
        handleClose={handleCancelClose}
        data={data}
      />
      <DemoFeedBackPopup
        open={openFeedBackPopup}
        handleClose={handleFeedBackClose}
        data={data}
      />
    </>
  );
}
