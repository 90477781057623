// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demoRequestCard {
  /* padding: 10px; */
  height: 100%;
}
.DemoRecordgrade {
  color: #fff;
  background-color: #17a2b8;
  width: 25%;
}
.DemoRecordcourseType {
  color: #fff;
  background-color: #b86217;
  width: 40%;
}
`, "",{"version":3,"sources":["webpack://./src/Assets/css/demoRequestCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;EACnB,YAAY;AACd;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,UAAU;AACZ;AACA;EACE,WAAW;EACX,yBAAyB;EACzB,UAAU;AACZ","sourcesContent":[".demoRequestCard {\n  /* padding: 10px; */\n  height: 100%;\n}\n.DemoRecordgrade {\n  color: #fff;\n  background-color: #17a2b8;\n  width: 25%;\n}\n.DemoRecordcourseType {\n  color: #fff;\n  background-color: #b86217;\n  width: 40%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
