import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  deleteTopic,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getTopicEditData,
  getTopicList,
  updateTopic,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import PaginationTable from "../../Components/paginationTable";
import { GradeList, levelList } from "../../Utils/dataConstant";
import { length_Hundred, length_TwoHundredFifty } from "../../Utils/validators";

export default function UpdateTopic() {
  const dispatch = useDispatch();
  const [isEditMode, setisEditMode] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [courseType, setCourseType] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [level, setLevel] = useState("");
  const [topic, setTopic] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [priorityOrder, setPriorityOrder] = useState<any>("");
  const [topicId, setTopicId] = useState<any>("");

  const [countryList, setCountryList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [topicList, setTopicList] = useState([]);

  const [resetCountry, setResetCountry] = useState(false);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetGrade, setResetGrade] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetLevel, setResetLevel] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const getData = (subjectId: any) => {
    const data: any = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      gradeId: grade,
      subjectId: subjectId,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getTopicList(
        data,
        (res: any) => {
          setTopicList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountry(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    setResetExam(true);
    setResetGrade(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value?._id);
    setResetGrade(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          setResetGrade(true);
          setGrade("");
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    getData(value._id);
  };
  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setLevel(value._id);
    setResetLevel(false);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData = {
      topicName: topic,
      priorityOrder: priorityOrder,
    };
    dispatch(
      updateTopic(
        postData,
        topicId,
        () => {
          setisEditMode(false);
          getData(subject);
        },
        () => {}
      )
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setTopicId(value?._id);
    dispatch(
      getTopicEditData(
        value?._id,
        (res: any) => {
          setLevel(res[0]?.levelId);
          setTopic(res[0]?.topicName);
          setPriorityOrder(res[0]?.priorityOrder);
        },
        () => {}
      )
    );
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteTopic(
        value._id,
        () => {
          getData(subject);
        },
        () => {}
      )
    );
  };
  const validation = () => {
    return topic === "" || priorityOrder === "";
  };
  const renderListMode = () => {
    return (
      <div className="p-4">
        <PaginationTable
          tableData={topicList ? topicList : []}
          pagename={"topic"}
          tableHead={[
            "S.N",
            " TOPIC NAME",
            "PRIORITY ORDER",
            "LEVEL",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </div>
    );
  };
  const renderEditMode = () => {
    return (
      <>
        <div className="px-4">
          <form className="" onSubmit={handleSubmit}>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Level"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Level"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeLevel(value)
                  }
                  value={level}
                  options={levelList}
                  Isrequired={true}
                  defaultValue={levelList?.length > 0 ? level : ""}
                  reset={resetLevel}
                  disabled
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={" Topic Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter  Topic Name"
                  value={topic}
                  onChangeInput={(value: any) => setTopic(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={3}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={" Priority Order"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Priority Order"
                  value={priorityOrder}
                  onChangeInput={(value: any) => setPriorityOrder(value)}
                  maxlength={length_Hundred - 1}
                  isRequired
                />
              </div>
            </div>

            <div className="d-flex justify-content-center mt-3">
              <Submitbtn name={"Save & Update"} validation={validation()} />
            </div>
          </form>
        </div>
      </>
    );
  };

  const renderSearchMode = () => {
    return (
      <div className="px-4 pt-4">
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Country"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Country"
              setInputText={(value: any) => onChangeCountry(value)}
              value={country}
              options={countryList}
              Isrequired={true}
              reset={resetCountry}
              disabled={isEditMode}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between mt-0">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
          </div>
          <div className="col-6 ps-2  ">
            <CustomDropdown
              lableName="Select Course Type"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeCourseType(value)
              }
              value={courseType}
              options={courseTypeList}
              Isrequired={true}
              defaultValue={courseTypeList.length > 0 ? courseType : ""}
              disabled={isEditMode}
              reset={resetCourseType}
            />
          </div>
        </div>
        {courseType?.type === 2 ? (
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Student Grade"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Student Grade"
                setInputText={(value: any) => onChangeStudentGrade(value)}
                value={grade}
                options={GradeList}
                defaultValue={GradeList.length > 0 ? grade : ""}
                reset={resetGrade}
                Isrequired={true}
                disabled={isEditMode}
              />
            </div>
          </div>
        ) : null}
        {courseType?.type === 2 || courseType?.type === 3 ? null : (
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Exam Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeExam(value)
                }
                value={exam}
                options={examList}
                Isrequired={true}
                defaultValue={examList.length > 0 ? exam : ""}
                disabled={isEditMode}
                reset={resetExam}
              />
            </div>
          </div>
        )}
        <div className="d-flex Justify-content-between">
          <div className="col-2 align-item-center d-flex">
            <LabelField lableName={"Subject Name"} />
          </div>
          <div className="col-6 ps-2 ">
            <CustomDropdown
              lableName="Select Subject Name"
              setInputText={(value: { _id: string; name: string }) =>
                onChangeSubject(value)
              }
              value={subject}
              options={subjectList}
              Isrequired={true}
              defaultValue={subjectList.length > 0 ? subject : ""}
              disabled={isEditMode}
              reset={resetSubject}
            />
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT TOPIC"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {renderSearchMode()}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
