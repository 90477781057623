import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import AdminTable from '../../Components/adminTable';
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import CustomDropdown from "../../Components/customDropdown";
import { deleteMainCategory, getMainCategoryEditData, getMainCategoryList, getSessionList, getSubcategoryList, updateMainCategory } from '../../Redux/Actions/AdminAction';
import { useDispatch } from 'react-redux';
import { startsWithSpace, validateFullName } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function UpdateMainCategory() {
  const dispatch: Function = useDispatch();
  const [category, setCategory] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>();
  const [isSession, setIsSession] = useState<any>(false);
  const [subcategoryList, setSubcategoryList] = useState<any>([]);
  const [primaryType, setPrimaryType] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
  const [eventValue, setEventValue] = useState<any>(null);
  const [renderUseEffect, setRenderUseEffect] = useState(false);
  const [primaryTypeList, setPrimaryTypeList] = useState<any>([]);
  const [resetPrimaryType, setResetPrimaryType] = useState(false);
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [sessionList, setSessionList] = useState<any>([]);
  const [sessionArray, setSessionArray] = useState<any>([]);
  const [mainCategoryId, setMainCategoryId] = useState<any>("");
  const [mainCategoryArr, setMainCategoryArr] = useState<any>("");
  const [mainCategoryEditData, setMainCategoryEditData] = useState<any>([]);
  const [sessionListEdit, setSessionListEdit] = useState([]);

  useEffect(() => {
    updatedata();
  }, []);

  const updatedata = () => {
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
        },
        () => { }
      )
    );
  };

  useEffect(() => {
    dispatch(
      getSubcategoryList(
        "",
        (res: any) => {
          setSubcategoryList(res)
        },
        () => { }
      )
    );
    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res)
        },
        () => { }
      )
    );
  }, []);

  useEffect(() => {
    if (renderUseEffect) {
      if (eventValue?.checked) {
        setPrimaryTypeList([
          ...primaryTypeList,
          { _id: eventValue?.id, name: eventValue?.value },
        ]);
        setRenderUseEffect(false);
      } else if (!eventValue?.checked) {
        let filterptype = primaryTypeList.filter(
          (item: any, index: any) => item._id !== eventValue?.id
        );
        setPrimaryTypeList(filterptype);
        setRenderUseEffect(false);
      }
    }
  }, [renderUseEffect]);

  const handleEdit = (value: any) => {
    setIsEdit(true);
    setSessionArray([]);
    setMainCategoryId(value?._id);
    setMainCategoryArr(value);
    setSessionListEdit(value?.sessionIds)
    dispatch(
      getMainCategoryEditData(
        value?._id,
        (res: any) => {
          setIsEdit(true);
          setMainCategoryEditData(res);
          setCategory(res?.maincategoryName);
          setIsSession(res?.isSession);
          setPrimaryType(res?.primaryType);
          let filterArray = res?.subcategoryIds?.filter((data: any) => data.isSelected)
          setPrimaryTypeList(filterArray)
          setSessionListEdit(res?.sessionIds)
          res?.sessionIds?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = sessionArray;
            if (item?.isSelected) {
              newArrr.push(item._id);
            }
            setSessionArray(newArrr);
          });


        }, () => { }
      )
    );
  }

  const handleDelete = (value: any) => {
    dispatch(
      deleteMainCategory(
        value._id,
        () => {
          updatedata();
        },
        () => { }
      )
    );

  }

  const handleOnEdit = (e: any) => {
    e.preventDefault();

    const postData: any = {
      sessionIdArray: isSession ? sessionArray : [],
      //sessionIdArray: sessionArray,
      isSession: isSession
    }
    if (mainCategoryArr?.name != category) {
      postData["name"] = category
    }
    if (mainCategoryArr?.isSession != isSession) {
      postData["isSession"] = isSession
    }
    if (mainCategoryArr?.primaryType._id != primaryType?._id) {
      postData["primaryType"] = primaryType?._id
    }

    setRenderUseEffect(true);

    dispatch(
      updateMainCategory(
        postData,
        mainCategoryId,
        (res: any) => {
          setIsEdit(false);
          updatedata();
          setSessionArray([])

        }, () => { }
      )
    );


  };

  const sessionChange = (event: any) => {
    if (event.target.checked === true) {
      setIsSession("true");
    } else {
      setIsSession("false");
    }
  };

  const onSelectSubCategory = (event: any, index: any, c: any) => {
    if (mainCategoryEditData.subcategoryIds[index]["isSelected"] = event.target.checked) {
      selectedSubCategory.push(event.target.id);
      setEventValue(event.target);
      setRenderUseEffect(true);
      setSelectedSubCategory(selectedSubCategory);
      setMainCategoryEditData(mainCategoryEditData);
    }

  };
  const onChangeCategory = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setCategory(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }
  const onChangePrimaryType = (value: any) => {
    setPrimaryType(value);
    setResetPrimaryType(false)
  };

  const changeSessionName = (event: any, data: any, index: any) => {

    let newArrr = [];
    newArrr = sessionArray;
    if (event.target.checked === true) {
      newArrr.push(parseInt(event.target.id));
    } else {
      newArrr.splice(newArrr.indexOf(parseInt(event.target.id)), 1);
    }
    setSessionArray(newArrr);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"EDIT MAIN CATEGORY"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        {isEdit !== true ? <AdminTable
          tableData={mainCategoryList ? mainCategoryList : []}
          pagename={"UpdateMainCategory"}
          tableHead={["S.N", "Main Category", "Session Flag", "Primary Type", "Sub Category", "Action"]}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
          edit={true}
        /> : ""}

        {isEdit === true ? <div>
          <form className='' onSubmit={handleOnEdit}>

            <div className="col-7">
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Main Category Name"} />
                </div>
                <div className="col-8">
                  <InputField
                    placeholder="Enter Exam Name"
                    value={category}
                    onChangeInput={(value: any) => onChangeCategory(value)}
                    maxlength={100}
                    minlength={2}
                  />
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Session Flag"} />
                </div>

                <div className="d-flex align-items-center ps-2">
                  <input
                    type="checkbox"
                    name="Session Flag"
                    value={isSession}
                    onChange={sessionChange}
                    checked={isSession === "true" ? true : false}
                  //defaultChecked={isSession==="true"?true:false}
                  />
                  <span className="ms-2">
                    <b>Session Flag</b>
                  </span>
                </div>

              </div>

              {isSession === "true" ? (
                <div className="d-flex align-items-center my-2">
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Session Name"} />
                  </div>
                  <div className="d-flex align-items-center">
                    {sessionListEdit.map((data: any, index: any) => {
                      return (
                        <div className="d-flex me-3" key={index}>
                          <input
                            type="checkbox"
                            name="Session Flag"
                            id={data._id}
                            value={data.name}
                            defaultChecked={data?.isSelected}
                            onChange={(e) => changeSessionName(e, data, index)}
                          />
                          <span className="ms-2">
                            <b>{data.name}</b>
                          </span>
                        </div>
                      );
                    })}
                  </div>
                </div>
              ) : null}
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Sub Category"} />
                </div>
                <div className="col-8 ps-2">
                  <p className='fw-bold mb-0'>Online Course</p>

                  {mainCategoryEditData?.subcategoryIds !== undefined
                    ? mainCategoryEditData?.subcategoryIds.map((c: any, index: any) => {
                      return (
                        <div className="ms-3">
                          <input
                            type="checkbox"
                            id={c._id}
                            value={c.name}
                            onChange={(e: any) => onSelectSubCategory(e, index, c)}
                            defaultChecked={c.isSelected}

                          />
                          <span className="ms-2">{c.name}</span>
                        </div>
                      );
                    })
                    : null}
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Primary Type"} />
                </div>
                <div className="col-8 ps-2">

                  <CustomDropdown
                    options={primaryTypeList}
                    lableName="Select Primary Type"
                    setInputText={(value: any) => onChangePrimaryType(value)}
                    value={primaryType}
                    defaultValue={primaryType._id}
                    reset={resetPrimaryType}
                  />
                </div>

              </div>


              <div className='d-flex justify-content-center mt-3'>
                <Submitbtn name={"Save & Update"} />
              </div>
            </div>

          </form>
        </div> : ""}
      </div>
    </div>
  )
}

