import React, { useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { getTableDataSlots, usePagination } from "../Utils/appConstants";
import PaginationView from "../Components/paginationView";
import PaginationTable from "../Components/paginationTable";
import Pagination from "@mui/material/Pagination";


interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function ConversionPopup(props: props) {
  const { open, handleClose, data } = props;
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  const Conversiondata = [
    {
      _id: 1,
      studentName: "Kshama Patil",
      courseType: "Test Preperation",
      exam: "SAT",
      subject: "Maths",
      date: "20/06/2024",
      admissionStatus: "Yes",
      source: "Landing Page",
    },
    {
      _id: 2,
      studentName: "Vipul Suryawanshi",
      courseType: "Test Preperation",
      exam: "SAT",
      date: "22/06/2024",
      subject: "Science",
      admissionStatus: "No",
      source: "Landing Page",
    },
    {
      _id: 3,
      studentName: "Durgesh Ambhure",
      courseType: "Academics",
      exam: "SAT",
      subject: "Maths",
      date: "23/06/2024",
      admissionStatus: "Yes",
      source: "Landing Page",
    },
  ];
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }

    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const renderListMode = () => {
    return (
      <>
        <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
          <Pagination
            count={totalPages}
            size="large"
            page={currentPage}
            variant="outlined"
            shape="rounded"
            color={"primary"}
            onChange={handleChange}
          />
        </div>

        <PaginationView
          count={count}
          tableData={Conversiondata ? Conversiondata : []}
          page={page}
          rowsPerPage={rowsPerPage}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={getTableDataSlots(count)}
        />
        <div>
          <PaginationTable
            tableData={Conversiondata ? Conversiondata : []}
            pagename={"conversionPopup"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "STUDENT NAME",
              "COURSE TYPE",
              "EXAM TYPE",
              "SUBJECT",
              "DATE",
              "ADMISSION STATUS",
              "SOURCE",
            ]}
            edit={true}
          />
        </div>
      </>
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >

      <Fade in={open}>
        <div className="demoSchedulemodeldivChat p-md-4 py-md-4 ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Sanju Sherwade</h5>

            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="m-2">
            {renderListMode()}

          </div>
        </div>
      </Fade>
    </Modal>
  );
}
