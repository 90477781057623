import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import PaginationTable from "../../Components/paginationTable";
import { dateFormat, today, TypeList } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  getCourseTypelist,
  getGeneralExamList,
  getPaymentStatus,
} from "../../Redux/Actions/AdminAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportButton from "../../Components/exportButton";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import moment from "moment";
import SearchButton from "../../Components/searchButton";
import { getCountryList } from "../../Redux/Actions/AdminAction";
export default function PaymentStatus() {
  const tableRef = useRef(null);
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [examList, setExamList] = useState([]);
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setCourseTypeList] = useState<any[]>([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [paymentType, setPaymentType] = useState<any>("");
  const [paymentList, setPaymentList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tabs = ["Success", "Cancelled"];
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
          if (res?.length > 0) {
            setCountry(res[2]);
          }
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangePaymentType = (value: any) => {
    setPaymentType(value);
  };

  const onChangeStartDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) >
      moment(endDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    } else {
      setStartDate(value);
    }
  };

  const onChangeEndDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) <
      moment(startDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("End date should be greater than Start date"));
    } else {
      setEndDate(value);
    }
  };

  const renderListMode = () => {
    return (
      <div ref={tableRef} className="p-3">
        <PaginationTable
          tableData={paymentList ? paymentList : []}
          pagename={"PaymentstatusList"}
          rowsPerPage={0}
          page={0}
          tableHead={[
            "S.N",
            "ORDER NO",
            "ORDER DATE",
            "BUYER NAME",
            "COURSE NAME",
            "PACKAGE NAME",
            "LEVEL",
            "PAID AMOUNT",
            "REMAINING AMOUNT",
            "INSTALLMENT",
            "COUPON CODE",
            "TOTAL COUPON DISCOUNT",
            "PAYMENT STATUS",
          ]}
          edit={true}
        />
      </div>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getPaymentData(e, 1);
  };
  const getPaymentData = (
    e: { preventDefault: () => void },
    status: number
  ) => {
    setSelectedIndex(status);
    setPaymentList([]);
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      coursetypeId: courseType?._id,
      paymentStatusCode: status === 1 ? 200 : 400,
      country_code: country?._id,
      type: paymentType?._id,
    };

    dispatch(
      getPaymentStatus(
        postdata,
        (res: any) => {
          setPaymentList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PAYMENT STATUS"} />
      <form className="text-center" onSubmit={handleSubmit}>
        <div className="px-2 mx-0 mt-4 row d-flex Justify-content-between">
          <div className="col-md-4 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Start Date:"} />
            </div>
            <div className="col-10 ps-5">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                Isrequired
                maxdate={today}
              />
            </div>
          </div>
          <div className=" col-md-4 d-flex mb-2 align-items-center">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"End Date:"} />
            </div>
            <div className="col-10 ps-5">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                Isrequired
                maxdate={today}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex Justify-content-between ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-9 ps-2">
              <CustomDropdown
                lableName="Select Country Name"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                defaultValue={country?._id}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex Justify-content-between ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-9 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={courseType}
                options={courseTypeList}
              />
            </div>
          </div>
          <div className="col-md-4 d-flex Justify-content-between ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Payment Type"} />
            </div>
            <div className="col-9 ps-2">
              <CustomDropdown
                lableName="Select Payment Type Name"
                setInputText={(value: any) => onChangePaymentType(value)}
                value={paymentType}
                options={TypeList}
              />
            </div>
          </div>

          <div className="col-md-3">
            <button className={"btn btn-dark"}>Search</button>
          </div>
        </div>
      </form>
      <div className="pe-5 d-flex justify-content-between mx-2 ps-2 mt-3">
        <div>
          {tabs?.map((data: string, index: number) => {
            return (
              <button
                className={`add_Question-btn btn     me-4 ${
                  selectedIndex === index + 1 ? "btn-success" : "btn-secondary"
                }`}
                onClick={(e) => getPaymentData(e, index + 1)}
              >
                {data}
              </button>
            );
          })}
        </div>
        <div className="d-flex">
          <DownloadTableExcel
            filename="Paymnet Status"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <ExportButton name={"Export Details"} />
          </DownloadTableExcel>
        </div>
      </div>
      {renderListMode()}
    </div>
  );
}
