import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { dateFormat, today } from "../../Utils/appConstants";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { getRole } from "../../Redux/Actions/SuperAdminAction";
import {
  getCountryList,
  getCourseTypelist,
  getDemoRequestList,
  getPaidAdsLeadList,
} from "../../Redux/Actions/AdminAction";
import moment from "moment";
import PaginationTable from "../../Components/paginationTable";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import SubmitButton from "../../Components/submitButton";
import Dropdown from "../../Components/dropdown";
import { socialMediaType } from "../../Utils/dataConstant";

export default function PaidAddLeads() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [userRoles, setUserRoles] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [leads, setLeads] = useState([]);
  const [country, setCountry] = useState<any>("");

  const [countryList, setCountryList] = useState([]);
  // const tableRef = useRef(null);
  const [selectedData, setSelectedData] = useState("");
  const [showEditSchedule, setShowEditSchedule] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>([]);
  const [smtype, setSmtype] = useState<any>("");

  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getRole(
        "",
        (res: any) => {
          setUserRoles(res);
        },
        () => {}
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    getDemoReq();
  }, []);

  const getDemoReq = () => {
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      smsourceId: smtype?._id,
    };
    if (country) {
      postData["countryId"] = country?._id;
    }
    if (course) {
      postData["coursetypeId"] = course?._id;
    }

    dispatch(
      getPaidAdsLeadList(
        postData,
        (res: any) => {
          setLeads(res);
        },
        () => {}
      )
    );
  };

  const handleEdit = (value: any) => {
    setSelectedData(value);
    setOpenPopup(true);
    setShowEditSchedule(true);
  };
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const handleDelete = (value: any) => {};

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getDemoReq();
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
  };
  const onChangeSmType = (value: any) => {
    setSmtype(value);
  };
  const renderListMode = () => {
    return (
      <div className="mx-3">
        <PaginationTable
          tableData={leads ? leads : []}
          pagename={"PaidAdsLandingPageLeads"}
          tableHead={[
            "S.N.",
            "STUDENT NAME",
            "COUNTRY",
            "COURSETYPE NAME",
            "EXAM NAME",
            "CREATED DATE",
            "SOURCE",
            "OTP VERIFIED",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"PAID ADD LEADS"} />
      <div className="formDiv">
        <form className="text-center" onSubmit={handleSubmit}>
          <div className="px-2 mx-0 my-4 row">
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-4 align-item-center d-flex">
                <LabelField lableName={"Start Date:"} />
              </div>
              <div className="col-md-7">
                <DatePicker
                  value={startDate}
                  setInputText={(value: any) =>
                    setStartDate(moment(value).format("YYYY-MM-DD"))
                  }
                  maxdate={today}
                />
              </div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-4 align-item-center d-flex">
                <LabelField lableName={"End Date:"} />
              </div>
              <div className="col-md-7">
                <DatePicker
                  value={endDate}
                  setInputText={(value: any) =>
                    setEndDate(moment(value).format("YYYY-MM-DD"))
                  }
                  maxdate={today}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-4 align-item-center d-flex">
                <LabelField lableName={"Country:"} />
              </div>
              <div className="col-md-7">
                <Dropdown
                  labelName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                />
              </div>
            </div>

            <div className="col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-4 align-item-center d-flex">
                <LabelField lableName={"Course Type:"} />
              </div>
              <div className="col-md-7">
                <Dropdown
                  labelName="Select CourseType"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                />
              </div>
            </div>
            <div className="col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-4 align-item-center d-flex">
                <LabelField lableName={"Source:"} />
              </div>
              <div className="col-md-7">
                <Dropdown
                  labelName="Select Source"
                  setInputText={(value: any) => onChangeSmType(value)}
                  value={smtype}
                  options={socialMediaType}
                />
              </div>
            </div>

            <div className="d-flex col-md-3 ms-3  align-items-center">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        <p className="d-flex mb-1 fw-bold mt-1 justify-content-end me-4">
          Count:{" "}
          <span className="successcolor fw-bold ms-2">{leads?.length}</span>
        </p>
        {renderListMode()}
      </div>
    </div>
  );
}
