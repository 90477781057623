class options {
  name = "";
  value = "";
}

class contentModal {
  defaultValue = "";
  desc = "";
  optionType = 0;
  options = [];
  status = 0;
  title = "";
  value = false;
  _id = "";
}

class settingDataModal {
  desc = "";
  content = [];
}

class talkToExpertModal {
  message = "";
  mobileNo = "";
}

class language {
  id = "";
  name = "English";
  displayId = "EN";
  abbreviation = "En";
}

class media {
  file = "";
  mediaType = "";
  _id = "";
}

class included {
  name = "";
  _id = "";
}
class subCategories {
  included = [];
  excluded = [];
}

class books {
  _id = "";
  name = "";
  price = 0;
  coverImage = "";
}

class subscriptionPlan {
  durationId = "";
  months = 0;
  price = 0;
  isRecommended = false;
  id = "";
  isSubPlanChange = false;
  totalPrice = {};
}
class packageSection {
  booksPackageId = "";
  name = "";
  booksPackagePrice = 0;
  booksCount = 0;
  _id = "";
  books = [];
  subscriptionPlan = [];
}
class sessions {
  name = "";
  type = 0;
  sessionId = "";
}

class courseDetailModal {
  subCategories = new subCategories();
  language = new language();
  _id = "";
  name = "";
  sectionId = "";
  mainCategoryId = "";
  sectionType = 0;
  startDate = "";
  bannerImage = "";
  video = "";
  pdf = "";
  duration = 0;
  packageSection = [];
  sessions = [];
  courseDetail = {};
  bookSectionId = "";
  fromWhere = false;
  primarySubCategoryName = "";
}

class courseArray {
  _id = "";
  categoryname = "";
  categorycolour = "";
  discountEndsOn = "";
  discountPer = 0;
  discountPrice = 0;
  duration = 0;
  durationType = "";
  examTypeTitle = "";
  includes = [];
  language = new language();
  media = [];
  name = "";
  price = 0;
  otherDesc = [];
}

class AuthReducerModal {
  authToken = "";
  loader = false;
  userId = "";
  userName = "";
  countryId = "";
  userEmail = "";
}

// class AuthReducerModal {
//   countryList = [];
//   tutorial = false;
//   theme = 'light';
//   ACCESS_TOKEN = '';
//   continueWatch = [];
//   internet = true;
//   countryCode = '';
//   baseCourseId = '';
//   baseCourseName = '';
//   firstName = '';
//   lastName = '';
//   stateId = '';
//   countryName = '';
//   _id = '';
//   stateData = [];
//   examType = [];
//   examTypeId = '';
//   examTypeName = '';
//   stateName = '';
//   childrenId = '';
//   childrenName = '';
//   display = new settingDataModal();
//   notification = new settingDataModal();
//   video = new settingDataModal();
//   settingLoader = false;
//   talkToExpert = new talkToExpertModal();
//   email = '';
//   mobileNo = '';
//   profilePicture = '';
//   dob = '';
//   hasActivePaidPlan = false;
//   parentCourse = [];
//   examList = [];
//   state = [];
//   cartItemCount = 0;
//   unReadCount = 0;
//   classroomBranchId = '';
//   userData = {};
//   classBatchData = [];
//   localState = { _id: '', name: 'All India' };
//   examTypeDataLocal = [];
//   liveVideoData = {};
//   recordedVideoData = {}
// }

class SnakbarReducerModal {
  successSnackbarOpen = false;
  errorSnackbarOpen = false;
  infoSnackbarOpen = false;
  message = "";
}

class ExamSelectModal {
  notSyncedData = "";
  examIdData = { "62ecf8e2ada4a6e998d4bea5": null };
  // examIdData = '627503981fe38d827a93b211';
}

class StartExamModal {
  examData = [];
  userData = [];
  selectedLanguage = { id: "en", name: "English" };
}

class SetOptionValueFalseModal {
  isTrue = false;
  isExamStatusValueChanged = false;
  isExamStatusSectionChanged = false;
  isQuestionSelected = false;
}

class HomeReducerModal {
  loader = false;
  upcoming = [];
  freeVideo = [];
  banner = [];
  courseHome = [];
  courseList = [];
  courseSelect = "";
  cartAddedData = {};
  recentView = [];
  addressList = [];
  homeQuote = "";
  courseDetail = new courseDetailModal();
  selectedCourse = [];
  subscriptionID = "";
  moreUpcoming = [];
  moreFree = [];
  address = {};
  addon = [];
  discountAmount = 0;
  paidTiles = [];
  installments = [];
  paidTilesInactive = [];
  paidTilesSubscribed = [];
  installmentsInactive = [];
  getLiveVideo = [];
  subscriptionStudyID = [];
  SelectedStudyPlan = [];
  selectedPackages = [];
  selectedTiles = {};
  CouponData = [];
  order = [];
  orderDetails = [];
  isLoader = false;
  cartListDetails = [];
  couponCode = "";
  showRemoveCoupon = false;
  OriginalPrice = "";
}

export {
  AuthReducerModal,
  SnakbarReducerModal,
  HomeReducerModal,
  courseArray,
  courseDetailModal,
  packageSection,
  subscriptionPlan,
  subCategories,
  sessions,
  ExamSelectModal,
  SetOptionValueFalseModal,
  StartExamModal,
};
