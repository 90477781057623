import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import {
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getSubTopicList,
  getMainCategoryList,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import Submitbtn from "../../Components/submitButton";
import {
  deleteRecVideo,
  getGeneralPackageList,
  getGeneralTopicList,
  getLiveRecordedList,
  getPackageSessionList,
  getPremierVideoId,
  getRecordedDppList,
  getRecordedEditData,
  getStudentList,
  updateRecVideo,
} from "../../Redux/Actions/SuperAdminAction";
import { usePagination } from "../../Utils/appConstants";
import "../../App.css";
import { startsWithSpace, validateFullName } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import TimerClock from "../../Atoms/timerClock";
import { defaultDuration } from "../../Utils/dataConstant";
import PaginationTable from "../../Components/paginationTable";
import { minConvertTime } from "../../Utils/dateFormat";

export default function UpdateLiveRecordedVideo() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [lecture, setLecture] = useState("");

  const [lectureDuration, setLectureDuration] = useState<any>(defaultDuration);
  const [awsMP4Link, setAwsMP4Link] = useState("");
  const [awsM3U8Link, setAwsM3U8Link] = useState("");
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState<any>([]);
  const [premierId, setPremierId] = useState<any>(1);
  const [premierIdList, setPremierIdList] = useState<any>([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [session, setSession] = useState<any>("");
  const [sessionList, setSessionList] = useState<any>([]);
  const [topicName, setTopicName] = useState<any>("");
  const [topicNameList, setTopicNameList] = useState<any>([]);
  const [DPPName, setDPPName] = useState<any>("");
  const [teachersHandout1, setTeachersHandout1] = useState<any>("");
  const [teachersHandout2, setTeachersHandout2] = useState<any>("");
  const [subTopicName, setSubTopicName] = useState<any>("");
  const [subTopicNameList, setSubTopicNameList] = useState<any>([]);

  const [liveRecordedList, setLiveRecordedList] = useState<any>([]);
  const [isEditMode, setisEditMode] = useState(false);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);

  const _DATA = usePagination(String(count), rowsPerPage);

  const [demoRecId, setDemoRecId] = useState<any>("");
  const [recordedEditDataList, setRecordedEditDataList] = useState<any>([]);

  const [batchList, setBatchtList] = useState<any>([]);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);

  const [category, setCategory] = useState<any>("");
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [resetMainCategory, setresetMainCategory] = useState(false);

  const [batch, setBatch] = useState("");
  const [DPPNameList, setDPPNameList] = useState<any>([]);

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
    dispatch(
      getPremierVideoId(
        {},
        (res: any) => {
          setPremierIdList(res);
          setPremierId(res[0]?._id);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralTopicList(
        "",
        (res: any) => {
          setTopicNameList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
        },
        () => {}
      )
    );
  };
  const onChangeMainCategory = (value: {
    _id?: string;
    name?: string;
    sessionIds?: any;
  }) => {
    setCategory(value._id);
    setresetMainCategory(false);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: subject,
          maincategoryId: value._id,
        },
        (res: any) => {
          setBatchtList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value._id);
  };
  const onChangeTopicName = (value: { _id: any; name?: string }) => {
    setTopicName(value._id);
  };
  const onChangeDPPName = (value: { _id: any; name?: string }) => {
    setDPPName(value?._id);
  };
  const onSelectBatch = (event: any, index: any) => {
    let newArray = JSON.parse(JSON.stringify(recordedEditDataList));
    if (event.target.checked) {
      newArray.packages[index]["isSelected"] = event.target.checked;
      selectedBatch.push(parseInt(event.target.id));
      setSelectedBatch(selectedBatch);
      setRecordedEditDataList(newArray);
    }
  };
  const onSelectGeneralBatch = (event: any, index: any) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
  };

  const onChangeBatch = (value: any) => {
    setBatch(value._id);
  };
  const onChangeSubTopicName = (value: { _id: any; name?: string }) => {
    setSubTopicName(value._id);
  };
  const onChangeStudentSearch = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };
  const onChangeStudent = () => {
    dispatch(
      getStudentList(
        {
          examtypeId: exam,
          search: student,
        },
        (res: any) => {
          selectedStudent?.map((item: any, i: any) =>
            res?.map((data: any, index: any) => {
              if (data?._id == item) {
                data["selected"] = true;
              }
            })
          );
          setStudentList(res);
          setSelectedStudent([]);
        },
        () => {}
      )
    );
  };

  const onChangeRegisteredStud = (event: any, index: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);

    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList, ""]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  const onChangeLecture = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setLecture(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const onChange = () => {
    const postData = {
      coursetypeId: parseInt(courseType),
      examtypeId: exam,
      subjectId: subject,
      premierVideoId: premierId,
      packageIds: premierId === 1 ? selectedBatch : [batch],
      maincategoryId: category._id,
    };
    if (selectedBatch.length === 0 && batch === "")
      delete postData["packageIds"];
    if (category._id === "") delete postData["maincategoryId"];

    dispatch(
      getLiveRecordedList(
        postData,
        (res: any) => {
          setLiveRecordedList(res);
        },
        () => {}
      )
    );
  };
  const handleEdit = (value: any) => {
    setisEditMode(true);
    setDemoRecId(value?._id);
    setSelectedStudentList([]);
    setSelectedStudent([]);
    dispatch(
      getRecordedEditData(
        value?._id,
        (res: any) => {
          setRecordedEditDataList(res);
          setCourseType(res?.data?.coursetypeId?._id);
          setExam(res?.data?.examtypeId?._id);
          setSubject(res?.data?.subjectId?._id);
          setLecture(res?.data?.lectureTitle);
          setAwsM3U8Link(res?.data?.m3u8Link);
          setAwsMP4Link(res?.data?.mp4LinkLink);
          setStudentList(res?.student);
          setDPPName(res?.data?.dppId);
          setTopicName(res?.data?.topicId?._id);
          setSubTopicName(res?.data?.subTopicId?._id);
          setSession(res?.data?.sessionId?._id);
          setTeachersHandout1(res?.data?.teacherHandout1);
          setTeachersHandout2(res?.data?.teacherHandout2);
          setSelectedStudent(res?.student?.studentId);
          setCategory(res?.data?.maincategoryId?._id);

          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudent;
            if (item?.isSelected) {
              newArrr.push(item.studentId);
            }
            setSelectedStudent(newArrr);
          });

          res?.student?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedStudentList;
            if (item?.isSelected) {
              newArrr.push(
                `${item.firstName} ${item.lastName} - ${item.mobile}`
              );
            }
            setSelectedStudentList(newArrr);
          });

          res?.data?.packages?.map((item: any, index: any) => {
            let newArrr = [];
            newArrr = selectedBatch;
            if (item?.isSelected) {
              newArrr.push(item._id);
            }
            setSelectedBatch(newArrr);
          });

          dispatch(
            getPackageSessionList(
              {
                packageIds: selectedBatch,
              },
              (res: any) => {
                setSessionList(res);
              },
              () => {}
            )
          );

          if (premierId === 1 || premierId === 2) {
            const data = {
              coursetypeId: parseInt(courseType),
              examtypeId: exam,
              subjectId: subject,
              topicId: res?.data?.topicId?._id,
            };
            dispatch(
              getSubTopicList(
                data,
                (res: any) => {
                  setSubTopicNameList(res);
                },
                () => {}
              )
            );
          }

          setLectureDuration(minConvertTime(res?.data?.lectureDuration));

          const postdata = {
            coursetypeId: courseType,
            examtypeId: exam,
            subjectId: subject,
            topicId: topicName._id,
            subTopicId: subTopicName?._id,
          };
          if (topicName._id === "") delete postdata["topicId"];
          if (subTopicName?._id === "") delete postdata["subTopicId"];
          dispatch(
            getRecordedDppList(
              postdata,
              (res: any) => {
                setDPPNameList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };
  const handleDelete = (value: any) => {
    dispatch(
      deleteRecVideo(
        value._id,
        () => {
          const postData = {
            coursetypeId: parseInt(courseType),
            examtypeId: exam,
            subjectId: subject,
            premierVideoId: premierId,
          };
          dispatch(
            getLiveRecordedList(
              postData,
              (res: any) => {
                setLiveRecordedList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const handleOnEdit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let arr = [];
    arr.push(lectureDuration.split(":"));
    var minutes = arr[0][0] * 60 + +arr[0][1] + arr[0][2] / 60;

    const postData: any = {};
    if (recordedEditDataList?.data?.coursetypeId?._id != courseType) {
      postData["coursetypeId"] = courseType;
    }
    if (recordedEditDataList?.data?.examtypeId?._id != exam) {
      postData["examtypeId"] = exam;
    }
    if (recordedEditDataList?.data?.subjectId?._id != subject) {
      postData["subjectId"] = subject;
    }
    if (recordedEditDataList?.data?.maincategoryId?._id != category) {
      postData["maincategoryId"] = category;
    }
    if (recordedEditDataList?.data?.lectureTitle != lecture) {
      postData["lectureTitle"] = lecture;
    }
    if (recordedEditDataList?.data?.m3u8Link != awsM3U8Link) {
      postData["m3u8Link"] = awsM3U8Link;
    }
    if (recordedEditDataList?.data?.mp4LinkLink != awsMP4Link) {
      postData["mp4LinkLink"] = awsMP4Link;
    }
    if (recordedEditDataList?.data?.premierVideoId?._id != premierId) {
      postData["premierVideoId"] = premierId;
    }
    if (recordedEditDataList?.student != selectedStudent) {
      postData["studentArrayID"] = selectedStudent;
    }
    if (recordedEditDataList?.data?.lectureDuration != minutes) {
      postData["lectureDuration"] = minutes;
    }
    if (recordedEditDataList?.data?.topicId?._id != topicName) {
      postData["topicId"] = topicName;
    }
    if (recordedEditDataList?.data?.subTopicId?._id != subTopicName) {
      postData["subTopicId"] = subTopicName;
    }
    if (recordedEditDataList?.data?.dppId != DPPName) {
      postData["dppId"] = DPPName;
    }
    if (recordedEditDataList?.data?.teacherHandout1 != teachersHandout1) {
      postData["teacherHandout1"] = teachersHandout1;
    }
    if (recordedEditDataList?.data?.teacherHandout2 != teachersHandout2) {
      postData["teacherHandout2"] = teachersHandout2;
    }
    dispatch(
      updateRecVideo(
        postData,
        demoRecId,
        (res: any) => {
          setisEditMode(false);
          setSelectedStudentList([]);
          setSelectedStudent([]);
          const postData = {
            coursetypeId: parseInt(courseType),
            examtypeId: exam,
            subjectId: subject,
            premierVideoId: premierId,
          };
          dispatch(
            getLiveRecordedList(
              postData,
              (res: any) => {
                setLiveRecordedList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const clearData1 = () => {
    setResetCourseType(true);
    setResetExam(true);
    setResetSubject(true);
    setBatchtList([]);
    setLiveRecordedList([]);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const renderListMode = () => {
    return (
      <>
        <PaginationTable
          tableData={liveRecordedList ? liveRecordedList : []}
          pagename={"Recordedvideo"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Lecture Title",
            "Subject Name",
            premierId === 1 || premierId === 2 ? "Topic Name" : null,
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const renderEditMode = () => {
    return (
      <>
        <div className="contentBox">
          <div className="px-4 mx-0 mt-4 formDiv ">
            <form className="col-10" onSubmit={handleOnEdit}>
              <div className="d-flex Justify-content-between mb-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Schedule For"} />
                </div>
                <div className="col-10 d-flex">
                  {premierIdList.map((c: any, index: any) => {
                    return (
                      <>
                        {premierId === c?._id ? (
                          <div className="col-3">
                            <span className="me-3 radiobtnspan">
                              <span>
                                <input
                                  type="radio"
                                  name="type"
                                  value={c._id}
                                  checked={premierId === c?._id ? true : false}
                                  onChange={() => setPremierId(c?._id)}
                                />{" "}
                                <span className="ms-2">{c?.name}</span>
                              </span>
                            </span>
                          </div>
                        ) : null}
                      </>
                    );
                  })}
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    Isrequired={true}
                    defaultValue={courseType}
                    disabled={isEditMode}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    defaultValue={exam}
                    disabled={isEditMode}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    disabled={isEditMode}
                    defaultValue={subjectList.length > 0 ? subject : ""}
                  />
                </div>
              </div>

              {premierId === 1 || premierId === 2 ? (
                <div className="d-flex Justify-content-between  ">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Main Category"} />
                  </div>
                  <div className="col-6 ps-2">
                    <CustomDropdown
                      lableName="Select Main Category"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeMainCategory(value)
                      }
                      value={category}
                      options={mainCategoryList}
                      Isrequired={true}
                      defaultValue={mainCategoryList.length > 0 ? category : ""}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              ) : null}

              {premierId === 1 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Batch Name"} />
                    </div>

                    <div className="col-6 border rounded p-3">
                      {recordedEditDataList?.packages !== undefined
                        ? recordedEditDataList?.packages.map(
                            (c: any, index: any) => {
                              return (
                                <div className="ms-3">
                                  <input
                                    type="checkbox"
                                    id={c._id}
                                    value={c.name}
                                    defaultChecked={c.isSelected}
                                    onChange={(e) => onSelectBatch(e, index)}
                                    disabled={isEditMode}
                                  />
                                  <span className="ms-2">{c.name}</span>
                                </div>
                              );
                            }
                          )
                        : null}
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between  ">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Session"} />
                    </div>

                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Session"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSession(value)
                        }
                        value={session}
                        options={sessionList}
                        Isrequired={true}
                        defaultValue={sessionList.length > 0 ? session : ""}
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {premierId === 2 ? (
                <div className="d-flex Justify-content-between  ">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 ps-2 ">
                    <CustomDropdown
                      lableName="Select Batch Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeBatch(value)
                      }
                      value={batch}
                      options={batchList}
                      Isrequired={true}
                      defaultValue={batchList.length > 0 ? batch : ""}
                      disabled={isEditMode}
                    />
                  </div>
                </div>
              ) : null}
              {premierId === 1 || premierId === 2 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Topic Name"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Topic Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeTopicName(value)
                        }
                        value={topicName}
                        options={topicNameList}
                        Isrequired={true}
                        defaultValue={topicNameList.length > 0 ? topicName : ""}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Sub-Topic Name"} />
                    </div>
                    <div className="col-6 ps-2 ">
                      <CustomDropdown
                        lableName="Select Sub-Topic Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeSubTopicName(value)
                        }
                        value={subTopicName}
                        options={subTopicNameList}
                        Isrequired={true}
                        defaultValue={
                          subTopicNameList.length > 0 ? subTopicName : ""
                        }
                      />
                    </div>
                  </div>
                </>
              ) : null}
              {premierId === 1 || premierId === 2 || premierId === 3 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Lecture Title"} />
                    </div>
                    <div className="col-6">
                      <InputField
                        placeholder="Enter Lecture Title"
                        value={lecture}
                        //onChangeInput={(value: any) => setLecture(value)}
                        onChangeInput={(value: any) => onChangeLecture(value)}
                        maxlength={50}
                        minlength={3}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"Lecture Duration "} />
                    </div>
                    <div className="col-6  ">
                      <TimerClock
                        value={lectureDuration}
                        setInputText={(value: any) => setLectureDuration(value)}
                        Isrequired={true}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"AWS M3U8 Link"} />
                    </div>
                    <div className="col-6   ">
                      <InputField
                        placeholder="Enter AWS M3U8 Link"
                        value={awsM3U8Link}
                        onChangeInput={(value: any) => setAwsM3U8Link(value)}
                        // maxlength={50}
                        minlength={3}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"AWS MP4 Link"} />
                    </div>
                    <div className="col-6   ">
                      <InputField
                        placeholder="Enter AWS MP4 Link"
                        value={awsMP4Link}
                        onChangeInput={(value: any) => setAwsMP4Link(value)}
                        // maxlength={50}
                        minlength={3}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {premierId === 1 || premierId === 2 ? (
                <>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 align-item-center d-flex">
                      <LabelField lableName={"DPP Name"} />
                    </div>
                    <div className="col-6  ps-2 ">
                      <CustomDropdown
                        lableName="Select DPP Name"
                        setInputText={(value: { _id: string; name: string }) =>
                          onChangeDPPName(value)
                        }
                        value={DPPName}
                        options={DPPNameList}
                        defaultValue={DPPNameList.length > 0 ? DPPName : ""}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 pt-2">
                      <LabelField lableName={"Teacher Handout 1"} />
                      <p className="text-secondary">(With Annotation)</p>
                    </div>
                    <div className="col-6   ">
                      <InputField
                        placeholder="Enter Teacher Handout Url 1"
                        value={teachersHandout1}
                        onChangeInput={(value: any) =>
                          setTeachersHandout1(value)
                        }
                        // maxlength={50}
                        minlength={3}
                      />
                    </div>
                  </div>
                  <div className="d-flex Justify-content-between">
                    <div className="col-2 pt-2">
                      <LabelField lableName={"Teacher Handout 2"} />
                      <p className="text-secondary">(Without Annotation)</p>
                    </div>
                    <div className="col-6   ">
                      <InputField
                        placeholder="Enter Teacher Handout Url 2"
                        value={teachersHandout2}
                        onChangeInput={(value: any) =>
                          setTeachersHandout2(value)
                        }
                        // maxlength={50}
                        minlength={3}
                      />
                    </div>
                  </div>
                </>
              ) : null}

              {premierId === 2 || premierId === 3 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Scheduled For"} />
                  </div>
                  <div className="col-6 p-2 border rounded">
                    <p className="fw-bold ">Registered Student</p>
                    <div className="d-flex">
                      <InputField
                        placeholder="Enter student name"
                        value={student}
                        onChangeInput={(value: any) =>
                          onChangeStudentSearch(value)
                        }
                        maxlength={50}
                      />
                      <button
                        type="button"
                        className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
                        onClick={(e) => onChangeStudent()}
                      >
                        Search
                      </button>
                    </div>

                    {studentList !== undefined && student
                      ? studentList.map((c: any, index: any) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                                onChange={(e) =>
                                  onChangeRegisteredStud(e, index)
                                }
                                defaultChecked={c.selected}
                              />
                              <span className="ms-2">
                                {c.firstName} {c.lastName}
                              </span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                  {selectedStudentList && selectedStudentList?.length > 0 ? (
                    <div className="col-auto p-2 border rounded">
                      {selectedStudentList.map((item: any, index: any) => {
                        return <div>{item}</div>;
                      })}
                    </div>
                  ) : null}
                </div>
              ) : null}
              <div className="d-flex justify-content-center mt-3">
                <Submitbtn name={"Save & Update"} />
              </div>
            </form>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    if (isEditMode === false) {
      setSelectedStudentList([]);
      setSelectedStudent([]);
    }
  }, [isEditMode]);

  return (
    <div className="contentBox">
      <PageTitle
        name={"UPDATE RECORDED LIVE VIDEO"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode !== true ? (
        <div className="p-4">
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {premierIdList.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          value={c._id}
                          checked={premierId === c?._id ? true : false}
                          onChange={() => {
                            setPremierId(c?._id);
                            clearData1();
                          }}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>

          {premierId === 1 || premierId === 2 || premierId === 3 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-6 ps-2  ">
                  <CustomDropdown
                    lableName="Select Course Type"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeCourseType(value)
                    }
                    value={courseType}
                    options={courseTypeList}
                    Isrequired={true}
                    reset={resetCourseType}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {premierId === 1 || premierId === 2 ? (
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Main Category"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Main Category"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeMainCategory(value)
                  }
                  value={category}
                  options={mainCategoryList}
                  Isrequired={true}
                  reset={resetMainCategory}
                />
              </div>
            </div>
          ) : null}

          {premierId === 1 ? (
            <>
              {batchList.length > 0 ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Batch Name"} />
                  </div>

                  <div className="col-6 border rounded p-3">
                    {batchList !== undefined
                      ? batchList.map((c: any, index: any) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={c.name}
                                onChange={(e) => onSelectGeneralBatch(e, index)}
                              />
                              <span className="ms-2">{c.name}</span>
                            </div>
                          );
                        })
                      : null}
                  </div>
                </div>
              ) : null}
            </>
          ) : (
            ""
          )}

          {premierId === 2 ? (
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>

              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                />
              </div>
            </div>
          ) : null}

          <div className="d-flex justify-content-end pe-4 mt-3">
            <button
              type="button"
              className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
              onClick={(e) => onChange()}
            >
              {" "}
              Search{" "}
            </button>
          </div>
        </div>
      ) : null}
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
