import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import CustomDropdown from "../../Components/customDropdown";
import { addMainCategory, getSessionList, getSubcategoryList } from '../../Redux/Actions/AdminAction';
import { useDispatch } from 'react-redux';
import { validateFullName, startsWithSpace, length_Hundred, length_Three } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function CreateMainCategory() {
  const dispatch: Function = useDispatch();
  const [category, setCategory] = useState<string>("");
  const [subcategoryList, setSubcategoryList] = useState<any>([]);
  const [selectedSubCategory, setSelectedSubCategory] = useState<any>([]);
  const [eventValue, setEventValue] = useState<any>(null);
  const [renderUseEffect, setRenderUseEffect] = useState(false);
  const [primaryTypeList, setPrimaryTypeList] = useState<any>([]);
  const [primaryType, setPrimaryType] = useState<any>("");
  const [resetPrimaryType, setResetPrimaryType] = useState(false);
  const [isSession, setIsSession] = useState<any>(false);
  const [sessionList, setSessionList] = useState<any>([]);
  const [sessionArray, setSessionArray] = useState<any>([]);


  useEffect(() => {
    dispatch(
      getSubcategoryList(
        "",
        (res: any) => {
          setSubcategoryList(res)
        },
        () => { }
      )
    );
    dispatch(
      getSessionList(
        {},
        (res: any) => {
          setSessionList(res)
        },
        () => { }
      )
    );
  }, []);

  useEffect(() => {
    if (renderUseEffect) {
      if (eventValue.checked) {
        setPrimaryTypeList([
          ...primaryTypeList,
          { _id: eventValue.id, name: eventValue.value },
        ]);
        setRenderUseEffect(false);
      } else if (!eventValue.checked) {
        let filterptype = primaryTypeList.filter(
          (item: any, index: any) => item._id !== eventValue.id
        );
        setPrimaryTypeList(filterptype);
        setRenderUseEffect(false);
      }
    }
  }, [renderUseEffect]);

  const sessionChange = (event: any) => {
    if (event.target.checked) {
      setIsSession(true);
    } else {
      setIsSession(false);
      setSessionArray([])
    }
  };

  const onSelectSubCategory = (event: any) => {
    setPrimaryType("");
    if (event.target.checked) {
      selectedSubCategory.push(event.target.id);
      setEventValue(event.target);
      setRenderUseEffect(true);
      setSelectedSubCategory(selectedSubCategory);
    } else {
      setEventValue(event.target);
      setRenderUseEffect(true);
      selectedSubCategory.splice(
        selectedSubCategory.indexOf(event.target.id),
        1
      );
    }
  };

  const onChangePrimaryType = (value: any) => {
    setPrimaryType(value);
    setResetPrimaryType(false)
  };

  const changeSessionName = (event: any) => {
    let newArrr = [];
    newArrr = sessionArray;
    if (event.target.checked) {
      newArrr.push(event.target.id);
    } else {
      newArrr.splice(newArrr.indexOf(event.target.id), 1);
    }
    setSessionArray(newArrr);
  };

  const onChangeCategory = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setCategory(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  }

  const validation = () => {
    return (
      category === "" ||
      category.length < length_Three - 1 ||
      primaryType === "" ||
      selectedSubCategory === "" ||
      selectedSubCategory?.length === 0 ||
      isSession === true && sessionArray.length === 0
    );
  }


  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();

    const postData = {
      name: category,
      isSession: isSession,
      sessionIdArray: isSession ? sessionArray : [],
      subcategoryIdArray: selectedSubCategory,
      primaryType: primaryType?._id
    }

    dispatch(
      addMainCategory(
        postData,
        (res: any) => {
          setCategory("");
          setSelectedSubCategory([]);
          setResetPrimaryType(true);
          setPrimaryTypeList([]);
          setIsSession(false);
          setSubcategoryList([])
          dispatch(
            getSubcategoryList(
              "",
              (res: any) => {
                setSubcategoryList(res)
              },
              () => { }
            )
          );

        },
        () => { }
      )
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"MAIN CATEGORY CREATION"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='' onSubmit={handleSubmit}>
          <div className="col-7">
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Main Category Name"} />
              </div>
              <div className="col-8 ">
                <InputField
                  placeholder="Enter Category Name"
                  value={category}
                  onChangeInput={(value: any) => onChangeCategory(value)}
                  maxlength={length_Hundred}
                  minlength={length_Three}
                  isRequired={true}
                />
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Session Flag"} />
              </div>
              <div className="d-flex align-items-center ps-2">
                <input
                  type="checkbox"
                  name="Session Flag"
                  value={isSession}
                  onChange={sessionChange}
                  checked={isSession}
                />
                <span className="ms-2">
                  <b>Session Flag</b>
                </span>
              </div>
            </div>
            {isSession ? (
              <div className="d-flex align-items-center my-2">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Session Name"} />
                </div>
                <div className="d-flex align-items-center">
                  {sessionList?.map((item: any, index: any) => {
                    return (
                      <div className="d-flex me-3" key={index}>
                        <input
                          type="checkbox"
                          name="Session Flag"
                          id={item._id}
                          value={item.name}
                          onChange={(e) => changeSessionName(e)}
                        />
                        <span className="ms-2">
                          <b>{item.name}</b>
                        </span>
                      </div>
                    );
                  })}
                </div>
              </div>
            ) : null}
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Sub Category"} />
              </div>
              <div className="col-8 ps-2">
                <p className='fw-bold mb-0'>Online Course</p>

                {subcategoryList !== undefined
                  ? subcategoryList.map((c: any, index: any) => {
                    return (
                      <div className="ms-3">
                        <input
                          type="checkbox"
                          id={c._id}
                          value={c.name}
                          onChange={onSelectSubCategory}
                        />
                        <span className="ms-2">{c.name}</span>
                      </div>
                    );
                  })
                  : null}
              </div>
            </div>
            <div className='d-flex mb-2 align-items-center'>
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Primary Type"} />
              </div>
              <div className="col-8 ps-2">

                <CustomDropdown
                  options={primaryTypeList}
                  lableName="Select Primary Type"
                  setInputText={(value: any) => onChangePrimaryType(value)}
                  value={primaryType}
                  reset={resetPrimaryType}
                  Isrequired={true}
                />
              </div>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <Submitbtn
                name={"Create Main category"}
                validation={validation()} />
            </div>
          </div>
        </form>
      </div>
    </div>
  )
}

