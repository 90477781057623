import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "../../Components/addmorebtn";
import {
  validateIsLettersAndDotOnly,
  startsWithSpace,
  length_FiveHundred,
  length_Ten,
  length_Five,
  length_Hundred_TwentyFive,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { useDispatch } from "react-redux";
import InputField from "../../Atoms/InputField";
import {
  getCourseDescription,
  getMainCategoryList,
  updateCourseDescription,
  deleteCourseDescription,
  bindSubjectList,
  getGeneralExamList,
  getCourseTypelist,
  getCountryList,
} from "../../Redux/Actions/AdminAction";
import SubmitButton from "../../Components/submitButton";
import { GradeList } from "../../Utils/dataConstant";

export default function UpdateCourseDescription() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [isEditMode, setisEditMode] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [CourseID, setCourseID] = useState<any>("");
  const initialVal = [""];
  const [descriptionArr, setDescriptionArr] = useState(initialVal);
  const [courseTitle, setCourseTitle] = useState<any>("");
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [mainCategory, setMainCategory] = useState<any>("");
  const [resetMainCategory, setresetMainCategory] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);

  const [courseDescList, setCourseDescList] = useState([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);
    setResetSubject(true);
    dispatch(
      getCourseDescription(
        {
          coursetypeId: value?._id,
        },
        (res: any) => {
          setCourseDescList(res);
        },
        () => {}
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    setResetSubject(true);
    dispatch(
      getCourseDescription(
        {
          coursetypeId: course?._id,
          gradeId: value?._id,
        },
        (res: any) => {
          setCourseDescList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setResetSubject(true);
    setExam(value?._id);
    dispatch(
      getCourseDescription(
        {
          coursetypeId: course?._id,
          examTypeId: value._id,
        },
        (res: any) => {
          setCourseDescList(res);
        },
        () => {}
      )
    );
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    const postData = {
      examTypeId: exam,
      coursetypeId: course?._id,
      gradeId: grade?._id,
      subjectId: value._id,
    };
    if (
      postData["examTypeId"] === null ||
      postData["examTypeId"] === undefined ||
      postData["examTypeId"] === ""
    ) {
      delete postData["examTypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getCourseDescription(
        postData,
        (res: any) => {
          setCourseDescList(res);
        },
        () => {}
      )
    );
  };

  const onPressAdd = () => {
    setDescriptionArr([...descriptionArr, initialVal[0]]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...descriptionArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setDescriptionArr(newArrr);
  };

  const onChangeCategory = (value: any) => {
    setMainCategory(value?.maincategoryId);
    setresetMainCategory(false);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    }
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleEdit = (value: any) => {
    setisEditMode(true);
    setCourseID(value._id);
    setMainCategory(value?.maincategoryId?._id);
    setCourseTitle(value?.header);
    setSubject(value?.subjectId?._id);
    setGrade(value?.gradeId?._id);
    //setDescriptionArr(value.courseDescriptions! = null ? value.courseDescriptions : [initialVal])
    setDescriptionArr(value.courseDescriptions);
  };

  const handleDelete = (value: any) => {
    dispatch(
      deleteCourseDescription(
        value._id,
        (res: any) => {
          dispatch(
            getCourseDescription(
              {
                examTypeId: value?._id,
              },
              (res: any) => {
                setCourseDescList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeCourseTitle = (value: any) => {
    if (validateIsLettersAndDotOnly(value) && !startsWithSpace(value)) {
      setCourseTitle(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  function onChangeDesc(val: any, index: any) {
    let newArrr = JSON.parse(JSON.stringify(descriptionArr));
    newArrr[index] = val;
    setDescriptionArr(newArrr);
  }
  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData = {
      coursetypeId: course?._id,
      examTypeId: exam,
      gradeId: grade?._id,
      subjectId: subject,
      maincategoryId: mainCategory,
      header: courseTitle,
      courseDescriptions: descriptionArr,
    };
    if (
      PostData["examTypeId"] === null ||
      PostData["examTypeId"] === undefined ||
      PostData["examTypeId"] === ""
    ) {
      delete PostData["examTypeId"];
    }
    if (
      PostData["gradeId"] === null ||
      PostData["gradeId"] === undefined ||
      PostData["gradeId"] === ""
    ) {
      delete PostData["gradeId"];
    }
    dispatch(
      updateCourseDescription(
        PostData,
        CourseID,
        (res: any) => {
          setisEditMode(false);
          dispatch(
            getCourseDescription(
              {},
              (res: any) => {
                setCourseDescList(res);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <>
        {courseDescList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center mt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={courseDescList ? courseDescList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={courseDescList ? courseDescList : []}
          pagename={"CourseDescription"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Exam Name",
            "Main Category ",
            "Exam Description",
            "Action",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };

  const validateDescription = () => {
    let isDescBlank: any = false;
    descriptionArr?.map((item) => {
      if (
        item === "" ||
        item === null ||
        item === undefined ||
        item.length < length_Ten - 1
      ) {
        isDescBlank = true;
      }
    });
    return isDescBlank;
  };

  const validation = () => {
    return (
      courseTitle === "" ||
      courseTitle.length < length_Five - 1 ||
      validateDescription()
    );
  };

  const renderEditMode = () => {
    return (
      <div className="col-7 px-3">
        <form onSubmit={handleSubmit}>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Main Category"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Main Category"
                setInputText={(value: any) => onChangeCategory(value)}
                value={mainCategory}
                options={mainCategoryList}
                Isrequired={true}
                defaultValue={mainCategory ? mainCategory : ""}
                reset={resetMainCategory}
                disabled={isEditMode}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Course Title"} />
            </div>
            <div className="col-8">
              <InputField
                placeholder="Enter Course Title"
                value={courseTitle}
                onChangeInput={(value: string) => onChangeCourseTitle(value)}
                minlength={length_Five}
                maxlength={length_Hundred_TwentyFive}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Exam Description "} />
            </div>

            <div className="col-8  ">
              {descriptionArr.map((item, index) => {
                return (
                  <div className="d-flex jusftify-content-between mt-2">
                    <textarea
                      className="w-100"
                      name="postContent"
                      rows={4}
                      cols={40}
                      onChange={(e: any) => {
                        onChangeDesc(e.target.value, index);
                      }}
                      maxLength={length_FiveHundred}
                      minLength={length_Ten}
                      value={item}
                    />

                    {descriptionArr.length === 1 ? null : (
                      <DeleteIcon
                        className="cursor"
                        color="error"
                        fontSize="small"
                        onClick={() => handleClose(index)}
                      />
                    )}
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>
          <div className="d-flex justify-content-center mt-3">
            <SubmitButton name={"Save & Update"} validation={validation()} />
          </div>
        </form>
      </div>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"UPDATE COURSE DESCRIPTION"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <div className="formDiv">
        <div className="col-7 p-3">
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-8 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                disabled={isEditMode}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2 ">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                Isrequired={true}
                disabled={isEditMode}
                reset={resetParentCourse}
              />
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  disabled={isEditMode}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Exam Name "} />
              </div>
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                  disabled={isEditMode}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
          )}

          <div className="d-flex mb-2 align-items-center">
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-8 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                disabled={isEditMode}
                reset={resetSubject}
              />
            </div>
          </div>
        </div>
        {isEditMode ? renderEditMode() : renderListMode()}
      </div>
    </div>
  );
}
