import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import TextArea from "../../Components/textArea";
import { useEffect, useState } from "react";
import StudentOverallRating from "../../Components/studentOverallRating";
import {
  addLiveFeedback,
  getLiveFeedback,
} from "../../Redux/Actions/AdminAction";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import ReplyIcon from "@mui/icons-material/Reply";

import moment from "moment";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateFeedbackErrMsg } from "../../Utils/errorMsg";
export default function TeacherLiveFeedback() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const classData = location?.state?.classData;
  const PtmData = location?.state?.PtmData;
  const PtmPageName = location?.state?.pageName;
  const isFeedbackPtm = location?.state?.isFeedback;
  const [q1ans, setq1ans] = useState<any>(0);
  const [q2ans, setq2ans] = useState<any>(0);
  const [q3ans, setq3ans] = useState<any>(0);
  const [q4ans, setq4ans] = useState<any>(0);
  const [q5ans, setq5ans] = useState<any>(0);
  const [q6ans, setq6ans] = useState<any>("");
  const [studentName, setStudentName] = useState<any>("");
  const [isFeedback, setIsFeedback] = useState(false);
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  const q1Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Disengaged",
    },
    {
      value: 1,
      label: "1",
      labelText: "Disengaged",
    },
    {
      value: 2,
      label: "2",
      labelText: "Disengaged",
    },
    {
      value: 3,
      label: "3",
      labelText: "Moderately Engaged",
    },
    {
      value: 4,
      label: "4",
      labelText: "Moderately Engaged",
    },
    {
      value: 5,
      label: "5",
      labelText: "Highly Engaged",
    },
  ];
  const q2Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Poor Understanding",
    },
    {
      value: 1,
      label: "1",
      labelText: "Poor Understanding",
    },
    {
      value: 2,
      label: "2",
      labelText: "Poor Understanding",
    },
    {
      value: 3,
      label: "3",
      labelText: "Average Understanding",
    },
    {
      value: 4,
      label: "4",
      labelText: "Average Understanding",
    },
    {
      value: 5,
      label: "5",
      labelText: "Excellent Understanding",
    },
  ];

  const q3Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Unresponsive",
    },
    {
      value: 1,
      label: "1",
      labelText: "Unresponsive",
    },
    {
      value: 2,
      label: "2",
      labelText: "Unresponsive",
    },
    {
      value: 3,
      label: "3",
      labelText: "Somewhat Responsive",
    },
    {
      value: 4,
      label: "4",
      labelText: "Somewhat Responsive",
    },
    {
      value: 5,
      label: "5",
      labelText: "Very Responsive",
    },
  ];
  const q4Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Unprepared",
    },
    {
      value: 1,
      label: "1",
      labelText: "Unprepared",
    },
    {
      value: 2,
      label: "2",
      labelText: "Unprepared",
    },
    {
      value: 3,
      label: "3",
      labelText: "Adequately Prepared",
    },
    {
      value: 4,
      label: "4",
      labelText: "Adequately Prepared",
    },
    {
      value: 5,
      label: "5",
      labelText: "Well Prepared",
    },
  ];
  const q5Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Regressed",
    },
    {
      value: 1,
      label: "1",
      labelText: "Regressed",
    },
    {
      value: 2,
      label: "2",
      labelText: "Regressed",
    },
    {
      value: 3,
      label: "3",
      labelText: "No Change",
    },
    {
      value: 4,
      label: "4",
      labelText: "No Change",
    },
    {
      value: 5,
      label: "5",
      labelText: "Improved",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (PtmPageName === "PtmFeedback") {
      const postData = {
        lectureId: PtmData?.lecture?.lectureId,
        teacherId: PtmData?.teacherId,
        studentId: PtmData?.studentId,
      };
      dispatch(
        getLiveFeedback(
          postData,
          (data: any) => {
            setStudentName(data?.data?.name);
            if (data?.data?.feedback?.length > 0) {
              setIsFeedback(true);
            } else {
              setIsFeedback(false);
            }
            setq1ans(
              data?.data?.feedback[1]?.answer
                ? data?.data?.feedback[1]?.answer
                : 0
            );
            setq2ans(
              data?.data?.feedback[2]?.answer
                ? data?.data?.feedback[2]?.answer
                : 0
            );
            setq3ans(
              data?.data?.feedback[3]?.answer
                ? data?.data?.feedback[3]?.answer
                : 0
            );
            setq4ans(
              data?.data?.feedback[4]?.answer
                ? data?.data?.feedback[4]?.answer
                : 0
            );
            setq5ans(
              data?.data?.feedback[5]?.answer
                ? data?.data?.feedback[5]?.answer
                : 0
            );
            setq6ans(
              data?.data?.feedback[6]?.answer
                ? data?.data?.feedback[6]?.answer
                : ""
            );
          },
          () => { }
        )
      );
    } else {
      const postData = {
        lectureId: classData?.lectureId,
        teacherId: userId,
        studentId: classData?.studentId,
      };
      dispatch(
        getLiveFeedback(
          postData,
          (data: any) => {
            if (data?.data?.feedback?.length > 0) {
              setIsFeedback(true);
            } else {
              setIsFeedback(false);
            }
            setq1ans(
              data?.data?.feedback[1]?.answer
                ? data?.data?.feedback[1]?.answer
                : 0
            );
            setq2ans(
              data?.data?.feedback[2]?.answer
                ? data?.data?.feedback[2]?.answer
                : 0
            );
            setq3ans(
              data?.data?.feedback[3]?.answer
                ? data?.data?.feedback[3]?.answer
                : 0
            );
            setq4ans(
              data?.data?.feedback[4]?.answer
                ? data?.data?.feedback[4]?.answer
                : 0
            );
            setq5ans(
              data?.data?.feedback[5]?.answer
                ? data?.data?.feedback[5]?.answer
                : 0
            );
            setq6ans(
              data?.data?.feedback[6]?.answer
                ? data?.data?.feedback[6]?.answer
                : ""
            );
          },
          () => { }
        )
      );
    }
  }, [classData, PtmData]);
  const onChangeQ1Star = (val: any) => {
    setq1ans(val);
  };
  const onChangeQ2Star = (val: any) => {
    setq2ans(val);
  };
  const onChangeQ3Star = (val: any) => {
    setq3ans(val);
  };
  const onChangeQ4Star = (val: any) => {
    setq4ans(val);
  };
  const onChangeQ5Star = (val: any) => {
    setq5ans(val);
  };

  const onClickBack = () => {
    if (PtmPageName === "PtmFeedback") {
      navigate("/OperationPanel/PtmFeedback");
    } else {
      navigate("/TeachersPanel/ClassConducted");
    }
  };

  return (
    <div className="feedbackPage p-4">
      <div className="feedbackCard rounded shadow-sm">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="feedbackHeader d-flex align-items-center">
              <div className="col-md-10">
                <h4 className="boldfont fw-bold  mb-2">
                  Live Session Feedback
                </h4>
                <div className=" fw-bold mb-1">
                  {PtmPageName === "PtmFeedback" ? (
                    <div>Student Name : {studentName}</div>
                  ) : (
                    <div>
                      Student Name :{" "}
                      {`${classData?.firstName} ${classData?.lastName}`}
                    </div>
                  )}
                </div>
                <div className="d-flex  justify-content-between">
                  <div>
                    <label className="fw-bold  me-2 mb-2">
                      Date of the Live Session :{" "}
                      {moment(classData?.scheduleFrom).format("LL")}{" "}
                      {classData?.time}
                    </label>
                  </div>
                </div>
              </div>
              <div className=" ">
                <button
                  type="button"
                  className="btn bgOrangeWithTextWhite font12"
                  // onClick={() => navigate("/TeachersPanel/ClassConducted")}
                  onClick={() => onClickBack()}
                >
                  <ReplyIcon /> Back
                </button>
              </div>
            </div>
            <div className="p-4">
              <div className="my-2">
                <div className="fw-bold mb-2">
                  1.Engagement Level: How engaged was the student during the
                  session?
                </div>
                <div className="mx-5 mt-3">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ1Star(val)}
                    val={q1ans}
                    marks={q1Marks}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  2. Understanding of Material: Rate the student's understanding
                  of the material covered.
                </div>
                <div className="mx-5 mt-3">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ2Star(val)}
                    val={q2ans}
                    marks={q2Marks}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  3. Responsiveness: How responsive was the student to questions
                  and prompts?
                </div>

                <div className="mx-5 mt-3 bookSessionOrange">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ3Star(val)}
                    val={q3ans}
                    marks={q3Marks}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  4. Preparation for Session: Was the student well-prepared for
                  the session?
                </div>
                <div className="mx-5 mt-3 bookSessionOrange">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ4Star(val)}
                    val={q4ans}
                    marks={q4Marks}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  4. Progress Tracking: Rate the student’s progress compared to
                  previous sessions.
                </div>
                <div className="mx-5 mt-3 bookSessionOrange">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ5Star(val)}
                    val={q5ans}
                    marks={q5Marks}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>

              <div className="mt-5">
                <div className="fw-bold mb-2">
                  6. Quick Comment: Any quick comment or suggestion for the
                  student? (Optional)
                </div>
                <div className="mt-3">
                  <TextArea
                    setValue={(val: any) => setq6ans(val)}
                    value={q6ans}
                    disabled={isFeedback || isFeedbackPtm}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
