import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import { getDepartment } from "../../Redux/Actions/SuperAdminAction";
import SubmitButton from "../../Components/submitButton";
import {
  validateIsNumberOnly,
  validatePassword,
  startsWithSpace,
  hasWhiteSpace,
  validateIsLettersAndDigitsOnly,
  emailCheck,
  length_Ten,
  length_Six,
  length_Hundred,
  length_OneHundredFifty,
  length_Fifty,
  length_Five,
  length_Three,
  validateUniqueId,
  length_Four,
  length_One,
  length_TwoHundred,
  length_Eight,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

import { addDsaUser, getCountryList } from "../../Redux/Actions/AdminAction";
import Addmorebtn from "../../Components/addmorebtn";
import DeleteIcon from "@mui/icons-material/Delete";

export default function CreateDsaUser() {
  const dispatch: Function = useDispatch();
  const [companyName, setCompanyName] = useState<string>("");
  const [companyId, setCompanyId] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentList, setDepartmentList] = useState([]);
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [uniqueID, setUniqueId] = useState<any>("");
  const initialVal: {
    countryId: any;
    charges: any;
  }[] = [
    {
      countryId: "",
      charges: "",
    },
  ];
  const [priceArr, setPriceArr] = useState(initialVal);
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 19);
          setDepartmentList(tempStatus);
        },
        () => {}
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    setDepartmentList(Object(departmentList));
  }, []);

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeEmpName = (value: any) => {
    setCompanyName(value);
  };
  // const onChangeEmpId = (value: any) => {
  //   if (validateIsLettersAndDigitsOnly(value)) {
  //     setCompanyId(value);
  //   } else {
  //     dispatch(showErrorSnackbar("Please only Character & Number"));
  //     return;
  //   }
  // };
  const onChangepwd = (value: any) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };
  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }
  const onchangeUniqueId = (val: string) => {
    if (validateUniqueId(val) && !startsWithSpace(val)) {
      setUniqueId(val?.toUpperCase());
    } else {
      dispatch(
        showErrorSnackbar("Please Character only & space is not allowed") as any
      );
      return;
    }
  };

  const onPressAdd = () => {
    setPriceArr([...priceArr, initialVal[0]]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...priceArr];
    newArrr.splice(index, 1);
    setPriceArr(newArrr);
  };

  function onchangePrice(val: any, index: any, key: any) {
    let newArr = JSON.parse(JSON.stringify(priceArr));
    if (key) {
      if (key === "charges") {
        if (!validateIsNumberOnly(val)) {
          newArr[index][key] = val;
          setPriceArr(newArr);
        } else {
          dispatch(showErrorSnackbar("Please enter number only"));
          return;
        }
      } else if (key === "countryId") {
        newArr[index][key] = val?._id;
        setPriceArr(newArr);
      }
      setPriceArr(newArr);
    }
  }

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData = {
        name: companyName,
        // empCreatedId: companyId,
        departmentId: 16,
        userRoleId: 21,
        username: userName,
        password: password,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        uniqueId: uniqueID,
        charges: priceArr,
      };
      dispatch(
        addDsaUser(
          postData,
          (res: any) => {
            setCompanyName("");
            // setCompanyId("");
            setMobileNo("");
            setEmail("");
            setDateOfJoining("");
            setUserName("");
            setPassword("");
            setUniqueId("");
            setPriceArr(initialVal);
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  const validatePrice = () => {
    let isPriceArr: boolean = false;
    priceArr?.map((item: any) => {
      if (
        item?.countryId === "" ||
        item?.charges === "" ||
        item?.charges?.length < 1
      ) {
        isPriceArr = true;
      }
    });
    return isPriceArr;
  };

  const validation = () => {
    return (
      companyName === "" ||
      companyName.length < length_Three ||
      // companyId === "" ||
      // companyId.length < length_One ||
      userName === "" ||
      userName.length < length_Five ||
      mobileNo === "" ||
      mobileNo?.length < length_Eight ||
      password === "" ||
      password.length < length_Five ||
      dateOfJoining === "" ||
      uniqueID === "" ||
      !allemail(email) ||
      validatePrice()
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"DSA COMPANY CREATE"} />
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-10 px-4 mx-0 mt-4 ">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Company Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Company Name"
                value={companyName}
                onChangeInput={(value: any) => onChangeEmpName(value)}
                maxlength={length_TwoHundred}
                minlength={length_One}
                isRequired
              />
            </div>
          </div>
          {/* <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Company ID"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Company ID"
                value={companyId}
                onChangeInput={(value: any) => onChangeEmpId(value)}
                maxlength={length_Six}
                isRequired
              />
            </div>
          </div> */}

          <div className="d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Mobile Number"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChangeInput={(value: any) => onChangeMobileNO(value)}
                maxlength={length_Ten}
                minlength={length_Eight}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Email Address"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => onChangeEmail(value)}
                maxlength={length_OneHundredFifty - 1}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Unique Id"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                maxlength={length_Four - 1}
                minlength={length_Three - 1}
                isRequired={true}
              />
            </div>
          </div>
          <div className=" d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Date Of Align With Us"} />
            </div>
            <div className="col-6">
              <DatePicker
                value={dateOfJoining}
                setInputText={(value: string) => setDateOfJoining(value)}
                Isrequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Username"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Username"
                value={userName}
                onChangeInput={(value: any) => onChangeUserName(value)}
                maxlength={length_OneHundredFifty}
                minlength={length_Five}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Password"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangepwd(value)}
                maxlength={length_Fifty}
                minlength={length_Five}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Demo Price"} />
            </div>
            <div className=" col-8">
              {priceArr.map((item: any, index: any) => {
                const { countryId, charges } = item;
                return (
                  <div className="d-flex p-2">
                    <div className=" d-flex col-9 border rounded p-3">
                      <div className="  input-border">
                        <div className="d-flex Justify-content-between">
                          <div className="col-4 align-item-center d-flex">
                            <LabelField lableName={"Country"} />
                          </div>
                          <div className="col-12">
                            <CustomDropdown
                              lableName="Select Country"
                              setInputText={(value: any) =>
                                onchangePrice(value, index, "countryId")
                              }
                              value={countryId?._id}
                              options={countryList}
                              Isrequired={true}
                              reset={resetCountryList}
                            />
                          </div>
                        </div>
                        <div className="d-flex Justify-content-between">
                          <div className="col-4 align-item-center d-flex">
                            <LabelField lableName={"Price"} />
                          </div>
                          <div className="col-12">
                            <InputField
                              placeholder="Enter Price"
                              value={charges}
                              onChangeInput={(value: any) =>
                                onchangePrice(value, index, "charges")
                              }
                              maxlength={length_Ten}
                              minlength={length_One}
                              isRequired
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="col-9 p-2 d-flex removeButton">
                      {priceArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-end col-8">
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <SubmitButton name="Create User" validation={validation()} />
        </div>
      </form>
    </div>
  );
}
