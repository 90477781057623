
import PageTitle from '../../Components/pageTitle'
import { useDispatch} from 'react-redux';
export default function MyDemo() {
  const dispatch = useDispatch();
  

  return (
    <div className="contentBox">
      <PageTitle name={"MY DEMO"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <h5 className='fw-bold'>Demo Booking</h5>
        <div className='demoCard mt-4'>
          <div className='text-end'>Conduct this demo and earn upto <span className=''>200</span></div>
          <div className='row mt-4'>
          <div className='col-md-6'>
            <h4>Grade: 4th</h4>
            <div className='font16 mb-2'>Name: Sanjana</div>
            <div className='font16 mb-2'>Child Name: Sanjana</div>
            <div className='font16 mb-2'>Phone: +9134555454</div>
            <div className='font16 mb-2'>Email:  example@gmail.com</div>
            <div className='font16 mb-2'>Platform: PlanetSpark Platform</div>
            <div className='font16 mb-2'>Notes: Parent agreed to join the class</div>
            <div className='font16 mb-2'>Source: Other</div>
            <div className='font16 mb-2'>Source Info: </div>
          </div>
          <div className='col-md-6'>
            <h4>Tomorrow, 11:00 AM</h4>
            <div>Notes:</div>
          </div>
          </div>
         <div className='d-flex justify-content-between border-top pt-3 align-items-center'>
          <span className='font16'>Rescheduled</span>
          <div><button type="button" className="btn btn-primary">Update Status</button></div>
          <div className='d-flex'>
            <div className='DemoRecordgrade p-1 me-2 rounded px-2'>Free Call</div>
            <div className='DemoRecordgrade p-1 rounded px-2'>Join Class</div>
          </div>
         </div>
        </div>
      </div>
    </div>
  )
}

