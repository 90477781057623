import PageTitle from "../../Components/pageTitle";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getCourseTypelist,
  getDsaSalesList,
  getManagerByCompany,
  getScheduledList,
} from "../../Redux/Actions/AdminAction";
import SubmitButton from "../../Components/submitButton";
import AdminTable from "../../Components/adminTable";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import DsaDemoDetail from "../../Components/dsaDemoDetail";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
export default function DemoConducted() {
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [endDate, setEndDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [startDate, setStartDate] = useState(
    moment(endDate).subtract(6, "days").format("YYYY-MM-DD")
  );
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [demoScheduleList, setDemoScheduleList] = useState([]);
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });
  const [countryList, setCountryList] = useState<any>([]);
  const [resetCourse, setResetCourse] = useState(false);

  const [dsaManager, setDsaManager] = useState<any>("");
  const [dsaManagerList, setDsaManagerList] = useState<any>([]);
  const [resetDsaManager, setResetDsaManager] = useState<any>(false);

  const [sales, setSales] = useState<any>("");
  const [salesList, setSalesList] = useState([]);
  const [resetSales, setResetSales] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [demoData, setDemoData] = useState<any>("");
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );

    dispatch(
      getManagerByCompany(
        userId,
        (res: any) => {
          setDsaManagerList(res);
        },
        () => {}
      )
    );

    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 2,
      statusId: [5, 7, 8],
      countryId: country?._id,
      companyId: userId,
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (dsaManager) {
      postData["managerId"] = dsaManager?._id;
    }
    if (sales) {
      postData["salesId"] = sales?._id;
    }

    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 2,
      statusId: [5, 7, 8],
      countryId: country?._id,
      companyId: userId,
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (dsaManager) {
      postData["managerId"] = dsaManager?._id;
    }
    if (sales) {
      postData["salesId"] = sales?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    const postdata = {
      countryId: data?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 2,
      statusId: [5, 7, 8],
      countryId: data?._id,
      companyId: userId,
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (dsaManager) {
      postData["managerId"] = dsaManager?._id;
    }
    if (sales) {
      postData["salesId"] = sales?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };
  const onChangedsaManager = (value: any) => {
    setDsaManager(value);

    const postData: any = {
      empId: value?._id,
    };

    dispatch(
      getDsaSalesList(
        postData,
        (res: any) => {
          setSalesList(res?.data);
        },
        () => {}
      )
    );
  };
  const onChangeSales = (value: any) => {
    setSales(value);
    setResetSales(false);
  };
  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      sourceId: 2,
      statusId: [5, 7, 8],
      countryId: country?._id,
      coursetypeId: value?._id,
      companyId: userId,
    };
    if (dsaManager) {
      postData["managerId"] = dsaManager?._id;
    }
    if (sales) {
      postData["salesId"] = sales?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };
  const onChnageStartDate = (value: any) => {
    if (
      moment(value).format("YYYY-MM-DD") > moment(endDate).format("YYYY-MM-DD")
    ) {
      dispatch(
        showErrorSnackbar("Start date should be lass than End date") as any
      );
    } else {
      setStartDate(value);
    }
  };
  const handleEdit = (value: any) => {
    setOpenPopup(true);
    setDemoData(value);
  };
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"DEMO CONDUCTED"} />
      <div className="px-4 mx-0 mt-2 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="row d-flex flex-wrap mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(startDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => onChnageStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <LabelField lableName={"Manager"} />
              <div className="col-md-8 ">
                <CustomDropdown
                  lableName="Select Manager Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangedsaManager(value)
                  }
                  value={dsaManager}
                  options={dsaManagerList}
                  reset={resetDsaManager}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <LabelField lableName={"Demo Scheduling Agent"} />
              <div className="col-md-8">
                <CustomDropdown
                  lableName="Select Demo Scheduling Agent"
                  setInputText={(value: any) => onChangeSales(value)}
                  value={sales}
                  options={salesList}
                  reset={resetSales}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
          <div className="demoReqCountry mt-4">
            <div className="d-flex">
              <h5 className="font-weight-bold me-5">Country</h5>
              {countryList?.map((item: any, index: any) => {
                return (
                  <button
                    className={`btn btn-sm me-4 ${
                      selectedIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                    }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                );
              })}
            </div>
            <div className="col-md-3 d-flex  align-items-center pe-2 mt-2 ms-3">
              <LabelField lableName={"Course type"} />
              <div className="col-md-8">
                <CustomDropdown
                  lableName="Select CourseType Name"
                  setInputText={(value: any) => onChangeCourse(value)}
                  value={course}
                  options={courseList}
                  reset={resetCourse}
                />
              </div>
            </div>
          </div>
          <div className="row">
            <>
              <AdminTable
                tableData={demoScheduleList ? demoScheduleList : []}
                pagename={"DsaDemoConducted"}
                tableHead={[
                  "S.N",
                  "NAME",
                  "COUNTRY",
                  "COURSE",
                  "DEMO REQUESTED DATE",
                  "DEMO DATE",
                  "DEMO SCHEDULING AGENT",
                  "MANAGER",
                  "SUBJECT",
                  "ACTION",
                ]}
                handleEdit={(value: any) => handleEdit(value)}
                edit={true}
              />
            </>
          </div>
          <DsaDemoDetail
            open={openPopup}
            data={demoData}
            handleClose={handleClose}
          />
        </form>
      </div>
    </div>
  );
}
