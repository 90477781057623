import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Snackbar } from '@mui/material';
import MuiAlert from '@mui/material/Alert';
import { clearSnackbar } from '../Redux/Actions/snackbarAction';
import { SnakbarReducerModal } from '../Modals';
const Alert = React.forwardRef(function Alert(props: any, ref: any) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
function SuccessSnackbar() {
    const dispatch: Function = useDispatch();
    const { successSnackbarOpen, errorSnackbarOpen, infoSnackbarOpen, message } = useSelector((state: { uiReducer: SnakbarReducerModal }) => ({
        successSnackbarOpen: state.uiReducer.successSnackbarOpen,
        errorSnackbarOpen: state.uiReducer.errorSnackbarOpen,
        infoSnackbarOpen: state.uiReducer.infoSnackbarOpen,
        message: state.uiReducer.message,
    })
    );

    function handleClose() {
        dispatch(clearSnackbar());
    }
    return (

        <Snackbar open={successSnackbarOpen} autoHideDuration={6000} onClose={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {message}
            </Alert>
        </Snackbar>
    );
}

export default SuccessSnackbar;