import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import TextArea from "../../Components/textArea";
import { useEffect, useState } from "react";
import StudentOverallRating from "../../Components/studentOverallRating";
import { useLocation, useNavigate } from "react-router-dom";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import RatingStar from "../../Components/ratingStar";
import ReplyIcon from "@mui/icons-material/Reply";
import { getLiveStudentFeedbackList } from "../../Redux/Actions/AdminAction";
import moment from "moment";

export default function StudentLiveFeedback() {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const liveClassData = location?.state?.liveClassData;
  const isFeedback = location?.state?.isFeedback;
  const [q1ans, setq1ans] = useState(0);
  const [q2ans, setq2ans] = useState(0);
  const [q3ans, setq3ans] = useState(0);
  const [q5anstxt, setq5anstxt] = useState("");
  const [q4ans, setq4ans] = useState(0);
  const [q5ans, setq5ans] = useState("");
  const [q6ans, setq6ans] = useState("");

  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };
  const q2Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Very Unclear",
    },
    {
      value: 1,
      label: "1",
      labelText: "Very Unclear",
    },
    {
      value: 2,
      label: "2",
      labelText: "Very Unclear",
    },
    {
      value: 3,
      label: "3",
      labelText: "Somewhat Clear",
    },
    {
      value: 4,
      label: "4",
      labelText: "Somewhat Clear",
    },
    {
      value: 5,
      label: "5",
      labelText: "Very Clear",
    },
  ];

  const q3Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Disengaged",
    },
    {
      value: 1,
      label: "1",
      labelText: "Disengaged",
    },
    {
      value: 2,
      label: "2",
      labelText: "Disengaged",
    },
    {
      value: 3,
      label: "3",
      labelText: "Somewhat Interactive",
    },
    {
      value: 4,
      label: "4",
      labelText: "Somewhat Interactive",
    },
    {
      value: 5,
      label: "5",
      labelText: "Very Interactive",
    },
  ];
  const q4Marks: any = [
    {
      value: 0,
      label: "0",
      labelText: "Too Fast",
    },
    {
      value: 1,
      label: "1",
      labelText: "Too Fast",
    },
    {
      value: 2,
      label: "2",
      labelText: "Too Fast",
    },
    {
      value: 3,
      label: "3",
      labelText: "Just Right",
    },
    {
      value: 4,
      label: "4",
      labelText: "Just Right",
    },
    {
      value: 5,
      label: "5",
      labelText: "Too Slow",
    },
  ];
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const postData = {
      lectureId: liveClassData?.lectureId,
      studentId: liveClassData?.studentId,
      teacherId: liveClassData?.teacherId,
    };

    dispatch(
      getLiveStudentFeedbackList(
        postData,
        (res: any) => {
          setq1ans(res?.data[0]?.feedback[0]?.answer);
          setq2ans(parseInt(res?.data[0]?.feedback[1]?.answer));
          setq3ans(parseInt(res?.data[0]?.feedback[2]?.answer));
          setq4ans(parseInt(res?.data[0]?.feedback[3]?.answer));
          setq5ans(res?.data[0]?.feedback[4]?.answer === "Yes" ? "Yes" : "No");
          if (res?.data[0]?.feedback[4]?.answer === "Yes") {
            setq5anstxt("");
          } else {
            setq5anstxt(res?.data[0]?.feedback[4]?.answer?.replace("No, ", ""));
          }
          setq6ans(res?.data[0]?.feedback[5]?.answer);
        },
        () => {}
      )
    );
  }, []);

  const onChangeQ1Star = (val: any) => {
    setq1ans(val);
  };
  const onChangeQ2Star = (val: any) => {
    setq2ans(val);
  };
  const onChangeQ3Star = (val: any) => {
    setq3ans(val);
  };
  const onChangeQ4Star = (val: any) => {
    setq4ans(val);
  };

  const onClickBack = () => {
    navigate("/OperationPanel/LiveClassStudentFeedback");
  };

  return (
    <div className="feedbackPage p-5">
      <div className="feedbackCard rounded shadow-sm">
        <div className="row">
          <div className="col-md-12 px-0">
            <div className="feedbackHeader d-flex align-items-center">
              <div className="d-flex justify-content-between col-md-10">
                <div className="">
                  <h4 className="boldfont fw-bold  mb-2">
                    Live Session Feedback
                  </h4>
                  <div className=" fw-bold mb-1">
                    <div>Tutor Name : {liveClassData?.teacherName}</div>
                  </div>
                  <div className="d-flex  justify-content-between">
                    <div>
                      <label className="fw-bold  me-2 mb-2">
                        Date of the Live Session :{" "}
                      </label>
                      {moment(liveClassData?.scheduleTo).format("LL")}
                    </div>
                  </div>
                </div>
              </div>
              <div className=" ">
                <button
                  type="button"
                  className="btn bgOrangeWithTextWhite font12"
                  // onClick={() => navigate("/TeachersPanel/ClassConducted")}
                  onClick={() => onClickBack()}
                >
                  <ReplyIcon /> Back
                </button>
              </div>
            </div>
            <div className="p-4">
              <div className="my-2">
                <div className="fw-bold mb-2">
                  1.Rating (Overall Experience): Please rate your overall
                  experience today on a scale from 1 to 5, where 5 is excellent
                  and 1 is poor.
                </div>
                <div className="mt-3">
                  <RatingStar
                    setValue={(val: any) => onChangeQ1Star(val)}
                    value={q1ans}
                    label={labels}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  2. Clarity of Explanation: Rate the clarity of the tutor's
                  explanations.
                </div>
                <div className="mx-4 mt-3">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ2Star(val)}
                    val={q2ans}
                    marks={q2Marks}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  3. Engagement Level: Rate the level of interaction and
                  engagement from the tutor.
                </div>

                <div className="mx-4 mt-3 bookSessionOrange">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ3Star(val)}
                    val={q3ans}
                    marks={q3Marks}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  4. Pace of the Session: Was the pace of the session
                  appropriate?
                </div>
                <div className="mx-4 mt-3 bookSessionOrange">
                  <StudentOverallRating
                    setValue={(val: any) => onChangeQ4Star(val)}
                    val={q4ans}
                    marks={q4Marks}
                    disabled={isFeedback}
                  />
                </div>
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  5. Would you recommend this tutor to a friend?
                </div>

                <div className="d-flex mt-3">
                  <div
                    className={`rounded-pill py-1 px-3 d-flex align-items-center cursor yesNoCard ${
                      q5ans === "Yes" ? "active" : ""
                    }`}
                    onClick={isFeedback ? undefined : () => setq5ans("Yes")}
                  >
                    YES{" "}
                    <ThumbUpIcon
                      className={`font16 ms-2 thumbsIcon ${
                        q5ans === "Yes" ? "active" : ""
                      }`}
                    />
                  </div>
                  <div
                    className={`rounded-pill py-1 px-3 d-flex align-items-center cursor yesNoCard ms-2 ${
                      q5ans === "No" ? "active" : ""
                    }`}
                    onClick={isFeedback ? undefined : () => setq5ans("No")}
                  >
                    No{" "}
                    <ThumbDownIcon
                      className={`font16 ms-2 thumbsIcon ${
                        q5ans === "No" ? "active" : ""
                      }`}
                    />
                  </div>
                </div>
                {q5ans === "No" ? (
                  <div className="mt-3">
                    <TextArea
                      disabled={isFeedback}
                      setValue={(val: any) => setq5anstxt(val)}
                      value={q5anstxt}
                    />
                  </div>
                ) : null}
              </div>
              <div className="mt-5">
                <div className="fw-bold mb-2">
                  6. Quick Comment: Any quick comment or suggestion? (Optional)
                </div>
                <div className="mt-3">
                  <TextArea
                    disabled={isFeedback}
                    setValue={(val: any) => setq6ans(val)}
                    value={q6ans}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
