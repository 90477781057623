 
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import moment from "moment";
import localImages from "../Constant/localImages";
import { teacherNotification } from "../Redux/Actions/AdminAction";
import { useNavigate } from "react-router-dom";
 
export default function NotificationDropDown() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [toggle, setToggle] = useState("Live");
  const [notificationList, setNotificationList] = useState([]);
  const [notificationFilter, setNotificationFilter] = useState<any>([]);
  const [open, setopen] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const tab = [
    {
      _id: "1",
      name: "Live",
    },
    {
      _id: "2",
      name: "Demo",
    },
  ];

  useEffect(() => {
    dispatch(
      teacherNotification(
        '',
        (data: any) => {
          setNotificationList(data);
          // const filter = data?.filter(
          //   (item: any) => item.notificationType?.name === "Class Schedule"
          // );
          // const groups = filter?.reduce((groups: any, game: any) => {
          //   const date = game?.scheduledAt.split("T")[0];
          //   if (!groups[date]) {
          //     groups[date] = [];
          //   }
          //   groups[date].push(game);
          //   return groups;
          // }, {});

          // // Edit: to add it in the array format instead
          // const groupArrays = Object.keys(groups).map((date) => {
          //   return {
          //     date,
          //     notification: groups[date],
          //   };
          // });

          // setNotificationFilter(groupArrays);
        },
        () => { }
      )
    );
  }, []);
  const onCardpress = (c:any) => { 
    navigate("/TeachersPanel/notification");
    
  };
 
 
  const handleChangetab = (val: any) => {
    setToggle(val);

  };
  const renderNotification = ()=> {
            return(
              <>
        { notificationList.map((c:any , index:any)=>{  
          return(  
           <div >
                 <div className=" d-flex align-items-center boxshadow border border-2 p-4 cursor mb-2 rounded" onClick={() => onCardpress(c)} >
                 <div className="col-2 col-sm-1 justify-centent-end">
                                    <img
                                      src={c.notificationIcon}
                                      alt="icon"
                                      className="notiicon"
                                    />
                                  </div>
                                  <div className="col-7 col-sm-8 ">
                                    <p className="font12 fw-bold text-success  m-0 ellipsisNotificationTitle">
                                      {c.notificationTitle}
                                    </p>

                                    <p className="font12 m-0 ellipsisNotification text-break">
                                      {c.notificationText}
                                    </p>
                                  </div>
                                  <div className="col-3 d-flex justify-content-end">
                                    <p className="font12 text-secondary">{`${moment(
                                     c.scheduledAt,
                                      "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                                    ).format("h:mm a")}`}</p>
                                  </div>
                </div>
                </div>)})}</>
 ) }
  return (
    <div className="shadow position-absolute rounded font-12 row notify"> 
      <div className="px-3 mx-0 mt-4 ">
        <div className="fw-bold">
          Notification
        </div>
        <div className="d-flex justify-content-between ">
          <div className="col-md-12 p-2 ">
            <div className="d-flex mt-2  border-bottom  ">
              {tab?.map((c, index) => (
                <div
                  className={`col-md-4 fs-6 text-center cursor fw-bold ${
                    toggle === c.name
                      ? " text-primary border-bottom border-primary border-3"
                      : "text-secondary"
                  }`}
                  onClick={() => handleChangetab(c)}
                >
                  {c.name}
                </div>
              ))}
            </div>
            {toggle === "Live" ? (
              <div className="mt-4 notification ">
                 {renderNotification()}   
              </div>
            ) : (
              ""
            )}
            {toggle === "Demo" ? <div className="mt-4 notification  "> {renderNotification()}</div> : ""} 
         
          </div>
         
        </div>
     
      </div>
    </div>
  );
}
