import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  addLecture,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getSubTopicList,
  getTopicList,
} from "../../Redux/Actions/AdminAction";
import InputField from "../../Atoms/InputField";
import Submitbtn from "../../Components/submitButton";
import {
  length_Hundred,
  length_Two,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import { GradeList, levelList } from "../../Utils/dataConstant";
import DeleteIcon from "@mui/icons-material/Delete";
import Addmorebtn from "../../Components/addmorebtn";

export default function CreateLecture() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [topic, setTopic] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);
  const [subTopic, setsubTopic] = useState("");
  const [subTopicList, setsubTopicList] = useState([]);
  const [resetsubTopic, setResetsubTopic] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);

  const initialVal: {
    lectureName: string;
    priorityOrder: any;
    extra: any;
  }[] = [
      {
        lectureName: "",
        priorityOrder: "",
        extra: false,
      },
    ];
  const [lectureArr, setLectureArr] = useState<any>(initialVal);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [level, setLevel] = useState("");
  const [resetLevel, setResetLevel] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    setResetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetSubject(true);
          setResetTopic(true);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setLevel(value._id);
    setResetLevel(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      levelId: value._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
          setResetSubject(false);
          setResetTopic(true);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
  };
  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value._id);
    setResetTopic(false);
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      levelId: level,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setsubTopicList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setsubTopic(value._id);
    setResetsubTopic(false);
  };
  const onPressAdd = () => {
    setLectureArr([...lectureArr, initialVal[0]]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...lectureArr];
    newArrr.splice(index, 1);
    setLectureArr(newArrr);
  };

  function onchangeLectureArr(val: any, index: any, key: any) {
    let newArr = JSON.parse(JSON.stringify(lectureArr));
    if (key) {
      if (key === "extra") {
        if (val.target.checked) {
          newArr[index]["extra"] = val.target.checked;
          setLectureArr(newArr);
        }
        else {
          newArr[index]["extra"] = val.target.checked;
          setLectureArr(newArr);
        }
      }
      else {
        newArr[index][key] = val;
        setLectureArr(newArr);
      }

    }
  }
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      levelId: level,
      topicId: topic,
      subTopicId: subTopic,
      lectureList: lectureArr,
    };
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    dispatch(
      addLecture(
        Postdata,
        (res: any) => {
          setResetCourseType(true);
          setResetExam(true);
          setResetSubject(true);
          setResetTopic(true);
          setResetsubTopic(true);
          setResetGradeList(true);
          setLectureArr([initialVal[0]]);
          setLevel("");
          setResetLevel(true);
          setResetCountryList(true);
          lectureArr.map((item: any) => (item.extra = false));
        },
        () => { }
      )
    );
  };
  const validateLecture = () => {
    let isLectureArr: boolean = false;
    lectureArr?.map((item: any) => {
      if (
        item?.lectureName === "" ||
        item?.priorityOrder === "" ||
        item?.lectureName?.length < length_Two + 1
      ) {
        isLectureArr = true;
      }
    });
    return isLectureArr;
  };
  const validation = () => {
    return (
      courseType === "" ||
      subject === "" ||
      topic === "" ||
      subTopic === "" ||
      level === "" ||
      validateLecture()
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE LECTURE"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10 pt-2" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                reset={resetCourseType}
                Isrequired={true}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2  ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  reset={resetExam}
                  Isrequired={true}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Level"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Level"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeLevel(value)
                }
                value={level}
                options={levelList}
                Isrequired={true}
                reset={resetLevel}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Topic Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Topic Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeTopic(value)
                }
                value={topic}
                options={topicList}
                Isrequired={true}
                reset={resetTopic}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Sub-Topic Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Sub-Topic Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubTopic(value)
                }
                value={subTopic}
                options={subTopicList}
                Isrequired={true}
                reset={resetsubTopic}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Lecture"} />
            </div>
            <div className=" col-8">
              {lectureArr.map((item: any, index: any) => {
                const { lectureName, priorityOrder, extra } = item;
                return (
                  <div className="d-flex p-2">
                    <div className=" d-flex col-9 border rounded p-3">
                      <div className=" input-border">
                        <div className="d-flex jusftify-content-between mt-2">
                          <div className="col-4 p-2">
                            <InputField
                              placeholder="Enter Lecture Name"
                              value={lectureName}
                              onChangeInput={(value: any) =>
                                onchangeLectureArr(value, index, "lectureName")
                              }
                              maxlength={length_TwoHundredFifty - 1}
                              minlength={length_Two + 1}
                              isRequired
                            />
                          </div>
                          <div className="col-4 p-2">
                            <InputField
                              placeholder="Enter Priority Order"
                              value={priorityOrder}
                              onChangeInput={(value: any) =>
                                onchangeLectureArr(
                                  value,
                                  index,
                                  "priorityOrder"
                                )
                              }
                              maxlength={length_Hundred - 1}
                              isRequired
                            />
                          </div>
                          <div className="col-4 p-2 d-flex">
                            <LabelField lableName={"Extra Lecture"} />
                            <input
                              className="check"
                              type="checkbox"
                              checked={extra?.target?.checked}
                              onChange={(value: any) => {
                                onchangeLectureArr(value, index, "extra");
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="col-9 p-2 d-flex removeButton">
                      {lectureArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-end col-8 p-2">
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
