import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  dateFormat,
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SubmitButton from "../../Components/searchButton";
import LeadDeatilpopup from "../../Components/leadDeatilpopup";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { getRole } from "../../Redux/Actions/SuperAdminAction";
import { useDispatch } from "react-redux";
import {
  getAcademicLeads,
  getCountryList,
} from "../../Redux/Actions/AdminAction";
import ExportButton from "../../Components/exportButton";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";

export default function CounsellingLeads() {
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [userCategory, setUserCategory] = useState("");
  const [userRoles, setUserRoles] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [popupData, setPopupData] = useState("");
  const [leads, setLeads] = useState([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const tableRef = useRef(null);
  useEffect(() => {
    dispatch(
      getRole(
        "",
        (res: any) => {
          setUserRoles(res);
        },
        () => {}
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    getLeads();
  }, []);

  const getLeads = () => {
    const postdata: any = {
      startDate: startDate,
      endDate: endDate,
      skip: 0,
      limit: 10,
      category: userCategory,
      countryCode: country?.phonecode,
    };

    if (userCategory == "") {
      delete postdata["category"];
    }
    if (country?.phonecode === "") {
      delete postdata["countryCode"];
    }

    dispatch(
      getAcademicLeads(
        postdata,
        (res: any) => {
          setCount(res?.count);
          setLeads(res?.data);
        },
        () => {}
      )
    );
  };

  const onChangeUserType = (value: { _id: any }) => {
    setUserCategory(value._id);
  };

  const handleEdit = (value: any) => {};
  const handleClose = (value: any) => {
    setOpenPopup(false);
  };

  const handleDelete = (value: any) => {};
  const onChangeStartDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) >
      moment(endDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    } else {
      setStartDate(value);
    }
  };

  const onChangeEndDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) <
      moment(startDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("End date should be greater than Start date"));
    } else {
      setEndDate(value);
    }
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getLeads();
  };

  const onChangeCountry = (value: any) => {
    setCountry(value);
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      category: userCategory,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
    };

    if (userCategory == "") {
      delete postdata["category"];
    }

    dispatch(
      getAcademicLeads(
        postdata,
        (res: any) => {
          setCount(res?.count);
          setLeads(res?.data);
        },
        () => {}
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleAddOnButton = (data: any) => {
    setOpenPopup(true);
    setPopupData(data);
  };

  const renderListMode = () => {
    return (
      <>
        <div className="d-flex justify-content-end">
          {count > 0 ? (
            <PaginationView
              count={count}
              tableData={leads ? leads : []}
              page={page}
              rowsPerPage={rowsPerPage}
              handleChangePage={handleChangePage}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={getTableDataSlots(count)}
            />
          ) : null}
          {leads.length !== 0 ? (
            <div className="d-flex paginationdiv justify-content-end align-items-center">
              <Pagination
                count={totalPages}
                size="large"
                page={currentPage}
                variant="outlined"
                shape="rounded"
                color={"primary"}
                onChange={handleChange}
              />
            </div>
          ) : null}
        </div>
        <div ref={tableRef}>
          <PaginationTable
            tableData={leads ? leads : []}
            pagename={"CounsellingLeads"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N.",
              "FULL NAME",
              "STUDENT MOBILE NUMBER",
              "STUDENT EMAIL",
              "SCHOOL NAME",
              "CONTACT DATE &TIME",
              "GRADE LEVEL",
              "ACTION",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
            addOnButton={true}
            handleAddOnButton={(value: any) => handleAddOnButton(value)}
            addOnButtonLabel={"View Details"}
            addOnButtonClass={"text-success me-2 fw-bold cursor"}
          />
        </div>
      </>
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"Counselling Leads"} />
      <div className="formDiv">
        <form className="text-center" onSubmit={handleSubmit}>
          <div className="px-2 mx-0 mt-4 row d-flex flex-wrap ">
            <div className="col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"Start Date:"} />
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              />
            </div>
            <div className=" col-md-2 d-flex mb-2 align-items-center">
              <LabelField lableName={"End Date:"} />
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              />
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"Submitted by:"} />
              <CustomDropdown
                lableName="Select Category"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeUserType(value)
                }
                value={userCategory}
                options={userRoles}
                Isrequired={true}
              />
            </div>
            <div className="d-flex col-md-3 ms-2 ">
              <LabelField lableName={"Country"} />
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                // Isrequired={true}
                reset={resetCountryList}
              />
            </div>
            <div className="col-md-1  d-flex ">
              <SubmitButton name={"Search"} />
            </div>
          </div>

          {leads?.length !== 0 && leads?.length !== 0 ? (
            <div className="pe-5 d-flex justify-content-end">
              <DownloadTableExcel
                filename="users table"
                sheet="users"
                currentTableRef={tableRef.current}
              >
                <ExportButton name={"Export Details"} />
              </DownloadTableExcel>
            </div>
          ) : (
            ""
          )}

          {renderListMode()}
        </form>
      </div>
      {openPopup ? (
        <LeadDeatilpopup
          open={openPopup}
          data={popupData}
          handleClose={handleClose}
        />
      ) : (
        ""
      )}
    </div>
  );
}
