export default {
  CategorySidebarData: [
    {
      title: " PACKAGE",
      subNav: [
        {
          title: "CREATE",
          path: "createPackage",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updatePackage",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM SYLLABUS ",
      subNav: [
        {
          title: "Add Syllabus",
          path: "createExamSyllabus",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExamSyllabus",
          cName: "sub-nav",
        },
      ],
    },

    {
      title: "ALL ABOUT EXAM ",
      subNav: [
        {
          title: "Add About Exam",
          path: "createAboutExam",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateAboutExam",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM DATES",
      subNav: [
        {
          title: "Add Dates",
          path: "createExamDate",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExamDates",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM PAPER ANALYSIS",
      subNav: [
        {
          title: "Add Paper Analysis",
          path: "createPaperAnalysis",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updatePaperAnalysis",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "EXAM DATES ICON",
      subNav: [
        {
          title: "Add Dates",
          path: "createExamDateIcon",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateExamDateIcon",
          cName: "sub-nav",
        },
      ],
    }





  ]
};