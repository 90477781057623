export default {
  ConfigurationSidebarData: [
    {
      title: "INSTALLMENT SETTING",
      path: "installmentSetting",
    },
    {
      title: "BBB SERVER TIMINGS",
      path: "bbbServerTimings",
    }, 
    {
      title: "QUESTION CONFIG",
      subNav: [
        {
          title: "Add Question Config",
          path: "questionConfig",
          cName: "sub-nav",
        },
        {
          title: "EDIT / DELETE",
          path: "updateQuestionConfig",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "LECTURE CHARGES",
      path: "lectureCharges",
    },

  ]
};