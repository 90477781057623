import { useEffect, useState } from "react";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../Utils/errorMsg";
import { validateIsFileImageType } from "../Utils/validators";
import { useDispatch } from "react-redux";
import { uploadQuestionMedia } from "../Redux/Actions/AdminAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import TextCKEditor from "./textCKEditor";
import parser from "html-react-parser";


interface props {
  setOptionData?: any;
  OnChange?: any;
  index: number;
  option: any;
  setCorrectOption: any;
  getData: any;
}
export default function AcademicsOption({
  option: defaultOptionData,
  setOptionData,
  index,
  setCorrectOption,
}: props) {
  const dispatch: Function = useDispatch();
  const [isCorrect, setIsCorrect] = useState(false);
  const [optionImage, setOptionImage] = useState<any>("");
  const [options, setOptions] = useState<any>("");


  useEffect(() => {
    if (defaultOptionData) {
      setOptions(defaultOptionData);
      setIsCorrect(
        defaultOptionData?.isCorrect ? defaultOptionData?.isCorrect : false
      );
    }
  }, []);


  const showMenu = (obj: any) => {
    let optionDataKeys = {
      descType: obj,
      description: "",
      isCorrect: false,
      index: 0,
    };
    setOptions(optionDataKeys);
  };

  const setOptionsValue = (val: any) => {
    let postData = JSON.parse(JSON.stringify(options));
    postData.description = val;
    postData.isCorrect = isCorrect;
    postData.index = index;
    setOptions(options);
    setOptionData(postData);
    return postData;
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setOptionImage(reader.result);
      };
      reader.readAsDataURL(file);

      dispatch(
        uploadQuestionMedia(
          file,
          (res: { filePath: string }) => {
            setOptionsValue(res?.filePath);
          },
          () => { }
        )
      );
    }
  };

  const setCorrectValue = (
    val: boolean | ((prevState: boolean) => boolean)
  ) => {
    const PostData = {
      isCorrect: val,
      index: index,
    };
    setCorrectOption(PostData);
    return PostData;
  };

  const onChangeRadio = (val: boolean | ((prevState: boolean) => boolean)) => {
    setIsCorrect(val);
    setCorrectValue(val);
  };

  const onChangeText = (val: string) => {
    setOptionsValue(val);
  };

  return (
    <div>
      <div className="w-100 mb-2 border border-black">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between">
          <h5 className="fw-bold text-light">Option {index + 1}</h5>
          <div className="bg-light d-flex align-items-center p-2">
            <span className="me-3">Correct Option</span>
            <input
              type="radio"
              name="correct"
              checked={isCorrect}
              onChange={(value) => onChangeRadio(value.target.checked)}
            />
          </div>
          <div className="d-flex">
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={() => showMenu("text")}
            >
              + Text
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={() => showMenu("image")}
            >
              + Image
            </div>
          </div>
        </div>
        {options?.descType === "text" ? (
          <div
            className="position-relative d-flex justify-content-between border m-2"
            key={index}
          >
            <div className="m-2 w-100">
              <TextCKEditor
                value={options.description}
                onChange={(value: string) => onChangeText(value)}
              />
            </div>
            <span className="mt-2">
              <HighlightOffIcon
                className="cursor"
                color="error"
                fontSize="small"
                onClick={() => setOptions("")}
              />
            </span>
          </div>
        ) : options?.descType === "image" ? (
          <div
            className="position-relative d-flex justify-content-between border m-2"
            key={index}
          >
            <div className="m-2">
              <ImagePickerAndViewer
                lableName={"Option Image"}
                value={optionImage ? optionImage : (options.description)}
                handleChange={(e: any) => handleOptionImageChange(e)}
                isRequired={true}
              />
            </div>
            <span className="mt-2">
              <HighlightOffIcon
                className="cursor"
                color="error"
                fontSize="small"
                onClick={() => setOptions("")}
              />
            </span>
          </div>
        ) : null}
      </div>
    </div>
  );
}
