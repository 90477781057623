// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.imgPopup{
        /* max-height: 400px; */
        max-width: 600px;
        margin-left: auto;
        margin-right: auto;
        background-color: #fff;
        border-radius: 16px;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);
       /* padding: 25px; */
}
.modal-container-header {
    padding: 16px 32px;
    border-bottom: 1px solid #ddd;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  .modal-container-title {
    display: flex;
    align-items: center;
    gap: 8px;
    line-height: 1;
    font-weight: 700;
    font-size: 1.125;
  }
  
  .modal-container-title svg {
    width: 32px;
    height: 32px;
    color: #750550;
  }
  .modal-container-body {
    padding: 24px 32px 51px;
    overflow-y: auto;
  }`, "",{"version":3,"sources":["webpack://./src/Assets/css/imgPopup.css"],"names":[],"mappings":"AAAA;QACQ,uBAAuB;QACvB,gBAAgB;QAChB,iBAAiB;QACjB,kBAAkB;QAClB,sBAAsB;QACtB,mBAAmB;QACnB,gBAAgB;QAChB,aAAa;QACb,sBAAsB;QACtB,6CAA6C;OAC9C,mBAAmB;AAC1B;AACA;IACI,kBAAkB;IAClB,6BAA6B;IAC7B,aAAa;IACb,mBAAmB;IACnB,8BAA8B;EAChC;;EAEA;IACE,aAAa;IACb,mBAAmB;IACnB,QAAQ;IACR,cAAc;IACd,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,cAAc;EAChB;EACA;IACE,uBAAuB;IACvB,gBAAgB;EAClB","sourcesContent":[".imgPopup{\n        /* max-height: 400px; */\n        max-width: 600px;\n        margin-left: auto;\n        margin-right: auto;\n        background-color: #fff;\n        border-radius: 16px;\n        overflow: hidden;\n        display: flex;\n        flex-direction: column;\n        box-shadow: 0 15px 30px 0 rgba(0, 0, 0, 0.25);\n       /* padding: 25px; */\n}\n.modal-container-header {\n    padding: 16px 32px;\n    border-bottom: 1px solid #ddd;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n  }\n  \n  .modal-container-title {\n    display: flex;\n    align-items: center;\n    gap: 8px;\n    line-height: 1;\n    font-weight: 700;\n    font-size: 1.125;\n  }\n  \n  .modal-container-title svg {\n    width: 32px;\n    height: 32px;\n    color: #750550;\n  }\n  .modal-container-body {\n    padding: 24px 32px 51px;\n    overflow-y: auto;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
