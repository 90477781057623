import { SnakbarReducerModal } from '../../Modals';

const uiReducer = (state = new SnakbarReducerModal(), action: { type: any; message: any; }) => {
  switch (action.type) {
    case 'SNACKBAR_SUCCESS':
      return {
        ...state,
        successSnackbarOpen: true,
        message: action.message
      };
    case 'SNACKBAR_CLEAR':
      return {
        ...state,
        successSnackbarOpen: false,
        errorSnackbarOpen: false,
        infoSnackbarOpen: false,
        message: ''
      };
    case 'SNACKBAR_ERROR':
      return {
        ...state,
        errorSnackbarOpen: true,
        message: action.message
      };
    default:
      return state;
  }
};

export default uiReducer;
