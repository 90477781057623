import { useState } from 'react'
import PageTitle from '../../Components/pageTitle'
 
export default function Finance() {
  
  const [meetingID, setMeetingID] = useState<string>("");
  const [password, setPassword ]  = useState<string>("");
 
 

   

   

  return (
    <div className="contentBox">
      <PageTitle name={"Finance"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className=''>
        
        <div className="d-flex justify-content-between">
          <div className='col-6 border rounded p-3 '>
      Working....
          </div>
          
        
      
        </div> 

         
  
      </form>
      </div>
    </div>
  )
}

