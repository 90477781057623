import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";
import { useDispatch } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import "../../Assets/css/calender.css";
import moment from "moment";
import StudentCard from "../../Components/StudentCard";
import { getweeklyScheduleList } from "../../Redux/Actions/AdminAction";
import { getLiveScheduleList } from "../../Redux/Actions/TeacherActions";
export default function MyClass() {
  const dispatch = useDispatch();
  const [startdemoAlignDate, setStartDemoAlignDate] = useState("");
  const [enddemoAlignDate, setEndDemoAlignDate] = useState("");
  const [weeklyList, setWeeklyList] = useState([]);
  const [liveLectureList, setLiveLectureList] = useState<any>([]);

  const highlightCurrentDate = (info: any) => {
    const day = info.date.getDate();
    const month = info.date.getMonth();
    const year = info.date.getFullYear();

    const today = new Date();
    const isToday =
      today.getDate() === day &&
      today.getMonth() === month &&
      today.getFullYear() === year;

    if (isToday) {
      info.el.classList.add("highlight-today"); // Add CSS class to highlight current date
    }
  };

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    getData();
    const data = {
      empId: userId,
      startDate: startdemoAlignDate,
      endDate: enddemoAlignDate,
    };
    dispatch(
      getweeklyScheduleList(
        data,
        (res: any) => {
          setWeeklyList(res);
        },
        () => { }
      )
    );
  }, [startdemoAlignDate, enddemoAlignDate]);

  const getData = () => {
    dispatch(
      getLiveScheduleList(
        userId,
        {},
        (res: any) => {
          setLiveLectureList(res);
        },
        () => { }
      )
    );
  };

  function renderEventContent(eventInfo: any) {
    return (
      <div
        className={`d-flex align-items-center justify-content-center h-100 eventCard ${eventInfo.event.title === "available" ? "bg-success" : "bg-secondary"
          }`}
      >
        <i className="text-center">{eventInfo.event.title}</i>
      </div>
    );
  }
  function handleEventClick(clickInfo: any) { }
  function handleEvents(events: any) { }

  const handleDatesSet = (arg: any) => {
    const start = arg.start;
    const end = arg.end;
    setStartDemoAlignDate(moment(start).format("YYYY-MM-DD"));
    setEndDemoAlignDate(moment(end).format("YYYY-MM-DD"));
  };
  return (
    <div className="contentBox">
      <PageTitle name={"MY CLASS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="row">
          <div className="col-md-6">
            {liveLectureList?.length > 0 ? (
              <div className="d-flex row flex-wrap studentDiv">
                {liveLectureList &&
                  liveLectureList.map((item: any, index: number) => (
                    <div className="col-md-10 mb-3">
                      <StudentCard
                        key={index}
                        item={item}
                        pageName={"MyClassTeacherPanel"}
                        getData={getData}
                      />
                    </div>
                  ))}
              </div>
            ) : (
              <h2 className="fw-bold">No Class Today</h2>
            )}
          </div>
          <div className="col-md-6 calender">
            <div className="fw-bold font16 mb-2">My Availability</div>
            <FullCalendar
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="timeGridWeek"
              events={weeklyList}
              eventContent={renderEventContent}
              weekends={false}
              eventClick={handleEventClick}
              eventsSet={() => handleEvents(weeklyList)}
              datesSet={handleDatesSet}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
