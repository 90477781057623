import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getLectureList,
  getSubTopicList,
  getTopicList,
} from "../../Redux/Actions/AdminAction";
import Submitbtn from "../../Components/submitButton";
import { GradeList } from "../../Utils/dataConstant";
import { useLocation, useNavigate } from "react-router-dom";

export default function AcademicsAddData() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [topic, setTopic] = useState<any>("");
  const [topicList, setTopicList] = useState([]);
  const [resetTopic, setResetTopic] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [subTopic, setSubTopic] = useState<any>("");
  const [subTopicList, setSubTopicList] = useState([]);
  const [resetSubTopic, setResetSubtopic] = useState(false);
  const [lecture, setLecture] = useState<any>("");
  const [lectureList, setLectureList] = useState([]);
  const [resetLectureList, setResetLectureList] = useState(false);
  const [countryData, setCountryData] = useState<any>();
  const [courseTypeData, setCourseTypeData] = useState<any>();
  const [gradeData, setGradeData] = useState<any>();
  const [examData, setExamData] = useState<any>();
  const [subjectData, setSubjectData] = useState<any>();
  const [topicData, setTopicData] = useState<any>();
  const [subTopicData, setSubTopicData] = useState<any>();
  const [lectureData, setLectureData] = useState<any>();

  useEffect(() => {
    if (location?.state) {
      const {
        countryData,
        courseTypeData,
        gradeData,
        examData,
        subjectData,
        topicData,
        subTopicData,
        lectureData,
      } = location?.state;
      setCountryData(countryData);
      setCourseTypeData(courseTypeData);
      setGradeData(gradeData);
      setExamData(examData);
      const postData = {
        examtypeId: examData._id,
        coursetypeId: courseTypeData?._id,
        gradeId: gradeData?._id,
      };
      if (
        postData["gradeId"] === null ||
        postData["gradeId"] === undefined ||
        postData["gradeId"] === ""
      ) {
        delete postData["gradeId"];
      }
      dispatch(
        bindSubjectList(
          postData,
          (res: any) => {
            setSubjectList(res);
            setSubjectData(subjectData);
          },
          () => {}
        )
      );
      setSubjectData(subjectData);
      setTopicData(topicData);
      setSubTopicData(subTopicData);
      setLectureData(lectureData);
    }
  }, [location?.state]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    setResetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetSubject(true);
          setResetTopic(true);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    setResetExam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setResetSubject(false);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => {}
      )
    );
  };

  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value);
    setResetTopic(false);
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setSubTopic(value);
    const postdata: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => {}
      )
    );
  };
  const onChangeLecture = (value: { _id: any; name?: string }) => {
    setLecture(value);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    navigate("../indexAcademics", {
      state: {
        country: country,
        courseType: courseType,
        grade: grade,
        exam: exam,
        subject: subject,
        topic: topic,
        subTopic: subTopic,
        lecture: lecture,
        pageName: "AcademicsCreateUser",
      },
    });
  };

  const validation = () => {
    return (
      country === "" ||
      courseType === "" ||
      subject === "" ||
      topic === "" ||
      subTopic === "" ||
      lecture === ""
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"ADD DATA"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10 pt-2" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
                defaultValue={
                  countryList?.length > 0 && countryData
                    ? countryData?._id
                    : null
                }
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                reset={resetCourseType}
                Isrequired={true}
                defaultValue={
                  courseTypeList?.length > 0 && courseTypeData
                    ? courseTypeData?._id
                    : null
                }
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2  ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeStudentGrade(value)
                  }
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                  defaultValue={
                    GradeList?.length > 0 && gradeData ? gradeData?._id : null
                  }
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  reset={resetExam}
                  Isrequired={true}
                  defaultValue={
                    examList?.length > 0 && examData ? examData?._id : null
                  }
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
                defaultValue={subjectList?.length > 0 ? subjectData?._id : null}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Topic Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Topic Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeTopic(value)
                }
                value={topic}
                options={topicList}
                Isrequired={true}
                reset={resetTopic}
                defaultValue={
                  topicList?.length > 0 && topicData ? topicData?._id : null
                }
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Sub-Topic Name "} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Sub-Topic Name "
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubTopic(value)
                }
                value={subTopic}
                options={subTopicList}
                Isrequired={true}
                reset={resetSubTopic}
                defaultValue={
                  subTopicList?.length > 0 && subTopicData
                    ? subTopicData?._id
                    : null
                }
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Lecture Name "} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Lecture Name "
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeLecture(value)
                }
                value={lecture}
                options={lectureList}
                Isrequired={true}
                reset={resetLectureList}
                defaultValue={
                  lectureList?.length > 0 && lectureData
                    ? lectureData?._id
                    : null
                }
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Go To Index"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
