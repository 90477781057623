import React from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";


interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
}

export default function LeadDeatilpopup(props: props) {
  const {
    open,
    handleClose,
    data
  } = props
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      onClose={() => {
        handleClose();
      }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="modeldivChat p-md-4 py-md-4 ">
          <div className="m-2">
            <div className="d-flex mb-0 ">
              <div className="col-3">
                <p className="fw-bold " >
                  Full Name:
                </p>
              </div>
              <p className="" >
                {data?.firstName} {data?.lastName}
              </p>
            </div>
            <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  Student Number:
                </p>
              </div>
              <p className="" >
                {data?.mobile}
              </p>
            </div>
            <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  Student Email:
                </p>
              </div>
              <p className="" >
                {data?.email}
              </p>
            </div>
            {data?.parentMobile ?
              <div className="d-flex ">
                <div className="col-3">
                  <p className="fw-bold " >
                    Parent Number:
                  </p>
                </div>
                <p className="" >
                  {data?.parentMobile}
                </p>
              </div>
              : null
            }
            {data?.parentEmail ?
              <div className="d-flex ">
                <div className="col-3">
                  <p className="fw-bold " >
                    Parent Email:
                  </p>
                </div>
                <p className="" >
                  {data?.parentEmail}
                </p>
              </div>
              : null
            }
            {data?.currentSchool ? <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  School Name:
                </p>
              </div>
              <p className="" >
                {data?.currentSchool}
              </p>
            </div>
              : null}
            <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  Grade Level:
                </p>
              </div>
              <p className="" >
                {data?.gradedesc}
              </p>
            </div>
            <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  Student Message:
                </p>
              </div>
              <p className="" >
                {data?.remarks}
              </p>
            </div>
            <div className="d-flex ">
              <div className="col-3">
                <p className="fw-bold " >
                  How You Found Us:
                </p>
              </div>
              <p className="" >
                {data?.referenceDesc}
              </p>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>

  );
}
