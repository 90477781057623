import moment from "moment";

export const minConvertTime = (dateData) => {
  const { minutes, hours, seconds } = moment.duration(
    dateData,
    "minute"
  )._data

  return (`${hours < 10 ? "0" + hours : hours}:${minutes < 10 ? "0" + minutes : minutes}:${seconds < 10 ? "0" + seconds : seconds}`)

}
 