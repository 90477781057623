import DemoRequestCard from "../../Components/demoRequestCard";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import { useEffect, useState } from "react";
import CustomDropdown from "../../Components/customDropdown";
import SubmitButton from "../../Components/submitButton";
import {
  getCountryList,
  getCourseTypelist,
  getScheduledList,
} from "../../Redux/Actions/AdminAction";
import { updateTeacherList } from "../../Redux/Actions/SuperAdminAction";
export default function DemoSchedule() {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(7, "days").format("YYYY-MM-DD")
  );
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [resetTeacher, setResetTeacher] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  const [demoScheduleList, setDemoScheduleList] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState<any>(3);
  const [countryList, setCountryList] = useState<any>([]);
  const [country, setCountry] = useState<any>({
    _id: 226,
    name: "UNITED STATES",
  });

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const postData = {};
    dispatch(
      updateTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res?.data);
        },
        () => {}
      )
    );
    const postdata = {
      countryId: country?._id,
    };
    dispatch(
      getCourseTypelist(
        postdata,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
    getDemoScheduledList();
  }, []);

  const getDemoScheduledList = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      statusId: [2, 3],
      countryId: country?._id,
    };
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          if (res) {
            setDemoScheduleList(res);
          }
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      statusId: [2, 3],
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (teacher) {
      postData["teacherId"] = teacher?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          if (res) {
            setDemoScheduleList(res);
          }
        },
        () => {}
      )
    );
  };

  const onChangeTeacher = (value: any) => {
    setTeacher(value);
    setResetTeacher(false);
  };
  const onChangeCountry = (e: any, index: any, data: any) => {
    setSelectedIndex(index);
    setCountry(data);
    setResetCourse(true);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: data?._id,
      statusId: [2, 3],
    };
    if (teacher) {
      postData["teacherId"] = teacher?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    const postData: any = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      statusId: [2, 3],
      coursetypeId: value?._id,
    };
    if (teacher) {
      postData["teacherId"] = teacher?._id;
    }
    dispatch(
      getScheduledList(
        postData,
        (res: any) => {
          setDemoScheduleList(res);
        },
        () => {}
      )
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"DEMO SCHEDULE"} />
      <div className="px-4 mx-0 mt-2 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="row d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={startDate}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2 mt-2">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <LabelField lableName={"Teacher"} />
              <div className="col-md-8">
                <CustomDropdown
                  lableName="Select Teacher Name"
                  setInputText={(value: any) => onChangeTeacher(value)}
                  value={teacher}
                  options={teacherList}
                  reset={resetTeacher}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center pe-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>
        <div className="demoReqCountry mt-4">
          <div className="d-flex">
            <h5 className="font-weight-bold me-5">Country</h5>
            {countryList?.map((item: any, index: any) => {
              return (
                <>
                  <button
                    className={`btn btn-sm me-4 ${
                      selectedIndex === index + 1
                        ? "btn-success"
                        : "btn-secondary"
                    }`}
                    onClick={(e) => onChangeCountry(e, index + 1, item)}
                  >
                    {item?.name}
                  </button>
                </>
              );
            })}
          </div>
          <div className="col-md-4 d-flex ms-3 align-items-center mt-2">
            <LabelField lableName={"Course type"} />
            <div className="col-md-6">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourse(value)}
                value={course}
                options={courseList}
                reset={resetCourse}
              />
            </div>
          </div>
        </div>
        <div className="row">
          {demoScheduleList?.map((item, index) => {
            return (
              <div className="col-md-4 mt-2 p-2">
                <DemoRequestCard
                  key={index}
                  data={item}
                  name={"DEMO SCHEDULED"}
                  pageName={"salesPanelDemoSchedule"}
                  getDemoReq={getDemoScheduledList}
                />
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}
