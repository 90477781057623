import { useEffect, useState, useRef } from 'react'
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import DatePicker from '../../Components/datePicker'
import CustomDropdown from '../../Components/customDropdown';
import PaginationView from '../../Components/paginationView';
import PaginationTable from '../../Components/paginationTable';
import { getTableDataSlots, today, usePagination } from '../../Utils/appConstants';
import Pagination from "@mui/material/Pagination";
import SearchButton from '../../Components/searchButton';
import { useDispatch } from 'react-redux';
import { getRegisterUser } from '../../Redux/Actions/SuperAdminAction';
import { getExamList } from '../../Redux/Actions/AdminAction';
import { DownloadTableExcel } from 'react-export-table-to-excel';
import ExportButton from '../../Components/exportButton';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';

export default function EnrolledStudent() {
  const tableRef = useRef(null);
  const dispatch: Function = useDispatch();
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [examName, setExamName] = useState("");
  const [examList, setExamList] = useState([]);
  const [users, setUsers] = useState([]);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    dispatch(
      getExamList(
        "",
        (res: any) => {
          setExamList(res)
        },
        () => { }
      )
    );
    // getUserData()
  }, []);

  // const getUserData = () => {
  //   const postdata: any = {
  //     startDate: startDate,
  //     endDate: endDate,
  //     examtypeId: examName,
  //     skip: 0,
  //     limit: 10,
  //   }

  //   if (examName == "") {
  //     delete postdata["examtypeId"]
  //   }

  //   dispatch(
  //     getRegisterUser(
  //       postdata,
  //       (res: any) => {
  //         setCount(res?.count)
  //         setUsers(res?.data)
  //       },
  //       () => { }
  //     )
  //   );
  // }

  const onChangeExamName = (value: { _id: any; }) => {
    setExamName(value._id)
  };

  const handleEdit = (value: any) => {

  }

  const handleDelete = (value: any) => {

  }

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number) => {
    let postdata: any = {
      startDate: startDate,
      endDate: endDate,
      examtypeId: examName,
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage
    };

    if (examName == "") {
      delete postdata["examtypeId"]
    }

    dispatch(
      getRegisterUser(
        postdata,
        (res: any) => {
          setCount(res?.count)
          setUsers(res?.data)
        },
        () => { }
      )
    );
  };

  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const onChangeStartDate = (value: any) => {
    if (startDate === today) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    }
    setStartDate(value);
  };
  const renderListMode = () => {
    return (
      <>
        {users?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={users ? users : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div ref={tableRef}>
          <PaginationTable
            tableData={users ? users : []}
            pagename={"RegisteredUsers"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "FULL NAME",
              "MOBILE NUMBER",
              "COURSE NAME",
              "REGISTRATION DATE",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
    // getUserData()
  }

  return (
    <div className="contentBox">
      <PageTitle name={"ENROLLED USER"} />
      <form className='text-center' onSubmit={handleSubmit}>
        <div className='px-2 mx-0 mt-4 row d-flex Justify-content-between'>
          <div className='col-md-3 d-flex mb-2 align-items-center'>
            <LabelField lableName={"Start Date:"} />
            <DatePicker
              value={startDate}
              setInputText={(value: string) => onChangeStartDate(value)}
              Isrequired
              maxdate={today} />
          </div>
          <div className=' col-md-3 d-flex mb-2 align-items-center'>
            <LabelField lableName={"End Date:"} />
            <DatePicker
              value={endDate}
              setInputText={(value: string) => setEndDate(value)}
              Isrequired
              maxdate={today} />
          </div>
          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"Exam Name:"} />
            <CustomDropdown
              lableName="Select Exam Name"
              setInputText={(value: { _id: string, name: string }) => onChangeExamName(value)}
              value={examName}
              options={examList}
            />
          </div>
          <div className='col-md-3 d-flex justify-content-end'>
            <SearchButton name={"Search"} />
          </div>
        </div>
      </form>
      {users?.length !== 0 &&
        users?.length !== 0 ? (
        <div className='pe-5 d-flex justify-content-end'>
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <ExportButton name={"Export Details"} />
          </DownloadTableExcel>
        </div>) : ""}
      {renderListMode()}
    </div>
  )
}

