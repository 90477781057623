export default {
  OperationSidebarData: [
    {
      title: "LEADS",
      subNav: [
        {
          title: "COUNSELLING LEADS",
          path: "counsellingLeads",
          cName: "sub-nav",
        },
        {
          title: "LANDING PAGE LEADS",
          path: "landingpageleads",
          cName: "sub-nav",
        },
        {
          title: "REGISTERED USER",
          path: "registeredUsers",
          cName: "sub-nav",
        },
        {
          title: "TUTOR NETWORK",
          path: "tutorNetwork",
          cName: "sub-nav",
        },
        {
          title: "MARKETING LANDING PAGE",
          path: "marketingLandingpage",
          cName: "sub-nav",
        },
        {
          title: "AFFILIATE MARKETING",
          path: "affiliateMarketing",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "DEMO REQUEST",
          path: "demoRequest",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULE",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "DEMO CONDUCTED",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "DEMO CANCEL",
          path: "demoCancel",
          cName: "sub-nav",
        },
        {
          title: "DEMO JOIN",
          path: "demoJoin",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "REGULAR CLASS JOIN",
      path: "ClassJoin",
    },
    {
      title: "UPCOMING CLASS",
      path: "upcomingScheduleClass",
    },
    {
      title: "DSA CONVERSION",
      path: "dsaConversion",
    },
    {
      title: "DSA PAYMENT PAYOUT",
      path: "dsaPaymentPayout",
    },
    {
      title: "AFFILIATE PAYMENT PAYOUT",
      path: "affiliatePaymentPayout",
    },
    {
      title: "TEACHER PAYMENT PAYOUT",
      path: "paymentPayout",
    },
    {
      title: "TEACHER CONVERSION",
      path: "conversion",
    },
    {
      title: "PTM FEEDBACK",
      path: "ptmFeedback",
    },
    {
      title: "STUDENT FEEDBACK",
      path: "liveClassStudentFeedback",
    },
  ],
};
