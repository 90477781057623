import { Route, Routes } from "react-router-dom";
import CounsellingLeads from './counsellingLeads';
import Sidebar from '../../Components/sidebar';
import salesSidebarData from './salesSidebarData';
import RegisteredUsers from '../SuperAdmin/registeredUsers';
import WIP from '../wip';
import TutorNetwork from "./tutorNetwork";
import MarketingLandingpage from "./marketingLandingpage";
import DemoRequest from "./demoRequest";
import MyDemo from "./myDemo";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoCancel from "./demoCancel";
import ScheduleClass from "./paymentPayout";
import UpcomingScheduleClass from "./upcomingScheduleClass";
import DemoJoin from "./demoJoin";
import ClassJoin from "./classJoin";
import PaymentPayout from "./paymentPayout";
import Reschedule from "./reschedule";
import PaidAdd from "./paidAdd";
import ConversionDetails from "./conversionDetails";
import Conversion from "./conversion";
export default function SalesPanelDashboard() {
  return (
    <div className='AppBox'>
      <Sidebar data={salesSidebarData?.SalesSidebarData} />

      <div className='dashboard'>
        <Routes>
          <Route path="/" element={<CounsellingLeads />} />
          <Route path="counsellingLeads" element={<CounsellingLeads />} />
          <Route path="registeredUsers" element={<RegisteredUsers />} />
          <Route path="tutorNetwork" element={<TutorNetwork />} />
          <Route path="marketingLandingpage" element={<MarketingLandingpage />} />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="myDemo" element={<MyDemo />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoCancel" element={<DemoCancel />} />
          <Route path="scheduleClass" element={<ScheduleClass />} />
          <Route path="upcomingScheduleClass" element={<UpcomingScheduleClass />} />
          <Route path="demoJoin" element={<DemoJoin />} />
          <Route path="classJoin" element={<ClassJoin />} />
          <Route path="paymentPayout" element={<PaymentPayout />} />
          <Route path="reschedule" element={<Reschedule />} />
          <Route path="conversion" element={<Conversion />} />
          <Route path="conversionDetails" element={<ConversionDetails />} />
          <Route path="paidAdd" element={<PaidAdd />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div></div>
  )
}
