import logger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import { persistStore, persistReducer } from 'redux-persist';
import { applyMiddleware, createStore, compose } from 'redux';
import storage from 'redux-persist/lib/storage';
import rootReducer from './Redux/Reducers/reducer';
// import { configureStore } from '@reduxjs/toolkit'

//@ts-ignore
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
let middleware: any = [thunkMiddleware];

//@ts-ignore
const { pathname } = window.location || {};
const IS_RUNNING_IN_CHROME = pathname && pathname.indexOf('debugger-ui');
const IS_PRODUCTION = process.env.REACT_APP_ENV === 'PROD';

if (IS_RUNNING_IN_CHROME && !IS_PRODUCTION) {
  middleware = [...middleware, logger];
}

// const enhancer = composeEnhancer(applyMiddleware(thunkMiddleware, logger));
const enhancer = composeEnhancer(applyMiddleware(...middleware));

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = createStore(persistedReducer, enhancer);
export const persistor = persistStore(store);