import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import { useEffect, useRef, useState } from "react";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import { today } from "../../Utils/appConstants";
import localImages from "../../Constant/localImages";
import {
  AffiliatePaymentList,
  getCountryList,
  getCourseTypelist,
  getDsaPayoutList,
  getTeacherPayoutList,
} from "../../Redux/Actions/AdminAction";
import CustomDropdown from "../../Components/customDropdown";
import moment from "moment";
import { DownloadTableExcel } from "react-export-table-to-excel";
import ExportButton from "../../Components/exportButton";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";

export default function DemoPayments() {
  const dispatch = useDispatch();
  const tableRef = useRef(null);
  const [toggle, setToggle] = useState("Current Month");
  const [startDate, setStartDate] = useState<any>(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(today);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [payoutList, setPayoutList] = useState([]);
  const [totalEarning, setTotalEarning] = useState<any>("");
  const [totalPaidAmount, setTotalPaidAmount] = useState<any>("");
  const [pendingAmount, setPendingAmount] = useState<any>("");
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
    const data = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      coursetypeId: course?._id,
      refId: userId,
    };
    dispatch(
      AffiliatePaymentList(
        data,
        (res: any) => {
          setPayoutList(res?.data);
          setTotalEarning(res?.totalEarning);
          setTotalPaidAmount(res?.totalPaidAmount);
          setPendingAmount(res?.pendingAmount);
        },
        () => {}
      )
    );
  }, []);

  const tab = [
    {
      _id: "1",
      name: "Current Month",
    },
    {
      _id: "2",
      name: "Old Month",
    },
  ];
  const handleChangetab = (val: any) => {
    setToggle(val);

    if (val === "Current Month") {
      const data = {
        startDate: startDate,
        endDate: endDate,
        countryId: country?._id,
        coursetypeId: course?._id,
        refId: userId,
      };
      dispatch(
        AffiliatePaymentList(
          data,
          (res: any) => {
            setPayoutList(res?.data);
            setTotalEarning(res?.totalEarning);
            setTotalPaidAmount(res?.totalPaidAmount);
            setPendingAmount(res?.pendingAmount);
          },
          () => {}
        )
      );
    } else if (val === "Old Month") {
      var lastdayoflastmonth = new Date();
      lastdayoflastmonth.setMonth(lastdayoflastmonth.getMonth(), 0);
      var firstdayoflastmonth = new Date();
      firstdayoflastmonth.setDate(1);
      firstdayoflastmonth.setMonth(firstdayoflastmonth.getMonth() - 1);
      var lastDateMonth = moment(lastdayoflastmonth).format("YYYY-MM-DD");
      var firstDateMonth = moment(firstdayoflastmonth).format("YYYY-MM-DD");
      const data = {
        startDate: firstDateMonth,
        endDate: lastDateMonth,
        countryId: country?._id,
        coursetypeId: course?._id,
        refId: userId,
      };
      dispatch(
        AffiliatePaymentList(
          data,
          (res: any) => {
            setPayoutList(res?.data);
            setTotalEarning(res?.totalEarning);
            setTotalPaidAmount(res?.totalPaidAmount);
            setPendingAmount(res?.pendingAmount);
          },
          () => {}
        )
      );
    }
  };

  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };
  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountryList(false);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const data = {
      startDate: startDate,
      endDate: endDate,
      countryId: country?._id,
      coursetypeId: course?._id,
      refId: userId,
    };
    dispatch(
      AffiliatePaymentList(
        data,
        (res: any) => {
          setPayoutList(res?.data);
          setTotalEarning(res?.totalEarning);
          setTotalPaidAmount(res?.totalPaidAmount);
          setPendingAmount(res?.pendingAmount);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="p-2 table-responsive ">
        <table
          className="PayoutTable table table-bordered text-center "
          ref={tableRef}
        >
          <thead className="table-primary ">
            <tr>
              <th className="p-3 ">Sr.No</th>
              <th className="p-3 text-start">Course Type</th>
              <th className="p-3 text-start">Class Title</th>
              <th className="p-3 text-start">Purchase Date</th>
              <th className="p-3 "> Per Refferal Charges</th>
              <th className="p-3 ">Amount</th>
              <th className="p-3 ">Status</th>
            </tr>
          </thead>
          {payoutList?.length > 0 ? (
            <tbody>
              {payoutList?.map((value: any, index: any) => {
                return (
                  <tr>
                    <td>{index + 1}</td>
                    <td className="text-start">
                      {value?.coursetypeName ? value?.coursetypeName : "-"}
                    </td>
                    <td className="text-start col-2">{value?.classTitle}</td>
                    <td className="text-start col-2">
                      {moment(value?.purchaseDate).format("DD-MM-YYYY")}
                    </td>

                    <td>{value?.amount ? value?.amount : "-"}</td>
                    <td>
                      {value?.paidAmount
                        ? parseFloat(value?.paidAmount).toFixed(2)
                        : "-"}
                    </td>
                    <td> {value?.status ? value?.status : "-"}</td>
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <td className="fw-bold p-1">No Record Found</td>
            </tbody>
          )}
        </table>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"DEMO PAYMENTS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <div className="border-bottom pb-4">
          <form className="text-center " onSubmit={handleSubmit}>
            <div className="px-2 mx-0 mt-4 row d-flex justify-content-between ">
              <div className="col-md-4 d-flex mb-2 align-items-center  ">
                <div className="col-md-3">
                  <LabelField lableName={"Start Date:"} />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={startDate}
                    setInputText={(value: string) => onChangeStartDate(value)}
                    maxdate={today}
                  />
                </div>
              </div>
              <div className=" col-md-4 d-flex mb-2 align-items-center">
                <div className="col-md-3">
                  <LabelField lableName={"End Date:"} />
                </div>
                <div className="col-md-9">
                  <DatePicker
                    value={endDate}
                    setInputText={(value: string) => onChangeEndDate(value)}
                    maxdate={today}
                  />
                </div>
              </div>
              <div className=" col-md-4 d-flex mb-2 align-items-center">
                <div className="col-md-3">
                  <LabelField lableName={"Country"} />
                </div>
                <div className="col-md-9">
                  <CustomDropdown
                    lableName="Select Country"
                    setInputText={(value: any) => onChangeCountry(value)}
                    value={country}
                    options={countryList}
                    reset={resetCountryList}
                  />
                </div>
              </div>
              <div className=" col-md-4 d-flex mb-2 align-items-center">
                <div className="col-md-3">
                  <LabelField lableName={"Course Type"} />
                </div>
                <div className="col-md-9">
                  <CustomDropdown
                    lableName="Select Course Type Name"
                    setInputText={(value: any) => onChangeCourseType(value)}
                    value={course}
                    options={courseList}
                    reset={resetParentCourse}
                  />
                </div>
              </div>
            </div>
            <div className=" col-md-3 d-flex mb-2 align-items-center">
              <button className=" btn btn-secondary rounded-pill">
                {" "}
                Search
              </button>
            </div>
            {payoutList?.length > 0 ? (
              <div className="d-flex justify-content-end">
                <DownloadTableExcel
                  filename="Payment Status"
                  sheet="users"
                  currentTableRef={tableRef.current}
                >
                  <ExportButton name={"Export Details"} />
                </DownloadTableExcel>
              </div>
            ) : null}
          </form>
          <div className="d-flex">
            <div className="d-flex col-md-12 p-3 ">
              <div className="card col-md-4 pricingCard2 border-0 shadow p-1 me-3">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold  ">Total Earning</p>
                    <div className="col-md-2 rounded-circle p-2 bg-light shadow">
                      <img
                        src={localImages.totalEarning}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                  <div className="d-flex align-items-end ">
                    <p className="fw-bold fs-2 mb-0"> {totalEarning}</p>
                  </div>
                </div>
              </div>
              <div className="card  col-md-4 pricingCard3 border-0 shadow p-2 me-3">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="fw-bold">Paid Amount</p>
                    <div className="col-md-2 rounded-circle p-2 bg-light shadow">
                      <img
                        src={localImages.payment}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                  <div className="d-flex align-items-end ">
                    <p className="fw-bold fs-2 mb-0"> {totalPaidAmount}</p>
                  </div>
                </div>
              </div>
              <div className="card col-md-4 pricingCard border-0 shadow">
                <div className="card-body">
                  <div className="d-flex justify-content-between">
                    <p className="fw-bolder ">Pending Payments</p>
                    <div className="col-md-2  rounded-circle p-2 bg-light shadow">
                      <img
                        src={localImages.pending}
                        className="img-fluid"
                      ></img>
                    </div>
                  </div>
                  <div className="d-flex align-items-end  ">
                    <p className="fw-bold fs-2 mb-0"> {pendingAmount}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-between  h-100 ">
          <div className="col-md-12 p-2 ">
            <div className="d-flex mt-5  border-bottom  ">
              {tab?.map((c, index) => (
                <div
                  className={`col-md-4 fs-6 text-center cursor fw-bold ${
                    toggle === c.name
                      ? " text-primary border-bottom border-primary border-3"
                      : "text-secondary"
                  }`}
                  onClick={() => handleChangetab(c.name)}
                >
                  {c.name}
                </div>
              ))}
            </div>
            {toggle === "Current Month" ? (
              <div className="mt-4">{renderListMode()}</div>
            ) : (
              ""
            )}
            {toggle === "Old Month" ? (
              <div className="mt-4">{renderListMode()}</div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
