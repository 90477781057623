export default {
  AcademicsSidebarData: [
    {
      title: "ADD DATA",
      path: "academicsAddData",
    },
    {
      title: "DEMO DATA",
      path: "demoData",
    },

  ],
};
