import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import DeleteIcon from "@mui/icons-material/Delete";

import {
  validateIsFileImageType,
  validateIsFileVideoType,
} from "../Utils/validators";
import {
  showErrorSnackbar,
  showSuccessSnackbar,
} from "../Redux/Actions/snackbarAction";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFileVideoTypeErrMsg,
} from "../Utils/errorMsg";
import { uploadAcademicsImg } from "../Redux/Actions/AdminAction";
import TextCKEditor from "./textCKEditor";

export default function AcademicsHint(props: any) {
  const dispatch = useDispatch();
  const [classContentArray, setClassContentArray] = useState<any>([]);
  const [uploadVideo, setUploadVideo] = useState<any>("");
  const [loadVideo, setLoadVideo] = useState<any>("");
  useEffect(() => {
    props?.getData?.map((value: any) => {
      setClassContentArray(value?.hint);
    });
  }, []);

  const showInputMenu = (inputType: any, data: any) => {
    if (inputType === "text") {
      const inputTypeText = {
        descType: inputType,
        description: "",
      };
      setClassContentArray([...classContentArray, inputTypeText]);
    } else if (inputType === "image") {
      const inputTypeImage = {
        descType: inputType,
        description: "",
      };
      setClassContentArray([...classContentArray, inputTypeImage]);
    } else if (inputType === "video") {
      const inputTypeVideo = {
        descType: inputType,
      };
      setClassContentArray([...classContentArray, inputTypeVideo]);
    }
  };

  const handleCloseClassContent = (index: any) => {
    const newArrr = [...classContentArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setClassContentArray(newArrr);
  };

  const onChangeClassContentText = (
    index: string | number,
    value: any,
    key: string
  ) => {
    let newArrr = JSON.parse(JSON.stringify(classContentArray));
    if (key) {
      newArrr[index][key] = value;
      setClassContentArray(newArrr);
    }
  };

  const onChangeClassContentImg = (
    e: { preventDefault: () => void; target: { files: any[] } },
    index: any
  ) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg) as any);
      return;
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.readAsDataURL(file);
      dispatch(
        uploadAcademicsImg(
          file,
          (res: any) => {
            onChangeClassContentText(index, res.filePath, "description");
          },
          () => { }
        )
      );
    }
  };

  const onChangeClassContentVideo = (e: any, index: any) => {
    e.preventDefault();
    if (!validateIsFileVideoType(e)) {
      dispatch(showErrorSnackbar(validateIsFileVideoTypeErrMsg) as any);
      return;
    }
    const reader = new FileReader();
    const file = e.target.files[0];
    reader.onloadend = () => {
      setLoadVideo(reader.result);
    };
    reader.readAsDataURL(file);

    dispatch(
      uploadAcademicsImg(
        file,
        (res: any) => {
          if (res) {
            dispatch(showSuccessSnackbar("File Succesfully Uploaded") as any);
            onChangeClassContentText(index, res.filePath, "description");
            setUploadVideo(res.filePath);
          } else {
            dispatch(showErrorSnackbar("File Not Uploaded") as any);
          }
        },
        () => { }
      )
    );
  };

  useEffect(() => {
    setHintValue();
  }, [classContentArray]);

  const setHintValue = () => {
    let tempClassContentArray = classContentArray?.map((item: any) => {
      if (item?.descType === "text") {
        return {
          descType: item?.descType,
          description: item.description.replace(/'/g, "&apos;"),
        };
      }
      return item;
    });
    const PostData = {
      hint: tempClassContentArray,
    };
    props?.setMcqHint(PostData);
    return PostData;
  };

  const renderClassContent = () => {
    return classContentArray?.map((item: any, index: any) => {
      const { descType, description } = item;
      return (
        <>
          <div
            className="position-relative d-flex justify-content-between m-2 p-2 border"
            key={index}
          >
            {descType === "text" ? (
              <div className="m-2 w-100">
                <TextCKEditor
                  value={description}
                  onChange={(value: any) =>
                    onChangeClassContentText(index, value, "description")
                  }
                />
              </div>
            ) : null}
            {descType === "image" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Image"}
                  value={description}
                  handleChange={(e: any) => onChangeClassContentImg(e, index)}
                  removelabel={true}
                />
              </div>
            ) : null}
            {descType === "video" ? (
              <div className="m-2">
                <ImagePickerAndViewer
                  lableName={"Video"}
                  value={description}
                  handleChange={(value: any) =>
                    onChangeClassContentVideo(value, index)
                  }
                  doctype={"video"}
                />
              </div>
            ) : null}
            <div>
              <span className="text-dark">
                <DeleteIcon
                  className="cursor"
                  color="error"
                  fontSize="small"
                  onClick={() => handleCloseClassContent(index)}
                />
              </span>
            </div>
          </div>
        </>
      );
    });
  };

  return (
    <div>
      <div className="w-100 pt-3">
        <div className="p-3 bg-dark d-flex align-items-center justify-content-between m-2">
          <h5 className="fw-bold text-light">Hint For Particular</h5>
          <div className=" d-flex">
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("text", id)}
            >
              + Text
            </div>

            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("image", id)}
            >
              + Image
            </div>
            <div
              className="cursor addInputbtn ms-2 p-1"
              onClick={(id: any) => showInputMenu("video", id)}
            >
              + Video
            </div>
          </div>
        </div>

        {renderClassContent()}
      </div>
    </div>
  );
}
