import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import InputField from "../../Atoms/InputField";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import { pdf_size_1MB, validateIsFileImageType } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../../Utils/errorMsg";
import { useDispatch } from "react-redux";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import Submitbtn from "../../Components/submitButton";
import { getExamDateIconList } from "../../Redux/Actions/AdminAction";

export default function UpdateExamDateIcon() {
  const dispatch: Function = useDispatch();
  const [labelList, setLabelList] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);
  const [label, setLabel] = useState<any>("");
  const [examLogo, setExamLogo] = useState<any>("");
  const setInputLabel = (value: any) => {
    setLabel(value);
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > pdf_size_1MB) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setExamLogo(reader.result);
      };
    }
  };

  useEffect(() => {
    updatedata();
  }, []);

  const updatedata = () => {
    dispatch(
      getExamDateIconList(
        "",
        (res: any) => {
          setLabelList(res);
        },
        () => {}
      )
    );
  };
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
      };
    }
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  const renderListMode = () => {
    return (
      <>
        {labelList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center mt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={labelList ? labelList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={labelList ? labelList : []}
          pagename={"examdates"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={["S.N", "LabelName", "Action"]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <div className="col-8 px-3">
        <div className="d-flex Justify-content-between  ">
          <div className="col-3 align-item-center d-flex">
            <LabelField lableName={"Label Name"} />
          </div>
          <div className="col-8 ps-2">
            <InputField
              placeholder="Enter Label Name"
              value={label}
              onChangeInput={(value: any) => setInputLabel(value)}
              maxlength={100}
              minlength={2}
            />
          </div>
        </div>
        <div className="d-flex Justify-content-between  ">
          <div className="col-3 align-item-center d-flex">
            <LabelField lableName={"Label Logo "} />
          </div>
          <div className="col-8 ps-2">
            <ImagePickerAndViewer
              lableName={"Option Image"}
              value={examLogo}
              handleChange={(e: any) => handleOptionImageChange(e)}
              isRequired={true}
            />
          </div>
        </div>

        <div className="d-flex justify-content-center mt-3">
          <Submitbtn name={"Submit"} />
        </div>
      </div>
    );
  };
  const handleEdit = (value: any) => {};

  const handleDelete = (value: any) => {};
  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT EXAM DATES"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      <form className="px-1 mx-0 mt-4 formDiv ">
        {isEditMode ? renderEditMode() : renderListMode()}
      </form>
    </div>
  );
}
