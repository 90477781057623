import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  filterRoles,
  getDepartment,
} from "../../Redux/Actions/SuperAdminAction";
import SubmitButton from "../../Components/submitButton";
import {
  validateFullName,
  validateIsNumberOnly,
  validatePassword,
  startsWithSpace,
  hasWhiteSpace,
  validateIsLettersAndDigitsOnly,
  emailCheck,
  length_Ten,
  length_Six,
  length_Hundred,
  length_OneHundredFifty,
  length_Fifty,
  length_Five,
  length_Three,
  validateUniqueId,
  length_Four,
  length_Eight,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { addDsaUser, getDsaManagerList } from "../../Redux/Actions/AdminAction";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";

export default function CreateUser() {
  const dispatch: Function = useDispatch();
  const [employeeName, setEmployeeName] = useState<string>("");
  const [employeeId, setEmployeeId] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [department, setDepartment] = useState<string>("");
  const [departmentList, setDepartmentList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [role, setRole] = useState("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [resetDept, setresetDept] = useState(false);
  const [resetRole, setresetRole] = useState(false);
  const [uniqueID, setUniqueId] = useState<any>("");

  const { userId, countryId } = useSelector(
    (state: { Auth: AuthReducerModal }) => ({
      userId: state.Auth.userId,
      countryId: state.Auth.countryId,
    })
  );

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 16);
          setDepartmentList(tempStatus);
          setDepartment(tempStatus[0]?._id);
        },
        () => {}
      )
    );
    setDepartmentList(Object(departmentList));
  }, []);

  const onChangeDepartment = (value: { _id: any; name?: string }) => {
    setDepartment(value._id);
    setresetDept(false);
    dispatch(
      filterRoles(
        value._id,
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 17);
          setRolesList(tempStatus);
          setRole(tempStatus[0]?._id);
        },
        () => {}
      )
    );
  };

  const onChangeRoles = (value: { _id: any; name?: string }) => {
    setresetRole(false);
    setRole(value._id);
  };
  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  // const onChangeEmpId = (value: any) => {
  //   if (validateIsLettersAndDigitsOnly(value)) {
  //     setEmployeeId(value);
  //   } else {
  //     dispatch(showErrorSnackbar("Please only Character & Number"));
  //     return;
  //   }
  // };
  const onChangepwd = (value: any) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };
  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }
  // const onchangeUniqueId = (val: string) => {
  //   if (validateUniqueId(val) && !startsWithSpace(val)) {
  //     setUniqueId(val?.toUpperCase());
  //   } else {
  //     dispatch(
  //       showErrorSnackbar("Please Character only & space is not allowed") as any
  //     );
  //     return;
  //   }
  // };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData = {
        name: employeeName,
        // empCreatedId: employeeId,
        departmentId: department,
        userRoleId: role,
        username: userName,
        password: password,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        // uniqueId: uniqueID,
        managerId: userId,
        countryId: countryId,
      };
      dispatch(
        addDsaUser(
          postData,
          (res: any) => {
            setresetDept(true);
            setresetRole(true);
            setEmployeeName("");
            // setEmployeeId("");
            setMobileNo("");
            setEmail("");
            setDateOfJoining("");
            setUserName("");
            setPassword("");
            // setUniqueId("");
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  const validation = () => {
    return (
      employeeName === "" ||
      employeeName.length < length_Three ||
      // employeeId === "" ||
      // employeeId.length < length_Three ||
      department === "" ||
      role === "" ||
      userName === "" ||
      userName.length < length_Five ||
      mobileNo === "" ||
      mobileNo?.length < length_Ten ||
      password === "" ||
      password.length < length_Five ||
      dateOfJoining === ""
      // ||
      // uniqueID === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE SALES PERSON"} />
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-10 px-4 mx-0 mt-4 ">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Employee Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Employee Name"
                value={employeeName}
                onChangeInput={(value: any) => onChangeEmpName(value)}
                maxlength={length_Hundred}
                minlength={length_Three}
                isRequired
              />
            </div>
          </div>
          {/* <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Employee ID"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Employee ID"
                value={employeeId}
                onChangeInput={(value: any) => onChangeEmpId(value)}
                maxlength={length_Six}
                minlength={length_Three}
                isRequired
              />
            </div>
          </div> */}
          <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Department"} />
            </div>
            <div className="col-6 ps-2">
              <CustomDropdown
                lableName="Select Department"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeDepartment(value)
                }
                value={department}
                options={departmentList}
                Isrequired={true}
                reset={resetDept}
                defaultValue={department}
                disabled
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Panel Role"} />
            </div>
            <div className="col-6 ps-2">
              <CustomDropdown
                lableName="Select Panel Role"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeRoles(value)
                }
                value={role}
                options={rolesList}
                Isrequired={true}
                reset={resetRole}
                defaultValue={role}
                disabled
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Mobile Number"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Mobile Number"
                value={mobileNo}
                onChangeInput={(value: any) => onChangeMobileNO(value)}
                maxlength={length_Ten}
                minlength={length_Eight + 1}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Email Address"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Email Address"
                value={email}
                onChangeInput={(value: any) => onChangeEmail(value)}
                maxlength={length_Hundred}
                isRequired
              />
            </div>
          </div>
          {/* <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Unique Id"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Unique Id"
                value={uniqueID}
                onChangeInput={(value: any) => onchangeUniqueId(value)}
                maxlength={length_Four - 1}
                minlength={length_Three - 1}
                isRequired={true}
              />
            </div>
          </div> */}
          <div className=" d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Date Of Joining"} />
            </div>
            <div className="col-6">
              <DatePicker
                value={dateOfJoining}
                setInputText={(value: string) => setDateOfJoining(value)}
                Isrequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Username"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Username"
                value={userName}
                onChangeInput={(value: any) => onChangeUserName(value)}
                maxlength={length_OneHundredFifty}
                minlength={length_Five}
                isRequired
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between ">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Password"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Password"
                value={password}
                onChangeInput={(value: any) => onChangepwd(value)}
                maxlength={length_Fifty}
                minlength={length_Five}
                isRequired
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-center mt-5">
          <SubmitButton name="Create User" validation={validation()} />
        </div>
      </form>
    </div>
  );
}
