import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import ImagePickerAndViewer from "../../Components/imagePickerAndViewer";
import { useDispatch } from "react-redux";
import {
  addPackage,
  bindSubjectList,
  getCountlist,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
  getMainCategoryList,
  getSectionList,
  uploadPackageMedia,
} from "../../Redux/Actions/AdminAction";
import { GradeList, homepageList, statusList } from "../../Utils/dataConstant";
import {
  image_size_2MB,
  pdf_size_1MB,
  validateIsFileImageType,
  validateIsFilePDFType,
  video_size_25MB,
} from "../../Utils/validators";
import {
  validateIsFileImageTypeErrMsg,
  validateIsFilePDFTypeErrMsg,
} from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import Time from "../../Components/time";

export default function CreatePackage() {
  const LabelArr = [
    "Level",
    "Offer Lecture Count",
    "Display Name",
    "Selling Price",
    "Offer Price",
    "Priority Order",
    "Recommendation",
  ];

  const initialData = [
    {
      levelId: "1",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
    {
      levelId: "2",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
    {
      levelId: "3",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
  ];

  const initialDataTestPrep = [
    {
      levelId: "3",
      levelName: "",
      offerLectureCount: "",
      offerPrice: "",
      sellingPrice: "",
      priorityOrder: "",
      isRecommended: false,
    },
  ];

  const dispatch: Function = useDispatch();
  const [country, setCountry] = useState<any>("");
  const [courseType, setCourseType] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [lectureCount, setLectureCount] = useState<any>("");
  const [section, setSection] = useState<any>("");
  const [category, setCategory] = useState<any>("");
  const [packageName, setPackageName] = useState<any>("");
  const [packageBanner, setPackageBanner] = useState<any>();
  const [bannerImage, setBannerImage] = useState<any>("");
  const [packagePDF, setPackagePDF] = useState<any>("");
  const [bannerPdf, setBannerPdf] = useState<any>("");
  const [video, setVideo] = useState<any>("");
  const [homePage, setHomePage] = useState<any>(false);
  const [status, setStatus] = useState<any>(false);

  const [countryList, setCountryList] = useState<any>([]);
  const [courseTypeList, setCourseTypeList] = useState<any>([]);
  const [examList, setExamList] = useState<any>([]);
  const [subjectList, setSubjectList] = useState<any>([]);
  const [sectionList, setSectionList] = useState<any>([]);
  const [mainCategoryList, setMainCategoryList] = useState<any>([]);
  const [sessionList, setSessionList] = useState<any>([]);
  const [packageDetails, setPackageDetails] = useState<any>();

  const [resetCountryList, setResetCountryList] = useState<any>(false);
  const [resetCourseType, setresetCourseType] = useState<any>(false);
  const [resetGradeList, setResetGradeList] = useState<any>(false);
  const [resetexam, setresetexam] = useState<any>(false);
  const [resetSubject, setResetSubject] = useState<any>(false);
  const [resetSection, setresetSection] = useState<any>(false);
  const [resetMainCategory, setresetMainCategory] = useState<any>(false);
  const [resetHomePriority, setresetHomePriority] = useState<any>(false);
  const [resetStatus, setresetStatus] = useState<any>(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMainCategoryList(res);
        },
        () => { }
      )
    );
    resetForm();
  }, []);

  const onChangeCountry = (value: any) => {
    setCountry(value);
    setResetCountryList(false);
    resetForm();
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string, type?: number }) => {
    setCourseType(value);
    setresetCourseType(false);
    setGrade("");
    setResetGradeList(true);
    setExam("");
    setresetexam(true);
    setExamList([]);
    setSubject("");
    setResetSubject(true);
    setSubjectList([]);
    setLectureCount("");
    setSection("");
    setresetSection(true);
    setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    if (value?.type === 1 || value?.name === "REGULAR ACADEMICS") {
      setPackageDetails(initialDataTestPrep)
    }
    else {
      setPackageDetails(initialData);
    }
    const Data = {
      coursetypeId: value._id,
    };
    dispatch(
      getCountlist(
        Data,
        (res: any) => {
          setLectureCount(res?.count);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    dispatch(
      getSectionList(
        "",
        (res: any) => {
          setSectionList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    setExam("");
    setresetexam(true);
    setExamList([]);
    setSubject("");
    setResetSubject(true);
    setSubjectList([]);
    setLectureCount("");
    setSection("");
    setresetSection(true);
    // setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    // setPackageDetails(initialData);
    const postData = {
      coursetypeId: courseType._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    setresetexam(false);
    setSubject("");
    setResetSubject(true);
    setSubjectList([]);
    setLectureCount("");
    setSection("");
    setresetSection(true);
    // setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    // setPackageDetails(initialData);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
    };

    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setResetSubject(false);
    setLectureCount("");
    setSection("");
    setresetSection(true);
    // setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    // setPackageDetails(initialData);
    const Data = {
      coursetypeId: courseType._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value?._id,
    };
    if (
      Data["gradeId"] === null ||
      Data["gradeId"] === undefined ||
      Data["gradeId"] === ""
    ) {
      delete Data["gradeId"];
    }
    if (
      Data["examtypeId"] === null ||
      Data["examtypeId"] === undefined ||
      Data["examtypeId"] === ""
    ) {
      delete Data["examtypeId"];
    }
    const newArrr: any = [...packageDetails];
    dispatch(
      getCountlist(
        Data,
        (res: any) => {
          setLectureCount(res?.level3);
          if (courseType?.type === 1 || courseType?.name === "REGULAR ACADEMICS") {
            newArrr[0].offerLectureCount = res?.level3;
          }
          else {
            newArrr[0].offerLectureCount = res?.level1;
            newArrr[1].offerLectureCount = res?.level2;
            newArrr[2].offerLectureCount = res?.level3;
          }

        },
        () => { }
      )
    );
    setPackageDetails(newArrr);
  };

  const onChangeLectureCount = (value: any) => {
    setLectureCount(value?._id);
  };

  const onChangeSection = (value: { _id: string; name?: string }) => {
    setSection(value._id);
    setresetSection(false);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    // setPackageDetails(initialData);
  };

  const onChangeMainCategory = (value: {
    _id?: number;
    name?: string;
    sessionIds?: any;
  }) => {
    setCategory(value);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    // setPackageDetails(initialData);
    setPackageName(
      `${grade !== "" ? grade?.name : exam?.name ? exam?.name : ""} ${subject?.name ? subject?.name : ""
      } - ${value?.name}`
    );
    if (value?._id === 2) {
      setSessionList(value?.sessionIds);
    } else {
      setSessionList([]);
    }
    setresetMainCategory(false);
    setresetStatus(true);
  };

  const onChangePackageName = (val: any) => {
    setPackageName(val);
  };

  const changeSessionFlag = (event: any, index: any) => {
    const newData = JSON.parse(JSON.stringify(sessionList));
    if (event.target.checked) {
      newData[index].isCheck = true;
      setSessionList(newData);
    } else {
      newData[index].isCheck = false;
      setSessionList(newData);
    }
  };

  const changeSessionTime = (value: any, index: any) => {
    const newData = JSON.parse(JSON.stringify(sessionList));
    newData[index].time = value;
    setSessionList(newData);
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      setBannerImage("");
      return;
    }
    if (e?.target?.files[0]?.size > image_size_2MB) {
      dispatch(showErrorSnackbar("File size should be less than 2 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBannerImage(reader.result);
        setPackageBanner(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleOptionPdfChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFilePDFType(e)) {
      dispatch(showErrorSnackbar(validateIsFilePDFTypeErrMsg));
      setBannerPdf("");
      return;
    }
    if (e?.target?.files[0]?.size > video_size_25MB) {
      dispatch(showErrorSnackbar("File size should be less than 25 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setBannerPdf(reader.result);
        setPackagePDF(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const onChangeHomePriority = (value: any) => {
    setHomePage(value);
    setresetHomePriority(false);
  };

  const onChangeStatus = (value: any) => {
    setStatus(value);
    setresetStatus(false);
  };

  const onChangePackageDetails = (val: any, index: number, key: string) => {
    const newArrr: any = [...packageDetails];
    if (key === "isRecommended") {
      newArrr.map((item: any, indexval: number) => {
        if (index === indexval) {
          item.isRecommended = true;
        } else {
          item.isRecommended = false;
        }
        return item;
      });
      setPackageDetails(newArrr);
    } else if (key === "priorityOrder") {
      if (val > "3") {
        dispatch(showErrorSnackbar("Priority Order should be less than 3!!"));
      } else {
        const checkPO = newArrr?.filter?.(
          (item: any) => item?.priorityOrder === val
        );
        if (checkPO?.length > 0) {
          dispatch(
            showErrorSnackbar("Priority Order " + val + " Already Set.")
          );
          newArrr[index][key] = "";
          setPackageDetails(newArrr);
        } else {
          newArrr[index][key] = val;
          setPackageDetails(newArrr);
        }
      }
    } else {
      newArrr[index][key] = val;
      setPackageDetails(newArrr);
    }
  };

  const renderSession = () => {
    return (
      <div className="d-flex mb-2">
        <div className="col-4 align-item-center d-flex">
          <LabelField lableName={"Session Flag"} />
        </div>
        <div className="col-8 ps-0">
          <div className="mt-2 border rounded p-1">
            <table className="border-0">
              <tr>
                <th style={{ background: "#fff", color: "#000", width: "50%" }}>
                  Session
                </th>
                <th style={{ background: "#fff", color: "#000", width: "50%" }}>
                  Timing(Optional)
                </th>
              </tr>
              {sessionList !== undefined
                ? sessionList?.map(
                  (
                    c: {
                      _id: string;
                      time: string;
                      name: string;
                      isCheck: boolean;
                    },
                    index: number
                  ) => (
                    <tr key={index}>
                      <td
                        style={{
                          background: "#fff",
                          color: "#000",
                          padding: "0 10px",
                        }}
                      >
                        <div>
                          <input
                            type="checkbox"
                            id={c._id}
                            name={c.time}
                            value={c.name}
                            onChange={(event) =>
                              changeSessionFlag(event, index)
                            }
                            checked={c?.isCheck}
                          />
                          <span className="ms-2">
                            <b>{c.name}</b>
                          </span>
                        </div>
                      </td>
                      <td
                        style={{
                          background: "#fff",
                          color: "#000",
                          padding: "0 10px",
                        }}
                      >
                        <div className="datepicker">
                          <Time
                            value={c.time}
                            setInputText={(value: string) =>
                              changeSessionTime(value, index)
                            }
                          />
                        </div>
                      </td>
                    </tr>
                  )
                )
                : null}
            </table>
          </div>
        </div>
      </div>
    );
  };

  const getSessions = (item: { _id: string; time: string }) => {
    if (item.time === undefined || item.time === "") {
      return {
        sessionId: item._id,
      };
    } else {
      return {
        sessionId: item._id,
        time: item.time,
      };
    }
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    let selectedsessionsData: any = [];
    if (sessionList?.length > 0) {
      selectedsessionsData = sessionList.filter(
        (session: any) => session?.isCheck
      );

      selectedsessionsData = selectedsessionsData.map(
        (data: { _id: string; time: string }) => {
          return getSessions(data);
        }
      );
    }

    let postData: any = {
      countryId: country?._id,
      coursetypeId: courseType._id,
      gradeId: grade?._id,
      examId: exam._id,
      subjectId: subject?._id,
      totalLectureCount: lectureCount,
      sectionId: section,
      maincategoryId: category._id,
      packageName,
      isHomePage: homePage?._id,
      isCourseActive: status?._id,
      packageOptions: packageDetails,
    };

    if (selectedsessionsData.length > 0) {
      postData["sessions"] = selectedsessionsData;
    }

    if (video.length > 0) {
      postData["videoUrl"] = video;
    }

    if (
      postData["examId"] === null ||
      postData["examId"] === undefined ||
      postData["examId"] === ""
    ) {
      delete postData["examId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (packageBanner) {
      dispatch(
        uploadPackageMedia(
          packageBanner,
          (resImage: any) => {
            if (resImage?.file) {
              postData["bannerImage"] = resImage?.file;
            }
            if (resImage?.originalName) {
              postData["originalBannerImage"] = resImage?.originalName;
            }
            if (packagePDF) {
              dispatch(
                uploadPackageMedia(
                  packagePDF,
                  (resPdf: any) => {
                    if (resPdf?.file) {
                      postData["packagePDF"] = resPdf?.file;
                    }
                    if (resPdf?.originalName) {
                      postData["originalPackagePDF"] = resPdf?.originalName;
                    }
                    dispatch(
                      addPackage(
                        postData,
                        () => {
                          setCountry("");
                          setResetCountryList(true);
                          resetForm();
                        },
                        () => { }
                      )
                    );
                  },
                  () => { }
                )
              );
            }
          },
          () => { }
        )
      );
    }
  };

  const resetForm = () => {
    setCourseType("");
    setresetCourseType(true);
    setCourseTypeList([]);
    setGrade("");
    setResetGradeList(true);
    setExam("");
    setresetexam(true);
    setExamList([]);
    setSubject("");
    setResetSubject(true);
    setSubjectList([]);
    setLectureCount("");
    setSection("");
    setresetSection(true);
    setSectionList([]);
    setCategory("");
    setresetMainCategory(true);
    setPackageName("");
    setPackageBanner("");
    setBannerImage("");
    setPackagePDF("");
    setBannerPdf("");
    setVideo("");
    setHomePage("");
    setresetHomePriority(true);
    setStatus("");
    setresetStatus(true);
    setPackageDetails(initialData);
  };

  function validation() {
    return (
      country === "" ||
      courseType === "" ||
      (courseType?.type === 1 || courseType?.type === 3 ? exam === "" : null) ||
      (courseType?.type === 2 ? grade === "" : null) ||
      subject === "" ||
      lectureCount === "" ||
      section === "" ||
      category === "" ||
      validateSessions() ||
      packageName === "" ||
      packageName.length < 5 ||
      bannerImage === "" ||
      bannerPdf === "" ||
      homePage === "" ||
      status === "" ||
      validatePackageDetails()
    );
  }

  const validateSessions = () => {
    let result = false;
    let type = category._id;
    if (sessionList.length > 0 && type === 2) {
      let indexes = sessionList?.filter((elm: any) => elm?.isCheck);
      if (indexes?.length <= 0) {
        result = true;
      }
    }

    return result;
  };

  const validatePackageDetails = () => {
    let result = false;
    let length = packageDetails?.length;
    if (packageDetails?.length > 0) {
      for (var i = 0; i < length; i++) {
        let isRecommended: any = packageDetails?.filter(
          (elm: any) => elm?.isRecommended
        );
        if (
          packageDetails[i]?.levelName === "" ||
          packageDetails[i]?.offerLectureCount === "" ||
          packageDetails[i]?.offerPrice === "" ||
          packageDetails[i]?.sellingPrice === "" ||
          packageDetails[i]?.priorityOrder === "" ||
          isRecommended?.length <= 0
        ) {
          result = true;
        }
      }
    }
    return result;
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE PACKAGE/BATCH"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center mt-2">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center mt-2">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCourseType(value)
                  }
                  value={courseType}
                  options={courseTypeList}
                  Isrequired={true}
                  reset={resetCourseType}
                />
              </div>
            </div>
            {courseType?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center mt-2">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {courseType?.type === 1 || courseType?.type === 3 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-8 ps-2">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetexam}
                  />
                </div>
              </div>
            ) : null}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-8 d-flex ps-2">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Total Lecture Count"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Lecture Count"
                  value={lectureCount}
                  onChangeInput={(value: any) => onChangeLectureCount(value)}
                  isRequired={true}
                  disabled={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Section Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Section Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSection(value)
                  }
                  value={section}
                  options={sectionList}
                  Isrequired={true}
                  reset={resetSection}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Main Category"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Main Category"
                  setInputText={(value: { _id: number; name: string }) =>
                    onChangeMainCategory(value)
                  }
                  value={category}
                  options={mainCategoryList}
                  Isrequired={true}
                  reset={resetMainCategory}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Package Name"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Package Name"
                  value={packageName}
                  onChangeInput={(value: any) => onChangePackageName(value)}
                  isRequired={true}
                  minlength={5}
                  maxlength={150}
                />
              </div>
            </div>

            {sessionList?.length > 0 && category?._id === 2
              ? renderSession()
              : null}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Package Banner"} />
              </div>
              <div className="col-8 ">
                <ImagePickerAndViewer
                  value={bannerImage}
                  handleChange={(e: any) => handleOptionImageChange(e)}
                  isRequired
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Package PDF"} />
              </div>
              <div className="col-8 ">
                <ImagePickerAndViewer
                  value={bannerPdf}
                  handleChange={(e: any) => handleOptionPdfChange(e)}
                  isRequired
                  doctype={"pdf"}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Package Video"} />
              </div>
              <div className="col-8 pe-3">
                <InputField
                  placeholder="Enter Package Video"
                  value={video}
                  onChangeInput={(value: any) => setVideo(value)}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Home Page"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select "
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeHomePriority(value)
                  }
                  value={homePage}
                  options={homepageList}
                  Isrequired={true}
                  reset={resetHomePriority}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Active"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Status"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeStatus(value)
                  }
                  value={status}
                  options={statusList}
                  Isrequired={true}
                  reset={resetStatus}
                />
              </div>
            </div>
          </div>
          <div className="mb-2">
            <div className="fw-bold mb-4">Package Details</div>
            <div className="row w-100">
              <div className="col-md-3">
                <div className="border p-2 pt-1 rounded mb-3 w-100">
                  {LabelArr?.map((item: any, index: number) => (
                    <div key={index} className="packageLabelName d-flex">
                      <LabelField lableName={item} />
                    </div>
                  ))}
                </div>
              </div>
              {packageDetails?.map((item: any, index: number) => {
                return (
                  <div className="d-flex col-md-3" key={index}>
                    <div className="border p-2 pt-3 rounded mb-3 w-100">
                      <div className="d-flex mb-3 align-items-center">
                        <div className="col-12 ps-2">Level {item?.levelId}</div>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="col-12  ">
                          <InputField
                            placeholder="Offer Lecture Count"
                            value={item?.offerLectureCount}
                            onChangeInput={(value: any) =>
                              onChangePackageDetails(
                                value,
                                index,
                                "offerLectureCount"
                              )
                            }
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="col-12  ">
                          <InputField
                            placeholder="Enter Display Name"
                            value={item?.levelName}
                            onChangeInput={(value: any) =>
                              onChangePackageDetails(value, index, "levelName")
                            }
                            minlength={5}
                            maxlength={100}
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="col-12">
                          <InputField
                            placeholder="Enter Selling Price"
                            value={item?.sellingPrice}
                            onChangeInput={(value: any) =>
                              onChangePackageDetails(
                                value,
                                index,
                                "sellingPrice"
                              )
                            }
                            maxlength={6}
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="col-12 ">
                          <InputField
                            placeholder="Enter Offer Price "
                            value={item?.offerPrice}
                            onChangeInput={(value: any) =>
                              onChangePackageDetails(value, index, "offerPrice")
                            }
                            maxlength={6}
                          />
                        </div>
                      </div>
                      <div className="d-flex mb-2 align-items-center">
                        <div className="col-12  ">
                          <InputField
                            placeholder="Enter Priority Order"
                            value={item?.priorityOrder}
                            onChangeInput={(value: any) =>
                              onChangePackageDetails(
                                value,
                                index,
                                "priorityOrder"
                              )
                            }
                            maxlength={1}
                          />
                        </div>
                      </div>
                      <div className="d-flex align-items-center">
                        <div className="col-12 ps-2">
                          <div className="form-check">
                            <input
                              className="form-check-input border-primary"
                              type="radio"
                              name="recommendation"
                              value={item?.offerPrice}
                              checked={item.isRecommended}
                              onChange={(value: any) =>
                                onChangePackageDetails(
                                  value?.target?.checked,
                                  index,
                                  "isRecommended"
                                )
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Create Package"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
