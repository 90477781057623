import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  addNotification,
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralSubjectList,
  getNotificationIconList,
} from "../../Redux/Actions/AdminAction";
import {
  getGeneralPackageList,
  getPackageSessionList,
  getPremierVideoId,
  getStudentList,
  getTimezoneList,
} from "../../Redux/Actions/SuperAdminAction";
import { length_FiveHundred, length_Ten } from "../../Utils/validators";
import {
  notificationTypeData,
  priorityOrderData,
  notificationScheduleType,
} from "../../Utils/dataConstant";
import InputTextArea from "../../Atoms/InputTextArea";
import DateTimePicker from "../../Components/dateTimePicker";
import { today_dateTime } from "../../Utils/appConstants";
export default function CreateNotification() {
  const dispatch = useDispatch();
  const [noti, setNoti] = useState<any>(1);
  const [notiList, setNotiList] = useState<any>([]);
  const [courseType, setCourseType] = useState("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState("");
  const [examList, setExamList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [session, setSession] = useState<any>("");
  const [notification, setNotification] = useState<any>("");
  const [resetNotification, setResetNotification] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [priorityOrder, setPriorityOrder] = useState<any>("");
  const [priorityOrderList, setPriorityOrderList] = useState<any>([]);
  const [resetPriorityOrder, setResetPriorityOrder] = useState(false);
  const [descriptionArr, setDescription] = useState("");
  const [date, setdate] = useState("");
  const [sessionTime, setSessionTime] = useState("");
  const [timezone, setTimezone] = useState<any>("");
  const [timezoneList, setTimezoneList] = useState<any>([]);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [notificationIconList, setNotificationIconList] = useState([]);
  const [icon, setIcon] = useState<any>("");
  const [batchList, setBatchList] = useState([]);
  const [sessionsList, setSessionsList] = useState([]);
  const [resetSessions, setResetSessions] = useState(false);
  const [batch, setBatch] = useState<any>("");
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [resetBatch, setResetBatch] = useState(false);
  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);
  const [mindate, setmindate] = useState(today_dateTime);

  useEffect(() => {
    setPriorityOrderList(priorityOrderData);
    setmindate(new Date());
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setcourseTypeList(res);
        },
        () => { }
      )
    );

    dispatch(
      getPremierVideoId(
        {},
        (res: any) => {
          setNotiList(res);
          setNoti(res[0]?._id);
        },
        () => { }
      )
    );
    dispatch(
      getTimezoneList(
        {},
        (res: any) => {
          setTimezoneList(res);
        },
        () => { }
      )
    );
    dispatch(
      getNotificationIconList(
        "",
        (res: any) => {
          setNotificationIconList(res);
        },
        () => { }
      )
    );
  }, []);
  const onChangeType = (value: any) => {
    setNoti(value);
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetSubject(true);
          setResetSessions(true);
        },
        () => { }
      )
    );
  };
  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: value._id,
        },
        (res: any) => {
          setSubjectList(res);
          setResetExam(false);
          setResetSessions(true);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    setResetSessions(true);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setBatchList(res);
        },
        () => { }
      )
    );
  };
  const onChangeNotificationType = (value: { _id: any; name?: string }) => {
    setNotification(value);
  };
  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value);
  };
  const onChangePriority = (value: { _id: any; name?: string }) => {
    setPriorityOrder(value);
    setResetPriorityOrder(false);
  };
  function onChangeDesc(val: any) {
    setDescription(val);
  }
  const onChangeTimeZone = (value: any) => {
    setTimezone(value);
    setResetTimezone(false);
  };
  const onChangeBatch = (value: any) => {
    setBatch(value._id);
    setResetBatch(false);
  };
  const onSelectBatch = (event: {
    target: { checked: boolean; id: string };
  }) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
    dispatch(
      getPackageSessionList(
        {
          packageIds: selectedBatch,
        },
        (res: any) => {
          setSessionsList(res);
        },
        () => { }
      )
    );
  };
  const onChangeStudentName = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };

  const onSearchStudentClick = () => {
    dispatch(
      getStudentList(
        {
          examtypeId: exam,
          search: student,
        },
        (res: any) => {
          setStudentList(res);
          setSelectedStudent([]);
        },
        () => { }
      )
    );
  };

  const onChangeRegisteredStud = (event: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);
    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  function validation() {
    return (
      courseType === "" ||
      (noti != 4 && exam === "") ||
      (noti != 4 && subject === "") ||
      notification === "" ||
      notificationTitle === "" ||
      priorityOrder === "" ||
      descriptionArr === "" ||
      date === "" ||
      icon === "" ||
      timezone === "" ||
      (noti != 1 && noti != 4 && selectedStudent?.length === 0) ||
      (noti === 2 && batch === "") ||
      (noti === 1 && selectedBatch?.length === 0)
    );
  }
  const resetForm = () => {
    setResetCourseType(true);
    setResetExam(true);
    setResetSubject(true);
    setResetNotification(true);
    setNotificationTitle("");
    setResetPriorityOrder(true);
    setCourseType("");
    setExam("");
    setDescription("");
    setdate("");
    setSessionTime("");
    setResetTimezone(true);
    setStudent("");
    setBatchList([]);
    setSessionsList([]);
    setSelectedStudentList([]);
    setResetBatch(true);
    setNotification("");
    setResetTimezone(true);
    setResetBatch(true);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
    document.querySelectorAll("textarea").forEach((el) => (el.value = ""));
    document
      .querySelectorAll("input[type=radio]")
      .forEach((el: any) => (el.checked = false));
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    var result = selectedStudent.map(function (x: string) {
      return parseInt(x);
    });
    const postData: any = {
      scheduleForId: noti,
      coursetypeId: courseType,
      packageArrayId: noti === 1 ? selectedBatch : [batch],
      notificationTypeId: notification._id,
      notificationTitle: notificationTitle,
      priorityorderId: priorityOrder._id,
      notificationText: descriptionArr,
      scheduledAt: date,
      notificationIconId: icon._id,
      timezoneId: timezone._id,
      studentArrayID: result,
      sessionId: session._id,
    };
    if (result.length === 0) delete postData["studentArrayID"];
    if (selectedBatch.length === 0 && batch === "")
      delete postData["packageArrayId"];
    if (session === "") delete postData["sessionId"];
    if (noti !== 4) {
      postData["examtypeId"] = exam;
      postData["subjectId"] = subject;
    }

    dispatch(
      addNotification(
        postData,
        (res: any) => {
          resetForm();
        },
        () => { }
      )
    );
  };
  const onChangeValue = (e: any, index: any) => {
    setNoti(index);
    resetForm();
  };
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE NOTIFICATION"} />
      <div className="px-4 mx-0 pt-4 formDiv ">
        <form className="col-10" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {notificationScheduleType.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          value={c._id}
                          checked={noti === c?._id ? true : false}
                          onChange={(e) => onChangeValue(e, c._id)}
                        />{" "}
                        <span className="ms-2">{c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                Isrequired={true}
                reset={resetCourseType}
              />
            </div>
          </div>
          {noti !== 4 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                  />
                </div>
              </div>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Subject Name"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Subject Name"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSubject(value)
                    }
                    value={subject}
                    options={subjectList}
                    Isrequired={true}
                    reset={resetSubject}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {noti === 1 && batchList.length > 0 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Batch Name"} />
                </div>
                <div className="col-6  ">
                  <div className=" border rounded p-3 mx-2">
                    {batchList.length > 0
                      ? batchList.map(
                        (
                          c: { checked: boolean; _id: string; name: string },
                          index: number
                        ) => {
                          return (
                            <div className="ms-3">
                              <input
                                type="checkbox"
                                id={c._id}
                                value={c.name}
                                onChange={onSelectBatch}
                              />
                              <span className="ms-2">{c.name}</span>
                            </div>
                          );
                        }
                      )
                      : null}
                  </div>
                </div>
              </div>
              <div className="d-flex Justify-content-between mt-3">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Session"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Session"
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeSession(value)
                    }
                    value={session}
                    options={sessionsList}
                    Isrequired={true}
                    reset={resetSessions}
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          )}

          {noti === 2 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>

              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                  reset={resetBatch}
                />
              </div>
            </div>
          ) : null}

          <div className="d-flex Justify-content-between mt-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Notification Type"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Notification Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeNotificationType(value)
                }
                value={notification}
                options={notificationTypeData}
                Isrequired={true}
                reset={resetNotification}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between mt-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Notification Title"} />
            </div>

            <div className="col-6  ">
              <InputField
                placeholder="Enter Notification Title"
                value={notificationTitle}
                onChangeInput={(value: any) => setNotificationTitle(value)}
                maxlength={50}
                minlength={3}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between mt-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Priority Order"} />
            </div>

            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Priority Order"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangePriority(value)
                }
                value={priorityOrder}
                options={priorityOrderList}
                Isrequired={true}
                reset={resetPriorityOrder}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between mt-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Notification Text"} />
            </div>

            <div className="col-6 ps-2  ">
              <InputTextArea
                placeholder="Enter Notification Description"
                rows={4}
                cols={40}
                value={descriptionArr}
                onChangeInput={(value: any) => {
                  onChangeDesc(value);
                }}
                maxlength={length_FiveHundred}
                minlength={length_Ten}
                isRequired={true}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Date & Time"} />
            </div>
            <div className="col-6 d-flex ">
              <div className="col-6">
                <DateTimePicker
                  value={date}
                  setInputText={(value: string) => setdate(value)}
                  Isrequired={true}
                />
              </div>
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Time Zone"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Time Zone"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeTimeZone(value)
                }
                value={timezone}
                options={timezoneList}
                Isrequired={true}
                reset={resetTimezone}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between ps-2">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Icon"} />
            </div>
            <div className="col-6  d-flex pe-3">
              <div className="col-12 border rounded p-3">
                {notificationIconList.map((item: any) => {
                  return (
                    <div className=" ">
                      <input
                        type="radio"
                        className="me-2"
                        name="icon"
                        value={item}
                        onChange={() => setIcon(item)}
                      />
                      {
                        <img
                          src={item?.notificationIcon}
                          style={{ width: "40px", height: "40px" }}
                          alt={""}
                        />
                      }
                      {"   "}
                      {item?.originaliconName}
                    </div>
                  );
                })}
              </div>
              <span className="required ms-2 fs-6">*</span>
            </div>
          </div>

          {noti === 2 || noti === 3 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Schedule For"} />
              </div>
              <div className="col-6 pe-2">
                <div className="p-2 border rounded">
                  <p className="fw-bold ">Registered Student</p>
                  <div className="d-flex">
                    <InputField
                      placeholder="Enter student name"
                      value={student}
                      onChangeInput={(value: any) => onChangeStudentName(value)}
                      maxlength={50}
                      minlength={1}
                    />
                    <button
                      type="button"
                      className="btn btn-primary mb-3 align-items-center liveSearchBtn mt-2"
                      onClick={(e) => onSearchStudentClick()}
                    >
                      Search
                    </button>
                  </div>
                  {studentList !== undefined && student.length > 0
                    ? studentList.map((c: any, index) => {
                      return (
                        <div className="ms-3">
                          <input
                            type="checkbox"
                            id={c._id}
                            value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                            onChange={onChangeRegisteredStud}
                          />
                          <span className="ms-2">
                            {c.firstName} {c.lastName}
                          </span>
                        </div>
                      );
                    })
                    : null}
                </div>
                {selectedStudentList.length > 0 ? (
                  <div className="col-auto p-2 border rounded">
                    {selectedStudentList.map((item: any, index: any) => {
                      return <div>{item}</div>;
                    })}
                  </div>
                ) : null}
              </div>
              <span className="required fs-6">*</span>
            </div>
          ) : null}

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
