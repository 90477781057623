import { SetStateAction, useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import SubmitButton from "../../Components/submitButton";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import Pagination from "@mui/material/Pagination";
import { getTableDataSlots, usePagination } from "../../Utils/appConstants";
import { useDispatch } from "react-redux";
import {
  filterRoles,
  getAdminPanelUsers,
  getDepartment,
} from "../../Redux/Actions/SuperAdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import {
  hasWhiteSpace,
  length_Five,
  length_Four,
  length_Hundred,
  length_Six,
  length_Ten,
  length_Thirty,
  length_Three,
  startsWithSpace,
  validateFullName,
  validateIsLettersAndDigitsOnly,
  validateIsNumberOnly,
  validatePassword,
  validateUniqueId,
} from "../../Utils/validators";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import CancelOutlinedIcon from "@mui/icons-material/CancelOutlined";
import {
  deleteDsaUser,
  getCountryList,
  updateDsaUser,
} from "../../Redux/Actions/AdminAction";
import { CouponStatusList } from "../../Utils/dataConstant";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";

export default function UpdateUser() {
  const dispatch: Function = useDispatch();
  const [departmentList, setDepartmentList] = useState([]);
  const [rolesList, setRolesList] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  // const [employeeId, setEmployeeId] = useState<string>("");
  // const [empId, setEmpId] = useState<string>("");
  const _DATA = usePagination(String(count), rowsPerPage);
  const [users, setUsers] = useState([]);
  const [isEditMode, setisEditMode] = useState(false);
  const [employeeName, setEmployeeName] = useState<string>("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [departmentedit, setDepartmentedit] = useState<string>("");
  const [roleedit, setRoleedit] = useState("");
  const [email, setEmail] = useState<string>("");
  const [dateOfJoining, setDateOfJoining] = useState<string>("");
  const [userName, setUserName] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [userID, setuserID] = useState<number>();
  const [changePassword, setChangePassword] = useState(false);
  // const [uniqueID, setUniqueId] = useState<any>("");
  const [dsaUserStatus, setDsaUserStatus] = useState<any>("");
  const [resetDsaUserStatusList, setResetDsaUserStatusList] = useState(false);
  const [toggle, setToggle] = useState<any>(false);
  const [country, setCountry] = useState<any>("");
  const [resetCountryList, setResetCountryList] = useState(false);

  const initialVal: {
    countryId: any;
    charges: any;
  }[] = [
    {
      countryId: "",
      charges: "",
    },
  ];
  const [priceArr, setPriceArr] = useState(initialVal);
  const [countryList, setCountryList] = useState([]);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getDepartment(
        "",
        (res: any) => {
          let tempStatus = res?.filter((item: any) => item?._id === 16);
          setDepartmentList(tempStatus);
        },
        () => {}
      )
    );

    const postData: any = {
      skip: 0,
      limit: 10,
      departmentId: 16,
      userRoleId: 16,
    };
    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );

    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeDsaUserStatus = (value: any) => {
    setResetDsaUserStatusList(false);
    setDsaUserStatus(value);
  };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number) => {
    const postData: any = {
      skip: (page - 1) * rowsPerPage,
      limit: rowsPerPage,
      departmentId: 16,
      userRoleId: 16,
    };

    // if (employeeId) {
    //   postData["empCreatedId"] = employeeId;
    // }
    if (dsaUserStatus) {
      postData["status"] = dsaUserStatus?._id;
    }

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value);
  };

  const handleEdit = (value: any) => {
    setEmployeeName(value?.name);
    // setEmpId(value?.empCreatedId);
    setDepartmentedit(value?.departmentId?._id);
    setRoleedit(value?.userRoleId?._id);
    setMobileNo(value?.mobile);
    setEmail(value?.email);
    setDateOfJoining(value?.doj);
    setUserName(value?.username);
    setToggle(value?.isActive);
    setuserID(value?.empId);
    // setUniqueId(value?.uniqueId);
    setCountry(value?.countryId);
    setisEditMode(true);
  };

  const handleDeactivate = (value: any) => {
    const postData: any = {
      isActive: false,
    };

    dispatch(
      deleteDsaUser(
        postData,
        value?.empId,
        (res: any) => {
          const postData: any = {
            skip: 0,
            limit: 10,
            departmentId: 16,
            userRoleId: 16,
          };
          // if (employeeId) {
          //   postData["empCreatedId"] = employeeId;
          // }
          dispatch(
            getAdminPanelUsers(
              postData,
              (res: any) => {
                setUsers(res?.data);
                setCount(res?.count);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
  };

  const onChangeDepartmentedit = (value: { _id: any; name?: string }) => {
    setDepartmentedit(value._id);
    dispatch(
      filterRoles(
        value._id,
        (res: any) => {
          setRolesList(res);
        },
        () => {}
      )
    );
  };

  const onChangeRolesedit = (value: { _id: any; name?: string }) => {
    setRoleedit(value._id);
  };

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeEmpName = (value: any) => {
    if (validateFullName(value)) {
      setEmployeeName(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };
  // const onChangeEmpId = (value: any) => {
  //   if (validateIsLettersAndDigitsOnly(value)) {
  //     setEmpId(value);
  //   } else {
  //     dispatch(showErrorSnackbar("Please only Character & Number"));
  //     return;
  //   }
  // };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
  };
  // const onChangeEmployeeId = (value: any) => {
  //   if (validateIsLettersAndDigitsOnly(value)) {
  //     setEmployeeId(value);
  //   } else {
  //     dispatch(showErrorSnackbar("Please only Character & Number"));
  //     return;
  //   }
  // };

  const onChangeUserName = (value: any) => {
    if (!startsWithSpace(value)) {
      setUserName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid User Name"));
      return;
    }
  };

  const onChangeEmail = (value: any) => {
    if (!startsWithSpace(value) && !hasWhiteSpace(value)) {
      setEmail(value);
    } else {
      dispatch(showErrorSnackbar("Please enter valid Email"));
      return;
    }
  };

  // const onchangeUniqueId = (val: string) => {
  //   if (validateUniqueId(val) && !startsWithSpace(val)) {
  //     setUniqueId(val?.toUpperCase());
  //   } else {
  //     dispatch(
  //       showErrorSnackbar("Please Character only & space is not allowed") as any
  //     );
  //     return;
  //   }
  // };

  const renderListMode = () => {
    return (
      <form className="formDiv" onSubmit={handleSubmit}>
        <div className="col-md-12 px-4 mx-0 mt-4 d-flex row Justify-content-between">
          <div className="col-md-4 d-flex Justify-content-between p-2 ">
            <label className="fw-bold pt-2 me-2">Status</label>
            <div className="col-7 ps-2 ">
              <CustomDropdown
                lableName="Select Status"
                setInputText={(value: any) => onChangeDsaUserStatus(value)}
                value={dsaUserStatus}
                options={CouponStatusList}
                reset={resetDsaUserStatusList}
              />
            </div>
          </div>
          {/* <div className="col-md-4 d-flex Justify-content-between">
            <LabelField lableName={"Employee ID"} />
            <InputField
              placeholder="Enter Employee ID"
              value={employeeId}
              onChangeInput={(value: any) => onChangeEmployeeId(value)}
              maxlength={length_Six}
            />
          </div> */}
          <div className="col-md-2 d-flex Justify-content-between mt-1">
            <SubmitButton name="Submit" />
          </div>
        </div>

        {users.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center pt-3 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={users ? users : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}

        <PaginationTable
          tableData={users ? users : []}
          pagename={"DsaUserdata"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Employee Name",
            // "Employee ID",
            "Department",
            "Status",
            "Panel Role",
            "Mobile Number",
            "Username",
            "Date Of Joining",
            "ACTION",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDeactivate={(value: any) => handleDeactivate(value)}
        />
      </form>
    );
  };

  const handleChangePassword = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setChangePassword(!changePassword);
  };

  const onChangePassword = (value: SetStateAction<string>) => {
    if (validatePassword(value)) {
      setPassword(value);
    } else {
      dispatch(showErrorSnackbar("Please input valid Password"));
      return;
    }
  };
  const handleClose = () => {
    setChangePassword(false);
  };

  const changeToggle = (event: any) => {
    if (event.target.checked) {
      setToggle(true);
      const postData: any = {
        isActive: true,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    } else {
      setToggle(false);
      const postData: any = {
        isActive: false,
      };

      dispatch(
        deleteDsaUser(
          postData,
          userID,
          (res: any) => {},
          () => {}
        )
      );
    }
  };
  const renderEditMode = () => {
    return (
      <div className="col-7 px-5">
        <form onSubmit={handleupdate}>
          <div className="d-flex mb-2 flex-column p-3">
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee Name"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee Name"
                  value={employeeName}
                  onChangeInput={(value: any) => onChangeEmpName(value)}
                  maxlength={length_Hundred}
                  minlength={length_Three}
                />
              </div>
            </div>
            {/* <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Employee ID"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Employee ID"
                  value={empId}
                  onChangeInput={(value: any) => onChangeEmpId(value)}
                  maxlength={length_Six}
                />
              </div>
            </div> */}
            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  defaultValue={country}
                  reset={resetCountryList}
                />
              </div>
            </div>

            <div className="d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChangeInput={(value: any) => onChangeMobileNO(value)}
                  maxlength={length_Ten}
                  minlength={length_Six}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Email Address"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Email Address"
                  value={email}
                  onChangeInput={(value: any) => onChangeEmail(value)}
                  maxlength={length_Hundred}
                />
              </div>
            </div>
            {/* <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Unique Id"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Unique Id"
                  value={uniqueID}
                  onChangeInput={(value: any) => onchangeUniqueId(value)}
                  maxlength={length_Four - 1}
                  minlength={length_Three - 1}
                  isRequired={true}
                />
              </div>
            </div> */}

            <div className="d-flex Justify-content-between  ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Username"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Username"
                  value={userName}
                  onChangeInput={(value: any) => onChangeUserName(value)}
                  maxlength={length_Thirty}
                  minlength={length_Five}
                />
              </div>
            </div>
            {changePassword ? (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Password"} />
                </div>
                <div className="col-6">
                  <InputField
                    placeholder="Enter Password"
                    value={password}
                    onChangeInput={(value: any) => onChangePassword(value)}
                    maxlength={30}
                    minlength={5}
                  />
                </div>
                <div className="pt-3">
                  <CancelOutlinedIcon onClick={() => handleClose()} />
                </div>
              </div>
            ) : (
              <div className="d-flex Justify-content-between  ">
                <div className="col-2 align-item-center d-flex"></div>
                <div className=" ps-2 ">
                  <button
                    type="submit"
                    className={"btn btn-primary rounded-pill me-4"}
                    onClick={(e) => handleChangePassword(e)}
                  >
                    Change Password{" "}
                  </button>
                </div>
              </div>
            )}

            {/* {toggle === false ? */}
            <div className="d-flex mb-2 align-items-center mt-2">
              <LabelField lableName={"Change Status"} />
              <ToggleSwitchbtn
                getToggleval={(value: any) => {
                  changeToggle(value);
                }}
                isActive={toggle}
              />
            </div>
            {/* : null} */}
          </div>
          <div className="d-flex mb-2 align-items-center mt-2 p-5">
            <SubmitButton name={"Submit"} />
          </div>
        </form>
      </div>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      skip: 0,
      limit: 10,
      // empCreatedId: employeeId,
      departmentId: 16,
      userRoleId: 16,
    };

    if (
      postData["empCreatedId"] === null ||
      postData["empCreatedId"] === undefined ||
      postData["empCreatedId"] === ""
    ) {
      delete postData["empCreatedId"];
    }
    if (dsaUserStatus) {
      postData["status"] = dsaUserStatus?._id;
    }

    dispatch(
      getAdminPanelUsers(
        postData,
        (res: any) => {
          setUsers(res?.data);
          setCount(res?.count);
        },
        () => {}
      )
    );
  };
  var emailCheck = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  function allemail(inputtxt: any) {
    if (email.match(emailCheck)) {
      return true;
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  }

  const handleupdate = (e: any) => {
    e.preventDefault();
    if (allemail(email)) {
      const postData: any = {
        name: employeeName,
        // empCreatedId: empId,
        departmentId: departmentedit,
        userRoleId: roleedit,
        username: userName,
        mobileNo: mobileNo,
        doj: dateOfJoining,
        email: email,
        // uniqueId: uniqueID,
        isActive: toggle,
        countryId: country?._id,
        // companyId: userId,
      };
      if (
        postData["empCreatedId"] === null ||
        postData["empCreatedId"] === undefined ||
        postData["empCreatedId"] === ""
      ) {
        delete postData["empCreatedId"];
      }
      if (password.length > 0) {
        postData["password"] = password;
      }
      dispatch(
        updateDsaUser(
          postData,
          userID,
          (res: any) => {
            setisEditMode(false);
            const postData: any = {
              skip: 0,
              limit: 10,
              departmentId: 16,
              userRoleId: 16,
            };
            if (dsaUserStatus) {
              postData["status"] = dsaUserStatus?._id;
            }

            dispatch(
              getAdminPanelUsers(
                postData,
                (res: any) => {
                  setUsers(res?.data);
                  setCount(res?.count);
                  setEmployeeName("");
                  // setEmployeeId("");
                  setUserName("");
                  setMobileNo("");
                  setDateOfJoining("");
                  setEmail("");
                  // setUniqueId("");
                  setPriceArr(initialVal);
                  setResetCountryList(true);
                },
                () => {}
              )
            );
          },
          () => {}
        )
      );
    } else {
      dispatch(showErrorSnackbar("Please input valid email"));
      return false;
    }
  };

  return (
    <div className="contentBox">
      <PageTitle
        name={"EDIT USER"}
        isEditMode={isEditMode}
        setisEditMode={setisEditMode}
      />
      {isEditMode ? renderEditMode() : renderListMode()}
    </div>
  );
}
