import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import CustomDropdown from "../../Components/customDropdown";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  dateFormat,
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants";
import Pagination from "@mui/material/Pagination";
import SearchButton from "../../Components/searchButton";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getCourseTypelist,
  getRescheduleList,
} from "../../Redux/Actions/AdminAction";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import moment from "moment";
import RescheduleDetailPopup from "../../Components/recheduleDetailPopup";

export default function Reschedule() {
  const tableRef = useRef(null);
  const dispatch: Function = useDispatch();

  const [startDate, setStartDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(7, "days").format("YYYY-MM-DD")
  );
  const [rescheduleList, setRescheduleList] = useState([]);

  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [rescheduleData, setRescheduleData] = useState<any>("");

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );

    getUserData();
  }, []);

  const getUserData = () => {
    const postData = {
      startDate: startDate,
      endDate: endDate,
      coursetypeId: course?._id,
      countryId: country?._id,
    };
    if (course) {
      postData["coursetypeId"] = course?._id;
    }
    if (country) {
      postData["countryId"] = country?._id;
    }
    dispatch(
      getRescheduleList(
        postData,
        (data: any) => {
          setRescheduleList(data);
        },
        () => { }
      )
    );
  };
  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };
  const handleEdit = (value: any) => {
    setOpenPopup(true);
    setRescheduleData(value);
  };

  const handleDelete = (value: any) => { };

  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };

  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data: any = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        startDate: startDate,
        endDate: endDate,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        startDate: startDate,
        endDate: endDate,
      };
    }
  };

  const handleChangeRowsPerPage = (event: { target: { value: any } }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };

  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };

  const onChangeStartDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) >
      moment(endDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("Start date should be lass than End date"));
    } else {
      setStartDate(value);
    }
  };

  const onChangeEndDate = (value: any) => {
    if (
      moment(value).format(dateFormat.yyyymmddA) <
      moment(startDate).format(dateFormat.yyyymmddA)
    ) {
      dispatch(showErrorSnackbar("End date should be greater than Start date"));
    } else {
      setEndDate(value);
    }
  };

  const handleClose = (value: any) => {
    setOpenPopup(false);
    getUserData();
  };

  const renderListMode = () => {
    return (
      <>
        {rescheduleList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
        {count > 0 ? (
          <PaginationView
            count={count}
            tableData={rescheduleList ? rescheduleList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        <div ref={tableRef}>
          <PaginationTable
            tableData={rescheduleList ? rescheduleList : []}
            pagename={"Reschedule"}
            rowsPerPage={rowsPerPage}
            page={page}
            tableHead={[
              "S.N",
              "TITLE",
              "STUDENT NAME",
              "TEACHER NAME",
              "RESCHEDULE DATE & TIME",
              "REQUEST",
            ]}
            edit={true}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
          />
        </div>
      </>
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getUserData();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"RESCHEDULE"} />
      <form className="text-center" onSubmit={handleSubmit}>
        <div className="px-2 mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
          <div className="col-md-2 d-flex mb-2 align-items-center">
            <LabelField lableName={"Start Date:"} />
            <DatePicker
              value={startDate}
              setInputText={(value: string) => onChangeStartDate(value)}
              maxdate={today}
            />
          </div>
          <div className=" col-md-2 d-flex mb-2 align-items-center">
            <LabelField lableName={"End Date:"} />
            <DatePicker
              value={endDate}
              setInputText={(value: string) => onChangeEndDate(value)}
            />
          </div>
          <div className=" col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"Country"} />
            <CustomDropdown
              lableName="Select Country"
              setInputText={(value: any) => onChangeCountry(value)}
              value={country}
              options={countryList}
              reset={resetCountryList}
            />
          </div>
          <div className="col-md-3 d-flex mb-2 align-items-center">
            <LabelField lableName={"Course Type"} />
            <CustomDropdown
              lableName="Select Course Type Name"
              setInputText={(value: any) => onChangeCourseType(value)}
              value={course}
              options={courseList}
              reset={resetParentCourse}
            />
          </div>

          <div className=" col-md-2 d-flex mb-2 align-items-center">
            <SearchButton name={"Search"} />
          </div>
        </div>
      </form>

      {renderListMode()}
      <RescheduleDetailPopup
        open={openPopup}
        handleClose={handleClose}
        data={rescheduleData}
        getList={getUserData}
      />
    </div>
  );
}
