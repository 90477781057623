import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import {
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getDemoClassBindData,
  getGeneralExamList,
  getLectureList,
  getSubTopicList,
  getTopicList,
  getUpdateDemoData,
} from "../../Redux/Actions/AdminAction";
import Submitbtn from "../../Components/submitButton";
import { GradeList } from "../../Utils/dataConstant";
import Dropdown from "../../Components/dropdown";

export default function DemoData() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setcourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [topic, setTopic] = useState<any>("");
  const [topicList, setTopicList] = useState([]);
  const [grade, setGrade] = useState<any>("");
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [subTopic, setSubTopic] = useState<any>("");
  const [subTopicList, setSubTopicList] = useState([]);
  const [lecture, setLecture] = useState<any>("");
  const [lectureList, setLectureList] = useState([]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);
  const onChangeCountry = (value: any) => {
    setCountry(value);
    setCourseType("");
    setExam("");
    setGrade("");
    setSubject("");
    setTopic("");
    setSubTopic("");
    setLecture("");
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setcourseTypeList(res);
        },
        () => {}
      )
    );
  };
  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setExam("");
    setGrade("");
    setSubject("");
    setTopic("");
    setSubTopic("");
    setLecture("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setSubject("");
    setTopic("");
    setSubTopic("");
    setLecture("");
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value);
    setSubject("");
    setTopic("");
    setSubTopic("");
    setLecture("");
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value);
    setTopic("");
    setSubTopic("");
    setLecture("");
    dispatch(
      getDemoClassBindData(
        value?._id,
        (res: any) => {
          const postData = {
            coursetypeId: courseType?._id,
            gradeId: grade?._id,
            examtypeId: exam?._id,
            subjectId: value?._id,
          };
          if (
            postData["gradeId"] === null ||
            postData["gradeId"] === undefined ||
            postData["gradeId"] === ""
          ) {
            delete postData["gradeId"];
          }
          if (
            postData["examtypeId"] === null ||
            postData["examtypeId"] === undefined ||
            postData["examtypeId"] === ""
          ) {
            delete postData["examtypeId"];
          }
          dispatch(
            getTopicList(
              postData,
              (resTopic: any) => {
                setTopicList(resTopic);
                let tempTopic = resTopic?.filter(
                  (item: any) => res[0]?.topicId === item?._id
                );
                setTopic(tempTopic[0]);
              },
              () => {}
            )
          );

          const data: any = {
            coursetypeId: courseType?._id,
            gradeId: grade?._id,
            examtypeId: exam?._id,
            subjectId: value?._id,
            topicId: res[0]?.topicId,
          };
          if (
            data["gradeId"] === null ||
            data["gradeId"] === undefined ||
            data["gradeId"] === ""
          ) {
            delete data["gradeId"];
          }
          if (
            data["examtypeId"] === null ||
            data["examtypeId"] === undefined ||
            data["examtypeId"] === ""
          ) {
            delete data["examtypeId"];
          }
          dispatch(
            getSubTopicList(
              data,
              (resSubTopic: any) => {
                setSubTopicList(resSubTopic);
                let tempSubTopic = resSubTopic?.filter(
                  (item: any) => res[0]?.subTopicId === item?._id
                );
                setSubTopic(tempSubTopic[0]);
              },
              () => {}
            )
          );

          // ************

          const postdata: any = {
            coursetypeId: courseType?._id,
            gradeId: grade?._id,
            examtypeId: exam?._id,
            subjectId: subject?._id,
            topicId: topic?._id,
            subTopicId: subTopic?._id,
          };
          if (
            postdata["gradeId"] === null ||
            postdata["gradeId"] === undefined ||
            postdata["gradeId"] === ""
          ) {
            delete postdata["gradeId"];
          }
          if (
            postdata["examtypeId"] === null ||
            postdata["examtypeId"] === undefined ||
            postdata["examtypeId"] === ""
          ) {
            delete postdata["examtypeId"];
          }
          dispatch(
            getLectureList(
              postdata,
              (resLecture: any) => {
                setLectureList(resLecture);
                let tempLecture = resLecture?.filter(
                  (item: any) => res[0]?.lectureId === item?._id
                );
                setLecture(tempLecture[0]);
              },
              () => {}
            )
          );
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: value?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicList(res);
        },
        () => {}
      )
    );
  };

  const onChangeTopic = (value: { _id: any; name?: string }) => {
    setTopic(value);
    setSubTopic("");
    setLecture("");
    const data: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: value._id,
    };
    if (
      data["gradeId"] === null ||
      data["gradeId"] === undefined ||
      data["gradeId"] === ""
    ) {
      delete data["gradeId"];
    }
    if (
      data["examtypeId"] === null ||
      data["examtypeId"] === undefined ||
      data["examtypeId"] === ""
    ) {
      delete data["examtypeId"];
    }
    dispatch(
      getSubTopicList(
        data,
        (res: any) => {
          setSubTopicList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubTopic = (value: { _id: any; name?: string }) => {
    setSubTopic(value);
    setLecture("");
    const postdata: any = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
    };
    if (
      postdata["gradeId"] === null ||
      postdata["gradeId"] === undefined ||
      postdata["gradeId"] === ""
    ) {
      delete postdata["gradeId"];
    }
    if (
      postdata["examtypeId"] === null ||
      postdata["examtypeId"] === undefined ||
      postdata["examtypeId"] === ""
    ) {
      delete postdata["examtypeId"];
    }
    if (
      postdata["levelId"] === null ||
      postdata["levelId"] === undefined ||
      postdata["levelId"] === ""
    ) {
      delete postdata["levelId"];
    }
    if (
      postdata["topicId"] === null ||
      postdata["topicId"] === undefined ||
      postdata["topicId"] === ""
    ) {
      delete postdata["topicId"];
    }
    if (
      postdata["subTopicId"] === null ||
      postdata["subTopicId"] === undefined ||
      postdata["subTopicId"] === ""
    ) {
      delete postdata["subTopicId"];
    }
    dispatch(
      getLectureList(
        postdata,
        (res: any) => {
          setLectureList(res);
        },
        () => {}
      )
    );
  };
  const onChangeLecture = (value: { _id: any; name?: string }) => {
    setLecture(value);
  };
  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topicId: topic?._id,
      subTopicId: subTopic?._id,
      lectureId: lecture?._id,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    dispatch(
      getUpdateDemoData(
        Postdata,
        (res: any) => {
          setCountry("");
          setCourseType("");
          setExam("");
          setGrade("");
          setSubject("");
          setTopic("");
          setSubTopic("");
          setLecture("");
        },
        () => {}
      )
    );
  };

  const validation = () => {
    return (
      country === "" ||
      courseType === "" ||
      subject === "" ||
      topic === "" ||
      subTopic === "" ||
      lecture === ""
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"DEMO DATA"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10 pt-2" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} isRequired />
            </div>
            <div className="col-6 ps-2  ">
              <Dropdown
                labelName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} isRequired />
              </div>
              <div className="col-6 ps-2  ">
                <Dropdown
                  labelName="Select Student Grade"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeStudentGrade(value)
                  }
                  value={grade}
                  options={GradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} isRequired />
              </div>
              <div className="col-6 ps-2 ">
                <Dropdown
                  labelName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Topic Name"} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Topic Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeTopic(value)
                }
                value={topic}
                options={topicList}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Sub-Topic Name "} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Sub-Topic Name "
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubTopic(value)
                }
                value={subTopic}
                options={subTopicList}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Lecture Name "} isRequired />
            </div>
            <div className="col-6 ps-2 ">
              <Dropdown
                labelName="Select Lecture Name "
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeLecture(value)
                }
                value={lecture}
                options={lectureList}
              />
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
