import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import LabelField from "./labelField";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DatePicker from "./datePicker";
import moment from "moment";
import SubmitButton from "./submitButton";
import {
  RescheduleRequest,
} from "../Redux/Actions/AdminAction";
import Time from "./time";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  pageName?: any;
  getList?: any
}

export default function SalesReSchedulePopup(props: props) {
  const { open, handleClose, data, pageName, getList } = props;

  const dispatch: Function = useDispatch();

  const [startDate, setStartDate] = useState("");
  const [mindate, setmindate] = useState("");

  const [sessionTime, setSessionTime] = useState<any>("");

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
    setStartDate(moment(data?.date).format("YYYY-MM-DD"));
  }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  const changeSessionTime = (value: any) => {
    setSessionTime(value);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const postData = {
      titleId: data?.titleId,
      sessionTime: sessionTime,
      scheduleFrom: startDate,
    };

    dispatch(
      RescheduleRequest(
        postData,
        (data: any) => {
          handleClose();
          getList();
        },
        () => { }
      )
    );
  };
  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoReSchedulemodel ">
          <div className=" d-flex justify-content-between">
            <h5 className="fw-bold"> Demo Re-Schedule</h5>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-2">
            <form className="" onSubmit={handleSubmit}>
              <div className="row mt-2 py-2 justify-content-center">
                <div className="px-2 mx-0 mt-2 Justify-content-between">
                  <div className="col-md-6 d-flex mb-2 align-items-center">
                    <div className="col-md-12">
                      <LabelField lableName={"Reschedule Date"} />
                    </div>
                    <div className="col-md-12 ">
                      <DatePicker
                        value={startDate}
                        setInputText={(value: string) => setStartDate(value)}
                        mindate={mindate}
                        defaultValue={startDate}
                      />
                    </div>
                  </div>
                  <div className="col-md-6 d-flex mb-2 align-items-center">
                    <div className="col-md-12">
                      <LabelField lableName={"Session Time"} />
                    </div>
                    <Time
                      value={sessionTime}
                      key={"sessionTime"}
                      setInputText={(value: any) => {
                        changeSessionTime(value);
                      }}
                    />
                  </div>
                  <div className="d-flex justify-content-center">
                    <SubmitButton name={"Time Update"} />
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
