import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import DatePicker from "../../Components/datePicker";
import Time from "../../Components/time";
import {
  getGeneralCourseList,
  getGeneralExamList,
  getGeneralTeacherList,
  getGeneralBbbMeetingList,
  getMainCategoryList,
  getCountryList,
  getGeneralTimezoneList,
  bindSubjectList,
  getTopicList,
  getCourseTypelist,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import Submitbtn from "../../Components/submitButton";
import {
  addLivePremier,
  getGeneralPackageList,
  getPackageSessionList,
  getPremierVideoId,
  getStudentList,
  getTimezoneList,
} from "../../Redux/Actions/SuperAdminAction";
import "../../App.css";
import { startsWithSpace, validateFullName } from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import moment from "moment";
import Addmorebtn from "../../Components/addmorebtn";
import { GradeList } from "../../Utils/dataConstant";
import ScheduleLive from "../../Components/scheduleLive";
import LiveVideoPopup from "../../Components/liveVideoPopup";

export default function CreatelivepremierVideo() {
  const dispatch: Function = useDispatch();
  const [courseType, setCourseType] = useState<any>("");
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [lecture, setLecture] = useState("");
  const [loginID, setLoginID] = useState<any>("");
  const [loginIDList, setLoginIDList] = useState([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState([]);
  const [fromdate, setFromdate] = useState<any>("");
  const [todate, setTodate] = useState<any>("");
  const [sessionTime, setSessionTime] = useState<any>("");
  const [selectedStudent, setSelectedStudent] = useState<any>([]);
  const [selectedStudentList, setSelectedStudentList] = useState<string[]>([]);
  const [student, setStudent] = useState("");
  const [studentList, setStudentList] = useState([]);
  const [premierId, setPremierId] = useState<any>(1);
  const [premierIdList, setPremierIdList] = useState<any>([]);
  const [timeZone, setTimeZone] = useState<any>("");
  const [timeZoneList, setTimeZoneList] = useState([]);
  const [resetCourseType, setResetCourseType] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [resetSubject, setResetSubject] = useState(false);
  const [resetloginID, setResetLoginID] = useState<any>(false);
  const [resetteacher, setResetTeacher] = useState<any>(false);
  const [resetTimezone, setResetTimezone] = useState<any>(false);
  const [batchList, setBatchList] = useState([]);
  const [session, setSession] = useState<any>("");
  const [sessionsList, setSessionsList] = useState([]);
  const [resetSessions, setResetSessions] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState<any>([]);
  const [grade, setGrade] = useState<any>("");
  const [batch, setBatch] = useState<any>("");
  const [resetBatch, setResetBatch] = useState(false);
  const [topicNameList, setTopicNameList] = useState<any>([]);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [timeZoneNew, setTimeZoneNew] = useState<any>("");
  const [timeZoneNewList, setTimeZoneNewList] = useState([]);
  const [resetTimezoneNew, setResetTimezoneNew] = useState<any>(false);
  const [resetGradeList, setResetGradeList] = useState<any>(false);
  const [isResetForm, setIsResetForm] = useState<any>(true);
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [liveVideoData, setLiveVideoData] = useState([]);
  const [errorMsg, setErrorMsg] = useState<any>("");

  const initialValInner = [
    {
      lectureId: "",
      title: "",
      scheduleFrom: "",
      scheduleTo: "",
      sessionTime: "",
    },
  ];
  const initialVal = [
    {
      topicId: "",
      subTopicId: "",
      lectureTitle: initialValInner,
    },
  ];

  const [category, setCategory] = useState<any>({});
  const [mainCategoryList, setMinCategoryList] = useState<any>([]);
  const [resetMainCategory, setresetMainCategory] = useState(false);
  const [mindate, setmindate] = useState("");
  const [titleClassScheduleArr, setTitleClassScheduleArr] = useState<any>([]);

  useEffect(() => {
    setmindate(moment(new Date()).format("YYYY-MM-DD"));
    dispatch(
      getPremierVideoId(
        {},
        (res: any) => {
          setPremierIdList(res);
          setPremierId(res[0]?._id);
        },
        () => { }
      )
    );
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseTypeList(res);
        },
        () => { }
      )
    );
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const handleCancelClose = (value: any) => {
    setOpenCancelPopup(false);
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
          setResetCourseType(false);
          setResetExam(true);
          setResetGradeList(true);
          setResetSubject(true);
          setResetBatch(true);
          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setResetTeacher(true);
          setTeacherList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setBatchList([]);
          // setMinCategoryList([]);
          setresetMainCategory(true);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          setResetCourseType(false);
          setResetExam(true);
          setExam("");
          setResetGradeList(true);
          setGrade("");
        },
        () => { }
      )
    );
    dispatch(
      getMainCategoryList(
        "",
        (res: any) => {
          setMinCategoryList(res);
          if (premierId === 2) {
            let tempStatus = res?.filter((item: any) => item?._id === 1);
            setMinCategoryList(tempStatus);
          }
        },
        () => { }
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          setResetGradeList(false);
          setResetSubject(true);
          setResetBatch(true);
          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setResetTeacher(true);
          setTeacherList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setBatchList([]);
          // setMinCategoryList([]);
          setresetMainCategory(true);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setExam(value._id);
    setResetExam(false);
    const postData = {
      examtypeId: value._id,
      coursetypeId: courseType?._id,
      gradeId: grade?._id,
    };
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
          setResetExam(false);
          setResetGradeList(true);
          setResetSubject(true);
          setResetBatch(true);
          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setResetTeacher(true);
          setTeacherList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setBatchList([]);
          // setMinCategoryList([]);
          setresetMainCategory(true);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    var result = selectedStudent.map(function (x: number) {
      return x;
    });

    if (premierId === 1 || premierId === 2) {
      const postData: any = {
        coursetypeId: courseType?._id,
        examtypeId: exam,
        gradeId: grade?._id,
        subjectId: subject,
        lectureData: titleClassScheduleArr,
        teacherId: teacher._id,
        bbbMeetingId: loginID._id,
        studentArrayID: result,
        countryId: country?._id,
        timezoneId: premierId === 2 ? timeZoneNew?.timezoneId : timeZone._id,
        premierVideoId: premierId,
        packageIds: premierId === 1 ? selectedBatch : [batch],
        sessionId: session?._id,
        maincategoryId: category._id,
      };
      if (selectedBatch.length === 0 && batch === "")
        delete postData["packageIds"];
      if (
        postData["sessionId"] === null ||
        postData["sessionId"] === undefined ||
        postData["sessionId"] === ""
      ) {
        delete postData["sessionId"];
      }
      if (
        postData["gradeId"] === null ||
        postData["gradeId"] === undefined ||
        postData["gradeId"] === ""
      ) {
        delete postData["gradeId"];
      }

      if (
        postData["bbbMeetingId"] === null ||
        postData["bbbMeetingId"] === undefined ||
        postData["bbbMeetingId"] === ""
      ) {
        delete postData["bbbMeetingId"];
      }
      if (
        postData["examtypeId"] === null ||
        postData["examtypeId"] === undefined ||
        postData["examtypeId"] === ""
      ) {
        delete postData["examtypeId"];
      }
      if (result.length === 0) delete postData["studentArrayID"];

      dispatch(
        addLivePremier(
          postData,
          (res: any) => {
            resetForm();
          },
          (error: any) => {
            setLiveVideoData(error?.data);
            setErrorMsg(error?.msg);
            setOpenCancelPopup(true);
          }
        )
      );
    } else {
      const postData = {
        coursetypeId: courseType?._id,
        examtypeId: exam,
        subjectId: subject,
        lectureData: lecture,
        teacherId: teacher._id,
        scheduleFrom: fromdate,
        scheduleTo: todate,
        sessionTime: sessionTime,
        bbbMeetingId: loginID._id,
        studentArrayID: result,
        timezoneId: timeZone._id,
        premierVideoId: premierId,
        packageIds: premierId === 1 ? selectedBatch : [batch],
        sessionId: session?._id,
        maincategoryId: category._id,
      };
      if (selectedBatch.length === 0 && batch === "")
        delete postData["packageIds"];
      if (result.length === 0) delete postData["studentArrayID"];

      dispatch(
        addLivePremier(
          postData,
          (res: any) => {
            resetForm();
          },
          (error: any) => {
            setLiveVideoData(error?.data);
            setErrorMsg(error?.msg);
            setOpenCancelPopup(true);
          }
        )
      );
    }
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
    dispatch(
      getGeneralTeacherList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setTeacherList(res);
          setResetSubject(false);
          setResetBatch(true);
          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setBatchList([]);
          // setMinCategoryList([]);
          setresetMainCategory(true);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
    dispatch(
      getGeneralBbbMeetingList(
        {
          subjectId: value._id,
        },
        (res: any) => {
          setLoginIDList(res);
        },
        () => { }
      )
    );

    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      subjectId: value._id,
      gradeId: grade?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getTopicList(
        postData,
        (res: any) => {
          setTopicNameList(res);
        },
        () => { }
      )
    );
  };

  const onChangeMainCategory = (value: {
    _id?: string;
    name?: string;
    sessionIds?: any;
  }) => {
    setCategory(value);
    setresetMainCategory(false);
    dispatch(
      getGeneralPackageList(
        {
          subjectId: subject,
          maincategoryId: value._id,
        },
        (res: any) => {
          setBatchList(res);

          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setresetMainCategory(false);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
  };

  const onSelectBatch = (event: {
    target: { checked: boolean; id: string };
  }) => {
    if (event.target.checked) {
      selectedBatch.push(event.target.id);
      setSelectedBatch(selectedBatch);
    } else {
      selectedBatch.splice(selectedBatch.indexOf(event.target.id), 1);
    }
    dispatch(
      getPackageSessionList(
        {
          packageIds: selectedBatch,
        },
        (res: any) => {
          setSessionsList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSession = (value: { _id: any; name?: string }) => {
    setSession(value);
  };

  const onChangeLecture = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setLecture(value);
    } else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }
  };

  const onChangeloginID = (value: any) => {
    setLoginID(value);
    setResetLoginID(false);
  };

  const onChangeteacher = (value: any) => {
    setTeacher(value);
    setResetTeacher(false);
    dispatch(
      getTimezoneList(
        {},
        (res: any) => {
          setTimeZoneList(res);
        },
        () => { }
      )
    );
  };

  const onChangeBatch = (value: any) => {
    setBatch(value._id);
    setResetBatch(false);
    setLecture("");
    setFromdate("");
    setTodate("");
    setSessionTime("");
    setStudent("");
    setSelectedStudentList([]);
    setResetSessions(true);
    setSessionsList([]);
    setSessionsList([]);
    setResetTimezone(true);
    setTimeZoneList([]);
  };

  const resetForm = () => {
    setResetCourseType(true);
    setTitleClassScheduleArr([initialVal[0]]);
    setCourseType("");
    setResetExam(true);
    setExamList([]);
    setResetSubject(true);
    setSubjectList([]);
    setLecture("");
    setResetLoginID(true);
    setLoginIDList([]);
    setResetTeacher(true);
    setTeacherList([]);
    setFromdate("");
    setTodate("");
    setSessionTime("");
    setStudent("");
    setResetTimezone(true);
    setTimeZoneList([]);
    setResetSessions(true);
    setSessionsList([]);
    setBatchList([]);
    setMinCategoryList([]);
    setresetMainCategory(true);
    setSelectedStudentList([]);
    setSelectedBatch([]);
    setResetBatch(true);
    setStudentList([]);
    setSelectedStudent([]);
    setBatch("");
    setCategory("");
    setResetCountryList(true);
    setCountryList([]);
    setResetTimezone(true);
    setTimeZoneList([]);
    setResetTimezoneNew(true);
    setTimeZoneNewList([]);
    setTopicNameList([]);
    setIsResetForm(true);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const clearData = () => {
    setTitleClassScheduleArr([initialVal[0]]);
    setResetCourseType(true);
    setCourseType("");
    setResetExam(true);
    setExamList([]);
    setResetSubject(true);
    setSubjectList([]);
    setLecture("");
    setResetLoginID(true);
    setLoginIDList([]);
    setResetTeacher(true);
    setTeacherList([]);
    setFromdate("");
    setTodate("");
    setSessionTime("");
    setStudent("");
    setResetTimezone(true);
    setTimeZoneList([]);
    setResetSessions(true);
    setSessionsList([]);
    setBatchList([]);
    setMinCategoryList([]);
    setresetMainCategory(true);
    setSelectedStudentList([]);
    setSelectedBatch([]);
    setResetBatch(true);
    setSelectedStudent([]);
    setBatch("");
    setCategory("");
    setResetCountryList(true);
    setResetTimezone(true);
    setResetTimezoneNew(true);
    document
      .querySelectorAll("input[type=checkbox]")
      .forEach((el: any) => (el.checked = false));
  };

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setIsResetForm(false);
    setResetTimezone(true);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
          setResetCourseType(true);
          setResetExam(true);
          setResetSubject(true);
          setResetBatch(true);
          setLecture("");
          setResetLoginID(true);
          setLoginIDList([]);
          setResetTeacher(true);
          setTeacherList([]);
          setFromdate("");
          setTodate("");
          setSessionTime("");
          setStudent("");
          setSelectedStudentList([]);
          setResetSessions(true);
          setSessionsList([]);
          setBatchList([]);
          // setMinCategoryList([]);
          setresetMainCategory(true);
          setSessionsList([]);
          setResetTimezone(true);
          setTimeZoneList([]);
          setIsResetForm(true);
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => { }
      )
    );
    dispatch(
      getGeneralTimezoneList(
        value?._id,
        (res: any) => {
          setTimeZoneNewList(res);
        },
        () => { }
      )
    );
  };

  const onChangeTimeZoneNew = (value: any) => {
    setTimeZoneNew(value);
    setResetTimezoneNew(false);
  };
  const onChangeStudentName = (value: any) => {
    setStudent(value);
    setStudentList([]);
  };

  const onSearchStudentClick = () => {
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: exam,
      search: student,
      gradeId: grade?._id,
    };
    if (
      postData["examtypeId"] === null ||
      postData["examtypeId"] === undefined ||
      postData["examtypeId"] === ""
    ) {
      delete postData["examtypeId"];
    }
    if (
      postData["gradeId"] === null ||
      postData["gradeId"] === undefined ||
      postData["gradeId"] === ""
    ) {
      delete postData["gradeId"];
    }
    dispatch(
      getStudentList(
        postData,
        (res: any) => {
          selectedStudent?.map((item: any, i: any) =>
            res?.map((data: any, index: any) => {
              if (data?._id == item) {
                data["selected"] = true;
              }
            })
          );
          setStudentList(res);
        },
        () => { }
      )
    );
  };

  const onChangeRegisteredStud = (event: any) => {
    const isInclude = selectedStudentList.indexOf(event.target.value);
    const isIncludeId = selectedStudent.indexOf(event.target.id);
    if (event.target.checked && isInclude === -1 && isIncludeId === -1) {
      setSelectedStudent([...selectedStudent, event.target.id]);
      setSelectedStudentList([...selectedStudentList, event.target.value]);
    } else {
      selectedStudent.splice(selectedStudent.indexOf(event.target.id), 1);
      selectedStudentList.splice(
        selectedStudentList.indexOf(event.target.value),
        1
      );
      setSelectedStudentList([...selectedStudentList]);
      setSelectedStudent([...selectedStudent]);
    }
  };

  const validateArray = () => {
    let result = false;
    for (var i = 0; i < titleClassScheduleArr?.length; i++) {
      if (
        titleClassScheduleArr[i]?.scheduleFrom === "" ||
        titleClassScheduleArr[i]?.scheduleTo === "" ||
        titleClassScheduleArr[i]?.sessionTime === ""
      ) {
        result = true;
      }
    }
    return result;
  };

  function validation() {
    return courseType === "" ||
      exam === "" ||
      subject === "" ||
      (premierId === 3 ? lecture === "" : false) ||
      loginID === "" ||
      teacher === "" ||
      (premierId === 3 ? fromdate === "" : false) ||
      (premierId === 3 ? todate === "" : false) ||
      (premierId === 3 ? sessionTime === "" : false) ||
      validateArray() ||
      timeZone === "" ||
      premierId != 1
      ? selectedStudent?.length === 0
      : false;
  }
  const onChangeValue = (e: any, index: any) => {
    setPremierId(index);
    clearData();
  };

  const onPressAdd = () => {
    setTitleClassScheduleArr([...titleClassScheduleArr, initialVal[0]]);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE LIVE/PREMIER VIDEO"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between mb-3">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Schedule For"} />
            </div>
            <div className="col-10 d-flex">
              {premierIdList.map((c: any, index: any) => {
                return (
                  <div className="col-3">
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          name="type"
                          id={c._id}
                          checked={c._id === premierId ? true : false}
                          onChange={(e) => onChangeValue(e, c._id)}
                        />
                        <span className="ms-2"> {c?.name}</span>
                      </span>
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                // Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2  ">
              <CustomDropdown
                lableName="Select Course Type"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeCourseType(value)
                }
                value={courseType}
                options={courseTypeList}
                Isrequired={true}
                reset={resetCourseType}
              />
            </div>
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2  ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>

          {premierId === 1 || premierId === 2 ? (
            <div className="d-flex Justify-content-between mt-2">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Main Category"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Main Category"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeMainCategory(value)
                  }
                  value={category}
                  options={mainCategoryList}
                  Isrequired={true}
                  reset={resetMainCategory}
                />
              </div>
            </div>
          ) : null}
          {premierId === 1 && batchList.length > 0 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>
              <div className="col-6 mb-2 ">
                <div className=" border rounded p-3 ms-2 me-3">
                  {batchList.length > 0
                    ? batchList.map(
                      (
                        c: { checked: boolean; _id: string; name: string },
                        index: number
                      ) => {
                        return (
                          <div className="ms-3">
                            <input
                              type="checkbox"
                              id={c._id}
                              value={c.name}
                              onChange={onSelectBatch}
                            />
                            <span className="ms-2">{c.name}</span>
                          </div>
                        );
                      }
                    )
                    : null}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          {premierId === 1 && sessionsList.length > 0 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Session"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Session"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSession(value)
                  }
                  value={session}
                  options={sessionsList}
                  reset={resetSessions}
                />
              </div>
            </div>
          ) : null}
          {premierId === 2 ? (
            <div className="d-flex Justify-content-between mt-3">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Batch Name"} />
              </div>

              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Batch Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeBatch(value)
                  }
                  value={batch}
                  options={batchList}
                  Isrequired={true}
                  reset={resetBatch}
                />
              </div>
            </div>
          ) : null}
          {premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Lecture Title"} />
              </div>
              <div className="col-10">
                <InputField
                  placeholder="Enter Lecture Title"
                  value={lecture}
                  onChangeInput={(value: any) => onChangeLecture(value)}
                  maxlength={50}
                  minlength={3}
                  isRequired
                />
              </div>
            </div>
          ) : null}

          {premierId === 2 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Login ID"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Login ID"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeloginID(value)
                  }
                  value={loginID}
                  options={loginIDList}
                  Isrequired={true}
                  reset={resetloginID}
                />
              </div>
            </div>
          )}
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Teachers Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Teacher Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeteacher(value)
                }
                value={teacher}
                options={teacherList}
                Isrequired={true}
                reset={resetteacher}
              />
            </div>
          </div>

          {premierId === 1 || premierId === 2 ? (
            <>
              <span>
                {titleClassScheduleArr?.map((item: any, index: number) => (
                  <ScheduleLive
                    courseType={courseType?._id}
                    exam={exam}
                    subject={subject}
                    grade={grade?._id}
                    topicNameList={topicNameList}
                    data={item}
                    index={index}
                    titleClassScheduleArr={titleClassScheduleArr}
                    setTitleClassScheduleArr={setTitleClassScheduleArr}
                    isResetForm={isResetForm}
                  />
                ))}
              </span>
              <div className=" col-8 d-flex justify-content-end mb-3">
                <Addmorebtn name="Add More" handleClick={onPressAdd} />
              </div>
            </>
          ) : null}

          {premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Class Schedule"} />
              </div>
              <div className="col-6 d-flex ps-2 ">
                <div className="col-4">
                  <div className="fw-bold">From Date</div>
                  <DatePicker
                    value={fromdate}
                    setInputText={(value: string) => setFromdate(value)}
                    mindate={mindate}
                  />
                </div>
                <div className="col-4">
                  <div className="fw-bold">To Date</div>
                  <DatePicker
                    value={todate}
                    setInputText={(value: string) => setTodate(value)}
                    mindate={mindate}
                  />
                </div>
                <div className="col-4">
                  <div className="fw-bold">Session Time</div>
                  <Time
                    value={sessionTime}
                    setInputText={(value: string) => {
                      setSessionTime(value);
                    }}
                  />
                </div>
              </div>
            </div>
          ) : null}
          {premierId === 2 ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Time Zone"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Time Zone"
                    value={timeZoneNew}
                    options={timeZoneNewList}
                    Isrequired={true}
                    reset={resetTimezoneNew}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeTimeZoneNew(value)
                    }
                  />
                </div>
              </div>
            </>
          ) : null}

          {premierId === 2 || premierId === 3 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Schedule For"} />
              </div>
              <div className="col-6 p-2 border rounded">
                <p className="fw-bold ">Registered Student</p>
                <div className="d-flex">
                  <InputField
                    placeholder="Enter student name"
                    value={student}
                    onChangeInput={(value: any) => onChangeStudentName(value)}
                    maxlength={50}
                    minlength={1}
                  />
                  <button
                    type="button"
                    className="btn btn-primary mb-3 ms-2 align-items-center liveSearchBtn mt-2"
                    onClick={(e) => onSearchStudentClick()}
                  >
                    Search
                  </button>
                </div>
                {studentList !== undefined && student.length > 0
                  ? studentList.map((c: any, index) => {
                    return (
                      <div className="ms-3">
                        <input
                          type="checkbox"
                          id={c._id}
                          value={`${c.firstName} ${c.lastName} - ${c.mobile}`}
                          onChange={onChangeRegisteredStud}
                          defaultChecked={c.selected}
                        />
                        <span className="ms-2">
                          {c.firstName} {c.lastName}
                        </span>
                      </div>
                    );
                  })
                  : null}
              </div>
              {selectedStudentList.length > 0 ? (
                <div className="col-auto p-2 border rounded">
                  {selectedStudentList.map((item: any, index: any) => {
                    return <div>{item}</div>;
                  })}
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
        <LiveVideoPopup
          open={openCancelPopup}
          handleClose={handleCancelClose}
          data={liveVideoData}
          errorMsg={errorMsg}
        />
      </div>
    </div>
  );
}
