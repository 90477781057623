import { Link, useLocation } from "react-router-dom";
import PowerSettingsNewIcon from "@mui/icons-material/PowerSettingsNew";
import localImages from "../Constant/localImages";
import { useDispatch, useSelector } from "react-redux";
import { adminLogout } from "../Redux/Actions/AuthAction";
import { AuthReducerModal } from "../Modals";
export default function Sidebar(props: any) {
  const dispatch = useDispatch();
  const currentURLarr = useLocation().pathname.split("/");
  let link = currentURLarr[currentURLarr.length - 1];
  const { userName } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userName: state.Auth.userName,
  }));
  const logout = () => {
    dispatch(
      adminLogout(
        "",
        (res: any) => {},
        () => {}
      )
    );
  };
  return (
    <div className="sidebar">
      <div className="userInfo d-flex align-items-center  justify-content-center pt-3">
        <img src={localImages.logo} className="logo" />
      </div>
      <div className="d-flex justify-content-center border-bottom">
        <div className=" p-2">
          <div className="d-flex justify-content-center ">
            <div className="profilebtn ">
              <img
                className="img-fluid mb-2  "
                src={localImages.profile}
                alt="profile"
              />
            </div>
          </div>
          <h6 className=" fw-bold text-start text-light d-flex align-items-center mt-2 text-uppercase">
            {" "}
            {userName}
          </h6>
        </div>
      </div>
      <ul className="sidebarList">
        <div className="accordion" id="accordionExample">
          {props?.data?.map((val: any, key: any) => {
            if (val?.subNav === undefined) {
              return (
                <li
                  key={key}
                  className={
                    val?.path === link
                      ? "sideListRow active text-uppercase d-flex justify-content-between"
                      : "sideListRow text-uppercase d-flex justify-content-between"
                  }
                >
                  <Link to={val?.path}>{val?.title}</Link>
                </li>
              );
            } else {
              return (
                <div className="accordion-item rounded-0 border-0">
                  <h2 className="accordion-header " id={"heading" + key}>
                    <button
                      className="accordion-button collapsed font-14"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={"#collapse" + key}
                      aria-expanded="false"
                      aria-controls={"collapse" + key}
                    >
                      {val?.title}
                    </button>
                  </h2>
                  <div
                    id={"collapse" + key}
                    className="accordion-collapse collapse"
                    aria-labelledby={"heading" + key}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body p-0">
                      {val?.subNav?.map((data: any, index: any) => {
                        return (
                          <li
                            key={index}
                            id={index}
                            className={
                              data.path === link
                                ? "sideListRow active text-uppercase"
                                : "sideListRow text-uppercase"
                            }
                          >
                            <Link to={data.path}>{data.title}</Link>
                          </li>
                        );
                      })}
                    </div>
                  </div>
                </div>
              );
            }
          })}
        </div>
      </ul>
      <div className="logout text-light border-top d-flex align-self-center">
        <div className="me-3 cursor  " onClick={logout}>
          <PowerSettingsNewIcon />
        </div>
        <h6 className="mb-0 fw-bold">Logout</h6>
      </div>
    </div>
  );
}
