import React from "react";
import { Link, useLocation } from "react-router-dom";
import { Interface } from "readline";

interface props {
  lableName: string;
  isRequired?: boolean;
}

export default function LabelField({ lableName, isRequired }: props) {
  return (
    <label className="label me-2">
      {lableName}
      {isRequired ? <span className="required ms-1 fs-6">*</span> : null}
    </label>
  );
}
