export const showSuccessSnackbar = (message: string) => {
  return (dispatch: (arg0: { type: string; message: any; }) => void) => {
    dispatch({ type: 'SNACKBAR_SUCCESS', message });
  };
};

export const showErrorSnackbar = (message: string) => {
  return (dispatch: (arg0: { type: string; message: any; }) => void) => {
    dispatch({ type: 'SNACKBAR_ERROR', message });
  };
};

export const clearSnackbar = () => {
  return (dispatch: (arg0: { type: string; }) => void) => {
    dispatch({ type: 'SNACKBAR_CLEAR' });
  };
};
