import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import moment from "moment";
import SubmitButton from "../../Components/submitButton";
import { usePagination } from "../../Utils/appConstants";
import { useNavigate } from "react-router-dom";
import { getBbbServerTimings } from "../../Redux/Actions/AdminAction";

export default function BbbServerTimings() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [startDate, setStartDate] = useState(
    moment(new Date()).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(
    moment(startDate).add(1, "days").format("YYYY-MM-DD")
  );
  const [serverTimings, setServerTimings] = useState([]);

  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    getData();
  }, []);

  const getData = () => {
    const postdata = {
      startDate: startDate,
      endDate: endDate,
    };
    dispatch(
      getBbbServerTimings(
        postdata,
        (res: any) => {
          setServerTimings(res);
        },
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    getData();
  };

  const renderListMode = () => {
    return (
      <>
        <div className="p-2 table-responsive">
          <div className="col-md-12 p-2 border-end "></div>
          <table className="table table-bordered text-center ">
            <thead className="table-primary ">
              <tr>
                <th className="p-3 ">Timings</th>
                <th className="p-3 ">Server Free</th>
                <th className="p-3 ">Server Busy</th>
              </tr>
            </thead>

            <tbody>
              {serverTimings?.map((value: any, index) => {
                return (
                  <>
                    <tr></tr>
                  </>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"BBB SERVER TIMINGS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className=" d-flex flex-wrap  mt-3">
            <div className="col-md-3 d-flex mb-2">
              <LabelField lableName={"Start Date"} />
              <div className="col-md-6">
                <DatePicker
                  value={moment(startDate).format("YYYY-MM-DD")}
                  setInputText={(value: string) => setStartDate(value)}
                />
              </div>
            </div>
            <div className="col-md-3 d-flex mb-2 align-items-center">
              <LabelField lableName={"End  Date"} />
              <div className="col-md-8 ">
                <DatePicker
                  value={endDate}
                  setInputText={(value: string) => setEndDate(value)}
                />
              </div>
            </div>

            <div className=" mb-2  mt-2">
              <SubmitButton name={"Apply Filter"} />
            </div>
          </div>
        </form>

        {renderListMode()}
      </div>
    </div>
  );
}
