import React from "react";
import { useEffect, useState, useRef } from "react";
import { useDispatch } from "react-redux";
import { dateFormat, today } from "../../Utils/appConstants";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { getRole } from "../../Redux/Actions/SuperAdminAction";
import {
    getCountryList,
    getDemoRequestList,
} from "../../Redux/Actions/AdminAction";
import { DownloadTableExcel } from "react-export-table-to-excel";
import moment from "moment";
import PaginationTable from "../../Components/paginationTable";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import SubmitButton from "../../Components/submitButton";
import Dropdown from "../../Components/dropdown";
import LeadDemoAlignPopup from "../../Components/leadDemoAlignPopup";

export default function MarketingLandingPage() {

    const dispatch: Function = useDispatch();
    const [startDate, setStartDate] = useState(today);
    const [endDate, setEndDate] = useState(today);
    const [userRoles, setUserRoles] = useState([]);
    const [openPopup, setOpenPopup] = useState(false);
    const [leads, setLeads] = useState([]);
    const [country, setCountry] = useState<any>("");
    const [countryList, setCountryList] = useState([]);
    // const tableRef = useRef(null);
    const [selectedData, setSelectedData] = useState("");
    const [showEditSchedule, setShowEditSchedule] = useState(false);

    useEffect(() => {
        dispatch(
            getRole(
                "",
                (res: any) => {
                    setUserRoles(res);
                },
                () => { }
            )
        );
        dispatch(
            getCountryList(
                "",
                (res: any) => {
                    setCountryList(res);
                },
                () => { }
            )
        );
        getDemoReq();
    }, []);

    const getDemoReq = () => {
        const postData: any = {
            startDate: startDate,
            endDate: endDate,
            countryId: country?._id,
            sourceId: 4,
            // category: userCategory?._id,
        };
        if (country) {
            postData["countryId"] = country?._id;
        }
        // if (userCategory) {
        //     postData["category"] = userCategory?._id;
        // }

        dispatch(
            getDemoRequestList(
                postData,
                (res: any) => {
                    setLeads(res);
                },
                () => { }
            )
        );
    };

    const handleEdit = (value: any) => {
        setSelectedData(value);
        setOpenPopup(true);
        setShowEditSchedule(true);
    };
    const handleClose = (value: any) => {
        setOpenPopup(false);
    };

    const handleDelete = (value: any) => { };
    const onChangeStartDate = (value: any) => {
        if (
            moment(value).format(dateFormat.yyyymmddA) >
            moment(endDate).format(dateFormat.yyyymmddA)
        ) {
            dispatch(showErrorSnackbar("Start date should be lass than End date"));
        } else {
            setStartDate(value);
        }
    };

    const onChangeEndDate = (value: any) => {
        if (
            moment(value).format(dateFormat.yyyymmddA) <
            moment(startDate).format(dateFormat.yyyymmddA)
        ) {
            dispatch(showErrorSnackbar("End date should be greater than Start date"));
        } else {
            setEndDate(value);
        }
    };

    const handleSubmit = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        getDemoReq();
    };

    const onChangeCountry = (value: any) => {
        setCountry(value);
    };

    const renderListMode = () => {
        return (
            <div className="mx-3">
                <PaginationTable
                    tableData={leads ? leads : []}
                    pagename={"PaidAdsMarketingLandingPage"}
                    tableHead={[
                        "S.N.",
                        "STUDENT NAME",
                        "SUBJECT",
                        "COUNTRY",
                        "CREATED DATE",
                        "DEMO STATUS",
                    ]}
                    edit={true}
                    handleEdit={(value: any) => handleEdit(value)}
                    handleDelete={(value: any) => handleDelete(value)}
                />
            </div>
        );
    };

    return (
        <div className="contentBox">
            <PageTitle name={"MARKETING LANDING PAGE"} />
            <div className="formDiv">
                <form className="text-center" onSubmit={handleSubmit}>
                    <div className="px-2 mx-0 my-4 row d-flex">

                        <div className="col-md-2 d-flex mb-2 align-items-center">
                            <LabelField lableName={"Start Date:"} />
                            <DatePicker
                                value={startDate}
                                setInputText={(value: string) => onChangeStartDate(value)}
                                maxdate={today}
                            />
                        </div>
                        <div className=" col-md-2 d-flex mb-2 align-items-center">
                            <LabelField lableName={"End Date:"} />
                            <DatePicker
                                value={endDate}
                                setInputText={(value: string) => onChangeEndDate(value)}
                                maxdate={today}
                            />
                        </div>

                        <div className="col-md-3 d-flex mb-2 align-items-center">
                            <LabelField lableName={"Country:"} />
                            <Dropdown
                                labelName="Select Country"
                                setInputText={(value: any) => onChangeCountry(value)}
                                value={country}
                                options={countryList}
                            />
                        </div>

                        <div className="d-flex col-md-3 ms-3  ">
                            <SubmitButton name={"Search"} />
                        </div>
                    </div>
                    {renderListMode()}
                </form>
                {/* {renderListMode()} */}
            </div>
            {/* <LeadDemoAlignPopup
            open={openPopup}
            handleClose={handleClose}
            showEditSchedule={showEditSchedule}
            // showDemoSchedule={showDemoSchedule}
            data={selectedData}
            pageName={"LandingPageLeads"}
            getDemoReq={getDemoReq}
          /> */}
        </div>
    );
}