import React, { useState, useRef, useEffect } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import SubmitButton from "./submitButton";
import {
  ReScheduleDemo,
  deleteRescheduleReq,
} from "../Redux/Actions/AdminAction";
import { useNavigate } from "react-router-dom";
import CustomDropdown from "./customDropdown";
import LabelField from "./labelField";
import { cancelReasonList } from "../Utils/dataConstant";
import { imgPreview } from "../Screens/Affiliate/imgPreview";
import ReactCrop, {
  type Crop,
  centerCrop,
  makeAspectCrop,
  PixelCrop,
  convertToPixelCrop,
} from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { useDebounceEffect } from "../Hooks/useDebounceEffect";
import ImgCropPopup from "./imgCropPopup";
import localImages from "../Constant/localImages";
import html2canvas from 'html2canvas';
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList?: any;
}
function centerAspectCrop(
  mediaWidth: number,
  mediaHeight: number,
  aspect: number
) {
  return centerCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      mediaWidth,
      mediaHeight
    ),
    mediaWidth,
    mediaHeight
  );
}

export default function CreateBannerPopup(props: props) {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();
  const [sname, setName] = useState("");
  const [cropprofileImg, setCropProfileImg] = useState<any>("");
  const canvasRef = useRef(null);
  const [profileImg, setProfileImg] = useState<any>("");
  const [crop, setCrop] = useState<Crop>();
  const [openImgcropPopup, setopenImgcropPopup] = useState(false);
  const [completedCrop, setCompletedCrop] = useState<PixelCrop>();
  const { open, handleClose, data, getList } = props;
  const [scale, setScale] = useState(1);
  const [rotate, setRotate] = useState(0);
  const [aspect, setAspect] = useState<number | undefined>(4 / 5);
  const imgRef = useRef<HTMLImageElement>(null);
  const hiddenFileInput: any = useRef(null);
  const previewCanvasRef = useRef<HTMLCanvasElement>(null);
  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  const handleClick = (event: any) => {
    hiddenFileInput.current.click();
  };
  const handleChange = (event: any) => {
    const reader = new FileReader();
    setCrop(undefined);
    const file = event.target.files[0];
    reader.onloadend = () => {
      setProfileImg(reader.result);
    };
    reader.readAsDataURL(file);
    setopenImgcropPopup(true);
  };

  function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
    if (aspect) {
      const { width, height } = e.currentTarget;
      setCrop(centerAspectCrop(width, height, aspect));
    }
  }
  const handleDownload = () => {
    // const canvas: any = canvasRef.current;
    // console.log("canvas", canvas);  
    // const link = document.createElement("a");
    // link.download = "canvas-image.png";
    // link.href = canvas.toDataURL();
    // link.click();
    if (canvasRef.current) {
      html2canvas(canvasRef.current).then((canvas: { toDataURL: (arg0: string) => string; }) => {
        const link = document.createElement('a');
        link.href = canvas.toDataURL('image/png');
        link.download = 'combined-image.png';
        link.click();
      });
    }
  };
  // useEffect(() => {
  //   drawCanvas();
  // }, [cropprofileImg]);
  console.log("canvasRef", canvasRef);
  const drawCanvas = () => {
    const canvas: any = canvasRef.current;
    const ctx = canvas?.getContext('2d');
    const img = new Image(); 

    // Load the main invitation card image
    img.onload = () => {
      canvas.width = img.width;
      canvas.height = img.height;
      ctx.drawImage(img, 0, 0);

      // Load the profile image
      if (cropprofileImg) {
        const profileImgElement = new Image();
        profileImgElement.onload = () => {
          ctx.drawImage(profileImgElement, 45, 840, 170, 210);

          // Draw text
          ctx.font = "bold 26px Poppins";
          ctx.textAlign = "center";
          ctx.fillStyle = "black";
          ctx.fillText(sname, 200, 1000); // Adjust the position as needed
        };
        profileImgElement.setAttribute("crossorigin", "anonymous");
        profileImgElement.src = cropprofileImg; // Use profileImg as the source
      } else {
        // Draw text if no profile image is selected
        ctx.font = "bold 26px Poppins";
        ctx.textAlign = "center";
        ctx.fillStyle = "black";
        ctx.fillText(sname, 200, 1000); // Adjust the position as needed
      }
    };
     
      // img.setAttribute("crossorigin", "anonymous");
    // img.src = localImages.bannerImage;
    img.src = data;

    console.log("img12", img);
  };
  useDebounceEffect(
    async () => {
      if (
        completedCrop?.width &&
        completedCrop?.height &&
        imgRef.current &&
        previewCanvasRef.current
      ) {
        // We use canvasPreview as it's much faster than imgPreview.
        imgPreview(
          imgRef.current,
          // previewCanvasRef.current,
          completedCrop,
          scale,
          rotate
        );
        console.log(
          "image",
          imgPreview(
            imgRef.current,
            // previewCanvasRef.current,
            completedCrop,
            scale,
            rotate
          ).then((res: any) => {
            setCropProfileImg(res);
          })
        );
      }
    },
    100,
    [completedCrop, scale, rotate]
  );
  console.log("completedCrop", completedCrop);
  console.log("cropprofileImg", cropprofileImg);
  console.log("data", data);


  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      // onClose={() => {
      //   handleClose();
      // }}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="bannerPopup ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Create Banner</h5>

            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>

          <div className="row d-flex justify-content-between mt-2">
            <div className="col-md-6"  ref={canvasRef}>
              <img src={data} alt="cardImg" className="img-fluid" />
              {cropprofileImg ? (
                <div className="">
                  <img
                    src={cropprofileImg}
                    alt="cardImg"
                    className="invitationCardProfileImg"
                  />
                </div>
              ) : null}
              {/* <div>
                <canvas ref={canvasRef} ></canvas>
              </div> */}
            </div>
            <div className="col-md-6">
              <div>
                <button
                  type="button"
                  className="btn btn-success w-100"
                  onClick={handleClick}
                >
                  <UploadIcon /> Upload Your QR Code
                </button>
                <input
                  type="file"
                  onChange={handleChange}
                  ref={hiddenFileInput}
                  style={{ display: "none" }} // Make the file input element invisible
                />
              </div>
              <div className="mt-3 text-center">
                <button
                  type="button"
                  className="btn btn-danger"
                  onClick={handleDownload}
                >
                  <DownloadIcon /> Download Banner
                </button>
              </div>
              {/* <div>
                <ReactCrop crop={crop} onChange={(_, percentCrop) => setCrop(percentCrop)} minHeight={100} onComplete={(c) => setCompletedCrop(c)} locked={true}>
                  {profileImg ? <img src={profileImg} alt='cardImg' className='' style={{ transform: `scale(${scale}) rotate(${rotate}deg)` }}
                    onLoad={onImageLoad} ref={imgRef} /> : null}
                </ReactCrop>
              </div> */}
              {!!completedCrop && (
                <div>
                  <canvas
                    ref={previewCanvasRef}
                    style={{
                      border: "1px solid black",
                      objectFit: "contain",
                      width: completedCrop.width,
                      height: completedCrop.height,
                      display: "none",
                    }}
                  />
                </div>
              )}
            </div>
            <ImgCropPopup
              open={openImgcropPopup}
              handleClose={() => setopenImgcropPopup(false)}
              crop={crop}
              setCrop={setCrop}
              setCompletedCrop={setCompletedCrop}
              profileImg={profileImg}
              scale={scale}
              rotate={rotate}
              onImageLoad={onImageLoad}
              imgRef={imgRef}
            />
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
