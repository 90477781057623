import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";

export default function TutorDataDetails() {
  const dispatch = useDispatch();
  const location = useLocation();
  const { details } = location.state;

  useEffect(() => {}, []);

  const renderListMode = () => {
    return (
      <div className="demoCard mt-5">
        <div className="row mt-2">
          <div className="col-md-7">
            {details?.name ? (
              <div className="d-flex">
                <div className="font16 mb-2 fw-bold">Full Name:</div>
                <div className="font16 mb-2 ms-2">{details?.name}</div>
              </div>
            ) : null}

            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Personal Mobile number:</div>
              <div className="font16 mb-2 ms-2">{details?.mobile}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.aadhaar_card_number}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Personal Mail ID:</div>
              <div className="font16 mb-2 ms-2">{details?.email}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Gender:</div>
              <div className="font16 mb-2 ms-2"> {details?.gender}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Date of Birth:</div>
              <div className="font16 mb-2 ms-2">
                {moment(details?.date_of_birth).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Blood Group:</div>
              <div className="font16 mb-2 ms-2">{details?.blood_group}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Father Name:</div>
              <div className="font16 mb-2 ms-2">{details?.father_name}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Marital Status:</div>
              <div className="font16 mb-2 ms-2">{details?.marital_status}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Place of birth:</div>
              <div className="font16 mb-2 ms-2">{details?.place_of_birth}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Aadhaar card number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.aadhaar_card_number}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Pan card number:</div>
              <div className="font16 mb-2 ms-2">{details?.pan_card_number}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Qualification:</div>
              <div className="font16 mb-2 ms-2">{details?.qualification}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">
                College/University Name:
              </div>
              <div className="font16 mb-2 ms-2">
                {details?.college_university_name}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Date of Joining:</div>
              <div className="font16 mb-2 ms-2">
                {moment(details?.date_of_joining).format("DD-MM-YYYY")}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Grade:</div>
              <div className="font16 mb-2 ms-2">{details?.grade}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Department:</div>
              <div className="font16 mb-2 ms-2">{details?.department}</div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Permanent Address:</div>
              <div className="font16 mb-2 ms-2">
                {details?.permanent_address}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Emergency Contact Name:</div>
              <div className="font16 mb-2 ms-2">
                {details?.emergency_contact_name}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">
                Emergency Contact Number:
              </div>
              <div className="font16 mb-2 ms-2">
                {details?.emergency_contact_number}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">
                Emergency Contact Relationship:
              </div>
              <div className="font16 mb-2 ms-2">
                {details?.emergency_contact_number}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">Bank account number:</div>
              <div className="font16 mb-2 ms-2">
                {details?.bank_account_number}
              </div>
            </div>
            <div className="d-flex">
              <div className="font16 mb-2 fw-bold">IFSC Code:</div>
              <div className="font16 mb-2 ms-2">{details?.ifsc_code}</div>
            </div>
          </div>
        </div>
      </div>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"TUTOR DETAILS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">{renderListMode()}</div>
    </div>
  );
}
