import { Bar, getElementsAtEvent } from "react-chartjs-2";
import { useRef } from "react";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
interface props {
  label: any;
  value: any;
}
export default function BarChartGraphsOverallRatings({ label, value }: props) {
  const options: any = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
    },
  };
  const graphData = {
    labels: label,
    datasets: [
      {
        label: "",
        data: value,
        backgroundColor: ["orange"],
        borderColor: ["orange"],
        borderWidth: 0.5,
        barPercentage: 0.3,
      },
    ],
  };
  const chartRef: any = useRef();

  const onClick = (event: any) => {
    if (getElementsAtEvent(chartRef.current, event).length > 0) {
      const datasetIndexNum = getElementsAtEvent(chartRef.current, event)[0]
        .datasetIndex;
      const dataPoint = getElementsAtEvent(chartRef.current, event)[0].index;
    }
  };

  return (
    <Bar options={options} data={graphData} onClick={onClick} ref={chartRef} />
  );
}
