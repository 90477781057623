import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { getPreferTeacherList } from "../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DemoSchedulePopup from "./demoSchedulePopup";
import { demoTimeList } from "../Utils/dataConstant";

interface props {
  open: boolean;
  handleClose: Function;
  getDemoReq: Function;
  data?: any;
  showEditSchedule?: any;
  showDemoSchedule?: any;
  pageName?: any;
}

export default function DemoTeacherPopup(props: props) {
  const dispatch = useDispatch();
  const [date, setDate] = useState<any>("");
  const [time, setTime] = useState<any>("");
  const [rating, setRating] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);
  const [openSchedulePopup, setOpenSchedulePopup] = useState(false);
  const [clickOther, setClickOther] = useState(false);

  const { open, handleClose, data, pageName, getDemoReq } = props;

  const labels: { [index: string]: string } = {
    1: "Bad",
    2: "Below Average",
    3: "Average",
    4: "Good",
    5: "Excellent",
  };

  useEffect(() => {
    setDate(data?.date);
    setRating({ id: props?.data?.rating, value: labels[props?.data?.rating] });
    let tempTime = data?.time.split(":");
    let filteredTime = demoTimeList?.find(
      (item: any) => item?._id === parseInt(tempTime[0])
    );
    setTime(filteredTime);
    const postData = {
      date: data?.date,
      time: data?.time,
      timezoneId: data?.timezoneId,
      countryId: data?.countryId,
      subjectId: data?.subjectId,
    };
    dispatch(
      getPreferTeacherList(
        postData,
        (res: any) => {
          setTeacherList(res);
        },
        () => {}
      )
    );
  }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
    getDemoReq();
    setClickOther(false);
  }

  const handleScheduleClose = (value: any) => {
    setOpenSchedulePopup(false);
  };

  const handleSaverClose = (value: any) => {
    setDate(value.startDate);
    setTime(value.demoTime);
    setOpenSchedulePopup(false);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        // onClose={() => {
        //   handleClose();
        // }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="demoTeacherPopup ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Demo Booking</h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCard mt-5">
              <div className="row mt-2">
                <div className="col-md-7">
                  <h4 className="fw-bold">{data?.gradedesc}</h4>
                  {data?.parentName ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Parent Name:</div>
                      <div className="font16 mb-2 ms-2">{data?.parentName}</div>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Student Name:</div>
                    <div className="font16 mb-2 ms-2">
                      {" "}
                      {`${data?.firstName} ${data?.lastName}`}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Phone:</div>
                    <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Email:</div>
                    <div className="font16 mb-2 ms-2"> {data?.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Course Type:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.coursetypeName}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Country:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.countryName} ({data?.timezone})
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Subject Name:</div>
                    <div className="font16 mb-2 ms-2">{data?.subjectName}</div>
                  </div>

                  {props?.showDemoSchedule === true ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Teacher Name:</div>
                      <div className="font16 mb-2 ms-2">
                        {data?.teacherName}
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </Fade>
      </Modal>
      <DemoSchedulePopup
        open={openSchedulePopup}
        handleClose={handleScheduleClose}
        data={data}
        getDateTime={(value: any) => handleSaverClose(value)}
        pageName={pageName}
      />
    </>
  );
}
