import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import InputField from '../../Atoms/InputField';
import { getLiveStatusUpdate } from '../../Redux/Actions/SuperAdminAction';
import { useDispatch, useSelector } from 'react-redux';
import { getLiveScheduleList } from '../../Redux/Actions/TeacherActions';
import { AuthReducerModal } from '../../Modals';
import { dateFormat, timeFormat } from '../../Utils/appConstants';
import moment from 'moment';

export default function Dashboard() {
  const dispatch = useDispatch();
  const [liveLectureList, setLiveLectureList] = useState<any>([]);

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId
  }));

  useEffect(() => {
    getData()
  }, [])

  const getData = () => {
    dispatch(
      getLiveScheduleList(
        userId,
        {},
        (res: any) => {
          setLiveLectureList(res)
        },
        () => { }
      )
    );
  }

  const handleSubmit = (e: any, titleId: any, statusCode: any) => {
    e.preventDefault()
    dispatch(
      getLiveStatusUpdate(
        {
          titleId: titleId,
          statusCode: statusCode
        },
        (res: any) => {
          getData()
        },
        () => { }
      )
    );
  }



  return (
    <div className="contentBox">
      <PageTitle name={"LIVE SCHEDULE"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <p className='fw-bold font24'>Today's Live Schedule</p>
        <div className=''>
          {
            liveLectureList.map((data: any) => {
              return <div className="d-flex justify-content-between mb-2">
                <div className='col-6 border rounded p-3 '>
                  <p className='fontcolor fw-bold'>BBB Live</p>
                  <p className=' mb-0'>{data?.lectureTitle}</p>
                  <p className='text-secondary'>Live Date & Time: {moment(data?.sessionTime, "HH:mm:ss").format(timeFormat.LT)}; {moment(data?.scheduleFrom).format(dateFormat.yyyymmdd)}</p>
                  <div className='row d-flex justify-content-between'>
                    <div className='col-6 d-flex'>
                      <InputField
                        placeholder="Enter meeting ID"
                        value={data?.bbbMeeting?.mailId}
                        onChangeInput={(value: any) => { }}
                        disabled
                      />
                    </div>
                    <div className='col-6 d-flex'>
                      <InputField
                        placeholder=""
                        value={data?.bbbMeeting?.attentivePass}
                        onChangeInput={(value: any) => { }}
                        disabled
                      />
                    </div>
                  </div>
                  <div className=' d-flex justify-content-end'>
                    <div className='  '>
                      <button
                        type="submit"
                        className={data.status !== 1 ? 'btn btn-secondary rounded-pill me-4' : "btn btn-primary rounded-pill me-4"}
                        onClick={(e: any) => handleSubmit(e, data?.titleId, 2)}
                        disabled={data.status !== 1}
                      >Start Live </button>
                    </div>
                    <div className=' '>
                      <button
                        type="submit"
                        className={data.status !== 2 ? 'btn btn-secondary rounded-pill me-4' : "btn btn-primary rounded-pill me-4"}
                        onClick={(e: any) => handleSubmit(e, data?.titleId, 9)}
                        disabled={data.status !== 2}
                      >End Live </button>
                    </div>

                  </div>
                </div>
              </div>

            })
          }



        </div>
      </div>
    </div>
  )
}

