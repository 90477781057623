interface props {
  placeholder: string;
  value?: string | number;
  maxlength?: number;
  minlength?: number;
  onChangeInput: Function;
  isRequired?: boolean;
  disabled?: boolean;
  onFocus?: Function;
}

export default function InputField({
  placeholder,
  value,
  maxlength,
  minlength,
  onChangeInput,
  isRequired,
  disabled,
  onFocus,
}: props) {
  return (
    <div className="d-flex mb-2 align-items-center w-100">
      <input
        type="text"
        placeholder={placeholder}
        value={value}
        className="form-control inputField"
        onChange={(value) => onChangeInput(value.target.value)}
        maxLength={maxlength}
        minLength={minlength}
        disabled={disabled}
        onFocus={(e) => onFocus && onFocus(e)}
      />
      {isRequired ? <span className="required ms-2 fs-6">*</span> : null}
    </div>
  );
}
