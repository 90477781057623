import { useEffect, useState } from "react";
import TextEditor from "./TextEditor";
import { optionTypeImage, optionTypeText } from "../Utils/dataConstant";
import ImagePickerAndViewer from "./imagePickerAndViewer";
import { showErrorSnackbar } from "../Redux/Actions/snackbarAction";
import { validateIsFileImageTypeErrMsg } from "../Utils/errorMsg";
import { image_size_150, validateIsFileImageType } from "../Utils/validators";
import { useDispatch } from "react-redux";
import { uploadQuestionMedia } from "../Redux/Actions/AdminAction";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";

interface props {
  setOptionData?: any;
  OnChange?: any;
  index: number;
  option: any;
  setCorrectOption: any;
}
export default function Options({
  option,
  setOptionData,
  index,
  setCorrectOption,
}: props) {
  const dispatch: Function = useDispatch();
  const [isCorrect, setIsCorrect] = useState(false);
  const [desctiptionType, setDesctiptionType] = useState("");
  const [description, setDesctiption] = useState("");
  const [optionImage, setOptionImage] = useState<any>("");
  const [options, setOptions] = useState<any>("");
  const [showOptionImage, setShowOptionImage] = useState(false);

  useEffect(() => {
    if (option) {
      setOptions(option);
      const { descType, description, isCorrect } = option;
      setDesctiption(description);
      setOptionImage(description);
      setIsCorrect(isCorrect ? isCorrect : false);
      setDesctiptionType(descType);
    }
  }, []);

  const showMenu = (obj: any) => {
    if (obj === "text") {
      setOptionImage("");
      setDesctiptionType("text");
      setOptions(optionTypeText);
    } else {
      setDesctiptionType("image");
      setShowOptionImage(true);
      setOptions(optionTypeImage);
    }
  };

  const setOptionsValue = (val: any, correctans: boolean) => {
    const PostData = {
      descType: desctiptionType,
      description: val,
      isCorrect: correctans,
      index: index,
    };
    setOptionData(PostData);
    return PostData;
  };

  const handleOptionImageChange = (e: {
    preventDefault: () => void;
    target: { files: any[] };
  }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_150) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setOptionImage(reader.result);
      };
      reader.readAsDataURL(file);

      dispatch(
        uploadQuestionMedia(
          file,
          (res: { filePath: string }) => {
            setOptionsValue(res?.filePath, isCorrect);
          },
          () => {}
        )
      );
    }
  };

  const setCorrectValue = (
    val: boolean | ((prevState: boolean) => boolean)
  ) => {
    const PostData = {
      isCorrect: val,
      index: index,
    };
    setCorrectOption(PostData);
    return PostData;
  };

  const onChangeRadio = (val: boolean | ((prevState: boolean) => boolean)) => {
    setIsCorrect(val);
    setCorrectValue(val);
  };

  const onChangeText = (val: string) => {
    setDesctiption(val);
    setOptionsValue(val, isCorrect);
  };

  const handleCloseOption = (type: string) => {
    if (type == "img") {
      setShowOptionImage(false);
    }
  };
  return (
    <div>
      <div className="p-3 bg-dark d-flex justify-content-between">
        <h5 className="fw-bold text-light">Option {index + 1}</h5>
        <div className="bg-light d-flex align-items-center p-2">
          <span className="me-3">Correct Option</span>
          <input
            type="radio"
            name="correct"
            checked={isCorrect}
            onChange={(value) => onChangeRadio(value.target.checked)}
          />
        </div>
        <div className=" d-flex">
          <div
            className="cursor addInputbtn ms-2 p-1"
            onClick={() => showMenu("text")}
          >
            + Text
          </div>
          <div
            className="cursor addInputbtn ms-2 p-1"
            onClick={() => showMenu("img")}
          >
            + Image
          </div>
        </div>
      </div>

      {options?.descType === "text" ? (
        <div className="editor_content mb-2">
          <TextEditor
            value={description}
            onChange={(value: string) => onChangeText(value)}
            height="vh100"
            width="vw100"
          />
        </div>
      ) : options?.descType === "img" && showOptionImage ? (
        <div className="position-relative col-12" key={index}>
          <span className="position-absolute justify-content-end p-2 top-0 end-0  ">
            <HighlightOffIcon
              className="cursor"
              color="error"
              fontSize="small"
              onClick={() => handleCloseOption("img")}
            />
          </span>
          <div className="mb-2">
            <ImagePickerAndViewer
              lableName={"Option Image"}
              value={optionImage}
              handleChange={(e: any) => handleOptionImageChange(e)}
              // htmlFor={`mcq-photo-upload ${index}`}
              isRequired={true}
            />
          </div>
        </div>
      ) : null}
    </div>
  );
}
