// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.calender th{
    color: var(--darkgray);
    background-color: var(--white);
    padding: 5px 12px;
    font-size: 18px;
}
.calender th a{
    color: var(--darkgray);
    text-decoration: none;
}`, "",{"version":3,"sources":["webpack://./src/Assets/css/calender.css"],"names":[],"mappings":"AAAA;IACI,sBAAsB;IACtB,8BAA8B;IAC9B,iBAAiB;IACjB,eAAe;AACnB;AACA;IACI,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".calender th{\n    color: var(--darkgray);\n    background-color: var(--white);\n    padding: 5px 12px;\n    font-size: 18px;\n}\n.calender th a{\n    color: var(--darkgray);\n    text-decoration: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
