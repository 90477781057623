import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff"; 
import moment from "moment";
interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList?: Function;
}

export default function AffilateLeadDeatilsPopup(props: props) {
  const dispatch = useDispatch(); 

  const { open, handleClose, data, getList } = props;
  useEffect(() => { }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  
  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        // onClose={() => {
        //   handleClose();
        // }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="modeldivChat p-md-4 py-md-4 ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Lead Details</h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCard mt-0">
              <div className="row mt-2">
                <div className="col-md-12">
                  <h5 className="fw-bold">{data?.title}</h5>
                  {data?.parentName ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Parent Name:</div>
                      <div className="font16 mb-2 ms-2">{data?.parentName}</div>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Student Name:</div>
                    <div className="font16 mb-2 ms-2">
                      {" "}
                      {`${data?.firstName} ${data?.lastName}`}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Phone:</div>
                    <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Email:</div>
                    <div className="font16 mb-2 ms-2"> {data?.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Grade:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.gradedesc}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Country:</div>
                    <div className="font16 mb-2 ms-2">{data?.countryName}</div>
                  </div>
                 
                  <div className="d-flex mt-2 ">
                    <div className="font16 mb-2 fw-bold">
                      Registration Date & Time:
                    </div>
                    <div className="font16 mb-2 ms-2">{moment( data?.createdAt ).format('MMMM Do YYYY, h:mm:ss a')}</div>
                    
                  </div>
                </div>
              </div>
            </div>
           
       
          </div>
        </Fade>
      </Modal>
    </>
  );
}
