import { combineReducers } from 'redux';
import {
  StartExamReducer,
  ExamSelectReducer,
  SetOptionValueFalseReducer
} from './examReducers';
import Auth from './authReducer';
import uiReducer from './uiReducer';
import HomeReducer from './homeReducer';
import { RESET_STORE } from '../Types/authTypes';
import { AuthReducerModal, ExamSelectModal, HomeReducerModal, SetOptionValueFalseModal, SnakbarReducerModal, StartExamModal } from '../../Modals';

export interface AppState {
  Auth: AuthReducerModal
  HomeReducer: HomeReducerModal
  StartExamReducer: StartExamModal
  ExamSelectReducer: ExamSelectModal
  SetOptionValueFalseReducer: SetOptionValueFalseModal
  uiReducer: SnakbarReducerModal
}

const appReducer = combineReducers<AppState>({
  Auth,
  StartExamReducer,
  ExamSelectReducer,
  SetOptionValueFalseReducer,
  uiReducer,
  HomeReducer,
});

interface action {
  type: string
}
const rootReducer = (state: any, action: any) => {
  if (action.type === RESET_STORE) {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
