import { useEffect, useState, useRef } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import DatePicker from "../../Components/datePicker";
import PaginationView from "../../Components/paginationView";
import PaginationTable from "../../Components/paginationTable";
import {
  getTableDataSlots,
  today,
  usePagination,
} from "../../Utils/appConstants"; 
import SubmitButton from "../../Components/searchButton";
 
import { useDispatch } from "react-redux";
 
import CustomDropdown from "../../Components/customDropdown";

export default function UpcomingScheduleClass() {
  const dispatch: Function = useDispatch();
  const tableRef = useRef(null);
  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [title, setTitle] = useState<any>("");
  const [titleList, setTitleList] = useState<any>([]);
  const [teacher, setTeacher] = useState<any>("");
  const [teacherList, setTeacherList] = useState<any>([]);

 
  const [toggle, setToggle] = useState("1:1 Live");

  useEffect(() => {
   
  }, []);

 
  const tab = [
    {
      _id: "1",
      name: "1:1 Live",
    },
    {
      _id: "2",
      name: "Demo Lecture",
    },
  ];
 
 
  const onChangeStartDate = (value: any) => {
    setStartDate(value);
  };
 

  const onChangeEndDate = (value: any) => {
    setEndDate(value);
  };
 
  const handleChangetab = (val:any) => {
    setToggle(val);
  };
  const onChangeTitle = (value: any) => {
    setTitle(value);
  };
  const onChangeTeacher= (value: any) => {
    setTeacher(value);
  };
 const data=[
  {
    title:"",
    teacher:"Chetan Dhumak",
    status:"Under Review",
    actualClassTime:"May 21,2024 ",
    classStartat:"Regular Class",
  }
 ]

 

  const renderListMode = () => {
    return (
      < div className="p-2 table-responsive ">
      <table className="table table-bordered text-center ">
  <thead className="table-primary ">
    <tr  >
      <th className="p-3 " >Title</th>
      <th  className="p-3 " >Teacher Name</th>
      <th  className="p-3 "  >Status</th>
      <th  className="p-3 "  >Next Class On</th>
      <th  className="p-3 " >Type</th>
      
    </tr>
  </thead>
  <tbody>
  { data?.map((c,index)=>{
    return( 
    <tr >
       <td>{index+1}</td>
       <td>{c?.teacher}</td>
       <td> {c?.status}</td>
       <td> {c?.actualClassTime}</td>
       <td> {c?.classStartat}</td>
     
    </tr>)})}
    
  </tbody>
</table>

    </div> 
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    
    
  };

  return (
    <div className="contentBox">
      <PageTitle name={"UPCOMING CLASS"} />
      <div className="formDiv">
        <form className="text-center " onSubmit={handleSubmit}>
          <div className="  mx-0 mt-4 row d-flex flex-wrap Justify-content-between ">
            <div className="col-md-4 d-flex mb-2 align-items-center  ">
              <div className="col-md-2">
              <LabelField lableName={"Start Date:"} /></div>
              <div className="col-md-10">
              <DatePicker
                value={startDate}
                setInputText={(value: string) => onChangeStartDate(value)}
                maxdate={today}
              /></div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
            <div className="col-md-2">
              <LabelField lableName={"End Date:"} /></div>
              <div className="col-md-10">
              <DatePicker
                value={endDate}
                setInputText={(value: string) => onChangeEndDate(value)}
                maxdate={today}
              /></div>
            </div>
            <div className=" col-md-4 d-flex mb-2 align-items-center">
            <div className="col-md-2">
              <LabelField lableName={"Title"} /></div>
              <div className="col-md-10">
              <CustomDropdown
                  lableName="Select Title"
                  setInputText={(value: any) => onChangeTitle(value)}
                  value={title}
                  options={titleList}
                />
            </div></div>
           
            <div className=" col-md-4 d-flex mb-2 align-items-center">
              <div className="col-md-2">
              <LabelField lableName={"Teacher"} /></div>
              <div className="col-md-10">
              <CustomDropdown
                  lableName="Select Teacher"
                  setInputText={(value: any) => onChangeTeacher(value)}
                  value={teacher}
                  options={teacherList}
                />
            </div></div>

            <div className="d-flex col-md-2  ">
              <SubmitButton name={"Search"} />
            </div>
          </div>
        </form>
        <div className="shadow-lg  m-2   ">
        <div className="d-flex  mx-2 pt-3  border-bottom">
              {tab?.map((c, index) => (
                <div 
                  className={`me-3 fw-bold fs-5  cursor 
                      ${toggle === c.name ? ' text-primary border-bottom border-primary border-3' : 'text-secondary' }`
                  }
                  onClick={() => handleChangetab(c.name)}
                >
                  {c.name}
                </div>
              ))}
            </div>
        {renderListMode()}
        </div>
      </div>
     
    </div>
  );
}
