import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import CustomDropdown from "../../Components/customDropdown";
import LabelField from '../../Components/labelField'
import { getTableDataSlots, today, usePagination } from '../../Utils/appConstants';
import InputField from '../../Atoms/InputField'; 
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import { image_size_150, validateIsFileImageType,validateIsLettersAndDotOnly,startsWithSpace} from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import { useDispatch } from 'react-redux';
import PaginationView from '../../Components/paginationView';
import PaginationTable from '../../Components/paginationTable';
import Pagination from "@mui/material/Pagination";
import TextEditor from '../../Components/TextEditor';
import DeleteIcon from '@mui/icons-material/Delete';
import Addmorebtn from '../../Components/addmorebtn';
import Submitbtn from "../../Components/submitButton";
import {getGeneralCourseList,  getGeneralSubjectList, getGeneralExamList} from '../../Redux/Actions/AdminAction';
export default function UpdateAboutExam() {
  const dispatch = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList]  = useState([]); 
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList]  = useState(); 
  const [resetExam, setResetExam] = useState(false);
  const [isEditMode,setisEditMode]=useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList]  = useState([]); 
  const [resetSubject, setResetSubject] = useState(false);
  const [question, setQuestion] = useState<any>("");
  const [video, setvideo]  = useState(""); 
  const initialVal = "";
  const [faqArr, setFaqArr] = useState([initialVal]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(count / rowsPerPage);
  const _DATA = usePagination(String(count), rowsPerPage);

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res)
        },
        () => { }
      )
    );

  }, []);
  const onChangeCourse = (value: any) => {
    setCourse(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id
        },
        (res: any) => {
          setExamList(res);

        },
        () => { }
      )
    );

  };
  const onChangeExam = (value: any) => {
    setExam(value._id)
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: exam._id,
        },
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value:any) => {
    setSubject(value)
   
  };
  const handleInputVideo = (value:any) => {
    setvideo(value)
   
  };
  const onPressAdd = () => {
    setFaqArr([...faqArr, initialVal[0]]);
  };
  const handleClose = (index: any) => {
    const newArrr = [...faqArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }

    setFaqArr(newArrr);
  };
  const handleChange = (e: any, page: number) => {
    onPageChange(page, rowsPerPage, 1);
    setPage(page - 1);
    setCurrentPage(page);
    _DATA.jump(page);
  };
  const onPageChange = (page: number, rowsPerPage: number, flag: number) => {
    /************ page change****************** */
    let data = {};
    if (flag === 1) {
      data = {
        skip: (page - 1) * rowsPerPage,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else if (flag === 2) {
      data = {
        skip: 0,
        limit: rowsPerPage,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    } else {
      data = {
        skip: 0,
        limit: count,
        // sectionType: type,
        // baseCourseId: parentCourseVal?._id,
        // examTypeId: examName?._id,
        // mainCategoryId: mainCategory?._id,
      };
    }

   
  };
  const handleChangePage = (event: any, newPage: number) => {
    setPage(newPage);
    setCurrentPage(newPage + 1);
  };
  const handleChangeRowsPerPage = (event: { target: { value: any; }; }) => {
    const value = event.target.value;
    if (value === "All") {
      setRowsPerPage(-1);
      setPage(0);
      setCurrentPage(1);
      return;
    }
    setRowsPerPage(parseInt(value, 10));
    setPage(0);
    setCurrentPage(1);
    onPageChange(page, value, 2);
  };
  const renderListMode = () => {
    return (
      <>
        {courseList?.length !== 0 ? (
          <div className="d-flex paginationdiv justify-content-end align-items-center mt-1 pe-4">
            <Pagination
              count={totalPages}
              size="large"
              page={currentPage}
              variant="outlined"
              shape="rounded"
              color={"primary"}
              onChange={handleChange}
            />
          </div>
        ) : null}
         {count > 0 ? (
          <PaginationView
            count={count}
            tableData={courseList ? courseList : []}
            page={page}
            rowsPerPage={rowsPerPage}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            rowsPerPageOptions={getTableDataSlots(count)}
          />
        ) : null}
        

        <PaginationTable
          tableData={courseList ? courseList : []}
          pagename={"AllAbout"}
          rowsPerPage={rowsPerPage}
          page={page}
          tableHead={[
            "S.N",
            "Questions",
            "Action",
          ]}
          edit={true}
          handleEdit={(value: any) => handleEdit(value)}
          handleDelete={(value: any) => handleDelete(value)}
        />  
      </>
    );
  };
  const renderEditMode = () => {
    return (
      <div className="col-8 px-3">
    
    {faqArr.map((item, index) => {
                  return (
          <div className="d-flex Justify-content-between  ">
          <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Faq's " +(index+1)} />
            </div>
            <div className='col-10 '>
            <div className='d-flex mb-3'>
              <div className='border rounded p-1'> <InputField
                        placeholder="Enter Question"
                        value={question}
                        onChangeInput={(value: any) => setQuestion(value)}
                        maxlength={100}
                        minlength={2}
                      />
                   <TextEditor
            value={''}
            onChange={(value: any) => console.log(value)} 
            height="vh100"
            width='vw100'
            /></div>
            {faqArr.length === 1 ? null : (<DeleteIcon className="cursor" color="error" fontSize='small'  onClick={() => handleClose(index)} />)}
               </div> </div>
            </div>)
          })}
             <div className='d-flex justify-content-end'>
              <Addmorebtn name="Add More" handleClick={onPressAdd} />
            </div>
            <Submitbtn
              name={"Submit"}
                 
              />
      </div>
    );
  };
  const handleEdit = (value: any) => { 
    
  }

  const handleDelete = (value: any) => {

  }
  return (
    <div className="contentBox">
      <PageTitle name={"EDIT ALL ABOUT EXAM "} isEditMode={isEditMode} setisEditMode={setisEditMode}/>
      <form className='px-1 mx-0 mt-4 formDiv pt-2'>
       <div className='col-8 px-3'>
       <div className="d-flex Justify-content-between  ">
          <div className="col-3 align-item-center d-flex">
            <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
            <CustomDropdown
              lableName="Select Course Type Name"
              setInputText={(value: any) =>
                onChangeCourse(value)
              }
              value={course}
              options={courseList}
              Isrequired={true}
              reset={resetParentCourse}
            />
          </div>
          </div>
          <div className="d-flex Justify-content-between  ">
          <div className="col-3 align-item-center d-flex">
            <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-8 ps-2">
            <CustomDropdown
              lableName="Select Exam Name"
              setInputText={(value: any) =>
                onChangeExam(value)
              }
              value={exam}
              options={examList}
              Isrequired={true}
              reset={resetExam}
            />
          </div>
          </div>
          <div className="d-flex Justify-content-between  ">
          <div className="col-3  align-item-center d-flex">
            <LabelField lableName={"Subject"} />
            </div>
            <div className="col-8 ps-2">
            <CustomDropdown
              lableName="Select Subject"
              setInputText={(value: any) =>
                onChangeSubject(value)
              }
              value={subject}
              options={subjectList}
              Isrequired={true}
              reset={resetSubject}
            />
          </div>
          </div>

       </div>
       {isEditMode?renderEditMode():renderListMode()}
      </form>
    </div>
  )
}

