import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import PageTitle from '../../Components/pageTitle'
import LabelField from '../../Components/labelField'
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
  import TextEditor from '../../Components/TextEditor';
  import DeleteIcon from '@mui/icons-material/Delete';
  import Addmorebtn from '../../Components/addmorebtn';
  import Submitbtn from "../../Components/submitButton";
  import {getGeneralCourseList,getExamList,getSubjectList,getAssignedExamsList, getGeneralSubjectList, getGeneralExamList} from '../../Redux/Actions/AdminAction';
import { length_FiveHundred, length_Ten } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
export default function CreateExamSyllabus() {
  const dispatch = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState();
  const [resetExam, setResetExam] = useState(false);
  const [subject, setSubject] = useState<any>("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [video, setvideo] = useState("");
  const initialVal = "";
  const [topicArr, setTopicArr] = useState([initialVal]);

  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res)
        },
        () => { }
      )
    );

    // dispatch(
    //   getExamList(
    //     "",
    //     (res: any) => {
    //       setExamList(res)
    //     },
    //     () => { }
    //   )
    // );

   
  }, []);
  const onChangeCourse = (value: any) => {
    setCourse(value._id);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id
        },
        (res: any) => {
          setExamList(res);

        },
        () => { }
      )
    );

  };
  const onChangeExam = (value: any) => {
    setExam(value)
    dispatch(
      getGeneralSubjectList(
        {
          examtypeId: exam._id,
        },
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };
  const onChangeSubject = (value: any) => {
    setSubject(value)

  };
  const handleInputVideo = (value: any) => {
    setvideo(value)

  };
  const onchangeTopic = (value: any ,index:any) => {
    let newArrr = JSON.parse(JSON.stringify(topicArr));
    newArrr[index] = value;
    setTopicArr(newArrr);

  };
  const onPressAdd = () => {
    setTopicArr([...topicArr, initialVal]);
  };
  const handleClose = (index: any) => {
    const newArrr = [...topicArr];
    if (index > -1) {
      newArrr.splice(index, 1);
    }

    setTopicArr(newArrr);
  };

  const validation = () => {
    let isEmpty:any = false;
    topicArr?.map((item)=>{
      if(item===""){
        isEmpty = true;
      }
    })
    return (
      course === "" || exam === "" || subject === "" || video === "" || isEmpty === true
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"EXAM SYLLABUS"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        <form className='col-8 pt-2'>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) =>
                  onChangeCourse(value)
                }
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3 align-item-center d-flex">
              <LabelField lableName={"Exam Name"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Exam Name"
                setInputText={(value: any) =>
                  onChangeExam(value)
                }
                value={exam}
                options={examList}
                Isrequired={true}
                reset={resetExam}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Subject"} />
            </div>
            <div className="col-8 ps-2">
              <CustomDropdown
                lableName="Select Subject"
                setInputText={(value: any) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
            <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Subject Video"} />
            </div>
            <div className="col-8 pe-3 ">
              <InputField
                placeholder="Enter Subject Video Url"
                value={video}
                onChangeInput={(value: any) => handleInputVideo(value)}
                maxlength={100}
                minlength={3}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between  ">
          <div className="col-3  align-item-center d-flex">
              <LabelField lableName={"Topic Info "} />
            </div>
            <div className='col-10 '>
           {topicArr?.map((item, index:any) => {
                  return ( <div className='d-flex mb-3'>
            
                   <TextEditor
            value={item}
            onChange={(value: any) => onchangeTopic(value ,index)} 
            height="vh100"
            width="700px"
            
          
            />
            {topicArr?.length === 1 ? null : (<DeleteIcon className="cursor" color="error" fontSize='small'  onClick={() => handleClose(index)} />)}
               </div> )
          })}</div>
            </div>
             <div className='d-flex justify-content-end'>
              <Addmorebtn name="Add More" handleClick={onPressAdd} />
            </div>
            <div className='d-flex justify-content-center mt-3'>
            <Submitbtn
              name={"Submit"}
                validation= {validation()} 
              />
          </div>
        </form>
      </div>
    </div>
  )
}

