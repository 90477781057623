import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import {
  getNewSubjectList,
  getTutorDetails,
} from "../../Redux/Actions/AdminAction";
import {
  FormStatus,
  GenderData,
  MaritalStatus,
} from "../../Utils/dataConstant";
import { useNavigate } from "react-router-dom";

import SubmitButton from "../../Components/submitButton";
import DatePicker from "../../Components/datePicker";
import AdminTable from "../../Components/adminTable";
import { today } from "../../Utils/appConstants";
import { useLocation } from "react-router-dom";
export default function TutorDetails() {
  const dispatch: Function = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);

  const [startDate, setStartDate] = useState(today);
  const [endDate, setEndDate] = useState(today);
  const [status, setStatus] = useState<any>("");
  const [resetStatus, setResetStatus] = useState(false);

  const [gender, setGender] = useState<any>("");
  const [resetGender, setResetGender] = useState(false);

  const [maritalStatus, setMaritalStatus] = useState<any>("");
  const [resetMaritalStatus, setResetMaritalStatus] = useState(false);

  const [tutorialDetalisList, setTutorialDetalisList] = useState([]);
console.log("location?.state",location?.state)
  useEffect(() => {
    if (location?.state) {
      const { endDateReturn, startdateReturn } = location?.state;
      const Postdata: any = {
        startDate: startdateReturn,
        endDate: endDateReturn,
      };
      dispatch(
        getTutorDetails(
          Postdata,
          (res: any) => {
            setTutorialDetalisList(res);
          },
          () => {}
        )
      );
    }
  }, [location?.state]);

  useEffect(() => {
    dispatch(
      getNewSubjectList(
        "",
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeStatus = (value: any) => {
    setStatus(value?._id);
    setResetStatus(false);
  };

  const onChangeGender = (value: any) => {
    setGender(value?.name);
    setResetGender(false);
  };

  const onChangeMaritalStatus = (value: any) => {
    setMaritalStatus(value?.name);
    setResetMaritalStatus(false);
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const Postdata = {
      marital_status: maritalStatus,
      gender: gender,
      status: status,
      startDate: startDate,
      endDate: endDate,
    };

    dispatch(
      getTutorDetails(
        Postdata,
        (res: any) => {
          console.log("resres",res)
          setTutorialDetalisList(res);
        },
        () => {}
      )
    );
  };
  const handleNavigate = (value: any) => {
    navigate("../tutorDataDetails", {
      state: {
        details: value,
        pageName: "tutorDetails",
        startDate: startDate,
        endDate: endDate,
      },
    });
  };

  const renderListMode = () => {
    return (
      <AdminTable
        tableData={tutorialDetalisList ? tutorialDetalisList : []}
        pagename={"tutorDetails"}
        tableHead={[
          "S.N",
          "Date Of Joining",
          "Full Name",
          "Mobile Number",
          "Email Id",
          "Gender",
          "Marital Status",
          "Grade",
          "Department (you are joining)",
          "Action",
        ]}
        handleNavigate={(value: any) => handleNavigate(value)}
      />
    );
  };

  const validation = () => {
    return (
      startDate === "" ||
      endDate === "" ||
      gender === "" ||
      maritalStatus === "" ||
      status === "" ||
      subject === ""
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"TUTOR DETAILS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form onSubmit={handleSubmit}>
          <div className="mt-4 ">
            <div className="d-flex flex-wrap justify-content-between p-3">
              <div className="col-md-12 d-flex flex-wrap justify-content-between">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-md-3 align-item-center d-flex">
                    <LabelField lableName={"Start Date"} />
                  </div>
                  <div className="col-md-7 ps-2">
                    <DatePicker
                      value={startDate}
                      setInputText={(value: string) => setStartDate(value)}
                      maxdate={today}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-md-3 align-item-center d-flex">
                    <LabelField lableName={"End Date"} />
                  </div>
                  <div className="col-md-7 ps-2">
                    <DatePicker
                      value={endDate}
                      setInputText={(value: string) => setEndDate(value)}
                      maxdate={today}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Status"} />
                  </div>
                  <div className="col-7 ps-2 ">
                    <CustomDropdown
                      lableName="Select Status"
                      setInputText={(value: any) => onChangeStatus(value)}
                      value={status}
                      options={FormStatus}
                      reset={resetStatus}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-12 d-flex flex-wrap justify-content-between">
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Gender"} />
                  </div>
                  <div className="col-7 ps-2  ">
                    <CustomDropdown
                      lableName="Select Gender"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeGender(value)
                      }
                      value={gender}
                      options={GenderData}
                      reset={resetGender}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Marital Status"} />
                  </div>
                  <div className="col-7 ps-2 ">
                    <CustomDropdown
                      lableName="Select Marital Status"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeMaritalStatus(value)
                      }
                      value={maritalStatus}
                      options={MaritalStatus}
                      reset={resetMaritalStatus}
                    />
                  </div>
                </div>
                <div className="d-flex col-md-4 Justify-content-between mt-2">
                  <div className="col-3 align-item-center d-flex">
                    <LabelField lableName={"Subject Name"} />
                  </div>
                  <div className="col-7 ps-2 ">
                    <CustomDropdown
                      lableName="Select Subject Name"
                      setInputText={(value: { _id: string; name: string }) =>
                        onChangeSubject(value)
                      }
                      value={subject}
                      options={subjectList}
                      reset={resetSubject}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-3">
              <SubmitButton name={"Submit"} validation={validation()} />
            </div>
            {renderListMode()}
          </div>
        </form>
      </div>
    </div>
  );
}
