import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import LandingPageLeads from "./landingPageLeads";
import paidAdsSidebarData from "./paidAdsSidebarData";
import CounsellingLeads from "./counsellingLeads";
import MarketingLandingPage from "./marketingLandingPage";
import PaidAddLeads from "./paidAddLeads";
import ReinquiredLeads from "./reinquiredLeads";


export default function PaidAdsPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={paidAdsSidebarData?.PaidAdsSidebarData} />
      <div className="dashboard">
        <Routes>
          <Route path="/" element={<PaidAddLeads/>} />
          <Route path="paidadsLandingpageleads" element={<LandingPageLeads />} />
          <Route path="paidadsCounsellingleads" element={<CounsellingLeads />} />
          <Route path="paidadsMarketinglandingpage" element={<MarketingLandingPage />} />
          <Route path="paidAddLeads" element={<PaidAddLeads />} />
          <Route path="reinquiredLeads" element={<ReinquiredLeads />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  )
}