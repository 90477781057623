import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import Addmorebtn from "../../Components/addmorebtn";
import CustomDropdown from "../../Components/customDropdown";
import DeleteIcon from "@mui/icons-material/Delete";
import InputField from "../../Atoms/InputField";
import { useDispatch } from "react-redux";
import {
  addTopic,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import {
  length_Hundred,
  length_Two,
  length_TwoHundredFifty,
} from "../../Utils/validators";
import { GradeList, levelList } from "../../Utils/dataConstant";

export default function CreateTopic() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const initialVal: {
    topicName: any;
    priorityOrder: any;
  }[] = [
      {
        topicName: "",
        priorityOrder: "",
      },
    ];
  const [topicArr, setTopicArr] = useState(initialVal);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [level, setLevel] = useState("");
  const [resetLevel, setResetLevel] = useState(false);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);
    setResetGradeList(true);
    setGrade("");
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };
  const onChangeLevel = (value: { _id: any; name?: string }) => {
    setLevel(value._id);
    setResetLevel(false);
  };

  function onchangeTopic(val: any, index: any, key: any) {
    let newArr = JSON.parse(JSON.stringify(topicArr));
    if (key) {
      newArr[index][key] = val;
      setTopicArr(newArr);
    }
  }

  const onPressAdd = () => {
    setTopicArr([...topicArr, initialVal[0]]);
  };

  const handleClose = (index: any) => {
    const newArrr = [...topicArr];
    newArrr.splice(index, 1);
    setTopicArr(newArrr);
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const Postdata = {
      topicList: topicArr,
      coursetypeId: course?._id,
      examtypeId: exam,
      subjectId: subject,
      gradeId: grade?._id,
      levelId: level,
    };
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    dispatch(
      addTopic(
        Postdata,
        (res: any) => {
          setResetParentCourse(true);
          setresetExam(true);
          setResetSubject(true);
          setResetGradeList(true);
          setTopicArr([initialVal[0]]);
          setLevel("");
          setResetLevel(true);
          setResetCountryList(true);
        },
        () => { }
      )
    );
  };
  const validateTopic = () => {
    let isTopicArr: boolean = false;
    topicArr?.map((item: any) => {
      if (
        item?.topicName === "" ||
        item?.priorityOrder === "" ||
        item?.topicName?.length < length_Two + 1
      ) {
        isTopicArr = true;
      }
    });
    return isTopicArr;
  };
  const validation = () => {
    return course === "" || subject === "" || level === "" || validateTopic();
  };

  return (
    <div className="contentBox">
      <PageTitle name={"CREATE TOPIC"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="col-10 pt-2" onSubmit={handleSubmit}>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
                reset={resetCountryList}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course Type"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Course Type Name"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={course}
                options={courseList}
                Isrequired={true}
                reset={resetParentCourse}
              />
            </div>
          </div>
          {course?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Student Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Student Grade"
                  setInputText={(value: any) => onChangeStudentGrade(value)}
                  value={grade}
                  Isrequired={true}
                  options={GradeList}
                  reset={resetGradeList}
                />
              </div>
            </div>
          ) : null}
          {course?.type === 2 || course?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name "} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
          )}

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject Name"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject Name"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeSubject(value)
                }
                value={subject}
                options={subjectList}
                Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Level"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Level"
                setInputText={(value: { _id: string; name: string }) =>
                  onChangeLevel(value)
                }
                value={level}
                options={levelList}
                Isrequired={true}
                reset={resetLevel}
              />
            </div>
          </div>

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Topic Name"} />
            </div>
            <div className=" col-8">
              {topicArr.map((item: any, index: any) => {
                const { topicName, priorityOrder } = item;
                return (
                  <div className="d-flex p-2">
                    <div className=" d-flex col-9 border rounded p-3">
                      <div className=" input-border">
                        <div className="d-flex jusftify-content-between mt-2">
                          <div className="col-6 p-2">
                            <InputField
                              placeholder="Enter Topic Name"
                              value={topicName}
                              onChangeInput={(value: any) =>
                                onchangeTopic(value, index, "topicName")
                              }
                              maxlength={length_TwoHundredFifty - 1}
                              minlength={length_Two + 1}
                              isRequired
                            />
                          </div>
                          <div className="col-6 p-2">
                            <InputField
                              placeholder="Enter Priority Order"
                              value={priorityOrder}
                              onChangeInput={(value: any) =>
                                onchangeTopic(value, index, "priorityOrder")
                              }
                              maxlength={length_Hundred - 1}
                              isRequired
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="col-9 p-2 d-flex removeButton">
                      {topicArr.length === 1 ? null : (
                        <DeleteIcon
                          className="cursor"
                          color="error"
                          fontSize="small"
                          onClick={() => handleClose(index)}
                        />
                      )}
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="d-flex justify-content-end col-8">
            <Addmorebtn name="Add More" handleClick={onPressAdd} />
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
