export default {
  CXOSidebarData: [
    // {
    //   title: "Transaction History",
    //   path: "transaction_history",

    // },
    {
      title: "PAYMENT STATUS",
      path: "payment_status",
    },
    {
      title: "COUNSELLOR PAYMENTS",
      path: "counsellorPayments",
    },
    {
      title: "INSTALLMENTS",
      subNav: [
        {
          title: "UPCOMING INSTALLMENTS",
          path: "upcomingInstallments",
          cName: "sub-nav",
        },
        {
          title: "OVERDUE INSTALLMENTS",
          path: "overdueInstallments",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "LEAD MANAGEMENT",
      subNav: [
        {
          title: "COUNSELLING LEADS",
          path: "counsellingLeads",
          cName: "sub-nav",
        },
        {
          title: "LANDING PAGE LEADS",
          path: "landingpageleads",
          cName: "sub-nav",
        },
        {
          title: "REGISTERED USER",
          path: "registeredUsers",
          cName: "sub-nav",
        },
        {
          title: "MARKETING LANDING PAGE",
          path: "marketingLandingpage",
          cName: "sub-nav",
        },
        {
          title: "AFFILIATE MARKETING",
          path: "affiliateMarketing",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "DEMO MANAGEMENT",
      subNav: [
        {
          title: "DEMO REQUEST",
          path: "demoRequest",
          cName: "sub-nav",
        },
        {
          title: "DEMO SCHEDULE",
          path: "demoSchedule",
          cName: "sub-nav",
        },
        {
          title: "DEMO CONDUCTED",
          path: "demoConducted",
          cName: "sub-nav",
        },
        {
          title: "DEMO CANCEL",
          path: "demoCancel",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "DSA MANAGEMENT",
      subNav: [
        {
          title: "DSA CONVERSION",
          path: "dsaConversion",
          cName: "sub-nav",
        },
        {
          title: "DSA PAYMENT PAYOUT",
          path: "dsaPaymentPayout",
          cName: "sub-nav",
        },
        {
          title: "DSA COMPANY LIST",
          path: "updateDsaUser",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "TUTOR MANAGEMENT",
      subNav: [
        {
          title: "TEACHER PAYMENT PAYOUT",
          path: "paymentPayout",
          cName: "sub-nav",
        },
        {
          title: "TEACHER CONVERSION",
          path: "conversion",
          cName: "sub-nav",
        },
        {
          title: "PTM FEEDBACK",
          path: "ptmFeedback",
          cName: "sub-nav",
        },
      ],
    },
    {
      title: "AFFILIATE PAYMENT PAYOUT",
      path: "affiliatePaymentPayout",
    },
    {
      title: "STUDENT FEEDBACK",
      path: "liveClassStudentFeedback",
    },
  ],
};
