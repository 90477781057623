import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import AdminTable from '../../Components/adminTable';
import LabelField from '../../Components/labelField'
import Submitbtn from "../../Components/submitButton";
import InputField from '../../Atoms/InputField';
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import { validateIsFileImageType, startsWithSpace, length_Two, validateFullName, image_size_2MB } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import { useDispatch } from 'react-redux';
import { Checkbox } from '@mui/material';
import { deleteSubCategory, getSubcategoryList, updateSubCategory, uploadSubcategoryLogo } from '../../Redux/Actions/AdminAction';

export default function UpdateSubCategory() {
  const dispatch: Function = useDispatch();
  const [subCategory, setSubCategory] = useState<string>("");
  const [isEdit, setIsEdit] = useState<boolean>();
  const [subCategoryLogo, setSubCategoryLogo] = useState<any>("");
  const [isTaxable, setIsTaxable] = useState(false);
  const [time, setTime] = useState<any>("");
  const [classType, setClassType] = useState<any>("");
  const [subcategoryList, setSubcategoryList] = useState<string>("");
  const [uploadSubCategoryIcon, setUploadSubCategorylogo] = useState<any>("");
  const [subCategoryId, setSubCategoryId] = useState<any>("");
  const [subCategoryArr, setSubCategoryArr] = useState<any>("");

  useEffect(() => {
    updatedata();
  }, []);

  const updatedata = () => {
    dispatch(
      getSubcategoryList(
        "",
        (res: any) => {
          setSubcategoryList(res)
        },
        () => { }
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEdit(true);
    setSubCategory(value?.name);
    setSubCategoryLogo(value?.subcategoryIcon);
    setIsTaxable(value?.istax);
    setTime(value?.timeType);
    setClassType(value?.classType);
    setSubCategoryId(value._id);
    setSubCategoryArr(value)
  }

  const handleDelete = (value: any) => {
    dispatch(
      deleteSubCategory(
        value._id,
        () => {
          updatedata();
        },
        () => { }
      )
    );

  }

  const handleOptionImageChange = (e: { preventDefault: () => void; target: { files: any[]; }; }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_2MB) {
      dispatch(showErrorSnackbar("File size should be less than 2 MB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setSubCategoryLogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadSubcategoryLogo(file, (res: any) => {
        delete res?.filePath;
        setUploadSubCategorylogo(res);
      }, () => { }));

    }
  }
  const onChangeSubCategory = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setSubCategory(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }
  const onChangeTax = (value: any) => {
    if (value.target.checked) {
      setIsTaxable(true);
    } else {
      setIsTaxable(false);
    }
  };

  const onChangeTime = (value: any) => {
    if (value === 1) {
      setTime(value);
    } else if (value === 2) {
      setTime(value);
      setClassType("");
    }
  };

  const onChangeClassType = (value: any) => {
    setClassType(value);
  };

  const handleOnEdit = (e: any) => {
    e.preventDefault();

    const postData: any = {
    }
    if (subCategoryArr?.name != subCategory) {
      postData["subcategoryName"] = subCategory
    }
    if (subCategoryArr?.istax != isTaxable) {
      postData["istax"] = isTaxable
    }
    if (subCategoryArr?.subcategoryIcon != uploadSubCategoryIcon?.file) {
      postData["subcategoryIcon"] = uploadSubCategoryIcon?.file
    }
    if (subCategoryArr?.subcategoryOriginalIcon != uploadSubCategoryIcon?.originalName) {
      postData["subcategoryOriginalIcon"] = uploadSubCategoryIcon?.originalName
    }
    if (subCategoryArr?.timeType != time) {
      postData["timeType"] = time
    }
    if (time === 1) {
      postData["classType"] = classType
    }
    if (subCategoryArr?.classType != classType) {
      postData["classType"] = classType
    }
    if (time === 2) {
      //postData["classType"]=classType
      delete postData["classType"];

    }

    dispatch(
      updateSubCategory(
        postData,
        subCategoryId,
        (res: any) => {
          setIsEdit(false);
          updatedata();

        }, () => { }
      )
    );

  };
  function validation() {
    let isValidate = false;
    isValidate =
      (subCategory === "" || subCategory.length < length_Two + 1 || uploadSubCategoryIcon === "") && time == 1
        ? classType === "" || subCategory.length < length_Two + 1
        : time === "" || subCategory.length < length_Two + 1;

    return isValidate;
  }

  return (
    <div className="contentBox">
      <PageTitle name={"EDIT SUB CATEGORY "} isEditMode={isEdit} setisEditMode={setIsEdit} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        {isEdit !== true ? <>
          <div className='d-flex mb-2 align-items-center col-7'>
            <div className="col-4 align-item-center d-flex">
              <LabelField lableName={"Sub Category For"} />
            </div>
            <div className="col-8 text-primary fw-bold ps-2 ">
              For Online Course
            </div>
          </div>

          <AdminTable
            tableData={subcategoryList ? subcategoryList : []}
            pagename={"subCatagory"}
            tableHead={["S.N", "Subcategory Name", "Subcategory Icon", "Tax Flag", "Time Flag", "Class Type Flag", "Action"]}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
            edit={true}
          />
        </> : ""}
        {isEdit === true ? <div>
          <form className='' onSubmit={handleOnEdit}>

            <div className="col-7">
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Sub Category For"} />
                </div>
                <div className="col-8 text-primary fw-bold ps-2 ">
                  For Online Course
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Sub Category"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Sub Category Name"
                    value={subCategory}
                    onChangeInput={(value: any) => onChangeSubCategory(value)}
                    maxlength={100}
                    minlength={2}
                  />
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Sub Category Icon1"} />
                </div>
                <div className="col-8 ps-2">
                  <ImagePickerAndViewer
                    lableName={"Option Image"}
                    value={subCategoryLogo}
                    handleChange={(e: any) => handleOptionImageChange(e)}
                    isRequired={true}

                  />
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Tax Flag"} />
                </div>

                <div className="col-8 d-flex  ">
                  <Checkbox
                    name="Tax"
                    onChange={(value: any) => onChangeTax(value)}
                    checked={isTaxable}
                  />
                  <span className="ms-2 mt-2">Taxable Goods</span>

                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Time"} />
                </div>
                <div className='ps-2'>
                  <span className="me-3 radiobtnspan">
                    <span>
                      <input
                        type="radio"
                        value={1}
                        name={"time"}
                        checked={time === 1}
                        onChange={() => onChangeTime(1)}
                      />{" "}
                      Start Date
                    </span>
                  </span>
                  <span className="me-3 radiobtnspan">
                    <input
                      type="radio"
                      value={2}
                      name={"time"}
                      checked={time === 2}
                      onChange={() => onChangeTime(2)}
                    />{" "}
                    Video Duration
                  </span>
                </div>
              </div>
              {time === 1 ? (
                <div className='d-flex mb-2 align-items-center'>
                  <div className="col-4 align-item-center d-flex">
                    <LabelField lableName={"Class Type Flag"} />
                  </div>
                  <div className='ps-2'>
                    <span className="me-3 radiobtnspan">
                      <span>
                        <input
                          type="radio"
                          value={1}
                          name={"classType" + "1"}
                          checked={classType === 1}
                          onChange={() => onChangeClassType(1)}
                        />{" "}
                        Live
                      </span>
                    </span>
                    <span className="me-3 radiobtnspan">
                      <input
                        type="radio"
                        value={2}
                        name={"classType" + "2"}
                        checked={classType === 2}
                        onChange={() => onChangeClassType(2)}
                      />{" "}
                      Premier
                    </span>
                  </div>
                </div>
              ) : null}

            </div>


            <div className='d-flex justify-content-center mt-3'>
              <Submitbtn name={"Save & Update"} validation={validation()} />
            </div>
          </form>
        </div> : ""}
      </div>
    </div>
  )
}

