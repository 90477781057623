import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  addGlobalSetting,
  bindSubjectList,
  getCountryList,
  getCourseTypelist,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { GradeList } from "../../Utils/dataConstant";
import InputField from "../../Atoms/InputField";
import {
  length_One,
  length_TwoHundredFifty,
  validateIsNumberOnly,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { currencyList } from "../../Utils/dataConstant";
export default function CreateGlobalSetting() {
  const dispatch: Function = useDispatch();
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [resetExam, setresetExam] = useState(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [grade, setGrade] = useState<any>("");
  const [resetGradeList, setResetGradeList] = useState(false);
  const [subject, setSubject] = useState("");
  const [subjectList, setSubjectList] = useState([]);
  const [resetSubject, setResetSubject] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [type, setType] = useState<any>("");
  const [classCharges, setClassCharges] = useState<any>("");
  const [currency, setCurrency] = useState<any>("");
  const [resetCurrencyList, setResetCurrencyList] = useState(false);
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
    setresetExam(true);

    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeStudentGrade = (value: any) => {
    setGrade(value);
    setResetGradeList(false);
    const postData = {
      coursetypeId: course?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: { _id: any; name?: string }) => {
    setresetExam(false);
    setExam(value?._id);
    const postData = {
      examtypeId: value._id,
      coursetypeId: course?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };
  const onChangeSubject = (value: { _id: any; name?: string }) => {
    setSubject(value._id);
    setResetSubject(false);
  };

  const onChangeType = (value: any) => {
    setType(value);
  };

  const onchangeClassCharges = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setClassCharges(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const onChangeCurrency = (value: any) => {
    setCurrency(value?.name);
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const Postdata = {
      countryId: country?._id,
      coursetypeId: course?._id,
      gradeId: grade?._id,
      examtypeId: exam,
      subjectId: subject,
      isNative: type === 1 ? true : false,
      charges: classCharges,
      currency: currency,
    };
    if (
      Postdata["gradeId"] === null ||
      Postdata["gradeId"] === undefined ||
      Postdata["gradeId"] === ""
    ) {
      delete Postdata["gradeId"];
    }
    if (
      Postdata["examtypeId"] === null ||
      Postdata["examtypeId"] === undefined ||
      Postdata["examtypeId"] === ""
    ) {
      delete Postdata["examtypeId"];
    }
    dispatch(
      addGlobalSetting(
        Postdata,
        (res: any) => {
          setResetParentCourse(true);
          setresetExam(true);
          setResetGradeList(true);
          setResetSubject(true);
          setType("");
          setClassCharges("");
          setCurrency("");
          document
            .querySelectorAll("input[type=checkbox]")
            .forEach((el: any) => (el.checked = false));
        },
        () => {}
      )
    );
  };

  const validation = () => {
    return (
      course === "" ||
      subject === "" ||
      type === "" ||
      classCharges === "" ||
      currency === ""
    );
  };

  return (
    <div className="contentBox">
      <PageTitle name={"GLOBAL SETTING FOR EXTENSION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            {course?.type === 2 ? (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Student Grade"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Student Grade"
                    setInputText={(value: any) => onChangeStudentGrade(value)}
                    value={grade}
                    Isrequired={true}
                    options={GradeList}
                    reset={resetGradeList}
                  />
                </div>
              </div>
            ) : null}
            {course?.type === 2 || course?.type === 3 ? null : (
              <div className="d-flex mb-2 align-items-center">
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Exam Name "} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Exam Name"
                    value={exam}
                    options={examList}
                    Isrequired={true}
                    reset={resetExam}
                    setInputText={(value: { _id: string; name: string }) =>
                      onChangeExam(value)
                    }
                  />
                </div>
              </div>
            )}

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Subject Name"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Subject Name"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeSubject(value)
                  }
                  value={subject}
                  options={subjectList}
                  Isrequired={true}
                  reset={resetSubject}
                />
              </div>
            </div>
            <div className="d-flex Justify-content-between my-2 col-md-6">
              <div className="col-8 align-item-center d-flex">
                <LabelField lableName={"Teacher Type"} />
              </div>
              <div className="col-8 d-flex ps-3 ">
                <div className="col-6">
                  <span>
                    <input
                      type="radio"
                      value={1}
                      name={"type"}
                      checked={type === 1 ? true : false}
                      onChange={() => onChangeType(1)}
                    />{" "}
                    Native
                  </span>
                </div>
                <div className="col-6">
                  <span>
                    <input
                      type="radio"
                      value={2}
                      name={"type"}
                      checked={type === 2 ? true : false}
                      onChange={() => onChangeType(2)}
                    />{" "}
                    Indian
                  </span>
                </div>
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Per Class Charges "} />
              </div>
              <div className="col-6 ps-2 ">
                <InputField
                  placeholder="Per Class Charges"
                  value={classCharges}
                  isRequired={true}
                  onChangeInput={(value: any) => onchangeClassCharges(value)}
                  maxlength={length_TwoHundredFifty - 1}
                  minlength={length_One}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"currency"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Currency"
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeCurrency(value)
                  }
                  value={currency}
                  options={currencyList}
                  Isrequired={true}
                  reset={resetCurrencyList}
                />
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
