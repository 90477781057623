import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  getAllStudentList,
  getCountryList,
  getCourseTypelist,
} from "../../Redux/Actions/AdminAction";
import {
  length_TwoHundred,
  length_One,
  validateIsNumberOnly,
} from "../../Utils/validators";
import InputField from "../../Atoms/InputField";
import AdminTable from "../../Components/adminTable";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function UserDetails() {
  const dispatch: Function = useDispatch();

  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState<any>();
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [country, setCountry] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountryList, setResetCountryList] = useState(false);
  const [studentNo, setStudentNo] = useState<any>("");
  const [allStudentList, setAllStudentList] = useState([]);

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setResetCountryList(false);
    setCountry(value);
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => {}
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourse(value);
    setResetParentCourse(false);
  };

  const onChangeStudentNo = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setStudentNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const PostData = {
      countryId: country?._id,
      coursetypeId: course?._id,
      search: studentNo,
    };
    if (
      PostData["search"] === null ||
      PostData["search"] === undefined ||
      PostData["search"] === ""
    ) {
      delete PostData["search"];
    }
    dispatch(
      getAllStudentList(
        PostData,
        (res: any) => {
          setAllStudentList(res);
        },
        () => {}
      )
    );
  };

  const renderListMode = () => {
    return (
      <div className="mt-4">
        <AdminTable
          tableData={allStudentList ? allStudentList : []}
          pagename={"AllUserDetails"}
          tableHead={["S.N", "NAME", "MOBILE NUMBER", "COUNTRY", "PASSWORD"]}
        />
      </div>
    );
  };

  const validation = () => {
    return country === "";
  };

  return (
    <div className="contentBox">
      <PageTitle name={"USER DETAILS"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7 pt-2">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangeCountry(value)}
                  value={country}
                  options={countryList}
                  reset={resetCountryList}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course Type"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourseType(value)}
                  value={course}
                  options={courseList}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-6">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={studentNo}
                  onChangeInput={(value: any) => onChangeStudentNo(value)}
                  maxlength={length_TwoHundred}
                  minlength={length_One}
                />
              </div>
            </div>
          </div>
          <div className="col-6 d-flex justify-content-center mt-3">
            <Submitbtn name={"Submit"} validation={validation()} />
          </div>
        </form>
        {renderListMode()}
      </div>
    </div>
  );
}
