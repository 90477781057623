import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import LabelField from "../../Components/labelField";
import CustomDropdown from "../../Components/customDropdown";
import InputField from "../../Atoms/InputField";
import {
  addToCart,
  applyCoupon,
  bindSubjectList,
  getByPackageTopicwiseList,
  getCartList,
  getCountryList,
  getCourseTypelist,
  getCustomizePackageAmount,
  getGeneralExamList,
  getPackageEditData,
  getRegistrationDetails,
  paymentRequest,
  placeOrder,
  saveCustomizePackage,
  savePaymentLink,
  saveRegistrationDetails,
} from "../../Redux/Actions/AdminAction";
import {
  length_Eight,
  length_Ten,
  startsWithSpace,
  validateDigitOnly,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
import { GradeList, paymentOptionsList } from "../../Utils/dataConstant";
import { getGeneralPackageList } from "../../Redux/Actions/SuperAdminAction";
import getSymbolFromCurrency from "currency-symbol-map";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../../Modals";

export default function CounsellorPayments() {
  const dispatch: Function = useDispatch();
  const [country, setCountryCode] = useState<any>("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [userManualEntry, setUserManualEntry] = useState(false);
  const [studentId, setStudentId] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [courseType, setCourseType] = useState<any>("");
  const [exam, setExam] = useState<any>("");
  const [grade, setGrade] = useState<any>("");
  const [subject, setSubject] = useState<any>("");
  const [packages, setPackage] = useState<any>("");
  const [packageOptions, setPackageOptions] = useState<any>("");
  const [coupon, setCoupon] = useState<any>("");
  const [employeeName, setEmployeeName] = useState<any>("");
  const [paymentOptions, setPaymentOptions] = useState<any>("");
  const [paymentLink, setPaymentLink] = useState<any>("");
  const [customPackageSaved, setCustomPackageSaved] = useState<any>(false);
  const [copied, setCopied] = useState(false);
  const [offerPrice, setOfferPrice] = useState<any>(0);
  const [sellingPrice, setSellingPrice] = useState<any>(0);
  const [totalItemCount, setTotalItemCount] = useState<any>(0);
  const [totalItemAmount, setTotalItemAmount] = useState<any>(0);
  const [totalLectureCount, setTotalLectureCount] = useState<any>(0);
  const [firstInstallmentPercentage, setFirstInstallmentPercentage] =
    useState<any>(0);
  const [secondInstallmentPercentage, setSecondInstallmentPercentage] =
    useState<any>(0);
  const [
    firstInstallmentPaymentPercentage,
    setFirstInstallmentPaymentPercentage,
  ] = useState<any>(0);
  const [
    secondInstallmentPaymentPercentage,
    setSecondInstallmentPaymentPercentage,
  ] = useState<any>(0);
  const [
    thirdInstallmentPaymentPercentage,
    setThirdInstallmentPaymentPercentage,
  ] = useState<any>(0);
  const [isFirstInstallment, setIsFirstInstallment] = useState<any>(false);
  const [isSecondInstallment, setIsSecondInstallment] = useState<any>(false);

  const [countryList, setCountryList] = useState([]);
  const [courseTypeList, setCourseTypeList] = useState([]);
  const [examList, setExamList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [packageList, setPackageList] = useState([]);
  const [packageOptionsList, setPackageOptionsList] = useState([]);
  const [topicList, setTopicList] = useState<any>([]);
  const [cartList, setCartList] = useState<any>([]);
  const [placeOrderData, setPlaceOrderData] = useState<any>([]);

  const [resetCourseType, setResetCourseType] = useState<any>("");
  const [resetExam, setResetExam] = useState<any>("");
  const [resetGrade, setResetGrade] = useState<any>("");
  const [resetSubject, setResetSubject] = useState<any>("");
  const [resetPackages, setResetPackage] = useState<any>("");
  const [resetPackageOptions, setResetPackageOptions] = useState<any>("");
  const [resetPaymentOptions, setResetPaymentOptions] = useState<any>("");

  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => {}
      )
    );
  }, []);

  const onChangeCountry = (value: any) => {
    setCountryCode(value);
    setPhoneNumber("");
    setStudentId("");
    setFirstName("");
    setLastName("");
    setEmail("");
    const postData = {
      countryId: value?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseTypeList(res);
        },
        () => {}
      )
    );
  };

  const onChangePhoneNumber = (value: any) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setPhoneNumber(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };

  const phoneNumberLengthValidation = (number: string) => {
    if (country?._id === 13) {
      return number?.length !== length_Eight;
    } else if (country?._id === 13) {
      return number?.length !== length_Ten;
    }
  };

  const phoneValidations = () => {
    return (
      phoneNumber === "" ||
      phoneNumberLengthValidation(phoneNumber) ||
      country === ""
    );
  };

  const userValidations = () => {
    return (
      phoneNumber === "" ||
      phoneNumberLengthValidation(phoneNumber) ||
      country === "" ||
      firstName === "" ||
      lastName === "" ||
      email === "" ||
      courseType === ""
    );
  };

  const onSearchMobile = () => {
    const postData = {
      mobile: phoneNumber,
      countryCode: country?.phonecode?.toString(),
    };
    if (
      postData["countryCode"] === null ||
      postData["countryCode"] === undefined ||
      postData["countryCode"] === ""
    ) {
      delete postData["countryCode"];
    }
    dispatch(
      getRegistrationDetails(
        postData,
        (res: any) => {
          console?.log("res manual", res);
          if (res !== null) {
            setUserManualEntry(false);
            setStudentId(res?.studentId);
            setFirstName(res?.firstName);
            setLastName(res?.lastName);
            setEmail(res?.email);
          }
        },
        () => {
          setUserManualEntry(true);
          setStudentId("");
          setFirstName("");
          setLastName("");
          setEmail("");
          setCourseType("");
          setResetCourseType(true);
        }
      )
    );
  };

  const onChangeCourseType = (value: { _id: any; name?: string }) => {
    setCourseType(value);
    setResetCourseType(false);
    setResetGrade(true);
    setResetExam(true);
    setResetSubject(true);
    setResetPackage(true);
    setResetPackageOptions(true);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => {}
      )
    );
    const postData = {
      coursetypeId: value._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onSaveUserDetails = (e: any) => {
    e.preventDefault();
    const postData = {
      mobile: phoneNumber,
      countryCode: country?.phonecode?.toString(),
      firstName: firstName,
      lastName: lastName,
      email: email,
      coursetypeId: courseType?._id,
      refId: userId,
      sourceId: 7,
    };
    dispatch(
      saveRegistrationDetails(
        postData,
        (res: any) => {
          console?.log("sdfgds", res);
          setStudentId(res[0]?.studentId);
        },
        () => {}
      )
    );
  };

  const onChangeGrade = (value: any) => {
    setGrade(value);
    setResetGrade(false);
    setResetExam(true);
    setResetSubject(true);
    setResetPackage(true);
    setResetPackageOptions(true);
    const postData = {
      coursetypeId: courseType?._id,
      gradeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeExam = (value: any) => {
    setExam(value);
    setResetExam(false);
    setResetGrade(true);
    setResetSubject(true);
    setResetPackage(true);
    setResetPackageOptions(true);
    const postData = {
      coursetypeId: courseType?._id,
      examtypeId: value?._id,
    };
    dispatch(
      bindSubjectList(
        postData,
        (res: any) => {
          setSubjectList(res);
        },
        () => {}
      )
    );
  };

  const onChangeSubject = (value: any) => {
    setSubject(value);
    setResetSubject(false);
    setResetPackage(true);
    setResetPackageOptions(true);
    const postData: any = {
      courseId: courseType?._id,
      subjectId: value?._id,
    };
    if (grade) {
      postData["gradeId"] = grade?._id;
    }

    if (exam) {
      postData["examtypeId"] = exam?._id;
    }
    dispatch(
      getGeneralPackageList(
        postData,
        (res: any) => {
          setPackageList(res);
        },
        () => {}
      )
    );
  };

  const onChangePackage = (value: any) => {
    setPackage(value);
    setResetPackage(false);
    setResetPackageOptions(true);
    setCartList([]);
    setTopicList([]);
    dispatch(
      getPackageEditData(
        value?._id,
        (res: any) => {
          if (res?.packageOptions?.length > 0) {
            let tempData: any = res?.packageOptions?.map((item: any) => {
              return {
                packageOptionId: item?.packageOptionId,
                levelId: item?.levelId,
                name: item?.levelName,
                offerPrice: item?.offerPrice,
                sellingPrice: item?.sellingPrice,
                isRecommended: item?.isRecommended,
                priorityOrder: item?.priorityOrder,
                offerLectureCount: item?.offerLectureCount,
              };
            });
            setPackageOptionsList(tempData);
          }
        },
        () => {}
      )
    );
  };

  const onChangePackageOptions = (value: any) => {
    setPackageOptions(value);
    setResetPackageOptions(false);
    setCartList([]);
    setTopicList([]);
    setTotalItemAmount(0);
    setCoupon("");
    setEmployeeName("");
    if (value?.levelId === 4) {
      const postData = {
        packageId: packages?._id,
      };
      dispatch(
        getByPackageTopicwiseList(
          postData,
          (res: any) => {
            let tempViewDetails = res?.map((item: any) => {
              return {
                _id: item?._id,
                name: item?.name,
                lectureCount: parseInt(item?.lectureCount),
                extraLectureCount: parseInt(item?.extraLectureCount),
                lectures: item?.lectures,
                viewDetails: false,
                isSelected: false,
                addedExtraLectureCount: 0,
              };
            });
            setTopicList(tempViewDetails);
          },
          () => {}
        )
      );
    } else {
      const postData = {
        studentId: studentId,
        packages: [
          {
            packageId: packages?._id,
            packageOptionId: value?.packageOptionId,
          },
        ],
      };
      dispatch(
        addToCart(
          postData,
          (res: any) => {},
          () => {}
        )
      );
    }
  };

  const getAmount = (tempArr: any) => {
    let tempData: any = tempArr?.filter((item: any) => item.isSelected);
    let lectureCount: number = 0;
    let totalExtraLectureCount: number = 0;
    tempData?.map((item: any) => {
      lectureCount = lectureCount + item?.lectureCount;
      totalExtraLectureCount =
        totalExtraLectureCount + item?.addedExtraLectureCount;
    });
    setTotalLectureCount(lectureCount + totalExtraLectureCount);
    const postData: any = {
      countryId: country?._id,
      lectureCount: lectureCount,
      extraLectureCount: totalExtraLectureCount,
    };
    if (
      postData["extraLectureCount"] === "" ||
      postData["extraLectureCount"] === null ||
      postData["extraLectureCount"] === undefined
    ) {
      delete postData["extraLectureCount"];
    }
    dispatch(
      getCustomizePackageAmount(
        postData,
        (res: any) => {
          if (res !== null) {
            setTotalItemAmount(res);
          } else {
            setTotalItemAmount(0);
          }
        },
        () => {}
      )
    );
  };

  const onChangeDetails = (index: any, type: any, value: any) => {
    const tempArr = topicList;
    if (type === "onSelectTopic") {
      if (tempArr[index].isSelected) {
        tempArr[index].isSelected = false;
        setTotalItemAmount(0);
        tempArr[index].addedExtraLectureCount = 0;
        getAmount(tempArr);
      } else {
        tempArr[index].isSelected = true;
        getAmount(tempArr);
      }
      let count: any = tempArr?.filter((item: any) => item.isSelected);
      setTotalItemCount(count?.length);
      setTopicList(tempArr);
    } else if (type === "onClickView") {
      if (tempArr[index].viewDetails) {
        tempArr[index].viewDetails = false;
      } else {
        tempArr[index].viewDetails = true;
      }
      setTopicList(tempArr);
    } else if (type === "addedExtraLectureCount") {
      if (value <= tempArr[index].extraLectureCount) {
        if (value === "") {
          tempArr[index].addedExtraLectureCount = 0;
          getAmount(tempArr);
        } else {
          tempArr[index].addedExtraLectureCount = parseInt(value);
          getAmount(tempArr);
        }
      } else {
        tempArr[index].addedExtraLectureCount = 0;
        dispatch(
          showErrorSnackbar(
            "Please add value less than extra lectures count !!!"
          )
        );
      }
      setTopicList(tempArr);
    }
  };

  const couponValidations = () => {
    return coupon === "";
  };

  const onChangeCoupon = (value: any) => {
    setCoupon(value);
    setEmployeeName("");
  };

  const onApplyCoupon = (e: any) => {
    e.preventDefault();
    const postData = {
      coursetypeId: courseType?._id,
      offerPrice: packageOptions?.offerPrice,
      sellingPrice: packageOptions?.sellingPrice,
      couponName: coupon,
    };
    if (packageOptions?.levelId === 4) {
      postData["offerPrice"] = totalItemAmount;
      postData["sellingPrice"] = totalItemAmount;
    }
    dispatch(
      applyCoupon(
        postData,
        (res: any) => {
          setEmployeeName(res?.counsellorName);
          if (packageOptions?.levelId === 4) {
            const postData = {
              studentId: studentId,
              packages: [
                {
                  packageId: packages?._id,
                  packageOptionId: packageOptions?.packageOptionId,
                },
              ],
            };
            dispatch(
              addToCart(
                postData,
                (res: any) => {},
                () => {}
              )
            );
          }
        },
        () => {}
      )
    );
  };

  const changePaymentOptions = (value: any) => {
    setPaymentOptions(value);
    setPaymentLink("");
    setIsFirstInstallment(false);
    setIsSecondInstallment(false);
    setTotalLectureCount("");
    setFirstInstallmentPaymentPercentage(0);
    setSecondInstallmentPaymentPercentage(0);
    setThirdInstallmentPaymentPercentage(0);
    setFirstInstallmentPercentage(0);
    setSecondInstallmentPercentage(0);
    const postData: any = {
      studentId: studentId,
      couponName: coupon,
      countryId: country?._id,
      isCouponApplied: employeeName ? true : false,
    };
    if (value?._id === 2) {
      postData["ratioType"] = value?._id;
      postData["installmentOptions"] = true;
    }
    if (value?._id === 3) {
      postData["ratioType"] = value?._id;
      postData["installmentOptions"] = true;
    }
    if (packageOptions?.levelId === 4) {
      postData["customPackage"] = true;
    }
    dispatch(
      getCartList(
        postData,
        (res: any) => {
          setCartList(res);
          console?.log("installmentOptions", res);
          setTotalLectureCount(res?.cart[0]?.levelId?.offerLectureCount);
          setFirstInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment1?.paymentPercentage)
          );
          setSecondInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment2?.paymentPercentage)
          );
          setThirdInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment3?.paymentPercentage)
          );
          if (value?._id === 1) {
            setOfferPrice(res?.fullAmount);
            setSellingPrice(res?.totalsellingPrice);
          }
          if (value?._id === 2 || value?._id === 3) {
            setOfferPrice(res?.totalofferPrice);
            setSellingPrice(res?.totalsellingPrice);
          }
        },
        () => {}
      )
    );
  };

  const onPlaceOrder = (e: { preventDefault: () => void }) => {
    e.preventDefault();

    const paymentReqData: any = {
      studentId: studentId,
      mobileNo: phoneNumber,
      paymentType: paymentOptions?._id,
      packageOptionlevelId: packageOptions?.levelId,
      couponName: coupon,
      countryId: country?._id,
      isCouponApplied: employeeName ? true : false,
      customInstallmentOptions: true,
      firstInstallmentPaymentPercentage: parseInt(
        firstInstallmentPaymentPercentage
      ),
      secondInstallmentPaymentPercentage: parseInt(
        secondInstallmentPaymentPercentage
      ),
    };
    if (parseInt(thirdInstallmentPaymentPercentage)) {
      paymentReqData["thirdInstallmentPaymentPercentage"] = parseInt(
        thirdInstallmentPaymentPercentage
      );
    }
    if (paymentOptions?._id === 2) {
      paymentReqData["ratioType"] = paymentOptions?._id;
      paymentReqData["installmentOptions"] = true;
    }
    if (paymentOptions?._id === 3) {
      paymentReqData["ratioType"] = paymentOptions?._id;
      paymentReqData["installmentOptions"] = true;
    }
    console?.log("paymentReqData", paymentReqData);
    const encodedData = encodeURIComponent(JSON.stringify(paymentReqData));
    console?.log("encodedData", encodedData);
    const URL = `${process.env.REACT_APP_WEBLINK}/paymentReqData?data=${encodedData}`;
    // const URL = `http://localhost:3700/paymentReqData?data=${encodedData}`;
    setPaymentLink(URL);
    let postData: any = {
      counsellorId: userId,
      countryId: country?._id,
      studentId: studentId,
      packageId: packages?._id,
      packageOptionId: packageOptions?.packageOptionId,
      coursetypeId: courseType?._id,
      sellingPrice: sellingPrice,
      offerPrice: offerPrice,
      paymentLink: URL,
      installmentOptions: paymentOptions?._id === 1 ? false : true,
    };
    dispatch(
      savePaymentLink(
        postData,
        () => {},
        () => {}
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      studentId: studentId,
      orderNo: placeOrderData?.orderNo,
      currency: "USD",
      countryId: country?._id,
    };

    // dispatch(
    //   paymentRequest(
    //     postData,
    //     (res: any) => {},
    //     () => {}
    //   )
    // );
  };

  const onSubmitCustomizePackage = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setCoupon("");
    setEmployeeName("");
    setResetPaymentOptions(true);
    let tempData: any = topicList?.filter((item: any) => item.isSelected);
    let topicIds: any = [];
    let lectureIds: any = [];

    tempData?.map((item: any) => {
      topicIds.push(item?._id);
      item?.lectures?.map((subItem: any) => {
        if (!subItem?.extra) {
          lectureIds.push(subItem?.lectureId);
        }
      });
      if (item?.addedExtraLectureCount > 0) {
        let tempExtra = item?.lectures?.filter(
          (subItem: any) => subItem?.extra
        );
        if (tempExtra) {
          for (let i = 0; i < item?.addedExtraLectureCount; i++) {
            lectureIds.push(tempExtra[i].lectureId);
          }
        }
      }
    });
    const postData: any = {
      studentId: studentId,
      countryId: country?._id,
      packageId: packages?._id,
      packageOptionId: packageOptions?.packageOptionId,
      coursetypeId: courseType?._id,
      examtypeId: exam?._id,
      subjectId: subject?._id,
      topics: topicIds,
      lectures: lectureIds,
      sellingPrice: totalItemAmount,
      offerPrice: totalItemAmount,
      totalLectureCount: totalLectureCount,
    };
    dispatch(
      saveCustomizePackage(
        postData,
        (res: any) => {
          setCustomPackageSaved(true);
        },
        () => {}
      )
    );
  };

  const onClickIsFirstInstallment = () => {
    if (isFirstInstallment) {
      setIsFirstInstallment(false);
    } else {
      setIsFirstInstallment(true);
    }
  };

  const onClickIsSecondInstallment = () => {
    if (paymentOptions?._id === 3) {
      if (isSecondInstallment) {
        setIsSecondInstallment(false);
      } else {
        setIsSecondInstallment(true);
      }
    }
  };

  const changeFirstInstallmentPaymentPercentage = (value: any) => {
    setPaymentLink("");
    setFirstInstallmentPaymentPercentage("");
    setSecondInstallmentPaymentPercentage("");
    setThirdInstallmentPaymentPercentage("");
    if (parseInt(value) < 100) {
      setFirstInstallmentPaymentPercentage(parseInt(value));
      setFirstInstallmentPercentage(1);
      if (paymentOptions?._id === 2) {
        const total = 100 - parseInt(value);
        setSecondInstallmentPaymentPercentage(total);
      }
      if (paymentOptions?._id === 3) {
        setIsSecondInstallment(true);
      }
    }
  };

  const changeSecondInstallmentPaymentPercentage = (value: any) => {
    setPaymentLink("");
    setSecondInstallmentPaymentPercentage("");
    setThirdInstallmentPaymentPercentage("");
    if (parseInt(value) < 100) {
      setSecondInstallmentPaymentPercentage(parseInt(value));
      setSecondInstallmentPercentage(1);
      if (paymentOptions?._id === 3) {
        const total = 100 - firstInstallmentPaymentPercentage - parseInt(value);
        setThirdInstallmentPaymentPercentage(total);
      }
    }
  };

  const onCustomizeAmount = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setPaymentLink("");
    const postData: any = {
      studentId: studentId,
      couponName: coupon,
      countryId: country?._id,
      isCouponApplied: employeeName ? true : false,
      customInstallmentOptions: true,
      firstInstallmentPaymentPercentage: parseInt(
        firstInstallmentPaymentPercentage
      ),
      secondInstallmentPaymentPercentage: parseInt(
        secondInstallmentPaymentPercentage
      ),
    };
    if (parseInt(thirdInstallmentPaymentPercentage)) {
      postData["thirdInstallmentPaymentPercentage"] = parseInt(
        thirdInstallmentPaymentPercentage
      );
    }
    if (paymentOptions?._id === 2) {
      postData["ratioType"] = paymentOptions?._id;
      postData["installmentOptions"] = true;
    }
    if (paymentOptions?._id === 3) {
      postData["ratioType"] = paymentOptions?._id;
      postData["installmentOptions"] = true;
    }
    if (packageOptions?.levelId === 4) {
      postData["customPackage"] = true;
    }
    dispatch(
      getCartList(
        postData,
        (res: any) => {
          setCartList(res);
          console?.log("installmentOptions", res);
          setFirstInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment1?.paymentPercentage)
          );
          setSecondInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment2?.paymentPercentage)
          );
          setThirdInstallmentPaymentPercentage(
            parseInt(res?.installmentOptions?.installment3?.paymentPercentage)
          );
          if (paymentOptions?._id === 1) {
            setOfferPrice(res?.fullAmount);
            setSellingPrice(res?.totalsellingPrice);
          }
          if (paymentOptions?._id === 2 || paymentOptions?._id === 3) {
            setOfferPrice(res?.totalofferPrice);
            setSellingPrice(res?.totalsellingPrice);
          }
        },
        () => {}
      )
    );
  };

  console?.log("firstInstallmentPercentage", firstInstallmentPercentage);
  console?.log("secondInstallmentPercentage", secondInstallmentPercentage);

  const copyToClipboard = () => {
    navigator.clipboard
      .writeText(paymentLink)
      .then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 2000); // Hide tooltip after 2 seconds
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
      });
  };

  const getLectureCount = (value: any) => {
    let count: number = 0;
    if (value !== null) {
      count = (totalLectureCount * value) / 100;
    }
    return Math.floor(count);
  };

  return (
    <div className="contentBox">
      <PageTitle name={"GENERATE PAYMENT LINK"} />
      <div className="px-4 mx-0 mt-2 formDiv ">
        <form className="col-10 pt-2">
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Country"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Country"
                setInputText={(value: any) => onChangeCountry(value)}
                value={country}
                options={countryList}
                Isrequired={true}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Phone Number"} />
            </div>
            <div className="col-6 me-2">
              <InputField
                placeholder="Enter Phone Number"
                value={phoneNumber}
                onChangeInput={(value: { _id: String; name: String }) =>
                  onChangePhoneNumber(value)
                }
                maxlength={country?._id === 13 ? length_Eight : length_Ten}
                minlength={country?._id === 13 ? length_Eight : length_Ten}
              />
            </div>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-dark rounded-pill px-3 "
                onClick={() => onSearchMobile()}
                disabled={phoneValidations()}
              >
                Search
              </button>
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"First Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter First Name"
                value={firstName}
                onChangeInput={(value: any) => setFirstName(value)}
                disabled={studentId ? true : false}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Last Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Last Name"
                value={lastName}
                onChangeInput={(value: any) => setLastName(value)}
                disabled={studentId ? true : false}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Email ID"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Email ID"
                value={email}
                onChangeInput={(value: any) => setEmail(value)}
                disabled={studentId ? true : false}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Course"} />
            </div>
            <div className="col-6 ps-2 me-2">
              <CustomDropdown
                lableName="Select Course"
                setInputText={(value: any) => onChangeCourseType(value)}
                value={courseType}
                options={courseTypeList}
                // Isrequired={true}
                reset={resetCourseType}
              />
            </div>
            {userManualEntry ? (
              <div className="mb-2">
                <button
                  type="button"
                  className="btn btn-dark rounded-pill px-3 "
                  onClick={(e: any) => onSaveUserDetails(e)}
                  disabled={userValidations()}
                >
                  Save
                </button>
              </div>
            ) : null}
          </div>
          {courseType?.type === 2 ? (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Grade"} />
              </div>
              <div className="col-6 ps-2 ">
                <CustomDropdown
                  lableName="Select Grade"
                  setInputText={(value: any) => onChangeGrade(value)}
                  value={grade}
                  options={GradeList}
                  // Isrequired={true}
                  reset={resetGrade}
                />
              </div>
            </div>
          ) : null}
          {courseType?.type === 2 || courseType?.type === 3 ? null : (
            <div className="d-flex Justify-content-between">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Exam"} />
              </div>
              <div className="col-6 ps-2">
                <CustomDropdown
                  lableName="Select Exam"
                  setInputText={(value: any) => onChangeExam(value)}
                  value={exam}
                  options={examList}
                  // Isrequired={true}
                  reset={resetExam}
                />
              </div>
            </div>
          )}

          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Subject"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Subject"
                setInputText={(value: any) => onChangeSubject(value)}
                value={subject}
                options={subjectList}
                // Isrequired={true}
                reset={resetSubject}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Package"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Package"
                setInputText={(value: any) => onChangePackage(value)}
                value={packages}
                options={packageList}
                // Isrequired={true}
                reset={resetPackages}
              />
            </div>
          </div>
          <div className="d-flex Justify-content-between">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Package Options"} />
            </div>
            <div className="col-6 ps-2 ">
              <CustomDropdown
                lableName="Select Package Options"
                setInputText={(value: any) => onChangePackageOptions(value)}
                value={packageOptions}
                options={packageOptionsList}
                // Isrequired={true}
                reset={resetPackageOptions}
              />
            </div>
          </div>
          {topicList?.length > 0 ? (
            <div className="d-flex Justify-content-between ">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Customized"} />
              </div>
              <div className="col-12 border rounded">
                <div className="d-flex mx-0 row justify-content-between input-border mb-2">
                  {topicList.map((item: any, index: any) => {
                    const {
                      _id,
                      name,
                      lectures,
                      lectureCount,
                      extraLectureCount,
                      viewDetails,
                      isSelected,
                      addedExtraLectureCount,
                    } = item;
                    return (
                      <div key={item?._id} className="d-flex col-6 p-2">
                        <div className="w-100 border rounded shadow p-2">
                          <div className="d-flex justify-content-between">
                            <div className="d-flex e-input align-items-center ms-3">
                              <input
                                id={_id}
                                className="check"
                                type="checkbox"
                                checked={isSelected}
                                onChange={() => {
                                  onChangeDetails(index, "onSelectTopic", "");
                                }}
                              />
                              <span className="checkVal ms-3">{name}</span>
                            </div>
                            <div className="mb-2 ">
                              <button
                                type="button"
                                className={`btn btn-link btn-sm fw-bold ${
                                  !viewDetails ? "text-success" : "text-danger"
                                }`}
                                onClick={() =>
                                  onChangeDetails(index, "onClickView", "")
                                }
                              >
                                {!viewDetails ? "View Details" : "Less Details"}
                              </button>
                            </div>
                          </div>
                          {viewDetails
                            ? lectures.map((subItem: any, subIndex: any) => {
                                const {
                                  lectureId,
                                  lectureName,
                                  levelId,
                                  extra,
                                } = subItem;
                                return (
                                  <div
                                    key={subItem?._id}
                                    className="d-flex center p-0"
                                  >
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="d-flex e-input align-items-center ms-3">
                                          <span className="checkVal ms-3">
                                            {subIndex + 1}. {lectureName}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })
                            : null}
                          <div className="d-flex justify-content-between">
                            <div className="col-4 d-flex align-items-center">
                              <div>
                                <div className="checkVal ms-3">
                                  Lectures : {lectureCount}
                                </div>
                                <div className="checkVal ms-3">
                                  Extra Lectures : {extraLectureCount}
                                </div>
                              </div>
                            </div>

                            <div className="d-flex Justify-content-between">
                              <div className="d-flex align-item-center col-8">
                                <LabelField
                                  lableName={"Additional Extra Classes"}
                                />
                              </div>
                              <div className="col-4">
                                <InputField
                                  placeholder="Add Extra Classes"
                                  value={addedExtraLectureCount}
                                  onChangeInput={(value: any) =>
                                    onChangeDetails(
                                      index,
                                      "addedExtraLectureCount",
                                      value
                                    )
                                  }
                                  disabled={!isSelected}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                  <div className="p-2">
                    <div className="d-flex justify-content-between border rounded shadow p-2">
                      <div className="text-dark fw-bold ms-3">
                        <div className="text-dark fw-bold ms-3">
                          Total Amount
                        </div>
                        <div className="text-primary fw-bold ms-3">
                          {getSymbolFromCurrency(country?.currency)}
                          {totalItemAmount}{" "}
                          <span className="text-secondary">
                            ({totalItemCount} Item)
                          </span>
                        </div>
                      </div>
                      <div className="fw-bold ms-3">
                        <button
                          type="button"
                          className="btn btn-dark rounded-pill px-3"
                          onClick={(e: any) => onSubmitCustomizePackage(e)}
                          disabled={totalItemCount > 0 ? false : true}
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
          <div className="d-flex Justify-content-between mt-2">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Coupon"} />
            </div>
            <div className="col-6 me-2">
              <InputField
                placeholder="Enter Coupon"
                value={coupon}
                onChangeInput={(value: any) => onChangeCoupon(value)}
                disabled={
                  packageOptions?.levelId === 4 && !customPackageSaved
                    ? true
                    : false
                }
              />
            </div>
            <div className="mb-2">
              <button
                type="button"
                className="btn btn-dark rounded-pill px-3"
                onClick={(e: any) => onApplyCoupon(e)}
                disabled={couponValidations()}
              >
                Apply Coupon
              </button>
            </div>
          </div>
          <div className="d-flex Justify-content-between mb-1">
            <div className="col-2 align-item-center d-flex">
              <LabelField lableName={"Employee Name"} />
            </div>
            <div className="col-6">
              <InputField
                placeholder="Enter Employee Name"
                value={employeeName}
                onChangeInput={(value: any) => setEmployeeName(value)}
                disabled={true}
              />
            </div>
          </div>
          {employeeName ? (
            <>
              <div className="d-flex Justify-content-between">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Payment Option"} />
                </div>
                <div className="col-6 ps-2 ">
                  <CustomDropdown
                    lableName="Select Payment Option"
                    setInputText={(value: any) => changePaymentOptions(value)}
                    value={paymentOptions}
                    options={paymentOptionsList}
                    reset={resetPaymentOptions}
                    // Isrequired={true}
                    // disabled={placeOrderData ? true : false}
                  />
                </div>
              </div>
              {paymentOptions ? (
                <div className="d-flex Justify-content-between">
                  <div className="col-2 align-item-center d-flex">
                    <LabelField lableName={"Cart"} />
                  </div>
                  <div
                    className={
                      "rounded p-2 font12 mb-3 installementCard w-50 active me-2"
                    }
                  >
                    <div className="d-flex fw-bold mb-2">
                      <input
                        type="radio"
                        className="me-2"
                        name="installment"
                        checked={
                          paymentOptions?._id === 1 ||
                          paymentOptions?._id === 2 ||
                          paymentOptions?._id === 3
                            ? true
                            : false
                        }
                      />
                      {paymentOptions?._id === 1 ? (
                        <>
                          Full Payment
                          {cartList?.isApplicableForInstallment ? (
                            <div className="text-success fw-bold ms-3">
                              ( Saved Extra{" "}
                              {getSymbolFromCurrency(country?.currency)}
                              {parseFloat(cartList?.additionalAmount).toFixed(
                                2
                              )}{" "}
                              ){" "}
                            </div>
                          ) : null}
                        </>
                      ) : (
                        <>{`Pay fee in ${paymentOptions?._id} EMI`}</>
                      )}
                    </div>
                    {paymentOptions?._id === 1 ? (
                      <div className="mt-2 ms-4 px-2 py-2 rounded border-primary rounded border">
                        <p className="mb-1 fw-bold">
                          Total Payable Amount:{" "}
                          <span className="text-success fw-bold">
                            {getSymbolFromCurrency(country?.currency)}
                            {cartList?.fullAmount
                              ? parseFloat(cartList?.fullAmount).toFixed(2)
                              : 0}
                          </span>
                        </p>
                        <p className="mb-0 font10">Including all Taxes</p>
                      </div>
                    ) : null}
                    {(paymentOptions?._id === 2 || paymentOptions?._id === 3) &&
                    cartList?.isApplicableForInstallment ? (
                      <>
                        <div className="mt-2 ms-4">
                          <p className="mb-1 fw-bold mt-1">
                            Total Payable Amount:{" "}
                            <span className="successcolor fw-bold ms-2">
                              {getSymbolFromCurrency(country?.currency)}
                              {cartList?.netAmount
                                ? parseFloat(cartList?.netAmount).toFixed(2)
                                : 0}
                            </span>
                          </p>
                          <div className="px-2 py-2 rounded w-100 border-primary rounded border">
                            <p className="mb-1 fw-bold">
                              1st Installment
                              <span className="successcolor fw-bold ms-2">
                                {getSymbolFromCurrency(country?.currency)}{" "}
                                {cartList?.installmentOptions?.installment1
                                  ? parseFloat(
                                      cartList?.installmentOptions?.installment1
                                        ?.firstInstallment
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <div className="d-flex justify-content-between">
                              <p className="mb-0 font10">Pay by Today</p>
                              <div className="d-flex Justify-content-between w-50">
                                <div className="col-4 align-item-center d-flex ">
                                  <LabelField lableName={"Payment Ratio"} />
                                </div>
                                <div className="col-6">
                                  <InputField
                                    placeholder="Enter Employee Name"
                                    value={firstInstallmentPaymentPercentage}
                                    onChangeInput={(value: any) =>
                                      changeFirstInstallmentPaymentPercentage(
                                        value
                                      )
                                    }
                                    disabled={
                                      !isFirstInstallment ? true : false
                                    }
                                  />
                                </div>
                                <div className="col-2 d-flex e-input align-items-center ms-3">
                                  <input
                                    className="check"
                                    type="checkbox"
                                    checked={!isFirstInstallment}
                                    onChange={() => {
                                      onClickIsFirstInstallment();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="px-2 py-2 rounded border mt-2 w-100">
                            <p className="mb-1 fw-bold">
                              2nd Installment{" "}
                              <span className="successcolor fw-bold ms-2">
                                {getSymbolFromCurrency(country?.currency)}{" "}
                                {cartList?.installmentOptions?.installment2
                                  ? parseFloat(
                                      cartList?.installmentOptions?.installment2
                                        ?.secondInstallment
                                    ).toFixed(2)
                                  : 0}
                              </span>
                            </p>
                            <div className="d-flex justify-content-between align-item-center">
                              <div>
                                <p className="mb-0 font10">
                                  pay before{" "}
                                  {getLectureCount(
                                    cartList?.installmentOptions?.installment2
                                      ?.secondLectureRatio
                                  )}{" "}
                                  lecture
                                </p>
                              </div>
                              <div className="d-flex Justify-content-between w-50">
                                <div className="col-4 align-item-center d-flex">
                                  <LabelField lableName={"Payment Ratio"} />
                                </div>
                                <div className="col-6">
                                  <InputField
                                    placeholder="Enter Employee Name"
                                    value={secondInstallmentPaymentPercentage}
                                    onChangeInput={(value: any) =>
                                      changeSecondInstallmentPaymentPercentage(
                                        value
                                      )
                                    }
                                    disabled={
                                      !isSecondInstallment ? true : false
                                    }
                                  />
                                </div>
                                <div className="col-2 d-flex e-input align-items-center ms-3">
                                  <input
                                    className="check"
                                    type="checkbox"
                                    checked={!isSecondInstallment}
                                    onChange={() => {
                                      onClickIsSecondInstallment();
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          {paymentOptions?._id === 3 &&
                          cartList?.isApplicableForInstallment ? (
                            <div className="px-2 py-2 rounded border mt-2 w-100">
                              <p className="mb-1 fw-bold">
                                3rd Installment{" "}
                                <span className="successcolor fw-bold ms-2">
                                  {getSymbolFromCurrency(country?.currency)}{" "}
                                  {cartList?.installmentOptions?.installment3
                                    ? parseFloat(
                                        cartList?.installmentOptions
                                          ?.installment3?.thirdInstallment
                                      ).toFixed(2)
                                    : 0}
                                </span>
                              </p>
                              <div className="d-flex justify-content-between align-item-center">
                                <div>
                                  <p className="mb-0 font10">
                                    pay before{" "}
                                    {getLectureCount(
                                      cartList?.installmentOptions?.installment3
                                        ?.thirdLectureRatio
                                    )}{" "}
                                    lecture
                                  </p>
                                </div>
                                <div className="d-flex Justify-content-between w-50">
                                  <div className="col-4 align-item-center d-flex">
                                    <LabelField lableName={"Payment Ratio"} />
                                  </div>
                                  <div className="col-6">
                                    <InputField
                                      placeholder="Enter Employee Name"
                                      value={thirdInstallmentPaymentPercentage}
                                      onChangeInput={(value: any) =>
                                        setThirdInstallmentPaymentPercentage(
                                          value
                                        )
                                      }
                                      disabled={
                                        paymentOptions?._id === 3 ? true : false
                                      }
                                    />
                                  </div>
                                  <div className="col-2 d-flex e-input align-items-center ms-3">
                                    <input
                                      className="check"
                                      type="checkbox"
                                      checked={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ) : null}
                        </div>
                      </>
                    ) : null}
                    {paymentOptions &&
                    (firstInstallmentPercentage === 1 ||
                      secondInstallmentPercentage === 1) ? (
                      <div className="d-flex justify-content-end mt-2">
                        <button
                          type="button"
                          className="btn btn-dark"
                          onClick={(e: any) => onCustomizeAmount(e)}
                        >
                          Get Amount
                        </button>
                      </div>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="d-flex Justify-content-between mb-1">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Payment Link"} />
                </div>
                <div className="col-6 me-2">
                  <InputField
                    placeholder="Enter Payment Link"
                    value={paymentLink}
                    onChangeInput={(value: any) => setPaymentLink(value)}
                    disabled={true}
                  />
                </div>
                {paymentLink ? (
                  <div className="mb-2">
                    <div
                      className="d-flex justify-content-center mt-0"
                      style={{ position: "relative" }}
                    >
                      <button
                        type="button"
                        className="btn btn-dark"
                        onClick={copyToClipboard}
                      >
                        Copy Link
                      </button>
                      {copied && (
                        <span
                          style={{
                            position: "absolute",
                            top: "-30px",
                            left: "50%",
                            transform: "translateX(-50%)",
                            backgroundColor: "black",
                            color: "white",
                            padding: "5px 10px",
                            borderRadius: "4px",
                            fontSize: "12px",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Link Copied!
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  <button
                    type="button"
                    className="btn btn-dark"
                    onClick={(e: any) => onPlaceOrder(e)}
                    disabled={paymentLink ? true : false}
                  >
                    Generate Link
                  </button>
                )}
              </div>
            </>
          ) : null}
        </form>
      </div>
    </div>
  );
}
