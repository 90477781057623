import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import {
  addPattern,
  addScholorshipPattern,
  getCalculatorTypes,
  getGeneralCourseList,
  getGeneralExamList,
} from "../../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import "../../App.css";
import CustomDropdown from "../../Components/customDropdown";
import ToggleSwitchbtn from "../../Components/toggleSwitchBtn";
import Addmorebtn from "../../Components/addmorebtn";
import DeleteIcon from "@mui/icons-material/Delete";
import {
  length_Fifty,
  length_Four,
  length_Hundred,
  length_One,
  length_Six,
  length_Three,
  length_Two,
  startsWithSpace,
  validateDigitOnly,
  validateIsLettersAndDigitsSpaceOnly,
  validateMinMaxMarks,
} from "../../Utils/validators";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";

export default function CreateScholershipPattern() {
  const initialPart = {
    questionRangeFrom: 0,
    questionRangeTo: 0,
    totalQuestion: 0,
    correctMarks: 0,
    negativeMarks: 0,
  };

  const initialVal = {
    sectionName: "",
    compulsoryFlag: false,
    totalQuestion: 0,
    maxAttempt: 0,
    createExamPatternParts: [initialPart],
    time: "",
    questionType: "",
  };
  const dispatch: Function = useDispatch();
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [exam, setExam] = useState<any>("");
  const [examList, setExamList] = useState([]);
  const [modelSet, setModelSet] = useState(true);
  const [totalQuestions, setTotalQuestions] = useState<any>(0);
  const [patternName, setPatternName] = useState<String>("");
  const [calculator, setCalculator] = useState<any>("");
  const [calculatorList, setCalculatorList] = useState([]);
  const [resetCalculator, setResetCalculator] = useState(false);
  const [resetParentCourse, setResetParentCourse] = useState(false);
  const [resetExam, setResetExam] = useState(false);
  const [sectionArray, setSectionArray] = useState<any>([initialVal]);
  useEffect(() => {
    dispatch(
      getGeneralCourseList(
        {},
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  }, []);

  const clearData = () => {
    setCourse("");
    setExam("");
    setCalculator("");
    setSectionArray([initialVal]);
    setModelSet(false);
    setTotalQuestions(0);
    setPatternName("");
    setExamList([]);
    setCalculatorList([]);
    setResetParentCourse(true);
    setResetCalculator(true);
    setResetExam(true);
  };

  const getModalsetData = (
    sectionArray: {
      sectionName: string;
      createExamPatternParts: { correctMarks: number; negativeMarks: number }[];
    }[]
  ) => {
    const localArray = sectionArray.map(
      (item: {
        sectionName: string;
        createExamPatternParts: {
          correctMarks: number;
          negativeMarks: number;
        }[];
      }) => {
        return {
          sectionName: item?.sectionName,
          createExamPatternParts: item?.createExamPatternParts.map(
            (data: { correctMarks: number; negativeMarks: number }) => {
              return {
                correctMarks: data?.correctMarks,
                negativeMarks: data?.negativeMarks,
              };
            }
          ),
        };
      }
    );
    return localArray;
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    const postData: any = {
      coursetypeId: course._id,
      examtypeId: exam._id,
      modelSet: modelSet,
      createExamPatternChild:
        modelSet === true ? sectionArray : getModalsetData(sectionArray),
    };
    if (modelSet === true) {
      postData["totalQuestion"] = totalQuestions;
    } else {
      postData["examPatternName"] = patternName;
    }
    if (calculator !== "") {
      postData["calculatorTypeId"] = calculator._id;
    }
    dispatch(
      addScholorshipPattern(
        postData,
        (res: any) => {
          if (res) clearData();
        },
        () => { }
      )
    );
  };

  const onChangeCourse = (value: any) => {
    setCourse(value);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
  };

  const onChangeExam = (value: any) => {
    setExam(value);
    dispatch(
      getCalculatorTypes(
        "",
        (res: any) => {
          setCalculatorList(res);
        },
        () => { }
      )
    );
  };

  const changeModelToggle = (value: any) => {
    if (value.target.checked) {
      setModelSet(true);
    } else {
      setModelSet(false);
    }
  };

  const onPressAddPart = (index: number) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    localArray[index].createExamPatternParts.push(initialPart);
    setSectionArray(localArray);
  };

  const onPressAddSection = () => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    localArray.push(initialVal);
    setSectionArray(localArray);
  };

  const handleCloseSection = (index: any) => {
    const newArrr = [...sectionArray];
    if (index > -1) {
      newArrr.splice(index, 1);
    }
    setSectionArray(newArrr);
  };

  const handleClosePart = (index: any, partIndex: number) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    if (index > -1) {
      localArray[index].createExamPatternParts.splice(partIndex, 1);
    }
    setSectionArray(localArray);
  };

  const validatePartsArray = (
    partsData: {
      questionRangeFrom: number;
      questionRangeTo: number;
      totalQuestion: number;
      correctMarks: number;
      negativeMarks: number;
    }[]
  ) => {
    let partsFlag = false;
    partsData.map(
      (data: {
        questionRangeFrom: number;
        questionRangeTo: number;
        totalQuestion: number;
        correctMarks: number;
        negativeMarks: number;
      }) => {
        if (
          data.questionRangeFrom == 0 ||
          data.questionRangeTo == 0 ||
          data.totalQuestion == 0 ||
          data.correctMarks == 0
        ) {
          return (partsFlag = true);
        } else {
          return (partsFlag = false);
        }
      }
    );
    return partsFlag;
  };

  const validateSectionArray = () => {
    let sectionFlag = false;
    sectionArray.map(
      (item: {
        sectionName: string;
        totalQuestion: number;
        maxAttempt: number;
        createExamPatternParts: {
          questionRangeTo: number;
          questionRangeFrom: number;
          totalQuestion: number;
          correctMarks: number;
          negativeMarks: number;
        }[];
      }) => {
        if (
          item.sectionName == "" ||
          item.totalQuestion == 0 ||
          item.maxAttempt == 0 ||
          validatePartsArray(item.createExamPatternParts)
        ) {
          return (sectionFlag = true);
        } else {
          return (sectionFlag = false);
        }
      }
    );
    return sectionFlag;
  };

  const validateParts = (
    partsData: {
      totalQuestion: number;
      correctMarks: number;
      negativeMarks: number;
    }[]
  ) => {
    let partsFlag = false;
    partsData.map(
      (data: {
        totalQuestion: number;
        correctMarks: number;
        negativeMarks: number;
      }) => {
        if (data.correctMarks == 0) {
          return (partsFlag = true);
        } else {
          return (partsFlag = false);
        }
      }
    );
    return partsFlag;
  };

  const validateSection = () => {
    let sectionFlag = false;
    sectionArray.map(
      (item: {
        sectionName: string;
        totalQuestion: number;
        maxAttempt: number;
        createExamPatternParts: {
          questionRangeFrom: number;
          questionRangeTo: number;
          totalQuestion: number;
          correctMarks: number;
          negativeMarks: number;
        }[];
      }) => {
        if (
          item.sectionName == "" ||
          validateParts(item.createExamPatternParts)
        ) {
          return (sectionFlag = true);
        } else {
          return (sectionFlag = false);
        }
      }
    );
    return sectionFlag;
  };

  function validation() {
    return (
      course === "" ||
      exam === "" ||
      (modelSet === true && parseInt(totalQuestions) == 0) ||
      (modelSet === false && patternName?.length < length_Two) ||
      (modelSet === true && validateSectionArray()) ||
      (modelSet === false && validateSection())
    );
  }

  const onChangeTotalQuestion = (value: number) => {
    if (validateDigitOnly(value) && !startsWithSpace(value)) {
      setTotalQuestions(value);
    } else {
      dispatch(showErrorSnackbar("Please enter digits only"));
      return;
    }
  };
  const onChangePatternName = (value: String) => {
    if (validateIsLettersAndDigitsSpaceOnly(value) && !startsWithSpace(value)) {
      setPatternName(value);
    } else {
      dispatch(showErrorSnackbar("Please enter only Character & Number"));
      return;
    }
  };

  const getSectionTotalCount = (array: string | any[], value: number) => {
    let sum = 0;
    for (let i = 0; i < array.length; i++) {
      sum += array[i].totalQuestion || 0;
    }
    if (sum > value) {
      return (
        <h6 className="text-danger">
          Section count is exceded by {sum - value}
        </h6>
      );
    } else {
      return null;
    }
  };

  const getTotalPartCount = (sectionArray: any, partArray: any[]) => {
    let partSum = 0;
    let sectionSum = sectionArray?.totalQuestion;
    for (let i = 0; i < partArray.length; i++) {
      partSum += parseInt(partArray[i].totalQuestion || 0);
    }
    if (partSum > sectionSum) {
      return (
        <h6 className="text-danger">
          Part count is exceded by {partSum - sectionSum}
        </h6>
      );
    } else {
      return null;
    }
  };

  const getPartCount = (partArray: any[]) => {
    let partSum = 0;
    for (let i = 0; i < partArray.length; i++) {
      partSum += parseInt(partArray[i].totalQuestion || 0);
    }
    return partSum;
  };

  const onChangeSectionName = (
    value: any | number,
    index: number,
    type: string,
    partIndex: number
  ) => {
    let localArray = JSON.parse(JSON.stringify(sectionArray));
    if (type === "name") {
      localArray[index].sectionName = value;
      if (
        validateIsLettersAndDigitsSpaceOnly(value) &&
        !startsWithSpace(value)
      ) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter only Character & Number"));
        return;
      }
    } else if (type === "totalQuestion") {
      localArray[index].totalQuestion = value;
      if (validateDigitOnly(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    } else if (type == "maxAttempt") {
      localArray[index].maxAttempt = value;
      if (validateDigitOnly(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    } else if (type == "compulsoryFlag") {
      localArray[index].compulsoryFlag = value.target.checked;
      setSectionArray(localArray);
    } else if (type == "totalQuestionPart") {
      localArray[index].createExamPatternParts[partIndex].totalQuestion = value;
      if (validateDigitOnly(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
      if (index === 0) {
        if (partIndex === 0) {
          localArray[index].createExamPatternParts[
            partIndex
          ].questionRangeFrom = value ? partIndex + 1 : 0;
          localArray[index].createExamPatternParts[partIndex].questionRangeTo =
            value ? value : 0;
        } else {
          localArray[index].createExamPatternParts[
            partIndex
          ].questionRangeFrom = value
              ? parseInt(
                localArray[index].createExamPatternParts[partIndex - 1]
                  .questionRangeTo
              ) + 1
              : 0;
          localArray[index].createExamPatternParts[partIndex].questionRangeTo =
            value
              ? parseInt(
                localArray[index].createExamPatternParts[partIndex - 1]
                  .questionRangeTo
              ) + parseInt(value)
              : 0;
        }
      } else {
        if (partIndex === 0) {
          localArray[index].createExamPatternParts[
            partIndex
          ].questionRangeFrom = value
              ? parseInt(
                localArray[index - 1].createExamPatternParts[
                  localArray[index - 1].createExamPatternParts?.length - 1
                ].questionRangeTo
              ) + 1
              : 0;
          localArray[index].createExamPatternParts[partIndex].questionRangeTo =
            value
              ? parseInt(
                localArray[index - 1].createExamPatternParts[
                  localArray[index - 1].createExamPatternParts?.length - 1
                ].questionRangeTo
              ) + parseInt(value)
              : 0;
        } else {
          localArray[index].createExamPatternParts[
            partIndex
          ].questionRangeFrom = value
              ? parseInt(
                localArray[index].createExamPatternParts[partIndex - 1]
                  .questionRangeTo
              ) + 1
              : 0;
          localArray[index].createExamPatternParts[partIndex].questionRangeTo =
            value
              ? parseInt(
                localArray[index].createExamPatternParts[partIndex - 1]
                  .questionRangeTo
              ) + parseInt(value)
              : 0;
        }
      }
    } else if (type == "correctMarks") {
      localArray[index].createExamPatternParts[partIndex].correctMarks = value;
      if (validateMinMaxMarks(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    } else if (type == "negativeMarks") {
      localArray[index].createExamPatternParts[partIndex].negativeMarks = value;
      if (validateMinMaxMarks(value) && !startsWithSpace(value)) {
        setSectionArray(localArray);
      } else {
        dispatch(showErrorSnackbar("Please enter digits only"));
        return;
      }
    } else if (type == "questionType") {
      localArray[index].questionType = value;
      setSectionArray(localArray);
    } else if (type === "time") {
      localArray[index].time = value;
      setSectionArray(localArray);
    }
  };
  const qtype = [
    {
      _id: 1,
      name: "MCQ",
    },
    {
      _id: 4,
      name: "CONTENT",
    },
  ];
  return (
    <div className="contentBox">
      <PageTitle name={"CREATE SCHOLERSHIP PATTERN"} />
      <div className=" px-4 mx-0 mt-4 formDiv ">
        <form className=" pt-2" onSubmit={handleSubmit}>
          <div className="col-12 d-flex flex-wrap">
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Course type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourse(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetParentCourse}
                />
              </div>
            </div>
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className=" col-2 align-item-center d-flex">
                <LabelField lableName={"Exam Name"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Exam Name"
                  value={exam}
                  options={examList}
                  Isrequired={true}
                  reset={resetExam}
                  setInputText={(value: { _id: string; name: string }) =>
                    onChangeExam(value)
                  }
                />
              </div>
            </div>
            {modelSet === true ? (
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Total Questions"} />
                </div>
                <div className="col-8 pe-3">
                  <InputField
                    placeholder="Enter Total Questions"
                    value={totalQuestions}
                    onChangeInput={(value: number) =>
                      onChangeTotalQuestion(value)
                    }
                    maxlength={length_Three - 1}
                    minlength={length_One}
                    onFocus={(e: { target: { select: () => any } }) =>
                      e.target.select()
                    }
                  />
                </div>
              </div>
            ) : (
              <div className="col-6 d-flex mb-2 align-items-center">
                <div className="col-2 align-item-center d-flex">
                  <LabelField lableName={"Exam Pattern Name"} />
                </div>
                <div className="col-8 ">
                  <InputField
                    placeholder="Enter Exam Pattern Name"
                    value={String(patternName)}
                    onChangeInput={(value: String) =>
                      onChangePatternName(value)
                    }
                    maxlength={length_Hundred - 1}
                    minlength={length_Four}
                  />
                </div>
              </div>
            )}
            <div className="col-6 d-flex mb-2 align-items-center">
              <div className="col-2 align-item-center d-flex">
                <LabelField lableName={"Calculator Type"} />
              </div>
              <div className="col-8 ms-2">
                <CustomDropdown
                  lableName="Select Calculator Type"
                  setInputText={(value: any) => setCalculator(value)}
                  value={calculator}
                  options={calculatorList}
                  reset={resetCalculator}
                  Isrequired={true}
                />
              </div>
            </div>
          </div>
          <p className="fw-bold">Section Creation</p>
          {sectionArray.map(
            (
              item: {
                sectionName: string;
                compulsoryFlag: boolean;
                totalQuestion: number;
                maxAttempt: number;
                createExamPatternParts: any;
                time: string;
                questionType: any;
              },
              index: number
            ) => {
              const {
                sectionName,
                compulsoryFlag,
                totalQuestion,
                maxAttempt,
                createExamPatternParts,
                time,
                questionType,
              } = item;
              return (
                <div className="d-flex">
                  <div className="col-11 border p-3 mt-2  ">
                    <div className="d-flex flex-wrap">
                      <div className="col-6 d-flex mb-2 align-items-center">
                        <div className="col-2 align-item-center d-flex">
                          <LabelField
                            lableName={"Section Name " + (index + 1)}
                          />
                        </div>
                        <div className="col-8 ">
                          <InputField
                            placeholder={
                              "Enter Section Name (Optional)" + (index + 1)
                            }
                            value={sectionName}
                            onChangeInput={(value: string) =>
                              onChangeSectionName(value, index, "name", 0)
                            }
                            maxlength={length_Fifty - 1}
                            minlength={length_Three - 1}
                          />
                        </div>
                      </div>
                      {modelSet === true ? (
                        <>
                          <div className="col-6 d-flex mb-2 align-items-center">
                            <div className="col-2 align-item-center d-flex">
                              <LabelField lableName={"Compulsory Flag"} />
                            </div>
                            <div className="col-8 ms-2 removelable">
                              <ToggleSwitchbtn
                                id={String(index)}
                                getToggleval={(value: any) =>
                                  onChangeSectionName(
                                    value,
                                    index,
                                    "compulsoryFlag",
                                    0
                                  )
                                }
                                isActive={compulsoryFlag}
                              />
                            </div>
                          </div>
                          <div className="col-6 d-flex mb-2 align-items-center">
                            <div className="col-2 align-item-center d-flex">
                              <LabelField lableName={"Total Question"} />
                            </div>
                            <div className="col-8 ">
                              <InputField
                                placeholder={
                                  "Enter Total Question For Section  " +
                                  (index + 1)
                                }
                                value={totalQuestion}
                                onChangeInput={(value: any) =>
                                  onChangeSectionName(
                                    value,
                                    index,
                                    "totalQuestion",
                                    0
                                  )
                                }
                                maxlength={length_Three - 1}
                                minlength={length_One}
                                onFocus={(e: {
                                  target: { select: () => any };
                                }) => e.target.select()}
                              />
                              {getSectionTotalCount(
                                sectionArray,
                                totalQuestions
                              )}
                            </div>
                          </div>
                          <div className="col-6 d-flex mb-2 align-items-center">
                            <div className="col-2 align-item-center d-flex">
                              <LabelField lableName={"Max Attempt"} />
                            </div>
                            <div className="col-8 ">
                              <InputField
                                placeholder={
                                  "Enter Max Attempt For Section  " +
                                  (index + 1)
                                }
                                value={maxAttempt}
                                onChangeInput={(value: any) =>
                                  onChangeSectionName(
                                    value,
                                    index,
                                    "maxAttempt",
                                    0
                                  )
                                }
                                maxlength={length_Three - 1}
                                minlength={length_One}
                                onFocus={(e: {
                                  target: { select: () => any };
                                }) => e.target.select()}
                              />
                              {maxAttempt - totalQuestion > 0 &&
                                maxAttempt > totalQuestion ? (
                                <h6 className="text-danger">
                                  Max attempt exceded by{" "}
                                  {maxAttempt - totalQuestion}{" "}
                                </h6>
                              ) : null}
                            </div>
                          </div>
                        </>
                      ) : null}
                      <div className="col-6 d-flex mb-2 align-items-center">
                        <div className="col-2 align-item-center d-flex">
                          <LabelField lableName={"Time"} />
                        </div>
                        <div className="col-8 ">
                          <InputField
                            placeholder={"Enter Time in minutes"}
                            value={time}
                            onChangeInput={(value: any) =>
                              onChangeSectionName(value, index, "time", 0)
                            }
                            maxlength={3}
                            minlength={length_One}
                            onFocus={(e: { target: { select: () => any } }) =>
                              e.target.select()
                            }
                          />
                        </div>
                      </div>
                      <div className="col-6 d-flex mb-2 align-items-center">
                        <div className="col-2 align-item-center d-flex">
                          <LabelField lableName={"Question Type"} />
                        </div>
                        <div className="col-8 ms-2">
                          <CustomDropdown
                            lableName="Select Question Type"
                            setInputText={(value: any) =>
                              onChangeSectionName(
                                value?._id,
                                index,
                                "questionType",
                                0
                              )
                            }
                            value={questionType}
                            options={qtype}
                            reset={resetCalculator}
                            Isrequired={true}
                          />
                        </div>
                      </div>
                    </div>

                    {createExamPatternParts.map(
                      (
                        data: {
                          correctMarks: number;
                          negativeMarks: number;
                          totalQuestion: number;
                          questionRangeFrom: string;
                          questionRangeTo: string;
                        },
                        partIndex: number
                      ) => {
                        let {
                          correctMarks,
                          negativeMarks,
                          totalQuestion,
                          questionRangeFrom,
                          questionRangeTo,
                        } = data;
                        return (
                          <>
                            <p className="fw-bold">
                              {"Part " + (partIndex + 1)}{" "}
                            </p>
                            <div className="d-flex p-2 p-3">
                              <div className="col-12 border p-2 mt-2">
                                <div>
                                  {modelSet === true ? (
                                    <div className="col-6 d-flex mb-2 align-items-center">
                                      <div className="col-2 align-item-center d-flex">
                                        <LabelField
                                          lableName={"Total Question"}
                                        />
                                      </div>
                                      <div className="col-10 d-flex">
                                        <div className="col-5">
                                          <InputField
                                            placeholder={
                                              "Enter Total Question For Part " +
                                              (partIndex + 1)
                                            }
                                            value={totalQuestion}
                                            onChangeInput={(value: any) =>
                                              onChangeSectionName(
                                                value,
                                                index,
                                                "totalQuestionPart",
                                                partIndex
                                              )
                                            }
                                            maxlength={length_Three - 1}
                                            minlength={length_One}
                                            onFocus={(e: {
                                              target: { select: () => any };
                                            }) => e.target.select()}
                                          />
                                          {getTotalPartCount(
                                            item,
                                            createExamPatternParts
                                          )}
                                        </div>
                                        <InputField
                                          placeholder="Question Range"
                                          value={`From: ${questionRangeFrom} - To: ${questionRangeTo}`}
                                          onChangeInput={(value: any) => { }}
                                          maxlength={length_Six - 1}
                                          minlength={length_One}
                                          disabled
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                                <div className="d-flex">
                                  <div className="col-6 d-flex mb-2 align-items-center">
                                    <div className="col-2 align-item-center d-flex">
                                      <LabelField lableName={"Correct Marks"} />
                                    </div>
                                    <div className="col-8 ">
                                      <InputField
                                        placeholder={
                                          "Enter Correct Marks For Part " +
                                          (partIndex + 1)
                                        }
                                        value={correctMarks}
                                        onChangeInput={(value: any) =>
                                          onChangeSectionName(
                                            value,
                                            index,
                                            "correctMarks",
                                            partIndex
                                          )
                                        }
                                        maxlength={length_Three - 1}
                                        minlength={length_One}
                                        onFocus={(e: {
                                          target: { select: () => any };
                                        }) => e.target.select()}
                                      />
                                    </div>
                                  </div>
                                  <div className="col-6 d-flex mb-2 align-items-center">
                                    <div className="col-2 align-item-center d-flex">
                                      <LabelField
                                        lableName={"Negative Marks"}
                                      />
                                    </div>
                                    <div className="col-8">
                                      <InputField
                                        placeholder={
                                          "Enter Negative Marks For Part " +
                                          (partIndex + 1)
                                        }
                                        value={negativeMarks}
                                        onChangeInput={(value: any) =>
                                          onChangeSectionName(
                                            value,
                                            index,
                                            "negativeMarks",
                                            partIndex
                                          )
                                        }
                                        maxlength={length_Four - 1}
                                        minlength={length_One}
                                        onFocus={(e: {
                                          target: { select: () => any };
                                        }) => e.target.select()}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {createExamPatternParts.length === 1 ? null : (
                                <DeleteIcon
                                  className="cursor"
                                  color="error"
                                  fontSize="small"
                                  onClick={() =>
                                    handleClosePart(index, partIndex)
                                  }
                                />
                              )}
                            </div>
                          </>
                        );
                      }
                    )}
                    <div className="d-flex justify-content-end pt-2">
                      <Addmorebtn
                        disabled={
                          modelSet === true &&
                          getPartCount(createExamPatternParts) >= totalQuestion
                        }
                        name="+ Add More"
                        handleClick={() => onPressAddPart(index)}
                      />
                    </div>
                  </div>
                  {sectionArray.length === 1 ? null : (
                    <DeleteIcon
                      className="cursor"
                      color="error"
                      fontSize="small"
                      onClick={() => handleCloseSection(index)}
                    />
                  )}
                </div>
              );
            }
          )}
          {modelSet === true ? (
            <div className="d-flex justify-content-end pt-2">
              <Addmorebtn
                name="+ Add Section"
                handleClick={onPressAddSection}
              />
            </div>
          ) : (
            ""
          )}
          <div className="d-flex justify-content-center mt-4">
            <Submitbtn name={"Create Section"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
