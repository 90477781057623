export default {
    PaidAdsSidebarData: [
    
        {
            title: "PAID ADD LEADS",
            path: "paidAddLeads",
        },
        {
            title: "REINQUIRED LEADS",
            path: "reinquiredLeads",
        },
    ]
};