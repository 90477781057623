import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
 

function WIP() {
  const dispatch = useDispatch();
  const navigate = useNavigate(); 

  return (
    <div className="contentBox" style={{background:'var(--transblue)'}}>
 
        <div className="rounded text-center pt-5">
          <h3 className="fw-bold boldfont mb-4 colorblue text-center">Work In Progress!!!</h3>
          <h1 className="letterSpacing mb-0">Please, Visit Again!!</h1>
              <h1 className="colorblue fw-bold" style={{fontSize:'4em'}}>
                {' '}
                Program Coming Soon
              </h1>
        </div>
                        
    </div>
  );
}

export default WIP;