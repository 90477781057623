import PageTitle from "../../Components/pageTitle";
import { useDispatch } from "react-redux";
import "swiper/css/pagination";
import "swiper/css";
import "swiper/css/navigation";
import { useEffect, useState } from "react";
import moment from "moment";
import { teacherNotification } from "../../Redux/Actions/AdminAction";
import NotificationPopUp from "../../Components/notificationPopup";
export default function Notification() {
  const dispatch = useDispatch();
  const [toggle, setToggle] = useState("Live");
  const [notificationList, setNotificationList] = useState([]);
  const [open, setopen] = useState(false);
  const [notificationData, setNotificationData] = useState();
  const tab = [
    {
      _id: "1",
      name: "Live",
    },
    {
      _id: "2",
      name: "Demo",
    },
  ];

  useEffect(() => {
    dispatch(
      teacherNotification(
        "",
        (data: any) => {
          setNotificationList(data);
        },
        () => {}
      )
    );
  }, []);
  const onCardpress = (c: any) => {
    setNotificationData(c);
    setopen(true);
  };
  const handleClose = () => {
    setopen(false);
  };
  const handleChangetab = (val: any) => {
    setToggle(val);
  };
  const renderNotification = () => {
    return (
      <>
        {notificationList.map((c: any, index: any) => {
          return (
            <div>
              <div
                className="col-md-10 d-flex align-items-center boxshadow border border-2 p-4 cursor mb-2 rounded"
                onClick={() => onCardpress(c)}
              >
                <div className="col-2 col-sm-1 justify-centent-end">
                  <img
                    src={c.notificationIcon}
                    alt="icon"
                    className="notiicon"
                  />
                </div>
                <div className="col-7 col-sm-8 ">
                  <p className=" fw-bold fs-6 text-success m-0 ellipsisNotificationTitle">
                    {c.notificationTitle}
                  </p>

                  <p className=" m-0 ellipsisNotification text-break">
                    {c.notificationText}
                  </p>
                </div>
                <div className="col-3 d-flex justify-content-end">
                  <p className=" text-secondary">{`${moment(
                    c.scheduledAt,
                    "YYYY-MM-DDTHH:mm:ss.SSS[Z]"
                  ).format("h:mm a")}`}</p>
                </div>
              </div>
            </div>
          );
        })}
      </>
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"Notifications"} />
      <div className="px-5 mx-0 mt-4 formDiv ">
        <div className="d-flex justify-content-between  h-100 ">
          <div className="col-md-12 p-2 ">
            <div className="d-flex mt-5  border-bottom  ">
              {tab?.map((c, index) => (
                <div
                  className={`col-md-4 fs-6 text-center cursor fw-bold ${
                    toggle === c.name
                      ? " text-primary border-bottom border-primary border-3"
                      : "text-secondary"
                  }`}
                  onClick={() => handleChangetab(c)}
                >
                  {c.name}
                </div>
              ))}
            </div>
            {toggle === "Live" ? (
              <div className="mt-4 notification  ">{renderNotification()}</div>
            ) : (
              ""
            )}
            {toggle === "Demo" ? (
              <div className="mt-4 notification  "> {renderNotification()}</div>
            ) : (
              ""
            )}
            <NotificationPopUp
              openPopup={open}
              handleClose={handleClose}
              dataCard={notificationData}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
