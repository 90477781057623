import { Route, Routes } from "react-router-dom";
import Sidebar from "../../Components/sidebar";
import WIP from "../wip";
import dsaSalesSidebarData from "./dsaSalesSidebarData";
import CreateUser from "./createUser";
import DemoRequest from "./demoRequest";
import DemoSchedule from "./demoSchedule";
import DemoConducted from "./demoConducted";
import DemoCancel from "./demoCancel";

export default function DsaSalesPanelDashboard() {
  return (
    <div className="AppBox">
      <Sidebar data={dsaSalesSidebarData?.DsaSalesSidebarData} />

      <div className="dashboard">
        <Routes>
          <Route path="/" element={<CreateUser />} />
          <Route path="createUser" element={<CreateUser />} />
          <Route path="demoRequest" element={<DemoRequest />} />
          <Route path="demoSchedule" element={<DemoSchedule />} />
          <Route path="demoConducted" element={<DemoConducted />} />
          <Route path="demoCancel" element={<DemoCancel />} />

          <Route path="*" element={<WIP />} />
        </Routes>
      </div>
    </div>
  );
}
