import { useEffect, useState } from 'react'
import PageTitle from '../../Components/pageTitle'
import AdminTable from '../../Components/adminTable';
import LabelField from '../../Components/labelField'
import InputField from '../../Atoms/InputField';
import ImagePickerAndViewer from '../../Components/imagePickerAndViewer';
import { useDispatch } from 'react-redux';
import { deleteLanguage, getLanguageList, updateLanguage, uploadLanguageLogo } from '../../Redux/Actions/AdminAction';
import { image_size_150, validateIsFileImageType, validateIsLettersAndDotOnly, startsWithSpace, length_Two, length_Three, length_Hundred, validateFullName } from '../../Utils/validators';
import { showErrorSnackbar } from '../../Redux/Actions/snackbarAction';
import { validateIsFileImageTypeErrMsg } from '../../Utils/errorMsg';
import SubmitButton from '../../Components/submitButton';

export default function UpdateLanguage() {
  const dispatch: Function = useDispatch();
  const [language, setLanguage] = useState<string>("");
  const [abbreviation, setAbbreviation] = useState<string>("");
  const [languageList, setLanguageList] = useState<string>("");
  const [languageLogo, setLanguageLogo] = useState<any>("");
  const [languageId, setLanguageId] = useState<any>("");
  const [uploadLanguagelogo, setUploadLanguagelogo] = useState<any>("");
  const [languageArr, setLanguageArr] = useState<any>("");

  const [isEdit, setIsEdit] = useState<boolean>();

  useEffect(() => {
    updatedata();
  }, []);

  const updatedata = () => {
    dispatch(
      getLanguageList(
        "",
        (res: any) => {
          setLanguageList(res)
        },
        () => { }
      )
    );
  };

  const handleEdit = (value: any) => {
    setIsEdit(true);
    setLanguage(value?.name);
    setAbbreviation(value?.abbreviation);
    setLanguageLogo(value?.languageIcon);
    setLanguageId(value._id);
    setLanguageArr(value)

  }

  const handleDelete = (value: any) => {
    dispatch(
      deleteLanguage(
        value._id,
        () => {
          updatedata();
        },
        () => { }
      )
    );
  }

  const handleOnEdit = (e: any) => {
    e.preventDefault();

    const postData: any = {
    }
    if (languageArr?.name != language) {
      postData["language"] = language
    }
    if (languageArr?.abbreviation != abbreviation) {
      postData["abbreviation"] = abbreviation
    }
    if (languageArr?.languageIcon != uploadLanguagelogo?.file) {
      postData["languageIcon"] = uploadLanguagelogo?.file
    }
    if (languageArr?.originalLanguageIcon != uploadLanguagelogo?.originalName) {
      postData["originalLanguageIcon"] = uploadLanguagelogo?.originalName
    }
    dispatch(
      updateLanguage(
        postData,
        languageId,
        (res: any) => {
          setIsEdit(false);
          updatedata();

        }, () => { }
      )
    );

  };

  const handleOptionImageChange = (e: { preventDefault: () => void; target: { files: any[]; }; }) => {
    e.preventDefault();
    if (!validateIsFileImageType(e)) {
      dispatch(showErrorSnackbar(validateIsFileImageTypeErrMsg));
      return;
    }
    if (e.target.files[0].size > image_size_150) {
      dispatch(showErrorSnackbar("File size should be less than 150 KB!!!"));
    } else {
      const reader = new FileReader();
      const file = e.target.files[0];
      reader.onloadend = () => {
        setLanguageLogo(reader.result);
      };
      reader.readAsDataURL(file);
      dispatch(uploadLanguageLogo(file, (res: any) => {
        delete res?.filePath;
        setUploadLanguagelogo(res);
      }, () => { }));
    }
  }
  const onChangeLanguage = (value: any) => {
    if (validateIsLettersAndDotOnly(value) && !startsWithSpace(value)) {
      setLanguage(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }
  const onChangeAbbreviation = (value: any) => {
    if (validateFullName(value) && !startsWithSpace(value)) {
      setAbbreviation(value)
    }
    else {
      dispatch(showErrorSnackbar("Please Character only"));
      return;
    }

  }

  const validation = () => {
    return (
      language === "" ||
      language.length < length_Two ||
      abbreviation === "" ||
      abbreviation.length < length_Two ||
      languageLogo === ""
    );
  }

  return (
    <div className="contentBox">
      <PageTitle name={"EDIT LANGUAGE NAME"} />
      <div className='px-4 mx-0 mt-4 formDiv '>
        {isEdit !== true ?
          <AdminTable
            tableData={languageList ? languageList : []}
            pagename={"UpdateLanguage"}
            tableHead={["S.N", "Language Name", "Language Abbreviation", "Language Icon", "Action"]}
            handleEdit={(value: any) => handleEdit(value)}
            handleDelete={(value: any) => handleDelete(value)}
            edit={true}
          />
          : ""}

        {isEdit === true ? <div>
          <form className='' onSubmit={handleOnEdit}>

            <div className="col-7">
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Language"} />
                </div>
                <div className="col-8">
                  <InputField
                    placeholder="Enter Language"
                    value={language}
                    onChangeInput={(value: any) => onChangeLanguage(value)}
                    maxlength={length_Hundred}
                    minlength={length_Two}
                  />
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Language Abbreviation"} />
                </div>
                <div className="col-8">
                  <InputField
                    placeholder="Enter Language Abbreviation"
                    value={abbreviation}
                    maxlength={length_Three - 1}
                    minlength={length_Two}
                    onChangeInput={(value: any) => onChangeAbbreviation(value)}
                  />
                </div>
              </div>
              <div className='d-flex mb-2 align-items-center'>
                <div className="col-4 align-item-center d-flex">
                  <LabelField lableName={"Language Icon"} />
                </div>
                <div className="col-8">
                  <ImagePickerAndViewer
                    lableName={"Option Image"}
                    value={languageLogo}
                    handleChange={(e: any) => handleOptionImageChange(e)}
                    isRequired={true}
                  />
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center mt-3'>
              <SubmitButton
                name={"Save & Update"}
                validation={validation()} />
            </div>
          </form>
        </div> : ""}
      </div>
    </div>
  )
}

