import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import { useDispatch } from "react-redux";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import Addmorebtn from "./addmorebtn";
import { RescheduleRequest } from "../Redux/Actions/AdminAction";
import DemoCancelPopup from "./demoCancelPopup";
import SalesReSchedulePopup from "./salesReSchedulePopup";
import RescheduleCancelPopup from "./rescheduleCancelPopup";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList?: Function;
}

export default function RescheduleDetailPopup(props: props) {
  const dispatch = useDispatch();
  const [openCancelPopup, setOpenCancelPopup] = useState(false);
  const [openReSchedulePopup, setOpenReSchedulePopup] = useState(false);

  const { open, handleClose, data, getList } = props;
  useEffect(() => { }, []);

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }

  const onClickAcceptBtn = (value: any) => {
    const postData = {
      titleId: data?.titleId,
      sessionTime: data?.sessionTime,
      scheduleFrom: data?.scheduleFrom,
    };

    dispatch(
      RescheduleRequest(
        postData,
        (data: any) => { 
          handleClose()
        },
        () => { }
      )
    );
  };

  const onClickCancelBtn = (value: any) => {
    setOpenCancelPopup(true);
  };

  const handleCancelClose = (value: any) => {
    setOpenCancelPopup(false);
    handleClose()
  };

  const handleReScheduleClose = (value: any) => {
    setOpenReSchedulePopup(false);
    handleClose()
  };

  const onClickRescheduleBtn = (value: any) => {
    setOpenReSchedulePopup(true);
  };

  return (
    <>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className="modelChat"
        open={open}
        // onClose={() => {
        //   handleClose();
        // }}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className="demomodeldivChat ">
            <div className="d-flex justify-content-between">
              <h5 className="fw-bold">Reschedule</h5>
              <div>
                <HighlightOffIcon
                  color="primary"
                  className="cursor"
                  onClick={closePopup}
                />{" "}
              </div>
            </div>
            <div className="demoCard mt-0">
              <div className="row mt-2">
                <div className="col-md-12">
                  <h5 className="fw-bold">{data?.title}</h5>
                  {data?.parentName ? (
                    <div className="d-flex">
                      <div className="font16 mb-2 fw-bold">Parent Name:</div>
                      <div className="font16 mb-2 ms-2">{data?.parentName}</div>
                    </div>
                  ) : null}
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Student Name:</div>
                    <div className="font16 mb-2 ms-2">
                      {" "}
                      {`${data?.firstName} ${data?.lastName}`}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Phone:</div>
                    <div className="font16 mb-2 ms-2">{data?.mobile}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Email:</div>
                    <div className="font16 mb-2 ms-2"> {data?.email}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Course Type:</div>
                    <div className="font16 mb-2 ms-2">
                      {data?.coursetypeName}
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Country:</div>
                    <div className="font16 mb-2 ms-2">{data?.countryName}</div>
                  </div>
                  <div className="d-flex">
                    <div className="font16 mb-2 fw-bold">Teacher Name:</div>
                    <div className="font16 mb-2 ms-2">{data?.teacherName}</div>
                  </div>
                  <div className="d-flex mt-2 ">
                    <div className="font16 mb-2 fw-bold">
                      Reschedule Date & Time:
                    </div>
                    <div className="font16 mb-2 ms-2">{`${data?.scheduleFrom}, ${data?.sessionTime}`}</div>
                    <div className="d-flex  ms-3">
                      <Addmorebtn
                        name="Re-Schedule"
                        handleClick={onClickRescheduleBtn}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex  mt-2 justify-content-between">
              <div className="col-md-6 d-flex  justify-content-center mt-2">
                <Addmorebtn name="Accept" handleClick={onClickAcceptBtn} />
              </div>

              <div className="col-md-6 d-flex justify-content-center  mt-2">
                <Addmorebtn name="Cancel" handleClick={onClickCancelBtn} />
              </div>
            </div>
            <RescheduleCancelPopup
              open={openCancelPopup}
              handleClose={handleCancelClose}
              data={data}
              getList={getList}
            />
            <SalesReSchedulePopup
              open={openReSchedulePopup}
              handleClose={handleReScheduleClose}
              data={data}
              getList={getList}
              pageName={"RescheduleDetailPopup"}
            />
          </div>
        </Fade>
      </Modal>
    </>
  );
}
