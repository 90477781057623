import { useEffect } from "react";
import { useDispatch } from "react-redux";
import BarChartGraphs from "./barChartGraphs";
import BarChartGraphsOverallRatings from "./barChartGraphsOverallRatings";

export default function FeedbackGraphs(props: any) {
  const dispatch = useDispatch();

  useEffect(() => { }, []);

  return (
    <div>
      <div className="w-100 pt-3">
        <div className="row">
          <div className="col-md-8 mt-5 ">
            <h5 className="fw-bold">Overall Rating</h5>
            <div className=" p-3">
              <BarChartGraphsOverallRatings
                label={props?.xLabel1}
                value={props?.Graphdata1}
              />
            </div>

          </div>
          <div className="d-flex mt-5">
            <div className="col-md-5 mt-5 ">
              <h5 className="fw-bold">1.{props?.question1}</h5>
              <BarChartGraphs
                label={props?.qLabel1}
                value={props?.GraphdataQ1}
              />
            </div>
            <div className="col-md-5 mt-5 ms-5 ">
              <h5 className="fw-bold">2.{props?.question2}</h5>
              <BarChartGraphs
                label={props?.qLabel2}
                value={props?.GraphdataQ2}
              />
            </div>
          </div>
          <div className="d-flex mt-5">
            <div className="col-md-5 mt-5 ">
              <h5 className="fw-bold">3.{props?.question3}</h5>
              <BarChartGraphs
                label={props?.qLabel3}
                value={props?.GraphdataQ3}
              />
            </div>
            <div className="col-md-5 mt-5 ms-5 ">
              <h5 className="fw-bold">4.{props?.question4}</h5>
              <BarChartGraphs
                label={props?.qLabel4}
                value={props?.GraphdataQ4}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
