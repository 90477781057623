import React, { useEffect, useState } from "react";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LabelField from "./labelField";
import CustomDropdown from "./customDropdown";
import { studentLevelList } from "../Utils/dataConstant";
import SubmitButton from "./submitButton";
import { getTeacherStudentList, updateStudentLevel } from "../Redux/Actions/AdminAction";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { AuthReducerModal } from "../Modals";

interface props {
  open: boolean;
  handleClose: Function;
  data?: any;
  getList: Function;

}

export default function StudentLevelChange(props: props) {
  const dispatch: Function = useDispatch();
  const { open, handleClose, data, getList } = props;
  const [studentLevel, setStudentLevel] = useState("");
  const [resetStudentLevelList, setResetStudentLevelList] = useState(false);
  const { userId } = useSelector((state: { Auth: AuthReducerModal }) => ({
    userId: state.Auth.userId,
  }));

  function closePopup(
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ): void {
    handleClose();
  }
  console.log('datadatadatadatadata', data)
  console.log('studentLevel', studentLevel)


  const onChangeStudentLevel = (value: any) => {
    setStudentLevel(value?._id);
    setResetStudentLevelList(false);
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    const Postdata = {
      subjectId: data?.subject?._id,
      studentId: data?.studentId,
      empId: userId,
      levelId: studentLevel
    };

    dispatch(
      updateStudentLevel(
        Postdata,
        (res: any) => {
          setResetStudentLevelList(true);
          handleClose();
          getList();
        },
        () => { }
      )
    );
  };

  const validation = () => {
    return studentLevel === "";
  };

  return (
    <Modal
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      className="modelChat"
      open={open}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
    >
      <Fade in={open}>
        <div className="demoTeacherPopup p-md-4 py-md-4 ">
          <div className="d-flex justify-content-between">
            <h5 className="fw-bold">Change Student Level</h5>
            <div>
              <HighlightOffIcon
                color="primary"
                className="cursor"
                onClick={closePopup}
              />{" "}
            </div>
          </div>
          <div className="demoCard mt-5 p-5">
            <div className="row mt-2">
              <form className="col-10 pt-2" onSubmit={handleSubmit}>
                <div className="col-md-7">
                  <div className="d-flex Justify-content-between">
                    <div className="col-4 align-item-center d-flex">
                      <LabelField lableName={"Student Level"} />
                    </div>
                    <div className="col-8 ps-2 ">
                      <CustomDropdown
                        lableName="Select Student Level"
                        setInputText={(value: any) => onChangeStudentLevel(value)}
                        value={studentLevel}
                        options={studentLevelList}
                        Isrequired={true}
                        reset={resetStudentLevelList}
                        defaultValue={data?.studentLevel}
                      />
                    </div>
                    <div className=" col-6 d-flex justify-content-center">
                      <SubmitButton name={"Submit"} validation={validation()} />
                    </div>
                  </div>

                </div>
              </form>
            </div>
          </div>
        </div>
      </Fade>
    </Modal>
  );
}
