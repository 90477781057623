import { useEffect, useState } from "react";
import PageTitle from "../../Components/pageTitle";
import LabelField from "../../Components/labelField";
import Submitbtn from "../../Components/submitButton";
import InputField from "../../Atoms/InputField";
import CustomDropdown from "../../Components/customDropdown";
import { useDispatch } from "react-redux";
import {
  getCountryList,
  getCourseTypelist,
  getExamListWeb,
  getGeneralExamList,
  register,
} from "../../Redux/Actions/AdminAction";
import {
  length_Ten,
  validateFullName,
  validateIsNumberOnly,
} from "../../Utils/validators";
import {
  validateEmailAddressErrMsg,
  validateFullNameErrMsg,
  validateLastNameErrMsg,
} from "../../Utils/errorMsg";
import { showErrorSnackbar } from "../../Redux/Actions/snackbarAction";
export default function CreatePackage() {
  const dispatch: Function = useDispatch();
  const [fname, setfname] = useState("");
  const [lname, setlname] = useState("");
  const [email, setemail] = useState("");
  const [examList, setExamList] = useState([]);
  const [examval, setExamval] = useState<any>("");
  const [pname, setpname] = useState("");
  const [mobileNo, setMobileNo] = useState<string>("");
  const [pmobno, setpmobno] = useState("");
  const [pemail, setpemail] = useState<string>("");
  const [altMobno, setaltMobno] = useState<string>("");
  const [countryCode, setcountryCode] = useState<any>("");
  const [countryList, setCountryList] = useState([]);
  const [resetCountry, setresetCountry] = useState<boolean>(false);
  const [resetExam, setresetExam] = useState<boolean>(false);
  const [course, setCourse] = useState<any>("");
  const [courseList, setCourseList] = useState([]);
  const [resetCourse, setResetCourse] = useState(false);
  useEffect(() => {
    dispatch(
      getCountryList(
        "",
        (res: any) => {
          setCountryList(res);
        },
        () => { }
      )
    );
    dispatch(
      getExamListWeb(
        "",
        (data: any) => {
          setExamList(data);
        },
        () => { }
      )
    );
  }, []);

  const handleFirstInputText = (e: any) => {
    if (validateFullName(e)) setfname(e);
    else {
      dispatch(showErrorSnackbar(validateFullNameErrMsg));
    }
  };

  const handleLastInputLnameText = (e: any) => {
    if (validateFullName(e)) setlname(e);
    else {
      dispatch(showErrorSnackbar(validateLastNameErrMsg));
    }
  };
  const handleParentNameInputLnameText = (e: any) => {
    if (validateFullName(e)) setpname(e);
    else {
      dispatch(showErrorSnackbar("Please enter valide Parent Name"));
    }
  };

  function getExamVal(value: any) {
    setExamval(value);
    setresetExam(false);
  }

  const onChangeMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setMobileNo(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangeParentMobileNO = (value: any) => {
    if (!validateIsNumberOnly(value)) {
      setpmobno(value);
    } else {
      dispatch(showErrorSnackbar("Please enter number only"));
      return;
    }
  };
  const onChangecountryCode = (val: any) => {
    setcountryCode(val);
    setresetCountry(false);
    const postData = {
      countryId: val?._id,
    };
    dispatch(
      getCourseTypelist(
        postData,
        (res: any) => {
          setCourseList(res);
        },
        () => { }
      )
    );
  };

  const handleSubmit = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    if (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
      const payload: any = {
        firstName: fname,
        lastName: lname,
        mobile: mobileNo,
        email: email,
        parentEmail: pemail,
        parentName: pname,
        parentNumber: pmobno,
        altNumber: altMobno,
        countryCode: "+" + countryCode?.phonecode,
        coursetypeId: course?._id,
      };
      if (altMobno === "") {
        delete payload.altNumber;
      }
      if (pemail === "") {
        delete payload.parentEmail;
      }
      if (pname === "") {
        delete payload.parentName;
      }
      if (pmobno === "") {
        delete payload.parentNumber;
      }
      dispatch(
        register(
          payload,
          (data: any) => {
            setresetCountry(true);
            setMobileNo("");
            setfname("");
            setlname("");
            setMobileNo("");
            setemail("");
            setpname("");
            setpemail("");
            setpmobno("");
            setcountryCode("");
            setExamval("");
            setCourse("");
            setresetExam(true);
            setResetCourse(true);
          },
          () => { }
        )
      );
    } else {
      dispatch(showErrorSnackbar(validateEmailAddressErrMsg));
      return false;
    }
  };

  function validation() {
    return (
      fname === "" ||
      lname === "" ||
      countryCode === "" ||
      mobileNo === "" ||
      mobileNo?.length < 9 ||
      email === ""
    );
  }

  const onChangeCourse = (value: any) => {
    setCourse(value);
    setResetCourse(false);
    dispatch(
      getGeneralExamList(
        {
          courseId: value._id,
        },
        (res: any) => {
          setExamList(res);
        },
        () => { }
      )
    );
  };
  return (
    <div className="contentBox">
      <PageTitle name={"USER CREATION"} />
      <div className="px-4 mx-0 mt-4 formDiv ">
        <form className="" onSubmit={handleSubmit}>
          <div className="col-7">
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"First Name"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter First Name"
                  value={fname}
                  onChangeInput={(value: any) => handleFirstInputText(value)}
                  isRequired={true}
                  minlength={2}
                  maxlength={150}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Last Name"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Last Name"
                  value={lname}
                  onChangeInput={(value: any) =>
                    handleLastInputLnameText(value)
                  }
                  isRequired={true}
                  minlength={2}
                  maxlength={150}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center mt-2">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Country"} />
              </div>
              <div className="col-8 ps-2 ">
                <CustomDropdown
                  lableName="Select Country"
                  setInputText={(value: any) => onChangecountryCode(value)}
                  value={countryCode}
                  options={countryList}
                  Isrequired={true}
                  reset={resetCountry}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Mobile Number"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Mobile Number"
                  value={mobileNo}
                  onChangeInput={(value: any) => onChangeMobileNO(value)}
                  maxlength={length_Ten}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Email Address"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Email Address"
                  value={email}
                  onChangeInput={(value: any) => setemail(value)}
                  isRequired={true}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Course type"} />
              </div>
              <div className="col-8 ps-2">
                <CustomDropdown
                  lableName="Select Course Type Name"
                  setInputText={(value: any) => onChangeCourse(value)}
                  value={course}
                  options={courseList}
                  Isrequired={true}
                  reset={resetCourse}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Parent Name"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Parent Name"
                  value={pname}
                  onChangeInput={(value: any) =>
                    handleParentNameInputLnameText(value)
                  }
                  maxlength={100}
                />
              </div>
            </div>

            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Parent Mobile Number"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Parent Mobile Number"
                  value={pmobno}
                  onChangeInput={(value: any) => onChangeParentMobileNO(value)}
                  maxlength={length_Ten}
                />
              </div>
            </div>
            <div className="d-flex mb-2 align-items-center">
              <div className="col-4 align-item-center d-flex">
                <LabelField lableName={"Parent Email Address"} />
              </div>
              <div className="col-8  ">
                <InputField
                  placeholder="Enter Parent Email Address"
                  value={pemail}
                  onChangeInput={(value: any) => setpemail(value)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex justify-content-center mt-3">
            <Submitbtn name={"Create User"} validation={validation()} />
          </div>
        </form>
      </div>
    </div>
  );
}
