export const validateFullNameErrMsg = "Please enter valid name. ";
export const validateLastNameErrMsg = "Please enter valid Last name. ";
export const validateIsNumberOnlyErrMsg = "Please enter number only";
export const validateEmailAddressErrMsg = "Please enter valid email address";
export const validateIsFileImageTypeErrMsg =
  "File should be type of Image only!";
export const validateIsAlphabetErrMsg = "Please enter alphabet only";
export const validateIsFilePDFTypeErrMsg = "File should be type of PDF only!";
export const validateIsFileVideoTypeErrMsg =
  "File should be type of VIDEO only!";
export const isValidDiscountErrMsg = "Only numbers are allowed in discount.";
export const discountValue = "Discount value should be less then 100.";
export const validateFeedbackErrMsg = "Please fill all feedback";
export const validEndDate = "End date should be greater then start date.";
